import {IDistribuidora, IDistribuidoraDashboard, IDistribuidoraListItem} from "../../models/Distribuidora";
import http from "../common/http-common";
import {AxiosResponse} from "axios";
import {IPaginatedList} from "../../models/PaginatedList";

export const GetDistribuidoras = () => {
  return http.get<IDistribuidoraListItem[]>(`/Distribuidora/BuscaTodasDistribuidoras`);
};

export const GetDistribuidoraById: (id: string) => Promise<AxiosResponse<IDistribuidora>> = (id: string) => {
  return http.get<IDistribuidora>(`/Distribuidora/${id}`);
};

export const GetPaginatedDistribuidora: (pageNumber: number, pageSize: number, search?: string) => Promise<AxiosResponse<IPaginatedList<IDistribuidora>, any>> = (
  pageNumber: number,
  pageSize: number,
  search?: string
): Promise<AxiosResponse<IPaginatedList<IDistribuidora>, any>> => {
  var searchString: string = "";

  if (search) {
    searchString = `&Search=${search}`;
  }

  return http.get<IPaginatedList<IDistribuidora>>(
    `/Distribuidora?pageNumber=${pageNumber}&pageSize=${pageSize}${searchString}`
  );
};

export const GetDashboardDistribuidora: () => Promise<AxiosResponse<any, IDistribuidoraDashboard>> = (): Promise<AxiosResponse<any, IDistribuidoraDashboard>> => {
  return http.get(`/Distribuidora/DistribuidoraDashboard`);
};

export const GetDistribuidoraaReport: () => Promise<AxiosResponse<File, any>> = () => {
  return http.get<File>(`/Distribuidora/BuscaRelatorioDistribuidora`, {
    responseType: "blob",
  });
};

export const setDistribuidora: (data: IDistribuidora) => Promise<AxiosResponse<any>> = (data: IDistribuidora) => {
  return http.post(`/Distribuidora`, data);
};

export const updateDistribuidora: (data: IDistribuidora) => Promise<AxiosResponse<any>> = (data: IDistribuidora) => {
  return http.put(`/Distribuidora`, data);
};