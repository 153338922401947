import { z } from "zod";
import {
  EnumConvenio,
  EnumConvenioNumericMap,
} from "../enums/EnumConvenio.enum";
import {
  EnumTipoParceiro,
  EnumTipoParceiroNumericMap,
} from "../enums/EnumTipoParceiro.enum";
import { validarUUID } from "../utils/Utils";

export const informacaoAssociadoSchema = z.object({
  captadorId: z.string().uuid(),
  tipoParceiro: z
    .nativeEnum(EnumTipoParceiro)
    .nullable()
    .transform((x) => (x != null ? EnumTipoParceiroNumericMap[x] : x))
    .refine((x) => x != null),
  convenio: z
    .nativeEnum(EnumConvenio)
    .nullable()
    .transform((x) => (x != null ? EnumConvenioNumericMap[x] : x))
    .refine((x) => x != null),
  backOfficeId: z.string().uuid(),
  captadorMasterId: z
    .string()
    .transform((x) => (x === "" ? null : x))
    .refine((x) => x === null || validarUUID(x), {
      message: "O captadorMasterId deve ser uma string vazia ou um UUID válido",
    }),
});

export type InformacaoAssociado = z.input<typeof informacaoAssociadoSchema>;

export type InformacaoAssociadoReq = z.output<typeof informacaoAssociadoSchema>;
