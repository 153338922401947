import RegisterCard from "../../../../../../components/RegisterCard";
import React, {Dispatch, FC, SetStateAction, useEffect, useRef, useState} from "react";
import * as G from "../../style";
import {TextField} from "@mui/material";
import * as D from "../../../../../../styles/appComponents";
import * as C from "../Situacao/style";
import {ErrorMessage} from "../../../../../../utils/AbstractValidation";
import {handleStyleFieldError} from "../../../../../../utils/verifyFieldError";
import {EnumTipoDocumento} from "../../../../../../enums/EnumTipoDocumento.enum";
import {ColaboradorByIdResponse, DocumentoResponse} from "../../../../../../models/Colaborador/Colaborador";
import {ArquivoSection} from "../../../../../../components/ArquivoSection";
import {IFullLog} from "../../../../../../models/Log";
import {IAnexoDTO} from "../../../../../../models/Anexo";
import {EnumTipoAnexo} from "../../../../../../enums/EnumTipoAnexo.enum";
import MaskDefaultInput from "../../../../../../components/MasDefaultinput/MaskDefaultInput";

enum DocumentosEnum {
  CPF = "CPF",
  RG = "RG",
  PIS = "PIS",
  SERIE = "SERIE",
  UF = "UF",
  TIPODOCUMENTO = "TIPODOCUMENTO"
}

export interface ElementForm{
  label?:string | null;
  name?: DocumentosEnum;
  key?: string;
}

export interface IDocumentoForm extends ElementForm {
  id?: string | null;
  cpf: string | null;
  rg: string | null;
  pis: string | null;
  numero: string | null;
  serie: string | null;
  uf: string | null;
  tipodocumento: EnumTipoDocumento | null
}

type PropsDocumentos = {
  onSave: (data: IDocumentoForm[]) => void;
  onUpdate: (data: IDocumentoForm[]) => void;
  onChange: (data: IDocumentoForm[]) => void;
  buttonSave: boolean;
  fieldErros: ErrorMessage[] | null;
  data?: object | null;
  colaboradorId: string | undefined;
  colaborador: ColaboradorByIdResponse | undefined;
  load: { status:boolean, section:string };
  anexos: IAnexoDTO[];
  dataUpdated: Dispatch<SetStateAction<boolean>>;
};

const Documentos:FC<PropsDocumentos> = (
  {
    onSave,
    onUpdate,
    onChange,
    buttonSave,
    data,
    fieldErros,
    colaborador,
    colaboradorId,
    load,
    anexos,
    dataUpdated
  }
) => {
  const docuemntosFormModel:IDocumentoForm[] = [
    {
      label:"CPF",
      name: DocumentosEnum.CPF,
      key: "cpf",
      cpf: null,
      rg: null,
      pis: null,
      numero: null,
      serie: null,
      uf: null,
      tipodocumento: EnumTipoDocumento.CPF
    },
    {
      label:"RG",
      name: DocumentosEnum.RG,
      key: "rg",
      cpf: null,
      rg: null,
      pis: null,
      numero: null,
      serie: null,
      uf: null,
      tipodocumento: EnumTipoDocumento.RG
    },
    {
      label:"Pis",
      name: DocumentosEnum.PIS,
      key: "pis",
      cpf: null,
      rg: null,
      pis: null,
      numero: null,
      serie: null,
      uf: null,
      tipodocumento: null
    },
    {
      label:"Série",
      name: DocumentosEnum.SERIE,
      key: "serie",
      cpf: null,
      rg: null,
      pis: null,
      numero: null,
      serie: null,
      uf: null,
      tipodocumento: null
    },
    {
      label:"UF",
      name: DocumentosEnum.UF,
      key: "uf",
      cpf: null,
      rg: null,
      pis: null,
      numero: null,
      serie: null,
      uf: null,
      tipodocumento: null
    }
  ];
  const [dadosDocumentos, setDadosDocumentos] = useState<IDocumentoForm[]>(docuemntosFormModel);
  const [anexo, setAnexo] = useState<IAnexoDTO | null>(null)
  const sendOnSave = (): void => onSave(dadosDocumentos);
  const sendOnUpdate = (): void => onUpdate(dadosDocumentos);
  const sendOnChange = (update: IDocumentoForm[]): void => onChange(update);
  const [log, setLog] = useState<IFullLog | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if(colaboradorId && colaborador?.documentos?.length !== 0){
      const docuemntosFormModelUpdated:any = (docuemntosFormModel || [])
      .map((x) => {


        const documento = (colaborador?.documentos || [])
        ?.find(e => e.tipoDocumento === x.tipodocumento)

        const documentoSerie = (colaborador?.documentos || [])
        ?.find(e => e.serie)

        const documentoUf = (colaborador?.documentos || [])
        ?.find(e => e.uf)

        const documentoNumero = (colaborador?.documentos || [])
        ?.find(e => e.numero)

        const documentoPis = (colaborador?.documentos || [])
        ?.find(e => e.pis)

        const documentoId = (colaborador?.documentos || [])
        ?.find(e => e.id)

        const verifyKey = (item:DocumentoResponse | undefined, key:string | undefined) => Object.keys(item || {})?.includes(key || "")

        return{
          ...x,
          id: x.key === "cpf" && documento?.tipoDocumento === EnumTipoDocumento.CPF? documento?.id: 
          x.key === "rg" && documento?.tipoDocumento === EnumTipoDocumento.RG? documento?.id: 
          x.key === "pis"?documentoPis?.id:
          x.key === "serie"? documentoSerie?.id:
          x.key === "uf"? documentoUf?.id:null,
          label:x?.label,
          name:x?.name,
          cpf: documento?.tipoDocumento === EnumTipoDocumento.CPF? documento.numero: null,
          rg: documento?.tipoDocumento === EnumTipoDocumento.RG? documento.numero: null,
          pis: documentoPis && x.key === "pis"? documentoPis?.pis: null,
          numero: documentoNumero && x.key === "numero"? documentoNumero?.numero: null,
          serie: documentoSerie && x.key === "serie"? documentoSerie?.serie: null,
          uf: documentoUf && x.key === "uf"? documentoUf?.uf : null
        }

      })
  
      setDadosDocumentos(docuemntosFormModelUpdated);

    }else setDadosDocumentos(docuemntosFormModel);

  }, [colaboradorId, colaborador])

  const handleInputChange = (index:number) => ({target}: React.ChangeEvent<HTMLInputElement>) => {
    const name = target?.name?.toLocaleLowerCase();
    const value = target?.value;

    const newForms = [...dadosDocumentos]
    newForms[index] = { ...newForms[index], [name]: value }

    setDadosDocumentos(newForms)
    sendOnChange(newForms)
  };

  const newAnexos = anexos
  .filter(x => x.enumTipoAnexo === EnumTipoAnexo.documentosColaborador)

  return (
    <RegisterCard title="Documentos">
      <G.FWStack direction={"row"} spacing={2}>
        {
          (dadosDocumentos || []).map((x, index) => (
            <>
              {
                x.name === DocumentosEnum.CPF?
                <MaskDefaultInput
                  type="CPF"
                  label={x?.label as any}
                  value={x[x?.name?.toLocaleLowerCase() as keyof IDocumentoForm] as any}
                  onChange={(value) => handleInputChange(index)(
                    { 
                        target:{ name:x?.name, value } 
                    } as any
                  ) as any}
                  sx={handleStyleFieldError(x.name as string, fieldErros, {width:"100%"}, index)}
              />
              :
              <TextField
                  disabled={buttonSave}
                  type={x.name === DocumentosEnum.UF?"text":"number"}
                  value={x[x?.name?.toLocaleLowerCase() as keyof IDocumentoForm]}
                  name={x?.name}
                  label={x?.label}  
                  onChange={handleInputChange(index)}
                  sx={handleStyleFieldError(x.name as string, fieldErros, {width: x.name === DocumentosEnum.UF? "50%": "100%"}, index)}
                  InputLabelProps={{
                    shrink: true,
                }} 
              />
              }

            </>
          ))
        }
      </G.FWStack>
      {
        colaboradorId &&
        <ArquivoSection 
          anexos={newAnexos}
          colaboradorId={colaboradorId}
          dataUpdated={dataUpdated}
          tipoAnexo={EnumTipoAnexo.documentosColaborador}
        />
      }

      <C.FWStack direction={"row"} spacing={2}>
          <C.FWStack direction={"column"} spacing={2} justifyContent={"flex-start"}>
            <C.FWStack direction={"row"} spacing={2}>
              <D.ContainedButton $color="lightpink">Gerar contrato de trabalho</D.ContainedButton>
              <D.ContainedButton $color="lightpink">Gerar a ficha de registro</D.ContainedButton>
              <D.ContainedButton $color="lightpink">Gerar termo de responsabilidade</D.ContainedButton>
              <D.ContainedButton $color="lightpink">Gerar termo de recebimento de cartão</D.ContainedButton>
            </C.FWStack>
          </C.FWStack>

          <D.ContainedButton disabled={!buttonSave} $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
          <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>Salvar</D.ContainedButton>
        </C.FWStack>
    </RegisterCard>
  );
};

export default Documentos;
