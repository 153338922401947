import { Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import ExcelIcon from "../../../assets/Plataforma/excelIcon.svg";
import Datatable from "../../../components/Datatable";
import { ListView } from "../../../components/ListView";
import SkeletonDefaultPage from "../../../components/SkeletonLoads/DefaultPage";
import {
  EnumStatusContratoGerador,
  enumStatusContratoGeradorMap,
} from "../../../enums/Gerador/EnumStatusContratoGerador.enum";
import { EnumTipoPessoa } from "../../../enums/Gerador/EnumTipoPessoa.enum";
import { useAuth } from "../../../hooks/useAuth";
import { UsinaResponse } from "../../../models/Gerador/GeradorResponse";
import { UsinaTipoPessoaJuridica } from "../../../models/Gerador/Usina";
import { ICreateLog, ILog, IUpdateLog } from "../../../models/Log";
import { StatusDashboard } from "../../../models/StatusDashboard";
import {
  GerarRelatorio,
  GetAll,
  GetGDContratoUsina,
  GetOnePessaoFisica,
  GetOnePessaoJuridica,
  GetStatusUsina,
} from "../../../services/api/GeradorService";
import {
  CreateLog,
  GetLogsByItemId,
  UpdateLog,
} from "../../../services/api/LogService";
import * as D from "../../../styles/appComponents";
import * as E from "../../../styles/appComponents";
import { PessoaType } from "../../../types/PessoaType";
import { FormatDate } from "../../../utils/dateFormatter";
import { HandleDataStatus } from "../../../utils/HandleDataStatus";
import { KWH } from "../../../utils/masInputs";
import { toastMessage } from "../../../utils/toastMessage";
import { Support } from "../OrdemPagamento/Support";
import ModalContratar from "../Propostas/Modais/contratar";
import { ModalInfoGerador } from "./Components/ModalInfoGerador";
import * as C from "./style";
import { InputFilterModel } from "../../../models/InputFilterModel";

const Gerador: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [isNotSendClickSign, setIsNotSendClickSign] = useState<boolean>(false);
  const [is, setSearch] = useState<string>("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [geradorId, setGeradorId] = useState<string | null>("");
  const [tipoPessoa, setTipopessoa] = useState<string>("");
  const [statusUsina, setStatusUsina] = useState<
    StatusDashboard<EnumStatusContratoGerador>[]
  >([]);
  const [filterStatusContrato, setFilterStatusContrato] = useState<
    EnumStatusContratoGerador | null | undefined
  >(null);
  const [load, setLoad] = useState<boolean>(false);
  const [usinas, setUsinas] = useState<UsinaResponse[]>([]);
  const [usina, setUsina] = useState<UsinaTipoPessoaJuridica | undefined>(
    undefined
  );
  const [openModalModalQuestionFormType, setOpenModalModalQuestionFormType] =
    useState<boolean>(false);
  const [logs, setLogs] = useState<ILog[]>([]);
  const [statusLoading, setStatusLoading] = useState<{sectionName:string, loading:boolean}>({ sectionName:"gerador", loading:false })
  const [modelFilter, setModelFilter] = useState<InputFilterModel | null>(null);
  const navigate = useNavigate();
  const auth = useAuth();

  const geradorAccess = useMemo<string | null>(
    () => auth.user?.idGerador ?? null,
    [auth]
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const update = searchParams.get("update");

  const handleCloseModalQuestionFormType = () => {
    setOpenModalModalQuestionFormType(false);
  };

  const redirectTypeForm = (type: PessoaType) => {
    navigate(`/Cadastro/Gerador?tipoFormulario=${type}`);
  };

  const downloadContract = async (model: any) => {
    try {
      const id = model?.gdContratoUsinaId;
      const { data } = await GetGDContratoUsina(id);

      const link = document.createElement("a");
      link.href = data?.downloadUrl ?? "";
      link.target = "_blank";
      link.rel = "noopener noreferrer";

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (error) {
      toastMessage("warning", "Erro ao baixar o anexo");
    }
  };

  const createNewLog = async (data: ICreateLog): Promise<void> => {
    searchParams.delete("update");
    navigate({
      pathname: `/Colaborador/Gerador`,
      search: `?update=${true}`,
    });

    if (data.assunto === "") {
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: "Assunto não pode ser vazio.",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });

      return;
    }

    const historicoData: ICreateLog = {
      gdLeadId: null,
      gdPropostaId: null,
      gdContratoClienteId: null,
      usinaId: geradorId || "",
      assunto: data?.assunto,
      descricao: data?.descricao,
      anexo: data?.anexo,
    };

    try {
      await CreateLog(historicoData);
      searchParams.delete("update");
      setSearchParams(searchParams);
      toastMessage("success", "Log criado com sucesso!");

    } catch (error) {
      searchParams.delete("update");
      setSearchParams(searchParams);
      toastMessage("error", "Erro ao criar o log");
    }
  };

  const loadDashBoard = async () => {
    try {
      const { data } = await GetStatusUsina();
      setStatusUsina(data);
      setLoading(false);

    } catch (error) {
      toastMessage("error", "Erro ao listar o dashBoard");
    }
  };

  const editLog = async (id: string, data: ICreateLog): Promise<void> => {
    searchParams.delete("update");
    navigate({
      pathname: `/Colaborador/Gerador`,
      search: `?update=${true}`,
    });

    var newData: IUpdateLog = {
      id: id!,
      assunto: data?.assunto,
      descricao: data?.descricao,
      anexo: data.anexo || null,
    };

    if (newData?.assunto == "") {
      toastMessage("warning", "Assunto/descrição não podem ser vazios.");
      return;
    }

    try {
      await UpdateLog(newData);
      toastMessage("success", "Log editado com sucesso!");
      searchParams.delete("update");
      setSearchParams(searchParams);

    } catch (error) {
      toastMessage("error", "Erro ao editar os dados");
      searchParams.delete("update");
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    if (geradorId) loadLogs(geradorId);
  }, [update]);

  const sectionNameListView = useMemo(
    () => (!geradorAccess ? "gerador" : "geradorAccess"),
    [geradorId]
  );

  useEffect(() => {
    loadUsinas();

  }, [page, pageSize, isNotSendClickSign, filterStatusContrato]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const loadUsina = async (geradorId: string | null) => {
      try {
        if (geradorId) {
          setLoad(true);
          const { data } =
            tipoPessoa === "pessoaJuridica"
              ? await GetOnePessaoJuridica(geradorId)
              : await GetOnePessaoFisica(geradorId);

          setLoad(false);
          setUsina(data);
          setOpenModal(true);
        }
      } catch (error) {
        toastMessage("error", "Erro ao listar uma usina");
        setLoad(false);
      }
    };
    loadUsina(geradorId);
  }, [geradorId, tipoPessoa]);

  const columns: GridColDef[] = [
    {
      field: "idReferencial",
      description: "id",
      headerName: "Id",
      maxWidth: 20,
      align: "center",
      renderCell: (params: any) => (
        <D.GridField
          onClick={() =>
            navigate(
              !!!geradorAccess
                ? `/Editar/Gerador/${params?.row?.id}?tipoFormulario=${params?.row?.tipoPessoa}`
                : `/Gerador/View/${params?.row?.id}?tipoFormulario=${params?.row?.tipoPessoa}`
            )
          }
        >
          {params.value}
        </D.GridField>
      ),
    },
    {
      field: "instalacao",
      description: "instalacao",
      headerName: "Instalação",
      flex: 1,
      renderCell: (params: any) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "gerador",
      description: "name",
      headerName: "Gerador",
      flex: 1,
      renderCell: (params: any) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "sigla",
      description: "sigla",
      headerName: "Sigla",
      renderCell: (params: any) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "kwhM",
      description:"kwh",
      headerName: "Kwh medio",
      flex: 1,
      renderCell: (params: any) => <D.GridField>{`${params?.value? new KWH().execute(params?.value): "0,00"} KWH` as any}</D.GridField>,
    },
    {
      field: "kwhA",
      description:"kwh",
      headerName: "Kwh alvo",
      flex: 1,
      renderCell: (params: any) => <D.GridField>{`${params?.value? new KWH().execute(params?.value): "0,00"} KWH` as any}</D.GridField>,
    },
    {
      field: "data",
      description:"date",
      headerName: "Data assinatura",
      flex: 1,
      renderCell: (params: any) => (
        <D.GridField>{FormatDate(params?.value, "DD/MM/YYYY") ?? ""}</D.GridField>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      width: 70,
      headerAlign: "center",
      renderCell: (params: any) => (
        <D.GridField $alignCenter>
          <Tooltip
            title={HandleDataStatus.getNameStatus(
              params?.row?.status,
              enumStatusContratoGeradorMap
            )}
            arrow
          >
            <E.Circle color={HandleDataStatus.getColorStatus(params?.row?.status, enumStatusContratoGeradorMap)} />
          </Tooltip>
          {!params?.row?.contratoEnviadoClickSign ? (
            "*"
          ) : (
            <span style={{ width: "10px" }}></span>
          )}
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => (
        <D.GridField>
          <D.DefaultGridButton
            onClick={() =>
              handleOpenModal(params?.row?.id, params?.row?.tipoPessoa)
            }
          >
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];

  const rows = (usinas || []).map((usina) => {
    return {
      id: usina?.id,
      tipoPessoa: EnumTipoPessoa[usina.tipoPessoa],
      idReferencial: usina?.idReferencial,
      instalacao: usina?.instalacao,
      gerador: usina?.gerador,
      sigla: usina?.sigla,
      kwhM: usina?.kwhMedio,
      kwhA: usina?.kwhAlvo,
      data: usina?.dataAssinatura ?? "",
      status: usina.status,
      contratoEnviadoClickSign: usina?.contratoEnviadoClickSign,
      outras: "ver mais",
    };
  });

  const loadLogs = async (id?: string) => {
    try {
      if (id) {
        const { data } = await GetLogsByItemId(id);
        setLogs(data);
      }
    } catch (error) {
      toastMessage("error", "Erro ao listar logs");
    }
  };

  const handleOpenModal = (id: string, tipoPessoa: EnumTipoPessoa) => {
    searchParams.delete("update");
    setSearchParams(searchParams);

    setGeradorId(id);
    setTipopessoa(tipoPessoa.toString());
    setOpenModal(true);
    loadLogs(id);
  };

  const handleChangePage = (value: number) => {
    setPage(value);
  };

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
  };

  const loadData = async (model:InputFilterModel | null, type:"clear"|"filter") => {
    setStatusLoading({ sectionName:sectionNameListView, loading: true });
    setModelFilter(model);

    try {
      const { data } = await GetAll(
        (model?.id? parseInt(model?.id): null),
        model?.instalacao,
        model?.name,
        model?.date,
        pageSize,
        page,
        "",
        filterStatusContrato === EnumStatusContratoGerador.aguardandoAssinatura
          ? isNotSendClickSign
          : null,
        filterStatusContrato,
        geradorAccess
      );

      setTotalPages(data?.totalPages);
      setPage(data?.pageNumber);
      
      setUsinas(() => {
        setStatusLoading({ sectionName:sectionNameListView, loading: false });

        if (geradorAccess)
          return data?.data.filter((x) => x.contratoEnviadoClickSign);

        else return data?.data;
      });

      await loadDashBoard();

    } catch (error) {
      setStatusLoading({ sectionName:sectionNameListView, loading: false });
      toastMessage("error", "Erro ao listar as usinas");
    }
  }

  const loadUsinas = async (queryString?: string | null) => {
    setStatusLoading({ sectionName:sectionNameListView, loading: true });

    try {
      const { data } = await GetAll(
        (modelFilter?.id? parseInt(modelFilter?.id): null),
        modelFilter?.instalacao,
        modelFilter?.name,
        modelFilter?.date,
        pageSize,
        page, 
        queryString,
        filterStatusContrato === EnumStatusContratoGerador.aguardandoAssinatura
          ? isNotSendClickSign
          : null,
        filterStatusContrato,
        geradorAccess
      );

      setTotalPages(data?.totalPages);
      setPage(data?.pageNumber);
      
      setUsinas(() => {
        setStatusLoading({ sectionName:sectionNameListView, loading: false });

        if (geradorAccess)
          return data?.data.filter((x) => x.contratoEnviadoClickSign);

        else return data?.data;
      });

      await loadDashBoard();

    } catch (error) {
      setStatusLoading({ sectionName:sectionNameListView, loading: false });
      toastMessage("error", "Erro ao listar as usinas");
    }
  };

  const handleSearch = async (value: string): Promise<void> => {
    setSearch(value);
    await loadUsinas(value);
  };

  const handleCloseModal = (): void => {
    setOpenModal(false);
    setGeradorId(null);
  };

  const gerarRelatorio = async (): Promise<void> => {
    try {
      const { data }: any = await GerarRelatorio({ statusContratoGerador:filterStatusContrato ?? null, gerador:geradorAccess });

      const blob = data;
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `gerador_${new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/[-:T]/g, "")}.xlsx`;
      link.click();

      URL.revokeObjectURL(link.href);
    } catch (error) {
      toastMessage("error", "Erro ao gerar relatório");
    }
  };

  return (
    <>
      <Support>
      {loading ? (
        <SkeletonDefaultPage />
      ) : (
        <C.Container>
          <ModalInfoGerador
            openModal={openModal}
            handleCloseModal={handleCloseModal}
            usina={usina}
            createNewLog={createNewLog}
            logs={logs}
            editLog={editLog}
            load={load}
            downloadFunCtionCustom={downloadContract}
            access={!!geradorAccess}
          />
          <ModalContratar
            openModalContratar={openModalModalQuestionFormType}
            handleCloseModalContratar={handleCloseModalQuestionFormType}
            callback={redirectTypeForm}
          />

          {geradorAccess ? (
            <D.DataArea $align="right">
              <Datatable
                sectionName={sectionNameListView}
                handleButton={() => setOpenModalModalQuestionFormType(true)}
                hasButton={!geradorAccess}
                titleButton="Novo Gerador"
                columns={columns}
                rows={rows}
                pageNumber={page}
                pageSize={pageSize}
                onChangePage={(e: number) => handleChangePage(e)}
                onChangePageSize={(e: number) => handleChangePageSize(e)}
                totalPages={totalPages}
                onSearch={handleSearch}
                loadData={loadData}
              />
            </D.DataArea>
          ) : (
            <ListView<EnumStatusContratoGerador>
              sectionName={sectionNameListView}
              hasButtonSendClickSign={true}
              hasOtherStatus={true}
              statusContratoBuilder={enumStatusContratoGeradorMap}
              data={statusUsina}
              isNotSendClickSign={isNotSendClickSign}
              getSendClickSign={setIsNotSendClickSign}
              getFilterStatus={setFilterStatusContrato}
              filterStatusContrato={filterStatusContrato}
              hasSecondaryValues={true}
              hasThridValues={!geradorId}

            >
              <D.DataArea $align="right">
                <Datatable
                  handleButton={() => setOpenModalModalQuestionFormType(true)}
                  sectionName={sectionNameListView}
                  hasButton={!geradorAccess}
                  hasSearch={true}
                  titleButton="Novo Gerador"
                  columns={columns}
                  rows={rows}
                  pageNumber={page}
                  pageSize={pageSize}
                  onChangePage={(e: number) => handleChangePage(e)}
                  onChangePageSize={(e: number) => handleChangePageSize(e)}
                  totalPages={totalPages}
                  onSearch={handleSearch}
                  statusLoading={statusLoading}
                  loadData={loadData}
                  hasFilter={true}
                />
                <C.LineBreak />
                <C.ExportButton
                  variant="contained"
                  onClick={gerarRelatorio}
                  startIcon={
                    <img
                      style={{ width: "20px", color: "white", fill: "white" }}
                      src={ExcelIcon}
                      alt="excelIcon"
                    />
                  }
                >
                  Gerar relatório
                </C.ExportButton>
              </D.DataArea>
            </ListView>
          )}
        </C.Container>
      )}
      </Support>
    </>
  );
};

export default Gerador;
