import {IConexaoContaProposta} from "../../../../../models/GDProposta";
import {AbstractValidation, Validate} from "../../../../../utils/AbstractValidation";

export class ConexaoContaValidate extends AbstractValidation<IConexaoContaProposta> {
  getValidate(): Validate<IConexaoContaProposta>[] {
    return [
      {
        field: "conexao",
        validate(value, model: IConexaoContaProposta, addError: (message: string) => void) {
          if (!value.toString()) addError("A conexão deve ser informada")
        }
      },
      {
        field: "tarifaDistribuidora",
        validate(value, model: IConexaoContaProposta, addError: (message: string) => void) {
          if (!value) addError("A tarifa distribuidora deve ser informada")
        }
      },
      {
        field: "consumoMensalkWh",
        validate(value, model: IConexaoContaProposta, addError: (message: string) => void) {
          if (!value) addError("O consumo mensal de KWH deve ser informado")
        }
      },
      {
        field: "valorConta",
        validate(value, model: IConexaoContaProposta, addError: (message: string) => void) {
          if (!value) addError("O valor da conta deve ser informada")
        }
      },
      {
        field: "iluminacaoPublica",
        validate(value, model: IConexaoContaProposta, addError: (message: string) => void) {
          if (!value) addError("A iluminação pública deve ser informada")
        }
      },
      {
        field: "tributosPisCofins",
        validate(value, model: IConexaoContaProposta, addError: (message: string) => void) {
          if (!value) addError("O tributo pis confins deve ser informado")
        }
      },
      {
        field: "valorDesconto",
        validate(value, model: IConexaoContaProposta, addError: (message: string) => void) {
          if (!value) addError("O valor de desconto deve ser informado")
          else if(parseFloat(value) < 20) addError("O valor de desconto deve ser maior ou igual a 20%")
        }
      },
      {
        field: "conexao",
        validate(value, model: IConexaoContaProposta, addError: (message: string) => void) {
          if (!value.toString()) addError("A conexão deve ser informada")
        }
      },
      {
        field: "distribuidoraId",
        validate(value, model: IConexaoContaProposta, addError: (message: string) => void) {
          if (!value) addError("A distribuidora deve ser informada")
        }
      },
      {
        field: "numeroInstalacao",
        validate(value, model: IConexaoContaProposta, addError: (message: string) => void) {
          if (!value) addError("O número de instalação deve ser informado")
        }
      },
    ]
  }
}