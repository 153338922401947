import {EnumStatusBuscaFatura} from "../enums/EnumStatusBuscaFatura";
import {ColorType} from "../types/ColorType";

export interface IBuscaFatura {
  id: number;
  titular: string;
  gd: string;
  instalacao: string;
  valor: string;
  referente: string;
  vencimento: string;
  recebimento: string;
  valorQuitado: number;
  jurosMultas: number;
  status: EnumStatusBuscaFatura;
}

export interface ICreateBuscaFatura {
  nome: string;
  cpf: string;
  telefone: string;
  email: string;
}

export const EnumStatusBuscaFaturaColorMap: Record<number, ColorType> = {
  0: "blue",
  1: "yellow",
  2: "green",
  3: "red",
};