import { CircularProgress, Tooltip } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SkeletonCreationCards } from "../../../../components/SkeletonLoads/CreationCards";
import { IFullGDContratoCliente } from "../../../../models/GDContrato";
import * as service from "../../../../services/api/GDContratoClienteService";
import * as D from "../../../../styles/appComponents";
import * as C from "./style";
import InformacoesContratoPJContratar from "../components/cnpj/InformacoesContratoPJ";
import InformacoesResponsavelLegal from "../components/cnpj/informacoesResponsavelLegal";
import EnderecoContratar from "../components/cpf/Endereco";
import InformacoesContratoPFContratar from "../components/cpf/InformacoesContratoPF";
import DadosContato from "../components/DadosContato";
import InformacoesTitularContratar from "../components/InformacoesTitular";
import OutrasInformacoesContratar from "../components/OutrasInformacoes";
import PreferenciaContratar from "../components/Preferencia";
import { toastMessage } from "../../../../utils/toastMessage";
import { PessoaType } from "../../../../types/PessoaType";

const EditarContrato = () => {
  //region Variáveis
  const { idContrato } = useParams();
  const [clickSignLoading, setClickSignLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<IFullGDContratoCliente>({
    isPF: false,
    id: "",
    idReferencial: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
    assinaturaDigitalId: "",
    dadosContato: undefined,
    endereco: undefined,
    informacoesContrato: undefined,
    informacoesTitularPF: undefined,
    informacoesTitularPJ: undefined,
    outrasInformacoes: undefined,
    preferencias: undefined,
    responsavelLegal: undefined,
  });
  const [tipo, setTipo] = useState<PessoaType | undefined>(undefined);
  //endregion

  //region Services
  const getFullContrato = async (id: string): Promise<void> => {
    try {
      const [response] = await Promise.all([service.GetFullContratoById(id)]);
      setTipo(response.data.isPF ? "pessoaFisica" : "pessoaJuridica");
      setData(response.data);
      setLoading(false);
    } catch (e: unknown) {
      const error = e as AxiosError;
      toastMessage(
        "error",
        error.response
          ? String(error.response?.data)
          : "Ocorreu um erro ao buscar o contrato"
      );
    }
  };

  const EnviaContratoPraAssinaturaDigital = async () => {
    setClickSignLoading(true);

    await service
      .VerificaPodeEnviarContratoParaClickSign(idContrato!)
      .then(async () => {
        EnviaContratoPraClickSign();
      })
      .catch((e: AxiosError) => {
        setClickSignLoading(false);
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao enviar o contrato para a ClickSign."
        );
      });
  };

  const EnviaContratoPraClickSign = async () => {
    await service
      .EnviaContratoParaClickSign(idContrato!)
      .then(async () => {
        setClickSignLoading(false);
        toastMessage("success", "Enviado com sucesso!");
      })
      .catch((e: AxiosError) => {
        setClickSignLoading(false);
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao enviar o contrato para a ClickSign."
        );
      });
  };
  //endregion

  //region UI
  useEffect((): void => {
    getFullContrato(idContrato!);
  }, []);
  //endregion

  return (
    <>
      {loading ? (
        <SkeletonCreationCards />
      ) : (
        <C.Container>
          <InformacoesTitularContratar
            id={idContrato!}
            type={tipo!}
            dataContrato={data}
            edit={true}
          />
          {tipo === "pessoaFisica" ? (
            <>
              <EnderecoContratar
                id={idContrato!}
                edit={true}
                dataContrato={data.endereco}
              />
              <InformacoesContratoPFContratar
                id={idContrato!}
                edit={true}
                dataContrato={data.informacoesContrato}
              />
            </>
          ) : (
            <>
              <InformacoesContratoPJContratar
                id={idContrato!}
                edit={true}
                dataContrato={data.informacoesContrato}
              />
              <InformacoesResponsavelLegal
                id={idContrato!}
                edit={true}
                dataContrato={data.responsavelLegal}
              />
            </>
          )}
          <OutrasInformacoesContratar
            id={idContrato!}
            edit={true}
            dataContrato={data.outrasInformacoes}
          />
          {data.assinaturaDigitalId && (
            <PreferenciaContratar
              id={idContrato!}
              dataContrato={data.preferencias}
            />
          )}
          {data.dadosContato?.usuarioId && (
            <DadosContato
              id={idContrato!}
              dataContrato={data.dadosContato}
              isSigned={
                data.assinaturaDigitalId != null &&
                data.assinaturaDigitalId != ""
              }
            />
          )}
          <D.FWStack justifyContent={"flex-end"} direction={"row"} spacing={2}>
            {data.assinaturaDigitalId != null &&
            data.assinaturaDigitalId != "" ? (
              <Tooltip title="Contrato já enviado para assinatura digital">
                <span>
                  <D.ContainedButton $color="lightpink" disabled={true}>
                    Gerar contrato
                  </D.ContainedButton>
                </span>
              </Tooltip>
            ) : (
              <D.ContainedButton
                $color="lightpink"
                onClick={EnviaContratoPraAssinaturaDigital}
              >
                {clickSignLoading ? (
                  <CircularProgress size={25} />
                ) : (
                  <>Gerar contrato</>
                )}
              </D.ContainedButton>
            )}
          </D.FWStack>
        </C.Container>
      )}
    </>
  );
};

export default EditarContrato;
