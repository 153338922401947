import styled from "styled-components";

export const Title = styled.h2`
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  color: #222222;

  @media (max-width: 1034px) {
    cursor: pointer;
  }
`;

export const StatusArea = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  flex-wrap: wrap;
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-basis: 18%;
  flex-direction: column;
  gap: 16px;
`;
