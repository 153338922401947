import { FC, useState } from "react";
import RegisterCard from "../RegisterCard";
import * as C from "../../pages/private/Associados/EditarAssociado/style";
import { TextField } from "@mui/material";
import MaskDefaultInput from "../MasDefaultinput/MaskDefaultInput";
import { ICepReturn } from "../../models/IbgeService";
import { ICaptador, ICaptadorDadosContrato } from "../../models/Captador";
import { ContainedButton } from "../../styles/appComponents";
import { DadosContratoValidate } from "./DadosContratoValidate";
import useValidate from "../../hooks/useValidate";

interface IDadosContratoInputProps {
  captador: ICaptador;
  updateAssociadoDadosContrato: (
    updateDadosContrato: ICaptadorDadosContrato
  ) => Promise<boolean>;
  disabled?: boolean;
}

const obterDadosContratoInicial = (captador: ICaptador) => ({
  captadorId: captador.id ?? "",
  razaoSocial: captador.razaoSocial ?? "",
  cnpj: captador.cnpj ?? "",
  cep: captador.cep ?? "",
  uf: captador.uf ?? "",
  cidade: captador.cidade ?? "",
  bairro: captador.bairro ?? "",
  logradouro: captador.logradouro ?? "",
  numero: captador.numero ?? "",
  complemento: captador.complemento ?? "",
});

const DadosContratoInput: FC<IDadosContratoInputProps> = ({
  captador,
  updateAssociadoDadosContrato,
  disabled = false,
}) => {
  const [dadosContrato, setDadosContrato] = useState<ICaptadorDadosContrato>(
    obterDadosContratoInicial(captador)
  );

  const [salvandoDados, setSalvandoDados] = useState<boolean>(false);

  const [editar, setEditar] = useState<boolean>(false);

  const editarDadosContrato = (novosDados: ICaptadorDadosContrato) => {
    if (editar) setDadosContrato(novosDados);
  };

  const dadosContratoValidate = useValidate(DadosContratoValidate);

  const handleSalvarAssociado = async () => {
    setSalvandoDados(true);

    const temErro = dadosContratoValidate.executeErrorMessage(dadosContrato);
    if (!temErro) {
      const res = await updateAssociadoDadosContrato(dadosContrato);
      setEditar(res === false);
    }
    setSalvandoDados(false);
  };

  return (
    <RegisterCard title="Dados do contrato">
      <C.FWStack direction={"row"} spacing={2}>
        {captador?.id ? `ID - ${captador.idReferencial}` : ""}
      </C.FWStack>
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Razão social"
          required
          fullWidth
          onChange={(e) =>
            editarDadosContrato({
              ...dadosContrato,
              razaoSocial: e.target.value,
            })
          }
          value={dadosContrato.razaoSocial}
          disabled={!editar || disabled}
          {...dadosContratoValidate.addValidateCampo(
            "razaoSocial",
            dadosContrato
          )}
        />
        <MaskDefaultInput
          label="CNPJ"
          type="CNPJ"
          onChange={(cnpj) =>
            editarDadosContrato({ ...dadosContrato, cnpj: cnpj as string })
          }
          value={dadosContrato.cnpj ?? ""}
          disabled={!editar || disabled}
          {...dadosContratoValidate.addValidateCampo("cnpj", dadosContrato)}
        />
        <MaskDefaultInput
          label="CEP"
          type="CEP"
          onChange={(cep) => {
            const cepReturn = cep as ICepReturn;
            editarDadosContrato({
              ...dadosContrato,
              ...cepReturn,
              cep: cepReturn.value,
              cidade: cepReturn.localidade ?? dadosContrato.cidade,
            });
          }}
          value={dadosContrato.cep ?? ""}
          disabled={!editar || disabled}
          {...dadosContratoValidate.addValidateCampo("cep", dadosContrato)}
        />
        <TextField
          required
          label="UF"
          onChange={(e) =>
            editarDadosContrato({ ...dadosContrato, uf: e.target.value })
          }
          value={dadosContrato.uf}
          disabled={!editar || disabled}
          {...dadosContratoValidate.addValidateCampo("uf", dadosContrato)}
        />
      </C.FWStack>
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Cidade"
          required
          fullWidth
          onChange={(e) =>
            editarDadosContrato({ ...dadosContrato, cidade: e.target.value })
          }
          value={dadosContrato.cidade}
          disabled={!editar || disabled}
          {...dadosContratoValidate.addValidateCampo("cidade", dadosContrato)}
        />
        <TextField
          label="Bairro"
          required
          fullWidth
          onChange={(e) =>
            editarDadosContrato({ ...dadosContrato, bairro: e.target.value })
          }
          value={dadosContrato.bairro}
          disabled={!editar || disabled}
          {...dadosContratoValidate.addValidateCampo("bairro", dadosContrato)}
        />
        <TextField
          label="Logradouro"
          required
          fullWidth
          onChange={(e) =>
            editarDadosContrato({
              ...dadosContrato,
              logradouro: e.target.value,
            })
          }
          value={dadosContrato.logradouro}
          disabled={!editar || disabled}
          {...dadosContratoValidate.addValidateCampo(
            "logradouro",
            dadosContrato
          )}
        />
        <TextField
          label="Número"
          type="number"
          required
          sx={{ width: "40%" }}
          onChange={(e) =>
            editarDadosContrato({ ...dadosContrato, numero: e.target.value })
          }
          value={dadosContrato.numero}
          disabled={!editar || disabled}
          {...dadosContratoValidate.addValidateCampo("numero", dadosContrato)}
        />
        <TextField
          label="Complemento"
          fullWidth
          onChange={(e) =>
            editarDadosContrato({
              ...dadosContrato,
              complemento: e.target.value,
            })
          }
          value={dadosContrato.complemento}
          disabled={!editar || disabled}
          {...dadosContratoValidate.addValidateCampo(
            "complemento",
            dadosContrato
          )}
        />
      </C.FWStack>
      <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <ContainedButton
          $color="yellow"
          onClick={() => setEditar(true)}
          disabled={editar || salvandoDados || disabled}
        >
          Editar
        </ContainedButton>
        <ContainedButton
          $color="pink"
          onClick={handleSalvarAssociado}
          disabled={
            !editar || salvandoDados || disabled || dadosContratoValidate.error
          }
        >
          Salvar
        </ContainedButton>
      </C.FWStack>
    </RegisterCard>
  );
};

export default DadosContratoInput;
