import { z } from "zod";
import { ObterEnum } from "../utils/Utils";
import { EnumStatusContratoParceiro } from "../enums/EnumStatusContratoParceiro.enum";

export const TotalStatusContratoParceiroSchema = z.record(
  z
    .string()
    .refine((x) => /^\d+$/.test(x), { message: "A chave não é um número" })
    .transform((x) => ObterEnum(EnumStatusContratoParceiro, Number(x)))
    .refine((x) => x != null, { message: "Enum não encontrado" })
    .transform((x) => x),
  z.number()
);

export type TotalStatusContratoParceiro = z.output<
  typeof TotalStatusContratoParceiroSchema
>;
