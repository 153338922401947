import http from "../common/http-common";
import {IPaginatedList} from "../../models/PaginatedList";
import {IBuscaFatura, ICreateBuscaFatura} from "../../models/BuscaFatura";
import {AxiosResponse} from "axios";
import {StatusDashboard} from "../../models/StatusDashboard";
import {EnumStatusBuscaFatura} from "../../enums/EnumStatusBuscaFatura";
import {IEditarFatura} from "../../pages/private/BuscaFatura/EditarFatura";

export const GetBuscaFaturas: (pageNumber: number, pageSize: number, search?: string, statusBuscaFatura?: (EnumStatusBuscaFatura | null))
  => Promise<AxiosResponse<IPaginatedList<IBuscaFatura>>> = (
  pageNumber: number,
  pageSize: number,
  search?: string,
  statusBuscaFatura?: EnumStatusBuscaFatura | null,
) => {
  let url = "/BuscaFatura";
  const params = [];

  if (pageSize) params.push(`PageSize=${pageSize}`);
  if (pageNumber) params.push(`PageNumber=${pageNumber}`);
  if (search) params.push(`Search=${search}`);
  if (statusBuscaFatura !== null) params.push(`StatusBuscaFatura=${statusBuscaFatura}`);

  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  return http.get<IPaginatedList<IBuscaFatura>>(url);
};

export const GetStatusBuscaFaturas: () => Promise<AxiosResponse<StatusDashboard<EnumStatusBuscaFatura>[]>> = () => {
  return http.get<StatusDashboard<EnumStatusBuscaFatura>[]>("/BuscaFatura/ContaDashboard");
};

export const GetBuscaFaturaById: (id: string) => Promise<AxiosResponse<IEditarFatura>> = (id: string) => {
  return http.get<IEditarFatura>(`/BuscaFatura/${id}`);
};

export const GeraRelatorio: () => Promise<AxiosResponse<File>> = () => {
  return http.get<File>(
    `/BuscaFatura/BuscaRelatorioBuscaFaturas`,
    {
      responseType: "blob",
    }
  );
};

export const CreateBuscaFatura: (createBuscaFaturaData: ICreateBuscaFatura) => Promise<AxiosResponse<ICreateBuscaFatura>> = (createBuscaFaturaData: ICreateBuscaFatura) => {
  return http.post<ICreateBuscaFatura>(`/BuscaFatura`, createBuscaFaturaData);
};

export const UpdateBuscaFatura: (updateBuscaFaturaData: ICreateBuscaFatura) => Promise<AxiosResponse<ICreateBuscaFatura>> = (updateBuscaFaturaData: ICreateBuscaFatura) => {
  return http.put<ICreateBuscaFatura>(`/BuscaFatura`, updateBuscaFaturaData);
};