import {AbstractValidation, Validate} from "../../../../../utils/AbstractValidation";
import {ICreateCargo} from "../../Modais/ModalCargo";

export class ModalCargoValidate extends AbstractValidation<ICreateCargo> {
  getValidate(): Validate<ICreateCargo>[] {
    return [
      {
        field: "cargo",
        validate(value, model: ICreateCargo, addError: (message: string) => void) {
          if (!value) addError("O cargo deve ser informado")
        }
      },
      {
        field: "setor",
        validate(value, model: ICreateCargo, addError: (message: string) => void) {
          if (!value) addError("O setor deve ser informado")
        }
      },
      {
        field: "cbo",
        validate(value, model: ICreateCargo, addError: (message: string) => void) {
          if (!value) addError("O CBO deve ser informado")
        }
      },
    ]
  }
}