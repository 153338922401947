import Swal from "sweetalert2";

export const toastMessage = (
  type: "success" | "warning" | "error",
  title: string,
  text?: string
) => {
  switch (type) {
    case "success":
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "success",
        title: title,
        text: text,
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
      break;

    case "warning":
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: title,
        text: text,
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
      break;

    default:
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "error",
        title: title,
        text: text,
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
      break;
  }
};
