import { ICreateAssociado } from "../../../../models/Associado";
import { FormatoLogo } from "../../../../models/Logo";
import { IUpdateResponsavelLegal } from "../../../../models/ResponsavelLegal";

const DESCONTO_DEFAUL = 25;

export const CREATE_ASSOCIADO_DEFAUL: ICreateAssociado = {
  razaoSocial: "",
  cnpj: "",
  cep: "",
  uf: "",
  cidade: "",
  bairro: "",
  logradouro: "",
  numero: "",
  complemento: "",
  limiteDesconto: DESCONTO_DEFAUL,
};

export const CREATE_RESPONSAVEL_LEGAL_DEFAULT: IUpdateResponsavelLegal = {
  nome: "",
  enumEstadoCivil: null,
  nacionalidade: "",
  profissao: "",
  email: "",
  celular: "",
  countryCode: "+55",
  cpf: "",
  cep: "",
  uf: "",
  cidade: "",
  bairro: "",
  numero: "",
  complemento: "",
  logradouro: "",
  dataNascimento: null,
  ativo: true,
};

export const TIPOS_VALIDOS_INPUT_LOGO: Record<FormatoLogo, string> = {
  svg: "image/svg+xml",
  png: "image/png",
  pdf: "application/pdf",
};
