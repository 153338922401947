import {IGetCalculoParams, IDadosPessoaisProposta} from "../../../../../models/GDProposta";
import {AbstractValidation, Validate} from "../../../../../utils/AbstractValidation";

export class GetCalculoValidate extends AbstractValidation<IGetCalculoParams> {
  getValidate(): Validate<IGetCalculoParams>[] {
    return [
      {
        field: "tarifaDistribuidora",
        validate(value, model: IGetCalculoParams, addError: (message: string) => void) {
          if (!value) addError("A tarifa distribuidora deve ser informada")
        }
      },
      {
        field: "consumoMensalkWh",
        validate(value, model: IGetCalculoParams, addError: (message: string) => void) {
          if (!value) addError("O consumo mensal de KWH deve ser informado")
        }
      },
      {
        field: "valorConta",
        validate(value, model: IGetCalculoParams, addError: (message: string) => void) {
          if (!value) addError("O valor da conta deve ser informada")
        }
      },
      {
        field: "iluminacaoPublica",
        validate(value, model: IGetCalculoParams, addError: (message: string) => void) {
          if (!value) addError("A iluminação pública deve ser informada")
        }
      },
      {
        field: "percentualDesconto",
        validate(value, model: IGetCalculoParams, addError: (message: string) => void) {
          if (!value) addError("O percentual de desconto deve ser informado")
          else if(value < 20) addError("O percentual de desconto deve ser maior ou igual a 20%")
        }
      },
      {
        field: "pisConfins",
        validate(value, model: IGetCalculoParams, addError: (message: string) => void) {
          if (!value) addError("O pis confins deve ser informado")
        }
      },
      {
        field: "tipoConexao",
        validate(value, model: IGetCalculoParams, addError: (message: string) => void) {
          if (!value) addError("O tipo de conexão deve ser informado")
        }
      },
    ]
  }
}