import SearchIcon from "@mui/icons-material/Search";
import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { GridColDef, GridRowIdGetter, GridToolbarFilterButton } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import * as D from "../../styles/appComponents";
import * as C from "./style";
import React, { Dispatch, useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DataTableFilter } from "../DataTableFilter";
import { InputFilterModel } from "../../models/InputFilterModel";

interface DatatableProps {
  hasButton?: boolean;
  hasSearch?: boolean;
  columns: GridColDef[];
  rows: any;
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  onChangePage?: (value: number) => void;
  onChangePageSize?: (value: number) => void;
  onSearch?: (value: string) => void;
  handleButton?: () => void;
  titleButton?: string;
  disabledButton?: boolean;
  getRowId?: GridRowIdGetter;
  titulo?: string;
  ocultarSelectPageSize?: boolean;
  statusLoading?:{sectionName:string, loading:boolean}
  sectionName?:string;
  loadData?:(model:InputFilterModel | null, type:"clear"|"filter") => void;
  hasFilter?: boolean;
}

const Datatable: React.FC<DatatableProps> = ({
  hasButton = false,
  hasSearch = true,
  columns,
  rows,
  pageNumber,
  pageSize,
  totalPages,
  onChangePage,
  onChangePageSize,
  onSearch,
  handleButton,
  titleButton,
  disabledButton = false,
  getRowId,
  titulo,
  ocultarSelectPageSize = false,
  statusLoading,
  sectionName,
  loadData,
  hasFilter
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null = null,
    value: number
  ) => {

    if (onChangePage) onChangePage(value);
  };

  const handleChangePageSize = (value: number) => {
    handleChangePage(null, 1)
    if (onChangePageSize) onChangePageSize(value);
  };
  const handleSearch = () => {
    setLoading(true);
    if (onSearch) onSearch(search);
  };

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [rows]);

  const applyFilter = (model: InputFilterModel | null, type:"clear"|"filter") => {
    if(loadData)
      loadData(model, type);
  };

  return (
    <C.Wrapper>
      {hasSearch && (
        <C.Header>
          {titulo && <Typography variant="h6">{titulo}</Typography>}
          {ocultarSelectPageSize !== true && (
            <Select
              size="small"
              value={pageSize}
              onChange={(e) => handleChangePageSize(Number(e.target.value))}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          )}
          <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"right"}
            spacing={1}
          >
            {hasButton && (
              <Stack sx={{ width: "25%" }}>
                <D.ContainedButton
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleButton}
                  disabled={disabledButton}
                >
                  {titleButton}
                </D.ContainedButton>
              </Stack>
            )}
          </Stack>
        </C.Header>
      )}

      <>
        {!isMobile ? (
          <>
            <DataTableFilter
              columnsTable={columns}
              rows={rows}
              applyFilter={applyFilter}
              handleChangePageSize={handleChangePageSize}
              getRowId={getRowId}
              statusLoading={statusLoading}
              sectionName={sectionName}
              hasFilter={hasFilter}
            />
            <D.DefaultPagination
              page={pageNumber}
              count={totalPages}
              size={"medium"}
              siblingCount={1}
              showFirstButton
              showLastButton
              onChange={handleChangePage}
              color="primary"
            />
          </>
        ) : (
          <>
            <D.CardDataAreaMobile>
              {rows.map((row: any, index: number) => (
                <D.CardDataMobile key={index}>
                  {columns.map((column: any, index: number) => (
                    <D.CardDataItemMobile key={index}>
                      <label>{column.headerName}:</label>
                      <p>
                        {column.renderCell
                          ? column?.renderCell({
                            value: row[column.field],
                            row,
                          })
                          : row[column.field]}
                      </p>
                    </D.CardDataItemMobile>
                  ))}
                </D.CardDataMobile>
              ))}
            </D.CardDataAreaMobile>
            <D.DefaultPagination
              page={pageNumber}
              count={totalPages}
              size={"medium"}
              siblingCount={1}
              showFirstButton
              showLastButton
              onChange={handleChangePage}
              color="primary"
            />
          </>
        )}
      </>
    </C.Wrapper>
  );
};

export default Datatable;
