import RegisterCard from "../../../../../components/RegisterCard";
import * as C from "../style";
import * as D from "../../../../../styles/appComponents";
import {Chip, MenuItem, TextField} from "@mui/material";
import {EnumTipoChavePix} from "../../../../../enums/Gerador/EnumTipoChavePix.enum";
import {IInformacaoContrato} from "../../../../../models/Gerador/Cadastro/InformacaoContrato";
import {FC, RefObject, useEffect, useState} from "react";
import {ErrorMessage} from "../../../../../utils/AbstractValidation";
import {EnumTipoPessoa, enumTipoPessoaEnumMap} from "../../../../../enums/Gerador/EnumTipoPessoa.enum";
import {EnumTipoTarifa} from "../../../../../enums/Gerador/EnumTipoTarifa.enum";
import {
  EnumStatusContratoGerador,
  enumStatusContratoGeradorMap,
} from "../../../../../enums/Gerador/EnumStatusContratoGerador.enum";
import {EnumMedidaCorrente} from "../../../../../enums/Gerador/EnumMedidaCorrente.enum";
import {EnumTipoConexao} from "../../../../../enums/Gerador/EnumTipoConexao.enum";
import {UsinaTipoPessoaFisica, UsinaTipoPessoaJuridica} from "../../../../../models/Gerador/Usina";
import {IDistribuidoraListItem} from "../../../../../models/Distribuidora";
import {MaskCurrencyInput} from "../../../../../components/MakCurrencyInput";
import {calcValues, formatValues, numberForExtensive} from "../../../../../utils/moneyFormatter";
import {SkeletonSectionForm} from "../../../../../components/SkeletonLoads/SectionForm";
import {iResponsavelIndicacao} from "../../../../../models/Gerador/Cadastro/ResponsavelIndicacao";
import {CircularSpinner} from "../../../../../components/CircularSpinner";
import {InformacaoContratoEnum} from "../../../../../enums/Gerador/EnumInputModel.ts/InformacaoContratoEnum";
import {handleStyleFieldError} from "../../../../../utils/verifyFieldError";
import {IResponsavelUsina} from "../../../../../models/Gerador/Cadastro/ResponsavelUsina";
import {ICreateResponsavelLegal} from "../../../../../models/ResponsavelLegal";

export interface PropsInformacaoContrato {
  onSave: (data: IInformacaoContrato) => void;
  onChange: (data: IInformacaoContrato) => void;
  onUpdate: (data: IInformacaoContrato) => void;
  onGenerate: (data: any) => void;
  onDistrato: (data: IInformacaoContrato) => void;
  buttonSave: boolean;
  fieldErros: ErrorMessage[] | null;
  distribuidoras: IDistribuidoraListItem[];
  children: any;
  geradorId: string | null | undefined;
  usina: UsinaTipoPessoaJuridica | UsinaTipoPessoaFisica | undefined;
  scrollRef: RefObject<HTMLDivElement> | null;
  uuidContratoGenerated: string | null;
  generateScroll: boolean;
  buttonSaveGenerate: boolean;
  tipoFormulario: string | null;
  load: { status:boolean, section:string },
  responsavelIndicacaos: iResponsavelIndicacao[] | undefined;
  temContrato: boolean;
  dadosUsina?: any;
  responsavelUsina?: IResponsavelUsina | undefined;
  responsavelLegal?: ICreateResponsavelLegal[] | undefined;
  areaAccess: boolean;
}

export const InformacaoContrato: FC<PropsInformacaoContrato> = ({
  onSave,
  onChange,
  onUpdate,
  onDistrato,
  onGenerate,
  buttonSave,
  fieldErros,
  distribuidoras,
  children,
  geradorId,
  usina,
  scrollRef,
  uuidContratoGenerated,
  generateScroll,
  buttonSaveGenerate,
  tipoFormulario,
  load,
  responsavelIndicacaos,
  temContrato,
  dadosUsina,
  responsavelUsina,
  responsavelLegal,
  areaAccess
}) => {
  const formInformacaoContrato: IInformacaoContrato = {
    tipopessoa: EnumTipoPessoa[tipoFormulario as keyof typeof EnumTipoPessoa],
    distribuidora: null,
    tipotarifa: 0,
    porcentagemdescontotarifa: null,
    tarifavigentecontrato: null,
    tarifagerador: null,
    potencia: null,
    potenciaporextenso: null,
    medidacorrente: 0,
    performacealvo: null,
    custofixo: undefined,
    custofixoextenso: null,
    vigenciacontratual: null,
    datalimiteconexao: undefined,
    tipoconexao: 0,
    statuscontratogerador: EnumStatusContratoGerador.aguardandoAssinatura,
    dataassinaturacontrato: null,
    uuidcontrato: null,
    agenciaresponsavelindicacao: null,
    favorecidoresponsavelindicacao: null,
    contaresponsavelindicacao: null,
    bancoresponsavelindicacao: null,
    cnpjfavorecidoresponsavelindicacao: null,
    chavepixresponsavelindicacao: null,
    tipochavepixresponsavelindicacao: 0,
    custofixoporextenso: null,
    pontenciaporextenso: null,
    responsavelIndicacaos:[]
  };

  const [informacaoContrato, setInformacaoContrato] = useState<IInformacaoContrato>(formInformacaoContrato);
  const sendOnChange = (update: IInformacaoContrato): void => onChange({...update, responsavelIndicacaos});
  const sendOnSave = () => onSave({...informacaoContrato, responsavelIndicacaos});
  const sendOnUpdate = () => onUpdate(informacaoContrato);
  const newData = tipoFormulario === "pessoaJuridica"?
  responsavelLegal?.[0]: responsavelUsina
  const sendOnGenerate = () => onGenerate(
    {
      ...informacaoContrato, 
      nomeresponsavel: tipoFormulario === "pessoaJuridica"?
      (usina as UsinaTipoPessoaJuridica)?.responsavelLegal?.[0]?.nome: 
      (usina as UsinaTipoPessoaFisica)?.nomeResponsavelUsina,
      celularresponsavel: tipoFormulario === "pessoaJuridica"?
      (usina as UsinaTipoPessoaJuridica)?.responsavelLegal?.[0]?.celular: 
      (usina as UsinaTipoPessoaFisica)?.celularResponsavelUsina,
      cpfresponsavel: tipoFormulario === "pessoaJuridica"?
      (usina as UsinaTipoPessoaJuridica)?.responsavelLegal?.[0]?.cpf: 
      (usina as UsinaTipoPessoaFisica)?.cpfResponsavelUsina,
      datanascimentoresponsavel: tipoFormulario === "pessoaJuridica"?
      (usina as UsinaTipoPessoaJuridica)?.responsavelLegal?.[0]?.dataNascimento: 
      (usina as UsinaTipoPessoaFisica)?.dataNascimento,
      cepresponsavel: tipoFormulario === "pessoaJuridica"?
      (usina as UsinaTipoPessoaJuridica)?.responsavelLegal?.[0]?.cep: 
      (usina as UsinaTipoPessoaFisica)?.cepResponsavelUsina,
      ufresponsavel: tipoFormulario === "pessoaJuridica"?
      (usina as UsinaTipoPessoaJuridica)?.responsavelLegal?.[0]?.uf: 
      (usina as UsinaTipoPessoaFisica)?.ufResponsavelUsina,
      cidaderesponsavel: tipoFormulario === "pessoaJuridica"?
      (usina as UsinaTipoPessoaJuridica)?.responsavelLegal?.[0]?.cidade: 
      (usina as UsinaTipoPessoaFisica)?.cidadeResponsavelUsina,
      bairroresponsavel: tipoFormulario === "pessoaJuridica"?
      (usina as UsinaTipoPessoaJuridica)?.responsavelLegal?.[0]?.bairro: 
      (usina as UsinaTipoPessoaFisica)?.bairroResponsavelUsina,
      numeroresponsavel: tipoFormulario === "pessoaJuridica"?
      (usina as UsinaTipoPessoaJuridica)?.responsavelLegal?.[0]?.numero: 
      (usina as UsinaTipoPessoaFisica)?.numeroResponsavelUsina,
      logradouroresponsavel: tipoFormulario === "pessoaJuridica"?
      (usina as UsinaTipoPessoaJuridica)?.responsavelLegal?.[0]?.logradouro: 
      (usina as UsinaTipoPessoaFisica)?.logradouroResponsavelUsina,
    }
  );

  const sendOnDistrato = () => {
    handleInputChange(
      { 
        target:{ 
          name:InformacaoContratoEnum.STATUSCONTRATOGERADOR, 
          value: EnumStatusContratoGerador.aguardandoAssinaturaDistrato 
        } 
      })

    return onDistrato(informacaoContrato)
  }

  useEffect(() => {
    if (geradorId) {
      const formInformacaoContrato: IInformacaoContrato = {
        tipopessoa: usina?.tipoPessoa as EnumTipoPessoa,
        distribuidora: usina?.distribuidoraId || null,
        tipotarifa: usina?.tipoTarifa as EnumTipoTarifa,
        porcentagemdescontotarifa: usina?.porcentagemDescontoTarifa || null,
        tarifavigentecontrato: usina?.tarifaVigenteContrato || null,
        tarifagerador: usina?.tarifaGerador || null,
        potencia: usina?.potencia || null,
        potenciaporextenso: usina?.potenciaExtenso || null,
        medidacorrente: usina?.medidaCorrente as EnumMedidaCorrente,
        custofixo: usina?.custoFixo || undefined,
        custofixoextenso: usina?.custoFixoExtenso?.toString() || null,
        vigenciacontratual: usina?.vigenciaContratual || null,
        datalimiteconexao: usina?.dataLimiteConexao || undefined,
        tipoconexao: usina?.tipoConexao as EnumTipoConexao,
        performacealvo: usina?.performaceAlvo ?? null,
        statuscontratogerador:
          usina?.statusContratoGerador as EnumStatusContratoGerador,
        dataassinaturacontrato: usina?.dataAssinaturaContrato || undefined,
        uuidcontrato: usina?.uuidContrato || null,
        agenciaresponsavelindicacao: usina?.agenciaResponsavelIndicacao || null,
        favorecidoresponsavelindicacao:
          usina?.favorecidoResponsavelIndicacao || null,
        contaresponsavelindicacao: usina?.contaResponsavelIndicacao || null,
        bancoresponsavelindicacao: usina?.bancoResponsavelIndicacao || null,
        cnpjfavorecidoresponsavelindicacao:
          usina?.cnpjFavorecidoResponsavelIndicacao || null,
        chavepixresponsavelindicacao:
          usina?.chavePixResponsavelIndicacao || null,
        custofixoporextenso: usina?.custoFixoPorExtenso || null,
        pontenciaporextenso: usina?.potenciaExtenso || null,
        tipochavepixresponsavelindicacao:
          usina?.tipoChavePixResponsavelIndicacao as EnumTipoChavePix,
          responsavelIndicacaos: []
      };
      setInformacaoContrato(formInformacaoContrato);
    }
  }, [geradorId, usina]);

  const handleInputChange = (event: any): void => {
    const name = event?.target?.name?.toLocaleLowerCase();
    const value = event?.target?.value;

    setInformacaoContrato((state: any) => {
      sendOnChange({ ...state, [name]: value });
      return { ...state, [name]: value };
    });
  };

  useEffect(() => {
    handleInputChange({
      target:{
        name:InformacaoContratoEnum.CUSTOFIXOPOREXTENSO,
        value:numberForExtensive(calcValues(
          informacaoContrato?.performacealvo,
          calcValues(
            informacaoContrato?.tarifavigentecontrato, 
            informacaoContrato?.porcentagemdescontotarifa,
            "%"
          ),
          "*"
        ),"$"
      )}
    });

    handleInputChange({
      target:{
        name:InformacaoContratoEnum.POTENCIAPOREXTENSO,
        value:`${
          numberForExtensive(informacaoContrato?.potencia)} ${informacaoContrato.medidacorrente === EnumMedidaCorrente.KWCA? 
          "quilowatt em corrente alternada":"megawatt em corrente alternada"
          }`
      }
    });

  }, [
    informacaoContrato?.tarifavigentecontrato,
    informacaoContrato?.porcentagemdescontotarifa,
    informacaoContrato?.potencia,
    informacaoContrato?.medidacorrente,
    informacaoContrato?.performacealvo
  ])

  return (
      <RegisterCard title="Informações do contrato">
        {
          load.status && load.section === "informaçãoContrato"?
          <SkeletonSectionForm />
          :
          <>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              inputProps={{ readOnly: true }}
              name={InformacaoContratoEnum.TIPOPESSOA}
              onChange={handleInputChange}
              value={informacaoContrato?.tipopessoa ?? ""}
              label="Tipo de pessoa"
              required
              select
              sx={handleStyleFieldError(
                InformacaoContratoEnum.TIPOPESSOA,
                fieldErros, { width:"80%" }
              )}
            >
              {enumTipoPessoaEnumMap.map((tipo, i) => (
                <MenuItem key={i} value={tipo.value} >
                  {tipo.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              inputProps={{ readOnly: buttonSave }}
              name={InformacaoContratoEnum.DISTRIBUIDORA}
              onChange={handleInputChange}
              value={informacaoContrato?.distribuidora ?? ""}
              label="Distribuidora"
              required
              select
              sx={handleStyleFieldError(
                InformacaoContratoEnum.DISTRIBUIDORA,
                fieldErros, { width:"80%" }
              )}
            >
              {(distribuidoras || []).map((distribuidora, i) => (
                <MenuItem key={i} value={distribuidora.id}>
                  {distribuidora.nome}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              inputProps={{ readOnly: buttonSave }}
              name={InformacaoContratoEnum.TIPOTARIFA}
              onChange={handleInputChange}
              value={informacaoContrato?.tipotarifa ?? ""}
              label="Tipo de tarifa"
              required
              select
              sx={handleStyleFieldError(
                InformacaoContratoEnum.TIPOTARIFA,
                fieldErros, { width:"80%" }
              )}
            >
              {Object.entries(EnumTipoTarifa)
                .filter(([value, key]) => isNaN(Number(key)))
                .map(([value, key], i) => (
                  <MenuItem value={Number(value)}>{key}</MenuItem>
                ))}
            </TextField>
            <MaskCurrencyInput
              label="Porcentagem de desconto da tarifa"
              readonly={buttonSave}
              name={InformacaoContratoEnum.PORCENTAGEMDESCONTOTARIFA}
              onValueChange={(newValue, name, values) => {
                if (values.float > 65) newValue = "65";

                handleInputChange({ target: { name, value: newValue } });
              }}
              value={informacaoContrato?.porcentagemdescontotarifa || ""}
              placeholder="00,00%"
              defaultValue={0}
              decimalsLimit={2}
              suffix="%"
              sx={handleStyleFieldError(
                InformacaoContratoEnum.PORCENTAGEMDESCONTOTARIFA,
                fieldErros
              )}
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <MaskCurrencyInput
              label="Tarifa vigente"
              readonly={buttonSave}
              name={InformacaoContratoEnum.TARIFAVIGENTECONTRATO}
              onValueChange={(newValue, name, values) =>
                handleInputChange({ target: { name, value: newValue } })
              }
              value={informacaoContrato?.tarifavigentecontrato ?? ""}
              placeholder="00,00"
              defaultValue={0}
              decimalsLimit={2}
              sx={handleStyleFieldError(
                InformacaoContratoEnum.TARIFAVIGENTECONTRATO,
                fieldErros, { width: "30%" }
              )}
            />
            <MaskCurrencyInput
              sx={{ width: "30%" }}
              label="Tarifa do gerador"
              readonly
              name={InformacaoContratoEnum.TARIFAGERADOR}
              value={
                calcValues(
                  informacaoContrato?.tarifavigentecontrato, 
                  informacaoContrato?.porcentagemdescontotarifa,
                  "%",
                  4
                )
              }
              placeholder="0,0000"
              defaultValue={0}
              decimalsLimit={4}
            />
            <TextField
              inputProps={{ readOnly: buttonSave }}
              name={InformacaoContratoEnum.MEDIDACORRENTE}
              onChange={handleInputChange}
              value={informacaoContrato?.medidacorrente ?? ""}
              label="Medida corrente"
              select
              required
              sx={{ width: "40%" }}
            >
              {Object.entries(EnumMedidaCorrente)
                .filter(([value, key]) => isNaN(Number(key)))
                .map(([value, key]) => (
                  <MenuItem key={key} value={Number(value)}>
                    {key}
                  </MenuItem>
                ))}
            </TextField>
            <MaskCurrencyInput
              label="Potência"
              readonly={buttonSave}
              name={InformacaoContratoEnum.POTENCIA}
              onValueChange={(newValue, name, values) =>
                handleInputChange({ target: { name, value: newValue } })
              }
              value={informacaoContrato?.potencia ?? ""}
              placeholder="00,0"
              defaultValue={0}
              decimalsLimit={1}
              sx={handleStyleFieldError(
                InformacaoContratoEnum.POTENCIA,
                fieldErros, { width: "30%" }
              )}
            />
            <TextField
              type="text"
              inputProps={{ readOnly: true }}
              name={InformacaoContratoEnum.POTENCIAPOREXTENSO}
              onChange={handleInputChange}
              value={informacaoContrato?.potenciaporextenso}
              label="Potência por extenso"
              required
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <MaskCurrencyInput
              label="Performance alvo"
              readonly={buttonSave}
              name={InformacaoContratoEnum.PERFORMACEALVO}
              onValueChange={(newValue, name, values) =>
                handleInputChange({ target: { name, value: newValue } })
              }
              value={informacaoContrato?.performacealvo ?? ""}
              placeholder="00,00kwh"
              defaultValue={0}
              decimalsLimit={2}
              sx={handleStyleFieldError(
                InformacaoContratoEnum.PERFORMACEALVO,
                fieldErros, { width: "30%" }
              )}
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              sx={{ width: "50%" }}
              label="Custo fixo"
              inputProps={{ readOnly: buttonSave }}
              name={InformacaoContratoEnum.CUSTOFIXO}
              value={
                formatValues(calcValues(
                  informacaoContrato?.performacealvo,
                  calcValues(
                    informacaoContrato?.tarifavigentecontrato, 
                    informacaoContrato?.porcentagemdescontotarifa,
                    "%"
                  ),
                  "*"
                ))
              ??""}
              placeholder="R$ 00,00"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              inputProps={{ readOnly: buttonSave }}
              name={InformacaoContratoEnum.CUSTOFIXOPOREXTENSO}
              onChange={handleInputChange}
              value={informacaoContrato?.custofixoporextenso ?? ""}
              label="Custo fixo por extenso"
              required
              fullWidth
            />
            <TextField
              type="number"
              inputProps={{ readOnly: buttonSave }}
              name={InformacaoContratoEnum.VIGENCIACONTRATUAL}
              onChange={({ target }:React.ChangeEvent<HTMLInputElement>) => {
                if (Number(target.value) > 120) target.value = "120";
                handleInputChange({ target });
              }}
              onBlur={({ target }:any) => {
                if (Number(target.value) < 12) target.value = "12";
                handleInputChange({ target });
              }}
              value={informacaoContrato?.vigenciacontratual || ""}
              label="Vigência contratual (meses)"
              required
              sx={handleStyleFieldError(
                InformacaoContratoEnum.VIGENCIACONTRATUAL,
                fieldErros, { width:"30%" }
              )}
            />
            <D.DateTextfield
              inputProps={{ readOnly: buttonSave }}
              name={InformacaoContratoEnum.DATALIMITECONEXAO}
              onChange={handleInputChange}
              value={
                ((informacaoContrato?.datalimiteconexao ?? "")
                .toString())?.split("T")[0]
              }
              InputLabelProps={{
                shrink: true,
              }}
              label="Data limite de conexão"
              type="date"
              required
              sx={handleStyleFieldError(
                InformacaoContratoEnum.DATALIMITECONEXAO,
                fieldErros, { width:"40%" }
              )}
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              inputProps={{ readOnly: buttonSave }}
              name={InformacaoContratoEnum.TIPOCONEXAO}
              onChange={handleInputChange}
              value={informacaoContrato?.tipoconexao ?? ""}
              label="Tipo de conexão"
              select
              required
              sx={handleStyleFieldError(
                InformacaoContratoEnum.TIPOCONEXAO,
                fieldErros, { width: "60%" }
              )}
            >
              {Object.entries(EnumTipoConexao)
                .filter(([value, key]) => isNaN(Number(key)))
                .map(([value, key]) => (
                  <MenuItem key={key} value={Number(value)}>
                    {key}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              inputProps={{ readOnly: buttonSave || !geradorId }}
              name={InformacaoContratoEnum.STATUSCONTRATOGERADOR}
              onChange={handleInputChange}
              value={informacaoContrato?.statuscontratogerador ?? ""}
              label="Status do contrato gerador"
              select
              required
              sx={{ width: "100%" }}
            >
              {enumStatusContratoGeradorMap.map(
                (
                  x: { name: string; value: EnumStatusContratoGerador },
                  i: number
                ) => (
                  <MenuItem key={i} value={x.value}>
                    {" "}
                    {x.name}{" "}
                  </MenuItem>
                )
              )}
            </TextField>
            <D.DateTextfield
              inputProps={{ readOnly: true }}
              name={InformacaoContratoEnum.DATAASSINATURACONTRATO}
              onChange={handleInputChange}
              value={
                ((informacaoContrato?.dataassinaturacontrato ?? "").toString())?.split("T")[0]
              }
              InputLabelProps={{
                shrink: true,
              }}
              label="Data de assinatura do contrato"
              type="date"
              required
              fullWidth
            />
          </C.FWStack>
          {children}
          <C.FWStack direction={"row"} spacing={16}>
            <C.MWDiv>
              <C.Subtitle>Dados bancários*</C.Subtitle>
              <C.Text>Insira seus dados bancários</C.Text>
              <C.FWStack direction={"row"} spacing={2}>
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  name={InformacaoContratoEnum.AGENCIARESPONSAVELINDICACAO}
                  onChange={handleInputChange}
                  value={informacaoContrato?.agenciaresponsavelindicacao || ""}
                  type="number"
                  label="Agência"
                  required
                  fullWidth
                />
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  name={InformacaoContratoEnum.CONTARESPONSAVELINDICACAO}
                  onChange={handleInputChange}
                  value={informacaoContrato?.contaresponsavelindicacao || ""}
                  type="number"
                  label="Conta"
                  required
                  fullWidth
                />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={2}>
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  name={InformacaoContratoEnum.FAVORECIDORESPONSAVELINDICACAO}
                  onChange={handleInputChange}
                  value={informacaoContrato?.favorecidoresponsavelindicacao || ""}
                  label="Favorecido"
                  required
                  fullWidth
                  onKeyDown={(event:any) => {
                    const regex = /[0-9]/;
                    if (regex.test(event?.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <TextField
                  inputProps={{ readOnly: buttonSave }}
                  name={InformacaoContratoEnum.BANCORESPONSAVELINDICACAO}
                  onChange={handleInputChange}
                  value={informacaoContrato?.bancoresponsavelindicacao || ""}
                  label="Banco"
                  required
                  fullWidth
                />
              </C.FWStack>
              <C.FWStack direction={"row"} spacing={2}>
                <TextField
                  type="number"
                  inputProps={{ readOnly: buttonSave }}
                  name={InformacaoContratoEnum.CNPJFAVORECIDORESPONSAVELINDICACAO}
                  onChange={handleInputChange}
                  value={
                    informacaoContrato?.cnpjfavorecidoresponsavelindicacao || ""
                  }
                  label="CNPJ do banco"
                  required
                  fullWidth
                />
                <C.FWDiv></C.FWDiv>
              </C.FWStack>
            </C.MWDiv>
            <C.MWDiv>
              <C.Subtitle>Pix*</C.Subtitle>
              <C.Text>Insira sua chave pix</C.Text>
              <C.FWStack direction={"row"} spacing={2}>
                <TextField
                  type={
                    informacaoContrato.tipochavepixresponsavelindicacao ===
                      EnumTipoChavePix.CELULAR ||
                    informacaoContrato.tipochavepixresponsavelindicacao ===
                      EnumTipoChavePix.ALEATORIO ||
                    informacaoContrato.tipochavepixresponsavelindicacao ===
                      EnumTipoChavePix.CPF
                      ? "number"
                      : "text"
                  }
                  inputProps={{ readOnly: buttonSave }}
                  name={InformacaoContratoEnum.CHAVEPIXRESPONSAVELINDICACAO}
                  onChange={handleInputChange}
                  value={informacaoContrato?.chavepixresponsavelindicacao || ""}
                  label="Chave PIX"
                  required
                  fullWidth
                />
              </C.FWStack>
              <C.Text>Qual dado você quer usar?</C.Text>
              <C.FWStack direction={"row"} spacing={2}>
                {Object.entries(EnumTipoChavePix)
                  .filter(([key, value]) => isNaN(Number(key)))
                  .map(([key, value]) => (
                    <Chip
                      disabled={buttonSave}
                      label={key}
                      onClick={() =>
                        handleInputChange({
                          target: {
                            name: InformacaoContratoEnum.TIPOCHAVEPIXRESPONSAVELINDICACAO,
                            value,
                          },
                        } as React.ChangeEvent<HTMLInputElement>)
                      }
                      color={
                        informacaoContrato?.tipochavepixresponsavelindicacao ===
                        value
                          ? "primary"
                          : "default"
                      }
                    />
                  ))}
              </C.FWStack>
            </C.MWDiv>
          </C.FWStack>
          {
            areaAccess &&
            <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
              {
                load?.status && load?.section === "generateContract"?
                <CircularSpinner 
                  color="pink"
                  size="30px" 
                />
                :
                <D.ContainedButton
                  disabled={
                    !buttonSave ||
                    !temContrato ||
                    (!!usina?.assinaturaDigitalId)
                  }
                  onClick={sendOnGenerate}
                >
                Gerar Contrato
              </D.ContainedButton>
              }
              <D.ContainedButton
                disabled={!buttonSave || !(!!usina?.dataAssinaturaContrato)}
                onClick={sendOnDistrato}
              >
                Gerar Distrato
              </D.ContainedButton>

              <D.ContainedButton
                disabled={!buttonSave}
                onClick={sendOnUpdate}
                $color="yellow"
              >
                Editar
              </D.ContainedButton>
              <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>
                Salvar
              </D.ContainedButton>
            </C.FWStack>
          }
          </>
        }
      </RegisterCard>
  );
};
