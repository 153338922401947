import { InputTypes } from "../../types/InputType";
import {
  ALEATORIA,
  CELULAR,
  CEP,
  CNPJ,
  CPF,
  DECIMAL,
  IMask,
  KWH,
  PORCENTAGEM,
  REAL,
  RG,
} from "../../utils/masInputs";

export const mapMask: Record<InputTypes, IMask> = {
  CELULAR: new CELULAR(),
  CEP: new CEP(),
  CNPJ: new CNPJ(),
  CPF: new CPF(),
  DECIMAL: new DECIMAL(),
  KWH: new KWH(),
  PORCENTAGEM: new PORCENTAGEM(),
  REAL: new REAL(),
  RG: new RG(),
  ALEATORIA: new ALEATORIA(),
};

export const mapMaskMaxLength: Partial<Record<InputTypes, number>> = {
  CELULAR: 15,
  CEP: 9,
  CNPJ: 18,
  CPF: 14,
  RG: 12,
  ALEATORIA: 36,
};
