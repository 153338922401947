import { IUpdateResponsavelComunicacao } from "../../models/ResponsavelComunicacao";
import { AbstractValidation, Validate } from "../../utils/AbstractValidation";
import { validarCelular, validarEmail } from "../../utils/Utils";

export class UpdateResponsavelComunicacaoValidade extends AbstractValidation<IUpdateResponsavelComunicacao> {
  getValidate(): Validate<IUpdateResponsavelComunicacao>[] {
    return [
      {
        field: "celular",
        validate(
          value: IUpdateResponsavelComunicacao["celular"],
          model,
          addError: (message: string) => void
        ) {
          if (value.trim().length === 0) return;
          const validacao = validarCelular(model.countryCode, value);
          if (validacao.sucesso === false) addError(validacao.mensagem ?? "");
        },
      },
      {
        field: "email",
        validate(
          value: IUpdateResponsavelComunicacao["email"],
          _model,
          addError: (message: string) => void
        ) {
          if (value.trim().length === 0) return;
          const validacao = validarEmail(value);
          if (validacao.sucesso === false) addError(validacao.mensagem ?? "");
        },
      },
    ];
  }
}
