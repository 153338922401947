import { z } from "zod";
import { EnumType } from "../types/EnumType";
import {
  EnumTipoChavePix,
  MapEnumTipoChavePixParaInputTypes,
} from "../enums/EnumTipoChavePix.enum";
import {
  mapMask,
  mapMaskMaxLength,
} from "../components/MasDefaultinput/MapMasks";
import { CodigoTelefonico } from "../enums/EnumCodigoTelefonico.enum";

export const objetoParaFormData = (obj: Object): FormData => {
  const formData = new FormData();
  for (const [chave, valor] of Object.entries(obj)) {
    if (valor !== null && valor !== undefined) {
      if (typeof valor === "string") {
        formData.append(chave, valor);
      } else if (valor instanceof Blob) {
        formData.append(chave, valor);
      } else {
        formData.append(chave, valor.toString());
      }
    }
  }
  return formData;
};

export const ObterEnum = <E extends EnumType>(
  enumType: E,
  id: number | null | undefined,
  mapNumerico?: Record<E[keyof E], number>
): E[keyof E] | null => {
  const map =
    mapNumerico ??
    (Object.fromEntries(
      Object.values(enumType).map((value, index) => [value, index])
    ) as Record<E[keyof E], number>);

  const chave = (Object.keys(map) as Array<E[keyof E]>).find(
    (key) => map[key] === id
  );

  return chave ?? null;
};

export const obterNomeArquivo = () =>
  new Date().toISOString().slice(0, 19).replace(/[-:T]/g, "");

export const downloadFile = (
  arquivo: File,
  nomeArquivo: string = arquivo.name
) => {
  const url = window.URL.createObjectURL(arquivo);
  const a = document.createElement("a");
  a.href = url;
  a.download = nomeArquivo;
  a.click();

  if (a.parentNode) {
    a.parentNode.removeChild(a);
  }
  window.URL.revokeObjectURL(url);
};

export const dowloadUrl = (fileUrl: string) => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.target = "_blank";
  link.rel = "noopener noreferrer";

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
};

export const criarPaginaResponseSchema = <ItemType extends z.ZodTypeAny>(
  itemSchema: ItemType
) =>
  z.object({
    pageNumber: z.number(),
    pageSize: z.number(),
    totalPages: z.number(),
    totalRecords: z.number(),
    data: z.array(itemSchema),
  });

export type ValidacaoResultado = {
  sucesso: boolean;
  mensagem?: string;
};

const validarDigitoCNPJ = (baseCNPJ: string, digito: number): boolean => {
  let soma = 0;
  let pos = baseCNPJ.length - 7;
  for (let i = baseCNPJ.length; i >= 1; i--) {
    soma += parseInt(baseCNPJ.charAt(baseCNPJ.length - i), 10) * pos--;
    if (pos < 2) pos = 9;
  }
  const resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  return resultado === digito;
};

export const validarCNPJ = (cnpj: string): ValidacaoResultado => {
  const limparCNPJ = cnpj.replace(/[^\d]+/g, "");

  if (limparCNPJ.length !== 14) {
    return { sucesso: false, mensagem: "CNPJ tem que ter 14 dígitos" };
  }

  if (/^(\d)\1+$/.test(limparCNPJ)) {
    return { sucesso: false, mensagem: "CNPJ inválido" };
  }

  const baseCNPJ = limparCNPJ.slice(0, 12);
  const digitos = limparCNPJ
    .slice(12)
    .split("")
    .map((d) => parseInt(d, 10));

  if (!validarDigitoCNPJ(baseCNPJ, digitos[0])) {
    return { sucesso: false, mensagem: "CNPJ inválido" };
  }

  if (!validarDigitoCNPJ(limparCNPJ.slice(0, 13), digitos[1])) {
    return { sucesso: false, mensagem: "CNPJ inválido" };
  }

  return { sucesso: true };
};

const validarDigitoCPF = (baseCPF: string, digito: number): boolean => {
  let soma = 0;
  let peso = baseCPF.length + 1;
  for (let i = 0; i < baseCPF.length; i++) {
    soma += parseInt(baseCPF.charAt(i), 10) * peso--;
  }
  const resto = (soma * 10) % 11;
  const resultado = resto === 10 || resto === 11 ? 0 : resto;
  return resultado === digito;
};

export const validarCPF = (cpf: string): ValidacaoResultado => {
  cpf = cpf.replace(/[^\d]+/g, "").trim();

  if (cpf.length !== 11) {
    return { sucesso: false, mensagem: "CPF tem que ter 11 dígitos" };
  }

  if (/^(\d)\1+$/.test(cpf)) {
    return { sucesso: false, mensagem: "CPF inválido" };
  }

  const baseCPF1 = cpf.substring(0, 9);
  const baseCPF2 = cpf.substring(0, 10);

  const digitoVerificador1 = parseInt(cpf.charAt(9), 10);
  const digitoVerificador2 = parseInt(cpf.charAt(10), 10);

  if (
    !validarDigitoCPF(baseCPF1, digitoVerificador1) ||
    !validarDigitoCPF(baseCPF2, digitoVerificador2)
  ) {
    return { sucesso: false, mensagem: "CPF inválido" };
  }

  return { sucesso: true };
};

export const validarChaveAleatoriaPix = (chave: string): ValidacaoResultado => {
  chave = chave.replace("-", "");

  if (chave.trim().length !== 32)
    return {
      sucesso: false,
      mensagem: "Chave aleatória tem que ter 32 dígitos",
    };

  return { sucesso: true };
};

export const validarEmail = (email: string): ValidacaoResultado => {
  const emailSchema = z.string().email();
  const validacao = emailSchema.safeParse(email);
  if (!validacao.success) {
    return { sucesso: false, mensagem: "Email inválido" };
  }
  return { sucesso: true };
};

export const aplicarMascaraChavePix = (
  chave: string,
  enumTipoChavePix: EnumTipoChavePix | null
): string => {
  if (enumTipoChavePix === null) return chave;
  const tipoChavePix = MapEnumTipoChavePixParaInputTypes[enumTipoChavePix];
  if (tipoChavePix) {
    return mapMask[tipoChavePix].execute(chave);
  }
  return chave;
};

export const obterTamanhoMaximoCampoChavePix = (
  enumTipoChavePix: EnumTipoChavePix | null
): number => {
  if (enumTipoChavePix === null) return Number.MAX_SAFE_INTEGER;
  const tipoChavePix = MapEnumTipoChavePixParaInputTypes[enumTipoChavePix];
  if (tipoChavePix) {
    const tamanhoMaximo = mapMaskMaxLength[tipoChavePix];
    if (tamanhoMaximo) return tamanhoMaximo;
  }
  return Number.MAX_SAFE_INTEGER;
};

const validarCelularBrasil = (numero: string): boolean => {
  const numeroLimpo = numero.replace(/\D/g, "");
  const padrao = /^\d{2}9\d{8}$/;
  return padrao.test(numeroLimpo);
};

const validarCelularEUA = (numero: string): boolean => {
  const numeroLimpo = numero.replace(/\D/g, "");
  const padrao = /^\d{3}\d{7}$/;
  return padrao.test(numeroLimpo);
};

const mapValidarCelular: Record<CodigoTelefonico, (numero: string) => boolean> =
  {
    [CodigoTelefonico.BRASIL]: validarCelularBrasil,
    [CodigoTelefonico.EUA]: validarCelularEUA,
  };

const isCodigoTelefonico = (codigo: any): codigo is CodigoTelefonico => {
  return Object.values(CodigoTelefonico).includes(codigo);
};

export const validarCelular = (
  codigoTelefonico: string,
  celular: string
): ValidacaoResultado => {
  let valido = false;
  if (
    isCodigoTelefonico(codigoTelefonico) &&
    codigoTelefonico in mapValidarCelular
  ) {
    valido = mapValidarCelular[codigoTelefonico](celular);
  }
  if (valido) return { sucesso: true };
  return { sucesso: false, mensagem: "Celular inválido" };
};

export const validarLink = (url: string): ValidacaoResultado => {
  const regex = /[^a-zA-Z0-9]/;
  if (regex.test(url)) {
    return { sucesso: false, mensagem: "Url inválido" };
  }
  return { sucesso: true };
};

export const validarCEP = (cep: string): ValidacaoResultado => {
  if (cep.includes(" ")) return { sucesso: false, mensagem: "CEP inválido" };
  const limparCEP = cep.replace(/[^\d]+/g, "");
  if (limparCEP.length !== 8)
    return { sucesso: false, mensagem: "CEP inválido" };
  return { sucesso: true };
};

export const regexUUID =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

export const validarUUID = (uuid: string) => regexUUID.test(uuid);

export const verificarMaiorDeIdade = (dataNascimento: string | Date) => {
  const nascimento = new Date(dataNascimento);
  const hoje = new Date();
  let idade = hoje.getFullYear() - nascimento.getFullYear();

  const mesAtual = hoje.getMonth();
  const mesNascimento = nascimento.getMonth();

  if (
    mesAtual < mesNascimento ||
    (mesAtual === mesNascimento && hoje.getDate() < nascimento.getDate())
  ) {
    idade--;
  }

  return idade >= 18;
};
