import {
  Autocomplete,
  AutocompleteRenderInputParams,
  MenuItem,
  TextField,
} from "@mui/material";
import RegisterCard from "../../../../../components/RegisterCard";
import * as D from "../../../../../styles/appComponents";
import {
  EnumTipoConexao,
  EnumTipoConexaoNumericMap,
} from "../../../../../enums/EnumTipoConexao.enum";
import {
  EnumTipoClasse,
  EnumTipoClasseNumericMap,
} from "../../../../../enums/EnumTipoClasse.enum";
import React, { useEffect, useState } from "react";
import {
  IConexaoContaProposta,
  IGetCalculoParams,
  IGetCalculoResponse,
} from "../../../../../models/GDProposta";
import * as service from "../../../../../services/api/PropostaService";
import { AxiosError, AxiosResponse } from "axios";
import Swal from "sweetalert2";
import * as captadorService from "../../../../../services/api/CaptadorService";
import * as distribuidoraService from "../../../../../services/api/DistribuidoraService";
import { useAuth } from "../../../../../hooks/useAuth";
import { IDistribuidoraListItem } from "../../../../../models/Distribuidora";
import { handleStyleFieldError } from "../../../../../utils/verifyFieldError";
import MaskDefaultInput from "../../../../../components/MasDefaultinput/MaskDefaultInput";
import { ErrorMessage } from "../../../../../utils/AbstractValidation";
import { GetCalculoValidate } from "../../../Leads/Validation/GetCalculo/GetCalculoValidate";
import { toastMessage } from "../../../../../utils/toastMessage";
import { TRIBUTOSPISCOFINS } from "../../../Leads/Constants";

interface IConexaoContaEditarPropostaProps {
  initialData: IConexaoContaProposta;
  data: IConexaoContaProposta;
  onSendData: (data: IConexaoContaProposta) => void;
  sendCalculoProposta: (data: IGetCalculoResponse) => void;
}

enum IConexaoContaPropostaEnum {
  CONEXAO = "conexao",
  CLASSE = "classe",
  DESCONTO = "valorDesconto",
  VALOR_CONTA = "valorConta",
  CONSUMO_MENSAL_KWH = "consumoMensalkWh",
  TARIFA_DISTRIBUIDORA = "tarifaDistribuidora",
  ILUMINACAO_PUBLICA = "iluminacaoPublica",
  NUMERO_INSTALACAO = "numeroInstalacao",
  DISTRIBUIDORA_ID = "distribuidoraId",
  ALICOTA_TRIBUTARIA_PIS_COFINS = "tributosPisCofins",
}

const ConexaoContaEditarProposta: React.FC<
  IConexaoContaEditarPropostaProps
> = ({ onSendData, sendCalculoProposta, data, initialData }) => {
  //region Variáveis
  const { user } = useAuth();
  const [tributosPisCofins, _] = useState<string>(TRIBUTOSPISCOFINS);
  const [distribuidoraList, setDistribuidoraList] = useState<
    IDistribuidoraListItem[]
  >([]);
  const [dataForm, setDataForm] = useState<IConexaoContaProposta>({
    classe: "",
    conexao: "",
    consumoMensalkWh: "",
    distribuidoraId: "",
    iluminacaoPublica: "",
    numeroInstalacao: "",
    tarifaDistribuidora: "",
    tributosPisCofins: tributosPisCofins,
    valorConta: "",
    valorDesconto: "",
  });
  //endregion

  //region Services
  const getValorDesconto: () => Promise<string | void> = async () => {
    return await captadorService
      .GetLimiteDesconto(user?.idCaptador ?? "")
      .then((response) => {
        return response.data;
      })
      .catch((e: AxiosError) => {
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os dados."
        );
      });
  };

  useEffect(() => {
    (async () => {
      try {
        const response: AxiosResponse<IDistribuidoraListItem[], any> =
          await distribuidoraService.GetDistribuidoras();

        setDistribuidoraList(response.data);
      } catch (error) {
        const e = error as AxiosError;
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os dados."
        );
      }
    })();
  }, []);

  const calcularProposta: () => void = () => {
    var data: IGetCalculoParams = {
      iluminacaoPublica: dataForm.iluminacaoPublica!,
      consumoMensalkWh: dataForm.consumoMensalkWh ?? "",
      percentualDesconto: dataForm.valorDesconto ?? "",
      pisConfins: dataForm.tributosPisCofins!,
      tarifaDistribuidora:
        String(dataForm.tarifaDistribuidora ?? "").replace(/,/g, ".") ?? "",
      tipoConexao: dataForm.conexao?.toString() ?? "",
      valorConta: dataForm.valorConta!,
    };

    const validator: ErrorMessage[] | null | undefined =
      new GetCalculoValidate().errorMessage(data);

    if (validator) {
      validator.forEach((error: ErrorMessage) => {
        toastMessage("warning", error?.message);
      });
      return;
    }

    service
      .GetCalculoGDProposta(data)
      .then((response) => {
        sendCalculoProposta(response.data);
      })
      .catch((e: AxiosError) => {
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os dados."
        );
      });
  };
  //endregion

  //region UI
  const handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setDataForm((state: IConexaoContaProposta) => {
      const updatedState = {
        ...state,
        [name]: value ?? null,
      };
      onSendData(updatedState);
      return updatedState;
    });
  };

  const handleChangeDesconto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const numericValue = Number(inputValue);

    if (inputValue === "" || (numericValue >= 0 && numericValue <= 30)) {
      setDataForm({ ...dataForm, valorDesconto: inputValue });
    } else if (numericValue < 0) {
      setDataForm({ ...dataForm, valorDesconto: "0" });
    } else if (numericValue > 30) {
      setDataForm({ ...dataForm, valorDesconto: "30" });
    }
  };

  useEffect(() => {
    (async () => {
      const valorDesconto = initialData.valorDesconto
        ? initialData.valorDesconto
        : (await getValorDesconto()) ?? "";

      const updatedData = {
        classe: initialData.classe ?? "",
        conexao: initialData.conexao ?? "",
        consumoMensalkWh: initialData.consumoMensalkWh ?? "",
        distribuidoraId: initialData.distribuidoraId ?? "",
        iluminacaoPublica: initialData.iluminacaoPublica ?? "",
        numeroInstalacao: initialData.numeroInstalacao ?? "",
        tarifaDistribuidora: initialData.tarifaDistribuidora ?? "",
        tributosPisCofins: initialData.tributosPisCofins ?? tributosPisCofins,
        valorConta: initialData.valorConta ?? "",
        valorDesconto,
      };

      setDataForm(updatedData);
      onSendData(updatedData);
    })();
  }, [initialData]);

  useEffect(() => {
    onSendData(dataForm);
  }, [dataForm]);
  //endregion

  return (
    <RegisterCard title="Conexao/Conta">
      <D.FWStack direction={"row"} spacing={2}>
        <Autocomplete
          options={distribuidoraList}
          fullWidth
          noOptionsText="Nenhuma distribuidora encontrada"
          getOptionLabel={(option: IDistribuidoraListItem) => option.nome}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField {...params} label="Distribuidora" />
          )}
          defaultValue={null}
          value={
            distribuidoraList.find((x) => x.id === dataForm.distribuidoraId) ??
            null
          }
          onChange={(_, newValue: null | IDistribuidoraListItem) => {
            handleInputChange({
              target: {
                name: IConexaoContaPropostaEnum.DISTRIBUIDORA_ID,
                value: newValue?.id as string,
              },
            } as unknown as unknown as React.ChangeEvent<HTMLInputElement>);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        <TextField
          select
          label="Conexão"
          fullWidth
          value={dataForm.conexao}
          name={IConexaoContaPropostaEnum.CONEXAO}
          onChange={handleInputChange}
        >
          {Object.values(EnumTipoConexao).map((value) => (
            <MenuItem key={value} value={EnumTipoConexaoNumericMap[value]}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Classe"
          fullWidth
          value={dataForm.classe}
          name={IConexaoContaPropostaEnum.CLASSE}
          onChange={handleInputChange}
        >
          {Object.values(EnumTipoClasse).map((value) => (
            <MenuItem key={value} value={EnumTipoClasseNumericMap[value]}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <MaskDefaultInput
          type={"REAL"}
          value={dataForm.valorConta ?? ""}
          onChange={(value) => {
            handleInputChange({
              target: {
                name: IConexaoContaPropostaEnum.VALOR_CONTA,
                value: value,
              },
            } as React.ChangeEvent<HTMLInputElement>);
          }}
          label={"Valor da conta"}
        />
        <MaskDefaultInput
          type={"KWH"}
          value={dataForm.consumoMensalkWh ?? ""}
          onChange={(value) => {
            handleInputChange({
              target: {
                name: IConexaoContaPropostaEnum.CONSUMO_MENSAL_KWH,
                value: value,
              },
            } as React.ChangeEvent<HTMLInputElement>);
          }}
          label={"Consumo mensal"}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Tarifa distribuidora"
          type="number"
          required
          fullWidth
          value={dataForm.tarifaDistribuidora}
          name={IConexaoContaPropostaEnum.TARIFA_DISTRIBUIDORA}
          onChange={handleInputChange}
          InputProps={{
            startAdornment: dataForm.tarifaDistribuidora ? (
              <div style={{ marginRight: "5px" }}>R$</div>
            ) : (
              ""
            ),
          }}
        />
        <MaskDefaultInput
          type={"REAL"}
          value={dataForm.iluminacaoPublica ?? ""}
          onChange={(value) => {
            handleInputChange({
              target: {
                name: IConexaoContaPropostaEnum.ILUMINACAO_PUBLICA,
                value: value,
              },
            } as React.ChangeEvent<HTMLInputElement>);
          }}
          label={"Iluminação pública"}
        />
        <TextField
          label="Número de instalação"
          type="number"
          required
          fullWidth
          value={dataForm.numeroInstalacao}
          name={IConexaoContaPropostaEnum.NUMERO_INSTALACAO}
          onChange={handleInputChange}
        />
        <TextField
          type="number"
          label="Desconto"
          required
          fullWidth
          inputProps={{ min: 0, max: 30 }}
          value={dataForm.valorDesconto}
          name={IConexaoContaPropostaEnum.DESCONTO}
          onChange={handleChangeDesconto}
          InputProps={{
            endAdornment: dataForm.valorDesconto ? <>%</> : <></>,
          }}
        />
        <MaskDefaultInput
          value={dataForm.tributosPisCofins ?? ""}
          type={"PORCENTAGEM"}
          label={"Alíquota tributária ( pis + confins )"}
          onChange={(value) => {
            handleInputChange({
              target: {
                name: IConexaoContaPropostaEnum.ALICOTA_TRIBUTARIA_PIS_COFINS,
                value: value,
              },
            } as React.ChangeEvent<HTMLInputElement>);
          }}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} justifyContent={"flex-end"}>
        <D.ContainedButton $color="lightpink" onClick={calcularProposta}>
          Calcular proposta
        </D.ContainedButton>
      </D.FWStack>
    </RegisterCard>
  );
};

export default ConexaoContaEditarProposta;
