import {TextField} from "@mui/material";
import RegisterCard from "../../../../../components/RegisterCard";
import * as C from "../style";
import * as D from "../../../../../styles/appComponents";
import {IResponsavelUsina} from "../../../../../models/Gerador/Cadastro/ResponsavelUsina";
import {ErrorMessage} from "../../../../../utils/AbstractValidation";
import {UsinaTipoPessoaFisica} from "../../../../../models/Gerador/Usina";
import {FC, useEffect, useState} from "react";
import {SkeletonSectionForm} from "../../../../../components/SkeletonLoads/SectionForm";
import MaskDefaultInput from "../../../../../components/MasDefaultinput/MaskDefaultInput";
import {ResponsavelUsinaEnum} from "../../../../../enums/Gerador/EnumInputModel.ts/ResponsavelUsinaEnum";
import {handleStyleFieldError} from "../../../../../utils/verifyFieldError";

export interface PropsResponsavelUsina {
    onSave: (data: IResponsavelUsina) => void;
    onChange: (data: IResponsavelUsina) => void;
    onUpdate: (data: IResponsavelUsina) => void;
    buttonSave: boolean;
    fieldErros: ErrorMessage[] | null;
    geradorId: string | null | undefined;
    usina: UsinaTipoPessoaFisica | undefined;
    load: { status:boolean, section:string };
    areaAccess: boolean;
}

export const ResponsavelUsina:FC<PropsResponsavelUsina> = (
    {
        onSave,
        onChange,
        onUpdate,
        buttonSave,
        fieldErros,
        geradorId,
        usina,
        load,
        areaAccess
    }
) => {

    const formResponsavelUsinaModel:IResponsavelUsina = {
        nomeresponsavelusina: null,
        emailresponsavelusina: null,
        celularresponsavelusina: null,
        cpfresponsavelusina: null,
        cepresponsavelusina: null,
        ufresponsavelusina: null,
        cidaderesponsavelusina: null,
        bairroresponsavelusina: null,
        numeroresponsavelusina: "S/N",
        complementoresponsavelusina: null,
        logradouroresponsavelusina: null,
        datanascimentoresponsavelusina: null,
        countrycoderesponsavelusina:"+55",
    }
    const [responsavelUsina,setResponsavelUsina] = useState<IResponsavelUsina>(formResponsavelUsinaModel)

    useEffect(() => {
        if(geradorId){
            const formResponsavelUsinaModel:IResponsavelUsina = {
                nomeresponsavelusina: geradorId? usina?.nomeResponsavelUsina: null,
                emailresponsavelusina: geradorId? usina?.emailResponsavelUsina: null,
                celularresponsavelusina: geradorId? usina?.celularResponsavelUsina: null,
                countrycoderesponsavelusina: geradorId? usina?.countryCodeResponsavelUsina: null,
                cpfresponsavelusina: geradorId? usina?.cpfResponsavelUsina: null,
                cepresponsavelusina: geradorId? usina?.cepResponsavelUsina: null,
                ufresponsavelusina: geradorId? usina?.ufResponsavelUsina: null,
                cidaderesponsavelusina: geradorId? usina?.cidadeResponsavelUsina: null,
                bairroresponsavelusina: geradorId? usina?.bairroResponsavelUsina: null,
                numeroresponsavelusina: !usina?.numeroResponsavelUsina && usina?.numeroResponsavelUsina === ""? "S/N": usina?.numeroResponsavelUsina,
                complementoresponsavelusina: geradorId? usina?.complementoResponsavelUsina: null,
                logradouroresponsavelusina: geradorId? usina?.logradouroResponsavelUsina: null,
                datanascimentoresponsavelusina: geradorId? usina?.dataNascimento: null
            }
            setResponsavelUsina(formResponsavelUsinaModel)
        }

    }, [geradorId,usina])

    const sendOnSave = () => onSave(responsavelUsina);
    const sendOnUpdate = () => onUpdate(responsavelUsina);
    const sendOnChange = (update:IResponsavelUsina) => onChange(update);

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>
      ): void => {
        const name = event.target.name.toLocaleLowerCase();
        const value = event.target.value;
    
        setResponsavelUsina((state: any) => {
            if(name === ResponsavelUsinaEnum.NOMERESPONSAVELUSINA){
                sendOnChange({ ...state, [name]: value === null || value === ""? "S/N": value });
                return { ...state, [name]: value === null || value === ""? "S/N": value }; 
            }

          sendOnChange({ ...state, [name]: value });
          return { ...state, [name]: value };
        });
    };

    return(
        <RegisterCard title="Responsável pela Usina">
            {
                load.status && load.section === "responsavelUsina"?
                <SkeletonSectionForm />
                :
                <>
                <C.FWStack direction={"row"} spacing={2}>
                    <TextField
                        name={ResponsavelUsinaEnum.NOMERESPONSAVELUSINA}
                        value={responsavelUsina?.nomeresponsavelusina ?? ""}
                        inputProps={{ readOnly: buttonSave }}
                        onChange={handleInputChange}
                        label="Nome"
                        required
                        sx={handleStyleFieldError(
                            ResponsavelUsinaEnum.NOMERESPONSAVELUSINA,
                            fieldErros, { width:"100%" }
                        )}
                    />
                    <TextField
                        name={ResponsavelUsinaEnum.EMAILRESPONSAVELUSINA}
                        value={responsavelUsina?.emailresponsavelusina ?? ""}
                        inputProps={{ readOnly: buttonSave }}
                        onChange={handleInputChange}
                        label="Email"
                        type="email"
                        required
                        sx={handleStyleFieldError(
                            ResponsavelUsinaEnum.EMAILRESPONSAVELUSINA,
                            fieldErros, { width:"100%" }
                        )}
                    />
                    <MaskDefaultInput
                        type="CELULAR"
                        label="Celular"
                        readonly={buttonSave}
                        value={responsavelUsina?.celularresponsavelusina ?? ""}
                        valueCountryCode={responsavelUsina?.countrycoderesponsavelusina ?? ""}
                        onChange={(value:any) =>
                            {
                                handleInputChange(
                                    { 
                                        target:{ name:ResponsavelUsinaEnum.CELULARRESPONSAVELUSINA, value:value?.value } 
                                    } as React.ChangeEvent<HTMLInputElement>);

                                handleInputChange(
                                    { 
                                        target:{ name:ResponsavelUsinaEnum.COUNTRYCODERESPONSAVELUSINA, value:value?.zipCode } 
                                    } as React.ChangeEvent<HTMLInputElement>);
                            }

                        }
                        sx={handleStyleFieldError(
                            ResponsavelUsinaEnum.CELULARRESPONSAVELUSINA,
                            fieldErros, { width:"100%" }
                        )}
                    />
                    <MaskDefaultInput
                        type="CPF"
                        label="CPF"
                        value={responsavelUsina?.cpfresponsavelusina ?? ""}
                        onChange={(value) => handleInputChange(
                            { 
                                target:{ name:ResponsavelUsinaEnum.CPFRESPONSAVELUSINA, value } 
                            } as React.ChangeEvent<HTMLInputElement>)}
                        sx={handleStyleFieldError(
                            ResponsavelUsinaEnum.CPFRESPONSAVELUSINA,
                            fieldErros, { width:"100%" }
                        )}
                    />
                </C.FWStack>
                <C.FWStack direction={"row"} spacing={2}>
                    <D.DateTextfield
                        name={ResponsavelUsinaEnum.DATANASCIMENTORESPONSAVELUSINA}
                        value={
                            ((responsavelUsina?.datanascimentoresponsavelusina ?? "")
                            .toString())?.split("T")[0]
                          }
                        inputProps={{ readOnly: buttonSave }}
                        type="date"
                        onChange={handleInputChange}
                        label="Data de Nascimento"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={handleStyleFieldError(
                            ResponsavelUsinaEnum.DATANASCIMENTORESPONSAVELUSINA,
                            fieldErros, { width:"90%" }
                        )}
                    />
                    <MaskDefaultInput
                        type="CEP"
                        label="CEP"
                        value={responsavelUsina?.cepresponsavelusina ?? ""}
                        onChange={(value:any) => {
                            handleInputChange(
                                { 
                                target:{ name:ResponsavelUsinaEnum.CEPRESPONSAVELUSINA, value: value?.value  } 
                                } as React.ChangeEvent<HTMLInputElement>
                            );
                            handleInputChange(
                                { 
                                target:{ name:ResponsavelUsinaEnum.CIDADERESPONSAVELUSINA, value: value?.localidade  } 
                                } as React.ChangeEvent<HTMLInputElement>
                            );
                            handleInputChange(
                                { 
                                target:{ name:ResponsavelUsinaEnum.UFRESPONSAVELUSINA, value: value?.uf  } 
                                } as React.ChangeEvent<HTMLInputElement>
                            );
                            handleInputChange(
                                { 
                                target:{ name:ResponsavelUsinaEnum.LOGRADOURORESPONSAVELUSINA, value: value?.logradouro  } 
                                } as React.ChangeEvent<HTMLInputElement>
                            );
                        }
                        }
                        sx={handleStyleFieldError(
                            ResponsavelUsinaEnum.CEPRESPONSAVELUSINA,
                            fieldErros, { width:"100%" }
                        )}
                    />
                    <TextField
                        name={ResponsavelUsinaEnum.UFRESPONSAVELUSINA}
                        value={responsavelUsina?.ufresponsavelusina ?? ""}
                        inputProps={{ readOnly: buttonSave }}
                        onChange={handleInputChange}
                        label="UF"
                        required
                        sx={handleStyleFieldError(
                            ResponsavelUsinaEnum.UFRESPONSAVELUSINA,
                            fieldErros, { width:"30%" }
                        )}
                    />
                    <TextField
                        name={ResponsavelUsinaEnum.CIDADERESPONSAVELUSINA}
                        value={responsavelUsina?.cidaderesponsavelusina ?? ""}
                        inputProps={{ readOnly: buttonSave }}
                        onChange={handleInputChange}
                        label="Cidade"
                        required
                        sx={handleStyleFieldError(
                            ResponsavelUsinaEnum.CIDADERESPONSAVELUSINA,
                            fieldErros, { width:"100%" }
                        )}
                    />
                    <TextField
                        name={ResponsavelUsinaEnum.BAIRRORESPONSAVELUSINA}
                        value={responsavelUsina?.bairroresponsavelusina ?? ""}
                        inputProps={{ readOnly: buttonSave }}
                        onChange={handleInputChange}
                        label="Bairro"
                        required
                        sx={handleStyleFieldError(
                            ResponsavelUsinaEnum.BAIRRORESPONSAVELUSINA,
                            fieldErros, { width:"100%" }
                        )}
                    />
                    <TextField
                        name={ResponsavelUsinaEnum.NUMERORESPONSAVELUSINA}
                        value={responsavelUsina?.numeroresponsavelusina ?? ""}
                        inputProps={{ readOnly: buttonSave }}
                        onChange={handleInputChange}
                        label="Número"
                        type="number"
                        required
                        sx={handleStyleFieldError(
                            ResponsavelUsinaEnum.NUMERORESPONSAVELUSINA,
                            fieldErros, { width:"30%" }
                        )}
                    />
                </C.FWStack>
                <C.FWStack direction={"row"} spacing={2}>
                    <TextField
                        name={ResponsavelUsinaEnum.LOGRADOURORESPONSAVELUSINA}
                        value={responsavelUsina?.logradouroresponsavelusina ?? ""}
                        inputProps={{ readOnly: buttonSave }}
                        onChange={handleInputChange}
                        label="Logradouro"
                        required
                        sx={handleStyleFieldError(
                            ResponsavelUsinaEnum.LOGRADOURORESPONSAVELUSINA,
                            fieldErros, { width:"30%" }
                        )}
                    />
                    <TextField
                        name={ResponsavelUsinaEnum.COMPLEMENTORESPONSAVELUSINA}
                        value={responsavelUsina?.complementoresponsavelusina ?? ""}
                        inputProps={{ readOnly: buttonSave }}
                        onChange={handleInputChange}
                        label="Complemento"
                        required
                        sx={handleStyleFieldError(
                            ResponsavelUsinaEnum.COMPLEMENTORESPONSAVELUSINA,
                            fieldErros, { width:"50%" }
                        )}
                    />
                </C.FWStack>
                {
                    areaAccess &&
                    <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
                        <D.ContainedButton
                            disabled={!buttonSave}
                            onClick={sendOnUpdate}
                            $color="yellow"
                        >
                            Editar
                        </D.ContainedButton>
                        <D.ContainedButton 
                            disabled={buttonSave} 
                            onClick={sendOnSave}
                        >
                            Salvar
                        </D.ContainedButton>
                    </C.FWStack>
                }
                </>
            }
        </RegisterCard>
    );
};