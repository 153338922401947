import { IconButton, Stack } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const ModalArea = styled.div<{ width?:string | null, height?:string | null }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: ${({width}) => (width || "80%")};;
  height: ${({height}) => (height || "80%")};
  border-radius: 10px;
  background: #f8f8f8;
  padding: 20px;
  overflow-y: auto;

  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 425px) {
    padding: 10px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
    color: #222222;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const CloseButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  color: gray;
  aspect-ratio: 1/1;
`;

export const ModalCard = styled.div`
  border-radius: 10px;
  background: #fff;
  width: 100%;
  box-shadow: 6px 6px 6px #0000000f;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;

  h4 {
    margin: 0;
    color: #222222;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-basis: 18%;
  flex-direction: column;
  gap: 16px;
`;

export const TitleArea = styled.div`
  display: flex;
  gap: 40px;

  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
  }
`;

export const Wrapper = styled.div`
  margin-top: 70px;
  width: 100%;
  display: flex;
  height: calc(100% - 70px);
  position: relative;
`;

export const StatusArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  label {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const FWStack = styled(Stack)`
  width: 100%;
`;
export const FWDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;

  img {
    max-width: 200px;
  }
`;

export const Subtitle = styled.h4`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
`;

export const Text = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
`;

export const MWDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 20px;
`;

export const BorderFWDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #6b767f;
  margin-top: 50px;
`;

export const GridTitleArea = styled.div`
  padding: 20px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 13px;

  h2 {
    margin: 0;
    font-weight: 400;
  }

  svg {
  }
`;

export const ArchiveArea = styled.div`
  border-radius: 5px;
  background: #f4f4f4;
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 50px;
  gap: 30px;

  p {
    margin: 0;
    color: #262626;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const LightBWArea = styled.div`
  border-radius: 10px;
  background: #f8f8f8;
  width: 100%;
  display: flex;
  padding: 10px;
`;
