import { Box, Tooltip, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { listaStatus } from "./Constants";
import StatusCard from "../../../components/StatusCard";
import {
  Circle,
  DataArea,
  DefaultGridButton,
  GridField,
  LinkStyled,
} from "../../../styles/appComponents";
import {
  EnumStatusContratoParceiro,
  StatusContratoParceiroColorMap,
} from "../../../enums/EnumStatusContratoParceiro.enum";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Datatable from "../../../components/Datatable";
import { CaptadorListItemTabela } from "../../../models/CaptadorListItemTabela";
import parceiroManager from "./Manager/ParceiroManager";
import SkeletonDefaultPage from "../../../components/SkeletonLoads/DefaultPage";
import ModalCaptador from "../../../components/ModalCaptador/ModalCaptador";
import { ICaptador, ICaptadorListItem } from "../../../models/Captador";
import { useNavigate } from "react-router-dom";
import { IBackofficeListItem } from "../../../models/Colaborador/Colaborador";

const Parceiro: FC = () => {
  const [pagina, setPagina] = useState<number>(1);
  const [tamanhoPagina, setTamanhoPagina] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pesquisa, setPesquisa] = useState<string>("");
  const [listarCaptadores, setListarCaptadores] = useState<
    CaptadorListItemTabela[]
  >([]);
  const [buscandoDadosInicial, setBuscandoDadosInicial] =
    useState<boolean>(true);
  const [statusContrato, setStatusContrato] = useState<
    Partial<Record<EnumStatusContratoParceiro, number>>
  >({});
  const [modalDados, setModalDados] = useState<ICaptador | null>(null);
  const navigate = useNavigate();
  const [backOffices, setBackOffices] = useState<IBackofficeListItem[]>([]);
  const [captadores, setCaptadores] = useState<ICaptadorListItem[]>([]);

  const buscandoCaptador = useCallback(
    async (pesquisa: string, pagina: number, tamanhoPagina: number) => {
      const [novaListaCaptadores, novoStatusCaptadores] = await Promise.all([
        parceiroManager.listarCaptadores(pesquisa, pagina, tamanhoPagina),
        parceiroManager.obterStatusCaptadores(),
      ]);

      if (novaListaCaptadores) {
        setListarCaptadores(novaListaCaptadores.data);
        setTotalPages(novaListaCaptadores.totalPages);
      }
      if (novoStatusCaptadores) {
        setStatusContrato(novoStatusCaptadores);
      }
    },
    []
  );

  const getBackoffices = async () => {
    const novoBackoffices = await parceiroManager.getBackoffices();
    setBackOffices(novoBackoffices);
  };

  const getCaptadores = async () => {
    const novoCaptadores = await parceiroManager.getCaptadoresFiltro(true);
    setCaptadores(novoCaptadores);
  };

  const onSearch = (novaPesquisa: string) => {
    setPesquisa(novaPesquisa);
    buscandoCaptador(novaPesquisa, pagina, tamanhoPagina);
  };

  const onChangePage = (novaPagina: number) => {
    setPagina(novaPagina);
    buscandoCaptador(pesquisa, novaPagina, tamanhoPagina);
  };

  const onChangePageSize = (novaPaginaTamanho: number) => {
    setPagina(1);
    setTamanhoPagina(novaPaginaTamanho);
    buscandoCaptador(pesquisa, 1, novaPaginaTamanho);
  };

  const buscaDadosModal = async (captadorId: string) => {
    const captador = await parceiroManager.getCaptadorById(captadorId);
    if (captador) setModalDados(captador);
  };

  const handleNovoParceiro = () => navigate("/Parceiro/Editar/Parceiro/");

  useEffect(() => {
    const buscarDadosInicial = async () => {
      await Promise.all([
        buscandoCaptador("", 1, 10),
        getBackoffices(),
        getCaptadores(),
      ]);
      setBuscandoDadosInicial(false);
    };

    buscarDadosInicial();
  }, [buscandoCaptador]);

  const columns: GridColDef<CaptadorListItemTabela>[] = [
    {
      field: "idReferencial",
      description: "id",
      headerName: "ID",
      renderCell: (
        params: GridRenderCellParams<
          CaptadorListItemTabela,
          CaptadorListItemTabela["idReferencial"]
        >
      ) => (
        <GridField>
          <LinkStyled to={`/Parceiro/Editar/Parceiro/${params.row.captadorId}`}>
            {params.value}
          </LinkStyled>
        </GridField>
      ),
      width: 64,
    },
    {
      field: "razaoSocial",
      headerName: "Razão social",
      flex: 1,
      align: "center",
      renderCell: (
        params: GridRenderCellParams<
          CaptadorListItemTabela,
          CaptadorListItemTabela["razaoSocial"]
        >
      ) => (
        <GridField>
          <Typography style={{ whiteSpace: "normal", fontSize: 14 }}>
            {params.value}
          </Typography>
        </GridField>
      ),
    },
    {
      field: "responsavelLegal",
      headerName: "Responsável legal",
      flex: 1,
      align: "center",
      renderCell: (
        params: GridRenderCellParams<
          CaptadorListItemTabela,
          CaptadorListItemTabela["responsavelLegal"]
        >
      ) => (
        <GridField>
          <Typography style={{ whiteSpace: "normal", fontSize: 14 }}>
            {params.value}
          </Typography>
        </GridField>
      ),
    },
    {
      field: "celular",
      headerName: "Celular",
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<
          CaptadorListItemTabela,
          CaptadorListItemTabela["celular"]
        >
      ) => <GridField>{params.value}</GridField>,
    },
    {
      field: "tipoParceiro",
      headerName: "Tipo de Parceiro",
      align: "center",
      headerAlign: "center",
      renderCell: (
        params: GridRenderCellParams<
          CaptadorListItemTabela,
          CaptadorListItemTabela["tipoParceiro"]
        >
      ) => (
        <GridField $alignCenter>
          <Typography textAlign="center" whiteSpace="normal" fontSize="14px">
            {params.value}
          </Typography>
        </GridField>
      ),
    },
    {
      field: "prazoContratual",
      headerName: "Prazo contratual",
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<
          CaptadorListItemTabela,
          CaptadorListItemTabela["prazoContratual"]
        >
      ) => (
        <GridField>
          {params.value
            ? new Date(String(params.value)).toLocaleDateString()
            : "Indeterminado"}
        </GridField>
      ),
    },
    {
      field: "backOfficeNome",
      headerName: "Backoffice",
      flex: 1,
      align: "center",
      renderCell: (
        params: GridRenderCellParams<
          CaptadorListItemTabela,
          CaptadorListItemTabela["backOfficeNome"]
        >
      ) => (
        <GridField>
          <Typography style={{ whiteSpace: "normal", fontSize: 14 }}>
            {params.value}
          </Typography>
        </GridField>
      ),
    },
    {
      field: "statusContrato",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (
        params: GridRenderCellParams<
          CaptadorListItemTabela,
          CaptadorListItemTabela["statusContrato"]
        >
      ) => (
        <GridField $alignCenter>
          <Tooltip title={params.value}>
            <Circle
              color={
                StatusContratoParceiroColorMap[
                  params.value ?? EnumStatusContratoParceiro.lead
                ]
              }
            />
          </Tooltip>
        </GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <GridField $alignCenter>
          <DefaultGridButton
            onClick={() => buscaDadosModal(params.row.captadorId)}
          >
            Ver mais
          </DefaultGridButton>
        </GridField>
      ),
    },
  ];

  return buscandoDadosInicial ? (
    <SkeletonDefaultPage />
  ) : (
    <Box display="flex" flexDirection="column" gap="16px">
      <Typography
        fontSize="24px"
        margin="0"
        fontWeight="400"
        color="#222222"
        variant="h2"
      >
        Status
      </Typography>
      <Box display="flex" width="100%" gap="16px" flexWrap="wrap">
        <Box display="flex" flex="1" flexBasis="16%" gap="16px">
          {listaStatus.map((status) => (
            <StatusCard
              key={status.id}
              text={status.texto}
              color={status.cor}
              number={statusContrato[status.id] ?? 0}
            />
          ))}
        </Box>
      </Box>
      <DataArea $align="right">
        <Datatable
          columns={columns}
          rows={listarCaptadores}
          pageNumber={pagina}
          pageSize={tamanhoPagina}
          onChangePage={onChangePage}
          onChangePageSize={onChangePageSize}
          onSearch={onSearch}
          totalPages={totalPages}
          hasButton
          titleButton="NOVO PARCEIRO"
          getRowId={(x) => x.idReferencial}
          handleButton={handleNovoParceiro}
        />
      </DataArea>
      {modalDados && (
        <ModalCaptador
          captador={modalDados}
          handleClose={() => setModalDados(null)}
          criarNovoLog={parceiroManager.criarNovoLog}
          buscarHistorico={parceiroManager.buscarHistorico}
          backOffices={backOffices}
          captadores={captadores}
          updateInformacaoAssociado={parceiroManager.updateInformacaoAssociado}
          updateResponsavelLegal={parceiroManager.updateResponsavelLegal}
        />
      )}
    </Box>
  );
};

export default Parceiro;
