import { IPaginatedList } from "../../models/PaginatedList";
import {
  ICreateGDProposta,
  IFullGDProposta,
  IGDProposta,
  IGDPropostaPaged,
  IGDPropostaRelatoriosFilters,
  IGetCalculoParams,
  IGetCalculoResponse,
  IGetMessageParams,
  IPDFPropostaResponse,
  IUpdateGDProposta,
} from "../../models/GDProposta";
import http from "../common/http-common";
import { AxiosResponse } from "axios";
import { EnumStatusGDProposta } from "../../enums/EnumStatusGDProposta.enum";
import { StatusDashboard } from "../../models/StatusDashboard";

export const GetStats = () => {
  return http.get<StatusDashboard<EnumStatusGDProposta>[]>(
    `/GDProposta/StatsDashboard`
  );
};

export const GetPropostas = (
  id: number | null,
  instalacao: string | null | undefined,
  name: string | null | undefined,
  pageNumber: number,
  pageSize: number,
  search?: string,
  filterStatusGDPropostas?: EnumStatusGDProposta | null
) => {
  var searchString: string = "";
  let url = "/GDProposta";
  const params = [];

  if (id) params.push(`Id=${id}`);
  if (instalacao) params.push(`Instalacao=${instalacao}`);
  if (name) params.push(`Name=${name}`);
  if (pageSize) params.push(`PageSize=${pageSize}`);
  if (pageNumber) params.push(`PageNumber=${pageNumber}`);
  if (search) params.push(`Search=${search}`);
  if (filterStatusGDPropostas !== null)
    params.push(`StatusGDProposta=${filterStatusGDPropostas}`);

  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }
  return http.get<IPaginatedList<IGDPropostaPaged>>(url);
};

export const GetPropostaById: (
  id: string
) => Promise<AxiosResponse<IGDProposta>> = (id: string) => {
  return http.get<IGDProposta>(`/GDProposta/${id}`);
};

export const GetFullPropostaById = (id: string) => {
  return http.get<IFullGDProposta>(`/GDProposta/GetFullGDPropostaById/${id}`);
};

export const UpdateGDProposta = (updateGDProposta: IUpdateGDProposta) => {
  return http.put(`/GDProposta`, updateGDProposta);
};

export const CreateGDProposta = (createGDProposta: ICreateGDProposta) => {
  return http.post<IGDProposta>(`/GDProposta`, createGDProposta);
};

export const GetPropostaPDFWattwise = (id: string) => {
  return http.get<IPDFPropostaResponse>(`/GDProposta/GetDadosToPdf/${id}`);
};

export const GetCalculoGDProposta = (getCalculoData: IGetCalculoParams) => {
  return http.get<IGetCalculoResponse>(
    `/GDProposta/GetCalculoGDProposta?TipoConexao=${getCalculoData.tipoConexao}&ValorConta=${getCalculoData.valorConta}&IluminacaoPublica=${getCalculoData.iluminacaoPublica}&ConsumoMensalkWh=${getCalculoData.consumoMensalkWh}&TarifaDistribuidora=${getCalculoData.tarifaDistribuidora}&PisConfins=${getCalculoData.pisConfins}&PercentualDesconto=${getCalculoData.percentualDesconto}`
  );
};

export const Send2Email = (formData: FormData) => {
  return http.post<void>("/GDProposta/SendEmail", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const Send2Whatsapp = (getMessageParams: IGetMessageParams) => {
  const ctCd = encodeURIComponent(getMessageParams.countryCode);
  return http.get<string>(
    `/GDProposta/GetLinkEnvioGDProposta?PropostaId=${getMessageParams.propostaId}&CountryCode=${ctCd}&Celular=${getMessageParams.celular}&IsCopyText=${getMessageParams.isCopyText}`
  );
};

export const GeraRelatorio = (filters: IGDPropostaRelatoriosFilters) => {
  const queryParams: string[] = [];

  if (filters.status !== undefined) {
    queryParams.push(`EnumStatusGDProposta=${filters.status}`);
  }

  if (filters.captadorId) {
    queryParams.push(`CaptadorId=${filters.captadorId}`);
  }

  if (filters.backofficeId) {
    queryParams.push(`BackofficeId=${filters.backofficeId}`);
  }

  if (filters.dataInicio) {
    queryParams.push(`DataInicio=${filters.dataInicio}`);
  }

  if (filters.dataFim) {
    queryParams.push(`DataFim=${filters.dataFim}`);
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

  return http.get<File>(`/GDProposta/BuscaRelatorioPropostas${queryString}`, {
    responseType: "blob",
  });
};
