import { FC, useCallback, useState } from "react";
import { EnumConvenio } from "../../enums/EnumConvenio.enum";
import { EnumTipoParceiro } from "../../enums/EnumTipoParceiro.enum";
import * as C from "../../pages/private/Associados/EditarAssociado/style";
import RegisterCard from "../RegisterCard";
import { Autocomplete, TextField } from "@mui/material";
import { IBackofficeListItem } from "../../models/Colaborador/Colaborador";
import { ContainedButton } from "../../styles/appComponents";
import SelectEnum from "../Select/SelectEnum";
import { ICaptador, ICaptadorListItem } from "../../models/Captador";
import { InformacaoAssociado } from "../../models/InformacaoAssociado";
import SelectInput from "../Select/SelectInput";
import TabelaAssociado from "../TabelaAssociado/TabelaAssociado";
import { IPaginatedList } from "../../models/PaginatedList";
import { IAssociadoListItem } from "../../models/Associado";

interface IInformacaoAssociadoProps {
  captador: ICaptador;
  backOffices: IBackofficeListItem[];
  disabled?: boolean;
  captadores: ICaptadorListItem[];
  getAssociados?: (
    pageNumber: number,
    pageSize: number,
    search: string
  ) => Promise<IPaginatedList<IAssociadoListItem> | null>;
  tipoParceiro?: EnumTipoParceiro | null;
  updateInformacaoAssociado?: (
    informacaoAssociado: InformacaoAssociado
  ) => Promise<boolean>;
}

export const InformacaoAssociadoInput: FC<IInformacaoAssociadoProps> = ({
  captador,
  backOffices,
  disabled = false,
  captadores,
  tipoParceiro = null,
  getAssociados,
  updateInformacaoAssociado,
}) => {
  const [informacaoAssociado, setInformacaoAssociado] =
    useState<InformacaoAssociado>({
      captadorId: captador.id,
      tipoParceiro: captador.tipoParceiro,
      convenio: captador.enumConvenio ?? null,
      backOfficeId: captador.backofficeId ?? "",
      captadorMasterId: captador.parceiroMasterId ?? "",
    });

  const [editar, setEditar] = useState<boolean>(false);
  const [associados, setAssociados] = useState<IAssociadoListItem[]>([]);
  const [tamanhoPagina, setTamanhoPagina] = useState<number>(5);
  const [paginaNumero, setPaginaNumero] = useState<number>(1);
  const [totalPagina, setTotalPagina] = useState<number>(0);
  const [pesquisa, setPesquisa] = useState<string>("");
  const [salvando, setSalvando] = useState<boolean>(false);

  const buscarAssociados = useCallback(
    async (pagina: number, tamanho: number, pesquisa: string) => {
      const paginaAssociados =
        getAssociados && (await getAssociados(pagina, tamanho, pesquisa));
      setTamanhoPagina(tamanho);
      setPesquisa(pesquisa);
      setPaginaNumero(pagina);
      if (paginaAssociados) {
        setAssociados(paginaAssociados.data);
        setTotalPagina(paginaAssociados.totalPages);
      }
    },
    [getAssociados]
  );

  const handleSalvar = async () => {
    setSalvando(true);
    if (await updateInformacaoAssociado?.(informacaoAssociado))
      setEditar(false);
    setSalvando(false);
  };

  return (
    <RegisterCard title="Informações do associado">
      <C.FWStack direction={"row"} spacing={2}>
        <SelectEnum
          label="Tipo de associado"
          values={EnumTipoParceiro}
          value={informacaoAssociado.tipoParceiro}
          onChange={(e) => {
            setInformacaoAssociado({
              ...informacaoAssociado,
              tipoParceiro: e,
              captadorMasterId: "",
            });
          }}
          disabled={
            disabled ||
            !editar ||
            tipoParceiro === EnumTipoParceiro.parceiroMaster ||
            associados.length > 0
          }
        />
        <SelectInput
          label="Parceiro Master"
          value={informacaoAssociado.captadorMasterId}
          values={captadores}
          getValue={(x) => x.id}
          getRender={(x) => x.nome}
          onChange={(x) =>
            setInformacaoAssociado({
              ...informacaoAssociado,
              captadorMasterId: x ?? "",
            })
          }
          disabled={
            disabled ||
            !editar ||
            informacaoAssociado.tipoParceiro === EnumTipoParceiro.parceiroMaster
          }
        />
        <SelectEnum
          label="Convênio"
          values={EnumConvenio}
          value={informacaoAssociado.convenio}
          onChange={(e) =>
            setInformacaoAssociado({ ...informacaoAssociado, convenio: e })
          }
          disabled={disabled || !editar}
        />
        <Autocomplete
          options={backOffices}
          fullWidth
          noOptionsText="Nenhum backoffice encontrado"
          getOptionLabel={(option) => option.nome}
          renderInput={(params) => <TextField {...params} label="Backoffice" />}
          onChange={(_, newValue) =>
            setInformacaoAssociado({
              ...informacaoAssociado,
              backOfficeId: newValue?.id ?? "",
            })
          }
          value={
            backOffices.find(
              (x) => x.id === informacaoAssociado.backOfficeId
            ) ?? null
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disabled={disabled || !editar}
        />
      </C.FWStack>
      {(tipoParceiro === EnumTipoParceiro.associadoMaster ||
        tipoParceiro === EnumTipoParceiro.parceiroMaster) && (
        <C.FWStack>
          <TabelaAssociado
            getAssociados={buscarAssociados}
            associados={associados}
            tamanhoPagina={tamanhoPagina}
            paginaNumero={paginaNumero}
            totalPagina={totalPagina}
            pesquisa={pesquisa}
          />
        </C.FWStack>
      )}
      <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <ContainedButton
          $color="yellow"
          disabled={disabled || salvando || editar}
          onClick={() => setEditar(true)}
        >
          Editar
        </ContainedButton>
        <ContainedButton
          $color="pink"
          disabled={disabled || salvando || !editar}
          onClick={handleSalvar}
        >
          Salvar
        </ContainedButton>
      </C.FWStack>
    </RegisterCard>
  );
};
