import {ColorType} from "../types/ColorType";
import {StatusBuilder} from "../models/StatusBuilder";

export enum EnumStatusLead {
  leads = 0,
  aguardandoEnvioConta = 1,
  contaAnexada = 2,
  propostaGerada = 3,
  cancelada = 4,
}

export enum EnumStatusLeadMapSelect {
  leads = "Leads",
  aguardandoEnvioConta = "Aguardando envio conta",
  contaAnexada = "Conta Anexada",
  propostaGerada = "Proposta Gerada",
  cancelada = "Cancelada",
}

export interface StatusGDLeads {
  totalStatus:number;
  enumStatus:EnumStatusLead;
}

export const EnumStatusLeadNumericMapSelect = {
  [EnumStatusLeadMapSelect.leads]: 0,
  [EnumStatusLeadMapSelect.aguardandoEnvioConta]: 1,
  [EnumStatusLeadMapSelect.contaAnexada]: 2,
  [EnumStatusLeadMapSelect.propostaGerada]: 3,
  [EnumStatusLeadMapSelect.cancelada]: 4,
};

export const EnumStatusLeadNumericMap = {
  [EnumStatusLead.leads]: 0,
  [EnumStatusLead.aguardandoEnvioConta]: 1,
  [EnumStatusLead.contaAnexada]: 2,
  [EnumStatusLead.propostaGerada]: 3,
  [EnumStatusLead.cancelada]: 4,
};

export const EnumStatusLeadMap:StatusBuilder[] = [
  {
    name:"Leads",
    value: EnumStatusLead.leads,
    color: "blue",
    primary: true
  },
  {
    name:"Aguardando envio de conta",
    value: EnumStatusLead.aguardandoEnvioConta,
    color: "yellow",
    primary: true
  },
  {
    name:"Conta anexada",
    value: EnumStatusLead.contaAnexada,
    color: "green",
    primary: true
  },
  {
    name:"Proposta Gerada",
    value: EnumStatusLead.propostaGerada,
    color: "purple",
    primary: true
  },
  {
    name:"Cancelado",
    value: EnumStatusLead.cancelada,
    color: "red",
    primary: true
  },
];

export const EnumStatusLeadColorMap: Record<number, ColorType> = {
  0: "blue",
  1: "yellow",
  2: "green",
  3: "purple",
  4: "red",
};
