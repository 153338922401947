import { IconButton, Stack, TextField, Typography, Button, Box } from "@mui/material";
import { useReducer } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import React from "react";
import RegisterCard from "../../../../../components/RegisterCard";
import * as D from "../../../../../styles/appComponents";
import { GridColDef } from "@mui/x-data-grid";

enum ActionType {
  ADD_DOCUMENT = "ADD_DOCUMENT",
  DELETE_DOCUMENT = "DELETE_DOCUMENT",
}

interface Document {
  id: number;
  responsavel: string;
  assunto: string;
  dataUpload: string;
}

export interface IAnexoDocumentos {
  documents: Document[];
}

type Action =
  | { type: ActionType.ADD_DOCUMENT; payload: Document }
  | { type: ActionType.DELETE_DOCUMENT; payload: number };

type PropsAnexoDocumentos = {
  onSave: (data: IAnexoDocumentos) => void;
  onUpdate: (data: IAnexoDocumentos) => void;
  onChange: (data: IAnexoDocumentos) => void;
  data?: object | null;
};

export const AnexoDocumentos = (props: PropsAnexoDocumentos) => {
  const reducer = (state: IAnexoDocumentos, action: Action): IAnexoDocumentos => {
    const newState = (() => {
      switch (action.type) {
        case ActionType.ADD_DOCUMENT:
          return { ...state, documents: [...state.documents, action.payload] };
        case ActionType.DELETE_DOCUMENT:
          return { ...state, documents: state.documents.filter(doc => doc.id !== action.payload) };
        default:
          return state;
      }
    })();

    sendOnChange(newState);
    return newState;
  };

  const [state, dispatch] = useReducer(reducer, { documents: [] });

  const sendOnChange = (update: IAnexoDocumentos): void => props.onChange(update);

  const handleAddDocument = () => {
    const newDocument: Document = {
      id: Date.now(),
      responsavel: "Cooperativa",
      assunto: "Novo Assunto",
      dataUpload: new Date().toLocaleDateString(),
    };
    dispatch({ type: ActionType.ADD_DOCUMENT, payload: newDocument });
  };

  const handleDelete = (id: number) => {
    dispatch({ type: ActionType.DELETE_DOCUMENT, payload: id });
  };

  const sendOnSave = (): void => props.onSave(state);
  const sendOnUpdate = (): void => props.onUpdate(state);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID" },
    {
      field: "responsavel",
      headerName: "Responsável",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography style={{ whiteSpace: "normal", fontSize: 14 }}>
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "dataUpload",
      headerName: "Data Upload",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography style={{ whiteSpace: "normal", fontSize: 14 }}>
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    { field: "anexo", headerName: "Anexo", flex: 1 },
    {
      field: "remover",
      headerName: "Remover",
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleDelete(params.row.id)}>
          <Typography color="error">Remover</Typography>
        </IconButton>
      ),
    },
  ];

  return (
    <RegisterCard title="Anexos de documentos">
      <Stack direction={"row"} spacing={8}>
        <Stack
          direction={"column"}
          spacing={2}
          style={{ flex: 1, backgroundColor: "#F9F9F9", padding: 16, borderRadius: 8 }}
        >
          <Typography variant="subtitle1" gutterBottom>
            <FileUploadIcon style={{ marginRight: 8 }} />
            Upload de arquivos
          </Typography>

          <TextField
            label="Assunto"
            fullWidth
            InputLabelProps={{
              style: { color: "#666666" },
            }}
            InputProps={{
              style: { backgroundColor: "#FFFFFF", borderRadius: 4 },
            }}
          />

          <TextField
            label="Descrição do documento"
            multiline
            rows={3}
            fullWidth
            InputLabelProps={{
              style: { color: "#666666" },
            }}
            InputProps={{
              style: { backgroundColor: "#FFFFFF", borderRadius: 4 },
            }}
          />

          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Button
              variant="contained"
              startIcon={<FileUploadIcon />}
              color="primary"
              component="label"
              style={{ backgroundColor: "#0061F2", textTransform: "none", marginRight: 16 }}
              onClick={handleAddDocument}
            >
              Anexar documento
              <input type="file" hidden />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              style={{ backgroundColor: "#E91E63", textTransform: "none" }}
            >
              Anexar
            </Button>
          </Box>
        </Stack>

        <Stack
          direction={"column"}
          spacing={2}
          style={{ flex: 1, backgroundColor: "#F9F9F9", padding: 16, borderRadius: 8 }}
        >
          <Typography variant="h6">Histórico de cooperativa</Typography>
          <D.DefaultTable
            rows={state.documents}
            columns={columns}
            disableColumnResize
            disableRowSelectionOnClick
            hideFooter
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
          />
        </Stack>
      </Stack>

      {props.data !== null && props.data !== undefined ? (
        <Stack direction={"row"} spacing={2} justifyContent={"flex-end"} mt={4}>
          <Button variant="contained" color="warning" onClick={sendOnUpdate}>
            Editar
          </Button>
          <Button variant="contained" color="secondary" onClick={sendOnSave}>
            Salvar
          </Button>
        </Stack>
      ) : null}
    </RegisterCard>
  );
};
