import RegisterCard from "../../../../../../components/RegisterCard";
import React, {FC, useEffect, useState} from "react";
import * as G from "../../style";
import {Chip, MenuItem, TextField} from "@mui/material";
import * as D from "../../../../../../styles/appComponents";
import {EnumSexo} from "../../../../../../enums/EnumSexo.enum";
import * as C from "../Situacao/style";
import {IFormDadosPessoais} from "../../../../../../models/Colaborador/cadastros/DadosPessoais";
import {ErrorMessage} from "../../../../../../utils/AbstractValidation";
import {handleStyleFieldError} from "../../../../../../utils/verifyFieldError";
import {EnumTipoChavePix} from "../../../../../../enums/Gerador/EnumTipoChavePix.enum";
import {ColaboradorByIdResponse} from "../../../../../../models/Colaborador/Colaborador";
import {EnumGrauInstrucao, enumGrauInstrucaoMap} from "../../../../../../enums/Colaborador/EnumGrauInstrucao.enum";
import {EnumEstadoCivil, EnumEstadoCivilMap} from "../../../../../../enums/Colaborador/EnumEstadoCivil.enum";
import MaskDefaultInput from "../../../../../../components/MasDefaultinput/MaskDefaultInput";

enum FormDadosPessoais {
  NOME = "NOME",
  DATANASCIMENTO = "DATANASCIMENTO",
  SEXO = "SEXO",
  ESTADOCIVIL = "ESTADOCIVIL",
  GRAUINSTRUCAO = "GRAUINSTRUCAO",
  MUNICIPIONASCIMENTO = "MUNICIPIONASCIMENTO",
  NACIONALIDADE = "NACIONALIDADE",
  DATAADMISSAO = "DATAADMISSAO",
  CEP = "CEP",
  UF = "UF",
  CIDADE = "CIDADE",
  BAIRRO = "BAIRRO",
  LOGRADOURO = "LOGRADOURO",
  NUMERO = "NUMERO",
  COMPLEMENTO = "COMPLEMENTO",
  CELULARPARACONTATO = "CELULARPARACONTATO",
  COUNTRYCODE = "COUNTRYCODE",
  EMAILPARACONTATO = "EMAILPARACONTATO",
  BANCO = "BANCO",
  AGENCIA = "AGENCIA",
  CONTA = "CONTA",
  CPFFAVORECIDO = "CPFFAVORECIDO",
  FAVORECIDO = "FAVORECIDO",
  TIPOCHAVEPIX = "TIPOCHAVEPIX",
  CHAVEPIX = "CHAVEPIX",
}

type PropsDadosPessoais = {
  onSave: (data: IFormDadosPessoais) => void;
  onUpdate: () => void;
  onChange: (data: IFormDadosPessoais) => void;
  buttonSave: boolean;
  fieldErros: ErrorMessage[] | null;
  cities: string[];
  data?: IFormDadosPessoais | null;
  colaboradorId: string | undefined;
  colaborador: ColaboradorByIdResponse | undefined;
  load: { status:boolean, section:string };
};

const DadosPessoais:FC<PropsDadosPessoais> = (
  {
    onSave,
    onUpdate,
    onChange,
    buttonSave,
    cities,
    fieldErros,
    colaborador,
    colaboradorId,
    load

  }) => {
  const dadosPessoaisFormModel:IFormDadosPessoais = {
    nome: null,
    datanascimento: null,
    sexo: EnumSexo.masculino,
    estadocivil: EnumEstadoCivil.solteiro,
    grauinstrucao: EnumGrauInstrucao.ensinoMedioCompleto,
    municipionascimento: null,
    nacionalidade: null,
    dataadmissao: null,
    cep: null,
    uf: null,
    cidade: null,
    bairro: null,
    logradouro: null,
    numero: null,
    complemento: null,
    celularparacontato: null,
    countrycode: "+55",
    emailparacontato: null,
    banco: null,
    agencia: null,
    conta: null,
    cpffavorecido: null,
    favorecido: null,
    tipochavepix: EnumTipoChavePix.CPF,
    chavepix: null,
  };
  const [dadosPessoais, setDadosPessoais] = useState<IFormDadosPessoais>(dadosPessoaisFormModel)
  const sendOnSave = (): void => onSave(dadosPessoais);
  const sendOnUpdate = (): void => onUpdate();
  const sendOnChange = (update: IFormDadosPessoais): void => onChange(update);

  useEffect(() => {
    const dadosPessoaisFormModel:IFormDadosPessoais = {
      nome: colaborador?.nome,
      datanascimento: colaborador?.dataNascimento,
      sexo: colaborador?.sexo,
      estadocivil: colaborador?.estadoCivil as EnumEstadoCivil,
      grauinstrucao: colaborador?.grauInstrucao as EnumGrauInstrucao,
      municipionascimento: colaborador?.municipioNascimento,
      nacionalidade: colaborador?.nacionalidade,
      dataadmissao: colaborador?.dataAdmissao,
      cep: colaborador?.cep,
      uf: colaborador?.uf,
      cidade: colaborador?.cidade,
      bairro: colaborador?.bairro,
      logradouro: colaborador?.logradouro,
      numero: colaborador?.numero,
      complemento: colaborador?.complemento,
      celularparacontato: colaborador?.celularParaContato,
      countrycode: colaborador?.countryCode,
      emailparacontato: colaborador?.emailParaContato,
      banco: colaborador?.banco,
      agencia: colaborador?.agencia,
      conta: colaborador?.conta,
      cpffavorecido: colaborador?.cpfFavorecido,
      favorecido: colaborador?.favorecido,
      tipochavepix: colaborador?.tipoChavePix as EnumTipoChavePix | undefined,
      chavepix: colaborador?.chavePix,
    };
    setDadosPessoais(dadosPessoaisFormModel);

  }, [colaboradorId, colaborador])

  const handleInputChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    const name = target.name.toLocaleLowerCase();
    const value = target.value;

    setDadosPessoais((state:IFormDadosPessoais) => {
      sendOnChange({...state, [name]: value});
      return {...state, [name]: value};

    });
  }

  return (
    <RegisterCard title="Dados pessoais">
      <G.FWStack direction={"row"} spacing={2}>
        <TextField
          sx={handleStyleFieldError(FormDadosPessoais.NOME, fieldErros)} 
          inputProps={{ readOnly: buttonSave }} 
          label="Nome"
          value={dadosPessoais?.nome ?? ""} 
          fullWidth
          name={FormDadosPessoais.NOME}
          onChange={handleInputChange} 
        />
        <D.DateTextfield
          sx={handleStyleFieldError(FormDadosPessoais.DATANASCIMENTO, fieldErros)}  
          inputProps={{ readOnly: buttonSave }} 
          type="date"
          label="Data de nascimento" 
          fullWidth 
          name={FormDadosPessoais.DATANASCIMENTO}
          onChange={handleInputChange}
          value={
            ((dadosPessoais?.datanascimento ?? "")
            .toString())?.split("T")[0]
          }
          InputLabelProps={{
            shrink: true,
          }} 
        />
        <TextField
          sx={handleStyleFieldError(FormDadosPessoais.SEXO, fieldErros)}  
          inputProps={{ readOnly: buttonSave }}
          value={dadosPessoais?.sexo ?? ""} 
          select 
          label="Sexo" 
          fullWidth
          name={FormDadosPessoais.SEXO}
          onChange={handleInputChange}  
        >
          {Object.values(EnumSexo).map((value, i) => (
            <MenuItem key={i} value={i}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          sx={handleStyleFieldError(FormDadosPessoais.ESTADOCIVIL, fieldErros)}  
          inputProps={{ readOnly: buttonSave }}
          value={dadosPessoais?.estadocivil ?? ""} 
          select 
          label="Estado civil" 
          fullWidth 
          name={FormDadosPessoais.ESTADOCIVIL}
          onChange={handleInputChange} 
        >
          {EnumEstadoCivilMap.map((x, i) => (
            <MenuItem key={i} value={x.value}>
              {x.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          sx={handleStyleFieldError(FormDadosPessoais.GRAUINSTRUCAO, fieldErros)}  
          inputProps={{ readOnly: buttonSave }}
          value={dadosPessoais?.grauinstrucao ?? ""}  
          select 
          label="Grau de instrução" 
          fullWidth 
          name={FormDadosPessoais.GRAUINSTRUCAO}
          onChange={handleInputChange}
        >
          {enumGrauInstrucaoMap.map((x, i) => (
            <MenuItem key={i} value={x.value}>
              {x.name}
            </MenuItem>
          ))}
        </TextField>
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={2}>
        <TextField
          sx={handleStyleFieldError(FormDadosPessoais.MUNICIPIONASCIMENTO, fieldErros, { width:"30%" })}  
          inputProps={{ readOnly: buttonSave }}  
          label="Município de nascimento"
          value={dadosPessoais?.municipionascimento ?? ""}  
          name={FormDadosPessoais.MUNICIPIONASCIMENTO}
          onChange={handleInputChange}
          fullWidth
        />
        <TextField
          sx={handleStyleFieldError(FormDadosPessoais.NACIONALIDADE, fieldErros, { width:"30%" })}  
          inputProps={{ readOnly: buttonSave }}
          value={dadosPessoais?.nacionalidade ?? ""}  
          label="Nacionalidade" 
          name={FormDadosPessoais.NACIONALIDADE}
          onChange={handleInputChange}
          fullWidth
         />
        <D.DateTextfield
          sx={handleStyleFieldError(FormDadosPessoais.DATAADMISSAO, fieldErros, { width:"30%" })}  
          inputProps={{ readOnly: buttonSave }}
          type="date"
          label="Data de admissão" 
          name={FormDadosPessoais.DATAADMISSAO}
          onChange={handleInputChange}
          value={
            ((dadosPessoais?.dataadmissao ?? "")
            .toString())?.split("T")[0]
          }
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth 
        />
        <MaskDefaultInput
          type="CEP"
          label="CEP"
          value={dadosPessoais?.cep ?? ""} 
          onChange={(value:any) => {
            handleInputChange(
              { 
                target:{ name:FormDadosPessoais.CEP, value: value?.value  } 
              } as React.ChangeEvent<HTMLInputElement>
            );
            handleInputChange(
              { 
                target:{ name:FormDadosPessoais.CIDADE, value: value?.localidade  } 
              } as React.ChangeEvent<HTMLInputElement>
            );
            handleInputChange(
              { 
                target:{ name:FormDadosPessoais.UF, value: value?.uf  } 
              } as React.ChangeEvent<HTMLInputElement>
            );
            handleInputChange(
              { 
                target:{ name:FormDadosPessoais.LOGRADOURO, value: value?.logradouro  } 
              } as React.ChangeEvent<HTMLInputElement>
            );
            }
          }
          sx={handleStyleFieldError(FormDadosPessoais.CEP, fieldErros, { Width:"100%" })} 
          />
        <TextField
          disabled={buttonSave}
          label="UF"
          value={dadosPessoais?.uf ?? ""} 
          sx={handleStyleFieldError(FormDadosPessoais.UF, fieldErros, { Width:"30%" })}  
          inputProps={{ readOnly: buttonSave }}  
          name={FormDadosPessoais.UF}
          onChange={handleInputChange}
        >
        </TextField>
        <TextField
          sx={handleStyleFieldError(FormDadosPessoais.CIDADE, fieldErros, { width:"30%" })}  
          inputProps={{ readOnly: buttonSave }}
          value={dadosPessoais?.cidade ?? ""} 
          label="Cidade"  
          name={FormDadosPessoais.CIDADE}
          onChange={handleInputChange}
        >
        </TextField>
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={2}>
        <TextField
          sx={handleStyleFieldError(FormDadosPessoais.BAIRRO, fieldErros)}  
          inputProps={{ readOnly: buttonSave }}
          value={dadosPessoais?.bairro ?? ""}  
          label="Bairro" 
          fullWidth 
          name={FormDadosPessoais.BAIRRO}
          onChange={handleInputChange}
        />
        <TextField
          sx={handleStyleFieldError(FormDadosPessoais.LOGRADOURO, fieldErros)}  
          inputProps={{ readOnly: buttonSave }} 
          label="Logradouro"
          value={dadosPessoais?.logradouro ?? ""} 
          fullWidth 
          name={FormDadosPessoais.LOGRADOURO}
          onChange={handleInputChange}
        />
        <TextField 
          disabled={buttonSave}
          type="number" 
          label="Número"
          value={dadosPessoais?.numero ?? ""} 
          sx={handleStyleFieldError(FormDadosPessoais.NUMERO, fieldErros, {width:"30%"})}  
          inputProps={{ readOnly: buttonSave }}
          name={FormDadosPessoais.NUMERO}
          onChange={handleInputChange}
        />
        <TextField
          sx={handleStyleFieldError(FormDadosPessoais.COMPLEMENTO, fieldErros)}  
          inputProps={{ readOnly: buttonSave }}  
          label="Complemento"
          value={dadosPessoais?.complemento ?? ""} 
          fullWidth 
          name={FormDadosPessoais.COMPLEMENTO}
          onChange={handleInputChange}
        />
      </G.FWStack>

      <G.FWStack direction={"row"} spacing={2}>
        <MaskDefaultInput
          type="CELULAR"
          label="Celular"
          readonly={buttonSave}
          value={dadosPessoais?.celularparacontato ?? ""} 
          valueCountryCode={dadosPessoais?.countrycode ?? ""}
          onChange={(value:any) =>
              {
                  handleInputChange(
                      { 
                          target:{ name:FormDadosPessoais.CELULARPARACONTATO, value:value?.value } 
                      } as React.ChangeEvent<HTMLInputElement>);

                  handleInputChange(
                      { 
                          target:{ name:FormDadosPessoais.COUNTRYCODE, value:value?.zipCode } 
                      } as React.ChangeEvent<HTMLInputElement>);
              }

          }
          sx={handleStyleFieldError(
              FormDadosPessoais.CELULARPARACONTATO,
              fieldErros
          )}
      />
        <TextField
          sx={handleStyleFieldError(FormDadosPessoais.EMAILPARACONTATO, fieldErros)}  
          inputProps={{ readOnly: buttonSave }} 
          label="Email Pessoal"
          value={dadosPessoais?.emailparacontato ?? ""}  
          name={FormDadosPessoais.EMAILPARACONTATO}
          onChange={handleInputChange}
        />
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={16}>
        <G.MWDiv>
          <G.Subtitle>Dados bancários*</G.Subtitle>
          <G.Text>Insira seus dados bancários</G.Text>
          <G.FWStack direction={"row"} spacing={2}>
            <TextField
              sx={handleStyleFieldError(FormDadosPessoais.BANCO, fieldErros)}  
              inputProps={{ readOnly: buttonSave }} 
              label="Banco"
              value={dadosPessoais?.banco ?? ""} 
              required 
              fullWidth
              name={FormDadosPessoais.BANCO}
              onChange={handleInputChange}
            />
            <TextField
              sx={handleStyleFieldError(FormDadosPessoais.AGENCIA, fieldErros)}  
              inputProps={{ readOnly: buttonSave }} 
              label="Agência"
              type="number"
              value={dadosPessoais?.agencia ?? ""} 
              required 
              fullWidth 
              name={FormDadosPessoais.AGENCIA}
              onChange={handleInputChange} 
            />
          </G.FWStack>
          <G.FWStack direction={"row"} spacing={2}>
            <TextField
              sx={handleStyleFieldError(FormDadosPessoais.CONTA, fieldErros)}  
              inputProps={{ readOnly: buttonSave }}  
              label="Conta"
              type="number"
              value={dadosPessoais?.conta ?? ""} 
              required 
              fullWidth 
              name={FormDadosPessoais.CONTA}
              onChange={handleInputChange}
            />
            <MaskDefaultInput
              type="CPF"
              label="CPF"
              value={dadosPessoais?.cpffavorecido ?? ""} 
              onChange={(value) => handleInputChange(
                  { 
                      target:{ name:FormDadosPessoais.CPFFAVORECIDO, value } 
                  } as React.ChangeEvent<HTMLInputElement>)}
              sx={handleStyleFieldError(FormDadosPessoais.CPFFAVORECIDO, fieldErros)} 
            />
          </G.FWStack>
          <G.FWStack direction={"row"} spacing={2}>
            <TextField
              sx={handleStyleFieldError(FormDadosPessoais.FAVORECIDO, fieldErros)}  
              inputProps={{ readOnly: buttonSave }}  
              label="Favorecido"
              value={dadosPessoais?.favorecido ?? ""} 
              required 
              fullWidth 
              name={FormDadosPessoais.FAVORECIDO}
              onChange={handleInputChange}
            />
            <G.FWDiv></G.FWDiv>
          </G.FWStack>
        </G.MWDiv>
        <G.MWDiv>
          <G.Subtitle>Pix*</G.Subtitle>
          <G.Text>Qual dado você quer usar?</G.Text>
          <G.FWStack direction="row" spacing={2}>
          {Object.entries(EnumTipoChavePix)
            .filter(([key, value]) => isNaN(Number(key)))
            .map(([key, value]) => (
              <Chip
                disabled={buttonSave}
                label={key}
                onClick={() =>
                  handleInputChange({
                    target: {
                      name: FormDadosPessoais.TIPOCHAVEPIX,
                      value,
                    },
                  } as React.ChangeEvent<HTMLInputElement>)
                }
                color={
                  dadosPessoais?.tipochavepix ===
                  value
                    ? "primary"
                    : "default"
                }
              />
            ))}
          </G.FWStack>
          <G.Text>Insira sua chave pix</G.Text>
          <G.FWStack direction={"row"} spacing={2}>
            <TextField
              sx={handleStyleFieldError(FormDadosPessoais.CHAVEPIX, fieldErros)}  
              inputProps={{ readOnly: buttonSave }}
              label="Chave PIX"
              disabled={
                dadosPessoais.tipochavepix === undefined ||
                dadosPessoais.tipochavepix === null
              }
              value={dadosPessoais?.chavepix ?? ""} 
              required 
              fullWidth 
              name={FormDadosPessoais.CHAVEPIX}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }} 
              type={
                dadosPessoais.tipochavepix ===
                  EnumTipoChavePix.CELULAR ||
                  dadosPessoais.tipochavepix ===
                  EnumTipoChavePix.CPF ||
                  dadosPessoais.tipochavepix ===
                  EnumTipoChavePix.CNPJ
                  ? "number"
                  : "text"
              }
             />
          </G.FWStack>
        </G.MWDiv>
      </G.FWStack>
      <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton disabled={!buttonSave} $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
        <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>Salvar</D.ContainedButton>
      </C.FWStack>
    </RegisterCard>
  );
};

export default DadosPessoais;
