import { TextField } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import MaskDefaultInput from "../../../../../../components/MasDefaultinput/MaskDefaultInput";
import RegisterCard from "../../../../../../components/RegisterCard";
import { useObjectState } from "../../../../../../hooks/useObjectState";
import {
  IEndereco,
  IUpdateEndereco,
} from "../../../../../../models/GDContrato";
import { ICepReturn } from "../../../../../../models/IbgeService";
import * as service from "../../../../../../services/api/GDContratoClienteService";
import * as D from "../../../../../../styles/appComponents";
import { convertEmptyStringsToNullAsync } from "../../../../../../utils/convertEmptyStringsToNull";
import { toastMessage } from "../../../../../../utils/toastMessage";

type PropsEnderecoContratar = {
  id?: string;
  dataContrato?: IEndereco;
  edit: boolean;
  callback?: (data: IUpdateEndereco) => void;
};

const EnderecoContratar = (props: PropsEnderecoContratar) => {
  //region Variáveis
  const { dataContrato, id } = props;
  const [editar, setEditar] = useState<boolean>(false);
  const { state, setObject, updateObject } = useObjectState<IUpdateEndereco>({
    id: id,
    cep: "",
    uf: "",
    cidade: "",
    bairro: "",
    logradouro: "",
    numero: "",
    complemento: "",
  });
  //endregion

  //region Services
  const Update: () => Promise<void> = async () => {
    var data = await convertEmptyStringsToNullAsync(state);

    await service
      .UpdateEndereco(data)
      .then(() => {
        setEditar(false);
        toastMessage("success", "Enviado com sucesso!");
      })
      .catch((e: AxiosError) => {
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao enviar o contrato para a ClickSign."
        );
      });
  };
  //endregion

  //region UI
  const BuscaCep: (cepReturn: ICepReturn) => Promise<void> = async (
    cepReturn: ICepReturn
  ) => {
    updateObject({
      cep: cepReturn.value ?? "",
      bairro: cepReturn.bairro ?? "",
      cidade: cepReturn.localidade ?? "",
      logradouro: cepReturn.logradouro ?? "",
      uf: cepReturn.uf ?? "",
    });
  };

  useEffect((): void => {
    if (dataContrato) {
      setObject({
        id: id,
        cep: dataContrato.cep ?? "",
        bairro: dataContrato.bairro ?? "",
        cidade: dataContrato.cidade ?? "",
        complemento: dataContrato.complemento ?? "",
        logradouro: dataContrato.logradouro ?? "",
        numero: dataContrato.numero ?? "",
        uf: dataContrato.uf ?? "",
      });
    }
  }, [dataContrato]);

  useEffect(() => {
    if (!props.edit) {
      props.callback!(state);
    }
  }, [state]);

  useEffect(() => {
    if (props.edit === false) {
      setEditar(true);
    }
  }, [props.edit]);
  //endregion

  return (
    <RegisterCard title="Endereço">
      <D.FWStack direction={"row"} spacing={2}>
        <MaskDefaultInput
          label="CEP"
          type="CEP"
          value={state.cep}
          onChange={(e) => BuscaCep(e as ICepReturn)}
          readonly={!editar}
        />
        <TextField
          label="UF"
          required
          fullWidth
          value={state.uf}
          onChange={(e) => updateObject({ uf: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Cidade"
          required
          fullWidth
          value={state.cidade}
          onChange={(e) => updateObject({ cidade: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Bairro"
          required
          fullWidth
          value={state.bairro}
          onChange={(e) => updateObject({ bairro: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Logradouro"
          required
          fullWidth
          value={state.logradouro}
          onChange={(e) => updateObject({ logradouro: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Número"
          required
          fullWidth
          value={state.numero}
          onChange={(e) => updateObject({ numero: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Complemento"
          fullWidth
          value={state.complemento}
          onChange={(e) => updateObject({ complemento: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
      </D.FWStack>
      {props.edit && (
        <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton
            $color="yellow"
            disabled={editar}
            onClick={() => setEditar(true)}
          >
            Editar
          </D.ContainedButton>
          <D.ContainedButton disabled={!editar} onClick={Update}>
            Salvar
          </D.ContainedButton>
        </D.FWStack>
      )}
    </RegisterCard>
  );
};

export default EnderecoContratar;
