import { z } from "zod";
import { criarPaginaResponseSchema } from "../utils/Utils";
import { CaptadorListItemTabelaSchema } from "./CaptadorListItemTabela";

export const CaptadorListItemTabelaPaginaSchema = criarPaginaResponseSchema(
  CaptadorListItemTabelaSchema
);

export type CaptadorListItemTabelaPagina = z.output<
  typeof CaptadorListItemTabelaPaginaSchema
>;
