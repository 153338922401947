import { AbstractValidation, Validate } from "../../../../../../utils/AbstractValidation";
import { IFormSalarioFuncao } from "../SalarioFuncao";

export class SalarioFuncaoValidate extends AbstractValidation<IFormSalarioFuncao>{
    getValidate(): Validate<IFormSalarioFuncao>[] {
        return[
            {
                field: "salariobase",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("O Salário base deve ser informado","salariobase")
                }
            },
            {
                field: "funcao",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(value === null) addError("A função deve ser informada","funcao")
                }
            },
            {
                field: "partidosindicato",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("O Partido sindicato deve ser informada","partidosindicato")
                }
            }
        ];
    }
}