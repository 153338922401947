import React, {useState} from 'react';
import RegisterCard from "../../../../../components/RegisterCard";
import * as D from "../../../../../styles/appComponents";
import {TextField} from "@mui/material";
import MaskDefaultInput from "../../../../../components/MasDefaultinput/MaskDefaultInput";
import {EnumTipoDocumento} from "../../../../../enums/EnumTipoDocumento.enum";
import {ICelularInputReturn} from "../../../../../models/Celular";

export interface IDadosPagador {
  celular: string | null;
  email: string | null;
  cpfcnpj: string | null;
  instalacao: string | null;
  contratoId: string | null;
  cep: string | null;
  uf: string | null;
  cidade: string | null;
  bairro: string | null;
  numero: string | null;
  complemento: string | null;
  logradouro: string | null;
}

export enum DadosPagadorEnum {
  CELULAR = "celular",
  EMAIL = "email",
  CPF_CNPJ = "cpfcnpj",
  INSTALACAO = "instalacao",
  CONTRATO_ID = "contratoId",
  CEP = "cep",
  UF = "uf",
  CIDADE = "cidade",
  BAIRRO = "bairro",
  NUMERO = "numero",
  COMPLEMENTO = "complemento",
  LOGRADOURO = "logradouro",
}

type DadosPagadorProps = {
  callback: (dadosPagador: IDadosPagador) => void;
  type: EnumTipoDocumento
}

const DadosPagador: (props: DadosPagadorProps) => React.JSX.Element = (props: DadosPagadorProps): React.JSX.Element => {
  //region Variáveis
  const {callback, type} = props;
  const [dadosPagador, setDadosPagador] = useState<IDadosPagador>({
    bairro: null,
    celular: null,
    cep: null,
    cidade: null,
    complemento: null,
    contratoId: null,
    cpfcnpj: null,
    email: null,
    instalacao: null,
    logradouro: null,
    numero: null,
    uf: null
  });
  //endregion

  //region UI
  const handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setDadosPagador((state: IDadosPagador) => {
      const newData = {
        ...state,
        [name]: value,
      };
      callback(newData);
      return newData;
    });
  };
  //endregion

  return (
    <>
      <RegisterCard title="Dados do pagador">
        <D.FWStack direction={"row"} spacing={2}>
          <MaskDefaultInput
            label="Celular"
            type="CELULAR"
            value={dadosPagador.celular || ""}
            onChange={(e): void => {
              const celular = e as ICelularInputReturn;
              handleInputChange({
                target: {
                  name: DadosPagadorEnum.CELULAR,
                  value: celular.value
                }
              } as React.ChangeEvent<HTMLInputElement>)
            }}
          />
          <TextField
            label="Email"
            required
            fullWidth
            value={dadosPagador.email}
            onChange={handleInputChange}
          />
          {
            type === EnumTipoDocumento.CPF ? (
              <MaskDefaultInput
                label="CPF"
                type="CPF"
                onChange={(value) => {
                  handleInputChange({
                    target: {
                      name: DadosPagadorEnum.CPF_CNPJ,
                      value
                    }
                  } as React.ChangeEvent<HTMLInputElement>)
                }}
                value={dadosPagador.cpfcnpj || ""}
              />
            ) : (
              <MaskDefaultInput
                label="CNPJ"
                type="CNPJ"
                onChange={(value) => {
                  handleInputChange({
                    target: {
                      name: DadosPagadorEnum.CPF_CNPJ,
                      value
                    }
                  } as React.ChangeEvent<HTMLInputElement>)
                }}
                value={dadosPagador.cpfcnpj || ""}
              />
            )
          }
          <TextField
            label="Instalação"
            required
            fullWidth
            value={dadosPagador.instalacao}
            name={DadosPagadorEnum.INSTALACAO}
            onChange={handleInputChange}
          />
          <TextField
            label="ID contrato"
            required
            fullWidth
            name={DadosPagadorEnum.CONTRATO_ID}
            value={dadosPagador.contratoId}
            onChange={handleInputChange}
          />
        </D.FWStack>

        <D.FWStack direction={"row"} spacing={2}>
          <MaskDefaultInput
            label="CEP"
            type="CEP"
            onChange={(value) => {
              handleInputChange({
                target: {
                  name: DadosPagadorEnum.CEP,
                  value
                }
              } as React.ChangeEvent<HTMLInputElement>)
            }}
            value={dadosPagador.cep || ""}
          />
          <TextField
            label="UF"
            required
            fullWidth
            name={DadosPagadorEnum.UF}
            value={dadosPagador.uf}
            onChange={handleInputChange}
          />
          <TextField
            label="Cidade"
            required
            fullWidth
            name={DadosPagadorEnum.CIDADE}
            value={dadosPagador.cidade}
            onChange={handleInputChange}
          />
          <TextField
            label="Bairro"
            required
            fullWidth
            name={DadosPagadorEnum.BAIRRO}
            value={dadosPagador.bairro}
            onChange={handleInputChange}
          />
          <TextField
            label="Número"
            required
            fullWidth
            name={DadosPagadorEnum.NUMERO}
            value={dadosPagador.numero}
            onChange={handleInputChange}
          />
          <TextField
            label="Logradouro"
            required
            fullWidth
            name={DadosPagadorEnum.LOGRADOURO}
            value={dadosPagador.logradouro}
            onChange={handleInputChange}
          />
        </D.FWStack>

        <D.FWStack direction={"row"} spacing={2}>
          <TextField
            name={DadosPagadorEnum.COMPLEMENTO}
            label="Complemento"
            required
            fullWidth
            sx={{width: '50%'}}
            value={dadosPagador.complemento}
            onChange={handleInputChange}
          />
        </D.FWStack>
      </RegisterCard>
    </>
  )
    ;
};

export default DadosPagador;