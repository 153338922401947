export enum EnumCupomBoasVindas {
  plataforma = "Plataforma",
  parceiroMaster = "Parceiro Master",
  associados = "Associados",
}

export const EnumCupomBoasVindasNumericMap: Record<
  EnumCupomBoasVindas,
  number
> = {
  [EnumCupomBoasVindas.plataforma]: 0,
  [EnumCupomBoasVindas.parceiroMaster]: 1,
  [EnumCupomBoasVindas.associados]: 2,
};
