import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import logo from "../../../assets/logo.png";
import { IPDFPropostaResponse } from "../../../models/GDProposta";
import {
  GetPropostaPDFWattwise,
  Send2Email,
  Send2Whatsapp,
} from "../../../services/api/PropostaService";
import * as C from "../../public/Home/components/Navbar/style";
import { ContainedButton, CustomInput } from "./style";
import { CELULAR } from "../../../utils/masInputs";
import { toastMessage } from "../../../utils/toastMessage";
import MaskDefaultInput from "../../../components/MasDefaultinput/MaskDefaultInput";
import { ICelularInputReturn } from "../../../models/Celular";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export enum dataTela {
  ID = "ID",
  IDREFERENCIAL = "IDREFERENCIAL",
  NOME = "NOME",
  CELULARCONTATO = "CELULARCONTATO",
  EMAIL = "EMAIL",
  NINSTALACAO = "NINSTALACAO",
  CONTADISTRIBUIDORA = "CONTADISTRIBUIDORA",
  TARIFAVIGENTE = "TARIFAVIGENTE",
  ILUMINACAOPUBLICA = "ILUMINACAOPUBLICA",
  ULTIMOCONSUMOKWHCONSIDERADO = "ULTIMOCONSUMOKWHCONSIDERADO",
  TAXADISPONIBILIDADE = "TAXADISPONIBILIDADE",
  KHWLIBERADORINJECAOENERGIA = "KHWLIBERADORINJECAOENERGIA",
  VALORATUALDISTRIBUIDORA = "VALORATUALDISTRIBUIDORA",
  CONTADISTRIBUIDORAAPOSCONTATACAO = "CONTADISTRIBUIDORAAPOSCONTATACAO",
  BOLETO = "BOLETO",
  CONTADISTRIBUIDORAMAISBOLETO = "CONTADISTRIBUIDORAMAISBOLETO",
  ECONOMIAMENSAL = "ECONOMIAMENSAL",
  ECONOMIAANUAL = "ECONOMIAANUAL",
  PERCENTUALDEDESCONTOSOBRETARIFA = "PERCENTUALDEDESCONTOSOBRETARIFA",
}

export const PropostaWattwise = () => {
  //region Variáveis
  const printRef = useRef<HTMLDivElement>(null);
  const setDataForm = (
    state: IPDFPropostaResponse,
    action: { type: dataTela; payload: any }
  ) => {
    const { type, payload } = action;

    if (state.hasOwnProperty(type.toLowerCase())) {
      return { ...state, [type.toLowerCase()]: payload };
    }

    return state;
  };
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("+55");
  const [celular, setCelular] = useState("");
  const [phone, setPhone] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [state, dispatch] = useReducer(setDataForm, {
    id: "",
    idreferencial: 0,
    nome: "",
    celularcontato: "",
    email: "",
    ninstalacao: "",
    contadistribuidora: 0,
    tarifavigente: 0,
    iluminacaopublica: 0,
    ultimoconsumokwhconsiderado: "",
    taxadisponibilidade: "",
    khwliberadorinjecaoenergia: 0,
    valoratualdistribuidora: 0,
    contadistribuidoraaposcontatacao: 0,
    boleto: 0,
    contadistribuidoramaisboleto: 0,
    economiamensal: 0,
    economiaanual: 0,
    percentualdedescontosobretarifa: 0,
  });
  const rowsSituacaoAtual: string[] = [
    "Conta distribuidora",
    "Tarifa vigente:",
    "Iluminação pública:",
    "Último consumo kwh considerado",
    "Taxa de disponibilidade",
    "KWH liberado para injeção de energia",
    "Valor atual da distribuidora (Energia consumida + iluminação pública)",
  ];
  const rowsContaDistribuidoraEstimadaAposContratação: string[] = [
    "Conta distribuidora após a contratação",
  ];
  const rowsEnergiaPorAssinatura: string[] = [
    "Boleto (contribuição mensal mediante a energia injetada em sua conta)",
  ];
  const rowsDespesasTotais: string[] = ["Conta distribuidora + boleto"];
  const { idProposta } = useParams();
  //endregion

  //region Services
  const sendEmail: () => Promise<void> = async () => {
    setDisabled(true);
    const pdfBlob = await createPDF();

    if (!pdfBlob) {
      console.error("Ocorreu um erro ao tentar criar o PDF");
      return;
    }

    const formData = new FormData();
    formData.append("GDPropostaId", idProposta!);
    formData.append("Email", email);
    formData.append("GDPropostaPDF", pdfBlob, "proposta.pdf");

    try {
      await Send2Email(formData);
      toastMessage("success", `Email enviado para ${email} com sucesso`);
    } catch (error) {
      toastMessage("error", `Erro ao enviar o e-mail`);
      console.error("Erro ao enviar o e-mail", error);
    } finally {
      setDisabled(false);
    }
  };

  const getDadosUsuario: (id: string) => Promise<void> = async (
    id: string
  ): Promise<void> => {
    try {
      const resposta: AxiosResponse<IPDFPropostaResponse> =
        await GetPropostaPDFWattwise(id);
      const data: any = resposta.data;

      dispatch({
        type: dataTela.ID,
        payload: "" + data.idReferencial,
      });
      dispatch({
        type: dataTela.NOME,
        payload:
          "" +
          data.nome.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.CELULARCONTATO,
        payload: "" + data.celularContato,
      });
      dispatch({
        type: dataTela.EMAIL,
        payload:
          data.email == null || data.email == ""
            ? "Não informado."
            : data.email,
      });
      dispatch({
        type: dataTela.NINSTALACAO,
        payload:
          "" +
          data.nInstalacao.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.CONTADISTRIBUIDORA,
        payload:
          "" +
          data.contaDistribuidora.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.TARIFAVIGENTE,
        payload:
          "" +
          data.tarifaVigente.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.ILUMINACAOPUBLICA,
        payload: data.iluminacaoPublica.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
      });
      dispatch({
        type: dataTela.ULTIMOCONSUMOKWHCONSIDERADO,
        payload: "" + data.ultimoConsumoKWHConsiderado + " KWH",
      });
      dispatch({
        type: dataTela.TAXADISPONIBILIDADE,
        payload: "" + data.taxaDisponibilidade + " KWH",
      });
      dispatch({
        type: dataTela.KHWLIBERADORINJECAOENERGIA,
        payload: "" + data.khwLiberadorInjecaoEnergia + " KWH",
      });
      dispatch({
        type: dataTela.VALORATUALDISTRIBUIDORA,
        payload:
          "" +
          data.valorAtualDistribuidora.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.CONTADISTRIBUIDORAAPOSCONTATACAO,
        payload:
          "" +
          data.contaDistribuidoraAposContatacao.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.BOLETO,
        payload:
          "" +
          data.boleto.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.CONTADISTRIBUIDORAMAISBOLETO,
        payload:
          "" +
          data.contaDistribuidoraMaisBoleto.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.ECONOMIAMENSAL,
        payload:
          "" +
          data.economiaMensal.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.ECONOMIAANUAL,
        payload:
          "" +
          data.economiaAnual.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      });
      dispatch({
        type: dataTela.PERCENTUALDEDESCONTOSOBRETARIFA,
        payload: "" + data.percentualDeDescontoSobreTarifa + " %",
      });

      handleChangeEmail(data.email);
      setCelular(data.celularContato);
    } catch (e: unknown) {
      console.error(e);
    }
  };
  //endregion

  //region UI
  const handleChangeEmail: (value: string) => void = (value: string) => {
    setEmail(value);
  };

  const handleChangePhone: (value: string) => void = (value: string) => {
    setPhone(value);
  };

  const handleChangeCelular = async (e: ICelularInputReturn) => {
    setCountryCode(e.zipCode);
    setCelular(e.value);
    const cellphone = e.zipCode + e.value;
    setPhone(cellphone);
  };

  const createPDF: () => Promise<Blob | null> =
    async (): Promise<Blob | null> => {
      const element = printRef.current;
      if (!element) return null;

      const iframe = document.createElement("iframe");
      iframe.style.position = "absolute";
      iframe.style.width = "210mm";
      iframe.style.height = "297mm";
      iframe.style.visibility = "hidden";
      document.body.appendChild(iframe);

      const doc = iframe.contentWindow?.document;
      if (!doc) return null;

      const styles = getDocumentStyles();
      doc.open();
      doc.write(`
    <html>
      <head>
        <style>
          ${styles}
          body, html {
            width: 210mm;
            height: 297mm;
            margin: 0;
            padding: 2mm; 
            box-sizing: border-box;
            overflow: hidden;
          }
          table {
            width: 100% !important;
            border-collapse: collapse !important;
          }
          #boxDataUser1 {
            margin-top: 40px;
          }
          #boxDataUser {
            margin-bottom: 26px;
            margin-top: 13px;
          }
          #boxDataUser2 {
            margin-bottom: 26px;
            margin-top: 13px;
          }
          table th, table td {
            padding: 5px !important;
            margin: 5px !important;
          }
          table tr {
            margin: 5px !important;
          }
          table th {
            text-align: left !important;
            color: #D14192 !important;
          }
          h3 {
            margin: 0;
          }
        </style>
      </head>
      <body>${element.innerHTML}</body>
    </html>
  `);
      doc.close();

      return new Promise((resolve) => {
        iframe.onload = async () => {
          const iframeElement = iframe.contentWindow?.document.body;
          if (!iframeElement) {
            document.body.removeChild(iframe);
            return resolve(null);
          }

          const canvas = await html2canvas(iframeElement, { scale: 2 });
          const imgData = canvas.toDataURL("image/png");

          const pdf = new jsPDF("p", "mm", "a4");

          const margin = 10;
          const imgWidth = 210 - 2 * margin;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          pdf.addImage(imgData, "PNG", margin, margin, imgWidth, imgHeight);

          document.body.removeChild(iframe);

          resolve(pdf.output("blob"));
        };
      });
    };

  const savePDF: () => Promise<void> = async () => {
    const pdfBlob = await createPDF();
    if (!pdfBlob) {
      console.error("Erro após tentarmos criar o pdf");
      return;
    }

    const url = URL.createObjectURL(pdfBlob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `Simulação ${state.nome}.pdf`;
    link.click();

    URL.revokeObjectURL(url);
  };

  const getDocumentStyles: () => string = (): string => {
    return Array.from(document.styleSheets)
      .map((styleSheet) => {
        try {
          return Array.from(styleSheet.cssRules)
            .map((rule) => rule.cssText)
            .join("");
        } catch (e) {
          console.error("Não foi possível acessar os estilos de:", styleSheet);
          return "";
        }
      })
      .join("");
  };

  const sendWhatsapp = async (isCopyText: boolean) => {
    setDisabled(true);

    try {
      const response: AxiosResponse<string, any> = await Send2Whatsapp({
        propostaId: idProposta!,
        countryCode: countryCode,
        celular: celular,
        isCopyText: isCopyText,
      });

      if (isCopyText) {
        await navigator.clipboard.writeText(response.data);
        toastMessage("success", "Mensagem copiada com sucesso.");
      } else {
        window.open(response.data, "_blank");
      }
    } catch (error) {
      toastMessage("error", "Erro ao tentar pegar o link para o whatsapp");
    } finally {
      setDisabled(false);
    }
  };

  useEffect((): (() => void) => {
    document.body.style.backgroundColor = "#6c757d";
    document.body.style.overflowX = "hidden";

    getDadosUsuario(idProposta!);

    return () => {
      document.body.style.backgroundColor = "";
      document.body.style.overflowX = "";
    };
  }, []);
  //endregion

  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <iframe
        id="printIframe"
        style={{ display: "none" }}
        title="Print Frame"
      ></iframe>
      <Paper
        ref={printRef}
        id="paperToPrint"
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          padding: "40px",
          marginLeft: { lg: 35, md: 0, sm: 0, xs: 0 },
          marginRight: { lg: 35, md: 0, sm: 0, xs: 0 },
          margin: { md: 5, sm: 5, xs: 5 },
          marginTop: 3,
          gap: 3,
          minWidth: "340px",
        }}
      >
        <Box id="boxDataUser1">
          <C.Logo
            src={logo}
            alt="logoWattwise"
            style={{
              width: "175px",
              height: "86px",
            }}
          />
        </Box>
        <Box id="boxDataUser">
          <Typography
            style={{
              width: "174px",
              height: "14px",
              opacity: "0px",
              whiteSpace: "nowrap",
              color: "#D14192",
              fontWeight: 800,
            }}
          >
            PROPOSTA WATTWISE - ID: {state.id}
          </Typography>
        </Box>
        <Box id="boxDataUser2">
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <b>Nome do cliente:</b> {state.nome}
          </Typography>
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <b>Celular de contato:</b>{" "}
            {new CELULAR().executeForInput(state.celularcontato)}
          </Typography>
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <b>E-mail:</b> {state.email ?? "Não informado."}
          </Typography>
          <Typography
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <b>Nº de instalação:</b> {state.ninstalacao}
          </Typography>
        </Box>
        <Box sx={{ backgroundColor: "#F8F8F8" }}>
          <TableContainer>
            <Table
              aria-label="caption table"
              sx={{
                marginBottom: 3,
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ color: "#D14192", paddingTop: 0, paddingBottom: 0 }}
                  >
                    <h3>Situação atual</h3>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsSituacaoAtual.map(
                  (
                    dados: string,
                    index: number | undefined
                  ): React.JSX.Element => {
                    let valor: string | number;

                    switch (index) {
                      case 0:
                        valor = state.contadistribuidora;
                        break;
                      case 1:
                        valor = state.tarifavigente;
                        break;
                      case 2:
                        valor = state.iluminacaopublica;
                        break;
                      case 3:
                        valor = state.ultimoconsumokwhconsiderado;
                        break;
                      case 4:
                        valor = state.taxadisponibilidade;
                        break;
                      case 5:
                        valor = state.khwliberadorinjecaoenergia;
                        break;
                      case 6:
                        valor = state.valoratualdistribuidora;
                        break;
                      default:
                        valor = "0,00";
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: "80%", paddingTop: 0, paddingBottom: 0 }}
                        >
                          {dados}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontWeight: 800,
                            borderLeft: "#D14192 2px solid",
                            color: index === 6 ? "red" : "inherit",
                          }}
                        >
                          {valor}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ backgroundColor: "#F8F8F8" }}>
          <TableContainer>
            <Table
              aria-label="caption table"
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ color: "#D14192", paddingTop: 0, paddingBottom: 0 }}
                  >
                    <h3>Conta distribuidora estimada após contratação</h3>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsContaDistribuidoraEstimadaAposContratação.map(
                  (
                    dados: string,
                    index: number | undefined
                  ): React.JSX.Element => {
                    let valor: string | number;

                    switch (index) {
                      case 0:
                        valor = state.contadistribuidoraaposcontatacao;
                        break;
                      default:
                        valor = "0,00";
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: "80%", paddingTop: 0, paddingBottom: 0 }}
                        >
                          {dados}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontWeight: 800,
                            borderLeft: "#D14192 2px solid",
                          }}
                        >
                          {valor}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>

            <Table
              aria-label="caption table"
              sx={{
                marginBottom: 3,
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ color: "#D14192", paddingTop: 0, paddingBottom: 0 }}
                  >
                    <h3>Energia por assinatura</h3>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsEnergiaPorAssinatura.map(
                  (
                    dados: string,
                    index: number | undefined
                  ): React.JSX.Element => {
                    let valor: string | number;

                    switch (index) {
                      case 0:
                        valor = state.boleto;
                        break;
                      default:
                        valor = "0,00";
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          sx={{ width: "80%", paddingTop: 0, paddingBottom: 0 }}
                          scope="row"
                        >
                          {dados}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontWeight: 800,
                            borderLeft: "#D14192 2px solid",
                          }}
                        >
                          {valor}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ backgroundColor: "#F8F8F8" }}>
          <TableContainer>
            <Table
              aria-label="caption table"
              sx={{
                marginBottom: 3,
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ color: "#D14192", paddingTop: 0, paddingBottom: 0 }}
                  >
                    <h3>Despesas totais</h3>
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsDespesasTotais.map(
                  (
                    dados: string,
                    index: number | undefined
                  ): React.JSX.Element => {
                    let valor: string | number;

                    switch (index) {
                      case 0:
                        valor = state.contadistribuidoramaisboleto;
                        break;
                      default:
                        valor = "0,00";
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: "80%", paddingTop: 0, paddingBottom: 0 }}
                        >
                          {dados}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontWeight: 800,
                            borderLeft: "#D14192 2px solid",
                            color: "#09f147",
                          }}
                        >
                          {" "}
                          {valor}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
                <TableRow>
                  <TableCell
                    component="th"
                    sx={{
                      width: "80%",
                      color: "#D14192",
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                    scope="row"
                  >
                    <h3>Economia mensal</h3>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      borderLeft: "#D14192 2px solid",
                      color: "#09f147",
                      fontWeight: 800,
                    }}
                  >
                    {" "}
                    {state.economiamensal}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    sx={{
                      width: "80%",
                      color: "#D14192",
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                    scope="row"
                  >
                    <h3>Economia anual</h3>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      borderLeft: "#D14192 2px solid",
                      color: "#09f147",
                      fontWeight: 800,
                    }}
                  >
                    {" "}
                    {state.economiaanual}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    component="th"
                    sx={{
                      width: "80%",
                      color: "#D14192",
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                    scope="row"
                  >
                    <h3>Percentual de desconto sobre a tarifa</h3>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      borderLeft: "#D14192 2px solid",
                      color: "#09f147",
                      fontWeight: 800,
                    }}
                  >
                    {" "}
                    {state.percentualdedescontosobretarifa}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>

      <Paper
        sx={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          padding: "40px",
          marginLeft: { lg: 35, md: 0, sm: 0, xs: 0 },
          marginRight: { lg: 35, md: 0, sm: 0, xs: 0 },
          margin: { md: 5, sm: 5, xs: 5 },
          gap: 3,
          minWidth: "340px",
        }}
      >
        <Box style={{ display: "flex", gap: "7px" }}>
          <MaskDefaultInput
            label="Celular"
            value={celular}
            valueCountryCode={countryCode}
            type="CELULAR"
            onChange={(e) => handleChangeCelular(e as ICelularInputReturn)}
          />
          <TextField
            id="outlined-read-only-input"
            label="Email"
            fullWidth
            onChange={(e) => handleChangeEmail(e.target.value)}
            value={email}
          />
        </Box>
        <Box style={{ display: "flex", gap: "7px" }}>
          <ContainedButton
            startIcon={<WhatsAppIcon />}
            onClick={() => sendWhatsapp(false)}
            disabled={disabled}
          >
            Enviar por whatsapp
          </ContainedButton>

          <Button
            variant="contained"
            color="primary"
            onClick={() => sendWhatsapp(true)}
            disabled={disabled}
          >
            <ContentCopyIcon />
          </Button>

          <ContainedButton
            startIcon={<EmailIcon />}
            onClick={sendEmail}
            disabled={disabled}
          >
            Enviar por email
          </ContainedButton>
        </Box>

        <Box style={{ display: "flex", gap: "7px" }}>
          <ContainedButton
            onClick={savePDF}
            startIcon={<ArrowCircleDownIcon />}
          >
            Baixar proposta PDF
          </ContainedButton>
        </Box>
      </Paper>
    </Box>
  );
};
