export const addOneDay = (dateToBeFormated: string | Date | null): string => {
  if (dateToBeFormated) {
    const date = new Date(dateToBeFormated ?? "");
    date.setDate(date.getDate() + 1);
    return date.toISOString().split("T")[0];
  } else return "";
};

type MascaraData = "YYYY-MM-DD" | "DD/MM/YYYY" | "DD-MM-YYYY";
export const FormatDate = (
  date: string | Date,
  maskara: MascaraData = "YYYY-MM-DD"
): string => {
  let parsedDate: Date | null;

  if (typeof date === "string") {
    parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      parsedDate = parseDate(date);
    }
  } else {
    parsedDate = date;
  }

  if (!(parsedDate instanceof Date) || isNaN(parsedDate.getTime())) {
    return "";
  }

  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const day = String(parsedDate.getDate()).padStart(2, "0");

  switch (maskara) {
    case "YYYY-MM-DD":
      return `${year}-${month}-${day}`;
    case "DD/MM/YYYY":
      return `${day}/${month}/${year}`;
    case "DD-MM-YYYY":
      return `${day}-${month}-${year}`;
    default:
      throw new Error("Máscara inválida");
  }
};

export const dateFormatter = (
  dateToBeFormated: string | Date | null
): string => {
  if (dateToBeFormated) {
    const date = new Date(dateToBeFormated);

    const formattedDate = `${String(date.getUTCDate()).padStart(
      2,
      "0"
    )}/${String(date.getUTCMonth() + 1).padStart(
      2,
      "0"
    )}/${date.getUTCFullYear()}`;

    return formattedDate;
  } else return "";
};

const parseDate = (dateStr: string): Date | null => {
  const regex = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})$/;
  const match = dateStr.match(regex);

  if (!match) return null;

  const [, day, month, year, hours, minutes, seconds] = match.map(Number);
  return new Date(year, month - 1, day, hours, minutes, seconds);
};
