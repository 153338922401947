import { FC, useState } from "react";
import { CircularProgress, Grid, TextField, Typography } from "@mui/material";
import RegisterCard from "../RegisterCard";
import CircleCheckbox from "../CircleCheckBox";
import { ContainedButton, OutlineButton } from "../../styles/appComponents";
import DownloadIcon from "@mui/icons-material/Download";
import { ICaptador } from "../../models/Captador";
import {
  EnumStatusContratoParceiro,
  statusContratoConfig,
} from "../../enums/EnumStatusContratoParceiro.enum";
import { FormatDate } from "../../utils/dateFormatter";

interface IStatusContratoInputProps {
  captador: ICaptador;
  disabled?: boolean;
  criaContratoParaAssinaturaDigital?: (
    captadorId: string
  ) => Promise<string | null>;
}

const StatusContratoInput: FC<IStatusContratoInputProps> = ({
  captador,
  disabled = false,
  criaContratoParaAssinaturaDigital,
}) => {
  const [status, setStatus] = useState<EnumStatusContratoParceiro | null>(
    captador.statusContrato
  );

  const [editar, setEditar] = useState<boolean>(false);
  const [gerandoContrato, setGerandoContrato] = useState<boolean>(false);

  const handleGerarContrato = async () => {
    setGerandoContrato(true);
    await criaContratoParaAssinaturaDigital?.(captador.id);
    setGerandoContrato(false);
  };

  return (
    <RegisterCard title="Status do contrato">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>Status atual</Typography>
        </Grid>
        <Grid item container spacing={2}>
          {Object.entries(statusContratoConfig).map(([key, config]) => (
            <Grid item key={key}>
              <CircleCheckbox
                disabled={disabled || !editar}
                label={config.label}
                color={config.color}
                key={key}
                id={config.id}
                selected={status === key}
                onSelect={() =>
                  !disabled &&
                  editar &&
                  setStatus(key as EnumStatusContratoParceiro)
                }
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Última alteração de status"
            fullWidth
            InputProps={{ readOnly: true }}
            value={
              captador.ultimaAlteracaoStatus
                ? FormatDate(captador.ultimaAlteracaoStatus, "DD/MM/YYYY")
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Data de assinatura do contrato"
            fullWidth
            InputProps={{ readOnly: true }}
            value={
              captador.dataAssinaturaContrato
                ? FormatDate(captador.dataAssinaturaContrato, "DD/MM/YYYY")
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="UUID do contrato"
            fullWidth
            InputProps={{ readOnly: true }}
            value={captador.uuidContrato ?? ""}
          />
        </Grid>
      </Grid>
      <Grid item container>
        <Grid
          item
          xs={6}
          container
          direction="row"
          spacing={2}
          alignItems="center"
        >
          <Grid item>
            <OutlineButton
              $color="blue"
              startIcon={<DownloadIcon />}
              disabled={
                !!captador.uuidContrato ||
                captador.statusContrato !==
                  EnumStatusContratoParceiro.contratoAssinado ||
                true
              }
            >
              Baixar contrato
            </OutlineButton>
          </Grid>
          <Grid item>
            <ContainedButton
              $color="lightpink"
              disabled={
                captador.statusContrato !== EnumStatusContratoParceiro.lead ||
                criaContratoParaAssinaturaDigital === undefined
              }
              onClick={handleGerarContrato}
            >
              {gerandoContrato ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                <>Gerar contrato</>
              )}
            </ContainedButton>
          </Grid>
          <Grid item>
            <ContainedButton $color="lightpink" disabled>
              Gerar distrato
            </ContainedButton>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={6}
          direction="row"
          justifyContent="flex-end"
          spacing={2}
        >
          <Grid item>
            <ContainedButton
              $color="yellow"
              disabled={disabled || editar || true}
              onClick={() => setEditar(true)}
            >
              Editar
            </ContainedButton>
          </Grid>
          <Grid item>
            <ContainedButton
              disabled={disabled || !editar || true}
              $color="pink"
              onClick={() => true}
            >
              Salvar
            </ContainedButton>
          </Grid>
        </Grid>
      </Grid>
    </RegisterCard>
  );
};

export default StatusContratoInput;
