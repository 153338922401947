import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { FC, ReactNode, useCallback } from "react";
import dayjs from "dayjs";

interface IDataInputProps {
  label: string;
  value: Date | null;
  onChange: (newValue: Date | null) => void;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
  required?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  helperText?: ReactNode;
  error?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  inputFormat?: string;
}

const DataInput: FC<IDataInputProps> = ({
  label,
  value,
  onChange,
  maxDate,
  minDate,
  disabled,
  required,
  onBlur,
  onFocus,
  helperText,
  error,
  disablePast,
  disableFuture,
  inputFormat = "DD/MM/YYYY",
}) => {
  const dayjsValue = value ? dayjs(value) : null;
  const dayjsMaxDate = maxDate ? dayjs(maxDate) : undefined;
  const dayjsMinDate = minDate ? dayjs(minDate) : undefined;

  const handleChange = useCallback(
    (newValue: dayjs.Dayjs | null) => {
      onChange(newValue ? newValue.toDate() : null);
    },
    [onChange]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <DatePicker
        label={label}
        value={dayjsValue}
        onChange={handleChange}
        maxDate={dayjsMaxDate}
        minDate={dayjsMinDate}
        disablePast={disablePast}
        disableFuture={disableFuture}
        format={inputFormat}
        onOpen={onFocus}
        onClose={onBlur}
        slotProps={{
          textField: {
            fullWidth: true,
            required,
            onBlur,
            onFocus,
            error,
            helperText,
          },
        }}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};

export default DataInput;
