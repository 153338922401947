import React, {useState} from 'react';
import RegisterCard from "../../../../../components/RegisterCard";
import * as D from "../../../../../styles/appComponents";
import {TextField} from "@mui/material";

export interface IDetalhesGd {
  valorGdDetalhesGd: string | null;
  recebedor: string | null;
  splitFalhou: string | null;
  statusDetalhesGd: string | null;
}

export enum DetalhesGdEnum {
  VALOR_GD = "valorGdDetalhesGd",
  RECEBEDOR = "recebedor",
  SPLIT_FALHOU = "splitFalhou",
  STATUS_DETALHES_GD = "statusDetalhesGd",
}

type DetalhesGdProps = {
  callback: (dadosPagador: IDetalhesGd) => void;
}

const DetalhesGd: (props: DetalhesGdProps) => React.JSX.Element = (props: DetalhesGdProps): React.JSX.Element => {
  //region Variáveis
  const {callback} = props;
  const [detalhesGd, setDetalhesGd] = useState<IDetalhesGd>({
    valorGdDetalhesGd: null,
    recebedor: null,
    splitFalhou: null,
    statusDetalhesGd: null,
  });
  //endregion

  //region UI
  const handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setDetalhesGd((state: IDetalhesGd) => {
      const newData = {
        ...state,
        [name]: value,
      };
      callback(newData);
      return newData;
    });
  };
  //endregion

  return (
    <>
      <RegisterCard title="Detalhes GD">
        <D.FWStack direction={"row"} spacing={2}>
          <TextField
            label="Valor GD"
            required
            fullWidth
            name={DetalhesGdEnum.VALOR_GD}
            value={detalhesGd.valorGdDetalhesGd}
            onChange={handleInputChange}
          />
          <TextField
            label="Recebedor"
            required
            fullWidth
            name={DetalhesGdEnum.RECEBEDOR}
            value={detalhesGd.recebedor}
            onChange={handleInputChange}
          />
          <TextField
            label="Split falhou?"
            required
            fullWidth
            name={DetalhesGdEnum.SPLIT_FALHOU}
            value={detalhesGd.splitFalhou}
            onChange={handleInputChange}
          />
          <TextField
            label="status"
            required
            fullWidth
            name={DetalhesGdEnum.STATUS_DETALHES_GD}
            value={detalhesGd.statusDetalhesGd}
            onChange={handleInputChange}
          />
        </D.FWStack>
      </RegisterCard>
    </>
  );
};

export default DetalhesGd;