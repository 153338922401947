import * as React from "react";
import {
    GridColDef,
    GridRowIdGetter,
    GridToolbarContainer,
    GridToolbarFilterButton
} from "@mui/x-data-grid";
import * as D from "../../styles/appComponents";
import { ptBR } from "@mui/x-data-grid/locales";
import { customFilterOperator } from "./customFilterOperator";
import { GridFilterModelDescription } from "../../models/GridFilterModelDescription";
import { InputFilterModel } from "../../models/InputFilterModel";
import { GlobalStyles } from "@mui/material";

interface DatataFilterProps {
  columnsTable: GridColDef[];
  rows: any;
  applyFilter: (model:InputFilterModel | null, type:"clear"|"filter") => void;
  handleChangePageSize: (value:number) => void;
  getRowId?: GridRowIdGetter;
  statusLoading?:{sectionName:string, loading:boolean}
  sectionName?:string;
  hasFilter?:boolean;
}

const CustomToolbar = (
    statusLoading:{sectionName:string, loading:boolean} | undefined, 
    sectionName:string | undefined,
    hasFilter?:boolean
) => {
    return (
        <>
        {
            hasFilter?
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                {
                    statusLoading?.loading && statusLoading.sectionName === sectionName &&
                    <span style={{ color:"#D24293" }}>Carregando...</span>
                }
            </GridToolbarContainer>
        :
        <></>
        }
        </>

    );
}

export const DataTableFilter:React.FC<DatataFilterProps> = (
    { 
        columnsTable, 
        rows,
        applyFilter,
        getRowId,
        statusLoading,
        sectionName,
        hasFilter,
    }
) => {
    const [filterModel, setFilterModel] = React.useState<GridFilterModelDescription>({ items: [] });

    const handleFilterChange = (model:GridFilterModelDescription) => {
        const updatedItems:GridFilterModelDescription = {items:model.items.map((filter:any) => {
            const column = columnsTable.find(col => col.field === filter.field);
            return {
                ...filter,
                description: column?.description || ""
            };
        })};

        setFilterModel(updatedItems);
    };

    React.useEffect(() => {
        if (!filterModel?.items?.length) {
            applyFilter(null,"clear")
            return;
        }
        

    }, [filterModel]);

    const hiddenFilterFields = ["id", "name","instalacao","date"];

    const columns = React.useMemo(
        () =>
            columnsTable.map((col) => ({
                ...col,
                filterable: hiddenFilterFields.includes(col?.description || ""),
                filterOperators: customFilterOperator(applyFilter),
            })),
        [columnsTable]
    );

    return (
        <div style={{ height: "auto", width: "100%" }}>
            <D.DefaultTable
                autoHeight
                hideFooter
                rowSelection
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rows}
                columns={columns}
                slots={{ toolbar: () => CustomToolbar(statusLoading, sectionName, hasFilter) }}
                filterModel={filterModel}
                getRowId={getRowId}
                getRowHeight={() => "auto"}
                onFilterModelChange={handleFilterChange as any}
                sx={{
                    "& .MuiDataGrid-cell": {
                        whiteSpace: "normal",
                        lineHeight: "1.5",
                        padding: "5px"
                    }
                }}
                slotProps={{
                    filterPanel: {
                      sx: {
                        width: "600px", 
                        maxHeight: "80vh",
                        position: "absolute",
                        top: "-120px",
                        left: "100px",
                        zIndex: 9999,
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      },
                    },
                  }}
                
            />
        </div>
    );
};
