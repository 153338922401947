import { Chip, TextField } from "@mui/material";
import * as C from "../../style";
import { EnumTipoChavePix } from "../../../../../enums/EnumTipoChavePix.enum";
import { ICaptador } from "../../../../../models/Captador";
import RegisterCardButton from "../../../../../components/RegisterCardButton/RegisterCardButton";

interface IComissaoDadosPagamentoProps {
  captador: ICaptador;
}

const ComissaoDadosPagamento: React.FC<IComissaoDadosPagamentoProps> = ({
  captador,
}) => {
  return (
    <RegisterCardButton title="Comissão e dados para pagamentos">
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          value={captador.percentualComissao}
          label="Porcentual de comissão"
          inputProps={{ readOnly: true }}
          fullWidth
        />
        <TextField
          value={captador.comissaoPorExtenso}
          label="Comissão por extenso"
          inputProps={{ readOnly: true }}
          fullWidth
        />
        <TextField
          value={captador.vigenciaContrato}
          label="Vigência do contrato"
          inputProps={{ readOnly: true }}
          fullWidth
        />
      </C.FWStack>
      <C.FWStack direction={"row"} spacing={2}>
        <C.Subtitle>Dados para pagamento de comissão*</C.Subtitle>
      </C.FWStack>
      <C.FWStack direction={"row"} spacing={16}>
        <C.MWDiv>
          <C.Subtitle>Dados bancários*</C.Subtitle>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              value={captador.agencia}
              label="Agência"
              inputProps={{ readOnly: true }}
              fullWidth
            />
            <TextField
              value={captador.conta}
              label="Conta"
              inputProps={{ readOnly: true }}
              fullWidth
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              value={captador.numero}
              label="Número"
              inputProps={{ readOnly: true }}
              fullWidth
            />
            <TextField
              value={captador.banco}
              label="Banco"
              inputProps={{ readOnly: true }}
              fullWidth
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              value={captador.banco}
              label="CNPJ do banco"
              inputProps={{ readOnly: true }}
              fullWidth
            />
            <C.FWDiv></C.FWDiv>
          </C.FWStack>
        </C.MWDiv>
        <C.MWDiv>
          <C.Subtitle>Pix*</C.Subtitle>
          <C.FWStack direction={"row"} spacing={2}>
            <Chip
              label="CPF"
              color={
                Object.values(EnumTipoChavePix)[
                  Number(captador.tipoChavePix)
                ] === EnumTipoChavePix.CPF
                  ? "primary"
                  : "default"
              }
            />
            <Chip
              label="CNPJ"
              color={
                Object.values(EnumTipoChavePix)[
                  Number(captador.tipoChavePix)
                ] === EnumTipoChavePix.CNPJ
                  ? "primary"
                  : "default"
              }
            />
            <Chip
              label="Email"
              color={
                Object.values(EnumTipoChavePix)[
                  Number(captador.tipoChavePix)
                ] === EnumTipoChavePix.email
                  ? "primary"
                  : "default"
              }
            />
            <Chip
              label="Celular"
              color={
                Object.values(EnumTipoChavePix)[
                  Number(captador.tipoChavePix)
                ] === EnumTipoChavePix.celular
                  ? "primary"
                  : "default"
              }
            />
            <Chip
              label="Aleatória"
              color={
                Object.values(EnumTipoChavePix)[
                  Number(captador.tipoChavePix)
                ] === EnumTipoChavePix.aleatoria
                  ? "primary"
                  : "default"
              }
            />
          </C.FWStack>
          <C.FWStack direction={"row"} spacing={2}>
            <TextField
              value={captador.chavePix}
              label="Chave PIX"
              inputProps={{ readOnly: true }}
              fullWidth
            />
          </C.FWStack>
        </C.MWDiv>
      </C.FWStack>
    </RegisterCardButton>
  );
};

export default ComissaoDadosPagamento;
