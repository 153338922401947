import { FC, useMemo } from "react";
import {
  EscolhaBinaria,
  LISTA_ESCOLHA_BINARIA,
} from "../../models/EscolhaBinaria";
import SelectInput from "./SelectInput";

interface ISelectSimNaoProps {
  idSelect?: string;
  label: string;
  value: boolean | null;
  onChange: (newValue: boolean | null) => void;
  disabled?: boolean;
  limparSelecao?: boolean;
}

const SelectSimNao: FC<ISelectSimNaoProps> = ({
  idSelect,
  label,
  value,
  onChange,
  disabled,
  limparSelecao,
}) => {
  const valor = useMemo(() => {
    if (value === null) return null;
    return value ? "Sim" : "Não";
  }, [value]);

  const onChangeValue = (newValue: EscolhaBinaria | null) => {
    if (newValue === null) {
      onChange(null);
    } else {
      onChange(newValue === "Sim");
    }
  };

  return (
    <SelectInput
      idSelect={idSelect}
      label={label}
      value={valor}
      values={LISTA_ESCOLHA_BINARIA}
      getValue={(v: EscolhaBinaria) => v}
      onChange={onChangeValue}
      disabled={disabled}
      limparSelecao={limparSelecao}
    />
  );
};

export default SelectSimNao;
