import RegisterCard from "../../../../../../components/RegisterCard";
import React, {FC, useEffect, useState} from "react";
import * as C from "./style";
import * as G from "../../style";
import {Checkbox, Stack, TextField} from "@mui/material";
import ImageInput from "../../../../../../components/ImageInput";
import * as D from "../../../../../../styles/appComponents";
import {
  EnumSituacaoColaborador,
  enumSituacaoColaboradorMapping
} from "../../../../../../enums/EnumSituacaoColaborador.enum";
import {IFormSituacao} from "../../../../../../models/Colaborador/cadastros/Situacao";
import {ErrorMessage} from "../../../../../../utils/AbstractValidation";
import {handleStyleFieldError} from "../../../../../../utils/verifyFieldError";
import {ColaboradorByIdResponse} from "../../../../../../models/Colaborador/Colaborador";
import {SkeletonSectionForm} from "../../../../../../components/SkeletonLoads/SectionForm";

enum FormSituacao {
  EMPRESA = "EMPRESA",
  NUMEROREGISTRO = "NUMEROREGISTRO",
  SITUACAO = "SITUACAO",
  FOTO = "FOTO",
  CODIGOCONDICAO = "CODIGOCONDICAO",
  CONDICAO = "CONDICAO",
  DATAAFASTAMENTO = "DATAAFASTAMENTO",
}

type PropsSituacao = {
  onSave: (data: IFormSituacao) => void;
  onUpdate: () => void;
  onChange: (data: IFormSituacao) => void;
  buttonSave: boolean;
  fieldErros: ErrorMessage[] | null;
  data?: object | null;
  colaboradorId: string | undefined;
  colaborador: ColaboradorByIdResponse | undefined;
  load: { status:boolean, section:string };
};

const Situacao:FC<PropsSituacao> = (
  {
    onSave,
    onUpdate,
    onChange,
    buttonSave,
    fieldErros,
    colaboradorId,
    colaborador,
    load
  }
) => {
  const situacaoFormModel:IFormSituacao = {
    empresa: null,
    numeroregistro: null,
    situacao: EnumSituacaoColaborador.ativo,
    foto: null,
    codigocondicao: null,
    condicao: null,
    dataafastamento: null
  };
  const [dadosSituacao, setDadosSituacao] = useState<IFormSituacao>(situacaoFormModel)
  const sendOnSave = (): void => onSave(dadosSituacao);
  const sendOnUpdate = (): void => onUpdate();
  const sendOnChange = (update: IFormSituacao): void => onChange(update);

  useEffect(() => {
    if(colaboradorId){
      const situacaoFormModel:IFormSituacao = {
        empresa: colaborador?.empresa,
        numeroregistro: colaborador?.numeroRegistro,
        situacao: colaborador?.situacao as EnumSituacaoColaborador,
        foto: colaborador?.foto,
        codigocondicao: colaborador?.codigoCondicao,
        condicao: colaborador?.condicao,
        dataafastamento: colaborador?.dataAfastamento
      };
  
      setDadosSituacao(situacaoFormModel);
    }

  }, [colaboradorId, colaborador])

  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const handleInputChange = ({target}:React.ChangeEvent<HTMLInputElement>) => {
    const name = target.name.toLocaleLowerCase();
    const value = target.value;

    setDadosSituacao((state:IFormSituacao) => {
      sendOnChange({...state, [name]: value});
      return {...state, [name]: value};

    });

  }

  const handleImageChange = (newImage: File | null) => {
    setSelectedImage(newImage);
    if (newImage !== null) {
      setDadosSituacao((state:IFormSituacao) => {
        sendOnChange({...state, [FormSituacao.FOTO.toLocaleLowerCase()]: newImage});
        return {...state, [FormSituacao.FOTO.toLocaleLowerCase()]: newImage};
      });
    }
  };

  return (
    <RegisterCard title="Situação">
      {
        load?.status && load?.section === "situacao"?
        <SkeletonSectionForm />
        :
        <C.Container>
        <C.Left>
          <G.FWStack direction={"row"} spacing={2}>
            <TextField
              inputProps={{ readOnly: buttonSave }}
              sx={handleStyleFieldError(FormSituacao.EMPRESA, fieldErros)} 
              name={FormSituacao.EMPRESA}
              value={dadosSituacao?.empresa ?? ""}
              label="Empresa" 
              fullWidth 
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }} 
            />
            <TextField 
              inputProps={{ readOnly: buttonSave }}
              type="number" 
              label="Número de registro"
              value={dadosSituacao?.numeroregistro ?? ""}
              name={FormSituacao.NUMEROREGISTRO}
              sx={handleStyleFieldError(FormSituacao.NUMEROREGISTRO, fieldErros, { width: "40%" })} 
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}  
            />
          </G.FWStack>
          <G.FWStack direction={"row"} style={{ alignItems: "end" }} spacing={2}>
            <Stack direction={"column"} spacing={2} alignItems={"flex-start"}>
              <G.Title>Situação</G.Title>
              {
                enumSituacaoColaboradorMapping.map((x) => (
                  <G.CheckboxWrapper key={x.value}>
                  <Checkbox
                    sx={handleStyleFieldError(FormSituacao.SITUACAO, fieldErros, { margin: 0 })}
                    inputProps={{ readOnly: buttonSave }}
                    checked={dadosSituacao?.situacao === x.value}
                    value={dadosSituacao?.situacao ?? ""}
                    onChange={() => handleInputChange(
                      { target: { name: FormSituacao.SITUACAO, value: x.value} } as any)}
                  />
                  <p>{x.name}</p>
                </G.CheckboxWrapper>
                ))
              }
            </Stack>
            <TextField
              sx={handleStyleFieldError(FormSituacao.CODIGOCONDICAO, fieldErros)}
              inputProps={{ readOnly: buttonSave }} 
              type="number" 
              label="Código da condição"
              value={dadosSituacao?.codigocondicao ?? ""}
              name={FormSituacao.CODIGOCONDICAO}
              fullWidth 
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}  
            />
            <TextField
              sx={handleStyleFieldError(FormSituacao.CONDICAO, fieldErros)} 
              inputProps={{ readOnly: buttonSave }}
              label="Condição"
              value={dadosSituacao?.condicao ?? ""}
              name={FormSituacao.CONDICAO} 
              fullWidth 
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}  
            />
            <D.DateTextfield
              sx={handleStyleFieldError(FormSituacao.DATAAFASTAMENTO, fieldErros)} 
              inputProps={{ readOnly: buttonSave }} 
              type="date" 
              label="Data afastamento"
              value={
                ((dadosSituacao?.dataafastamento ?? "")
                .toString())?.split("T")[0]
              }
              name={FormSituacao.DATAAFASTAMENTO}
              fullWidth 
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}  
            />
          </G.FWStack>
        </C.Left>
        <C.Right>
          <ImageInput
            readonly={buttonSave}
            value={dadosSituacao?.foto}
            onImageChange={(value) => {
              handleInputChange({
                target:{
                  name: FormSituacao.FOTO,
                  value: null
                }
              } as any);
              handleImageChange(value);
            }}

          />
        </C.Right>
      </C.Container>
      }

      <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton disabled={!buttonSave} $color="yellow" onClick={sendOnUpdate}>Editar</D.ContainedButton>
          <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>Salvar</D.ContainedButton>
        </C.FWStack>
    </RegisterCard>
  );
};

export default Situacao;
