import {ColorType} from "../types/ColorType";
import {StatusBuilder} from "../models/StatusBuilder";

export enum EnumStatusGDProposta {
  naoTeveInteresse = 0,
  emAnalise = 1,
  contratoGerado = 2,
  reprovada = 3,
  cancelada = 4,
  aprovada = 5,
}

export enum EnumStatusGDPropostaMapSelect {
  naoTeveInteresse = "Não teve interesse",
  emAnalise = "Em analise",
  contratoGerado = "Contrato gerado",
  reprovada = "Reprovada",
  cancelada = "Cancelada",
  aprovada = "Aprovada",
}

export const StatusPropostaNumberMap: {
  [key: number]: string;
} = {
  0: "Não teve interesse",
  1: "Em análise",
  2: "Contrato gerado",
  3: "Reprovada",
  4: "Cancelada",
  5: "Aprovada",
};

export const EnumStatusGDPropostaNumericMap = {
  [EnumStatusGDProposta.naoTeveInteresse]: 0,
  [EnumStatusGDProposta.emAnalise]: 1,
  [EnumStatusGDProposta.contratoGerado]: 2,
  [EnumStatusGDProposta.reprovada]: 3,
  [EnumStatusGDProposta.cancelada]: 4,
  [EnumStatusGDProposta.aprovada]: 5,
};

export const StatusPropostaColorMap: Record<number, ColorType> = {
  0: "yellow",
  1: "blue",
  2: "green",
  3: "red",
  4: "orange",
  5: "purple",
};

export interface StatusGDProposta {
  quantidade: number;
  totalReais: number;
  totalkWh: number;
  status: EnumStatusGDProposta;
}

export const EnumStatusPropostaMap: StatusBuilder[] = [
  {
    name: "Não teve interesse",
    value: EnumStatusGDProposta.naoTeveInteresse,
    color: "yellow",
    titleThird: "Valor total de kwh",
    typeNumberFormatterThird: "KWH",
    titleSecondary: "Valor total de contratos",
    typeNumberFormatter: "$",
    primary: true
  },
  {
    name: "Em análise",
    value: EnumStatusGDProposta.emAnalise,
    color: "blue",
    titleThird: "Valor total de kwh",
    typeNumberFormatterThird: "KWH",
    titleSecondary: "Valor total de contratos",
    typeNumberFormatter: "$",
    primary: true
  },
  {
    name: "Aprovada",
    value: EnumStatusGDProposta.aprovada,
    color: "purple",
    titleThird: "Valor total de kwh",
    typeNumberFormatterThird: "KWH",
    titleSecondary: "Valor total de contratos",
    typeNumberFormatter: "$",
    primary: true
  },
  {
    name: "Contrato Gerado",
    value: EnumStatusGDProposta.contratoGerado,
    color: "green",
    titleThird: "Valor total de kwh",
    typeNumberFormatterThird: "KWH",
    titleSecondary: "Valor total de contratos",
    typeNumberFormatter: "$",
    primary: true
  },
  {
    name: "Reprovado",
    value: EnumStatusGDProposta.reprovada,
    color: "red",
    titleThird: "Valor total de kwh",
    typeNumberFormatterThird: "KWH",
    titleSecondary: "Valor total de contratos",
    typeNumberFormatter: "$",
    primary: true
  },
  {
    name: "Cancelado",
    value: EnumStatusGDProposta.cancelada,
    color: "orange",
    primary: false
  },
];