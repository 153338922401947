import { Box, Stack, styled, Typography } from "@mui/material";

export const FWStack = styled(Stack)({
  width: "100%",
});

export const Subtitle = styled(Typography)({
  margin: 0,
  fontWeight: 500,
  fontSize: "14px",
});

export const MWDiv = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "50%",
  gap: "20px",
});
