import { Button, IconButton, Select } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.h2`
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  color: #222222;

  @media (max-width: 1034px) {
    cursor: pointer;
  }
`;

export const StatusArea = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  flex-wrap: wrap;
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-basis: 15%;
  flex-direction: column;
  gap: 16px;
`;

export const CardDataAreaMobile = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
`;

export const CardDataMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  flex-basis: 48%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 3px 3px 6px 6px rgba(0, 0, 0, 0.06);
  padding: 15px 10px;
`;

export const CardDataLineMobile = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
`;

export const CardDataItemMobile = styled.div<{ $center?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: auto;
  white-space: nowrap;
  gap: 5px;
  align-items: ${(props) => (props.$center ? "center" : "flex-start")};

  label {
    color: #222;
    font-size: 14px;
    font-weight: 300;
  }

  p {
    margin: 0;
    color: #222;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const ReportsArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  align-items: flex-end;
  flex-wrap: wrap;
`;

export const SelectArea = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;

  @media (max-width: 1024px) {
    flex-basis: 48%;
  }
`;

export const ReportsLabel = styled.label`
  color: #3e3c3c;
  font-size: 14px;
  font-weight: 400;
`;

export const ReportsSelect = styled(Select)`
  width: 100%;
  text-align: start;
`;

export const ExportButton = styled(Button)`
  width: auto;
  background-color: #107c41;
  color: white;
  margin-top: 10px;

  &:hover {
    background-color: #185c37;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const LineBreak = styled.hr`
  height: 2px;
  width: 100%;
  opacity: 0.3;
  background: #222222;
`;

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const ModalArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  height: 80%;
  border-radius: 10px;
  background: #f8f8f8;
  padding: 20px;
  overflow-y: auto;

  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 425px) {
    padding: 10px;
  }
`;

export const ModalCard = styled.div`
  border-radius: 10px;
  background: #fff;
  width: 100%;
  box-shadow: 6px 6px 6px #0000000f;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;

  h4 {
    margin: 0;
    color: #222222;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
    color: #222222;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const CloseButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  color: gray;
  aspect-ratio: 1/1;
`;

export const ModalLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between-;
  width: 100%;
  gap: 20px;
`;

export const ModalItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: 30%;
  gap: 10px;

  label {
    color: #222222;
    font-size: 16px;
    font-weight: 400;
  }

  p {
    margin: 0;
    color: #222222;
    font-size: 14px;
    font-weight: 300;
  }
`;

export const ModalTextIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ModalStatusArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
`;

export const ModalStatus = styled.div`
  display: flex;
  flex: 1;
  flex-basis: auto;
  gap: 10px;
`;

export const ModalButtonsArea = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
`;

export const ModalDocumentButton = styled(Button)`
  background-color: #144fe7;
  color: white;

  &:hover {
    background-color: #1244c4;
  }

  @media (max-width: 768px) {
    flex: 1;
    flex-basis: 48%;
  }
`;

export const ModalHistoricButton = styled(Button)`
  background-color: #f6e948;
  color: white;

  &:hover {
    background-color: #e6d940;
  }

  @media (max-width: 768px) {
    flex: 1;
    flex-basis: 48%;
  }
`;

export const ModalProposalButton = styled(Button)`
  background-color: #107c41;
  color: white;
  flex: 1;
  flex-basis: 23%;

  &:hover {
    background-color: #0f703b;
  }

  @media (max-width: 768px) {
    flex-basis: 48%;
  }
`;

export const ModalSaveButton = styled(Button)`
  background-color: #d24293;
  color: white;

  &:hover {
    background-color: #bf3b86;
  }

  @media (max-width: 768px) {
    flex: 1;
    flex-basis: 48%;
  }
`;

export const ModalGridDownloadButton = styled(Button)`
  color: #144fe7;
  border-color: #144fe7;

  &:hover {
    background-color: #144fe7;
    color: white;
    border-color: #144fe7;
  }

  @media (max-width: 768px) {
    font-size: 10px;

    span {
      margin-right: 2px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }
`;

export const ModalGridDeleteButton = styled(Button)`
  color: #d60d0d;
  border-color: #d60d0d;

  &:hover {
    background-color: #d60d0d;
    color: white;
    border-color: #d60d0d;
  }

  @media (max-width: 768px) {
    font-size: 10px;

    span {
      margin-right: 2px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }
`;

export const ModalPaginationArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const GridImage = styled.img<{ $disable?: boolean }>`
  filter: ${(props) => (props.$disable ? "grayscale(1)" : "none")};
  cursor: ${(props) => (props.$disable ? "default" : "pointer")};
`;
