import {AxiosResponse} from "axios";
import http from "../common/http-common";
import {IPaginatedList} from "../../models/PaginatedList";
import {IDistribuidoraDashboard} from "../../models/Distribuidora";
import {ITarifaFioB} from "../../models/TarifaFioB";

export const getOrdemPagamentoByDateAndTipoConexao: (date: number, tipoConexao: number) => Promise<AxiosResponse<ITarifaFioB, any>> = (
  date: number,
  tipoConexao: number
): Promise<AxiosResponse<ITarifaFioB, any>> => {
  return http.get(
    `/tarifaFioB/${date}/${tipoConexao}`,
  );
};

export const GetPaginatedTarifaFioB: (pageNumber: number, pageSize: number, search?: string) => Promise<AxiosResponse<IPaginatedList<ITarifaFioB>, any>> = (
  pageNumber: number,
  pageSize: number,
  search?: string
): Promise<AxiosResponse<IPaginatedList<ITarifaFioB>, any>> => {
  var searchString: string = "";

  if (search) {
    searchString = `&Search=${search}`;
  }

  return http.get<IPaginatedList<ITarifaFioB>>(
    `/TarifaFioB?pageNumber=${pageNumber}&pageSize=${pageSize}${searchString}`
  );
};

export const GetTarifaFioBReport: () => Promise<AxiosResponse<File, any>> = () => {
  return http.get<File>(`/tarifaFioB/BuscaRelatorioTarifaFioB`, {
    responseType: "blob",
  });
};

export const GetDashboardTarifaFioB: () => Promise<AxiosResponse<any, IDistribuidoraDashboard>> = (): Promise<AxiosResponse<any, IDistribuidoraDashboard>> => {
  return http.get(`/tarifaFioB/getDashboardTarifaFioB`);
};