export enum EnumTipoPagamento {
  ContaCemig = 0,
  TarifaFioB = 1,
  ContaDistribuidoraGerador = 2,
  PagamentoDiversosCliente = 3
}

export const enumTipoPagamentoMap = [
  {
    name: "Conta Cemig",
    value: EnumTipoPagamento.ContaCemig
  },
  {
    name: "Tarifa fio B",
    value: EnumTipoPagamento.TarifaFioB
  },
  {
    name: "Conta da distribuidora do próprio gerador",
    value: EnumTipoPagamento.ContaDistribuidoraGerador
  },
  {
    name: "Pagamento de diversos clientes",
    value: EnumTipoPagamento.PagamentoDiversosCliente
  }
];