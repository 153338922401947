import {ColorType} from "../types/ColorType";
import {StatusBuilder} from "../models/StatusBuilder";
import {EnumStatusGDProposta} from "./EnumStatusGDProposta.enum";

export enum EnumStatusBuscaFatura {
  aguardandoPagamento = 0,
  vencido = 1,
  quitado = 2,
  cancelado = 3
}

export const EnumStatusBuscaFaturaColorMap: { [key: number]: ColorType } = {
  [EnumStatusBuscaFatura.aguardandoPagamento]: "blue",
  [EnumStatusBuscaFatura.vencido]: "yellow",
  [EnumStatusBuscaFatura.quitado]: "green",
  [EnumStatusBuscaFatura.cancelado]: "red",
}

export const EnumStatusBuscaFaturaMap: StatusBuilder[] = [
  {
    name: "Aguardando pagamento",
    value: EnumStatusBuscaFatura.aguardandoPagamento,
    color: EnumStatusBuscaFaturaColorMap[EnumStatusBuscaFatura.aguardandoPagamento],
    titleSecondary: "Aguardando Pagamento",
    typeNumberFormatter: "$",
    primary: true
  },
  {
    name: "Vencido",
    value: EnumStatusBuscaFatura.vencido,
    color: EnumStatusBuscaFaturaColorMap[EnumStatusBuscaFatura.vencido],
    titleSecondary: "Vecido",
    typeNumberFormatter: "$",
    primary: true
  },
  {
    name: "Quitado",
    value: EnumStatusBuscaFatura.quitado,
    color: EnumStatusBuscaFaturaColorMap[EnumStatusBuscaFatura.quitado],
    titleSecondary: "quitado",
    typeNumberFormatter: "$",
    primary: true
  },
  {
    name: "Cancelado",
    value: EnumStatusBuscaFatura.cancelado,
    color: EnumStatusBuscaFaturaColorMap[EnumStatusBuscaFatura.cancelado],
    titleSecondary: "cancelado",
    typeNumberFormatter: "$",
    primary: true
  },
];
