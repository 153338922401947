import {
  CELULAR,
  CEP,
  CNPJ,
  CPF,
  DECIMAL,
  KWH,
  PORCENTAGEM,
  REAL,
  RG,
} from "../../utils/masInputs";

export const handleTypeMasks = [
  {
    type: "CPF",
    applyMask: (value: any, _: any) => new CPF().executeForInput(value),
    applyChanges: (value: any, onChange: any) => onChange?.(value),
    newValue: (value: any) => (value = value.slice(0, 11)),
  },
  {
    type: "RG",
    applyMask: (value: any, _: any) => new RG().executeForInput(value),
    applyChanges: (value: any, onChange: any) => onChange?.(value),
    newValue: (value: any) => (value = value.slice(0, 11)),
  },
  {
    type: "CNPJ",
    applyMask: (value: any, _: any) => new CNPJ().executeForInput(value),
    applyChanges: (value: any, onChange: any) => onChange?.(value),
    newValue: (value: any) => (value = value.slice(0, 14)),
  },
  {
    type: "CELULAR",
    applyMask: (value: any, countryCode: any) => new CELULAR().executeForInput(value, countryCode),
    applyChanges: (value: any, onChange: any, newValue?: any) =>
      onChange?.({ value: `${value}`, zipCode: newValue }),
    newValue: (value: any) => (value),
  },
  {
    type: "REAL",
    applyMask: (value: any, _: any) => new REAL().executeForInput(value),
    applyChanges: (value: any, onChange: any, newValue?: any) =>
      onChange?.((value as unknown as number) ? value : value),
    newValue: (value: any) => (value = (parseFloat(value) / 100).toString()),
  },
  {
    type: "CEP",
    applyMask: (value: any, _: any) => new CEP().executeForInput(value),
    applyChanges: (value: any, onChange: any, newValue?: any, func?: any) => {
      if (value.length === 8) {
        return func?.(value);
      } else {
        return onChange?.({ value: `${value}` });
      }
    },
    newValue: (value: any) => value.slice(0, 8),
  },
  {
    type: "PORCENTAGEM",
    applyMask: (value: any, _: any) => new PORCENTAGEM().executeForInput(value),
    applyChanges: (value: any, onChange: any, newValue?: any) =>
      onChange?.(value),
    newValue: (value: any) => value.replace(",", "."),
  },
  {
    type: "DECIMAL",
    applyMask: (value: any, _: any) => new DECIMAL().executeForInput(value),
    applyChanges: (value: any, onChange: any, newValue?: any) =>
      onChange?.(value),
    newValue: (value: any) => value.slice(0, 3),
  },
  {
    type: "KWH",
    applyMask: (value: any, _: any) => new KWH().executeForInput(value),
    applyChanges: (value: any, onChange: any, newValue?: any) =>
      onChange?.(value),
    newValue: (value: any) => value,
  },
];
