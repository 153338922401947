import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import DefaultModal from "../../../../../components/Modal";
import * as D from "../../../../../styles/appComponents";
import { PessoaType } from "../../../../../types/PessoaType";

type PropsModalContratar = {
  openModalContratar: boolean;
  handleCloseModalContratar: () => void;
  callback: (type: PessoaType) => void;
  uuidModalContratar?: string;
};

const ModalContratar = (props: PropsModalContratar) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  let { openModalContratar, handleCloseModalContratar, callback } = props;

  const handleClick = async (type: PessoaType) => {
    callback(type);
  };

  useEffect(() => {
    setOpenModal(openModalContratar);
  }, [openModalContratar]);

  return (
    <>
      <DefaultModal
        handleCloseModal={handleCloseModalContratar}
        openModal={openModalContratar}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
            textAlign: "center",
            width: "100%",
            height: "100%",
            marginTop: "-50px",
          }}
        >
          <h1 style={{ fontWeight: 500, fontSize: "1.8rem", margin: "0" }}>
            Quem está contratando o serviço?
          </h1>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "15px",
            }}
          >
            <D.OutlineButton
              $color="lightpink"
              onClick={() => handleClick("pessoaFisica")}
              style={{
                padding: "12px 24px",
                fontSize: "1.2rem",
              }}
            >
              Pessoa física
            </D.OutlineButton>

            <D.ContainedButton
              $color="lightpink"
              onClick={() => handleClick("pessoaJuridica")}
              style={{
                padding: "12px 24px",
                fontSize: "1.2rem",
              }}
            >
              Pessoa jurídica
            </D.ContainedButton>
          </Box>
        </Box>
      </DefaultModal>
    </>
  );
};

export default ModalContratar;
