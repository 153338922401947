export enum ResponsavelLegalEnum {
  NOME = "NOME",
  EMAIL = "EMAIL",
  CELULAR = "CELULAR",
  CPF = "CPF",
  CEP = "CEP",
  UF = "UF",
  CIDADE = "CIDADE",
  BAIRRO = "BAIRRO",
  NUMERO = "NUMERO",
  COMPLEMENTO = "COMPLEMENTO",
  LOGRADOURO = "LOGRADOURO",
  DATANASCIMENTO = "DATANASCIMENTO",
  ADDFORM = "ADDFORM",
  REMOVEFORM = "REMOVEFORM",
  COUNTRYCODE = "COUNTRYCODE"
}