export const convertEmptyStringsToNullAsync = async <
  T extends Record<string, any>
>(
  obj: T
): Promise<T> => {
  const updatedObj = { ...obj } as Record<string, any>; // Permite mutação

  await Promise.all(
    Object.keys(updatedObj).map(async (key) => {
      const value = updatedObj[key];

      if (typeof value === "string" && value === "") {
        updatedObj[key] = null; // Converte strings vazias para null
      } else if (typeof value === "object" && value !== null) {
        updatedObj[key] = await convertEmptyStringsToNullAsync(value); // Aplica recursivamente em objetos aninhados
      }
    })
  );

  return updatedObj as T; // Retorna no tipo original
};
