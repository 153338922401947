export interface Validate<T> {
  field: keyof T;
  validate: (value: any, model: T, addError: (message: string) => void) => void;
}

export interface ErrorMessage<T = any> {
  ref: keyof T;
  message: string;
  index?: number | null;
}

export abstract class AbstractValidation<T> {
  errorMessage(model: T, index?: number | null): ErrorMessage<T>[] | null {
    const erros: ErrorMessage<T>[] | null = [];
    const validate = this.getValidate();

    validate.forEach((item) => {
      item.validate(model[item.field], model, (message: string) => {
        erros.push({ ref: item.field, message, index });
      });
    });

    return erros.length === 0 ? null : erros;
  }

  validarCampo(model: T, field: keyof T): ErrorMessage<T>[] | null {
    const erros: ErrorMessage<T>[] | null = [];
    const validate = this.getValidate();

    validate
      .filter((item) => item.field === field)
      .forEach((item) => {
        item.validate(model[item.field], model, (message: string) => {
          erros.push({ ref: item.field, message });
        });
      });

    return erros.length ? erros : null;
  }

  abstract getValidate(): Validate<T>[];
}
