import {IFormSituacao} from "../../../../../../models/Colaborador/cadastros/Situacao";
import {AbstractValidation, Validate} from "../../../../../../utils/AbstractValidation";

export class SituacaoValidate extends AbstractValidation<IFormSituacao>{
    
    getValidate():Validate<IFormSituacao>[]{
        return[
            {
                field:"empresa",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("O campo de tipo de empresa deve ser preenchido","empresa")
                }
            },
            {
                field:"numeroregistro",
                validate: (value, data, addError:(message:string, ref:string) => void) => {
                    if(!value) addError("O campo numero de registro deve ser preenchido","numeroregistro")
                }
            }
        ];
    }
}