import React, {useState} from 'react';
import RegisterCard from "../../../../../components/RegisterCard";
import * as D from "../../../../../styles/appComponents";
import {TextField} from "@mui/material";

export interface IEconomiaImpostos {
  economia: string | null;
  tributo: string | null;
  economiaLiquida: string | null;
}

export enum EconomiaImpostosEnum {
  ECONOMIA = 'economia',
  TRIBUTO = 'TRIBUTO',
  ECONOMIA_LIQUIDA = 'economiaLiquida'
}

type EconomiaImpostosProps = {
  callback: (dadosPagador: IEconomiaImpostos) => void;
}

const EconomiaImpostos: (props: EconomiaImpostosProps) => React.JSX.Element = (props: EconomiaImpostosProps): React.JSX.Element => {
  //region Variáveis
  const {callback} = props;
  const [detalhesGd, setDetalhesGd] = useState<IEconomiaImpostos>({
    economiaLiquida: null,
    tributo: null,
    economia: null
  });
  //endregion

  //region UI
  const handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setDetalhesGd((state: IEconomiaImpostos) => {
      const newData = {
        ...state,
        [name]: value,
      };
      callback(newData);
      return newData;
    });
  };
  //endregion

  return (
    <>
      <RegisterCard title="Economia/impostos">
        <D.FWStack direction={"row"} spacing={2}>
          <TextField
            label="Economia"
            required
            fullWidth
            name={EconomiaImpostosEnum.ECONOMIA}
            value={detalhesGd.economia}
            onChange={handleInputChange}
          />
          <TextField
            label="Tributo"
            required
            fullWidth
            name={EconomiaImpostosEnum.TRIBUTO}
            value={detalhesGd.tributo}
            onChange={handleInputChange}
          />
          <TextField
            label="Economia líquida"
            required
            fullWidth
            name={EconomiaImpostosEnum.ECONOMIA_LIQUIDA}
            value={detalhesGd.economiaLiquida}
            onChange={handleInputChange}
          />
        </D.FWStack>
      </RegisterCard>
    </>
  );
};

export default EconomiaImpostos;