import {EnumTipoDocumento} from "../enums/EnumTipoDocumento.enum";
import {EnumTipoParceiro, EnumTipoParceiroNumericMap} from "../enums/EnumTipoParceiro.enum";
import {EnumTipoConexao} from "../enums/Gerador/EnumTipoConexao.enum";
import {ICreateAssociado} from "../models/Associado";
import {IFeriasForm} from "../models/Colaborador/cadastros/Ferias";
import {
  ConsultaCreate,
  ConsultaUpdate,
  ICreateColaborador,
  IUpdateColaborador
} from "../models/Colaborador/Colaborador";
import {DadosPagamentos} from "../models/Gerador/Cadastro/DadosPagamentoGerador";
import {
  IFormCreateGeradorPessoaFisica,
  IFormCreateGeradorPessoaJuridica,
  IFormUpdateGeradorPessoaFisica,
  IFormUpdateGeradorPessoaJuridica
} from "../models/Gerador/Cadastro/Gerador";
import {iResponsavelIndicacao} from "../models/Gerador/Cadastro/ResponsavelIndicacao";
import {IResponsavelLegal} from "../models/Gerador/Cadastro/ResponsavelLegal";
import {
  IFormInputLinhasDeOnibus
} from "../pages/private/Colaborador/Cadastro/components/BeneficiosComissao";
import {IDocumentoForm} from "../pages/private/Colaborador/Cadastro/components/Documentos";
import {calcValues, formatValueStringInFloat} from "./moneyFormatter";

export const dataCreateAssociado = (model:any):ICreateAssociado => {

    return {
        razaoSocial: model?.razaosocial,
        cnpj: model?.cnpj,
        cep: model?.cep,
        uf: model?.uf,
        cidade: model?.cidade,
        bairro: model?.bairro,
        logradouro: model?.logradouro,
        numero: model?.numero,
        complemento: model?.complemento,
        responsaveisLegais: model?.dadosResponsavelLegal,
        responsavelComunicacao: model.responsavelComunicacao,
        percentualComissao: model?.percentualcomissao,
        comissaoPorExtenso: model?.comissaoporextenso,
        vigenciaContrato: model?.vigenciacontrato
          ? new Date(model?.vigenciacontrato)
          : undefined,
        banco: model?.banco,
        agencia: model?.agencia,
        conta: model?.conta,
        cnpjFavorecido: model?.cnpjfavorecido,
        favorecido: model?.favorecido,
        tipoChavePix: model?.tipochavepix,
        chavePix: model?.chavepix,
        enumConvenio: model?.enumconvenio !== undefined? 
          Number(model?.enumconvenio): undefined,
        backofficeId: model?.backoffice,
        isMaster: model?.ismaster? 
        Number(model?.ismaster) ===
          EnumTipoParceiroNumericMap[EnumTipoParceiro.associadoMaster]: undefined,
        limiteDesconto: model?.limitedesconto,
        statusContrato: model?.statuscontrato,
        ultimaAlteracaoStatus: model?.ultimaalteracaostatus? 
          new Date(model?.ultimaalteracaostatus): undefined,
        dataAssinaturaContrato: model?.dataassinaturacontrato? 
          new Date(model?.dataassinaturacontrato): undefined,
        uuidContrato: model?.uuidcontrato,
        cupomBoasVindas: model?.cupomboasvindas? 
          Number(model?.cupomboasvindas) : undefined,
        user: model?.login,
        password: model?.senha,
        userEmail: model?.login,
        userCelular: "",
        linkCaptador: model?.linkcaptador,
        captadorMasterId: model.user?.idCaptador
    }
}

export const dataCreateColaborador = (model:any):ICreateColaborador => {
  return{
    empresa: model?.empresa,
    numeroRegistro: model?.numeroregistro,
    codigoCondicao: model.codigocondicao,
    foto: model.foto instanceof File? model?.foto: null,
    situacao: model?.situacao,
    condicao: model?.condicao,
    dataAfastamento: model?.dataafastamento? 
    new Date(model?.dataafastamento): null,
    nome: model?.nome,
    dataNascimento: model?.datanascimento?
    new Date(model?.datanascimento): null,
    sexo: model?.sexo,
    estadoCivil: model?.estadocivil,
    grauInstrucao: model?.grauinstrucao,
    municipioNascimento: model?.municipionascimento,
    nacionalidade: model?.nacionalidade,
    dataAdmissao: model?.dataadmissao? 
    new Date(model.dataadmissao): null,
    cep: model?.cep,
    uf: model?.uf === undefined? null: model?.uf,
    cidade: model?.cidade === undefined? null: model?.cidade,
    bairro: model?.bairro,
    logradouro: model?.logradouro,
    numero: model?.numero,
    complemento: model?.complemento,
    celularParaContato: model?.telefone,
    countryCode: model.countrycode,
    emailParaContato: model?.email,
    banco: model?.banco,
    agencia: model?.agencia,
    conta: model?.conta,
    cpfFavorecido: model?.cpffavorecido,
    favorecido: model?.favorecido,
    tipoChavePix: model?.tipochavepix,
    chavePix: model?.chavepix,
    salarioBase: model?.salariobase?
    formatValueStringInFloat(model?.salariobase): null,
    funcao: model.funcao,
    partidoSindicato: model?.partidosindicato,
    boolPeriodoExperiencia: model?.boolperiodoexperiencia,
    inicioPeriodoExperiencia: model?.inicioperiodoexperiencia,
    fimPeriodoExperiencia: model?.fimperiodoexperiencia,
    boolIncideINSS: model?.boolincideinss,
    boolIncideIRF: model?.boolincideirf,
    boolIncideFGTS: model?.boolincidefgts,
    enumContribuicaoSindical: model?.enumcontribuicaosindical,
    enumTipoVaga: model?.enumtipovaga,
    enumHorasSemanais: model?.enumhorassemanais,
    boolPlanoSaude: model?.boolplanosaude,
    boolPlanoOdontologico: model?.boolplanoodontologico,
    enumModalidadePlanoSaude: model?.enummodalidadeplanosaude,
    enumModalidadePlanoOdontologico: model?.enummodalidadeplanoodontologico,
    boolPontoEletronico: model?.boolpontoeletronico,
    boolRecebeComissao: model?.boolrecebecomissao,
    enumTipoComissao: model?.enumtipocomissao,
    percentualComissao: model?.percentualcomissao,
    valorFixoComissao: model?.valorfixocomissao?
    formatValueStringInFloat(model?.valorfixocomissao): null,
    boolRecebeVR: model?.boolrecebevr,
    valorDiaVR: model?.valordiavr?
    formatValueStringInFloat(model?.valordiavr): null,
    boolRecebeVA: model?.boolrecebeva,
    valorDiaVA: model?.valordiava?
    formatValueStringInFloat(model?.valordiava): null,
    boolRecebeCombustivel: model?.boolrecebecombustivel,
    valorDiaCombustivel: model?.valordiacombustivel?
    formatValueStringInFloat(model?.valordiacombustivel): null,
    boolRecebeVT: model?.boolrecebevt,
    enumTipoVT: model?.enumtipovt,
    valorDiaVT: model?.valordiavt?
    formatValueStringInFloat(model?.valordiavt): null,
    user: model.login,
    password: model?.senha,
    userEmail: model?.emailparacontato,
    userCelular: model?.celularparacontato,
    whatsappDadosAcesso: model?.whatsappdadosacesso,
    CountryCodeWhatsapp : model?.whatsappcountrycode,
    documentos: (model.documentos || []).map((documento:IDocumentoForm) => {
      return {
        tipoDocumento: documento?.tipodocumento,
        numero: documento?.tipodocumento === EnumTipoDocumento.CPF? 
        documento?.cpf: documento?.tipodocumento === EnumTipoDocumento.RG? documento?.rg:
        documento.numero,
        PIS: documento?.pis,
        serie: documento?.serie,
        UF: documento?.uf
      }
    }),
    consultas: (model.consultas || []).map((consulta:ConsultaCreate) => {
      return {
        tipoConsulta: consulta?.tipoconsulta,
        exame: consulta?.exame,
        data: consulta?.data
      }
    }),
    linhaOnibus: (model?.linhasDeOnibus || [])?.map((linha:IFormInputLinhasDeOnibus) => {
      return{
        valorDiaBhBusVt: linha?.valordiabhbusvt?
        formatValueStringInFloat(linha?.valordiabhbusvt): null,
        valordiaOtimovt: linha?.valordiaotimovt?
        formatValueStringInFloat(linha?.valordiaotimovt): null,
        linhaBhBusIda: linha.linhabhbusida,
        linhaBhBusVolta: linha.linhabhbusvolta,
        linhaOtimoIda: linha.linhaotimoida,
        linhaOtimoVolta: linha.linhaotimovolta
      }
    }),
    feriasColaborador: (model?.ferias || [])?.map((x:IFeriasForm) => {
      return{
        id: x.id,
        periodoCompetencia: x?.periodocompetencia? 
        new Date(x.periodocompetencia): null,
        aquisitivoInicio: x?.aquisitivoinicio?
        new Date(x.aquisitivoinicio): null,
        aquisitivoFim: x?.aquisitivofim?
        new Date(x.aquisitivofim): null,
        gozoInicio: x?.gozoinicio?
        new Date(x.gozoinicio): null,
        gozoFim: x?.gozofim?
        new Date(x.gozofim): null,
        diasGozo: x?.diasgozo,
        solicitacaoAbono: x?.solicitacaoabono?
        new Date(x?.solicitacaoabono): null,
        abonoInicio: x?.abonoinicio?
        new Date(x.abonoinicio): null,
        abonoFim: x?.abonofim?
        new Date(x?.abonofim): null,
        diasAbono: x?.diasabono? parseInt(x?.diasabono): null
      }
    })
  }
}

export const dataUpdateColaborador = (model:any):IUpdateColaborador => {
  return{
    empresa: model?.empresa || undefined,
    numeroRegistro: model?.numeroregistro || undefined,
    codigoCondicao: model.codigocondicao || undefined,
    foto: model.foto instanceof File? model?.foto: null,
    situacao: model?.situacao,
    condicao: model?.condicao || undefined,
    dataAfastamento: model?.dataafastamento !== null && !isNaN(new Date(model?.dataafastamento as Date).getTime())? 
    new Date(model?.dataafastamento): undefined,
    nome: model?.nome || undefined,
    dataNascimento: model?.datanascimento !== null && !isNaN(new Date(model?.datanascimento as Date).getTime())?
    new Date(model?.datanascimento): undefined,
    sexo: model?.sexo,
    estadoCivil: model?.estadocivil,
    grauInstrucao: model?.grauinstrucao || undefined,
    municipioNascimento: model?.municipionascimento || undefined,
    nacionalidade: model?.nacionalidade || undefined,
    dataAdmissao: model?.dataadmissao !== null && !isNaN(new Date(model?.dataadmissao as Date).getTime())? 
    new Date(model.dataadmissao): null,
    cep: model?.cep || undefined,
    uf: model?.uf || undefined,
    cidade: model?.cidade || undefined,
    bairro: model?.bairro || undefined,
    logradouro: model?.logradouro || undefined,
    numero: model?.numero || undefined,
    complemento: model?.complemento || undefined,
    celularParaContato: model?.telefone || undefined,
    countryCode: model.countrycode || undefined,
    emailParaContato: model?.email || undefined,
    banco: model?.banco || undefined,
    agencia: model?.agencia || undefined,
    conta: model?.conta || undefined,
    cpfFavorecido: model?.cpffavorecido || undefined,
    favorecido: model?.favorecido || undefined,
    tipoChavePix: model?.tipochavepix || undefined,
    chavePix: model?.chavepix || undefined,
    salarioBase: model?.salariobase !== null && 
    model?.salariobase !== undefined?
    formatValueStringInFloat(model?.salariobase): undefined,
    funcao: model?.funcao || undefined,
    partidoSindicato: model?.partidosindicato || undefined,
    boolPeriodoExperiencia: model?.boolperiodoexperiencia,
    inicioPeriodoExperiencia: model?.inicioperiodoexperiencia !== null && !isNaN(new Date(model?.inicioperiodoexperiencia as Date).getTime())?
    new Date(model?.inicioperiodoexperiencia): undefined,
    fimPeriodoExperiencia: model?.fimperiodoexperiencia !== null && !isNaN(new Date(model?.fimperiodoexperiencia as Date).getTime())?
    new Date(model?.fimperiodoexperiencia): undefined,
    boolIncideINSS: model?.boolincideinss,
    boolIncideIRF: model?.boolincideirf,
    boolIncideFGTS: model?.boolincidefgts,
    enumContribuicaoSindical: model?.enumcontribuicaosindical,
    enumTipoVaga: model?.enumtipovaga,
    enumHorasSemanais: model?.enumhorassemanais,
    boolPlanoSaude: model?.boolplanosaude,
    boolPlanoOdontologico: model?.boolplanoodontologico,
    enumModalidadePlanoSaude: model?.enummodalidadeplanosaude,
    enumModalidadePlanoOdontologico: model?.enummodalidadeplanoodontologico,
    boolPontoEletronico: model?.boolpontoeletronico,
    boolRecebeComissao: model?.boolrecebecomissao,
    enumTipoComissao: model?.enumtipocomissao,
    percentualComissao: model?.percentualcomissao || undefined,
    valorFixoComissao: model?.valorfixocomissao !== null && 
    model?.valorfixocomissao !== undefined?
    formatValueStringInFloat(model?.valorfixocomissao): undefined,
    boolRecebeVR: model?.boolrecebevr,
    valorDiaVR: model?.valordiavr !== null && 
    model?.valordiavr !== undefined?
    formatValueStringInFloat(model?.valordiavr): undefined,
    boolRecebeVA: model?.boolrecebeva,
    valorDiaVA: model?.valordiava !== null && 
    model?.valordiava !== undefined?
    formatValueStringInFloat(model?.valordiava): undefined,
    boolRecebeCombustivel: model?.boolrecebecombustivel,
    valorDiaCombustivel: model?.valordiacombustivel !== null && 
    model?.valordiacombustivel !== undefined?
    formatValueStringInFloat(model?.valordiacombustivel): undefined,
    boolRecebeVT: model?.boolrecebevt,
    enumTipoVT: model?.enumtipovt,
    valorDiaVT: model?.valordiavt !== null && 
    model?.valordiavt !== undefined?
    formatValueStringInFloat(model?.valordiavt): undefined,
    user: model.login || undefined,
    password: model?.senha || undefined,
    userEmail: model?.emailparacontato || undefined,
    userCelular: model?.celularparacontato || undefined,
    whatsappDadosAcesso: model?.whatsappdadosacesso || undefined,
    countryCodeWhatsapp : model?.whatsappcountrycode || undefined,
    documentos: (!model?.documentos || model?.documentos?.length === 0)?undefined: model?.documentos?.map((documento:IDocumentoForm) => {
      return {
        id: documento?.id,
        tipoDocumento: documento?.tipodocumento,
        numero: documento?.tipodocumento === EnumTipoDocumento.CPF? 
        documento?.cpf: documento?.tipodocumento === EnumTipoDocumento.RG? documento?.rg:
        documento.numero,
        PIS: documento?.pis,
        serie: documento?.serie,
        UF: documento?.uf
      }
    }),
    consultas: (!model?.consultas || model?.consultas?.length === 0)?undefined: model?.consultas?.map((consulta:ConsultaUpdate) => {
      return {
        id: consulta?.id,
        tipoConsulta: consulta?.tipoconsulta,
        exame: consulta?.exame,
        data: consulta?.data
      }
    }),
    linhaOnibus: (!model?.linhasDeOnibus || model?.linhasDeOnibus?.length === 0)?undefined: model?.linhasDeOnibus?.map((linha:IFormInputLinhasDeOnibus) => {
      return{
        id: linha.id,
        valorDiaBhBusVt: linha?.valordiabhbusvt !== null && 
        linha?.valordiabhbusvt !== undefined?
        formatValueStringInFloat(linha?.valordiabhbusvt): undefined,
        valordiaOtimovt: linha?.valordiaotimovt !== null && 
        linha?.valordiaotimovt !== undefined?
        formatValueStringInFloat(linha?.valordiaotimovt): undefined,
        linhaBhBusIda: linha.linhabhbusida,
        linhaBhBusVolta: linha.linhabhbusvolta,
        linhaOtimoIda: linha.linhaotimoida,
        linhaOtimoVolta: linha.linhaotimovolta
      }
    }),
    feriasColaborador: (!model?.ferias || model?.ferias?.length === 0)?undefined: model?.ferias?.map((x:IFeriasForm) => {
      return{
        id: x.id,
        periodoCompetencia: x?.periodocompetencia? 
        new Date(x.periodocompetencia): null,
        aquisitivoInicio: x?.aquisitivoinicio?
        new Date(x.aquisitivoinicio): null,
        aquisitivoFim: x?.aquisitivofim?
        new Date(x.aquisitivofim): null,
        gozoInicio: x?.gozoinicio?
        new Date(x.gozoinicio): null,
        gozoFim: x?.gozofim?
        new Date(x.gozofim): null,
        diasGozo: x?.diasgozo,
        solicitacaoAbono: x?.solicitacaoabono?
        new Date(x?.solicitacaoabono): null,
        abonoInicio: x?.abonoinicio?
        new Date(x.abonoinicio): null,
        abonoFim: x?.abonofim?
        new Date(x?.abonofim): null,
        diasAbono: x?.diasabono? parseInt(x?.diasabono): null
      }
    })
  }
}

export const dataCreateGeradorPessoaJuridica = (model:any):IFormCreateGeradorPessoaJuridica => {
  return{
    nomeDadosAcesso: (model?.dadosResponsavelLegal ?? [{nome:""}])[0].nome,
    razaoSocialNome: model?.razaosocialnome,
    nomeGerador: model?.razaosocialnome,
    cnpjcpf: model?.cpfcnpj,
    instalacao: model?.instalacao,
    sigla: model?.sigla,
    cep: model?.cep,
    uf: model?.uf,
    cidade: model?.cidade,
    bairro: model?.bairro,
    logradouro: model?.logradouro,
    numero: model?.numero,
    complemento: model?.complemento,
    responsavelLegal:(model?.dadosResponsavelLegal ?? []).map((x:IResponsavelLegal) => {
      const {
        cpf,
        cep,
        uf,
        datanascimento,
        ...rest
      } = {...x, CPF: x.cpf, CEP: x.cep, countryCode: x.countrycode, UF: x.uf, dataNascimento: x.datanascimento}

      return rest;
    }),
    nomeResponsavelComunicacao: model?.nomeresponsavelcomunicacao,
    emailResponsavelComunicacao: model?.emailresponsavelcomunicacao,
    celularResponsavelComunicacao: model?.celularresponsavelcomunicacao,
    tipoPessoa: model?.tipopessoa,
    distribuidora: model?.distribuidora,
    tipoTarifa: model?.tipotarifa,
    porcentagemDescontoTarifa: model?.porcentagemdescontotarifa?
    formatValueStringInFloat(model?.porcentagemdescontotarifa): null,
    tarifaVigenteContrato: model?.tarifavigentecontrato?
    formatValueStringInFloat(model?.tarifavigentecontrato, 4): null,//4 casas
    potencia: model?.potencia?
    formatValueStringInFloat(model?.potencia, 1): null,//1 casa
    medidaCorrente: model?.medidacorrente,
    performaceAlvo: model?.performacealvo?
    formatValueStringInFloat(model?.performacealvo): null,
    potenciaPorExtenso: model?.potenciaporextenso,
    custoFixoPorExtenso: model?.custofixoporextenso,
    vigenciaContratual: model?.vigenciacontratual,//max12 min120 ??
    dataLimiteConexao: model?.datalimiteconexao? 
    new Date(model?.datalimiteconexao): null,
    tipoConexao: model?.tipoconexao,
    statusContratoGerador: model?.statuscontratogerador === undefined? null: model?.statuscontratogerador,
    dataAssinaturaContrato: model?.dataassinaturacontrato? new Date(model.dataassinaturacontrato): null,
    uuidContrato: null,
    responsavelIndicacaos: (model?.responsavelIndicacaos ?? []).map((x:iResponsavelIndicacao) => {
      const { 
        percentualcomissao, 
        ...rest 
      } = { 
        ...x, 
        percentualComissao: 
        x.percentualcomissao? parseFloat(x.percentualcomissao): null
      };
      return rest;
    }),
    agenciaResponsavelIndicacao: model?.agenciaresponsavelindicacao,
    bancoResponsavelIndicacao: model?.bancoresponsavelindicacao,
    contaResponsavelIndicacao: model?.contaresponsavelindicacao,
    cnpjFavorecidoResponsavelIndicacao: model?.cnpjfavorecidoresponsavelindicacao,
    chavePixResponsavelIndicacao: model?.chavepixresponsavelindicacao,
    agenciaPagamentoGerador: model.agenciapagamentogerador,
    cnpjFavorecidoPagamentoGerador: model.cnpjfavorecidopagamentogerador,
    bancoPagamentoGerador: model.bancopagamentogerador,
    tipoChavePixPagamentoGerador: model.tipochavepixpagamentogerador,
    favorecidoPagamentoGerador: model.favorecidopagamentogerador,
    contaPagamentoGerador: model.contapagamentogerador,
    chavePixPagamentoGerador: model.chavepixpagamentogerador,
    favorecidoResponsavelIndicacao: model?.favorecidoresponsavelindicacao,
    tipoChavePixResponsavelIndicacao: model?.tipochavepixresponsavelindicacao,
    tarifa:model.dadospagamentos? (model?.dadospagamentos ?? [])
    .map((x:DadosPagamentos) => { 
      return {
        tipoConexao: x.tipoconexao,
        descontoTarifaPercentual: x.descontotarifapercentual?
        parseFloat(x.descontotarifapercentual.toString()): null,
        tarifaGerador: x.tarifagerador?
        parseFloat(x.tarifagerador.toString()): null,
        tarifaVigente: x.tarifavigente?
        parseFloat(x.tarifavigente.toString()): null,
        dataInicial: x.datainicial? 
        new Date(x.datainicial): null,
        dataFinal: x.datafinal?
        new Date(x.datafinal): null,
      }
     }): [],
    emailDadosAcesso: model?.email,
    celularDadosAcesso: model?.telefone,
    whatsappDadosAcesso: model?.whatsapp,
    whatsappCountryCode: model?.whatsappcountrycode,
  }
}

export const dataCreateGeradorPessoaFisica = (model:any):IFormCreateGeradorPessoaFisica => {
  return{
    nomeDadosAcesso: model?.nomeresponsavelusina,
    nomeUsina: model?.nomeusina,
    nomeGerador: model?.nomeusina,
    cpf: model?.cpf,
    instalacao: model?.instalacao,
    sigla: model?.sigla,
    cep: model?.cep,
    uf: model?.uf,
    cidade: model?.cidade,
    bairro: model?.bairro,
    logradouro: model?.logradouro,
    numero: model?.numero,
    complemento: model?.complemento,
    nomeResponsavelUsina: model?.nomeresponsavelusina,
    emailResponsavelUsina: model?.emailresponsavelusina,
    celularResponsavelUsina: model?.celularresponsavelusina,
    countryCodeResponsavelUsina: model.countrycoderesponsavelusina,
    cpfResponsavelUsina: model?.cpfresponsavelusina,
    cepResponsavelUsina: model?.cepresponsavelusina,
    ufResponsavelUsina: model?.ufresponsavelusina,
    cidadeResponsavelUsina: model?.cidaderesponsavelusina,
    bairroResponsavelUsina: model?.bairroresponsavelusina,
    numeroResponsavelUsina: model?.numeroresponsavelusina,
    complementoResponsavelUsina: model?.complementoresponsavelusina,
    logradouroResponsavelUsina: model?.logradouroresponsavelusina,
    tipoPessoa: model?.tipopessoa,
    distribuidora: model?.distribuidora,
    tipoTarifa: model?.tipotarifa,
    porcentagemDescontoTarifa: model?.porcentagemdescontotarifa?
    formatValueStringInFloat(model?.porcentagemdescontotarifa): null,
    tarifaVigenteContrato: model?.tarifavigentecontrato?
    formatValueStringInFloat(model?.tarifavigentecontrato, 4): null,
    potencia: model?.potencia?
    formatValueStringInFloat(model?.potencia, 1): null,//1 casa
    medidaCorrente: model?.medidacorrente,
    performaceAlvo: model?.performacealvo?
    formatValueStringInFloat(model?.performacealvo): null,
    potenciaPorExtenso: model?.potenciaporextenso,
    custoFixoPorExtenso: model?.custofixoporextenso,
    vigenciaContratual: model?.vigenciacontratual,//max12 min120 ??
    dataLimiteConexao: model?.datalimiteconexao? 
    new Date(model?.datalimiteconexao): null,
    dataNascimento: model?.datanascimentoresponsavelusina? 
    new Date(model?.datanascimentoresponsavelusina): null,
    tipoConexao: model?.tipoconexao,
    statusContratoGerador: model?.statuscontratogerador === undefined? null: model?.statuscontratogerador,
    dataAssinaturaContrato: model?.dataassinaturacontrato? new Date(model.dataassinaturacontrato): null,
    uuidContrato: null,
    responsavelIndicacaos: (model?.responsavelIndicacaos ?? []).map((x:iResponsavelIndicacao) => {
      const { 
        percentualcomissao, 
        ...rest 
      } = { 
        ...x, 
        percentualComissao: 
        x.percentualcomissao? parseFloat(x.percentualcomissao): null
      };
      return rest;
    }),
    agenciaResponsavelIndicacao: model?.agenciaresponsavelindicacao,
    bancoResponsavelIndicacao: model?.bancoresponsavelindicacao,
    contaResponsavelIndicacao: model?.contaresponsavelindicacao,
    cnpjFavorecidoResponsavelIndicacao: model?.cnpjfavorecidoresponsavelindicacao,
    chavePixResponsavelIndicacao: model?.chavepixresponsavelindicacao,
    agenciaPagamentoGerador: model.agenciapagamentogerador,
    cnpjFavorecidoPagamentoGerador: model.cnpjfavorecidopagamentogerador,
    bancoPagamentoGerador: model.bancopagamentogerador,
    tipoChavePixPagamentoGerador: model.tipochavepixpagamentogerador,
    favorecidoPagamentoGerador: model.favorecidopagamentogerador,
    contaPagamentoGerador: model.contapagamentogerador,
    chavePixPagamentoGerador: model.chavepixpagamentogerador,
    favorecidoResponsavelIndicacao: model?.favorecidoresponsavelindicacao,
    tipoChavePixResponsavelIndicacao: model?.tipochavepixresponsavelindicacao,
    tarifa:model.dadospagamentos? (model?.dadospagamentos ?? [])
    .map((x:DadosPagamentos) => { 
      return {
        tipoConexao: x.tipoconexao,
        descontoTarifaPercentual: x.descontotarifapercentual?
        parseFloat(x.descontotarifapercentual.toString()): null,
        tarifaGerador: x.tarifagerador?
        parseFloat(x.tarifagerador.toString()): null,
        tarifaVigente: x.tarifavigente?
        parseFloat(x.tarifavigente.toString()): null,
        dataInicial: x.datainicial? 
        new Date(x.datainicial): null,
        dataFinal: x.datafinal?
        new Date(x.datafinal): null,
      }
     }): [],
    emailDadosAcesso: model?.email,
    celularDadosAcesso: model?.telefone,
    whatsappDadosAcesso: model?.whatsapp,
    whatsappCountryCode: model?.whatsappcountrycode,
  }
}

export const dataUpdateGeradorPessoaJuridica = (model:any):IFormUpdateGeradorPessoaJuridica => {
  return{
    nomeDadosAcesso: model?.razaosocialnome !== null? model?.razaosocialnome: undefined,
    razaoSocialNome: model?.razaosocialnome !== null? model?.razaosocialnome: undefined,
    nomeGerador: model?.razaosocialnome !== null? model?.razaosocialnome: undefined,
    cnpjcpf: model?.cpfcnpj !== null? model?.cpfcnpj: undefined,
    instalacao: model?.instalacao !== null? model?.instalacao: undefined,
    sigla: model?.sigla !== null? model?.sigla: undefined,
    cep: model?.cep !== null? model?.cep: undefined,
    uf: model?.uf !== null? model?.uf: undefined,
    cidade: model?.cidade !== null? model?.cidade: undefined,
    bairro: model?.bairro !== null? model?.bairro: undefined,
    logradouro: model?.logradouro !== null? model?.logradouro: undefined,
    numero: model?.numero !== null? model?.numero: undefined,
    complemento: model?.complemento !== null? model?.complemento: undefined,
    responsavelLegal: (!model.dadosResponsavelLegal || model.dadosResponsavelLegal.length === 0)?undefined: model.dadosResponsavelLegal.map((x: IResponsavelLegal) => {
        const { cpf, cep, uf, datanascimento, ...rest } = { ...x, CPF: x.cpf, countryCode: x.countrycode, CEP: x.cep, UF: x.uf, dataNascimento:x.datanascimento };
        return rest;
    }),
    nomeResponsavelComunicacao: model?.nomeresponsavelcomunicacao !== null? model?.nomeresponsavelcomunicacao: undefined,
    emailResponsavelComunicacao: model?.emailresponsavelcomunicacao !== null? model?.emailresponsavelcomunicacao: undefined,
    celularResponsavelComunicacao: model?.celularresponsavelcomunicacao !== null? model?.celularresponsavelcomunicacao: undefined,
    tipoPessoa: model?.tipopessoa,
    distribuidora: model?.distribuidora !== null? model.distribuidora: undefined,
    tipoTarifa: model?.tipotarifa,
    porcentagemDescontoTarifa: model?.porcentagemdescontotarifa !== null && 
    model?.porcentagemdescontotarifa !== undefined?
    formatValueStringInFloat(model?.porcentagemdescontotarifa): undefined,
    tarifaVigenteContrato: model?.tarifavigentecontrato !== null && 
    model?.tarifavigentecontrato !== undefined?
    formatValueStringInFloat(model?.tarifavigentecontrato, 2): undefined,
    potencia: model?.potencia !== null && model?.potencia !== undefined?
    formatValueStringInFloat(model?.potencia, 1): undefined,
    medidaCorrente: model?.medidacorrente !== null? model?.medidacorrente: undefined,
    performaceAlvo: model?.performacealvo !== null && 
    model?.performacealvo !== undefined?
    formatValueStringInFloat(model?.performacealvo): undefined,
    potenciaPorExtenso: model?.potenciaporextenso !== null? model?.potenciaporextenso: undefined,
    custoFixoPorExtenso: model?.custofixoporextenso !== null? model?.custofixoporextenso: undefined,
    vigenciaContratual: model?.vigenciacontratual !== null? model?.vigenciacontratual: undefined,//max12 min120 ??
    dataLimiteConexao: model?.datalimiteconexao && !isNaN(new Date(model?.datalimiteconexao as Date).getTime())? 
    new Date(model?.datalimiteconexao): undefined,
    tipoConexao: model?.tipoconexao,
    statusContratoGerador: model?.statuscontratogerador,
    dataAssinaturaContrato: model?.dataassinaturacontrato && !isNaN(new Date(model?.dataassinaturacontrato as Date).getTime())? 
    new Date(model?.dataassinaturacontrato): undefined,
    uuidContrato: model.uuidcontrato,
    responsavelIndicacaos:(!model?.responsavelIndicacaos || model?.responsavelIndicacaos?.length === 0)?undefined: model?.responsavelIndicacaos.map((x:iResponsavelIndicacao) => {
      const { 
        percentualcomissao, 
        ...rest 
      } = { 
        ...x, 
        percentualComissao: 
        x.percentualcomissao? parseFloat(x.percentualcomissao): undefined 
      };
      return rest;
    }),
    agenciaResponsavelIndicacao: model?.agenciaresponsavelindicacao !== null? model?.agenciaresponsavelindicacao: undefined,
    bancoResponsavelIndicacao: model?.bancoresponsavelindicacao !== null? model?.bancoresponsavelindicacao: undefined,
    contaResponsavelIndicacao: model?.contaresponsavelindicacao !== null? model?.contaresponsavelindicacao: undefined,
    cnpjFavorecidoResponsavelIndicacao: model?.cnpjfavorecidoresponsavelindicacao !== null? model?.cnpjfavorecidoresponsavelindicacao: undefined,
    chavePixResponsavelIndicacao: model?.chavepixresponsavelindicacao !== null? model?.chavepixresponsavelindicacao: undefined,
    agenciaPagamentoGerador: model.agenciapagamentogerador !== null? model?.agenciapagamentogerador: undefined,
    cnpjFavorecidoPagamentoGerador: model.cnpjfavorecidopagamentogerador !== null? model?.cnpjfavorecidopagamentogerador: undefined,
    bancoPagamentoGerador: model.bancopagamentogerador !== null? model?.bancopagamentogerador: undefined,
    tipoChavePixPagamentoGerador: model.tipochavepixpagamentogerador,
    favorecidoPagamentoGerador: model.favorecidopagamentogerador !== null? model?.favorecidopagamentogerador: undefined,
    contaPagamentoGerador: model.contapagamentogerador !== null? model?.contapagamentogerador: undefined,
    chavePixPagamentoGerador: model.chavepixpagamentogerador !== null? model?.chavepixpagamentogerador: undefined,
    favorecidoResponsavelIndicacao: model?.favorecidoresponsavelindicacao !== null? model?.favorecidoresponsavelindicacao: undefined,
    tipoChavePixResponsavelIndicacao: model?.tipochavepixresponsavelindicacao,
    tarifa: (!model?.dadospagamentos || model?.dadospagamentos?.length === 0)?undefined: model?.dadospagamentos?.map((x:DadosPagamentos) => { 
      return {
        id: x?.id,
        tipoConexao: x.tipoconexao,
        descontoTarifaPercentual: x.descontotarifapercentual?
        parseFloat(x.descontotarifapercentual.toString()): null,
        tarifaGerador: x.tarifagerador?
        parseFloat(x.tarifagerador.toString()): null,
        tarifaVigente: x.tarifavigente?
        parseFloat(x.tarifavigente.toString()): null,
        dataInicial: x.datainicial? 
        new Date(x.datainicial): null,
        dataFinal: x.datafinal?
        new Date(x.datafinal): null,
      }
     }),
    emailDadosAcesso: model?.email || undefined,
    celularDadosAcesso: model?.telefone || undefined,
    whatsappDadosAcesso: model?.whatsapp || undefined,
    whatsappCountryCode: model?.whatsappcountrycode || undefined,
  }
}

export const dataUpdateGeradorPessoaFisica = (model:any):IFormUpdateGeradorPessoaFisica => {
  return{
    nomeDadosAcesso: model?.nomeusina !== null? model?.nomeusina: undefined,
    nomeUsina: model?.nomeusina !== null? model?.nomeusina: undefined,
    nomeGerador: model?.nomeusina !== null? model?.nomeusina: undefined,
    cpf: model?.cpf !== null? model?.cpf: undefined,
    instalacao: model?.instalacao !== null? model?.instalacao: undefined,
    sigla: model?.sigla !== null? model?.sigla: undefined,
    cep: model?.cep !== null? model?.cep: undefined,
    uf: model?.uf !== null? model?.uf: undefined,
    cidade: model?.cidade !== null? model?.cidade: undefined,
    bairro: model?.bairro !== null? model?.bairro: undefined,
    logradouro: model?.logradouro !== null? model?.logradouro: undefined,
    numero: model?.numero !== null? model?.numero: undefined,
    complemento: model?.complemento !== null? model?.complemento: undefined,
    nomeResponsavelUsina: model?.nomeresponsavelusina ?? undefined,
    emailResponsavelUsina: model?.emailresponsavelusina !== null? model?.emailresponsavelusina: undefined,
    celularResponsavelUsina: model.celularresponsavelusina !== null? model?.celularresponsavelusina: undefined,
    countryCodeResponsavelUsina: model.countrycoderesponsavelusina !== null? model.countrycoderesponsavelusina: undefined,
    cpfResponsavelUsina: model.cpfresponsavelusina !== null? model?.cpfresponsavelusina: undefined,
    cepResponsavelUsina: model.cepresponsavelusina !== null? model?.cepresponsavelusina: undefined,
    ufResponsavelUsina: model.ufresponsavelusina !== null? model?.ufresponsavelusina: undefined,
    cidadeResponsavelUsina: model.cidaderesponsavelusina !== null? model?.cidaderesponsavelusina: undefined,
    bairroResponsavelUsina: model.bairroresponsavelusina !== null? model?.bairroresponsavelusina: undefined,
    numeroResponsavelUsina: model.numeroresponsavelusina !== null? model?.numeroresponsavelusina: undefined,
    complementoResponsavelUsina: model.complementoresponsavelusina !== null? model?.complementoresponsavelusina: undefined,
    logradouroResponsavelUsina: model.logradouroresponsavelusina !== null? model?.logradouroresponsavelusina: undefined,
    tipoPessoa: model?.tipopessoa,
    distribuidora: model?.distribuidora !== null? model.distribuidora: undefined,
    tipoTarifa: model?.tipotarifa,
    porcentagemDescontoTarifa: model?.porcentagemdescontotarifa !== null && 
    model?.porcentagemdescontotarifa !== undefined?
    formatValueStringInFloat(model?.porcentagemdescontotarifa): undefined,
    tarifaVigenteContrato: model?.tarifavigentecontrato !== null && 
    model?.tarifavigentecontrato !== undefined?
    formatValueStringInFloat(model?.tarifavigentecontrato, 2): undefined,
    potencia: model?.potencia !== null && model?.potencia !== undefined?
    formatValueStringInFloat(model?.potencia, 1): undefined,
    medidaCorrente: model?.medidacorrente !== null? model?.medidacorrente: undefined,
    performaceAlvo: model?.performacealvo !== null && 
    model?.performacealvo !== undefined?
    formatValueStringInFloat(model?.performacealvo): undefined,
    potenciaPorExtenso: model?.potenciaporextenso !== null? model?.potenciaporextenso: undefined,
    custoFixoPorExtenso: model?.custofixoporextenso !== null? model?.custofixoporextenso: undefined,
    vigenciaContratual: model?.vigenciacontratual !== null? model?.vigenciacontratual: undefined,
    dataLimiteConexao: model?.datalimiteconexao && !isNaN(new Date(model?.datalimiteconexao as Date).getTime())? 
    new Date(model?.datalimiteconexao): undefined,
    dataNascimento: model?.datanascimentoresponsavelusina && !isNaN(new Date(model?.datanascimentoresponsavelusina).getTime())? 
    new Date(model?.datanascimentoresponsavelusina): undefined,
    tipoConexao: model?.tipoconexao,
    statusContratoGerador: model?.statuscontratogerador,
    dataAssinaturaContrato: model?.dataassinaturacontrato && !isNaN(new Date(model?.dataassinaturacontrato).getTime())? new Date(model?.dataassinaturacontrato): undefined,
    uuidContrato: model.uuidcontrato,
    responsavelIndicacaos:(!model?.responsavelIndicacaos || model?.responsavelIndicacaos?.length === 0)?undefined: model?.responsavelIndicacaos.map((x:iResponsavelIndicacao) => {
      const { 
        percentualcomissao, 
        ...rest 
      } = { 
        ...x, 
        percentualComissao: 
        x.percentualcomissao? parseFloat(x.percentualcomissao): undefined
      };
      return rest;
    }),
    agenciaResponsavelIndicacao: model?.agenciaresponsavelindicacao !== null? model?.agenciaresponsavelindicacao: undefined,
    bancoResponsavelIndicacao: model?.bancoresponsavelindicacao !== null? model?.bancoresponsavelindicacao: undefined,
    contaResponsavelIndicacao: model?.contaresponsavelindicacao !== null? model?.contaresponsavelindicacao: undefined,
    cnpjFavorecidoResponsavelIndicacao: model?.cnpjfavorecidoresponsavelindicacao !== null? model?.cnpjfavorecidoresponsavelindicacao: undefined,
    chavePixResponsavelIndicacao: model?.chavepixresponsavelindicacao !== null? model?.chavepixresponsavelindicacao: undefined,
    agenciaPagamentoGerador: model?.agenciapagamentogerador !== null? model?.agenciapagamentogerador: undefined,
    cnpjFavorecidoPagamentoGerador: model?.cnpjfavorecidopagamentogerador !== null? model?.cnpjfavorecidopagamentogerador: undefined,
    bancoPagamentoGerador: model?.bancopagamentogerador !== null? model?.bancopagamentogerador: undefined,
    tipoChavePixPagamentoGerador: model?.tipochavepixpagamentogerador,
    favorecidoPagamentoGerador: model?.favorecidopagamentogerador !== null? model?.favorecidopagamentogerador: undefined,
    contaPagamentoGerador: model?.contapagamentogerador !== null? model?.contapagamentogerador: undefined,
    chavePixPagamentoGerador: model?.chavepixpagamentogerador !== null? model?.chavepixpagamentogerador: undefined,
    favorecidoResponsavelIndicacao: model?.favorecidoresponsavelindicacao !== null? model?.favorecidoresponsavelindicacao: undefined,
    tipoChavePixResponsavelIndicacao: model?.tipochavepixresponsavelindicacao,
    tarifa: (!model?.dadospagamentos || model?.dadospagamentos?.length === 0)?undefined: model?.dadospagamentos?.map((x:DadosPagamentos) => { 
      return {
        tipoConexao: x.tipoconexao,
        descontoTarifaPercentual: x.descontotarifapercentual?
        parseFloat(x.descontotarifapercentual.toString()): null,
        tarifaGerador: x.tarifagerador?
        parseFloat(x.tarifagerador.toString()): null,
        tarifaVigente: x.tarifavigente?
        parseFloat(x.tarifavigente.toString()): null,
        dataInicial: x.datainicial? 
        new Date(x.datainicial): null,
        dataFinal: x.datafinal?
        new Date(x.datafinal): null,
      }
     }),
    emailDadosAcesso: model?.email || undefined,
    celularDadosAcesso: model?.telefone || undefined,
    whatsappDadosAcesso: model?.whatsapp || undefined,
    whatsappCountryCode: model?.whatsappcountrycode || undefined,
  }
}

export const dataUpdateTarifaGerador = (usina:any) => {
  return{
    ...usina,
    tarifa: [
      {
        id: null,
        tipoConexao: usina.tipoConexao as EnumTipoConexao,
        descontoTarifaPercentual: usina.porcentagemDescontoTarifa,
        tarifaGerador: calcValues(
          usina?.tarifaVigenteContrato, 
          usina?.porcentagemDescontoTarifa,
          "%",
          4
        ),
        tarifaVigente:usina?.tarifaVigenteContrato,
        dataInicial: (usina?.dataAssinaturaContrato ?? "").toString()?.split("T")[0],
        dataFinal: null
      }
    ]
  }
}
