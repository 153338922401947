import { ICaptadorDadosContrato } from "../../models/Captador";
import { AbstractValidation, Validate } from "../../utils/AbstractValidation";
import { validarCNPJ } from "../../utils/Utils";

export class DadosContratoValidate extends AbstractValidation<ICaptadorDadosContrato> {
  getValidate(): Validate<ICaptadorDadosContrato>[] {
    return [
      {
        field: "razaoSocial",
        validate(
          value: ICaptadorDadosContrato["razaoSocial"],
          _model,
          addError: (message: string) => void
        ) {
          if (value.trim().length === 0) addError("O campo é obrigatorio");
        },
      },
      {
        field: "cnpj",
        validate(
          value: ICaptadorDadosContrato["cnpj"],
          _model,
          addError: (message: string) => void
        ) {
          if (value.trim().length === 0) addError("O campo é obrigatorio");
        },
      },
      {
        field: "cnpj",
        validate(
          value: ICaptadorDadosContrato["cnpj"],
          _model,
          addError: (message: string) => void
        ) {
          const validacao = validarCNPJ(value);
          if (!validacao.sucesso) addError(validacao.mensagem ?? "");
        },
      },
      {
        field: "cep",
        validate(
          value: ICaptadorDadosContrato["cep"],
          _model,
          addError: (message: string) => void
        ) {
          if (value.trim().length === 0) addError("O campo é obrigatorio");
        },
      },
      {
        field: "cep",
        validate(
          value: ICaptadorDadosContrato["cep"],
          _model,
          addError: (message: string) => void
        ) {
          if (value.trim().replace(/[^\d]+/g, "").length !== 8)
            addError("CEP tem que ter 8 dígitos");
        },
      },
      {
        field: "uf",
        validate(
          value: ICaptadorDadosContrato["uf"],
          _model,
          addError: (message: string) => void
        ) {
          if (value.trim().length === 0) addError("O campo é obrigatorio");
        },
      },
      {
        field: "cidade",
        validate(
          value: ICaptadorDadosContrato["cidade"],
          _model,
          addError: (message: string) => void
        ) {
          if (value.trim().length === 0) addError("O campo é obrigatorio");
        },
      },
      {
        field: "bairro",
        validate(
          value: ICaptadorDadosContrato["bairro"],
          _model,
          addError: (message: string) => void
        ) {
          if (value.trim().length === 0) addError("O campo é obrigatorio");
        },
      },
      {
        field: "logradouro",
        validate(
          value: ICaptadorDadosContrato["logradouro"],
          _model,
          addError: (message: string) => void
        ) {
          if (value.trim().length === 0) addError("O campo é obrigatorio");
        },
      },
      {
        field: "numero",
        validate(
          value: ICaptadorDadosContrato["numero"],
          _model,
          addError: (message: string) => void
        ) {
          if (value.trim().length === 0) addError("O campo é obrigatorio");
        },
      },
    ];
  }
}
