import * as C from "../../../Cliente/Cadastro/style";
import {MenuItem, TextField} from "@mui/material";
import RegisterCard from "../../../../../components/RegisterCard";
import * as D from "../../../../../styles/appComponents";
import React, {useEffect, useReducer, useState} from "react";
import * as services from "../../../../../services/api/ColaboradorService";
import {IBackofficeListItem} from "../../../../../models/Colaborador/Colaborador";

enum FormInformacoesContrato {
  LIMITE_KWH = "LIMITE_KWH",
  BACKOFFICEID = "BACKOFFICEID",
}

export interface IFormInformacoesContrato {
  limiteKwh: string;
  backofficeid: string;
}

type PropsInformacoesContrato = {
  onSave: (data: IFormInformacoesContrato) => void;
  onUpdate: (data: IFormInformacoesContrato) => void;
  onChange: (data: IFormInformacoesContrato) => void;
  data?: object | null;
};

export const InformacoesContrato = (props: PropsInformacoesContrato) => {
  const sendOnSave = (): void => props.onSave(state);
  const sendOnUpdate = (): void => props.onUpdate(state);
  const sendOnChange = (update: IFormInformacoesContrato): void =>
    props.onChange(update);
  const [backOffice, setbackOffice] = useState<IBackofficeListItem[] | []>([]);

  const setDataForm = (
    state: IFormInformacoesContrato,
    action: {
      type: FormInformacoesContrato;
      payload: string;
    }
  ): IFormInformacoesContrato => {
    const change = { ...state, [action.type.toLowerCase()]: action.payload };
    sendOnChange(change);
    return change;
  };

  const [state, dispatch] = useReducer(setDataForm, {
    limiteKwh: "",
    backofficeid: "",
  });

  const getBackoffices: () => Promise<void> = async (): Promise<void> => {
    try {
      const response = await services.GetBackoffices();

      if (Array.isArray(response.data)) {
        setbackOffice(response.data as IBackofficeListItem[]);
      } else {
        setbackOffice([]);
      }
    } catch (e: unknown) {
      console.error(e);
    }
  };

  const handleInputChange =
    (field: FormInformacoesContrato) =>
    (event: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
      dispatch({ type: field, payload: event.target.value as string });
    };

  useEffect(() => {
    getBackoffices();
  }, []);

  return (
    <RegisterCard title="Informações do contrato">
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Limite de kWh"
          required
          fullWidth
          onChange={handleInputChange(FormInformacoesContrato.LIMITE_KWH)}
        />
        <C.ReportsSelect
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          defaultValue={""}
          onChange={(e) =>
            dispatch({
              type: FormInformacoesContrato.BACKOFFICEID,
              payload: e.target.value as string,
            })
          }
        >
          <MenuItem value="">
            <em>backoffice</em>
          </MenuItem>
          {backOffice.map((item: IBackofficeListItem) => {
            return <MenuItem value={item.id}>{item.nome}</MenuItem>;
          })}
        </C.ReportsSelect>
      </C.FWStack>

      {props.data !== null && props.data !== undefined ? (
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow" onClick={sendOnUpdate}>
            Editar
          </D.ContainedButton>
          <D.ContainedButton onClick={sendOnSave}>Salvar</D.ContainedButton>
        </C.FWStack>
      ) : null}
    </RegisterCard>
  );
};
