import * as C from "../../pages/private/Associados/EditarAssociado/style";
import { TextField } from "@mui/material";
import { FC } from "react";
import { IUpdateResponsavelComunicacao } from "../../models/ResponsavelComunicacao";
import MaskDefaultInput from "../MasDefaultinput/MaskDefaultInput";
import { ICelularInputReturn } from "../../models/Celular";
import { UseValidateHook } from "../../hooks/useValidate";

type PropsReponsavelComunicacao = {
  responsavel: IUpdateResponsavelComunicacao;
  setResponsavel: (novoResponsavel: IUpdateResponsavelComunicacao) => void;
  disabled: boolean;
  validate: UseValidateHook<IUpdateResponsavelComunicacao>;
};

const ResponsavelComunicacaoInput: FC<PropsReponsavelComunicacao> = ({
  responsavel,
  setResponsavel,
  disabled,
  validate,
}) => {
  return (
    <>
      <C.TitleArea>
        <h2>Responsável pela comunicação</h2>
      </C.TitleArea>
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          required
          label="Nome"
          fullWidth
          onChange={(e) =>
            setResponsavel({ ...responsavel, nome: e.target.value })
          }
          value={responsavel.nome}
          disabled={disabled}
          {...validate.addValidateCampo("nome", responsavel)}
        />
        <TextField
          label="Email"
          fullWidth
          onChange={(e) =>
            setResponsavel({ ...responsavel, email: e.target.value })
          }
          value={responsavel.email}
          disabled={disabled}
          {...validate.addValidateCampo("email", responsavel)}
        />
        <MaskDefaultInput
          label="Celular"
          type="CELULAR"
          onChange={(e) => {
            const celular = e as ICelularInputReturn;
            setResponsavel({
              ...responsavel,
              celular: celular.value,
              countryCode: celular.zipCode,
            });
          }}
          value={responsavel.celular}
          valueCountryCode={responsavel.countryCode}
          disabled={disabled}
          {...validate.addValidateCampo("celular", responsavel)}
        />
      </C.FWStack>
    </>
  );
};

export default ResponsavelComunicacaoInput;
