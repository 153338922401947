export const styleError = {
    "& .MuiOutlinedInput-root": {
      color: "#000",
      fontFamily: "Arial",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "#FF0000 solid 2px",
      },
    },
    "& .MuiInputLabel-outlined": {
      color: "#FF0000"
    },
  };