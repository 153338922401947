import RegisterCard from "../../../../../components/RegisterCard";
import * as C from "../../../Cliente/Cadastro/style";
import * as D from "../../../../../styles/appComponents";
import { TextField } from "@mui/material";
import React, { useReducer } from "react";

enum FormDadosCooperativa {
  NOME = "NOME",
  CNPJ = "CNPJ",
  PRESIDENTE = "PRESIDENTE",
  CPFPRESIDENTE = "CPFPRESIDENTE",
  DIRETORADMNISTRATIVO = "DIRETORADMNISTRATIVO",
  CPFDIRETORADMINISTRATIVO = "CPFDIRETORADMINISTRATIVO",
  DIRETORTECNICO = "DIRETORTECNICO",
  CPFDIRETORTECNICO = "CPFDIRETORTECNICO",
  DATAMANDATOINICIO = "DATAMANDATOINICIO",
  DATAMANDATOFIM = "DATAMANDATOFIM",
}

export interface IFormDadosCooperativa {
  nome: string;
  cnpj: string;
  presidente: string;
  cpfpresidente: string;
  diretoradministrativo: string;
  cpfdiretoradministrativo: string;
  diretortecnico: string;
  cpfdiretortecnico: string;
  datamandatoinicio: Date;
  datamandatofim: Date;
}

type PropsDadosCooperativa = {
  onSave: (data: IFormDadosCooperativa) => void;
  onUpdate: (data: IFormDadosCooperativa) => void;
  onChange: (data: IFormDadosCooperativa) => void;
  data?: object | null;
};

export const DadosCooperativa = (props: PropsDadosCooperativa) => {
  const sendOnSave = (): void => props.onSave(state);
  const sendOnUpdate = (): void => props.onUpdate(state);
  const sendOnChange = (update: IFormDadosCooperativa): void =>
    props.onChange(update);

  const setDataForm = (
    state: IFormDadosCooperativa,
    action: {
      type: FormDadosCooperativa;
      payload: string;
    }
  ): IFormDadosCooperativa => {
    const change = { ...state, [action.type.toLowerCase()]: action.payload };
    sendOnChange(change);
    return change;
  };

  const [state, dispatch] = useReducer(setDataForm, {
    nome: "",
    cnpj: "",
    presidente: "",
    cpfpresidente: "",
    diretoradministrativo: "",
    cpfdiretoradministrativo: "",
    diretortecnico: "",
    cpfdiretortecnico: "",
    datamandatoinicio: new Date(),
    datamandatofim: new Date(),
  });

  const handleInputChange =
    (field: FormDadosCooperativa) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: field, payload: event.target.value });
    };

  return (
    <RegisterCard title="Dados da cooperativa">
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Nome da cooperativa"
          required
          fullWidth
          onChange={handleInputChange(FormDadosCooperativa.NOME)}
        />
        <TextField
          label="CNPJ da cooperativa"
          required
          fullWidth
          onChange={handleInputChange(FormDadosCooperativa.CNPJ)}
        />
        <TextField
          label="Presidente da cooperativa"
          required
          fullWidth
          onChange={handleInputChange(FormDadosCooperativa.PRESIDENTE)}
        />
        <TextField
          label="CPF da cooperativa"
          required
          fullWidth
          onChange={handleInputChange(FormDadosCooperativa.CPFPRESIDENTE)}
        />
      </C.FWStack>

      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Diretor administrativo"
          required
          fullWidth
          onChange={handleInputChange(
            FormDadosCooperativa.DIRETORADMNISTRATIVO
          )}
        />
        <TextField
          label="CNPJ do diretor administrativo"
          required
          fullWidth
          onChange={handleInputChange(
            FormDadosCooperativa.CPFDIRETORADMINISTRATIVO
          )}
        />
        <TextField
          label="Diretor técnico"
          required
          fullWidth
          onChange={handleInputChange(FormDadosCooperativa.DIRETORTECNICO)}
        />
        <TextField
          label="CPF diretor técnico"
          required
          fullWidth
          onChange={handleInputChange(FormDadosCooperativa.CPFDIRETORTECNICO)}
        />
        <D.DateTextfield
          label="Data mandato inicial"
          required
          fullWidth
          type="date"
          onChange={handleInputChange(FormDadosCooperativa.DATAMANDATOINICIO)}
        />
        <D.DateTextfield
          label="Data mandato fim"
          required
          fullWidth
          type="date"
          onChange={handleInputChange(FormDadosCooperativa.DATAMANDATOFIM)}
        />
      </C.FWStack>

      {props.data !== null && props.data !== undefined ? (
        <C.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton $color="yellow" onClick={sendOnUpdate}>
            Editar
          </D.ContainedButton>
          <D.ContainedButton onClick={sendOnSave}>Salvar</D.ContainedButton>
        </C.FWStack>
      ) : null}
    </RegisterCard>
  );
};
