import { AxiosResponse } from "axios";
import {
  IFormCreateGeradorPessoaFisica,
  IFormCreateGeradorPessoaJuridica,
} from "../../models/Gerador/Cadastro/Gerador";
import { GeradorResponse, UsinaResponse } from "../../models/Gerador/GeradorResponse";
import { StatusUsina } from "../../models/Gerador/StatusContrato";
import { IPaginatedList } from "../../models/PaginatedList";
import http from "../common/http-common";
import {
  IUsinaListItem,
  UsinaTipoPessoaFisica,
  UsinaTipoPessoaJuridica,
} from "../../models/Gerador/Usina";
import { IBuscaTodasUsinasPorNome } from "../../models/TarifaFioB";
import { GDContratoUsinaResponse } from "../../models/Gerador/GDContratoUsina";
import { EnumStatusContratoGerador } from "../../enums/Gerador/EnumStatusContratoGerador.enum";
import {IDashboardExtratoPorUsina, IExtratoPorUsina} from "../../pages/private/ExtratoPorUsina";
import { UsinaReport } from "../../models/Gerador/UsinaReport";
import { SupportWhatsappBody } from "../../models/Gerador/SupportWhatsappBody";

export const GetAll = (
  id: number | null = null,
  instalacao: string | null = null,
  name: string | null = null,
  date: string | null = null,
  pageSize: number | null = null,
  pageNumber: number | null = null,
  search: string | null = null,
  isNotSendClickSign: boolean | null = null,
  statusContratoGerador: EnumStatusContratoGerador | null | undefined,
  geradorId: string | null
) => {
  let url = "/Gerador";

  const params = [];
  const formData = new FormData();

  if (id) params.push(`Id=${id}`);
  if (instalacao) params.push(`Instalacao=${instalacao}`);
  if (name) params.push(`Name=${name}`);
  if (date) params.push(`Date=${date}`);
  if (pageSize) params.push(`PageSize=${pageSize}`);
  if (pageNumber) params.push(`PageNumber=${pageNumber}`);
  if (search) params.push(`Search=${search}`);
  if (isNotSendClickSign !== null)
    params.push(`isNotSendClickSign=${isNotSendClickSign}`);
  if (statusContratoGerador !== null) params.push(`StatusContratoGerador=${statusContratoGerador}`);
  if (geradorId) params.push(`GeradorId=${geradorId}`);

  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  return http.get<IPaginatedList<UsinaResponse>>(url);
};

export const GetOnePessaoJuridica = (id: string | null) => {
  return http.get<UsinaTipoPessoaJuridica>(
    `/Gerador/UsinaJuridicaById?id=${id}`
  );
};

export const GetOneGerador = (geradorId: string | null) => {
  return http.get<GeradorResponse | null>(
    `/Gerador/GeradorById/${geradorId}`
  );
};

export const SendWhatsappSupport = (body:SupportWhatsappBody) => {
  return http.post<boolean>(
    `/Gerador/EnviarSuporteWhatsapp`,body
  );
};

export const GetOnePessaoFisica = (id: string | null) => {
  return http.get<UsinaTipoPessoaFisica>(`/Gerador/UsinaFisicaById?id=${id}`);
};

export const GetStatusUsina = () => {
  return http.get<StatusUsina[]>(`/Gerador/GetUsinaStatus`);
};

export const GetUsinasListItem = () => {
  return http.get<IUsinaListItem[]>(`/Gerador/BuscarTodasUsinasPorNome`);
};

export const CreateGeradorPessoaJuridica = (
  createGerador: IFormCreateGeradorPessoaJuridica
) => {
  return http.post<IFormCreateGeradorPessoaJuridica>(
    "/Gerador/UsinaJuridica",
    createGerador
  );
};

export const CreateGeradorPessoaFisica = (
  createGerador: IFormCreateGeradorPessoaFisica
) => {
  return http.post<IFormCreateGeradorPessoaFisica>(
    "/Gerador/UsinaFisica",
    createGerador
  );
};

export const UpdateGeradorPessoaJuridica = (
  updateGerador: UsinaTipoPessoaJuridica | undefined
) => {
  return http.put<UsinaTipoPessoaJuridica>(
    "/Gerador/UpdateUsinaJuridica",
    updateGerador
  );
};

export const UpdateGeradorPessoaFisica = (
  updateGerador: UsinaTipoPessoaFisica | undefined
) => {
  return http.put<UsinaTipoPessoaFisica>(
    "/Gerador/UpdateUsinaFisica",
    updateGerador
  );
};

export const GerarRelatorio = (data:UsinaReport) => {
    let url = "/Gerador/BuscaRelatorioUsinas";

  const params = [];

  if(data.statusContratoGerador !== null)
    params.push(`StatusContratoGerador=${data?.statusContratoGerador}`)

  if(data.gerador)
    params.push(`Gerador=${data?.gerador}`)

  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  return http.get<File>(`${url}`, { responseType:"blob" });
};

export const GenerateContrato = (id: string) => {
  return http.post<void>(`/Gerador/EnviaContratoParaAssinaturaDigital/${id}`);
};

export const GetGDContratoUsina = (id: string) => {
  return http.get<GDContratoUsinaResponse>(`/Gerador/GDContrato/GetById/${id}`);
};

export const GetTodasUsinas: () => Promise<
  AxiosResponse<any, IBuscaTodasUsinasPorNome>
> = (): Promise<AxiosResponse<any, IBuscaTodasUsinasPorNome>> => {
  return http.get(`/Gerador/BuscarTodasUsinasPorNome`);
};