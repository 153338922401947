import { Autocomplete, InputAdornment, MenuItem, Modal, TextField } from "@mui/material";
import SkeletonLoadOrdemPagamento from "../../../../../../components/SkeletonLoads/OrdemPagamento";
import CloseIcon from "@mui/icons-material/Close";
import * as C from "../../style";
import * as D from "../../../../../../styles/appComponents";
import RegisterCard from "../../../../../../components/RegisterCard";
import { MonthYearCalendar } from "../../../Components/MonthYearCalendar";
import { IBuscaTodasUsinasPorNome, ITarifaFioB } from "../../../../../../models/TarifaFioB";
import { MaskCurrencyInput } from "../../../../../../components/MakCurrencyInput";
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { toastMessage } from "../../../../../../utils/toastMessage";
import { AxiosResponse } from "axios";
import { INovaOrdem, IOrdemPagamento } from "../../../../../../models/OrdemPagamento";
import { EnumTipoPessoa } from "../../../../../../enums/Gerador/EnumTipoPessoa.enum";
import { GetOnePessaoFisica, GetOnePessaoJuridica, GetTodasUsinas } from "../../../../../../services/api/GeradorService";
import { useAuth } from "../../../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { GetLastDataReferenciaOrdemPagamentoByUsinaId, GetOrdemPagamento, SetOrdemPagamento, updateOrdemPagamento } from "../../../../../../services/api/OrdemPagamentoService";
import { IContaOrdemPagamento } from "../../../../../../models/Conta";
import { getOrdemPagamentoByDateAndTipoConexao } from "../../../../../../services/api/TarifaFioBService";
import dayjs, { Dayjs } from "dayjs";
import { EnumTipoConexao } from "../../../../../../enums/Gerador/EnumTipoConexao.enum";
import { UsinaTipoPessoaFisica, UsinaTipoPessoaJuridica } from "../../../../../../models/Gerador/Usina";
import { calcValues, formatValueFloatInString, formatValues } from "../../../../../../utils/moneyFormatter";
import { EnumStatusOrdemPagamento } from "../../../../../../enums/EnumStatusOrdemPagamento";
import { NovaOrdemEnum } from "../../../../../../enums/EnumOrdemPagamento";
import { addCalendarDays } from "../../../../../../utils/formDataTransform";
import { handleStyleFieldError } from "../../../../../../utils/verifyFieldError";
import { ErrorMessage } from "../../../../../../utils/AbstractValidation";
import { EnumTipoTarifa } from "../../../../../../enums/Gerador/EnumTipoTarifa.enum";
import { OrdemPagamentoValidate } from "../../Validations/OrdemPagamentoValidate";
import { StatusDashboard } from "../../../../../../models/StatusDashboard";
import MaskDefaultInput from "../../../../../../components/MasDefaultinput/MaskDefaultInput";

interface NewOrdemModalProps {
    ordemId: string | null;
    openModal: boolean;
    onCloseModal: () => void;
    dataForm: INovaOrdem;
    setDataForm: React.Dispatch<React.SetStateAction<INovaOrdem>>;
    dashboard: StatusDashboard<EnumStatusOrdemPagamento>[];
    ordemPagamento: IOrdemPagamento | null | undefined;
}

export const NewOrdemModal: FC<NewOrdemModalProps> = (
    {
        ordemId,
        openModal,
        onCloseModal,
        dataForm,
        setDataForm,
        ordemPagamento
    }
) => {
    //region Variáveis
    const navigate = useNavigate();
    const [usinas, setUsinas] = useState<IBuscaTodasUsinasPorNome[]>([]);
    const [loading, setLoading]: (boolean | Dispatch<SetStateAction<boolean>>)[] = useState<boolean>(true);
    const [fieldErros, setFieldErros] = useState<ErrorMessage[]>([]);
    const [ordemPagamentoUpdated, setOrdemPagamentoUpdated] = useState<boolean>(true)
    const auth = useAuth();

    const geradorAccess: string | null = useMemo(() => auth?.user?.idGerador || null, [auth])

    //endregion

    //region Services
    const getUsinas: () => Promise<void> = async (): Promise<void> => {
        setLoading(true);

        try {
            const response: AxiosResponse<any, IBuscaTodasUsinasPorNome> = await GetTodasUsinas();
            setUsinas(response.data);
            setLoading(false);

        } catch (e: unknown) {
            toastMessage("error", "Erro ao listar as usinas");
            setLoading(false);
        }
    }

    const getUsina: (id: string) => Promise<void> = async (id: string): Promise<void> => {
        if (!id) return;

        try {

            const usina: IBuscaTodasUsinasPorNome[] = usinas.filter((value: IBuscaTodasUsinasPorNome) => value.id === id);

            if (!Object.values(EnumTipoPessoa).includes(usina[0].tipoUsina)) {
                toastMessage("error", "Erro ao listar a usina selecionada");
                return;
            }

            const guidGerador: string = usina[0]?.id;

            const { data } = usina[0].tipoUsina as EnumTipoPessoa === EnumTipoPessoa.pessoaFisica
                ? await GetOnePessaoFisica(guidGerador)
                : await GetOnePessaoJuridica(guidGerador);

            loadLastDataReferenciaOrdemPagamento(id, (dataForm?.dataReferencia || "")).then((x) => {
                setDataFromUsina(data, x?.saldo);
            })

        } catch (e: unknown) {
            console.error("Erro ao listar usinas por ID:", e)
        }
    }

    const loadLastDataReferenciaOrdemPagamento = async (usinaId:string, dataReferencia:string): Promise<IContaOrdemPagamento | undefined> => {
        try {
            const {data} = await GetLastDataReferenciaOrdemPagamentoByUsinaId(usinaId, dataReferencia);
            return data;
            
        } catch (error) {
            console.error("Erro ao listar ordem de pagamento por Data e referência", error);
            
        }
    }

    const setOrdemPagamento: () => Promise<void> = async (): Promise<void> => {

        try {
            const resultValidation = new OrdemPagamentoValidate();
            const errorMessages = resultValidation.errorMessage(dataForm)

            if (errorMessages) {
                setFieldErros(errorMessages)

                errorMessages.forEach((error: ErrorMessage) => {
                    toastMessage("warning", error?.message)
                })

                return;
            }

            const response: AxiosResponse<INovaOrdem, any> = !ordemId ?
                await SetOrdemPagamento(dataForm) :
                await updateOrdemPagamento(dataForm);

            if (response) {
                toastMessage("success", "Ordem de pagamento criada com sucesso");
                onCloseModal();
                setFieldErros([]);
            }
        } catch (e: unknown) {
            toastMessage("error", "Erro ao criar a ordem de pagamento");
        }
    }

    const liberarVisualização = async (): Promise<void> => {

        try {

            const response: AxiosResponse<INovaOrdem, any> = await updateOrdemPagamento({ ...dataForm, id: ordemId, visualizacao: true });
            setOrdemPagamentoUpdated((x) => !x);

            if (response)
                toastMessage("success", "Visualização liberada");

        } catch (error) {
            console.error("Erro ao atualizar ordem de pagamento", error);
            toastMessage("warning", "Erro ao atualizar ordem de pagamento");
            setOrdemPagamentoUpdated((x) => !x);
        }
    };

    const getTarifaFioB: (date: string, tipoConexao: number) => Promise<void> = async (date: string, tipoConexao: number) => {
        try {
            let year: number = new Date(date).getFullYear();

            const response: AxiosResponse<ITarifaFioB, any> = await getOrdemPagamentoByDateAndTipoConexao(year, tipoConexao);

            setDataForm((x) => { return { ...x, tarifaFioB: response.data?.aliquota ?? 0 } });

        } catch (e: any) {
            setDataForm((x) => { return { ...x, tarifaFioB: 0 } });

        }
    }

    const getOrdemPagamento: (id: string) => Promise<void> = async (id: string) => {
        try {
            const response: AxiosResponse<IContaOrdemPagamento, any> = await GetOrdemPagamento(id);

            if (!response?.data) {
                throw new Error("Erro ao buscar a ordem de pagamento");
            }
            const ordemPagamento: IContaOrdemPagamento = response.data;

            setDataInFields(ordemPagamento);
            
        } catch (e: unknown) {
            console.error(e)
            toastMessage("error", "Erro ao tentarmos buscar a ordem de pagamento");
        }
    }

    const handleFecharOrdem = async () => {

        if(dataForm.status === EnumStatusOrdemPagamento.AguardandoPagamento)
            return toastMessage("warning","a ordem não pode ser fechada!");

        else if(dataForm?.saldoDeQuitacao && dataForm?.saldoDeQuitacao > 0)
            return toastMessage("warning","a ordem não pode ser fechada!");

        try {
            await updateOrdemPagamento({...dataForm, status: EnumStatusOrdemPagamento.fechado});
            setOrdemPagamentoUpdated((x) => !x);
            
        } catch (error) {
            console.error("Erro ao fechar a ordem");
            toastMessage("warning","Erro ao fechar a ordem");
            setOrdemPagamentoUpdated((x) => !x);
            
        }
    }

    const handleAbrirOrdem = async () => {

        try {
            await updateOrdemPagamento({...dataForm, status: EnumStatusOrdemPagamento.Quitada});
            setOrdemPagamentoUpdated((x) => !x);
            
        } catch (error) {
            console.error("Erro ao fechar a ordem");
            toastMessage("warning","Erro ao fechar a ordem");
            setOrdemPagamentoUpdated((x) => !x);
            
        }
    }

    const handleExcluir: () => Promise<void> = async () => {
        navigate({
            pathname: `/Colaborador/OrdemPagamento`,
            search: `?update=${true}`,
        });

        const obj = { ...dataForm, active: false };

        try {
            const response: AxiosResponse<INovaOrdem, any> = await updateOrdemPagamento(obj);

            if (response.data) {
                toastMessage("success", "Ordem de pagamento excluida com sucesso");
                onCloseModal();
            }
        } catch (e: unknown) {
            toastMessage("error", "Erro ao criar a ordem de pagamento");
        }
    }
    //endregion

    //region UI

    const setDataInFields: (ordemPagamento: IContaOrdemPagamento) => void = (ordemPagamento: IContaOrdemPagamento) => {
        setDataForm({
            id: ordemPagamento.id,
            dataReferencia: ordemPagamento.dataReferencia ? ordemPagamento.dataReferencia.toString().split('T')[0] : null,
            instalacao: ordemPagamento.instalacao,
            sigla: ordemPagamento.sigla,
            descontoTarifa: ordemPagamento.descontoTarifa ?? 0,
            energiaInjetada: ordemPagamento.energiaInjetada,
            energiaConsumida: ordemPagamento.energiaConsumida ?? 0,
            assunto: ordemPagamento.contas?.[0]?.assunto || null,
            descricao: ordemPagamento.contas?.[0]?.descricao || null,
            tarifaDistribuidora: ordemPagamento.tarifaDistribuidora ?? 0,
            tipoConexao: ordemPagamento.tipoConexao as EnumTipoConexao ?? null,
            dataLimiteQuitacao: ordemPagamento.dataLimiteQuitacao,
            status: ordemPagamento.status,
            comprovante: ordemPagamento.comprovante,
            usinaId: ordemPagamento.usina?.id || null,
            tarifaBase: ordemPagamento.tarifaBase ?? 0,
            valorBase: ordemPagamento.valorBase ?? 0,
            valorCredito: ordemPagamento.valorCredito,
            tarifaFioB: null,
            saldo: ordemPagamento?.saldo,
            saldoTotal: ordemPagamento?.saldoTotal,
            saldoAnterior: ordemPagamento?.saldoAnterior || 0,
            saldoDeQuitacao: ordemPagamento.saldoDeQuitacao || 0,
            active: ordemPagamento.active,
            visualizacao: ordemPagamento.visualizacao,
        });
    }

    const setDataFromUsina: (data: UsinaTipoPessoaFisica | UsinaTipoPessoaJuridica, saldo: number | null | undefined) => void = (data: UsinaTipoPessoaFisica | UsinaTipoPessoaJuridica, saldo: number | null | undefined): void => {
        setDataForm((x) => {
            return {
                ...x,
                tarifaBase: data.tipoTarifa,
                valorBase: data.tarifaGerador || 0,
                tarifaDistribuidora: data.tarifaVigenteContrato || 0,
                descontoTarifa: formatValueFloatInString(data?.porcentagemDescontoTarifa) || 0,
                tipoConexao: data.tipoConexao as EnumTipoConexao,
                instalacao: data.instalacao || "",
                sigla: data.sigla || "",
                saldoAnterior: saldo || 0
            }

        });
    }

    const handleDateChange: (date: (Dayjs | null)) => void = (date: Dayjs | null): void => {
        setDataForm((state: INovaOrdem) => {
            if (date && date.isValid()) {
                return { ...state, dataReferencia: date.toISOString() };
            }
            return state;
        })
    }

    const handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        const name: string = event.target.name;
        const value: string = event.target.value;

        setDataForm((state: INovaOrdem) => {
            return {
                ...state,
                [name]: value,
            };
        });
    }

    const handleCalcSaldoTotal: (
        energiaInjetada: number | undefined | null, 
        energiaConsumida: number | undefined | null, 
        saldo: number | undefined | null, 
        saldoAnterior: number | undefined | null
    ) => void = (
        energiaInjetada: number | undefined | null, 
        energiaConsumida: number | undefined | null, 
        saldo: number | undefined | null, 
        saldoAnterior: number | undefined | null
    ): void => {
        setDataForm((state: INovaOrdem) => {
            return {
                ...state,
                saldoTotal: (energiaInjetada || 0) - (energiaConsumida || 0) - (saldo || 0) - (saldoAnterior || 0),
            };
        })

    }

    const handleCalcValorCredito: (totalSaldo: number, valorBase: number) => void = (totalSaldo: number, valorBase: number): void => {
        setDataForm((state: INovaOrdem) => {
            return {
                ...state,
                valorCredito: calcValues(totalSaldo || 0, valorBase, "*", 2),
            };
        })
    }

    useEffect(() => {
        if (dataForm.dataReferencia != null && dataForm.tipoConexao != null) {
            getTarifaFioB(dataForm.dataReferencia, dataForm.tipoConexao);
        }
    }, [dataForm.tipoConexao, dataForm.dataReferencia]);

    useEffect(() => {
        handleCalcSaldoTotal(dataForm.energiaInjetada as number, dataForm.energiaConsumida as number, dataForm.saldo as number, dataForm?.saldoAnterior as number);

    }, [dataForm?.energiaInjetada, dataForm?.energiaConsumida, dataForm?.saldo, dataForm?.usinaId]);

    useEffect(() => {
        handleCalcValorCredito(dataForm.saldoTotal as number, dataForm.valorBase as number);
    }, [dataForm?.saldoTotal, dataForm?.usinaId]);

    useEffect(() => {
        if (dataForm?.usinaId && dataForm?.dataReferencia){
            getUsina(dataForm?.usinaId);
            loadLastDataReferenciaOrdemPagamento(dataForm?.usinaId, dataForm?.dataReferencia)
        }

    }, [dataForm.usinaId, dataForm?.dataReferencia]);

    useEffect(() => {
        getUsinas()

    }, []);

    useEffect(() => {
        if (ordemId)
            getOrdemPagamento(ordemId);

    }, [ordemId, dataForm?.dataReferencia, ordemPagamentoUpdated])

    useEffect(() => {
        handleInputChange(
            {
                target: {
                    name: NovaOrdemEnum.DATALIMITEQUITACAO,
                    value: addCalendarDays(120, dataForm?.dataReferencia)
                }
            } as any
        );

    }, [dataForm?.dataReferencia])

    //endregion

    return (
        <>
            <Modal
                open={openModal}
                onClose={onCloseModal}
                aria-labelledby="modal-details"
                aria-describedby="modal-details"
            >
                <C.ModalContainer>

                    <C.ModalArea height="95%" width="90%">
                        <C.ModalCard>
                            <C.ModalHeader>
                                <C.CloseButton
                                    aria-label="close"
                                    onClick={onCloseModal}
                                >
                                    <CloseIcon />
                                </C.CloseButton>
                            </C.ModalHeader>
                            {
                                loading ? (<SkeletonLoadOrdemPagamento />) : (
                                    <>
                                        <RegisterCard title={ordemId ? "Editar ordem" : "Inserir ordem"}>
                                            {ordemId &&
                                                <div>ID - {ordemPagamento?.idReferencial}</div>
                                            }
                                            <C.FWStack direction={"row"} spacing={2}>
                                                <MonthYearCalendar
                                                    readOnly={!!geradorAccess || dataForm?.status == EnumStatusOrdemPagamento.fechado}
                                                    handleDateChange={handleDateChange}
                                                    dateSelected={dayjs(dataForm?.dataReferencia)}
                                                />
                                                <Autocomplete
                                                    onChange={(e, newValue: IBuscaTodasUsinasPorNome | null) => handleInputChange({
                                                        target: {
                                                            name: NovaOrdemEnum.USINA,
                                                            value: newValue?.id as string ?? "",
                                                        }
                                                    } as unknown as React.ChangeEvent<HTMLInputElement>)}
                                                    options={usinas}
                                                    fullWidth
                                                    value={
                                                        usinas.find(
                                                            (x: IBuscaTodasUsinasPorNome) => x.id === dataForm?.usinaId
                                                        ) ?? null
                                                    }
                                                    disabled={!!geradorAccess || dataForm?.status == EnumStatusOrdemPagamento.fechado}
                                                    noOptionsText="Nenhuma usina encontrada"
                                                    getOptionLabel={(option) => option.nome}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder="Selecione uma usina"
                                                            inputProps={{
                                                                readOnly:!!geradorAccess,
                                                                ...params.inputProps,
                                                                "aria-label": "Without label",
                                                            }}
                                                        />
                                                    )}
                                                    sx={handleStyleFieldError(NovaOrdemEnum.USINA, fieldErros, { width: "130%" })}
                                                    defaultValue={null}
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.id === value.id
                                                    }
                                                />
                                                <MaskCurrencyInput
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                Kwh
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    sx={{ width: "60%" }}
                                                    readonly={!!geradorAccess || dataForm?.status == EnumStatusOrdemPagamento.fechado}
                                                    disabled={!dataForm?.usinaId}
                                                    label="Energia Injetada"
                                                    name={NovaOrdemEnum.ENERGIAINJETADA}
                                                    onValueChange={(newValue, name, values) => {
                                                        handleInputChange({ target: { name, value: newValue } } as unknown as ChangeEvent<HTMLInputElement>);
                                                    }}
                                                    value={dataForm?.energiaInjetada ?? ""}
                                                    placeholder="00,00"
                                                    defaultValue={0}
                                                    decimalsLimit={2}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                <MaskCurrencyInput
                                                    sx={{ width: "50%" }}
                                                    readonly={!!geradorAccess || dataForm?.status == EnumStatusOrdemPagamento.fechado}
                                                    label="Energia Consumida"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                Kwh
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    name={NovaOrdemEnum.ENERGIACONSUMIDA}
                                                    onValueChange={(newValue, name, values) => {
                                                        handleInputChange({ target: { name, value: newValue } } as unknown as ChangeEvent<HTMLInputElement>);
                                                    }}
                                                    value={dataForm?.energiaConsumida ?? ""}
                                                    disabled={!dataForm?.usinaId}
                                                    placeholder="00,00"
                                                    defaultValue={0}
                                                    decimalsLimit={2}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                <MaskCurrencyInput
                                                    sx={{ width: "50%" }}
                                                    disabled={!!geradorAccess || dataForm?.status == EnumStatusOrdemPagamento.fechado || !dataForm?.usinaId}
                                                    label="Saldo de geração"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                Kwh
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    name={NovaOrdemEnum.SALDO}
                                                    onValueChange={(newValue, name, values) => {
                                                        handleInputChange({ target: { name, value: newValue } } as unknown as ChangeEvent<HTMLInputElement>);
                                                    }}
                                                    value={dataForm?.saldo ?? ""}
                                                    placeholder="00,00"
                                                    defaultValue={0}
                                                    decimalsLimit={2}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </C.FWStack>
                                            <C.FWStack direction={"row"} spacing={2}>
                                                <MaskCurrencyInput
                                                    sx={{ width: "100%" }}
                                                    disabled={!!geradorAccess || dataForm?.status == EnumStatusOrdemPagamento.fechado}
                                                    label="Saldo de geração do mês anterior"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                Kwh
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    value={dataForm?.saldoAnterior}
                                                    placeholder="00,00"
                                                    defaultValue={0}
                                                    decimalsLimit={2}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                <MaskCurrencyInput
                                                    sx={{ width: "100%" }}
                                                    readonly={true}
                                                    label="SaldoTotal"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                Kwh
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    name={NovaOrdemEnum.SALDOTOTAL}
                                                    onValueChange={(newValue, name, values) => {
                                                        handleInputChange({ target: { name, value: newValue } } as unknown as ChangeEvent<HTMLInputElement>);
                                                    }}
                                                    value={dataForm?.saldoTotal}
                                                    placeholder="00,00"
                                                    defaultValue={0}
                                                    decimalsLimit={2}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                <TextField
                                                    name={NovaOrdemEnum.TARIFABASE}
                                                    inputProps={{ readOnly: "true" }}
                                                    select
                                                    onChange={handleInputChange}
                                                    value={dataForm.tarifaBase ?? ""}
                                                    sx={{ width: "100%" }}
                                                    label="Tarifa base"
                                                    required
                                                    fullWidth
                                                >
                                                    {
                                                        Object.entries(EnumTipoTarifa).map(([value, key]) => (
                                                            <MenuItem key={key} value={value}> {key} </MenuItem>
                                                        ))
                                                    }
                                                </TextField>
                                                <D.DateTextfield
                                                    name={NovaOrdemEnum.DATALIMITEQUITACAO}
                                                    onChange={handleInputChange}
                                                    value={
                                                        ((dataForm?.dataLimiteQuitacao ?? "")?.toString())?.split("T")[0]
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    label="Data limite de quitação"
                                                    type="date"
                                                    InputProps={{ readOnly: true }}
                                                    required
                                                    fullWidth
                                                />
                                                <MaskDefaultInput
                                                    type="REAL"
                                                    sx={{ width: "100%" }}
                                                    readonly={true}
                                                    label="Tarifa distribuidora"
                                                    onChange={(value) => {
                                                        handleInputChange({ target: { name:NovaOrdemEnum.TARIFADISTRIBUIDORA, value } } as unknown as ChangeEvent<HTMLInputElement>);
                                                    }}
                                                    value={dataForm.tarifaDistribuidora ?? ""}
                                                />
                                                <MaskCurrencyInput
                                                    label="Desconto tarifa"
                                                    readonly={true}
                                                    name={NovaOrdemEnum.DESCONTOTARIFA}
                                                    onValueChange={(newValue, name) => handleInputChange({ target: { name, value: newValue } } as ChangeEvent<HTMLInputElement>)}
                                                    value={dataForm.descontoTarifa ?? ""}
                                                    placeholder="00,00%"
                                                    defaultValue={0}
                                                    decimalsLimit={2}
                                                    suffix="%"
                                                />
                                                <MaskDefaultInput
                                                    type="REAL"
                                                    sx={{ width: "100%" }}
                                                    readonly={true}
                                                    label="Tarifa do gerador"
                                                    onChange={(value) => {
                                                        handleInputChange({ target: { name:NovaOrdemEnum.VALORBASE, value } } as unknown as ChangeEvent<HTMLInputElement>);
                                                    }}
                                                    value={dataForm.valorBase ?? ""}
                                                />
                                            </C.FWStack>
                                            <C.FWStack direction={"row"} spacing={2}>
                                                <TextField
                                                    inputProps={{ readOnly: "true" }}
                                                    name={NovaOrdemEnum.VALORCREDITO}
                                                    onChange={handleInputChange}
                                                    value={formatValues(dataForm?.valorCredito ?? 0)}
                                                    sx={{ maxWidth: "15%" }}
                                                    label="Valor crédito"
                                                    required
                                                    fullWidth
                                                />
                                                <TextField
                                                    inputProps={{ readOnly: true }}
                                                    onChange={handleInputChange}
                                                    sx={{ maxWidth: "30%" }}
                                                    select
                                                    label="Tipo conexão"
                                                    name={NovaOrdemEnum.TIPOCONEXAO}
                                                    value={dataForm.tipoConexao ?? ""}
                                                    required
                                                    fullWidth>
                                                    {Object.keys(EnumTipoConexao).filter((key: string) => isNaN(Number(key))).map((key: string, i: number) => (
                                                        <MenuItem key={key} value={i}>
                                                            {key}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                <TextField
                                                    inputProps={{ readOnly: "true" }}
                                                    name={NovaOrdemEnum.TARIFAFIOB}
                                                    onChange={handleInputChange}
                                                    value={dataForm.tarifaFioB ?? ""}
                                                    sx={{ maxWidth: "15%" }}
                                                    label="Tarifa fio B"
                                                    required
                                                    fullWidth
                                                />
                                            </C.FWStack>
                                            {
                                                !geradorAccess &&
                                                <C.FWStack
                                                    direction={"row"}
                                                    spacing={2}
                                                    justifyContent={ordemId ? "space-between" : "flex-end"}
                                                >
                                                    {
                                                        ordemId &&
                                                        <>
                                                            <D.ContainedButton
                                                                onClick={liberarVisualização}
                                                                disabled={dataForm?.visualizacao}
                                                                $color="green"
                                                                style={{
                                                                    marginTop: 'auto',
                                                                    marginBottom: 'auto',
                                                                    height: 0
                                                                }}
                                                            >
                                                                Liberar visualização
                                                            </D.ContainedButton>
                                                            <D.ContainedButton
                                                                disabled={dataForm.status !== EnumStatusOrdemPagamento.fechado}
                                                                onClick={handleAbrirOrdem}
                                                                $color="blue"
                                                                style={{
                                                                    marginTop: 'auto',
                                                                    marginBottom: 'auto',
                                                                    height: 0
                                                                }}
                                                            >
                                                                Abrir ordem
                                                            </D.ContainedButton>
                                                            <D.ContainedButton
                                                                disabled={dataForm?.status === EnumStatusOrdemPagamento.fechado}
                                                                onClick={handleFecharOrdem}
                                                                $color="purple"
                                                                style={{
                                                                    marginTop: 'auto',
                                                                    marginBottom: 'auto',
                                                                    height: 0
                                                                }}
                                                            >
                                                                Fechar ordem
                                                            </D.ContainedButton>
                                                            <D.ContainedButton
                                                                onClick={handleExcluir}
                                                                $color="red"
                                                                style={{
                                                                    marginTop: 'auto',
                                                                    marginBottom: 'auto',
                                                                    height: 0
                                                                }}
                                                            >
                                                                Excluir
                                                            </D.ContainedButton>
                                                        </>
                                                    }
                                                    <D.ContainedButton onClick={setOrdemPagamento} style={{
                                                        marginTop: 'auto',
                                                        marginBottom: 'auto',
                                                        height: 0
                                                    }}>Salvar</D.ContainedButton>
                                                </C.FWStack>
                                            }
                                        </RegisterCard>
                                    </>
                                )
                            }
                        </C.ModalCard>
                    </C.ModalArea>
                </C.ModalContainer>
            </Modal>
        </>
    );
}