import { ReactComponent as Associado } from "../../../assets/Layouts/Parceiro/associado.svg";
import { ReactComponent as Comissao } from "../../../assets/Layouts/Parceiro/comissao.svg";
import { ReactComponent as Contrato } from "../../../assets/Layouts/Parceiro/contrato.svg";
import { ReactComponent as Leads } from "../../../assets/Layouts/Parceiro/leads.svg";
import { ReactComponent as MeuCadastro } from "../../../assets/Layouts/Parceiro/meuCadastro.svg";
import { ReactComponent as Proposta } from "../../../assets/Layouts/Parceiro/proposta.svg";
import { ISidebarMenu } from "../../../models/SidebarPopulate";

const ColaboradorRoutes = () => {
  var routes: ISidebarMenu[] = [
    // {
    //   icon: () => <img src={DashboardParceiro} />,
    //   title: "DashboardParceiro",
    //   hasSubMenus: false,
    //   path: "/Parceiro/DashboardParceiro",
    // },
    {
      icon: () => <Leads />,
      title: "Leads",
      hasSubMenus: false,
      path: "/Parceiro/Leads",
    },
    {
      icon: () => <Proposta />,
      title: "Proposta",
      hasSubMenus: false,
      path: "/Parceiro/Propostas",
    },
    {
      icon: () => <Contrato />,
      title: "Contrato",
      hasSubMenus: false,
      path: "/Parceiro/Contratos",
    },
    {
      icon: () => <Associado />,
      title: "Associados",
      hasSubMenus: false,
      path: "/Parceiro/Associados",
    },
    {
      icon: () => <Comissao />,
      title: "Comissão",
      hasSubMenus: false,
      path: "/Parceiro/Comissao",
    },
    {
      icon: () => <MeuCadastro />,
      title: "Meu Cadastro",
      hasSubMenus: false,
      path: "/Parceiro/MeuCadastro",
    },
  ];

  return routes;
};

export default ColaboradorRoutes;
