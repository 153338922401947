import { useCallback, useMemo, useState } from "react";
import { ClasseConstrutor } from "../types/ClasseConstrutor";
import { AbstractValidation } from "../utils/AbstractValidation";
import { addValidate, AddValidateReturn } from "../utils/AddValidate";
import { HelperText } from "../types/HelperText";

export interface UseValidateHook<T> {
  validate: AbstractValidation<T>;
  helperText: HelperText<T>;
  setHelperText: React.Dispatch<React.SetStateAction<HelperText<T>>>;
  addValidateCampo: (campo: keyof T, dados: T) => AddValidateReturn;
  error: boolean;
  executeErrorMessage: (dados: T) => boolean;
}

const useValidate = <T extends Object>(
  c: ClasseConstrutor<AbstractValidation<T>>
): UseValidateHook<T> => {
  const [helperText, setHelperText] = useState<
    Partial<Record<keyof T, string | null>>
  >({});

  const validate = useMemo(() => new c(), [c]);

  const error = useMemo(
    () => Object.values(helperText).some((x) => x !== null),
    [helperText]
  );

  const addValidateCampo = useCallback(
    (campo: keyof T, dados: T) =>
      addValidate(validate, campo, dados, helperText, setHelperText),
    [validate, helperText]
  );

  const executeErrorMessage = useCallback(
    (dados: T) => {
      const erros = validate.errorMessage(dados);
      if (erros === null) return false;

      const newHelperText = Object.fromEntries(
        Object.keys(dados).map((key) => [
          key,
          erros?.find((x) => x.ref === key)?.message ?? null,
        ])
      );

      setHelperText(newHelperText as HelperText<T>);
      return true;
    },
    [validate]
  );

  return {
    validate,
    helperText,
    setHelperText,
    addValidateCampo,
    error,
    executeErrorMessage,
  };
};

export default useValidate;
