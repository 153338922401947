import React, {useState} from "react";
import Login from "../../../../components/Login";
import gerador from "../../../../assets/Login/imgGerador.svg";
import * as C from "../../../../components/Login/style";
import {useNavigate} from "react-router-dom";
import {IconButton, InputAdornment} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {ILoginParams} from "../../../../models/Usuario";
import * as services from "../../../../services/api/UsuarioService";
import Swal from "sweetalert2";
import {AxiosError} from "axios";
import {useAuth} from "../../../../hooks/useAuth";
import { CNPJ } from "../../../../utils/masInputs";

const topics: string[] = [
  "Seja bem-vindo! Sua energia é vital para o funcionamento desta empresa.",
  "Você é fundamental para o nosso crescimento e prosperidade. Obrigado por fazer parte desta equipe.",
  "Sua presença aqui é mais do que uma entrada. Você é a engrenagem que nos faz avançar.",
];

const LoginGerador: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { login } = useAuth();

  const [showLogin, setShowLogin] = useState(false);

  const handleClickShowLogin = () => setShowLogin((show) => !show);

  const handleMouseDownLogin = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    var loginParams: ILoginParams = {
      user: user.replace(/\D/g, ""),
      password,
    };

    await services
      .LoginGerador(loginParams)
      .then(async (response: any) => {
        await login(response.data);

        new CNPJ()

        navigate("/Gerador/Dashboard");

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Bem vindo!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao fazer o login.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };
  return (
    <>
      {/* <Login
        title="gerador"
        topics={topics}
        imageSrc={gerador}
        formTitle="Que bom ter você com a gente!"
        formSubtitle="Faça seu login abaixo"
      >
        <C.LoginForm onSubmit={handleLogin}>
          <C.LoginInput
            label="Login"
            variant="outlined"
            required
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />
          <C.LoginInput
            label="Senha"
            variant="outlined"
            type={showLogin ? "text" : "password"}
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowLogin}
                    onMouseDown={handleMouseDownLogin}
                    edge="end"
                  >
                    {showLogin ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <C.LinksArea>
            <div></div>
            <C.Links to="/Login/Gerador/RecuperarSenha">
              Esqueceu sua senha?
            </C.Links>
          </C.LinksArea>
          <C.LoginButton type="submit" variant="contained">
            Acessar
          </C.LoginButton>
        </C.LoginForm>
      </Login> */}
      <Login
        title="gerador"
        topics={topics}
        imageSrc={gerador}
        formTitle="Que bom ter você com a gente!"
        formSubtitle="Faça seu login abaixo"
      >
        <C.LoginForm onSubmit={handleLogin}>
          <C.LoginInput label="Login" variant="outlined" onChange={(e) => setUser(e.target.value)} />
          <C.LoginInput
            label="Senha"
            variant="outlined"
            type={showLogin ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowLogin}
                    onMouseDown={handleMouseDownLogin}
                    edge="end"
                  >
                    {showLogin ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <C.LinksArea>
            <div></div>
            <C.Links to="/Login/Gerador/RecuperarSenha">
              Esqueceu sua senha?
            </C.Links>
          </C.LinksArea>
          <C.LoginButton
            type="submit"
            variant="contained"
          >
            Acessar
          </C.LoginButton>
        </C.LoginForm>
      </Login>
    </>
  );
};

export default LoginGerador;
