import { ColorType } from "../types/ColorType";

export enum EnumStatusContratoParceiro {
  lead = "Lead",
  aguardandoAssinatura = "Aguardando Assinatura",
  contratoAssinado = "Contrato Assinado",
  aguardandoAssinaturaDistrato = "Aguardando Assinatura Distrato",
  cancelado = "Cancelado",
}

export const StatusContratoParceiroNumberMap: {
  [key: number]: EnumStatusContratoParceiro;
} = {
  0: EnumStatusContratoParceiro.lead,
  1: EnumStatusContratoParceiro.aguardandoAssinatura,
  2: EnumStatusContratoParceiro.contratoAssinado,
  3: EnumStatusContratoParceiro.aguardandoAssinaturaDistrato,
  4: EnumStatusContratoParceiro.cancelado,
};

export const StatusContratoParceiroColorMap: Record<
  EnumStatusContratoParceiro,
  ColorType
> = {
  [EnumStatusContratoParceiro.lead]: "yellow",
  [EnumStatusContratoParceiro.aguardandoAssinatura]: "blue",
  [EnumStatusContratoParceiro.contratoAssinado]: "green",
  [EnumStatusContratoParceiro.aguardandoAssinaturaDistrato]: "red",
  [EnumStatusContratoParceiro.cancelado]: "purple",
};

interface IStatusConfig {
  label: string;
  color: ColorType;
  id: number;
}

export const statusContratoConfig: Record<
  EnumStatusContratoParceiro,
  IStatusConfig
> = {
  [EnumStatusContratoParceiro.lead]: {
    label: "Lead",
    color: "yellow",
    id: 0,
  },
  [EnumStatusContratoParceiro.aguardandoAssinatura]: {
    label: "Aguardando assinatura de contrato",
    color: "blue",
    id: 1,
  },
  [EnumStatusContratoParceiro.contratoAssinado]: {
    label: "Contrato assinado",
    color: "green",
    id: 2,
  },
  [EnumStatusContratoParceiro.aguardandoAssinaturaDistrato]: {
    label: "Aguardando assinatura de distrato",
    color: "red",
    id: 3,
  },
  [EnumStatusContratoParceiro.cancelado]: {
    label: "Cancelado",
    color: "purple",
    id: 4,
  },
};
