import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Autocomplete, MenuItem, TextField, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import ExcelIcon from "../../../assets/Plataforma/excelIcon.svg";
import PourposeIcon from "../../../assets/Plataforma/pourposeIcon.svg";
import Datatable from "../../../components/Datatable";
import {
  EnumStatusLead,
  EnumStatusLeadColorMap,
  EnumStatusLeadMap,
  EnumStatusLeadMapSelect,
  EnumStatusLeadNumericMapSelect,
} from "../../../enums/EnumStatusLead.enum";
import { useAuth } from "../../../hooks/useAuth";
import { ICaptadorListItem } from "../../../models/Captador";
import { IBackofficeListItem } from "../../../models/Colaborador/Colaborador";
import { ILead, ILeadsRelatoriosFilters } from "../../../models/Lead";
import * as captadorServices from "../../../services/api/CaptadorService";
import * as colaboradorServices from "../../../services/api/ColaboradorService";
import * as services from "../../../services/api/LeadService";
import * as D from "../../../styles/appComponents";
import { moneyFormatter } from "../../../utils/moneyFormatter";
import * as C from "./style";
import { useNavigate } from "react-router-dom";
import SkeletonDefaultPage from "../../../components/SkeletonLoads/DefaultPage";
import NewLeadModal from "./Modais/NewLead";
import LeadModal from "./Modais/LeadModal";
import { toastMessage } from "../../../utils/toastMessage";
import { CELULAR } from "../../../utils/masInputs";
import { jwtDecode } from "jwt-decode";
import { ListView } from "../../../components/ListView";
import { StatusDashboard } from "../../../models/StatusDashboard";
import { InputFilterModel } from "../../../models/InputFilterModel";

enum leadsReportEnum {
  STATUS = "status",
  CAPTADORID = "captadorId",
  BACKOFFICEID = "backofficeId",
  DATAINICIO = "dataInicio",
  DATAFIM = "dataFim",
}

const Leads: React.FC = () => {
  //region Variaveis
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [opeLead, setOpeLead] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [open, setOpen] = useState(false);
  const [openNewLead, setOpenNewLead] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [leadsRows, setLeadsRows] = useState<ILead[]>([]);
  const [currentId, setCurrentId] = useState<string | undefined>(undefined);
  const [backoffices, setBackoffices] = useState<IBackofficeListItem[]>([]);
  const [captadores, setCaptadores] = useState<ICaptadorListItem[]>([]);
  const [statusLoading, setStatusLoading] = useState<{sectionName:string, loading:boolean}>({ sectionName:"captadorAccessLeads", loading:false })
  const [modelFilter, setModelFilter] = useState<InputFilterModel | null>(null)
  const navigate = useNavigate();
  const [statusGDLeads, setStatusGDLeads] = useState<
    StatusDashboard<EnumStatusLead>[]
  >([]);
  const [filterStatusGDLeads, setFilterStatusGDLeads] = useState<
    EnumStatusLead | null | undefined
  >(null);
  const [leadsReport, setLeadsReport] = useState<ILeadsRelatoriosFilters>({
    status: "",
    captadorId: "",
    backofficeId: "",
    dataInicio: "",
    dataFim: "",
  });
  const columns: GridColDef[] = [
    {
      field: "idReferencial",
      description: "id",
      headerName: "Id",
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "nome",
      headerName: "Nome",
      description: "name",
      flex: 1,
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "celular",
      headerName: "Celular",
      flex: 1,
      renderCell: (params) => (
        <D.GridField>{new CELULAR().executeForInput(params.value)}</D.GridField>
      ),
    },
    {
      field: "nomeCaptador",
      headerName: "Parceiro",
      flex: 1,
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "valorMedioConta",
      headerName: "Valor",
      flex: 1,
      renderCell: (params) => (
        <D.GridField>{moneyFormatter.format(Number(params.value))}</D.GridField>
      ),
    },
    {
      field: "createdAt",
      headerName: "Data",
      description: "date",
      flex: 1,
      renderCell: (params) => (
        <D.GridField>
          {new Date(String(params.value)).toLocaleDateString()}
        </D.GridField>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <Tooltip title={EnumStatusLeadMap[params.value].name}>
            <D.Circle color={EnumStatusLeadColorMap[Number(params.value)]} />
          </Tooltip>
        </D.GridField>
      ),
    },
    {
      field: "id",
      headerName: "Gerar Proposta",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <D.GridField
            $alignCenter
            onClick={() =>
              auth.user?.idCaptador
                ? navigate(`/Parceiro/Novo/Proposta/${params.value}`)
                : navigate(`/Colaborador/Novo/Proposta/${params.value}`)
            }
          >
            <D.GridImage src={PourposeIcon} alt="pourposeIcon" />
          </D.GridField>
        );
      },
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.DefaultGridButton onClick={() => handleOpen(params.row.id)}>
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];
  //endregion

  //region Services
  const getBackoffices: () => void = () => {
    colaboradorServices
      .GetBackoffices()
      .then((response) => {
        setBackoffices(response.data);
      })
      .catch((e: AxiosError) => {
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os backoffices."
        );
      });
  };

  const getCaptadores: () => void = () => {
    captadorServices
      .GetCaptadores()
      .then((response) => {
        setCaptadores(response.data);
      })
      .catch((e: AxiosError) => {
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os captadores."
        );
      });
  };

  const geraRelatorio: () => void = () => {
    services
      .GeraRelatorio(leadsReport)
      .then(async (response) => {
        const blob = response.data;

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `leads_${new Date()
          .toISOString()
          .slice(0, 19)
          .replace(/[-:T]/g, "")}.xlsx`;
        link.click();

        URL.revokeObjectURL(link.href);
      })
      .catch((e: AxiosError) => {
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao gerar o relatório."
        );
      });
  };

  const getLeadsStatus: () => Promise<void> = async () => {
    await services
      .GetLeadsStatus()
      .then(
        (response: {
          data: React.SetStateAction<StatusDashboard<EnumStatusLead>[]>;
        }) => {
          setStatusGDLeads(response.data);
        }
      )
      .catch((e: AxiosError) => {
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os dados."
        );
      });
  };

  const loadData = async (model:InputFilterModel | null, type:"clear"|"filter") => {
    setStatusLoading({ sectionName:"captadorAccessLeads", loading:true });
    setModelFilter(model);

    await getLeadsStatus();

    await services
      .GetLeads(
        (model?.id? parseInt(model?.id): null),
        model?.name,
        model?.date,
        page,
        pageSize, 
        search, 
        filterStatusGDLeads ?? null
      )
      .then((response) => {
        setLeadsRows(response.data.data);
        setTotalPages(response.data.totalPages);
        setLoading(false);
        setStatusLoading({ sectionName:"captadorAccessLeads", loading:false });
      })
      .catch((e: AxiosError) => {
        setStatusLoading({ sectionName:"captadorAccessLeads", loading:false });
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os dados."
        );
      });
  }

  const getLeads: () => Promise<void> = async () => {
    setStatusLoading({ sectionName:"captadorAccessLeads", loading:true });
    await getLeadsStatus();

    await services
      .GetLeads(
        (modelFilter?.id? parseInt(modelFilter?.id): null),
        modelFilter?.name,
        modelFilter?.date,
        page, 
        pageSize, 
        search, 
        filterStatusGDLeads ?? null
      )
      .then((response) => {
        setLeadsRows(response.data.data);
        setTotalPages(response.data.totalPages);
        setLoading(false);
        setStatusLoading({ sectionName:"captadorAccessLeads", loading:false });
      })
      .catch((e: AxiosError) => {
        setStatusLoading({ sectionName:"captadorAccessLeads", loading:false });
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os dados."
        );
      });
  };
  //endregion

  //region UI
  const handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setLeadsReport((state: ILeadsRelatoriosFilters) => {
      const updatedState = {
        ...state,
        [name]: value,
      };
      return updatedState;
    });
  };

  const handleStatusOpen: () => void = () => {
    setStatusOpen(!statusOpen);
  };

  const handleChangePage: (value: number) => void = (value: number) => {
    setPage(value);
  };

  const handleChangePageSize: (value: number) => void = (value: number) => {
    setPageSize(value);
  };

  const handleOpen: (id: string) => Promise<void> = async (id: string) => {
    setCurrentId(id);
    setOpeLead(true);
  };

  const handleSearch: (value: string) => Promise<void> = async (
    value: string
  ) => {
    setPage(1);
    setSearch(value);
  };

  const handleOpenNewLead: () => Promise<void> = async () => {
    setOpenNewLead(true);
  };

  const callbackNewLeadModal: () => void = () => {
    setOpenNewLead(false);
    getLeads();
  };

  const callbackLeadModal: () => void = () => {
    setOpeLead(false);
    setCurrentId(undefined);
    getLeads();
  };

  useEffect(() => {
    getLeads();
  }, [page, pageSize, search, open]);

  useEffect(() => {
    getBackoffices();
    getCaptadores();
  }, []);

  useEffect(() => {
    getLeads();
  }, [filterStatusGDLeads]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const captadorAccessLeads = useMemo<string | null>(
    () => auth.user?.idCaptador ?? null,
    [auth]
  );

  const sectionNameListViewLeads = useMemo(
    () => (!captadorAccessLeads ? "gerador" : "captadorAccessLeads"),
    [currentId]
  );
  //endregion

  return (
    <>
      <NewLeadModal openNewLead={openNewLead} callback={callbackNewLeadModal} />
      <LeadModal
        openNewLead={opeLead}
        callback={callbackLeadModal}
        id={currentId}
      />
      {loading ? (
        <SkeletonDefaultPage />
      ) : (
        <C.Container>
          <C.Title onClick={() => handleStatusOpen()}>
            {isMobile && (
              <>
                {!statusOpen ? (
                  <KeyboardArrowDownRoundedIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </>
            )}
          </C.Title>
          <ListView<EnumStatusLead>
            sectionName={"captadorAccessLeads"}
            hasButtonSendClickSign={false}
            hasOtherStatus={false}
            statusContratoBuilder={EnumStatusLeadMap}
            data={statusGDLeads}
            getFilterStatus={setFilterStatusGDLeads}
            filterStatusContrato={filterStatusGDLeads}
            hasSecondaryValues={false}
            hasThridValues={false}
          >
            <D.DataArea $align="right">
              <D.ContainedButton
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={handleOpenNewLead}
              >
                Nova lead
              </D.ContainedButton>
              <Datatable
                sectionName={"captadorAccessLeads"}
                columns={columns}
                rows={leadsRows}
                pageNumber={page}
                pageSize={pageSize}
                onChangePage={(e) => handleChangePage(e)}
                onChangePageSize={(e) => handleChangePageSize(e)}
                totalPages={totalPages}
                onSearch={handleSearch}
                statusLoading={statusLoading}
                loadData={loadData}
                hasFilter={true}
              />
              <C.LineBreak />
              <C.ReportsArea>
                <C.SelectArea>
                  <C.ReportsLabel>Status de leads</C.ReportsLabel>
                  <C.ReportsSelect
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    defaultValue={""}
                    onChange={(e) =>
                      handleInputChange({
                        target: {
                          name: leadsReportEnum.STATUS,
                          value: e.target.value as string,
                        },
                      } as unknown as unknown as React.ChangeEvent<HTMLInputElement>)
                    }
                  >
                    <MenuItem value="">
                      <C.DefaultColorText>Todos os status</C.DefaultColorText>
                    </MenuItem>
                    {Object.keys(EnumStatusLeadMapSelect).map((key, index) => (
                      <MenuItem
                        key={key}
                        value={
                          EnumStatusLeadNumericMapSelect[
                            EnumStatusLeadMapSelect[
                              key as keyof typeof EnumStatusLeadMapSelect
                            ]
                          ]
                        }
                      >
                        {
                          EnumStatusLeadMapSelect[
                            key as keyof typeof EnumStatusLeadMapSelect
                          ]
                        }
                      </MenuItem>
                    ))}
                  </C.ReportsSelect>
                </C.SelectArea>
                <C.SelectArea>
                  <C.ReportsLabel>Selecione um Parceiro</C.ReportsLabel>
                  <Autocomplete
                    aria-label="Parceiro"
                    options={captadores}
                    fullWidth
                    noOptionsText="Nenhum parceiro encontrado"
                    getOptionLabel={(option) => option.nome}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Todos os parceiros"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Without label",
                        }}
                      />
                    )}
                    defaultValue={null}
                    onChange={(event, newValue) => {
                      handleInputChange({
                        target: {
                          name: leadsReportEnum.CAPTADORID,
                          value: newValue?.id as string,
                        },
                      } as unknown as unknown as React.ChangeEvent<HTMLInputElement>);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </C.SelectArea>
                <C.SelectArea>
                  <C.ReportsLabel>Selecione o backoffice</C.ReportsLabel>
                  <Autocomplete
                    aria-label="backoffice"
                    options={backoffices}
                    fullWidth
                    noOptionsText="Nenhum backoffice encontrado"
                    getOptionLabel={(option) => option.nome}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Todos os backoffices"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Without label",
                        }}
                      />
                    )}
                    defaultValue={null}
                    onChange={(event, newValue) => {
                      handleInputChange({
                        target: {
                          name: leadsReportEnum.BACKOFFICEID,
                          value: newValue?.id as string,
                        },
                      } as unknown as unknown as React.ChangeEvent<HTMLInputElement>);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </C.SelectArea>
                <C.SelectArea>
                  <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
                  <D.DateTextfield
                    aria-label="dataInicio"
                    type="date"
                    label="Data início"
                    fullWidth
                    value={leadsReport.dataInicio}
                    onChange={(e) =>
                      handleInputChange({
                        target: {
                          name: leadsReportEnum.DATAINICIO,
                          value: e.target.value as string,
                        },
                      } as unknown as unknown as React.ChangeEvent<HTMLInputElement>)
                    }
                  />
                </C.SelectArea>
                <C.SelectArea>
                  <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
                  <D.DateTextfield
                    aria-label="dataFim"
                    type="date"
                    label="Data fim"
                    fullWidth
                    value={leadsReport.dataFim}
                    onChange={(e) =>
                      handleInputChange({
                        target: {
                          name: leadsReportEnum.DATAFIM,
                          value: e.target.value as string,
                        },
                      } as unknown as unknown as React.ChangeEvent<HTMLInputElement>)
                    }
                  />
                </C.SelectArea>
              </C.ReportsArea>
              <C.ExportButton
                onClick={geraRelatorio}
                variant="contained"
                startIcon={
                  <img
                    style={{ width: "20px", color: "white", fill: "white" }}
                    src={ExcelIcon}
                    alt="excelIcon"
                  />
                }
              >
                Gerar relatório
              </C.ExportButton>
            </D.DataArea>
          </ListView>
        </C.Container>
      )}
    </>
  );
};

export default Leads;
