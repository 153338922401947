import CloseIcon from "@mui/icons-material/Close";
import { Modal, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { HistoricoSection } from "../../../../components/HistoricSection";
import { SkeletonSectionForm } from "../../../../components/SkeletonLoads/SectionForm";
import * as A from "../../../../components/StatusStyle/Style";
import { EnumStatusContratoGerador, enumStatusContratoGeradorMap } from "../../../../enums/Gerador/EnumStatusContratoGerador.enum";
import { EnumTipoTarifa } from "../../../../enums/Gerador/EnumTipoTarifa.enum";
import {
  UsinaTipoPessoaFisica,
  UsinaTipoPessoaJuridica,
} from "../../../../models/Gerador/Usina";
import { ICreateLog, ILog } from "../../../../models/Log";
import * as D from "../../../../styles/appComponents";
import * as C from "../../Leads/style";
import { CELULAR } from "../../../../utils/masInputs";
import { HandleDataStatus } from "../../../../utils/HandleDataStatus";

export interface PropsModalInfoGerador {
  openModal: boolean;
  handleCloseModal: () => void;
  usina: UsinaTipoPessoaJuridica | UsinaTipoPessoaFisica | undefined;
  createNewLog: (data: ICreateLog) => Promise<void>;
  logs: ILog[];
  editLog: (id: string, data: ICreateLog) => Promise<void>;
  load: boolean;
  downloadFunCtionCustom?: (model: any) => void;
  access?:boolean;
}

export const ModalInfoGerador: FC<PropsModalInfoGerador> = ({
  openModal,
  handleCloseModal,
  usina,
  createNewLog,
  logs,
  editLog,
  load,
  downloadFunCtionCustom,
  access
}) => {
  return (
    <Modal open={openModal}>
      <C.ModalContainer>
        <C.ModalArea>
          <C.ModalCard>
            {load ? (
              <SkeletonSectionForm />
            ) : (
              <>
                <C.ModalHeader>
                  <h2>{usina?.nomeDadosAcesso} - {usina?.instalacao}</h2>
                  <C.CloseButton aria-label="close" onClick={handleCloseModal}>
                    <CloseIcon />
                  </C.CloseButton>
                </C.ModalHeader>
                <D.FWStack direction={"column"} spacing={4}>
                  <D.FWStack direction={"column"} spacing={2}>
                    <h4>Status atual:</h4>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <A.StatusStyle
                        status={usina?.statusContratoGerador}
                      ></A.StatusStyle>
                      <Typography>
                        {
                          HandleDataStatus.getNameStatus<EnumStatusContratoGerador | undefined>(
                            usina?.statusContratoGerador, enumStatusContratoGeradorMap
                          )
                        }
                      </Typography>
                    </Stack>
                  </D.FWStack>

                  <D.FWStack direction="row" spacing={2}>
                    <D.FWStack direction="column" spacing={2}>
                      <label>Desconto da tarifa</label>
                      <p>
                        {usina?.porcentagemDescontoTarifa
                          ? usina.porcentagemDescontoTarifa + " %"
                          : "Não informado."}
                      </p>
                    </D.FWStack>
                    <D.FWStack direction="column" spacing={2}>
                      <label>Data da conexão</label>
                      <p>
                        {usina?.dataLimiteConexao != null
                          ? new Date(
                              usina.dataLimiteConexao
                            ).toLocaleDateString() ?? ""
                          : ""}
                      </p>
                    </D.FWStack>
                    <D.FWStack direction="column" spacing={2}>
                      <label>Término do contrato</label>
                      <p>
                        {usina?.dataTermino != null
                          ? new Date(usina.dataTermino).toLocaleDateString() ??
                            ""
                          : ""}
                      </p>
                    </D.FWStack>
                    <D.FWStack direction="column" spacing={2}>
                      <label>Celular</label>
                      <p>{new CELULAR().execute(usina?.celularDadosAcesso || "")}</p>
                    </D.FWStack>
                  </D.FWStack>
                  {/* Linha 2 com 6 colunas */}
                  <D.FWStack direction="row" spacing={2}>
                    <D.FWStack direction="column" spacing={2}>
                      <label>CEP</label>
                      <p>{usina?.cep ?? "Não informado."}</p>
                    </D.FWStack>
                    <D.FWStack direction="column" spacing={2}>
                      <label>UF</label>
                      <p>{usina?.uf ?? "Não informado."}</p>
                    </D.FWStack>
                    <D.FWStack direction="column" spacing={2}>
                      <label>Cidade</label>
                      <p>{usina?.cidade ?? "Não informado."}</p>
                    </D.FWStack>
                    <D.FWStack direction="column" spacing={2}>
                      <label>Bairro</label>
                      <p>{usina?.bairro ?? "Não informado."}</p>
                    </D.FWStack>
                    <D.FWStack direction="column" spacing={2}>
                      <label>Número</label>
                      <p>{usina?.numero ?? "Não informado."}</p>
                    </D.FWStack>
                    <D.FWStack direction="column" spacing={2}>
                      <label>Tipo de Conexão</label>
                      <p>
                        {EnumTipoTarifa[usina?.tipoTarifa as EnumTipoTarifa] ??
                          "Não informado."}
                      </p>
                    </D.FWStack>
                  </D.FWStack>
                  {/* Linha 3 com 2 colunas */}
                  <D.FWStack direction="row" spacing={2}>
                    <D.FWStack direction="column" spacing={2}>
                      <label>Logradouro</label>
                      <p>{usina?.logradouro ?? "Não informado."}</p>
                    </D.FWStack>
                    <D.FWStack direction="column" spacing={2}>
                      <label>Complemento</label>
                      <p>{usina?.complemento ?? "Não informado."}</p>
                    </D.FWStack>
                    <D.FWStack></D.FWStack>
                    <D.FWStack></D.FWStack>
                  </D.FWStack>
                </D.FWStack>
              </>
            )}
          </C.ModalCard>
          <HistoricoSection
            tableData={logs}
            saveFunction={createNewLog}
            reloadTable={() => {}}
            editLog={editLog}
            access={access}
          />
        </C.ModalArea>
      </C.ModalContainer>
    </Modal>
  );
};
