import {ColorType} from "../types/ColorType";
import {StatusBuilder} from "../models/StatusBuilder";

export enum EnumStatusContratoCliente {
  aguardandoAssinatura = 0,
  processoConexao = 1,
  conectadoADistribuidora = 2,
  inadimplente = 3,
  desconexaoSolicitada = 4,
  emAnalise = 5,
  cancelado = 6,
  contratoAssinado = 7,
  medidorZerado = 8,
  processoJudicial = 9,
  saldoGeracaoEmExcesso = 10,
  aguardandoAssinaturaAditivo = 11,
  aguardandoAssinaturaDistrato = 12,
  desconectado = 13,
}

export enum EnumStatusContratoClienteMapSelect {
  aguardandoAssinatura = "Aguardando assinatura",
  processoConexao = "Processo de conexão",
  conectadoADistribuidora = "Conectado a distribuidora",
  inadimplente = "Inadimplente",
  desconexaoSolicitada = "Deconexão solicitada",
  emAnalise = "Em análise",
  cancelado = "Cancelado",
  contratoAssinado = "Contrato assinado",
  medidorZerado = "Medidor zerado",
  processoJudicial = "Processo Judicial",
  saldoGeracaoEmExcesso = "Saldo de geração em excesso",
  aguardandoAssinaturaAditivo = "Aguardando assinatura de aditivo",
  aguardandoAssinaturaDistrato = "Aguardando assinatura de distrato",
  desconectado = "Desconectado",
}

export const EnumStatusContratoClienteMap: StatusBuilder[] = [
  {
    name: "Aguardando assinatura",
    value: EnumStatusContratoCliente.aguardandoAssinatura,
    color: "yellow",
    titleThird: "Valor total de kwh",
    typeNumberFormatterThird: "KWH",
    titleSecondary: "Valor total de contratos",
    typeNumberFormatter: "$",
    primary: true
  },
  {
    name: "Em processo de conexão",
    value: EnumStatusContratoCliente.processoConexao,
    color: "blue",
    titleThird: "Valor total de kwh",
    typeNumberFormatterThird: "KWH",
    titleSecondary: "Valor total de contratos",
    typeNumberFormatter: "$",
    primary: true
  },
  {
    name: "Conectado a distribuidora",
    value: EnumStatusContratoCliente.conectadoADistribuidora,
    color: "green",
    titleThird: "Valor total de kwh",
    typeNumberFormatterThird: "KWH",
    titleSecondary: "Valor total de contratos",
    typeNumberFormatter: "$",
    primary: true
  },
  {
    name: "Inadimplente",
    value: EnumStatusContratoCliente.inadimplente,
    color: "purple",
    titleThird: "Valor total de kwh",
    typeNumberFormatterThird: "KWH",
    titleSecondary: "Valor total de contratos",
    typeNumberFormatter: "$",
    primary: true
  },
  {
    name: "Desconexão solicitada",
    value: EnumStatusContratoCliente.desconexaoSolicitada,
    color: "red",
    titleThird: "Valor total de kwh",
    typeNumberFormatterThird: "KWH",
    titleSecondary: "Valor total de contratos",
    typeNumberFormatter: "$",
    primary: true
  },
  {
    name: "Contrato assinado",
    value: EnumStatusContratoCliente.contratoAssinado,
    color: "cyan",
    primary: false
  },
  {
    name: "Em processo de conexão",
    value: EnumStatusContratoCliente.processoConexao,
    color: "darkBlue",
    primary: false
  },
  {
    name: "Medidor zerado",
    value: EnumStatusContratoCliente.medidorZerado,
    color: "orange",
    primary: false
  },
  {
    name: "Saldo de geração em exesso",
    value: EnumStatusContratoCliente.saldoGeracaoEmExcesso,
    color: "gold",
    primary: false
  },
  {
    name: "Aguardando assinatura de aditivo",
    value: EnumStatusContratoCliente.aguardandoAssinaturaAditivo,
    color: "darkPink",
    primary: false
  },
  {
    name: "Aguardando assinatura de distrato",
    value: EnumStatusContratoCliente.aguardandoAssinaturaDistrato,
    color: "brown",
    primary: false
  },
  {
    name: "Processo judicial",
    value: EnumStatusContratoCliente.processoJudicial,
    color: "darkGreen",
    primary: false
  },
  {
    name: "Conectado a distribuidora",
    value: EnumStatusContratoCliente.conectadoADistribuidora,
    color: "whiteGreen",
    primary: false
  },
  {
    name: "Desconectado",
    value: EnumStatusContratoCliente.desconectado,
    color: "grey",
    primary: false
  },
  {
    name: "Em análise",
    value: EnumStatusContratoCliente.emAnalise,
    color: "whitePink",
    primary: false
  },
  {
    name: "Cancelado",
    value: EnumStatusContratoCliente.cancelado,
    color: "whiteOrange",
    primary: false
  },
]

export const StatusGDContratoNumberMap: {
  [key: number]: EnumStatusContratoCliente;
} = {
  0: EnumStatusContratoCliente.aguardandoAssinatura,
  1: EnumStatusContratoCliente.processoConexao,
  2: EnumStatusContratoCliente.conectadoADistribuidora,
  3: EnumStatusContratoCliente.inadimplente,
  4: EnumStatusContratoCliente.desconexaoSolicitada,
  5: EnumStatusContratoCliente.emAnalise,
  6: EnumStatusContratoCliente.cancelado,
  7: EnumStatusContratoCliente.contratoAssinado,
  8: EnumStatusContratoCliente.medidorZerado,
  9: EnumStatusContratoCliente.processoJudicial,
  10: EnumStatusContratoCliente.saldoGeracaoEmExcesso,
  11: EnumStatusContratoCliente.aguardandoAssinaturaAditivo,
  12: EnumStatusContratoCliente.aguardandoAssinaturaDistrato,
  13: EnumStatusContratoCliente.desconectado,
};

export const StatusGDContratoNumber: {
  [key: number]: EnumStatusContratoClienteMapSelect;
} = {
  0: EnumStatusContratoClienteMapSelect.aguardandoAssinatura,
  1: EnumStatusContratoClienteMapSelect.processoConexao,
  2: EnumStatusContratoClienteMapSelect.conectadoADistribuidora,
  3: EnumStatusContratoClienteMapSelect.inadimplente,
  4: EnumStatusContratoClienteMapSelect.desconexaoSolicitada,
  5: EnumStatusContratoClienteMapSelect.emAnalise,
  6: EnumStatusContratoClienteMapSelect.cancelado,
  7: EnumStatusContratoClienteMapSelect.contratoAssinado,
  8: EnumStatusContratoClienteMapSelect.medidorZerado,
  9: EnumStatusContratoClienteMapSelect.processoJudicial,
  10: EnumStatusContratoClienteMapSelect.saldoGeracaoEmExcesso,
  11: EnumStatusContratoClienteMapSelect.aguardandoAssinaturaAditivo,
  12: EnumStatusContratoClienteMapSelect.aguardandoAssinaturaDistrato,
  13: EnumStatusContratoClienteMapSelect.desconectado,
};

export const EnumStatusGDContratoNumericMap = {
  [EnumStatusContratoClienteMapSelect.aguardandoAssinatura]: 0,
  [EnumStatusContratoClienteMapSelect.processoConexao]: 1,
  [EnumStatusContratoClienteMapSelect.conectadoADistribuidora]: 2,
  [EnumStatusContratoClienteMapSelect.inadimplente]: 3,
  [EnumStatusContratoClienteMapSelect.desconexaoSolicitada]: 4,
  [EnumStatusContratoClienteMapSelect.emAnalise]: 5,
  [EnumStatusContratoClienteMapSelect.cancelado]: 6,
  [EnumStatusContratoClienteMapSelect.contratoAssinado]: 7,
  [EnumStatusContratoClienteMapSelect.medidorZerado]: 8,
  [EnumStatusContratoClienteMapSelect.processoJudicial]: 9,
  [EnumStatusContratoClienteMapSelect.saldoGeracaoEmExcesso]: 10,
  [EnumStatusContratoClienteMapSelect.aguardandoAssinaturaAditivo]: 11,
  [EnumStatusContratoClienteMapSelect.aguardandoAssinaturaDistrato]: 12,
  [EnumStatusContratoClienteMapSelect.desconectado]: 13,
};

export const StatusContratoColorMap: Record<number, ColorType> = {
  0: "yellow",
  1: "blue",
  2: "green",
  3: "purple",
  4: "red",
  5: "whitePink",
  6: "whiteOrange",
  7: "cyan",
  8: "darkBlue",
  9: "darkGreen",
  10: "gold",
  11: "darkPink",
  12: "brown",
  13: "grey",
};