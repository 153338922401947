import React, {useState} from 'react';
import RegisterCard from "../../../../../components/RegisterCard";
import * as D from "../../../../../styles/appComponents";
import {Box, TextField} from "@mui/material";

export interface IDetalhesFatura {
  quantidadeCompensada: string | null;
  quantidadeRecebimento: string | null;
  quantidadeSaldoAtual: string | null;
  energiaInjetada: string | null;
  valorFaturaCooperativa: string | null;
  valorGd: string | null;
  cupomAplicado: string | null;
  valorFaturaLiquidada: string | null;
  status: string | null;
  split: string | null;
  vencimento: string | null;
}

export enum DetalhesFaturaEnum {
  QUANTIDADE_COMPENSADA = "quantidadeCompensada",
  QUANTIDADE_RECEBIMENTO = "quantidadeRecebimento",
  QUANTIDADE_SALDO_ATUAL = "quantidadeSaldoAtual",
  ENERGIA_INJETADA = "energiaInjetada",
  VALOR_FATURA_COOPERATIVA = "valorFaturaCooperativa",
  VALOR_GD = "valorGd",
  CUPOM_APLICADO = "cupomAplicado",
  VALOR_FATURA_LIQUIDADA = "valorFaturaLiquidada",
  STATUS = "status",
  SPLIT = "split",
  VENCIMENTO = "vencimento",
}

type DadosPagadorProps = {
  callback: (dadosPagador: IDetalhesFatura) => void;
}

const DetalhesFatura: (props: DadosPagadorProps) => React.JSX.Element = (props: DadosPagadorProps): React.JSX.Element => {
  //region Variáveis
  const {callback} = props;
  const [detalhesFatura, setDetalhesFatura] = useState<IDetalhesFatura>({
    quantidadeCompensada: null,
    quantidadeRecebimento: null,
    quantidadeSaldoAtual: null,
    energiaInjetada: null,
    valorFaturaCooperativa: null,
    valorGd: null,
    cupomAplicado: null,
    valorFaturaLiquidada: null,
    status: null,
    split: null,
    vencimento: null,
  });
  //endregion

  //region UI
  const handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setDetalhesFatura((state: IDetalhesFatura) => {
      const newData = {
        ...state,
        [name]: value,
      };
      callback(newData);
      return newData;
    });
  };
  //endregion

  return (
    <>
      <RegisterCard title="Detalhes da fatura">
        <D.FWStack direction={"row"} spacing={2}>
          <TextField
            label="Quantidade compensada"
            required
            fullWidth
            name={DetalhesFaturaEnum.QUANTIDADE_COMPENSADA}
            value={detalhesFatura.quantidadeCompensada}
            onChange={handleInputChange}
          />
          <TextField
            label="Quantidade recebimento"
            required
            fullWidth
            name={DetalhesFaturaEnum.QUANTIDADE_RECEBIMENTO}
            value={detalhesFatura.quantidadeRecebimento}
            onChange={handleInputChange}
          />
          <TextField
            label="Quantidade saldo atual"
            required
            fullWidth
            name={DetalhesFaturaEnum.QUANTIDADE_SALDO_ATUAL}
            value={detalhesFatura.quantidadeSaldoAtual}
            onChange={handleInputChange}
          />
          <TextField
            label="Energia injetada"
            required
            fullWidth
            name={DetalhesFaturaEnum.ENERGIA_INJETADA}
            value={detalhesFatura.energiaInjetada}
            onChange={handleInputChange}
          />
          <TextField
            label="Valor fatura cooperativa"
            required
            fullWidth
            name={DetalhesFaturaEnum.VALOR_FATURA_COOPERATIVA}
            value={detalhesFatura.valorFaturaCooperativa}
            onChange={handleInputChange}
          />
          <TextField
            label="Valor GD"
            required
            fullWidth
            name={DetalhesFaturaEnum.VALOR_GD}
            value={detalhesFatura.valorGd}
            onChange={handleInputChange}
          />
          <TextField
            label="Cupom aplicado"
            required
            fullWidth
            name={DetalhesFaturaEnum.CUPOM_APLICADO}
            value={detalhesFatura.cupomAplicado}
            onChange={handleInputChange}
          />
        </D.FWStack>

        <D.FWStack direction={"row"} spacing={2}>
          <TextField
            label="Valor fatura líquida"
            required
            fullWidth
            name={DetalhesFaturaEnum.VALOR_FATURA_LIQUIDADA}
            value={detalhesFatura.valorFaturaLiquidada}
            onChange={handleInputChange}
          />
          <TextField
            label="Status"
            select
            required
            fullWidth
            name={DetalhesFaturaEnum.STATUS}
            value={detalhesFatura.status}
            onChange={handleInputChange}
          />
          <TextField
            label="split"
            required
            fullWidth
            name={DetalhesFaturaEnum.SPLIT}
            value={detalhesFatura.split}
            onChange={handleInputChange}
          />
          <TextField
            label="Vencimento"
            required
            fullWidth
            name={DetalhesFaturaEnum.VENCIMENTO}
            value={detalhesFatura.vencimento}
            onChange={handleInputChange}
          />
        </D.FWStack>

        <D.FWStack direction={"row"} spacing={2} alignItems={"end"}>
          <Box style={{marginLeft: "auto"}}>
            <D.ContainedButton>
              Visualizar faturas
            </D.ContainedButton>
          </Box>
        </D.FWStack>
      </RegisterCard>
    </>
  );
};

export default DetalhesFatura;