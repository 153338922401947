import { useEffect, useState } from "react";
import RegisterCard from "../../../../../components/RegisterCard";
import * as D from "../../../../../styles/appComponents";
import {
  EnumStatusGDProposta,
  EnumStatusGDPropostaNumericMap,
  StatusPropostaColorMap,
  StatusPropostaNumberMap,
} from "../../../../../enums/EnumStatusGDProposta.enum";

interface IStatusPropostaGerarPropostaProps {
  economiaMensalLiquida: string | null;
}

const StatusPropostaGerarProposta: React.FC<
  IStatusPropostaGerarPropostaProps
> = ({ economiaMensalLiquida }) => {
  //region Variáveis
  const [status, setStatus] = useState<number>(0);
  //endregion

  //region UI
  useEffect(() => {
    if (economiaMensalLiquida == null || economiaMensalLiquida == "") {
      setStatus(EnumStatusGDPropostaNumericMap[EnumStatusGDProposta.emAnalise]);
    } else if (Number(economiaMensalLiquida) >= 20) {
      setStatus(EnumStatusGDPropostaNumericMap[EnumStatusGDProposta.aprovada]);
    } else {
      setStatus(EnumStatusGDPropostaNumericMap[EnumStatusGDProposta.reprovada]);
    }
  }, [economiaMensalLiquida]);
  //endregion

  return (
    <RegisterCard title="Status da proposta">
      Status da proposta
      <D.FWStack direction={"row"} spacing={2} alignItems={"center"}>
        <D.Circle color={StatusPropostaColorMap[status]}></D.Circle>{" "}
        <span>{StatusPropostaNumberMap[status]}</span>
      </D.FWStack>
    </RegisterCard>
  );
};

export default StatusPropostaGerarProposta;
