import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import Datatable from "../../../components/Datatable";
import * as D from "../../../styles/appComponents";
import * as C from "./style";
import * as services from "../../../services/api/GDContratoClienteService";
import { IGDContratoPaged } from "../../../models/GDContrato";
import {
  EnumStatusContratoCliente,
  EnumStatusContratoClienteMap,
  EnumStatusContratoClienteMapSelect,
  EnumStatusGDContratoNumericMap,
  StatusContratoColorMap,
  StatusGDContratoNumber,
  StatusGDContratoNumberMap,
} from "../../../enums/EnumStatusContratoCliente.enum";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import SkeletonDefaultPage from "../../../components/SkeletonLoads/DefaultPage";
import { toastMessage } from "../../../utils/toastMessage";
import ContratoModal from "./Modais/ContratoModal";
import { ListView } from "../../../components/ListView";
import { StatusDashboard } from "../../../models/StatusDashboard";
import { useAuth } from "../../../hooks/useAuth";
import { Autocomplete, MenuItem, TextField, Tooltip } from "@mui/material";
import ExcelIcon from "../../../assets/Plataforma/excelIcon.svg";
import { undefined } from "zod";
import * as captadorServices from "../../../services/api/CaptadorService";
import { ICaptadorListItem } from "../../../models/Captador";
import * as colaboradorServices from "../../../services/api/ColaboradorService";
import { IBackofficeListItem } from "../../../models/Colaborador/Colaborador";
import { InputFilterModel } from "../../../models/InputFilterModel";

export interface IContratoReport {
  EnumStatusContratoCliente: string;
  CaptadorId: string;
  BackofficeId: string;
  dataInicio: string | undefined;
  dataFim: string | undefined;
}

export enum ContratoReportEnum {
  STATUS = "EnumStatusContratoCliente",
  PARCEIROID = "CaptadorId",
  SELECIONEBACKOFFICE = "BackofficeId",
  DATAINICIO = "dataInicio",
  DATAFIM = "dataFim",
}

const ContratosClientes = () => {
  //region Variáveis
  const auth = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [openModalSeeMore, setOpenModalSeeMore] = useState(false);
  const [modalId, setModalId] = useState<string | null>("");
  const [statusOpen, setStatusOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [captadores, setCaptadores] = useState<ICaptadorListItem[]>([]);
  const [backoffices, setBackoffices] = useState<IBackofficeListItem[]>([]);
  const [statusContratoCliente, setstatusContratoCliente] = useState<
    StatusDashboard<EnumStatusContratoCliente>[]
  >([]);
  const [filterStatusContratoCliente, setFilterStatusContratoCliente] =
    useState<EnumStatusContratoCliente | null | undefined>(null);
  const [contratoReport, setContratoReport] = useState<IContratoReport>({
    dataFim: "",
    dataInicio: "",
    CaptadorId: "",
    BackofficeId: "",
    EnumStatusContratoCliente: "",
  });
  const [modelFilter, setModelFilter] = useState<InputFilterModel | null>(null);
  const [statusLoading, setStatusLoading] = useState<{sectionName:string, loading:boolean}>({ sectionName:"captadorAccessCaptador", loading:false })
  const [rows, setRows] = useState<IGDContratoPaged[]>([]);
  const columns: GridColDef[] = [
    {
      field: "idReferencial",
      headerName: "Id",
      description: "id",
      renderCell: (params: GridCellParams<IGDContratoPaged>) => {
        return (
          <D.GridField
            onClick={() => {
              auth.user?.idCaptador
                ? navigate(`/Parceiro/Editar/Contrato/${params.row.id}`)
                : navigate(`/Colaborador/Editar/Contrato/${params.row.id}`);
            }}
          >
            <D.LinkClick
              style={{
                whiteSpace: "normal",
                fontSize: 14,
              }}
            >
              {params.row.idReferencial}
            </D.LinkClick>
          </D.GridField>
        );
      },
    },
    {
      field: "titular",
      headerName: "Titular",
      description: "name",
      flex: 1,
      align: "center",
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "instalacao",
      headerName: "Instalação",
      description: "instalacao",
      flex: 1,
      align: "center",
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "nomeParceiro",
      headerName: "Parceiro",
      flex: 1,
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "usina",
      headerName: "Usina",
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridCellParams<IGDContratoPaged>) => (
        <D.GridField $alignCenter>
          <Tooltip title={params.row.nomeUsina}>
            <a>{params.row.siglaUsina}</a>
          </Tooltip>
        </D.GridField>
      ),
    },
    {
      field: "dataAssinatura",
      headerName: "Data de assinatura",
      description: "date",
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridCellParams<IGDContratoPaged>) => (
        <D.GridField onClick={() => {}}>
          {new Date(params.row.dataAssinatura).toLocaleDateString() ===
          "01/01/1"
            ? "Não assinado"
            : new Date(params.row.dataAssinatura).toLocaleDateString()}
        </D.GridField>
      ),
    },
    {
      field: "dataVencimento",
      headerName: "Data de vencimento",
      flex: 1,
      minWidth: 150,
      renderCell: (params: GridCellParams<IGDContratoPaged>) => (
        <D.GridField onClick={() => {}}>
          {new Date(params.row.dataAssinatura).toLocaleDateString() ===
          "01/01/1"
            ? "Não assinado"
            : new Date(params.row.dataVencimento).toLocaleDateString() ===
              "01/01/1"
            ? "N/A"
            : new Date(params.row.dataVencimento).toLocaleDateString()}
        </D.GridField>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <Tooltip title={StatusGDContratoNumber[params.value]}>
            <D.Circle color={StatusContratoColorMap[params.value]} />
          </Tooltip>
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridCellParams<IGDContratoPaged>) => (
        <D.GridField $alignCenter>
          <D.DefaultGridButton onClick={() => handleOpenModal(params.row.id)}>
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];
  //endregion

  //region Services
  useEffect(() => {
    (async () => {
      colaboradorServices
        .GetBackoffices()
        .then((response) => {
          setBackoffices(response.data);
        })
        .catch((e: AxiosError) => {
          toastMessage(
            "error",
            e.response
              ? String(e.response?.data)
              : "Houve um erro ao pegar os backoffices."
          );
        });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      captadorServices
        .GetCaptadores()
        .then((response) => {
          setCaptadores(response.data);
        })
        .catch((e: AxiosError) => {
          toastMessage(
            "error",
            e.response
              ? String(e.response?.data)
              : "Houve um erro ao pegar os captadores."
          );
        });
    })();
  }, []);

  const geraRelatorio: () => void = () => {
    services
      .gerarRelatorio(contratoReport)
      .then(async (response) => {
        const blob = response.data;

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `contratoCliente_${new Date()
          .toISOString()
          .slice(0, 19)
          .replace(/[-:T]/g, "")}.xlsx`;
        link.click();

        URL.revokeObjectURL(link.href);
      })
      .catch((e: AxiosError) => {
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao gerar o relatório."
        );
      });
  };

  const loadData = async (model:InputFilterModel | null, type:"clear"|"filter") => {
    setStatusLoading({ sectionName:"captadorAccessCaptador", loading:true });
    setModelFilter(model);
    await getStats();

    await services
      .GetContratos(
        (model?.id? parseInt(model?.id): null),
        model?.instalacao,
        model?.name,
        model?.date,
        page, 
        pageSize, 
        "", 
        filterStatusContratoCliente
      )
      .then((response) => {
        setStatusLoading({ sectionName:"captadorAccessCaptador", loading:false });
        setRows(response.data.data);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((e: AxiosError) => {
        setStatusLoading({ sectionName:"captadorAccessCaptador", loading:false });
        toastMessage(
          "warning",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os dados."
        );
      });
  }

  const getContratos = async (search?: string) => {
    setStatusLoading({ sectionName:"captadorAccessCaptador", loading:true });
    await getStats();

    await services
      .GetContratos(
        (modelFilter?.id? parseInt(modelFilter?.id): null),
        modelFilter?.instalacao,
        modelFilter?.name,
        modelFilter?.date,
        page, 
        pageSize, 
        search, 
        filterStatusContratoCliente
      )
      .then((response) => {
        setStatusLoading({ sectionName:"captadorAccessCaptador", loading:false });
        setRows(response.data.data);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((e: AxiosError) => {
        setStatusLoading({ sectionName:"captadorAccessCaptador", loading:false });
        toastMessage(
          "warning",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os dados."
        );
      });
  };

  const getStats = () => {
    services
      .GetStats()
      .then((response) => {
        setstatusContratoCliente(response.data);
      })
      .catch((e: AxiosError) => {
        toastMessage(
          "warning",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os dados."
        );
      });
  };
  //endregion

  //region UI
  const handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setContratoReport((state: IContratoReport) => {
      const updatedState = {
        ...state,
        [name]: value,
      };
      return updatedState;
    });
  };

  const handleOpenModal = async (contratoId: string | null) => {
    setModalId(contratoId);
    setOpenModalSeeMore(!openModalSeeMore);
  };

  const handleSearch = (value: string) => {
    getContratos(value);
  };

  const handleChangePage = (value: number) => {
    setPage(value);
  };

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getContratos();
  }, [page, pageSize, openModalSeeMore]);

  useEffect(() => {
    getContratos();
  }, [filterStatusContratoCliente]);

  const captadorAccessContrato = useMemo<string | null>(
    () => auth.user?.idCaptador ?? null,
    [auth]
  );

  const sectionNameListViewContrato: string = useMemo(
    () => (!captadorAccessContrato ? "gerador" : "captadorAccess"),
    [modalId]
  );
  //endregion

  return (
    <>
      <ContratoModal
        id={modalId}
        openContratoModal={openModalSeeMore}
        callback={handleOpenModal}
      />
      {loading ? (
        <SkeletonDefaultPage />
      ) : (
        <>
          <C.Container>
            <C.Title onClick={() => handleOpenModal(null)}>
              {isMobile && (
                <>
                  {!statusOpen ? (
                    <KeyboardArrowDownRoundedIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </>
              )}
            </C.Title>
            <ListView<EnumStatusContratoCliente>
              sectionName={"captadorAccessCaptador"}
              hasButtonSendClickSign={false}
              hasOtherStatus={true}
              hasThridValues={true}
              statusContratoBuilder={EnumStatusContratoClienteMap}
              data={statusContratoCliente}
              getFilterStatus={setFilterStatusContratoCliente}
              filterStatusContrato={filterStatusContratoCliente}
              hasSecondaryValues={true}
            >
              <D.DataArea $align="right">
                <Datatable
                  columns={columns}
                  sectionName={"captadorAccessCaptador"}
                  rows={rows}
                  pageNumber={page}
                  pageSize={pageSize}
                  onChangePage={(e) => handleChangePage(e)}
                  onChangePageSize={(e) => handleChangePageSize(e)}
                  totalPages={totalPages}
                  onSearch={handleSearch}
                  loadData={loadData}
                  hasFilter={true}
                  statusLoading={statusLoading}
                />
                <C.LineBreak />
                <C.ReportsArea>
                  <C.SelectArea>
                    <C.ReportsLabel>Status do contrato</C.ReportsLabel>
                    <C.ReportsSelect
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      defaultValue={""}
                      onChange={(e) =>
                        handleInputChange({
                          target: {
                            name: ContratoReportEnum.STATUS,
                            value: e.target.value as string,
                          },
                        } as unknown as unknown as React.ChangeEvent<HTMLInputElement>)
                      }
                    >
                      <MenuItem value="">
                        <C.DefaultColorText>Todos os status</C.DefaultColorText>
                      </MenuItem>
                      {Object.keys(EnumStatusContratoClienteMapSelect).map(
                        (key, index) => (
                          <MenuItem
                            key={key}
                            value={
                              EnumStatusGDContratoNumericMap[
                                EnumStatusContratoClienteMapSelect[
                                  key as keyof typeof EnumStatusContratoClienteMapSelect
                                ]
                              ]
                            }
                          >
                            {
                              EnumStatusContratoClienteMapSelect[
                                key as keyof typeof EnumStatusContratoClienteMapSelect
                              ]
                            }
                          </MenuItem>
                        )
                      )}
                    </C.ReportsSelect>
                  </C.SelectArea>
                  <C.SelectArea>
                    <C.ReportsLabel>Selecione um parceiro</C.ReportsLabel>
                    <Autocomplete
                      aria-label="captador"
                      options={captadores}
                      fullWidth
                      noOptionsText="Nenhum captador encontrado"
                      getOptionLabel={(option) => option.nome}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Todos os captadores"
                          inputProps={{
                            ...params.inputProps,
                            "aria-label": "Without label",
                          }}
                        />
                      )}
                      defaultValue={null}
                      onChange={(event, newValue) => {
                        handleInputChange({
                          target: {
                            name: ContratoReportEnum.PARCEIROID,
                            value: newValue?.id as string,
                          },
                        } as unknown as unknown as React.ChangeEvent<HTMLInputElement>);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                    />
                  </C.SelectArea>
                  <C.SelectArea>
                    <C.ReportsLabel>Selecione o backoffice</C.ReportsLabel>
                    <Autocomplete
                      aria-label="backoffice"
                      options={backoffices}
                      fullWidth
                      noOptionsText="Nenhum backoffice encontrado"
                      getOptionLabel={(option) => option.nome}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Todos os backoffices"
                          inputProps={{
                            ...params.inputProps,
                            "aria-label": "Without label",
                          }}
                        />
                      )}
                      defaultValue={null}
                      onChange={(event, newValue) => {
                        handleInputChange({
                          target: {
                            name: ContratoReportEnum.SELECIONEBACKOFFICE,
                            value: newValue?.id as string,
                          },
                        } as unknown as unknown as React.ChangeEvent<HTMLInputElement>);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                    />
                  </C.SelectArea>
                  <C.SelectArea>
                    <C.ReportsLabel>Data início</C.ReportsLabel>
                    <D.DateTextfield
                      aria-label="dataInicio"
                      label={"Data início"}
                      type="date"
                      fullWidth
                      value={contratoReport.dataInicio}
                      onChange={(e) =>
                        handleInputChange({
                          target: {
                            name: ContratoReportEnum.DATAINICIO,
                            value: e.target.value as string,
                          },
                        } as unknown as unknown as React.ChangeEvent<HTMLInputElement>)
                      }
                    />
                  </C.SelectArea>
                  <C.SelectArea>
                    <C.ReportsLabel>Data fim</C.ReportsLabel>
                    <D.DateTextfield
                      aria-label="dataFim"
                      type="date"
                      fullWidth
                      label={"Data fim"}
                      value={contratoReport.dataFim}
                      onChange={(e) =>
                        handleInputChange({
                          target: {
                            name: ContratoReportEnum.DATAFIM,
                            value: e.target.value as string,
                          },
                        } as unknown as unknown as React.ChangeEvent<HTMLInputElement>)
                      }
                    />
                  </C.SelectArea>
                </C.ReportsArea>
                <C.ExportButton
                  onClick={geraRelatorio}
                  variant="contained"
                  startIcon={
                    <img
                      style={{ width: "20px", color: "white", fill: "white" }}
                      src={ExcelIcon}
                      alt="excelIcon"
                    />
                  }
                >
                  Gerar relatório
                </C.ExportButton>
              </D.DataArea>
            </ListView>
          </C.Container>
        </>
      )}
    </>
  );
};

export default ContratosClientes;
