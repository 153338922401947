import { Autocomplete, TextField } from "@mui/material";
import RegisterCard from "../../../../../components/RegisterCard";
import * as D from "../../../../../styles/appComponents";
import React, { useEffect, useState } from "react";
import * as leadService from "../../../../../services/api/LeadService";
import * as cooperativaService from "../../../../../services/api/CooperativaService";
import { IFullLead } from "../../../../../models/Lead";
import { AxiosError, AxiosResponse } from "axios";
import Swal from "sweetalert2";
import {
  IDadosPessoaisEditarProposta,
  IDadosPessoaisProposta,
} from "../../../../../models/GDProposta";
import { ICooperativaListItem } from "../../../../../models/Cooperativa";
import MaskDefaultInput from "../../../../../components/MasDefaultinput/MaskDefaultInput";
import { handleStyleFieldError } from "../../../../../utils/verifyFieldError";
import { ErrorMessage } from "../../../../../utils/AbstractValidation";
import { toastMessage } from "../../../../../utils/toastMessage";
import { undefined } from "zod";

interface IDadosPessoaisEditarPropostaProps {
  initialData: IDadosPessoaisEditarProposta;
  onSendData: (data: IDadosPessoaisEditarProposta) => void;
}

enum DadosPessoaisGerarPropostaEnum {
  NOME = "nome",
  CPF = "cpf",
  CELULAR = "celular",
  EMAIL = "email",
  COOPERATIVA = "cooperativaId",
}

const DadosPessoaisEditarProposta: React.FC<
  IDadosPessoaisEditarPropostaProps
> = ({ onSendData, initialData }) => {
  //region Variáveis
  const [cooperativaList, setCooperativaList] = useState<
    ICooperativaListItem[]
  >([]);
  const [fieldErros] = useState<ErrorMessage[] | null | undefined>(null);
  const [formData, setFormData] = useState<IDadosPessoaisEditarProposta>({
    backofficeNome: "",
    captadorNome: "",
    captadorId: "",
    leadId: "",
    celular: "",
    cooperativaId: "",
    cpf: "",
    email: "",
    nome: "",
  });
  //endregion

  //region Services
  useEffect(() => {
    (async () => {
      try {
        const response: AxiosResponse<ICooperativaListItem[], any> =
          await cooperativaService.GetCooperativas();

        setCooperativaList(response.data);
      } catch (error) {
        const e = error as AxiosError;

        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os dados."
        );
      }
    })();
  }, []);
  //endregion

  //region UI
  const handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setFormData((state: IDadosPessoaisEditarProposta) => {
      const updatedState = {
        ...state,
        [name]: value ?? null,
      };

      onSendData(updatedState);
      return updatedState;
    });
  };

  useEffect(() => {
    const updatedData = {
      celular: initialData.celular,
      cooperativaId: initialData.cooperativaId,
      cpf: initialData.cpf,
      email: initialData.email,
      nome: initialData.nome,
      captadorId: initialData.captadorId,
      leadId: initialData.leadId,
      backofficeNome: initialData?.backofficeNome,
      captadorNome: initialData?.captadorNome,
    };

    setFormData(updatedData);
    onSendData(updatedData);
  }, [initialData]);

  useEffect(() => {
    onSendData(formData);
  }, [formData]);
  //endregion

  return (
    <RegisterCard title="Dados Pessoais">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Nome"
          required
          fullWidth
          sx={handleStyleFieldError(
            DadosPessoaisGerarPropostaEnum.NOME,
            fieldErros ?? null,
            { width: "100%" }
          )}
          name={DadosPessoaisGerarPropostaEnum.NOME}
          onChange={handleInputChange}
          value={formData.nome}
        />
        <MaskDefaultInput
          value={formData.cpf ?? ""}
          type={"CPF"}
          label={"CPF"}
          onChange={(rawValue) => {
            const value =
              typeof rawValue === "string"
                ? rawValue
                : (rawValue as { value?: string })?.value ?? "";

            handleInputChange({
              target: {
                name: DadosPessoaisGerarPropostaEnum.CPF,
                value: value,
              },
            } as React.ChangeEvent<HTMLInputElement>);
          }}
        />
        <MaskDefaultInput
          sx={handleStyleFieldError(
            DadosPessoaisGerarPropostaEnum.CELULAR,
            fieldErros ?? null,
            { width: "100%" }
          )}
          value={formData.celular ?? ""}
          type={"CELULAR"}
          label={"Celular"}
          onChange={(rawValue) => {
            const value =
              typeof rawValue === "string"
                ? rawValue
                : (rawValue as { value?: string })?.value ?? "";

            handleInputChange({
              target: {
                name: DadosPessoaisGerarPropostaEnum.CELULAR,
                value: value,
              },
            } as React.ChangeEvent<HTMLInputElement>);
          }}
        />
        <TextField
          label="Email"
          required
          fullWidth
          value={formData.email}
          name={DadosPessoaisGerarPropostaEnum.EMAIL}
          onChange={handleInputChange}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          value={formData.captadorNome ?? ""}
          label="Parceiro"
          required
          fullWidth
        />
        <Autocomplete
          options={cooperativaList}
          fullWidth
          value={
            cooperativaList.find((x) => formData.cooperativaId === x.id) ?? null
          }
          noOptionsText="Nenhuma cooperativa encontrada"
          getOptionLabel={(option) => option.nome}
          sx={{
            ...handleStyleFieldError(
              DadosPessoaisGerarPropostaEnum.COOPERATIVA,
              fieldErros ?? null,
              { width: "100%" }
            ),
          }}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.nome}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Cooperativa" required />
          )}
          defaultValue={null}
          onChange={(_, newValue) => {
            handleInputChange({
              target: {
                name: DadosPessoaisGerarPropostaEnum.COOPERATIVA,
                value: newValue?.id as string,
              },
            } as unknown as unknown as React.ChangeEvent<HTMLInputElement>);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        <TextField
          value={formData.backofficeNome ?? ""}
          label="Backoffice"
          required
          fullWidth
        />
      </D.FWStack>
    </RegisterCard>
  );
};

export default DadosPessoaisEditarProposta;
