import React, { useEffect, useRef, useState } from "react";
import * as D from "../../styles/appComponents";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import { Box, ListItemText, Typography } from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';

interface ButtonFileProps {
    multiple?: boolean;
    onFileChange?: (files: File) => void;
    acceptTypes?: string;
    setTitle?: string;
    value?: string | null | undefined | File;
}

const ButtonFile: React.FC<ButtonFileProps> = (props: ButtonFileProps) => {
    const [selectedFiles, setSelectedFiles] = useState<File>()
    const { multiple, onFileChange, acceptTypes, setTitle } = props;
    const inputRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

    const handleFileChange: any = (event: React.ChangeEvent<HTMLInputElement>): any => {
        const files: FileList | null = event.target.files;
        if (files) {
            const fileArray: File[] | File = Array.from(files);
            setSelectedFiles(fileArray[0]);

            if (fileArray[0]) {
                if (onFileChange) {
                    onFileChange(fileArray[0]);
                }
            }
        }
    };

    const handleUploadClick: () => void = (): void => {
        inputRef.current?.click();
    };

    useEffect(() => {
        if (setTitle != undefined)
            setSelectedFiles({ name: setTitle ?? "Nenhum selecionado" } as File)
    }, [setTitle])

    return (
        <div>
            <D.VisuallyHiddenInput
                ref={inputRef}
                type="file"
                multiple={multiple}
                accept={acceptTypes ?? "image/*"}
                onChange={handleFileChange}
            />
            <D.ContainedButton startIcon={<FileUploadIcon />} $color={"blue"} onClick={handleUploadClick}
                sx={{ marginTop: 3 }}>
                Anexar documento
            </D.ContainedButton>

            <Box>
                {selectedFiles || props?.value && typeof props?.value === "string" ? (
                    <List sx={{ padding: 0, margin: 0 }}>
                        <ListItemText sx={{ padding: 0, margin: 0 }}>
                            <Typography
                                sx={{
                                    overflow: "hidden",
                                    padding: 0,
                                    margin: 0,
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    display: "block",
                                    maxWidth: "220px",
                                }}
                            >
                                {props?.value && typeof props?.value === "string"
                                    ? props?.value
                                    : selectedFiles?.name}
                            </Typography>
                        </ListItemText>
                    </List>
                ) :
                    (
                        <Typography color="textSecondary">
                            Nenhum selecionado
                        </Typography>
                    )}
            </Box>
        </div>
    );
};

export default ButtonFile;
