import { InputTypes } from "../types/InputType";

export enum EnumTipoChavePix {
  CPF = "CPF",
  CNPJ = "CNPJ",
  email = "Email",
  celular = "Celular",
  aleatoria = "Aleatória",
}

export const TipoChavePixNumberMap: {
  [key: number]: EnumTipoChavePix;
} = {
  0: EnumTipoChavePix.CPF,
  1: EnumTipoChavePix.CNPJ,
  2: EnumTipoChavePix.email,
  3: EnumTipoChavePix.celular,
  4: EnumTipoChavePix.aleatoria,
};

export const EnumTipoChavePixNumerciMap: Record<EnumTipoChavePix, number> = {
  [EnumTipoChavePix.CPF]: 0,
  [EnumTipoChavePix.CNPJ]: 1,
  [EnumTipoChavePix.email]: 2,
  [EnumTipoChavePix.celular]: 3,
  [EnumTipoChavePix.aleatoria]: 4,
};

export const MapEnumTipoChavePixParaInputTypes: Partial<
  Record<EnumTipoChavePix, InputTypes>
> = {
  [EnumTipoChavePix.CPF]: "CPF",
  [EnumTipoChavePix.CNPJ]: "CNPJ",
  [EnumTipoChavePix.celular]: "CELULAR",
  [EnumTipoChavePix.aleatoria]: "ALEATORIA",
};
