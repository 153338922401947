export enum DistribuidoraEnum {
  ID = "id",
  NOME = "nome",
  NOMEFANTASIA = "nomeFantasia",
  CNPJ = "cnpj",
  ATIVO = "ativo",
}

export enum EnumStatusDistribuidora {
  inativa,
  ativa,
}
