import { EnumTipoChavePix } from "../../enums/EnumTipoChavePix.enum";
import {
  IUpdateDadosContrato,
  IUpdateDadosDeposito,
  IUpdateDadosPix,
} from "../../models/DadosBancario";
import { AbstractValidation, Validate } from "../../utils/AbstractValidation";
import {
  ValidacaoResultado,
  validarChaveAleatoriaPix,
  validarCNPJ,
  validarCPF,
  validarEmail,
} from "../../utils/Utils";

export class UpdateDadosContratoValidate extends AbstractValidation<IUpdateDadosContrato> {
  getValidate(): Validate<IUpdateDadosContrato>[] {
    return [
      {
        field: "percentualComissao",
        validate(
          value: IUpdateDadosContrato["percentualComissao"],
          _model,
          addError: (message: string) => void
        ) {
          if (value < 0) addError("O campo não pode ser negativo");
        },
      },
      {
        field: "vigenciaContrato",
        validate(
          value: IUpdateDadosContrato["vigenciaContrato"],
          _model,
          addError: (message: string) => void
        ) {
          if (value != null && isNaN(value.getTime()))
            addError("Data inválida");
        },
      },
    ];
  }
}

export class UpdateDadosDepositoValidate extends AbstractValidation<IUpdateDadosDeposito> {
  getValidate(): Validate<IUpdateDadosDeposito>[] {
    return [
      /*
      {
        field: "banco",
        validate(
          value: IUpdateDadosDeposito["banco"],
          _model,
          addError: (message: string) => void
        ) {
          if (value.trim().length === 0) addError("O campo é obrigatorio");
        },
      },
      {
        field: "agencia",
        validate(
          value: IUpdateDadosDeposito["agencia"],
          _model,
          addError: (message: string) => void
        ) {
          if (value.trim().length === 0) addError("O campo é obrigatorio");
        },
      },
      {
        field: "conta",
        validate(
          value: IUpdateDadosDeposito["conta"],
          _model,
          addError: (message: string) => void
        ) {
          if (value.trim().length === 0) addError("O campo é obrigatorio");
        },
      },
      {
        field: "cnpjFavorecido",
        validate(
          value: IUpdateDadosDeposito["cnpjFavorecido"],
          _model,
          addError: (message: string) => void
        ) {
          if (value.trim().length === 0) addError("O campo é obrigatorio");
        },
      },
      {
        field: "cnpjFavorecido",
        validate(
          value: IUpdateDadosDeposito["cnpjFavorecido"],
          _model,
          addError: (message: string) => void
        ) {
          const validacao = validarCNPJ(value);
          if (!validacao.sucesso) addError(validacao.mensagem ?? "");
        },
      },
      {
        field: "favorecido",
        validate(
          value: IUpdateDadosDeposito["favorecido"],
          _model,
          addError: (message: string) => void
        ) {
          if (value.trim().length === 0) addError("O campo é obrigatorio");
        },
      }, */
    ];
  }
}

export class UpdateDadosPixValidate extends AbstractValidation<IUpdateDadosPix> {
  getValidate(): Validate<IUpdateDadosPix>[] {
    return [
      {
        field: "chavePix",
        validate(
          value: IUpdateDadosPix["chavePix"],
          model,
          addError: (message: string) => void
        ) {
          let validacao: ValidacaoResultado | null = null;
          if (model.tipoChavePix !== null) {
            if (value.trim().length === 0) {
              addError("O campo é obrigatorio");
            } else if (model.tipoChavePix === EnumTipoChavePix.CNPJ) {
              validacao = validarCNPJ(value);
            } else if (model.tipoChavePix === EnumTipoChavePix.CPF) {
              validacao = validarCPF(value);
            } else if (model.tipoChavePix === EnumTipoChavePix.email) {
              validacao = validarEmail(value);
            } else if (model.tipoChavePix === EnumTipoChavePix.aleatoria) {
              validacao = validarChaveAleatoriaPix(value);
            }
          }
          if (validacao?.sucesso === false) addError(validacao.mensagem ?? "");
        },
      },
    ];
  }
}
