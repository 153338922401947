import { IUpdateResponsavelLegal } from "../../../../models/ResponsavelLegal";
import * as captadorService from "../../../../services/api/CaptadorService";
import * as associadoServices from "../../../../services/api/AssociadoService";
import * as anexoServices from "../../../../services/api/AnexoService";
import * as colaboradorServices from "../../../../services/api/ColaboradorService";

import {
  ICaptador,
  ICaptadorDadosContrato,
  ICaptadorResponsavelUpdateRequest,
} from "../../../../models/Captador";
import ReadonlyArray from "../../../../types/ReadonlyArray";
import { IUpdateResponsavelComunicacao } from "../../../../models/ResponsavelComunicacao";
import { IAssociadoStats } from "../../../../models/Stats";
import {
  EnumEstadoCivil,
  EnumEstadoCivilNumericMap,
} from "../../../../enums/EnumEstadoCivil.enum";
import { ObterEnum } from "../../../../utils/Utils";
import { toastMessage } from "../../../../utils/toastMessage";
import {
  DicLogo,
  FormatoLogo,
  IDadosLogo,
  IUpdateLogoReq,
  LISTA_FORMATO_LOGO,
  UpdateDetailsLogo,
} from "../../../../models/Logo";
import { handleError } from "../../../../utils/handleError";
import { IAnexoDTO } from "../../../../models/Anexo";
import {
  IUpdateDadosAcesso,
  IUpdateDadosAcessoRequest,
} from "../../../../models/Colaborador/cadastros/DadosAcesso";
import {
  EnumCupomBoasVindas,
  EnumCupomBoasVindasNumericMap,
} from "../../../../enums/EnumCupomBoasVindas.enum";
import {
  IUpdateDadosContrato,
  IUpdateDadosDeposito,
  IUpdateDadosDepositoRequest,
  IUpdateDadosPix,
} from "../../../../models/DadosBancario";
import {
  EnumTipoChavePix,
  EnumTipoChavePixNumerciMap,
} from "../../../../enums/EnumTipoChavePix.enum";
import { EnumConvenio } from "../../../../enums/EnumConvenio.enum";
import { IAssociado } from "../../../../models/Associado";
import { numberForExtensive } from "../../../../utils/moneyFormatter";
import { EnumTipoParceiro } from "../../../../enums/EnumTipoParceiro.enum";
import { EnumStatusContratoParceiro } from "../../../../enums/EnumStatusContratoParceiro.enum";

class AssociadoManager {
  async updateResponsavelLegal(
    captadorId: string,
    responsaveisLegais: ReadonlyArray<IUpdateResponsavelLegal>,
    responsavelComunicacao: IUpdateResponsavelComunicacao
  ): Promise<boolean> {
    const req: ICaptadorResponsavelUpdateRequest = {
      captadorId,
      responsaveisLegais: responsaveisLegais.map((x) => ({
        ...x,
        dataNascimento: x.dataNascimento?.toISOString() ?? null,
        estadoCivil:
          x.enumEstadoCivil && EnumEstadoCivilNumericMap[x.enumEstadoCivil],
      })),
      responsavelComunicacao,
    };

    try {
      await captadorService.UpdateResponsavelLegal(req);
      toastMessage("success", "Dados salvo com sucesso!");
      return true;
    } catch (error) {
      handleError(error, "Houve um erro ao atualizar os daods");
      return false;
    }
  }

  async updateAssociadoDadosContrato(
    updateDadosContrato: ICaptadorDadosContrato
  ): Promise<boolean> {
    try {
      await captadorService.UpdateAssociadoDadosContrato(updateDadosContrato);
      toastMessage("success", "Dados salvo com sucesso!");
      return true;
    } catch (error) {
      handleError(error, "Houve um erro ao atualizar os daods");
      return false;
    }
  }

  async getCaptadorById(captadorId: string): Promise<ICaptador | null> {
    try {
      const { data } = await captadorService.GetById(captadorId);
      return {
        ...data,
        responsaveisLegais: data.responsaveisLegais.map((x) => ({
          ...x,
          dataNascimento: x.dataNascimento ? new Date(x.dataNascimento) : null,
          enumEstadoCivil: ObterEnum(EnumEstadoCivil, x.enumEstadoCivil),
        })),
        cupomBoasVindas: ObterEnum(EnumCupomBoasVindas, data.cupomBoasVindas),
        tipoChavePix: ObterEnum(EnumTipoChavePix, data.tipoChavePix),
        enumConvenio: ObterEnum(EnumConvenio, data.enumConvenio),
        tipoParceiro:
          ObterEnum(EnumTipoParceiro, data.tipoParceiro) ??
          EnumTipoParceiro.associado,
        statusContrato: ObterEnum(
          EnumStatusContratoParceiro,
          data.statusContrato
        ),
      };
    } catch (error) {
      handleError(error, "Houve um erro ao pegar os dados.");
      return null;
    }
  }

  async getAssociadoStatus(): Promise<IAssociadoStats | null> {
    try {
      const { data } = await associadoServices.GetStats();
      return data;
    } catch (error) {
      handleError(error, "Houve um erro ao pegar os dados.");
      return null;
    }
  }

  async updateCaptadorLogo(captadorId: string, dicLogo: DicLogo) {
    const updateDicLogo = Object.fromEntries(
      LISTA_FORMATO_LOGO.map((formato: FormatoLogo) => {
        const detalhes = dicLogo[formato];
        if (detalhes && "id" in detalhes) {
          return [formato, { id: detalhes.id }];
        } else {
          return [formato, detalhes];
        }
      })
    ) as Record<FormatoLogo, UpdateDetailsLogo>;

    const req: IUpdateLogoReq = { captadorId, dicLogo: updateDicLogo };

    try {
      const { data } = await captadorService.UpdateCaptadorLogo(req);
      toastMessage("success", "Dados salvo com sucesso!");
      return Object.fromEntries(
        LISTA_FORMATO_LOGO.map((formato) => [
          formato,
          this.converterAnexoDtoEmIDadosLogo(data[formato]),
        ])
      ) as Record<FormatoLogo, IDadosLogo | null>;
    } catch (error) {
      handleError(error, "Houve um erro ao atualizar os daods");
      return null;
    }
  }

  async getLogo(captadorId: string) {
    try {
      const { data } = await captadorService.GetLogo(captadorId);
      return Object.fromEntries(
        LISTA_FORMATO_LOGO.map((formato) => [
          formato,
          this.converterAnexoDtoEmIDadosLogo(data[formato]),
        ])
      ) as Record<FormatoLogo, IDadosLogo | null>;
    } catch (error) {
      handleError(error, "Houve um erro ao pegar os dados.");
      return null;
    }
  }

  converterAnexoDtoEmIDadosLogo(anexo: IAnexoDTO | null): IDadosLogo | null {
    if (!anexo) return null;
    return {
      id: anexo.id ?? "",
      descricao: anexo.descricao ?? "",
      dataUpload: new Date(anexo.createdAt),
      usuarioAnexoNome: anexo.nomeResponsavel ?? "",
    };
  }

  async dowloadAnexo(anexoId: string) {
    try {
      const { data } = await anexoServices.GetUrl(anexoId);
      return data;
    } catch (error) {
      handleError(error, "Erro ao baixar o arquivo");
      return null;
    }
  }

  async updateDadosAcesso(captadorId: string, dadosAcesso: IUpdateDadosAcesso) {
    const req: IUpdateDadosAcessoRequest = {
      ...dadosAcesso,
      captadorId,
      cupomBoasVindas:
        dadosAcesso.cupomBoasVindas &&
        EnumCupomBoasVindasNumericMap[dadosAcesso.cupomBoasVindas],
    };
    try {
      await captadorService.UpdateDadosAcesso(req);
      toastMessage("success", "Dados salvo com sucesso!");
      return true;
    } catch (error) {
      handleError(error, "Houve um erro ao atualizar os daods");
      return false;
    }
  }

  async updateDadosPagamento(
    captadorId: string,
    dadosDeposito: IUpdateDadosDeposito,
    dadosPix: IUpdateDadosPix,
    dadosContrato: IUpdateDadosContrato
  ) {
    const req: IUpdateDadosDepositoRequest = {
      captadorId,
      dadosDeposito,
      dadosPix: {
        ...dadosPix,
        tipoChavePix:
          dadosPix.tipoChavePix &&
          EnumTipoChavePixNumerciMap[dadosPix.tipoChavePix],
      },
      dadosContrato: {
        ...dadosContrato,
        comissaoPorExtenso: numberForExtensive(
          dadosContrato.percentualComissao
        ),
        vigenciaContrato:
          dadosContrato.vigenciaContrato === null
            ? null
            : dadosContrato.vigenciaContrato.toISOString(),
      },
    };
    try {
      await captadorService.UpdateDadosPagamento(req);
      toastMessage("success", "Dados salvo com sucesso!");
      return true;
    } catch (error) {
      handleError(error, "Houve um erro ao atualizar os daods");
      return false;
    }
  }

  async getBackoffices() {
    try {
      const { data } = await colaboradorServices.GetBackoffices();
      return data;
    } catch (error) {
      handleError(error, "Houve um erro ao pegar os backoffices.");
      return [];
    }
  }

  async getAssociado(id: string) {
    try {
      const { data } = await associadoServices.GetById(id);
      const res: IAssociado = {
        ...data,
        dadosBancario: {
          ...data.dadosBancario,
          dadosPix: {
            ...data.dadosBancario.dadosPix,
            tipoChavePix: ObterEnum(
              EnumTipoChavePix,
              data.dadosBancario.dadosPix.tipoChavePix
            )!,
          },
        },
      };

      return res;
    } catch (error) {
      handleError(error, "Houve um erro ao pegar os dados.");
      return null;
    }
  }

  async getCaptadores() {
    try {
      const { data } = await captadorService.GetCaptadores();
      return data;
    } catch (error) {
      handleError(error, "Houve um erro ao pegar os dados.");
      return [];
    }
  }
}

const associadoManager = new AssociadoManager();
export default associadoManager;
