import React, {useEffect, useState} from 'react';
import RegisterCard from "../../../../../components/RegisterCard";
import * as D from "../../../../../styles/appComponents";
import {TextField} from "@mui/material";

export interface IDetalhesPagamento {
  juros: string | null;
  multa: string | null;
  valorPago: string | null;
  vencimentoDetalhesPagamento: string | null;
}

export enum DetalhesPagamentoEnum {
  JUROS = 'juros',
  MULTA = 'multa',
  VALOR_PAGO = 'valorPago',
  VENCIMENTO_DETALHES_PAGAMENTO = 'vencimentoDetalhesPagamento'
}

type DetalhesPagamentoProps = {
  callback: (dadosPagador: IDetalhesPagamento) => void;
}

const DadosPagador: (props: DetalhesPagamentoProps) => React.JSX.Element = (props: DetalhesPagamentoProps): React.JSX.Element => {
  //region Variáveis
  const {callback} = props;
  const [dadosPagador, setDadosPagador] = useState<IDetalhesPagamento>({
    juros: null,
    multa: null,
    valorPago: null,
    vencimentoDetalhesPagamento: null
  });
  //endregion

  //region UI
  const handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setDadosPagador((state: IDetalhesPagamento) => {
      return {
        ...state,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    callback(dadosPagador);
  }, [dadosPagador]);
  //endregion

  return (
    <>
      <RegisterCard title="Detalhes do pagamento">
        <D.FWStack direction={"row"} spacing={2}>
          <TextField
            label="Juros"
            required
            fullWidth
            name={DetalhesPagamentoEnum.JUROS}
            value={dadosPagador.juros}
            onChange={handleInputChange}
          />
          <TextField
            label="Multa"
            required
            fullWidth
            name={DetalhesPagamentoEnum.MULTA}
            value={dadosPagador.multa}
            onChange={handleInputChange}
          />
          <TextField
            label="Valor pago"
            required
            fullWidth
            name={DetalhesPagamentoEnum.VALOR_PAGO}
            value={dadosPagador.valorPago}
            onChange={handleInputChange}
          />
          <TextField
            label="Vencimento"
            required
            fullWidth
            name={DetalhesPagamentoEnum.VENCIMENTO_DETALHES_PAGAMENTO}
            value={dadosPagador.vencimentoDetalhesPagamento}
            onChange={handleInputChange}
          />
        </D.FWStack>
      </RegisterCard>
    </>
  );
};

export default DadosPagador;