import { FC, RefObject, useRef, useState } from "react";
import RegisterCardButton from "../RegisterCardButton/RegisterCardButton";
import {
  DefaultTable,
  GridField,
  OutlineButton,
} from "../../styles/appComponents";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArquivoVazio from "../../assets/Icones/arquivoVazio.svg";
import ArquivoAnexado from "../../assets/Icones/arquivoAnexado.svg";
import * as C from "./style";
import { ptBR } from "@mui/x-data-grid/locales";
import {
  DicLogo,
  FormatoLogo,
  ICreateLogo,
  IDadosLogo,
  LISTA_FORMATO_LOGO,
} from "../../models/Logo";
import { TIPOS_VALIDOS_INPUT_LOGO } from "../../pages/private/Associados/Constants";
import { toastMessage } from "../../utils/toastMessage";
import { FormatDate } from "../../utils/dateFormatter";
import { useAuth } from "../../hooks/useAuth";
import { dowloadUrl, downloadFile } from "../../utils/Utils";
import { ArchiveArea } from "../ArchiveArea/ArchiveArea";
import { Box, Stack } from "@mui/material";

interface ILogoCardInputProps {
  title: string;
  value: DicLogo;
  updateCaptadorLogo: (dicLogo: DicLogo) => Promise<unknown>;
  dowloadAnexo: (idAnexo: string) => Promise<string | null>;
  disabled?: boolean;
}

const LogoCardInput: FC<ILogoCardInputProps> = ({
  title,
  updateCaptadorLogo,
  value,
  dowloadAnexo,
  disabled = false,
}) => {
  const [dicLogo, setDicLogo] = useState<DicLogo>(value);
  const [editar, setEditar] = useState<boolean>(false);
  const [salvandoDados, setSalvandoDados] = useState<boolean>(false);
  const { user } = useAuth();

  const fileInputRefs: Record<FormatoLogo, RefObject<HTMLInputElement>> = {
    svg: useRef<HTMLInputElement>(null),
    png: useRef<HTMLInputElement>(null),
    pdf: useRef<HTMLInputElement>(null),
  };

  const handleButtonClick = (formatoLogo: FormatoLogo) => {
    if (editar) fileInputRefs[formatoLogo].current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    formatoLogo: FormatoLogo
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.type === TIPOS_VALIDOS_INPUT_LOGO[formatoLogo]) {
        if (user) {
          const novaLogo: ICreateLogo = {
            arquivo: file,
            descricao: `Logo ${formatoLogo.toUpperCase()}`,
            usuarioAnexouId: user.idUsuario,
            usuarioAnexoNome: user.nome,
          };
          setDicLogo({
            ...dicLogo,
            [formatoLogo]: novaLogo,
          });
        }
      } else {
        toastMessage("error", "Formato invalido");
      }
    }
  };

  const handleDownload = async (formatoLogo: FormatoLogo) => {
    const logo = dicLogo[formatoLogo];
    if (logo === null) return;

    if ("arquivo" in logo) {
      downloadFile(logo.arquivo);
    } else if ("id" in logo) {
      const link = await dowloadAnexo(logo.id);
      if (link) dowloadUrl(link);
    }
  };

  const handleDelete = (formatoLogo: FormatoLogo) => {
    setDicLogo({ ...dicLogo, [formatoLogo]: null });
  };

  const tabelaValores = Object.entries(dicLogo)
    .filter(([_, valor]) => valor)
    .map(([chave, valor]) => ({
      formato: chave as FormatoLogo,
      valor: valor as ICreateLogo | IDadosLogo,
    }))
    .map((x, index) => ({
      id: index,
      index,
      formato: x.formato,
      idLogo: "id" in x.valor ? x.valor.id : null,
      descricao: x.valor.descricao,
      usuarioAnexoNome: x.valor.usuarioAnexoNome,
      dataUpload:
        "dataUpload" in x.valor
          ? FormatDate(x.valor.dataUpload, "DD/MM/YYYY")
          : "-",
    }));

  type TypeTabelaValores = (typeof tabelaValores)[number];

  const tabelaColuna: GridColDef[] = [
    {
      field: "usuarioAnexoNome",
      headerName: "Responsável",
      flex: 1,
    },
    { field: "descricao", headerName: "Decrição", flex: 1 },
    {
      field: "dataUpload",
      headerName: "Data Upload",
      align: "center",
      flex: 1,
    },
    {
      field: "visualizar",
      headerName: "Visualizar",
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridCellParams<TypeTabelaValores>) => (
        <GridField>
          <OutlineButton
            sx={{ fontSize: "10px" }}
            startIcon={<VisibilityIcon />}
            $color="pink"
            disabled={params.row.idLogo === null || true}
          >
            Abrir
          </OutlineButton>
        </GridField>
      ),
    },
    {
      field: "anexo",
      headerName: "Anexo",
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridCellParams<TypeTabelaValores>) => (
        <GridField>
          <OutlineButton
            sx={{ fontSize: "10px" }}
            startIcon={<DownloadIcon />}
            $color="blue"
            onClick={() => handleDownload(params.row.formato)}
          >
            Baixar
          </OutlineButton>
        </GridField>
      ),
    },
    {
      field: "remover",
      headerName: "Remover",
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridCellParams<TypeTabelaValores>) => (
        <GridField>
          <OutlineButton
            sx={{ fontSize: "10px" }}
            startIcon={<DeleteIcon />}
            $color="red"
            onClick={() => handleDelete(params.row.formato)}
            disabled={!editar || disabled}
          >
            Deletar
          </OutlineButton>
        </GridField>
      ),
    },
  ];

  const handleSalvar = async () => {
    setSalvandoDados(true);
    setEditar(false);
    await updateCaptadorLogo(dicLogo);
    setSalvandoDados(false);
  };

  return (
    <RegisterCardButton
      title={title}
      desativarBotaoSalvar={!editar || salvandoDados || disabled}
      desativarBotaoEditar={editar || salvandoDados || disabled}
      onClickEditar={() => setEditar(true)}
      onClickSalvar={handleSalvar}
    >
      <Stack width="100%" direction={"row"} spacing={8}>
        <Box display="flex" flexDirection="column" width="50%" gap="20px">
          <Box display="flex" fontSize="14px" gap="13px" alignItems="center">
            <FileUploadIcon />
            <h2>Upload de arquivos</h2>
          </Box>
          <Stack width="100%" direction={"row"} spacing={2}>
            {LISTA_FORMATO_LOGO.map((formatoLogo) => (
              <Stack
                width="100%"
                key={formatoLogo}
                direction={"column"}
                spacing={2}
                textAlign={"center"}
              >
                <ArchiveArea
                  disabled={!editar || disabled}
                  onClick={() => handleButtonClick(formatoLogo)}
                >
                  <img
                    src={dicLogo[formatoLogo] ? ArquivoAnexado : ArquivoVazio}
                    alt="anexado"
                  />
                  <p>{formatoLogo.toUpperCase()}</p>
                  <input
                    type="file"
                    accept={`.${formatoLogo}`}
                    ref={fileInputRefs[formatoLogo]}
                    onChange={(event) => handleFileChange(event, formatoLogo)}
                    style={{ display: "none" }}
                  />
                </ArchiveArea>
                <p>{dicLogo[formatoLogo] ? "Logo anexada" : "Anexar logo"}</p>
              </Stack>
            ))}
          </Stack>
        </Box>
        <C.MWDiv>
          <C.LightBWArea minHeight="128px">
            {Boolean(tabelaColuna.length) && (
              <DefaultTable
                disableColumnResize
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={tabelaValores}
                columns={tabelaColuna}
                hideFooter
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
              />
            )}
          </C.LightBWArea>
        </C.MWDiv>
      </Stack>
    </RegisterCardButton>
  );
};

export default LogoCardInput;
