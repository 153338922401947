import { Autocomplete, MenuItem, TextField } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import RegisterCard from "../../../../../components/RegisterCard";
import {
  EnumGatewayDePagamento,
  EnumGatewayDePagamentoNumericMap,
} from "../../../../../enums/EnumGatewayDePagamento.enum";
import {
  EnumPreferenciaRecebimento,
  EnumPreferenciaRecebimentoNumericMap,
} from "../../../../../enums/EnumPreferenciaRecebimento.enum";
import {
  EnumStatusContratoClienteMapSelect,
  EnumStatusGDContratoNumericMap,
  StatusContratoColorMap,
} from "../../../../../enums/EnumStatusContratoCliente.enum";
import { useObjectState } from "../../../../../hooks/useObjectState";
import { ICaptadorListItem } from "../../../../../models/Captador";
import { IBackofficeListItem } from "../../../../../models/Colaborador/Colaborador";
import { IFormInformacoesOutrasInformacoes } from "../../../../../models/Contratar";
import { IDistribuidoraListItem } from "../../../../../models/Distribuidora";
import {
  IOutrasInformacoes,
  IUpdateOutrasInformacoes,
} from "../../../../../models/GDContrato";
import { IUsinaListItem } from "../../../../../models/Gerador/Usina";
import * as captadorServices from "../../../../../services/api/CaptadorService";
import * as colaboradorServices from "../../../../../services/api/ColaboradorService";
import * as distribuidoraService from "../../../../../services/api/DistribuidoraService";
import * as service from "../../../../../services/api/GDContratoClienteService";
import * as propostaService from "../../../../../services/api/PropostaService";
import * as geradorService from "../../../../../services/api/GeradorService";
import * as D from "../../../../../styles/appComponents";
import { convertEmptyStringsToNullAsync } from "../../../../../utils/convertEmptyStringsToNull";
import { FormatDate } from "../../../../../utils/dateFormatter";
import { IFullGDProposta } from "../../../../../models/GDProposta";
import { toastMessage } from "../../../../../utils/toastMessage";

type PropsOutrasInformacoesContratar = {
  id?: string;
  dataContrato?: IOutrasInformacoes;
  edit: boolean;
  callback?: (data: IUpdateOutrasInformacoes) => void;
  idProposta?: string;
};

const OutrasInformacoesContratar = (props: PropsOutrasInformacoesContratar) => {
  //region Variáveis
  const { id, dataContrato } = props;
  const [editar, setEditar] = useState<boolean>(false);
  const [backoffices, setBackoffices] = useState<IBackofficeListItem[]>([]);
  const [captadores, setCaptadores] = useState<ICaptadorListItem[]>([]);
  const [distribuidoraList, setDistribuidoraList] = useState<
    IDistribuidoraListItem[]
  >([]);
  const [usinaList, setUsinaList] = useState<IUsinaListItem[]>([]);
  const { state, setObject, updateObject, resetObject } =
    useObjectState<IFormInformacoesOutrasInformacoes>({
      usina: "",
      distribuidora: "",
      comissaoCaptador: "",
      gatewayPagamento: "0",
      limiteInjecao: "",
      prefRecebido: "",
      parceiro: "",
      apoioParceiro: "",
      backofficeId: "",
      dataAssinatura: "",
      assinaturaDigitalId: "",
      status: "",
    });
  //endregion

  //region Services
  const getBackoffices: () => Promise<void> = async (): Promise<void> => {
    try {
      const response: AxiosResponse<IBackofficeListItem[]> =
        await colaboradorServices.GetBackoffices();
      setBackoffices(response.data);
    } catch (e) {
      var errorMessage: string = "Houve um erro ao pegar os backoffices.";

      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: errorMessage,
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
    }
  };

  const getCaptadores: () => Promise<void> = async (): Promise<void> => {
    try {
      const response: AxiosResponse<IBackofficeListItem[]> =
        await captadorServices.GetCaptadores();
      setCaptadores(response.data);
    } catch (e) {
      var errorMessage: string = "Houve um erro ao pegar os backoffices.";

      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "warning",
        title: errorMessage,
        showConfirmButton: false,
        showCloseButton: true,
        timer: 3000,
      });
    }
  };

  const getDistribuidoras: () => Promise<void> = async () => {
    await distribuidoraService
      .GetDistribuidoras()
      .then((response) => {
        setDistribuidoraList(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const getUsinas: () => Promise<void> = async () => {
    await geradorService
      .GetUsinasListItem()
      .then((response) => {
        setUsinaList(response.data);
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const Update: () => Promise<void> = async () => {
    var rawData: IUpdateOutrasInformacoes = {
      id: id!,
      apoioParceiro: state.apoioParceiro,
      comissaoCaptador: state.comissaoCaptador,
      dataAssinatura: state.dataAssinatura,
      assinaturaDigitalId: state.assinaturaDigitalId,
      distribuidoraId: state.distribuidora,
      energiaAdicionalkWh: state.energiaAdicionalKWH!,
      energiaAdicionalReais: state.energiaAdicionalReais!,
      gatewayPagamento: Number(state.gatewayPagamento),
      limiteInjecao: state.limiteInjecao,
      preferenciaRecebimento: Number(state.prefRecebido),
      status: Number(state.status) ?? null,
      usinaId: state.usina,
    };

    var data: IUpdateOutrasInformacoes = await convertEmptyStringsToNullAsync(
      rawData
    );

    await service
      .UpdateOutrasInformacoes(data)
      .then(() => {
        setEditar(false);
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Atualizado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  useEffect(() => {
    if (!props.edit) {
      propostaService
        .GetFullPropostaById(props.idProposta!)
        .then(async (response: AxiosResponse<IFullGDProposta>) => {
          updateObject({ backofficeId: response.data.backofficeId! });
          updateObject({ parceiro: response.data.captadorId! });
        })
        .catch((e: AxiosError) => {
          toastMessage(
            "warning",
            e.response
              ? String(e.response?.data)
              : "Houve um erro ao pegar os dados."
          );
        });
    }
  }, [props.edit]);
  //endregion

  //region UI
  const getListsData = async () => {
    await getBackoffices();
    await getCaptadores();
    await getDistribuidoras();
    await getUsinas();
  };

  useEffect((): void => {
    if (dataContrato) {
      getListsData().then((_) => {
        setObject({
          usina: dataContrato.usinaId ?? "",
          distribuidora: dataContrato.distribuidoraId ?? "",
          comissaoCaptador: dataContrato.comissaoCaptador?.toString() ?? "",
          gatewayPagamento: dataContrato.gatewayPagamento?.toString() ?? "0",
          limiteInjecao: dataContrato.limiteInjecao?.toString() ?? "",
          prefRecebido: dataContrato.preferenciaRecebimento?.toString() ?? "",
          parceiro: dataContrato.captadorId ?? "",
          apoioParceiro: dataContrato.apoioParceiro ?? "",
          backofficeId: dataContrato.backofficeId ?? "",
          dataAssinatura:
            dataContrato.dataAssinatura != null
              ? FormatDate(new Date(dataContrato?.dataAssinatura!)) ?? ""
              : "",
          assinaturaDigitalId: dataContrato.assinaturaDigitalId,
          status: dataContrato.status?.toString() ?? "",
        });
      });
    } else {
      getListsData();
    }
  }, [dataContrato]);

  useEffect(() => {
    if (
      Number(state.gatewayPagamento) !=
      EnumGatewayDePagamentoNumericMap[
        EnumGatewayDePagamento.clienteComLimiteDeInjecao
      ]
    ) {
      updateObject({ limiteInjecao: "" });
    }
  }, [state.gatewayPagamento]);

  useEffect(() => {
    if (!props.edit) {
      var data: IUpdateOutrasInformacoes = {
        id: id,
        apoioParceiro: state.apoioParceiro,
        comissaoCaptador: state.comissaoCaptador,
        dataAssinatura: state.dataAssinatura,
        assinaturaDigitalId: state.assinaturaDigitalId,
        distribuidoraId: state.distribuidora,
        energiaAdicionalkWh: state.energiaAdicionalKWH!,
        energiaAdicionalReais: state.energiaAdicionalReais!,
        gatewayPagamento: Number(state.gatewayPagamento),
        limiteInjecao: state.limiteInjecao,
        preferenciaRecebimento: Number(state.prefRecebido),
        status: Number(state.status) ?? null,
        usinaId: state.usina,
      };

      props.callback!(data);
    }
  }, [state]);

  useEffect(() => {
    if (props.edit === false) {
      setEditar(true);
    }
  }, [props.edit]);

  //endregion

  return (
    <RegisterCard title="Outras informações">
      <D.FWStack direction={"row"} spacing={2}>
        <Autocomplete
          options={Object.values(usinaList)}
          getOptionLabel={(option) => option.nome}
          fullWidth
          value={
            Object.values(usinaList).find(
              (usina) => usina.id === state.usina
            ) || null
          }
          onChange={(_, newValue) =>
            updateObject({ usina: newValue ? newValue.id : undefined })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Usina"
              required
              fullWidth
              inputProps={{
                ...params.inputProps,
                readOnly: !editar,
              }}
            />
          )}
          disabled={!editar}
        />
        <TextField
          select
          fullWidth
          label="Status do contrato"
          value={state.status}
          onChange={(e) => updateObject({ status: e.target.value })}
          inputProps={{ readOnly: !editar }}
        >
          {Object.keys(EnumStatusContratoClienteMapSelect).map((key, index) => (
            <MenuItem
              key={key}
              value={
                EnumStatusGDContratoNumericMap[
                  EnumStatusContratoClienteMapSelect[
                    key as keyof typeof EnumStatusContratoClienteMapSelect
                  ]
                ]
              }
            >
              <D.FWStack direction={"row"} spacing={2} alignItems={"center"}>
                <D.Circle
                  color={
                    StatusContratoColorMap[
                      EnumStatusGDContratoNumericMap[
                        EnumStatusContratoClienteMapSelect[
                          key as keyof typeof EnumStatusContratoClienteMapSelect
                        ]
                      ]
                    ]
                  }
                ></D.Circle>
                <span>
                  {
                    EnumStatusContratoClienteMapSelect[
                      key as keyof typeof EnumStatusContratoClienteMapSelect
                    ]
                  }
                </span>
              </D.FWStack>
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Distribuidora"
          select
          required
          fullWidth
          value={state.distribuidora}
          onChange={(e) => updateObject({ distribuidora: e.target.value })}
          inputProps={{ readOnly: !editar }}
        >
          {Object.values(distribuidoraList).map((value, index) => (
            <MenuItem key={index} value={value.id}>
              {value.nome}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Pref recebimento"
          select
          required
          fullWidth
          value={state.prefRecebido}
          inputProps={{ readOnly: !editar }}
          onChange={(e) => updateObject({ prefRecebido: e.target.value })}
        >
          {Object.values(EnumPreferenciaRecebimento).map((value, index) => (
            <MenuItem
              key={value}
              value={EnumPreferenciaRecebimentoNumericMap[value]}
            >
              {value}
            </MenuItem>
          ))}
        </TextField>
      </D.FWStack>

      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Comissão captador"
          required
          fullWidth
          value={state.comissaoCaptador}
          onChange={(e) => updateObject({ comissaoCaptador: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Gateway pagamento"
          required
          fullWidth
          select
          value={state.gatewayPagamento}
          onChange={(e) => updateObject({ gatewayPagamento: e.target.value })}
          inputProps={{ readOnly: !editar }}
        >
          {Object.values(EnumGatewayDePagamento).map((value, index) => (
            <MenuItem
              key={value}
              value={EnumGatewayDePagamentoNumericMap[value]}
            >
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Valor máximo de injeção"
          required
          fullWidth
          value={state.limiteInjecao}
          onChange={(e) => updateObject({ limiteInjecao: e.target.value })}
          inputProps={{ readOnly: state.gatewayPagamento != "2" && !editar }}
          disabled={
            Number(state.gatewayPagamento) !=
            EnumGatewayDePagamentoNumericMap[
              EnumGatewayDePagamento.clienteComLimiteDeInjecao
            ]
          }
        />
        <TextField
          label="Apoio parceiro"
          required
          fullWidth
          value={state.apoioParceiro}
          onChange={(e) => updateObject({ apoioParceiro: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
      </D.FWStack>

      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Parceiro"
          select
          required
          fullWidth
          inputProps={{ readOnly: true }}
          value={state.parceiro}
        >
          {Object.values(captadores).map((value, index) => (
            <MenuItem key={index} value={value.id}>
              {value.nome}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Backoffice"
          select
          required
          fullWidth
          inputProps={{ readOnly: true }}
          value={state.backofficeId}
          onChange={(e) => updateObject({ backofficeId: e.target.value })}
        >
          {Object.values(backoffices).map((value, index) => (
            <MenuItem key={index} value={value.id}>
              {value.nome}
            </MenuItem>
          ))}
        </TextField>
        {dataContrato && (
          <>
            <D.DateTextfield
              type="date"
              label="Data de assinatura"
              fullWidth
              value={state.dataAssinatura}
              onChange={(e) => updateObject({ dataAssinatura: e.target.value })}
              inputProps={{
                readOnly: !editar || dataContrato.dataAssinatura != null,
              }}
            />
            <TextField
              label="Assinatura digital Id"
              fullWidth
              value={state.assinaturaDigitalId}
              onChange={(e) =>
                updateObject({ assinaturaDigitalId: e.target.value })
              }
              inputProps={{
                readOnly: !editar,
              }}
            />
          </>
        )}
      </D.FWStack>
      {dataContrato !== null && (
        <D.FWStack direction={"row"} spacing={2}>
          <TextField
            label="Energia adicional (kWh)"
            required
            value={state.energiaAdicionalKWH}
            fullWidth
            onChange={(e) =>
              updateObject({ energiaAdicionalKWH: e.target.value })
            }
            inputProps={{ readOnly: !editar }}
          />
          <TextField
            label="Energia adicional (R$)"
            required
            fullWidth
            value={state.energiaAdicionalReais}
            onChange={(e) =>
              updateObject({ energiaAdicionalReais: e.target.value })
            }
            inputProps={{ readOnly: !editar }}
          />
          <D.FWStack></D.FWStack>
          <D.FWStack></D.FWStack>
        </D.FWStack>
      )}
      {props.edit && (
        <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton
            $color="yellow"
            disabled={editar}
            onClick={() => setEditar(true)}
          >
            Editar
          </D.ContainedButton>
          <D.ContainedButton disabled={!editar} onClick={Update}>
            Salvar
          </D.ContainedButton>
        </D.FWStack>
      )}
    </RegisterCard>
  );
};

export default OutrasInformacoesContratar;
