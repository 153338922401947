import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {Box, Stack} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import {ptBR} from "@mui/x-data-grid/locales";
import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import * as D from "../../../../styles/appComponents";
import * as C from "../../Gerador/style";
import TotalCard from "../../../../components/TotalCard";
import {AxiosResponse} from "axios";
import {
  GetContas,
  GetStatusConta,
  SetConta,
  UpdateConta,
} from "../../../../services/api/ContaService";
import {toastMessage} from "../../../../utils/toastMessage";
import {formatValueStringInFloat, moneyFormatter} from "../../../../utils/moneyFormatter";
import {EnumMeses} from "../../../../enums/EnumMeses";
import comprovante from "../../../../assets/Icones/comprovante.svg";
import {GetUrl} from "../../../../services/api/AnexoService";
import {
  INovoPagamento,
  IStatusOrdemPagamento,
} from "../../../../models/OrdemPagamento";
import {IContaOrdemPagamento} from "../../../../models/Conta";
import { useAuth } from "../../../../hooks/useAuth";
import { NewPaymentModal } from "../Cadastro/Modais/NewPaymentModal";
import { ContaValidate } from "../Cadastro/Validations/ContaValidate";
import { ErrorMessage } from "../../../../utils/AbstractValidation";
import OrdemPagamento from "..";
import { KWH } from "../../../../utils/masInputs";

const Account: React.FC = () => {
  //region Variáveis
  const {contaId} = useParams();
  const [page]: (number | Dispatch<SetStateAction<number>>)[] = useState(1);
  const [pageNumber] = useState(1);
  const [statusOpen, setStatusOpen] = useState(false);
  const [currentConta, setCurrentConta] = useState<string | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [fieldErros, setFieldErros] = useState<ErrorMessage[] | null>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [statusOrdemPagamento, setStatusOrdemPagamento] =
    useState<IStatusOrdemPagamento>({
      valorTotal: 0,
      valorTotalKwh: 0,
      valorTotalQuitado: 0,
      valorTotalKwhQuitado: 0,
      valorTotalAPagar: 0,
      valorTotalKwhAPagar: 0,
    });
  const [contaOrdemPagamento, setContaOrdemPagamento] =
    useState<IContaOrdemPagamento>({
      id: null,
      idReferencial: null,
      createdAt: null,
      updatedAt: null,
      dataReferencia: null,
      instalacao: null,
      sigla: null,
      kwhInjetado: null,
      descontoTarifa: null,
      valorBase: null,
      valorCredito: null,
      saldoTotal: null,
      energiaInjetada: null,
      energiaConsumida: null,
      saldo: null,
      tarifaBase: null,
      tarifaDistribuidora: null,
      tipoConexao: null,
      dataLimiteQuitacao: null,
      status: null,
      comprovante: null,
      visualizacao: true,
      active: true,
      contas: [],
      usina: {
        id: null,
        idReferencial: null,
        createdAt: null,
        updatedAt: null,
        nomeUsina: null,
        cpf: null,
        instalacao: null,
        sigla: null,
      },
    });
  const [novoPagamento, setNovoPagamento] = useState<INovoPagamento>({
    data: null,
    tipoPagamento: null,
    valor: null,
    energiaTotalGd: null,
    assunto: null,
    descricao: null,
    comprovante: null,
    ordemPagamentoId: null,
    comprovanteNome: null,
    cliente: null,
    comprovanteId: null
  });
  const auth = useAuth();

  const geradorId: string | null = useMemo(() => auth?.user?.idGerador || null, [auth]);
  
  //endregion

  //region Services
  const getStatus: (id: string) => Promise<void> = async (id: string) => {
    try {
      const response: AxiosResponse<IStatusOrdemPagamento, any> =
        await GetStatusConta(id);

      setStatusOrdemPagamento({...response.data});
    } catch (e: unknown) {
      toastMessage("error", "Erro ao tentarmos pegar os status");
    }
  };

  const getContas: (id: string) => Promise<void> = async (id: string) => {
    try {
      const response: AxiosResponse<IContaOrdemPagamento, any> =
        await GetContas(id, 10, pageNumber);

      setContaOrdemPagamento(response.data);
    } catch (e: unknown) {
      toastMessage("error", "Erro ao tentarmos pegar as contas");
    }
  };

  const onSaveNovoPagamento: () => Promise<void> = async () => {
    try {

      const resultValidation = new ContaValidate();
      const errorMessage = resultValidation.errorMessage(novoPagamento);

      if(errorMessage){
        setFieldErros(errorMessage);

        errorMessage.forEach((error:ErrorMessage) => {
          toastMessage("warning", error?.message)
        })

        return;
      }

      const data = {
        ...novoPagamento, 
        valor:formatValueStringInFloat(novoPagamento?.valor as string)
      }

      const response:
        | AxiosResponse<INovoPagamento, any>
        | Promise<AxiosResponse<INovoPagamento, any>> = !currentConta
        ? await SetConta(data)
        : await UpdateConta(data);

      if (response) {
        setOpenModal(false);
        toastMessage(
          "success",
          !currentConta
            ? "Conta inserida com sucesso"
            : "Conta alterada com sucesso"
        );

        setCurrentConta(currentConta);

        if (typeof contaId === "string") {
          setTimeout(() => {
            Promise.all([getStatus(contaId), getContas(contaId)]);
          }, 500);
        }
      }
    } catch (e: unknown) {
      toastMessage(
        "error",
        !currentConta
          ? "Erro após inserir a conta"
          : "Erro após atualizar a conta"
      );
    }
  };

  const findImageByComprovanteId: (id: string) => Promise<void> = async (
    id: string
  ) => {
    try {
      const response: AxiosResponse<string, any> = await GetUrl(id);

      window.open(response.data);
    } catch (e: unknown) {
      toastMessage("error", "Erro ao tentarmos pegar o comprovante");
    }
  };
  //endregion

  //region UI
  useEffect(() => {
    const columnsNoGeradorAccess: GridColDef[] = [
      {
        field: "idReferencial",
        headerName: "ID",
        flex: 1,
      },
      {
        field: "data",
        headerName: "dataReferencia",
        flex: 1,
        renderCell: (params: any) => {
          const date = new Date(params.value);
          return (
            <D.GridField>
              {date.toLocaleDateString()}
            </D.GridField>
          );
        },
      },
      {
        field: "assunto",
        headerName: "Descrição",
        flex: 1,
      },
      {
        field: "valor",
        headerName: "Valor",
        flex: 1,
        renderCell: (params: any) => (
          <D.GridField>
            {moneyFormatter.format(params.value)}
          </D.GridField>
        ),
      },
      {
        field: "comprovanteId",
        headerName: "Comprovante",
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (params: any) => (
          <D.GridField $alignCenter>
            <D.GridImage
              onClick={() => {
                if (![undefined, null].includes(params.value)) {
                  findImageByComprovanteId(params.value);
                }
              }}
              $disable={[undefined, null].includes(params.value)}
              src={comprovante}
              alt="comprovante"
            />
          </D.GridField>
        ),
      },
      {
        field: "outras",
        headerName: "Outras",
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (params: any) => (
          <D.DefaultGridButton onClick={() => handleOpenModal(params?.row?.id)}>
            Editar
          </D.DefaultGridButton>
        ),
      },
    ];

    const columnsGeradorAccess: GridColDef[] = [
      {
        field: "idReferencial",
        headerName: "ID",
        flex: 1,
      },
      {
        field: "data",
        headerName: "Data",
        flex: 1,
        renderCell: (params: any) => {
          const date = new Date(params.value);
          return (
            <D.GridField>
              {date.toLocaleDateString()}
            </D.GridField>
          );
        },
      },
      {
        field: "assunto",
        headerName: "Descrição",
        flex: 1,
      },
      {
        field: "valor",
        headerName: "Valor",
        flex: 1,
        renderCell: (params: any) => (
          <D.GridField>
            {moneyFormatter.format(params.value)}
          </D.GridField>
        ),
      },
      {
        field: "comprovanteId",
        headerName: "Comprovante",
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (params: any) => (
          <D.GridField $alignCenter>
            <D.GridImage
              onClick={() => {
                if (![undefined, null].includes(params.value)) {
                  findImageByComprovanteId(params.value);
                }
              }}
              $disable={[undefined, null].includes(params.value)}
              src={comprovante}
              alt="comprovante"
            />
          </D.GridField>
        ),
      }
    ];

    if (!!geradorId) setColumns(columnsGeradorAccess);
    else setColumns(columnsNoGeradorAccess);
    
  }, [auth])

  const handleOpenModal = (id:string | null) => {
    setOpenModal(true);
    if(id)
     setCurrentConta(id);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentConta(null);

  };

  const onChangeNovoPagamento: (novoPagamento: INovoPagamento) => void = (
    novoPagamento: INovoPagamento
  ) => {
    setNovoPagamento({...novoPagamento});
  };

  const handleStatusOpen: () => void = () => {
    setStatusOpen(!statusOpen);
  };

  useEffect(() => {
    const handleResize: () => void = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (typeof contaId === "string") {
      Promise.all([getStatus(contaId), getContas(contaId)]);
    }
  }, [contaId]);
  //endregion

  return (
    <>
      <C.Container>
        <C.Title onClick={() => handleStatusOpen()}>
          Status
          {isMobile && (
            <>
              {!statusOpen ? (
                <KeyboardArrowDownRoundedIcon/>
              ) : (
                <KeyboardArrowUpIcon/>
              )}
            </>
          )}
        </C.Title>

        {(!isMobile || (statusOpen && isMobile)) && (
          <C.StatusArea>
            <C.StatusWrapper>
              <TotalCard
                color="blue"
                title="Valor total"
                text={moneyFormatter.format(statusOrdemPagamento.valorTotal)}
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="blue"
                title="Valor total de kwh"
                text={`${new KWH().execute(statusOrdemPagamento?.valorTotalKwh as any)} KWH`}
                evolutionNumber="-8%"
                evolution={false}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <TotalCard
                color="green"
                title="Valor quitado"
                text={moneyFormatter.format(
                  statusOrdemPagamento.valorTotalQuitado
                )}
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="green"
                title="Energia quitada"
                text={`${new KWH().execute(statusOrdemPagamento?.valorTotalKwhQuitado as any)} KWH`}
                evolutionNumber="-8%"
                evolution={false}
              />
            </C.StatusWrapper>
            <C.StatusWrapper>
              <TotalCard
                color="yellow"
                title="A pagar"
                text={moneyFormatter.format(
                  statusOrdemPagamento.valorTotalAPagar
                )}
                evolutionNumber="-8%"
                evolution={false}
              />
              <TotalCard
                color="yellow"
                title="Energia a pagar"
                text={`${new KWH().execute(statusOrdemPagamento?.valorTotalKwhAPagar as any)} KWH`}
                evolutionNumber="-8%"
                evolution={false}
              />
            </C.StatusWrapper>
          </C.StatusArea>
        )}
        <>
          <D.DataArea $align="right">
            <Box
              sx={{
                width: "100%",
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Stack
                sx={{
                  width: "50%",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                <Stack sx={{width: "100%"}}>
                  <Stack sx={{fontWeight: "bold"}}>ID</Stack>
                  <Stack>{contaOrdemPagamento.idReferencial}</Stack>
                </Stack>
                <Stack sx={{width: "100%"}}>
                  <Stack sx={{fontWeight: "bold"}}>Período</Stack>
                  <Stack>
                    {" "}
                    {`${
                      EnumMeses[
                        contaOrdemPagamento.dataReferencia
                          ? new Date(
                            contaOrdemPagamento.dataReferencia
                          ).getMonth() + 1
                          : 0
                        ] ?? "Mês"
                    }/${
                      contaOrdemPagamento.dataReferencia
                        ? new Date(
                          contaOrdemPagamento.dataReferencia
                        ).getFullYear()
                        : "Ano"
                    }`}
                  </Stack>
                </Stack>
                <Stack sx={{width: "100%"}}>
                  <Stack sx={{fontWeight: "bold"}}>Número</Stack>
                  <Stack>{contaOrdemPagamento.instalacao}</Stack>
                </Stack>
                <Stack sx={{width: "100%"}}>
                  <Stack sx={{fontWeight: "bold"}}>Usina</Stack>
                  <Stack sx={{whiteSpace: "nowrap"}}>
                    {contaOrdemPagamento.usina.nomeUsina}
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  width: "50%",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                {
                  !(!!geradorId) &&
                  <D.ContainedButton
                    startIcon={<AddCircleOutlineIcon/>}
                    onClick={() => handleOpenModal(null)}
                  >
                    Novo Pagamento
                  </D.ContainedButton>
                }

              </Stack>
            </Box>
            <D.DefaultTable
              disableColumnResize
              disableRowSelectionOnClick
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={contaOrdemPagamento.contas}
              columns={columns}
              hideFooter
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
            />
            <D.DefaultPagination
              page={page}
              count={pageNumber}
              size={"medium"}
              siblingCount={1}
              showFirstButton
              showLastButton
              color="primary"
            />
            <C.LineBreak/>
          </D.DataArea>
        </>
        <NewPaymentModal
          openModal={openModal}
          onCloseModal={handleCloseModal}
          onSave={onSaveNovoPagamento}
          onChange={onChangeNovoPagamento}
          fieldErros={fieldErros}
          id={currentConta}
          ordemPagamento={contaOrdemPagamento}
          statusOrdemPagamento={statusOrdemPagamento}
        />
      </C.Container>
    </>
  );
};

export default Account;
