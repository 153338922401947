import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthProvider } from "../hooks/useAuth";
import LoginLayout from "../layouts/loginLayout";
import LoginCliente from "../pages/public/Cliente/LoginCliente";
import RecuperarSenhaCliente from "../pages/public/Cliente/RecuperarSenhaCliente";
import LoginColaborador from "../pages/public/Colaborador/LoginColaborador";
import RecuperarSenhaColaborador from "../pages/public/Colaborador/RecuperarSenhaColaborador";
import LoginGerador from "../pages/public/Gerador/LoginGerador";
import RecuperarSenhaGerador from "../pages/public/Gerador/RecuperarSenhaGerador";
import Home from "../pages/public/Home";
import AnexarArquivo from "../pages/public/Leads/AnexarArquivo";
import GeraLead from "../pages/public/Leads/GeraLead";
import LoginParceiro from "../pages/public/Parceiro/LoginParceiro";
import RecuperarSenhaParceiro from "../pages/public/Parceiro/RecuperarSenhaParceiro";
import ColaboradorLayout from "../layouts/colaboradorLayout";
import Dashboard from "../pages/private/DashboardColaborador";
import Colaborador from "../pages/private/Colaborador";
import Cliente from "../pages/private/Cliente";
import LayoutNav from "../layouts/layoutNav";
import CadastroCliente from "../pages/private/Cliente/Cadastro";
import CadastroColaborador from "../pages/private/Colaborador/Cadastro";
import ParceiroLayout from "../layouts/parceiroLayout";
import ParceiroDashboard from "../pages/private/DashboardParceiro";
import Leads from "../pages/private/Leads";
import Propostas from "../pages/private/Propostas";
import ContratosClientes from "../pages/private/Contratos";
import ParceiroAssociados from "../pages/private/Associados";
import MeuCadastroParceiro from "../pages/private/MeuCadastro";
import ParceiroLayoutOnlyNav from "../layouts/parceiroLayout/parceiroLayoutOnlyNav";
import NovoAssociado from "../pages/private/Associados/EditarAssociado";
import GerarProposta from "../pages/private/Leads/GerarProposta";
import { PropostaWattwise } from "../pages/private/Proposta-wattwise";
import ParceiroHome from "../pages/public/Parceiro/Home";
import CadastrarParceiro from "../pages/public/Parceiro/CadastrarParceiro";
import EditarProposta from "../pages/private/Propostas/EditarProposta";
import Cooperativa from "../pages/private/Cooperativa";
import ColaboradorLayoutOnlyNav from "../layouts/colaboradorLayout/colaboradorLayoutOnlyNav";
import CadastroCooperativa from "../pages/private/Cooperativa/Cadastro";
import OrdemPagamento from "../pages/private/OrdemPagamento";
import Account from "../pages/private/OrdemPagamento/Conta";
import Gerador from "../pages/private/Gerador";
import CadastroGerador from "../pages/private/Gerador/Cadastro";
import ParceiroComissao from "../pages/private/Comissao";
import EditarContrato from "../pages/private/Contratos/EditarContrato";
import Distribuidora from "../pages/private/Distribuidora";
import TarifaFioB from "../pages/private/TarifaFioB";
import TarifaDistribuidora from "../pages/private/TarifaDistribuidora";
import Parceiro from "../pages/private/Parceiro";
import GeradorLayout from "../layouts/geradorLayout";
import DashboardGerador from "../pages/private/DashboardGerador";
import EditarParceiro from "../pages/private/Parceiro/EditarParceiro";
import Cargo from "../pages/private/Cargo";
import Contratar from "../pages/private/Contratos/Contratar";
import ExtratoPorUsina from "../pages/private/ExtratoPorUsina";
import BuscaFatura from "../pages/private/BuscaFatura";
import EditarFatura from "../pages/private/BuscaFatura/EditarFatura";

const AppRoutes: React.FC = () => (
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        {/* Public */}
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" element={<Home />} />
        <Route path="/Convida/:url" element={<GeraLead />} />
        <Route path="/AnexarConta/:id" element={<AnexarArquivo />} />

        <Route path="/Login/*" element={<LoginLayout />}>
          <Route path="Parceiro" element={<LoginParceiro />} />
          <Route
            path="Parceiro/RecuperarSenha"
            element={<RecuperarSenhaParceiro />}
          />
          <Route path="Cliente" element={<LoginCliente />} />
          <Route
            path="Cliente/RecuperarSenha"
            element={<RecuperarSenhaCliente />}
          />
          <Route path="Colaborador" element={<LoginColaborador />} />
          <Route
            path="Colaborador/RecuperarSenha"
            element={<RecuperarSenhaColaborador />}
          />
          <Route path="Gerador" element={<LoginGerador />} />
          <Route
            path="Gerador/RecuperarSenha"
            element={<RecuperarSenhaGerador />}
          />
        </Route>
        {/*Landing page parceiro */}
        <Route path="/Parceiro" element={<ParceiroHome />} />
        <Route
          path="/Parceiro/CadastrarParceiro"
          element={<CadastrarParceiro />}
        />
        {/* End Public */}

        {/* Colaborador */}
        <Route path="/Colaborador" element={<ColaboradorLayout />}>
          <Route path="/Colaborador" element={<Navigate to="/" />} />
          <Route path="/Colaborador/Dashboard" element={<Dashboard />} />
          <Route path="/Colaborador/ExtratoPorUsina" element={<ExtratoPorUsina />} />
          <Route path="/Colaborador/Lead" element={<Leads />} />
          <Route path="/Colaborador/Proposta" element={<Propostas />} />
          <Route
            path="/Colaborador/Distribuidora"
            element={<Distribuidora />}
          />
          <Route path="/Colaborador/TarifaFioB" element={<TarifaFioB />} />
          <Route
            path="/Colaborador/TarifaDistribuidora"
            element={<TarifaDistribuidora />}
          />
          <Route path="/Colaborador/Cargo" element={<Cargo />} />
          <Route
            path="/Colaborador/TarifaDistribuidora"
            element={<TarifaDistribuidora />}
          />
          <Route
            path="/Colaborador/Comercial/GD/Cooperativa"
            element={<Cooperativa />}
          />
          <Route
            path="/Colaborador/Comercial/GD/Proposta"
            element={<Propostas />}
          />
          <Route
            path="/Colaborador/Comercial/ContratoCliente"
            element={<ContratosClientes />}
          />
          <Route
            path="/Colaborador/Cadastro/GD/Parceiro"
            element={<Parceiro />}
          />
          <Route
            path="/Colaborador/Cadastro/GD/Colaborador"
            element={<Colaborador />}
          />
          <Route
            path="/Colaborador/BuscarFatura"
            element={<BuscaFatura />}
          />
          <Route path="Gerador" element={<Gerador />} />
          <Route
            path="/Colaborador/OrdemPagamento"
            element={<OrdemPagamento />}
          />
        </Route>
        <Route path="/Cadastro" element={<LayoutNav />}>
          <Route path="/Cadastro" element={<Navigate to="/" />} />
          <Route path="/Cadastro/Cliente" element={<CadastroCliente />} />
          <Route path="/Cadastro/Parceiro" element={<Propostas />} />
          <Route path="/Cadastro/Gerador" element={<CadastroGerador />} />
          <Route
            path="/Cadastro/OrdemPagamento/conta/:contaId"
            element={<Account />}
          />
          <Route
            path="/Cadastro/Colaborador"
            element={<CadastroColaborador />}
          />
        </Route>
        <Route path="/Editar" element={<LayoutNav />}>
          <Route path="Gerador/:geradorId" element={<CadastroGerador />} />
          <Route path="BuscarFatura/:faturaId" element={<EditarFatura />} />
          <Route
            path="colaborador/:colaboradorId"
            element={<CadastroColaborador />}
          />
        </Route>
        <Route path="/Colaborador/Editar/*" element={<ParceiroLayoutOnlyNav />}>
          <Route path="Proposta/:idProposta" element={<EditarProposta />} />
          <Route path="Contrato/:idContrato" element={<EditarContrato />} />
        </Route>
        <Route
          path="/Colaborador/Documento/Proposta/:idProposta"
          element={<PropostaWattwise />}
        />
        {/* End Colaborador */}

        {/* Gerador */}
        <Route path="/Gerador" element={<GeradorLayout />}>
          <Route path="Dashboard" element={<DashboardGerador />} />
          <Route path="OrdemPagamento" element={<OrdemPagamento />} />
          <Route path="ContratoGerador" element={<Gerador />} />
        </Route>
        <Route path="/Gerador/View" element={<LayoutNav />}>
          <Route path=":geradorId" element={<CadastroGerador />} />
        </Route>
        {/* End Gerador */}

        {/*Parceiro*/}
        <Route path="/Parceiro/*" element={<ParceiroLayout />}>
          <Route path="Dashboard" element={<ParceiroDashboard />} />
          <Route path="Leads" element={<Leads />} />
          <Route path="Propostas" element={<Propostas />} />
          <Route path="Contratos" element={<ContratosClientes />} />
          <Route path="Associados" element={<ParceiroAssociados />} />
          <Route path="Comissao" element={<ParceiroComissao />} />
          <Route path="MeuCadastro" element={<MeuCadastroParceiro />} />
        </Route>

        <Route path="/Parceiro/Novo/*" element={<ParceiroLayoutOnlyNav />}>
          <Route path="Proposta/:idLead" element={<GerarProposta />} />
          <Route
            path="Contrato/Contratar/:tipo/:idProposta"
            element={<Contratar />}
          />
        </Route>

        <Route path="/Parceiro/Editar/*" element={<ParceiroLayoutOnlyNav />}>
          <Route path="Proposta/:idProposta" element={<EditarProposta />} />
          <Route path="Contrato/:idContrato" element={<EditarContrato />} />
          <Route path="Associado/:associadoId" element={<NovoAssociado />} />
          <Route path="Parceiro/:captadorId?" element={<EditarParceiro />} />
        </Route>

        <Route path="/Colaborador/Novo/*" element={<ParceiroLayoutOnlyNav />}>
          <Route path="Proposta/:idLead" element={<GerarProposta />} />
          <Route
            path="Contrato/Contratar/:tipo/:idProposta"
            element={<Contratar />}
          />
        </Route>

        <Route
          path="/Colaborador/Comercial/GD/Novo"
          element={<ColaboradorLayoutOnlyNav />}
        >
          <Route
            path="/Colaborador/Comercial/GD/Novo/Cooperativa"
            element={<CadastroCooperativa />}
          />
        </Route>
        <Route
          path="/Parceiro/Documento/Proposta/:idProposta"
          element={<PropostaWattwise />}
        />
        {/*End Parceiro*/}
      </Routes>
    </AuthProvider>
  </BrowserRouter>
);

export default AppRoutes;
