import { EnumConvenio } from "../../../../enums/EnumConvenio.enum";
import {
  EnumCupomBoasVindas,
  EnumCupomBoasVindasNumericMap,
} from "../../../../enums/EnumCupomBoasVindas.enum";
import {
  EnumEstadoCivil,
  EnumEstadoCivilNumericMap,
} from "../../../../enums/EnumEstadoCivil.enum";
import { EnumStatusContratoParceiro } from "../../../../enums/EnumStatusContratoParceiro.enum";
import {
  EnumTipoChavePix,
  EnumTipoChavePixNumerciMap,
} from "../../../../enums/EnumTipoChavePix.enum";
import {
  ICaptador,
  ICaptadorDadosContrato,
  ICaptadorResponsavelUpdateRequest,
} from "../../../../models/Captador";
import { CaptadorListItemTabelaPagina } from "../../../../models/CaptadorListItemTabelaPagina";
import { IUpdateResponsavelComunicacao } from "../../../../models/ResponsavelComunicacao";
import { IUpdateResponsavelLegal } from "../../../../models/ResponsavelLegal";
import * as captadorService from "../../../../services/api/CaptadorService";
import * as colaboradorServices from "../../../../services/api/ColaboradorService";
import * as anexoServices from "../../../../services/api/AnexoService";
import * as associadoServices from "../../../../services/api/AssociadoService";
import * as logServices from "../../../../services/api/LogService";
import { handleError } from "../../../../utils/handleError";
import { toastMessage } from "../../../../utils/toastMessage";
import { ObterEnum } from "../../../../utils/Utils";
import {
  DicLogo,
  FormatoLogo,
  IDadosLogo,
  IUpdateLogoReq,
  LISTA_FORMATO_LOGO,
  UpdateDetailsLogo,
} from "../../../../models/Logo";
import { IAnexoDTO } from "../../../../models/Anexo";
import {
  IUpdateDadosContrato,
  IUpdateDadosDeposito,
  IUpdateDadosDepositoRequest,
  IUpdateDadosPix,
} from "../../../../models/DadosBancario";
import { numberForExtensive } from "../../../../utils/moneyFormatter";
import {
  IUpdateDadosAcesso,
  IUpdateDadosAcessoRequest,
} from "../../../../models/Colaborador/cadastros/DadosAcesso";
import { EnumTipoParceiro } from "../../../../enums/EnumTipoParceiro.enum";
import {
  InformacaoAssociado,
  informacaoAssociadoSchema,
} from "../../../../models/InformacaoAssociado";
import { ICreateLog } from "../../../../models/Log";

class ParceiroManager {
  async listarCaptadores(
    pesquisa: string | null,
    pagina: number,
    tamanhoPagina: number
  ): Promise<CaptadorListItemTabelaPagina | null> {
    try {
      const listaCaptador = await captadorService.Listar(
        pesquisa,
        pagina,
        tamanhoPagina
      );
      return listaCaptador;
    } catch (error) {
      handleError(error, "Houve um erro ao obter os captadores");
      return null;
    }
  }

  async obterStatusCaptadores(): Promise<Partial<
    Record<EnumStatusContratoParceiro, number>
  > | null> {
    try {
      const resultado = await captadorService.GetStatus();
      return resultado;
    } catch (error) {
      handleError(error, "Houve um erro ao obter os status");
      return null;
    }
  }

  async getCaptadorById(captadorId: string): Promise<ICaptador | null> {
    try {
      const { data } = await captadorService.GetById(captadorId);
      return {
        ...data,
        responsaveisLegais: data.responsaveisLegais.map((x) => ({
          ...x,
          dataNascimento: x.dataNascimento ? new Date(x.dataNascimento) : null,
          enumEstadoCivil: ObterEnum(EnumEstadoCivil, x.enumEstadoCivil),
        })),
        cupomBoasVindas: ObterEnum(EnumCupomBoasVindas, data.cupomBoasVindas),
        tipoChavePix: ObterEnum(EnumTipoChavePix, data.tipoChavePix),
        enumConvenio: ObterEnum(EnumConvenio, data.enumConvenio),
        tipoParceiro: ObterEnum(EnumTipoParceiro, data.tipoParceiro),
        statusContrato: ObterEnum(
          EnumStatusContratoParceiro,
          data.statusContrato
        ),
      };
    } catch (error) {
      handleError(error, "Houve um erro ao pegar os dados.");
      return null;
    }
  }

  async updateAssociadoDadosContrato(
    updateDadosContrato: ICaptadorDadosContrato
  ): Promise<boolean> {
    try {
      await captadorService.UpdateAssociadoDadosContrato(updateDadosContrato);
      toastMessage("success", "Dados salvo com sucesso!");
      return true;
    } catch (error) {
      handleError(error, "Houve um erro ao atualizar os daods");
      return false;
    }
  }

  async updateResponsavelLegal(
    captadorId: string,
    responsaveisLegais: ReadonlyArray<IUpdateResponsavelLegal>,
    responsavelComunicacao: IUpdateResponsavelComunicacao
  ): Promise<boolean> {
    const req: ICaptadorResponsavelUpdateRequest = {
      captadorId,
      responsaveisLegais: responsaveisLegais.map((x) => ({
        ...x,
        dataNascimento: x.dataNascimento?.toISOString() ?? null,
        estadoCivil:
          x.enumEstadoCivil && EnumEstadoCivilNumericMap[x.enumEstadoCivil],
      })),
      responsavelComunicacao,
    };

    try {
      await captadorService.UpdateResponsavelLegal(req);
      toastMessage("success", "Dados salvo com sucesso!");
      return true;
    } catch (error) {
      handleError(error, "Houve um erro ao atualizar os daods");
      return false;
    }
  }

  async getBackoffices() {
    try {
      const { data } = await colaboradorServices.GetBackoffices();
      return data;
    } catch (error) {
      handleError(error, "Houve um erro ao pegar os backoffices.");
      return [];
    }
  }

  async getCaptadores() {
    try {
      const { data } = await captadorService.GetCaptadores();
      return data;
    } catch (error) {
      handleError(error, "Houve um erro ao pegar os dados.");
      return [];
    }
  }

  async dowloadAnexo(anexoId: string) {
    try {
      const { data } = await anexoServices.GetUrl(anexoId);
      return data;
    } catch (error) {
      handleError(error, "Erro ao baixar o arquivo");
      return null;
    }
  }

  async updateCaptadorLogo(captadorId: string, dicLogo: DicLogo) {
    const updateDicLogo = Object.fromEntries(
      LISTA_FORMATO_LOGO.map((formato: FormatoLogo) => {
        const detalhes = dicLogo[formato];
        if (detalhes && "id" in detalhes) {
          return [formato, { id: detalhes.id }];
        } else {
          return [formato, detalhes];
        }
      })
    ) as Record<FormatoLogo, UpdateDetailsLogo>;

    const req: IUpdateLogoReq = { captadorId, dicLogo: updateDicLogo };

    try {
      const { data } = await captadorService.UpdateCaptadorLogo(req);
      toastMessage("success", "Dados salvo com sucesso!");
      return Object.fromEntries(
        LISTA_FORMATO_LOGO.map((formato) => [
          formato,
          this.converterAnexoDtoEmIDadosLogo(data[formato]),
        ])
      ) as Record<FormatoLogo, IDadosLogo | null>;
    } catch (error) {
      handleError(error, "Houve um erro ao atualizar os daods");
      return null;
    }
  }

  converterAnexoDtoEmIDadosLogo(anexo: IAnexoDTO | null): IDadosLogo | null {
    if (!anexo) return null;
    return {
      id: anexo.id ?? "",
      descricao: anexo.descricao ?? "",
      dataUpload: new Date(anexo.createdAt),
      usuarioAnexoNome: anexo.nomeResponsavel ?? "",
    };
  }

  async getLogo(captadorId: string) {
    try {
      const { data } = await captadorService.GetLogo(captadorId);
      return Object.fromEntries(
        LISTA_FORMATO_LOGO.map((formato) => [
          formato,
          this.converterAnexoDtoEmIDadosLogo(data[formato]),
        ])
      ) as Record<FormatoLogo, IDadosLogo | null>;
    } catch (error) {
      handleError(error, "Houve um erro ao pegar os dados.");
      return null;
    }
  }

  async updateDadosPagamento(
    captadorId: string,
    dadosDeposito: IUpdateDadosDeposito,
    dadosPix: IUpdateDadosPix,
    dadosContrato: IUpdateDadosContrato
  ) {
    const req: IUpdateDadosDepositoRequest = {
      captadorId,
      dadosDeposito,
      dadosPix: {
        ...dadosPix,
        tipoChavePix:
          dadosPix.tipoChavePix &&
          EnumTipoChavePixNumerciMap[dadosPix.tipoChavePix],
      },
      dadosContrato: {
        ...dadosContrato,
        comissaoPorExtenso: numberForExtensive(
          dadosContrato.percentualComissao
        ),
        vigenciaContrato:
          dadosContrato.vigenciaContrato === null
            ? null
            : dadosContrato.vigenciaContrato.toISOString(),
      },
    };
    try {
      await captadorService.UpdateDadosPagamento(req);
      toastMessage("success", "Dados salvo com sucesso!");
      return true;
    } catch (error) {
      handleError(error, "Houve um erro ao atualizar os daods");
      return false;
    }
  }

  async updateDadosAcesso(captadorId: string, dadosAcesso: IUpdateDadosAcesso) {
    const req: IUpdateDadosAcessoRequest = {
      ...dadosAcesso,
      captadorId,
      cupomBoasVindas:
        dadosAcesso.cupomBoasVindas &&
        EnumCupomBoasVindasNumericMap[dadosAcesso.cupomBoasVindas],
    };
    try {
      await captadorService.UpdateDadosAcesso(req);
      toastMessage("success", "Dados salvo com sucesso!");
      return true;
    } catch (error) {
      handleError(error, "Houve um erro ao atualizar os daods");
      return false;
    }
  }

  async getAssociadosByCaptador(
    captadorId: string,
    pageNumber: number,
    pageSize: number,
    search: string
  ) {
    try {
      const { data } = await associadoServices.GetByCaptadorId(
        captadorId,
        pageNumber,
        pageSize,
        search
      );

      return data;
    } catch (error) {
      handleError(error, "Houve um erro ao pegar os dados.");
      return null;
    }
  }

  async createCaptador(req: ICaptadorDadosContrato) {
    try {
      const res = await captadorService.CreateCaptador(req);
      return res.data;
    } catch (error) {
      handleError(error, "Houve um erro ao criar o captador.");
      return null;
    }
  }

  async getCaptadoresFiltro(isMaster?: boolean) {
    try {
      const res = await captadorService.GetCaptadoresFiltro(isMaster);
      return res.data;
    } catch (error) {
      handleError(error, "Houve um erro ao pegar os captadores");
      return [];
    }
  }

  async updateInformacaoAssociado(informacaoAssociado: InformacaoAssociado) {
    try {
      const req = informacaoAssociadoSchema.parse(informacaoAssociado);
      await captadorService.UpdateUpdateInformacaoAssociado(req);
      toastMessage("success", "Dados salvo com sucesso!");
      return true;
    } catch (error) {
      handleError(error, "Houve ao atualizar informaçãos do associado");
      return false;
    }
  }

  async buscarHistorico(captadorId: string) {
    try {
      const { data } = await logServices.GetLogsByItemId(captadorId);
      return data;
    } catch (error) {
      handleError(error, "Houve um erro ao pegar os captadores");
      return [];
    }
  }

  async criarNovoLog(novoLog: ICreateLog) {
    try {
      await logServices.CreateLog(novoLog);
      return true;
    } catch (error) {
      handleError(error, "Houve um erro ao salvar o logo");
      return false;
    }
  }

  async criaContratoParaAssinaturaDigital(captadorId: string) {
    try {
      const { data } = await captadorService.CriaContratoParaAssinaturaDigital(
        captadorId
      );
      toastMessage("success", "Contrato criado com sucesso!");
      return data;
    } catch (error) {
      handleError(error);
      return null;
    }
  }
}

const parceiroManager = new ParceiroManager();
export default parceiroManager;
