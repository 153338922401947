import DownloadIcon from "@mui/icons-material/Download";
import { Stack, TextField } from "@mui/material";
import CircleCheckbox from "../../../../../components/CircleCheckBox";
import * as D from "../../../../../styles/appComponents";
import { EnumStatusContratoParceiro } from "../../../../../enums/EnumStatusContratoParceiro.enum";
import { ICaptador } from "../../../../../models/Captador";
import * as C from "../../style";
import RegisterCard from "../../../../../components/RegisterCard";

interface IStatusContratoProps {
  captador: ICaptador;
}

const StatusContrato: React.FC<IStatusContratoProps> = ({ captador }) => {
  return (
    <RegisterCard title="Status do contrato">
      <C.StatusArea>
        <label>Status atual:</label>
        <Stack direction={"row"} spacing={2}>
          <CircleCheckbox
            label={EnumStatusContratoParceiro.lead}
            color="yellow"
            id={1}
            selected={
              Object.values(EnumStatusContratoParceiro)[
                Number(captador.statusContrato)
              ] === EnumStatusContratoParceiro.lead
            }
            onSelect={() => {}}
          />
          <CircleCheckbox
            label={EnumStatusContratoParceiro.aguardandoAssinatura}
            color="blue"
            id={0}
            selected={
              Object.values(EnumStatusContratoParceiro)[
                Number(captador.statusContrato)
              ] === EnumStatusContratoParceiro.aguardandoAssinatura
            }
            onSelect={() => {}}
          />
          <CircleCheckbox
            label={EnumStatusContratoParceiro.contratoAssinado}
            color="green"
            id={2}
            selected={
              Object.values(EnumStatusContratoParceiro)[
                Number(captador.statusContrato)
              ] === EnumStatusContratoParceiro.contratoAssinado
            }
            onSelect={() => {}}
          />
          <CircleCheckbox
            label={EnumStatusContratoParceiro.aguardandoAssinaturaDistrato}
            color="red"
            id={3}
            selected={
              Object.values(EnumStatusContratoParceiro)[
                Number(captador.statusContrato)
              ] === EnumStatusContratoParceiro.aguardandoAssinaturaDistrato
            }
            onSelect={() => {}}
          />
          <CircleCheckbox
            label={EnumStatusContratoParceiro.cancelado}
            color="purple"
            id={4}
            selected={
              Object.values(EnumStatusContratoParceiro)[
                Number(captador.statusContrato)
              ] === EnumStatusContratoParceiro.cancelado
            }
            onSelect={() => {}}
          />
        </Stack>
      </C.StatusArea>
      <C.FWStack direction={"row"} spacing={2}>
        <TextField
          value={
            captador.ultimaAlteracaoStatus != null
              ? new Date(captador.ultimaAlteracaoStatus).toLocaleDateString()
              : null
          }
          label="Última alteração de status"
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          label="Data de assinatura do contrato"
          value={
            captador.dataAssinaturaContrato != null
              ? new Date(captador.dataAssinaturaContrato).toLocaleDateString()
              : ""
          }
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          label="UUID do contrato"
          value={captador.uuidContrato}
          fullWidth
          inputProps={{ readOnly: true }}
        />
      </C.FWStack>
      <C.FWStack direction={"row"} justifyContent={"space-between"}>
        <C.FWStack direction={"row"} spacing={2}>
          <D.OutlineButton $color="blue" startIcon={<DownloadIcon />}>
            Baixar contrato
          </D.OutlineButton>
          {/*
          <D.ContainedButton $color={"lightpink"}>
            Gerar contrato
          </D.ContainedButton>
          <D.ContainedButton $color={"lightpink"}>
            Gerar distrato
          </D.ContainedButton>
           */}
        </C.FWStack>
      </C.FWStack>
    </RegisterCard>
  );
};

export default StatusContrato;
