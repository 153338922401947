import { EnumStatusGDProposta } from "../enums/EnumStatusGDProposta.enum";

export interface IStatusDashboardProposta {
  status: EnumStatusGDProposta;
  quantidade: number;
  totalReais: number;
  totalkWh: number;
}

export interface IGDPropostaPaged {
  id: string;
  idReferencial: string;
  createdAt: Date;
  updatedAt: Date;
  cliente: string;
  instalacao: string;
  parceiro: string;
  temProposta: boolean;
  status: number;
}

export interface IGDProposta {
  instalacao: string;
  id: string;
  idReferencial: string;
  createdAt: Date;
  updatedAt: Date;
  cliente: string;
  celular: string;
  status: EnumStatusGDProposta;
  captadorId: string;
  nomeCaptador: string;
  celularCaptador: string;
  email: string;
  nomeBackoffice: string;
  valor: string;
  kwh: string;
}

export interface IPDFPropostaResponse {
  id: string;
  idreferencial: number;
  nome: string;
  celularcontato: string;
  email: string | null;
  ninstalacao: string;
  contadistribuidora: number;
  tarifavigente: number;
  iluminacaopublica: number;
  ultimoconsumokwhconsiderado: string;
  taxadisponibilidade: string;
  khwliberadorinjecaoenergia: number;
  valoratualdistribuidora: number;
  contadistribuidoraaposcontatacao: number;
  boleto: number;
  contadistribuidoramaisboleto: number;
  economiamensal: number;
  economiaanual: number;
  percentualdedescontosobretarifa: number;
}

export interface IFullGDProposta {
  leadId: string;
  nome: string | null;
  cpf: string | null;
  celular: string | null;
  captadorNome: string | null;
  backofficeNome: string | null;
  email: string | null;
  distribuidoraId: string | null;
  idCaptador: string | null;
  conexao: string | null;
  classe: string | null;
  valorConta: string | null;
  consumoMensalkWh: string | null;
  tarifaDistribuidora: string | null;
  iluminacaoPublica: string | null;
  numeroInstalacao: string | null;
  energiaInjetadakWh: string | null;
  energiaInjetadaReais: string | null;
  valorDesconto: string | null;
  valorCooperativa: string | null;
  taxaDisponibilidadekWh: string | null;
  taxaDisponibilidadeReais: string | null;
  tributosPisCofins: string | null;
  impostoTotalDistribuidora: string | null;
  totalContaDistribuidora: string | null;
  valorTotal: string | null;
  tarifaCooperativa: string | null;
  economiaMensalBruta: string | null;
  economiaMensalLiquida: string | null;
  economiaAnual: string | null;
  contasEconomizadasAnualmente: string | null;
  status: number | null;
  idReferencial: number;
  createdAt: Date;
  updatedAt: Date;
  cooperativaId: string | null;
  captadorId: string | null;
  backofficeId: string | null;
}

export interface ICreateGDProposta {
  leadId: string | null;
  nome: string | null;
  cpf: string | null;
  celular: string | null;
  email: string | null;
  distribuidoraId: string | null;
  conexao: string | null;
  classe: string | null;
  valorConta: string | null;
  consumoMensalkWh: string | null;
  tarifaDistribuidora: string | null;
  iluminacaoPublica: string | null;
  numeroInstalacao: string | null;
  energiaInjetadakWh: string | null;
  energiaInjetadaReais: string | null;
  valorDesconto: string | null;
  valorCooperativa: string | null;
  taxaDisponibilidadekWh: string | null;
  taxaDisponibilidadeReais: string | null;
  tributosPisCofins: string | null;
  impostoTotalDistribuidora: string | null;
  totalContaDistribuidora: string | null;
  valorTotal: string | null;
  tarifaCooperativa: string | null;
  economiaMensalBruta: string | null;
  economiaMensalLiquida: string | null;
  economiaAnual: string | null;
  contasEconomizadasAnualmente: string | null;
  status: number | null;
  cooperativaId: string | null;
}

export interface IUpdateGDProposta {
  id: string | null;
  nome: string | null;
  cpf: string | null;
  celular: string | null;
  email: string | null;
  captadorId: string | null;
  distribuidoraId: string | null;
  conexao: string | null;
  classe: string | null;
  valorConta: string | null;
  consumoMensalkWh: string | null;
  tarifaDistribuidora: string | null;
  iluminacaoPublica: string | null;
  numeroInstalacao: string | null;
  energiaInjetadakWh: string | null;
  energiaInjetadaReais: string | null;
  valorDesconto: string | null;
  valorCooperativa: string | null;
  taxaDisponibilidadekWh: string | null;
  taxaDisponibilidadeReais: string | null;
  tributosPisCofins: string | null;
  impostoTotalDistribuidora: string | null;
  totalContaDistribuidora: string | null;
  valorTotal: string | null;
  tarifaCooperativa: string | null;
  economiaMensalBruta: string | null;
  economiaMensalLiquida: string | null;
  economiaAnual: string | null;
  contasEconomizadasAnualmente: string | null;
  status: number | null;
  cooperativaId: string | null;
}

export const DefaultUpdateGDProposta: IUpdateGDProposta = {
  id: null,
  nome: null,
  cpf: null,
  celular: null,
  email: null,
  captadorId: null,
  distribuidoraId: null,
  conexao: null,
  classe: null,
  valorConta: null,
  consumoMensalkWh: null,
  tarifaDistribuidora: null,
  iluminacaoPublica: null,
  numeroInstalacao: null,
  energiaInjetadakWh: null,
  energiaInjetadaReais: null,
  valorDesconto: null,
  valorCooperativa: null,
  taxaDisponibilidadekWh: null,
  taxaDisponibilidadeReais: null,
  tributosPisCofins: null,
  impostoTotalDistribuidora: null,
  totalContaDistribuidora: null,
  valorTotal: null,
  tarifaCooperativa: null,
  economiaMensalBruta: null,
  economiaMensalLiquida: null,
  economiaAnual: null,
  contasEconomizadasAnualmente: null,
  status: null,
  cooperativaId: null,
};

//Gera proposta data

export interface IDadosPessoaisProposta {
  nome: string | null;
  cpf: string | null;
  celular: string | null;
  email: string | null;
  cooperativaId: string | null;
}

export interface IDadosPessoaisEditarProposta {
  nome: string | null;
  cpf: string | null;
  celular: string | null;
  email: string | null;
  cooperativaId: string | null;
  captadorNome: string | null;
  backofficeNome: string | null;
  captadorId: string | null;
  leadId: string;
}

export interface IConexaoContaProposta {
  distribuidoraId: string | null;
  conexao: string | null;
  classe: string | null;
  valorConta: string | null;
  consumoMensalkWh: string | null;
  tarifaDistribuidora: string | null;
  iluminacaoPublica: string | null;
  numeroInstalacao: string | null;
  valorDesconto: string | null;
  tributosPisCofins: string | null;
}

export interface IContaCooperativaProposta {
  energiaInjetadakWh: string | null;
  energiaInjetadaReais: string | null;
  valorCooperativa: string | null;
}

export interface IContaDistribuidoraProposta {
  taxaDisponibilidadekWh: string | null;
  taxaDisponibilidadeReais: string | null;
  impostoTotalDistribuidora: string | null;
  totalContaDistribuidora: string | null;
}

export interface IResumoFaturaProposta {
  valorTotal: string | null;
  tarifaCooperativa: string | null;
  economiaMensalBruta: string | null;
  economiaMensalLiquida: string | null;
  economiaAnual: string | null;
  contasEconomizadasAnualmente: string | null;
}

export interface IGetCalculoParams {
  tipoConexao: string;
  valorConta: string;
  iluminacaoPublica: string;
  consumoMensalkWh: string;
  tarifaDistribuidora: string;
  pisConfins: string;
  percentualDesconto: string;
}

export interface IGetMessageParams {
  propostaId: string;
  countryCode: string;
  celular: string;
  isCopyText: boolean;
}

export interface IGetCalculoResponse {
  kwhInjetado: number;
  energiaInjetada: number;
  tarifaUsina: number;
  valorContaCooperativa: number;
  valorContaDistribuidora: number;
  valorTaxaDisponibilidade: number;
  iluminacaoPublica: number;
  valorImpostosDistribuidora: number;
  totalContaDistribuidora: number;
  valorTotalFatura: number;
  economiaMensalLiquida: number;
  economiaMensalBruta: number;
  economiaAnual: number;
  contasEconomizadas: number;
}

export interface IGDPropostaRelatoriosFilters {
  status: string | undefined;
  captadorId: string | undefined;
  backofficeId: string | undefined;
  dataInicio: string | undefined;
  dataFim: string | undefined;
}
