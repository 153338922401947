import { MenuItem, TextField } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import MaskDefaultInput from "../../../../../../components/MasDefaultinput/MaskDefaultInput";
import RegisterCard from "../../../../../../components/RegisterCard";
import {
  EnumEstadoCivil,
  EnumEstadoCivilNumericMap,
} from "../../../../../../enums/EnumEstadoCivil.enum";
import { useObjectState } from "../../../../../../hooks/useObjectState";
import { ICelularInputReturn } from "../../../../../../models/Celular";
import { IUpdateResponsavelLegal } from "../../../../../../models/GDContrato";
import * as service from "../../../../../../services/api/GDContratoClienteService";
import * as D from "../../../../../../styles/appComponents";
import { convertEmptyStringsToNullAsync } from "../../../../../../utils/convertEmptyStringsToNull";
import { FormatDate } from "../../../../../../utils/dateFormatter";
import { IResponsavelLegal } from "../../../../../../models/ResponsavelLegal";
import { ICepReturn } from "../../../../../../models/IbgeService";
import { toastMessage } from "../../../../../../utils/toastMessage";

type PropsInformacoesResponsavelLegal = {
  id?: string;
  dataContrato?: IResponsavelLegal;
  edit: boolean;
  callback?: (data: IUpdateResponsavelLegal) => void;
};

const InformacoesResponsavelLegal = (
  props: PropsInformacoesResponsavelLegal
) => {
  //region Variáveis
  const { id, dataContrato } = props;
  const [editar, setEditar] = useState<boolean>(false);
  const { state, setObject, updateObject, resetObject } =
    useObjectState<IUpdateResponsavelLegal>({
      id: id,
      nome: "",
      email: "",
      countryCode: "+55",
      celular: "",
      cpf: "",
      enumEstadoCivil: "",
      nacionalidade: "",
      profissao: "",
      dataNascimento: "",
      cep: "",
      uf: "",
      cidade: "",
      bairro: "",
      logradouro: "",
      numero: "",
      complemento: "",
    });
  //endregion

  //region Services
  const Update: () => Promise<void> = async () => {
    var data: IUpdateResponsavelLegal = await convertEmptyStringsToNullAsync(
      state
    );

    await service
      .UpdateResponsavelLegal(data)
      .then(() => {
        setEditar(false);
        toastMessage("success", "Atualizado com sucesso!");
      })
      .catch((e: AxiosError) => {
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os dados."
        );
      });
  };
  //endregion

  //region UI
  const handleChangeCelular: (e: ICelularInputReturn) => Promise<void> = async (
    e: ICelularInputReturn
  ) => {
    updateObject({ celular: e.value });
    updateObject({ countryCode: e.zipCode });
  };

  const BuscaCep: (cepReturn: ICepReturn) => Promise<void> = async (
    cepReturn: ICepReturn
  ) => {
    updateObject({
      cep: cepReturn.value ?? "",
      bairro: cepReturn.bairro ?? "",
      cidade: cepReturn.localidade ?? "",
      logradouro: cepReturn.logradouro ?? "",
      uf: cepReturn.uf ?? "",
    });
  };

  useEffect((): void => {
    if (dataContrato) {
      setObject({
        id: id,
        nome: dataContrato.nome ?? "",
        email: dataContrato.email ?? "",
        countryCode: dataContrato.countryCode ?? "+55",
        celular: dataContrato.celular ?? "",
        cpf: dataContrato.cpf ?? "",
        enumEstadoCivil: dataContrato.enumEstadoCivil ?? "",
        nacionalidade: dataContrato.nacionalidade ?? "Brasileiro(a)",
        profissao: dataContrato.profissao ?? "",
        dataNascimento:
          dataContrato?.dataNascimento != null
            ? FormatDate(new Date(dataContrato?.dataNascimento!))
            : "",
        cep: dataContrato.cep ?? "",
        uf: dataContrato.uf ?? "",
        cidade: dataContrato.cidade ?? "",
        bairro: dataContrato.bairro ?? "",
        logradouro: dataContrato.logradouro ?? "",
        numero: dataContrato.numero ?? "",
        complemento: dataContrato.complemento ?? "",
      });
    }
  }, [dataContrato]);

  useEffect(() => {
    if (!props.edit) {
      props.callback!(state);
    }
  }, [state]);

  useEffect(() => {
    if (props.edit === false) {
      setEditar(true);
    }
  }, [props.edit]);
  //endregion

  return (
    <RegisterCard title="Informações do responsável legal">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Nome do responsável legal"
          required
          fullWidth
          value={state.nome}
          onChange={(e) => updateObject({ nome: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Email"
          required
          fullWidth
          value={state.email}
          onChange={(e) => updateObject({ email: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
        <MaskDefaultInput
          label="CPF"
          type="CPF"
          value={state.cpf}
          onChange={(e) => {
            updateObject({ cpf: e as string });
          }}
          readonly={!editar}
        />
        <MaskDefaultInput
          label="Celular"
          value={state.celular}
          valueCountryCode={state.countryCode}
          type="CELULAR"
          readonly={!editar}
          onChange={(e) => handleChangeCelular(e as ICelularInputReturn)}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2}>
        <D.DateTextfield
          label="Data de nascimento"
          type="date"
          fullWidth
          value={state.dataNascimento}
          onChange={(e) => updateObject({ dataNascimento: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Estado Civil"
          required
          fullWidth
          select
          value={state.enumEstadoCivil}
          onChange={(e) => updateObject({ enumEstadoCivil: e.target.value })}
          inputProps={{ readOnly: !editar }}
        >
          {Object.values(EnumEstadoCivil).map((value) => (
            <MenuItem key={value} value={EnumEstadoCivilNumericMap[value]}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Nacionalidade"
          required
          fullWidth
          value={state.nacionalidade}
          onChange={(e) => updateObject({ nacionalidade: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Profissão"
          required
          fullWidth
          value={state.profissao}
          onChange={(e) => updateObject({ profissao: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2}>
        <MaskDefaultInput
          label="CEP"
          type="CEP"
          value={state.cep}
          onChange={(e) => BuscaCep(e as ICepReturn)}
          readonly={!editar}
        />
        <TextField
          label="UF"
          required
          fullWidth
          value={state.uf}
          onChange={(e) => updateObject({ uf: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Cidade"
          required
          fullWidth
          value={state.cidade}
          onChange={(e) => updateObject({ cidade: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Bairro"
          required
          fullWidth
          value={state.bairro}
          onChange={(e) => updateObject({ bairro: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          label="Logradouro"
          required
          fullWidth
          value={state.logradouro}
          onChange={(e) => updateObject({ logradouro: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Número"
          required
          fullWidth
          value={state.numero}
          onChange={(e) => updateObject({ numero: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
        <TextField
          label="Complemento"
          fullWidth
          value={state.complemento}
          onChange={(e) => updateObject({ complemento: e.target.value })}
          inputProps={{ readOnly: !editar }}
        />
      </D.FWStack>
      {props.edit && (
        <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton
            $color="yellow"
            disabled={editar}
            onClick={() => setEditar(true)}
          >
            Editar
          </D.ContainedButton>
          <D.ContainedButton disabled={!editar} onClick={Update}>
            Salvar
          </D.ContainedButton>
        </D.FWStack>
      )}
    </RegisterCard>
  );
};

export default InformacoesResponsavelLegal;
