import { useEffect, useState } from "react";
import { ICaptador, ICaptadorListItem } from "../../../../models/Captador";
import * as C from "./style";
import ReponsavelLegalInput from "../../../../components/ResponsavelLegalInput/ReponsavelLegalInput";
import AssociadoManager from "../Util/AssociadoManager";
import { useParams } from "react-router-dom";
import { SkeletonCreationCards } from "../../../../components/SkeletonLoads/CreationCards";
import LogoCardInput from "../../../../components/LogoCardInput/LogoCardInput";
import { DicLogo } from "../../../../models/Logo";
import ComissaoDadosPagamento from "../../../../components/ComissaoDadosPagamento/ComissaoDadosPagamento";
import { InformacaoAssociadoInput } from "../../../../components/InformacaoAssociadoInput/InformacaoAssociadoInput";
import { IBackofficeListItem } from "../../../../models/Colaborador/Colaborador";
import DadosContratoInput from "../../../../components/DadosContratatoInput/DadosContratoInput";
import { DadosAcessoInput } from "../../../../components/DadosAcessoInput/DadosAcessoInput";

const obterDicLogoInicial = (): DicLogo => {
  return {
    svg: null,
    png: null,
    pdf: null,
  };
};

const EditarAssociado = () => {
  const [captador, setCaptador] = useState<ICaptador | null>(null);
  const [dicLogo, setDicLogo] = useState<DicLogo>(obterDicLogoInicial());
  const [backOffices, setBackOffices] = useState<IBackofficeListItem[]>([]);
  const [captadores, setCaptadores] = useState<ICaptadorListItem[]>([]);

  const { associadoId } = useParams();

  useEffect(() => {
    const getCapacitador: () => Promise<void> = async () => {
      if (!associadoId) return;
      const captador = await AssociadoManager.getCaptadorById(associadoId);
      setCaptador(captador);
    };

    const getLogo: () => Promise<void> = async () => {
      if (!associadoId) return;
      const novoDicLogo = await AssociadoManager.getLogo(associadoId);
      if (novoDicLogo) setDicLogo(novoDicLogo);
    };

    getCapacitador();
    getLogo();
  }, [associadoId]);

  useEffect(() => {
    const getBackoffices: () => Promise<void> = async () => {
      const novoBackoffices = await AssociadoManager.getBackoffices();
      setBackOffices(novoBackoffices);
    };

    const getCaptadores = async () => {
      const novoCaptadores = await AssociadoManager.getCaptadores();
      setCaptadores(novoCaptadores);
    };

    getBackoffices();
    getCaptadores();
  }, []);

  const updateResponsavelLegal: (
    updateDicLogo: DicLogo
  ) => Promise<void> = async (updateDicLogo: DicLogo) => {
    if (captador) {
      const novoDicLogo = await AssociadoManager.updateCaptadorLogo(
        captador.id,
        updateDicLogo
      );
      if (novoDicLogo) setDicLogo(novoDicLogo);
    }
  };

  const obterKeyDicLogo: () => string = () =>
    Object.entries(dicLogo).reduce((acc, [chave, valor]) => {
      if (valor === null) return acc;
      return acc + `${chave}:${"id" in valor ? valor.id : ""}`;
    }, "");

  return captador ? (
    <C.Container>
      <DadosContratoInput
        captador={captador}
        updateAssociadoDadosContrato={
          AssociadoManager.updateAssociadoDadosContrato
        }
        disabled
      />
      <ReponsavelLegalInput
        captador={captador}
        updateResponsavelLegal={AssociadoManager.updateResponsavelLegal}
        disabled
      />
      <InformacaoAssociadoInput
        captador={captador}
        backOffices={backOffices}
        captadores={captadores}
        disabled
      />
      <ComissaoDadosPagamento
        captador={captador}
        updateDadosPagamento={AssociadoManager.updateDadosPagamento}
        disabled
      />
      <LogoCardInput
        title="Anexo logo do parceiro"
        value={dicLogo}
        updateCaptadorLogo={updateResponsavelLegal}
        dowloadAnexo={AssociadoManager.dowloadAnexo}
        key={obterKeyDicLogo()}
        disabled
      />
      <DadosAcessoInput
        captador={captador}
        updateDadosAcesso={AssociadoManager.updateDadosAcesso}
        disabled
      />
    </C.Container>
  ) : (
    <C.Container>
      <SkeletonCreationCards />
    </C.Container>
  );
};

export default EditarAssociado;
