import { CodigoTelefonico } from "../enums/EnumCodigoTelefonico.enum";
import { ICaptador } from "../models/Captador";
import { IUpdateResponsavelComunicacao } from "../models/ResponsavelComunicacao";
import { IUpdateResponsavelLegal } from "../models/ResponsavelLegal";
import { CREATE_RESPONSAVEL_LEGAL_DEFAULT } from "../pages/private/Associados/Constants";

export const obterResponavelLegalInicial = (
  captador: ICaptador
): IUpdateResponsavelLegal[] =>
  captador?.responsaveisLegais?.length
    ? captador.responsaveisLegais.map((x) => ({
        id: x.id,
        nome: x.nome ?? "",
        enumEstadoCivil: x.enumEstadoCivil,
        nacionalidade: x.nacionalidade ?? "",
        profissao: x.profissao ?? "",
        email: x.email ?? "",
        celular: x.celular ?? "",
        countryCode: x.countryCode ?? "",
        cpf: x.cpf ?? "",
        cep: x.cep ?? "",
        uf: x.uf ?? "",
        cidade: x.cidade ?? "",
        bairro: x.bairro ?? "",
        numero: x.numero ?? "",
        complemento: x.complemento ?? "",
        logradouro: x.logradouro ?? "",
        dataNascimento: x.dataNascimento,
        ativo: x.ativo,
      }))
    : [{ ...CREATE_RESPONSAVEL_LEGAL_DEFAULT }];

export const obterResponsavelComunicacaoInicial = (
  captador: ICaptador
): IUpdateResponsavelComunicacao => ({
  id: captador.responsavelComunicacao?.id,
  nome: captador.responsavelComunicacao?.nome ?? "",
  email: captador.responsavelComunicacao?.email ?? "",
  celular: captador.responsavelComunicacao?.celular ?? "",
  countryCode:
    captador.responsavelComunicacao?.countryCode ?? CodigoTelefonico.BRASIL,
});
