//https://flagcdn.com/en/codes.json
export const countryCodes = [
  {code: "+55", name: "Brasil", img: "https://flagcdn.com/br.svg"},
  {code: "+1", name: "Estados Unidos", img: "https://flagcdn.com/us.svg"},
  {code: "+353", name: "Irlanda", img: "https://flagcdn.com/ie.svg"},
];

export abstract class IMask {
  executeForInput(value: string, countryCode?: string): string {
    return this.execute(value);
  }

  abstract execute(value: string): string;
}

export class CEP extends IMask {
  execute(value: string): string {
    return value
      .replace(/\D/g, "")
      .replace(/^(\d{5})(\d{3})/, "$1-$2")
      .slice(0, 9);
  }
}

export class CPF extends IMask {
  execute(value: string): string {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .slice(0, 14);
  }
}

export class CNPJ extends IMask {
  execute(value: string): string {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
      .slice(0, 18);
  }
}

export class RG extends IMask {
  execute(value: string): string {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, "$1.$2.$3-$4")
      .slice(0, 12);
  }
}

export class CELULAR extends IMask {
  executeForInput(value: string, countryCode?: any): string {
    switch (countryCode) {
      case "+55":
        return value
          .replace(/\D/g, "")
          .replace(/^(\d{2})(\d{5})(\d{4}).*/, "($1) $2-$3")
          .replace(/^(\d{2})(\d{4})(\d{4}).*/, "($1) $2-$3")
          .slice(0, 15);

      case "+1":
        return value
          .replace(/\D/g, "")
          .replace(/^(\d{3})(\d{3})(\d{4}).*/, "$1-$2-$3")
          .slice(0, 12);

      case "+353":
        return value
          .replace(/\D/g, "")
          .replace(/^(\d{2})(\d{3})(\d{4}).*/, "$1 $2 $3")
          .slice(0, 11);

      default:
        return value
          .replace(/\D/g, "")
          .replace(/^(\d{2})(\d{5})(\d{4}).*/, "($1) $2-$3")
          .replace(/^(\d{2})(\d{4})(\d{4}).*/, "($1) $2-$3")
          .slice(0, 15);
    }
  }

  execute(value: string): string {
    return value
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d{5})(\d{4}).*/, "($1) $2-$3")
      .replace(/^(\d{2})(\d{4})(\d{4}).*/, "($1) $2-$3")
      .slice(0, 15);
  }
}

const maskPercentage = (value: string): string => {
  // Converte para string caso seja um número
  let numericValue = String(value);

  // Troca ponto por vírgula para exibição no formato brasileiro
  numericValue = numericValue.replace(".", ",");

  // Garante que só tenha uma vírgula e no máximo 2 casas decimais
  const parts = numericValue.split(",");
  if (parts.length > 1) {
    parts[1] = parts[1].slice(0, 2); // Mantém só 2 casas após a vírgula
  }
  numericValue = parts.join(",");

  // Converte para número para validar se está dentro do limite
  const floatValue = parseFloat(numericValue.replace(",", "."));
  if (!isNaN(floatValue) && floatValue > 100) return "100";

  return numericValue;
};

const maskReal = (value: string): string => {
  const numericValue = (value || "")?.replace(/\D/g, "");

  if (!numericValue) return "";

  const floatValue = parseFloat(value);

  if (isNaN(floatValue)) return "";

  return floatValue.toLocaleString("pt-br", {minimumFractionDigits: 2});
};

export class REAL extends IMask {
  execute(value: string): string {
    return maskReal(value);
  }
}

export class DECIMAL implements IMask {
  executeForInput(value: string): string {
    return maskReal(value);
  }

  execute(value: string): string {
    return maskReal(value);
  }
}

export class PORCENTAGEM implements IMask {
  executeForInput(value: string): string {
    return maskPercentage(value);
  }

  execute(value: string): string {
    return maskPercentage(value);
  }
}

export class KWH extends IMask {
  executeForInput(value: string): string {
    return value;
  }

  execute(value: string): string {
    let numericValue = (value?.toString() || "")?.replace(
      /(?<!^)-|[^0-9.,-]/g,
      ""
    );

    numericValue = numericValue.replace(/[,]/g, ".");
    const parts = numericValue.split(".");

    if (parts.length > 1) {
      parts[1] = parts[1].substring(0, 2);
    }

    let formattedValue = parts.join(".");

    formattedValue = formattedValue
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      .replace(/\.(\d{2})$/, ",$1");

    return formattedValue;
  }
}

export class ALEATORIA extends IMask {
  execute(value: string): string {
    const valorLimpo = value.replace(/[^a-zA-Z0-9]/g, "");
    const partes = [
      valorLimpo.slice(0, 8),
      valorLimpo.slice(8, 12),
      valorLimpo.slice(12, 16),
      valorLimpo.slice(16, 20),
      valorLimpo.slice(20, 32),
    ];

    return partes.filter((parte) => parte.length > 0).join("-");
  }
}
