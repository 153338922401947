import { ISidebarMenu } from "../../../models/SidebarPopulate";
import { IAcessoDTO } from "../../../models/Acesso";

import  { ReactComponent as ContratoIcon } from "../../../assets/Layouts/Gerador/contrato.svg";
import  { ReactComponent as InformeRendimantoIcon } from "../../../assets/Layouts/Gerador/informe-rendimento.svg";
import  { ReactComponent as OrdemPagamntoIcon } from "../../../assets/Layouts/Gerador/ordem-pagamento.svg";
import  { ReactComponent as SupportIcon } from "../../../assets/Layouts/Gerador/suport.svg";

const GeradorRoutes = (acesso?: IAcessoDTO) => {
  const route = window.location.pathname;

  var routes: ISidebarMenu[] = [
    {
      icon: () => <ContratoIcon/>,
      title: "Contrato gerador",
      hasSubMenus: false,
      path: "/Gerador/ContratoGerador",
    },
    {
      icon: () => <InformeRendimantoIcon/>,
      title: "Informe rendimento",
      hasSubMenus: false,
      path: "",
    },
    {
      icon: () => <OrdemPagamntoIcon/>,
      title: "Ordem de pagamento",
      hasSubMenus: false,
      path: "/Gerador/OrdemPagamento",
    },
    {
      icon: () => <SupportIcon/>,
      title: "Contate nosso suporte via Whatsapp",
      hasSubMenus: false,
      path: `${route}?support=open`,
    }
  ];

  return routes;
};

export default GeradorRoutes;
