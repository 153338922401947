import React, {useEffect, useMemo, useState} from 'react';
import SkeletonDefaultPage from "../../../components/SkeletonLoads/DefaultPage";
import * as C from "./style";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {ListView} from "../../../components/ListView";
import * as D from "../../../styles/appComponents";
import Datatable from "../../../components/Datatable";
import ExcelIcon from "../../../assets/Plataforma/excelIcon.svg";
import {useAuth} from "../../../hooks/useAuth";
import {StatusDashboard} from "../../../models/StatusDashboard";
import {GridColDef} from "@mui/x-data-grid";
import * as cargoServices from "../../../services/api/CargoService";
import {AxiosError, AxiosResponse} from "axios";
import {toastMessage} from "../../../utils/toastMessage";
import {IPaginatedList} from "../../../models/PaginatedList";
import {ICargo} from "../../../models/Cargo";
import {EnumStatusCargo, EnumStatusCargoColorMap, EnumStatusCargoMap} from "../../../enums/EnumStatusCargo";
import NewCargoModal from "./Modais/ModalCargo";
import { CELULAR } from '../../../utils/masInputs';

const Cargo: () => React.JSX.Element = (): React.JSX.Element => {
  //region Variaveis
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [opeModalCargo, setOpeModalCargo] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [statusOpen, setStatusOpen] = useState<boolean>(false);
  const [isMobile, _] = useState(window.innerWidth < 1024);
  const [cargosRows, setCargosRows] = useState<ICargo[]>([]);
  const [currentId, setCurrentId] = useState<string | undefined>(undefined);
  const [statusCargo, setStatusCargo] = useState<StatusDashboard<EnumStatusCargo>[]>([]);
  const [filterStatusCargo, setFilterStatusCargo] = useState<EnumStatusCargo | null | undefined>(null);
  const columns: GridColDef[] = [
    {
      field: "idReferencial",
      headerName: "Id",
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "cargo",
      headerName: "Cargo",
      flex: 1,
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "setor",
      headerName: "Setor",
      flex: 1,
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "cbo",
      headerName: "CBO",
      flex: 1,
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.Circle color={EnumStatusCargoColorMap[Number(params.value)]}/>
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.DefaultGridButton onClick={() => handleOpen(params.row.id)}>
            Editar
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];
  //endregion

  //region Services
  const getCargos: () => Promise<void> = async () => {
    try {
      const {data}: AxiosResponse<IPaginatedList<ICargo>> = await cargoServices.GetCargos(page, pageSize , search, filterStatusCargo ?? null);

      setTotalPages(data?.totalPages);
      setCargosRows(data?.data);
    } catch (e) {
      const error = e as AxiosError;

      toastMessage("error", error.response
        ? String(error.response?.data)
        : "Houve um erro ao pegar os dados.");
    } finally {
      setLoading(false);
    }
  }

  const getStatusCargo: () => Promise<void> = async () => {
    try {
      const response: AxiosResponse<StatusDashboard<EnumStatusCargo>[]> = await cargoServices.GetStatusCargos();

      setStatusCargo(response.data);
    } catch (e) {
      const error = e as AxiosError;

      toastMessage("error", error.response
        ? String(error.response?.data)
        : "Houve um erro ao pegar os dados.");
    } finally {
      setLoading(false);
    }
  }

  const geraRelatorio: () => Promise<void> = async () => {
    try {
      const response: AxiosResponse<File, any> = await cargoServices.GeraRelatorio();
      const blob: File = response.data;

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `cargo_${new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/[-:T]/g, "")}.xlsx`;
      link.click();

      URL.revokeObjectURL(link.href);
    } catch (e) {
      const error = e as AxiosError;

      toastMessage("error", error.response
        ? String(error.response?.data)
        : "Houve um erro ao tentar gerar o relatório.");
    }
  }
  //endregion

  //region UI
  const onCloseNewCargoModal = () => {
    setCurrentId(undefined);
    setOpeModalCargo(false);
  }

  const callbackModalCargo = () => {
    setOpeModalCargo(false);
    getCargos();
    getStatusCargo();
  }

  const handleStatusOpen: () => void = () => {
    setStatusOpen(!statusOpen);
  };

  const handleChangePage: (value: number) => void = (value: number) => {
    setPage(value);
  };

  const handleChangePageSize: (value: number) => void = (value: number) => {
    setPageSize(value);
  };

  const handleOpen: (id: string) => Promise<void> = async (id: string) => {
    setCurrentId(id);
    setOpeModalCargo(true);
  };

  const handleSearch: (value: string) => Promise<void> = async (value: string) => {
    setPage(1);
    setSearch(value);
  };

  const handleOpenNewLead: () => Promise<void> = async () => {
    setOpeModalCargo(true);
  };

  useEffect(() => {
    getCargos();
    getStatusCargo();
  }, []);

  const captadorAccessLeads = useMemo<string | null>(
    () => auth.user?.idCaptador ?? null,
    [auth]
  );

  useEffect(() => {
    getCargos()
  }, [filterStatusCargo]);
  //endregion

  return (
    <>
      <NewCargoModal
        id={currentId}
        openNewCargo={opeModalCargo}
        callback={callbackModalCargo}
        onClose={onCloseNewCargoModal}
      />
      {loading ? (
        <SkeletonDefaultPage/>
      ) : (
        <C.Container>
          <C.Title onClick={() => handleStatusOpen()}>
            {isMobile && (
              <>
                {!statusOpen ? (
                  <KeyboardArrowDownRoundedIcon/>
                ) : (
                  <KeyboardArrowUpIcon/>
                )}
              </>
            )}
          </C.Title>
          <ListView<EnumStatusCargo>
            sectionName={"colaboradorAcessCargo"}
            hasButtonSendClickSign={false}
            hasOtherStatus={false}
            statusContratoBuilder={EnumStatusCargoMap}
            data={statusCargo}
            getFilterStatus={setFilterStatusCargo}
            filterStatusContrato={filterStatusCargo}
            hasSecondaryValues={false}
            hasThridValues={false}
          >
            <D.DataArea $align="right">
              <Datatable
                hasButton={true}
                titleButton={"Novo Cargo"}
                handleButton={handleOpenNewLead}
                columns={columns}
                rows={cargosRows}
                pageNumber={page}
                onSearch={() => handleSearch}
                pageSize={pageSize}
                onChangePage={(e) => handleChangePage(e)}
                onChangePageSize={(e) => handleChangePageSize(e)}
                totalPages={totalPages}
              />
              <C.LineBreak/>
              <C.ExportButton
                onClick={geraRelatorio}
                variant="contained"
                startIcon={
                  <img
                    style={{width: "20px", color: "white", fill: "white"}}
                    src={ExcelIcon}
                    alt="excelIcon"
                  />
                }
              >
                Gerar relatório
              </C.ExportButton>
            </D.DataArea>
          </ListView>
        </C.Container>
      )}
    </>
  );
};

export default Cargo;