import {AbstractValidation, Validate} from "../../../../../utils/AbstractValidation";
import {IUpdateLead} from "../../../../../models/Lead";


export class LeadModalValidate extends AbstractValidation<IUpdateLead> {
  getValidate(): Validate<IUpdateLead>[] {
    return [
      {
        field: "nome",
        validate(value, model: IUpdateLead, addError: (message: string) => void) {
          if (!value) addError("O Nome deve ser informada")
        }
      },
      {
        field: "celular",
        validate(value, model: IUpdateLead, addError: (message: string) => void) {
          if (!value) {
            addError("O Telefone deve ser informado");
          } else if (value.length < 11) {
            addError("O Telefone deve ser preenchido corretamente");
          }
        },
      },
      {
        field: "captadorId",
        validate(value, model: IUpdateLead, addError: (message: string) => void) {

          if (!value) {
            addError("O Captador deve ser informado");
          }
        }
      },
      {
        field: "status",
        validate(value, model: IUpdateLead, addError: (message: string) => void) {
          if ([2, 3].includes(value) && [null, "0"].includes(model.valor)) {
            addError("Para trocar o status para conta anexada ou proposta gerada, o valor deve ser preenchido");
          }
        }
      },
    ];
  }
}