import {SxProps, TextField, Theme} from "@mui/material";
import {CurrencyInputWrapper} from "../CurrencyInputWrapper";
import {FC} from "react";

interface PropsMaskCurrencyInput {
  name?: string | undefined;
  value?: number | string | undefined | null;
  disabled?: boolean | undefined;
  onValueChange?: (
    newValue: string,
    name: string,
    values: any
  ) => void | undefined;
  placeholder?: string | undefined;
  decimalsLimit?: number | undefined;
  defaultValue?: number | undefined;
  suffix?: string | undefined;
  prefixo?: string | undefined;
  label?: string | undefined;
  sx?: SxProps<Theme> | undefined;
  readonly?: boolean;
  InputProps?: { endAdornment: any }
  InputLabelProps?: { shrink:boolean }
}

export const MaskCurrencyInput: FC<PropsMaskCurrencyInput> = ({
  name = undefined,
  value = undefined,
  disabled = undefined,
  onValueChange = undefined,
  placeholder = undefined,
  decimalsLimit = undefined,
  defaultValue = undefined,
  suffix = undefined,
  prefixo = undefined,
  label = undefined,
  sx = undefined,
  readonly = false,
  InputProps = {},
  InputLabelProps
}) => {
  return (
    <TextField
      sx={sx}
      disabled={disabled}
      label={label}
      InputLabelProps={InputLabelProps}
      fullWidth
      required
      InputProps={{
        ...InputProps,
        inputComponent: CurrencyInputWrapper as any,
      }}
      inputProps={{
        name: name,
        value: value,
        onValueChange: onValueChange,
        placeholder: placeholder,
        decimalsLimit: decimalsLimit,
        suffix: suffix,
        prefix: prefixo,
        defaultValue: defaultValue,
        readOnly: readonly,
      }}
    />
  );
};

