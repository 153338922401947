import {StatusBuilder} from "../models/StatusBuilder";
import {ColorType} from "../types/ColorType";

export class HandleDataStatus{
    static getNameStatus<T>(status: T, listStatus:StatusBuilder[]):string {
        return (listStatus || []).find(x => x.value === status)?.name || ""
    };

    static getColorStatus<T>(status: T, listStatus:StatusBuilder[]):ColorType {
        return (listStatus || []).find(x => x.value === status)?.color || "blue"
    };

    static getColorTitle<T>(status: T, listStatus:StatusBuilder[]):ColorType | null {
        return (listStatus || []).find(x => x.value === status)?.colorTitle || null
    };

    static getColorText<T>(status: T, listStatus:StatusBuilder[]):ColorType | null {
        return (listStatus || []).find(x => x.value === status)?.colorText || null
    };

    static getTitleSecondary<T>(status: T, listStatus:StatusBuilder[]):string {
        return (listStatus || []).find(x => x.value === status)?.titleSecondary || ""
    };

    static getTitleThird<T>(status: T, listStatus:StatusBuilder[]):string {
        return (listStatus || []).find(x => x.value === status)?.titleThird || ""
    };

    static getTypeFormatter<T>(status: T, listStatus:StatusBuilder[]): "$" | "KWH" {
        return (listStatus || []).find(x => x.value === status)?.typeNumberFormatter || "KWH"
    };

    static getTypeFormatterThird<T>(status: T, listStatus:StatusBuilder[]): "$" | "KWH" {
        return (listStatus || []).find(x => x.value === status)?.typeNumberFormatterThird || "KWH"
    };

    static getHasPrimaryStatusContrato<T>(status: T, listStatus:StatusBuilder[]):boolean {
        return (listStatus || []).find(x => x.value === status)?.primary || false
    };
}