import React, {useState} from 'react';
import RegisterCard from "../../../../../components/RegisterCard";
import * as D from "../../../../../styles/appComponents";
import {TextField} from "@mui/material";

export interface ITipoFaturamento {
  tipo: string | null;
}

export enum TipoFaturamentoEnum {
  TIPO = "tipo",
}

type TipoFaturamentoProps = {
  callback: (dadosPagador: ITipoFaturamento) => void;
}

const TipoFaturamento: (props: TipoFaturamentoProps) => React.JSX.Element = (props: TipoFaturamentoProps): React.JSX.Element => {
  //region Variáveis
  const {callback} = props;
  const [detalhesGd, setDetalhesGd] = useState<ITipoFaturamento>({
    tipo: null,
  });
  //endregion

  //region UI
  const handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setDetalhesGd((state: ITipoFaturamento) => {
      const newData = {
        ...state,
        [name]: value,
      };
      callback(newData);
      return newData;
    });
  };
  //endregion

  return (
    <>
      <RegisterCard title="Tipo de faturamento">
        <D.FWStack direction={"row"} spacing={2}>
          <TextField
            label="Tipo"
            name={TipoFaturamentoEnum.TIPO}
            required
            select
            fullWidth
            sx={{width:'50%'}}
            value={detalhesGd.tipo}
            onChange={handleInputChange}
          />
        </D.FWStack>
      </RegisterCard>
    </>
  );
};

export default TipoFaturamento;