import { useState } from "react";

export const useSessionStorage = <T,>(key: string, initialValue: T) => {
  const storedValue = sessionStorage.getItem(key);
  const [value, setValue] = useState<T>(() => 
    storedValue ? JSON.parse(storedValue) : initialValue
  );

  const setStoredValue = (newValue: T) => {
    setValue(newValue);
    newValue === null
      ? sessionStorage.removeItem(key)
      : sessionStorage.setItem(key, JSON.stringify(newValue));
  };

  return [value, setStoredValue] as const;
};
