import * as D from "../../styles/appComponents";
import {CircularProgress, TextField} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {ICreateLog, IFullLog, ILog, IUpdateLog} from "../../models/Log";
import {DataGrid, GridCellParams, GridColDef} from "@mui/x-data-grid";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {ptBR} from "@mui/x-data-grid/locales";
import PublishIcon from "@mui/icons-material/Publish";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";

import {AxiosError} from "axios";
import Swal from "sweetalert2";
import * as anexoServices from "../../services/api/AnexoService";
import * as services from "../../services/api/LogService";
import {toastMessage} from "../../utils/toastMessage";
import SkeletonDefaultPage from "../SkeletonLoads/DefaultPage";
import {useNavigate, useSearchParams} from "react-router-dom";

interface HistoricoSectionProps {
  tableData: ILog[];
  saveFunction: (data:ICreateLog) => Promise<void>;
  reloadTable: () => void;
  downloadFunCtionCustom?: (model:any) => void;
  editLog: (id:string, data:ICreateLog) => Promise<void>;
  access?:boolean;
}

export const HistoricoSection: React.FC<HistoricoSectionProps> = ({
  tableData,
  saveFunction,
  reloadTable,
  editLog,
  downloadFunCtionCustom,
  access=true
}) => {
  const dadosHistoricModel = {assunto:"",descricao:"",anexo:null,gdContratoClienteId:null}
  const [dadosHistorico, setDadosHistorico] = useState<ICreateLog>(dadosHistoricModel);
  const [addHistorico, setAddHistorico] = useState<boolean>(false);
  const [assunto, setAssunto] = useState<string>("");
  const [descricao, setDescricao] = useState<string>("");
  const [archive, setArchive] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [anexoName, setAnexoName] = useState<string>("");
  const [columns, setColumns] = useState<GridColDef[]>([]);

  const navigate = useNavigate();

  const [ searchParams, setSearchParams ] = useSearchParams();
  const update = searchParams.get("update")

  const [idLog, setIdLog] = useState<string | null>(null);

  const downloadConta = async (id: string | null) => {
    if(!id) return;

    try {
      const {data} = await anexoServices.GetUrl(id);
      const fileUrl = data;

      const link = document.createElement("a");
      link.href = fileUrl;
      link.target = "_blank";
      link.rel = "noopener noreferrer";

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      
    } catch (error) {
      toastMessage("warning","Erro ao baixar o anexo")
    }
 
  };

  const deleteLog = async (id: string) => {
    searchParams.delete('update')
    navigate({
      pathname: `/Colaborador/Gerador`,
      search: `?update=${true}`
    });

    try {
      await services.DeleteLog(id)
      toastMessage("success","Deletado com sucesso.");
      searchParams.delete('update');
      setSearchParams(searchParams);
      
    } catch (error) {
      toastMessage("error","Houve um erro ao deletar o log.");
      searchParams.delete('update');
      setSearchParams(searchParams);
    }

  };

  const handleDeleteLog = async (id: string) => {
    setLoading(true)
    Swal.fire({
      title: "Você tem certeza?",
      text: "Você não podera reverter esta ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, deletar!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteLog(id);
        setLoading(false)

      }else setLoading(false)
    });
  };

  const getLogData = async (id: string) => {
    await services
      .GetLogById(id)
      .then((response) => {
        setAssunto(response.data.assunto);
        setDescricao(response.data.descricao);
        setAnexoName(response.data.anexoName ?? "");
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao buscar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  const handleInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setDadosHistorico((state: any) => {
      return { ...state, [target.name]: target.value };
    });
  };

  const handleEditButton = async (id: string, data:IFullLog) => {
    const newDta:IUpdateLog = {
      id,
      assunto: data.assunto,
      descricao: data?.descricao,
      anexo: { name:data.anexoName } as File | null
    }

    setAddHistorico(true);
    setIdLog(id);
    getLogData(id);
    setDadosHistorico(newDta as any);

  };

  const updateLog = () => {
    if(idLog){
      setLoading(true)

      editLog(idLog, dadosHistorico)
      .then(() => {
        setLoading(false)
      })
      .catch(() => setLoading(false))

      cancelAddHistorico()
    }  

  }

  const cancelAddHistorico = () => {
    setAddHistorico(false);
    setDadosHistorico(dadosHistoricModel);
    setIdLog(null);
  };

  const createNewLog = () => {
    setLoading(true)

    saveFunction(dadosHistorico)
    .then(() => setLoading(false))
    .catch(() => setLoading(false))

    cancelAddHistorico()
  }



  useEffect(() => {
    const columnsNotAccess: GridColDef[] = [
      { field: "criadoPor", headerName: "Responsável", flex: 1 },
      {
        field: "assunto",
        headerName: "Assunto",
        flex: 1,
        renderCell: (params: GridCellParams<ILog>) => (
          <D.GridField>
            {params.row.assunto}
            <Tooltip title={params.row.descricao} arrow>
              <IconButton>
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </D.GridField>
        ),
      },
      {
          field: "createdAt",
          headerName: "Data",
          renderCell: (params: GridCellParams<ILog>) => (
            <D.GridField>
              {new Date(params.row.createdAt).toLocaleDateString()}
            </D.GridField>
          ),
      },
      {
          field: "editar",
          headerName: "Anexo",
          align: "center",
          renderCell: (params: GridCellParams<IFullLog>) => (
          <D.ModalGridEditButton
              $color="blue"
              variant="outlined"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => handleEditButton(params.row.id, params.row)}
          >
              Editar
          </D.ModalGridEditButton>
          ),
      },
      {
          field: "baixar",
          headerName: "Baixar",
          align: "center",
          renderCell: (params: GridCellParams<ILog>) => (
          <D.ModalGridEditButton
              startIcon={<DownloadIcon />}
              size="small"
              variant="outlined"
              $color="orange"
              onClick={() => {
                if(downloadFunCtionCustom){
                  downloadConta(params?.row?.anexoId);
                  downloadFunCtionCustom(params?.row);
                  
                }else{
                  downloadConta(params?.row?.anexoId);
                }
              }}
          >
              Baixar
          </D.ModalGridEditButton>
          ),
      },
      {
          field: "remover",
          headerName: "Remover",
          align: "center",
          renderCell: (params: GridCellParams<ILog>) => (
          <D.ModalGridEditButton
              variant="outlined"
              size="small"
              $color="red"
              startIcon={<DeleteIcon />}
              onClick={() => handleDeleteLog(params.row.id)}
          >
              Deletar
          </D.ModalGridEditButton>
          ),
      },
    ];
  
    const columnsAccess: GridColDef[] = [
      { field: "criadoPor", headerName: "Responsável", flex: 1 },
      {
        field: "assunto",
        headerName: "Assunto",
        flex: 2,
        renderCell: (params: GridCellParams<ILog>) => (
          <D.GridField>
            {params.row.assunto}
            <Tooltip title={params.row.descricao} arrow>
              <IconButton>
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </D.GridField>
        ),
      },
      {
          field: "createdAt",
          headerName: "Data",
          renderCell: (params: GridCellParams<ILog>) => (
            <D.GridField>
              {new Date(params.row.createdAt).toLocaleDateString()}
            </D.GridField>
          ),
      },
      {
          field: "baixar",
          headerName: "Baixar",
          align: "center",
          renderCell: (params: GridCellParams<ILog>) => (
          <D.ModalGridEditButton
              startIcon={<DownloadIcon />}
              size="small"
              variant="outlined"
              $color="orange"
              onClick={() => {
                if(downloadFunCtionCustom){
                  downloadConta(params?.row?.anexoId);
                  downloadFunCtionCustom(params?.row);
                  
                }else{
                  downloadConta(params?.row?.anexoId);
                }
              }}
          >
              Baixar
          </D.ModalGridEditButton>
          ),
      }
    ];

    setColumns(() => access? columnsAccess: columnsNotAccess)

  }, [access])

  const paginationModel = { page: 0, pageSize: 5 };

  return (
    <D.ModalCard>
        <>
          <D.FWStack direction={"row"} justifyContent={"flex-end"} spacing={2}>
            <D.ContainedButton
              $color="yellow"
              onClick={() => setAddHistorico(true)}
              disabled={addHistorico}
            >
              Incluir ao histórico
            </D.ContainedButton>
            {addHistorico && (
              <D.OutlineButton
                $color="pink"
                onClick={cancelAddHistorico}
                disabled={!addHistorico}
              >
                Cancelar
              </D.OutlineButton>
            )}
          </D.FWStack>
            {addHistorico && (
              <D.FWStack direction={"column"} spacing={2}>
                <TextField
                  name="assunto"
                  label={"Assunto"}
                  fullWidth
                  value={dadosHistorico?.assunto}
                  onChange={handleInputChange}
                />
                <TextField
                  name="descricao"
                  label={"Descrição do documento"}
                  fullWidth
                  multiline
                  rows={3}
                  value={dadosHistorico?.descricao}
                  onChange={handleInputChange}
                />
                <D.FWStack direction={"row"} spacing={2}>
                  <D.ContainedButton
                    variant="contained"
                    sx={{ width: "50%" }}
                    startIcon={<PublishIcon />}
                    onClick={() => inputRef.current?.click()}
                  >
                    Anexar arquivo
                  </D.ContainedButton>
                  <D.VisuallyHiddenInput
                    ref={inputRef}
                    type="file"
                    onChange={
                      (e:any) => handleInputChange(
                        { 
                          target:{ name:"anexo", value:e.target.files![0] } 
                        } as React.ChangeEvent<HTMLInputElement>
                      )
                    }
                  />
                  <TextField
                    label="Nome do arquivo"
                    fullWidth
                    value={dadosHistorico?.anexo?.name ?? ""}
                    inputProps={{ readOnly: true }}
                  />

                  {archive != null && (
                    <IconButton onClick={() => setArchive(null)}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </D.FWStack>
                <D.FWStack direction={"row"} justifyContent={"flex-end"}>
                  <D.ContainedButton onClick={idLog? updateLog: createNewLog}>
                    {loading ? (
                      <CircularProgress size={20} color="secondary" />
                    ) : (
                      <>Salvar</>
                    )}
                  </D.ContainedButton>
                </D.FWStack>
              </D.FWStack>
            )}
          <D.FWStack>
            { loading? <><SkeletonDefaultPage /></>:
            tableData.length > 0 ? (
              <DataGrid
                rows={tableData}
                disableColumnSelector
                disableRowSelectionOnClick
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                columns={columns}
                initialState={{ pagination: { paginationModel } }}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                sx={{ border: 0 }}
              />
            ) : (
              <>Não há dados.</>
            )}
          </D.FWStack>
        </>
    </D.ModalCard>
  );
};

