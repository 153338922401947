import { TextField } from "@mui/material";
import RegisterCard from "../../../../../components/RegisterCard";
import * as C from "../../style";
import * as D from "../../../../../styles/appComponents";
import QRCode from "react-qr-code";
import { EnumCupomBoasVindas } from "../../../../../enums/EnumCupomBoasVindas.enum";
import { ICaptador } from "../../../../../models/Captador";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Swal from "sweetalert2";

interface IDadosAcessoParceiroProps {
  captador: ICaptador;
}

const DadosAcessoParceiro: React.FC<IDadosAcessoParceiroProps> = ({
  captador,
}) => {
  const handleCopy = () => {
    const textToCopy = `${process.env.REACT_APP_APP_URL}Convida/${captador.linkCaptador}`;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Texto copiado para a área de transferência!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch(() => {
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: "Erro ao copiar o texto",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };

  return (
    <RegisterCard title="Dados de acesso">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          value={captador.user}
          label="Login"
          sx={{ width: "80%" }}
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={"não implementado"}
          label="Senha"
          type="password"
          sx={{ width: "80%" }}
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={
            Object.values(EnumCupomBoasVindas)[Number(captador.cupomBoasVindas)]
          }
          label="Cupom de boas vindas destinado para?"
          fullWidth
          inputProps={{ readOnly: true }}
        />
        <TextField
          value={captador.emissorNotaFiscal ? "Sim" : "Não"}
          label="Emissor de nota fiscal?"
          sx={{ width: "70%" }}
          inputProps={{ readOnly: true }}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          value={captador.linkCaptador}
          label="Link do parceiro"
          fullWidth
          inputProps={{ readOnly: true }}
          InputProps={{
            startAdornment: (
              <C.StartTextTextfield>
                {`${process.env.REACT_APP_APP_URL?.replace(
                  "https://",
                  ""
                ).replace("http://", "")}Convida/`}
              </C.StartTextTextfield>
            ),
          }}
        />
        <D.ContainedButton onClick={handleCopy}>
          <ContentCopyIcon />
        </D.ContainedButton>
      </D.FWStack>
      <D.FWStack justifyContent={"flex-end"} alignItems={"flex-end"}>
        <C.QRCodeWrapper>
          <QRCode
            value={`${process.env.REACT_APP_APP_URL}Convida/${captador.linkCaptador}`}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            size={256}
            viewBox={`0 0 256 256`}
          />
        </C.QRCodeWrapper>
      </D.FWStack>
    </RegisterCard>
  );
};

export default DadosAcessoParceiro;
