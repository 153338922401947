export const cbos = [
  {"cod":"0101-05", "desc": "Oficial general da aeronáutica"},
  {"cod":"0101-10", "desc": "Oficial general do exército"},
  {"cod":"0101-15", "desc": "Oficial general da marinha"},
  {"cod":"0102-05", "desc": "Oficial da aeronáutica"},
  {"cod":"0102-10", "desc": "Oficial do exército"},
  {"cod":"0102-15", "desc": "Oficial da marinha"},
  {"cod":"0103-05", "desc": "Praça da aeronáutica"},
  {"cod":"0103-10", "desc": "Praça do exército"},
  {"cod":"0103-15", "desc": "Praça da marinha"},
  {"cod":"0201-05", "desc": "Coronel da polícia militar"},
  {"cod":"0201-10", "desc": "Tenente-coronel da polícia militar"},
  {"cod":"0201-15", "desc": "Major da polícia militar"},
  {"cod":"0202-05", "desc": "Capitão da polícia militar"},
  {"cod":"0203-05", "desc": "Primeiro tenente de polícia militar"},
  {"cod":"0203-10", "desc": "Segundo tenente de polícia militar"},
  {"cod":"0211-05", "desc": "Subtenente da policia militar"},
  {"cod":"0211-10", "desc": "Sargento da policia militar"},
  {"cod":"0212-05", "desc": "Cabo da polícia militar"},
  {"cod":"0212-10", "desc": "Soldado da polícia militar"},
  {"cod":"0301-05", "desc": "Coronel bombeiro militar"},
  {"cod":"0301-10", "desc": "Major bombeiro militar"},
  {"cod":"0301-15", "desc": "Tenente-coronel bombeiro militar"},
  {"cod":"0302-05", "desc": "Capitão bombeiro militar"},
  {"cod":"0303-05", "desc": "Tenente do corpo de bombeiros militar"},
  {"cod":"0311-05", "desc": "Subtenente bombeiro militar"},
  {"cod":"0311-10", "desc": "Sargento bombeiro militar"},
  {"cod":"0312-05", "desc": "Cabo bombeiro militar"},
  {"cod":"0312-10", "desc": "Soldado bombeiro militar"},
  {"cod":"1111-05", "desc": "Senador"},
  {"cod":"1111-10", "desc": "Deputado federal"},
  {"cod":"1111-15", "desc": "Deputado estadual e distrital"},
  {"cod":"1111-20", "desc": "Vereador"},
  {"cod":"1112-05", "desc": "Presidente da república"},
  {"cod":"1112-10", "desc": "Vice-presidente da república"},
  {"cod":"1112-15", "desc": "Ministro de estado"},
  {"cod":"1112-20", "desc": "Secretário - executivo"},
  {"cod":"1112-25", "desc": "Membro superior do poder executivo"},
  {"cod":"1112-30", "desc": "Governador de estado"},
  {"cod":"1112-35", "desc": "Governador do distrito federal"},
  {"cod":"1112-40", "desc": "Vice-governador de estado"},
  {"cod":"1112-45", "desc": "Vice-governador do distrito federal"},
  {"cod":"1112-50", "desc": "Prefeito"},
  {"cod":"1112-55", "desc": "Vice-prefeito"},
  {"cod":"1113-05", "desc": "Ministro do supremo tribunal federal"},
  {"cod":"1113-10", "desc": "Ministro do superior tribunal de justiça"},
  {"cod":"1113-15", "desc": "Ministro do superior tribunal militar"},
  {"cod":"1113-20", "desc": "Ministro do superior tribunal do trabalho"},
  {"cod":"1113-25", "desc": "Juiz de direito"},
  {"cod":"1113-30", "desc": "Juiz federal"},
  {"cod":"1113-35", "desc": "Juiz auditor federal - justiça militar"},
  {"cod":"1113-40", "desc": "Juiz auditor estadual - justiça militar"},
  {"cod":"1113-45", "desc": "Juiz do trabalho"},
  {"cod":"1114-05", "desc": "Dirigente do serviço público federal"},
  {"cod":"1114-10", "desc": "Dirigente do serviço público estadual e distrital"},
  {"cod":"1114-15", "desc": "Dirigente do serviço público municipal"},
  {"cod":"1115-05", "desc": "Especialista de políticas públicas e gestão governamental - eppgg"},
  {"cod":"1115-10", "desc": "Analista de planejamento e orçamento - apo"},
  {"cod":"1130-05", "desc": "Cacique"},
  {"cod":"1130-10", "desc": "Líder de comunidade caiçara"},
  {"cod":"1130-15", "desc": "Membro de liderança quilombola"},
  {"cod":"1141-05", "desc": "Dirigente de partido político"},
  {"cod":"1142-05", "desc": "Dirigentes de entidades de trabalhadores"},
  {"cod":"1142-10", "desc": "Dirigentes de entidades patronais"},
  {"cod":"1143-05", "desc": "Dirigente e administrador de organização religiosa"},
  {"cod":"1144-05", "desc": "Dirigente e administrador de organização da sociedade civil sem fins lucrativos"},
  {"cod":"1210-05", "desc": "Diretor de planejamento estratégico"},
  {"cod":"1210-10", "desc": "Diretor geral de empresa e organizações (exceto de interesse público)"},
  {"cod":"1221-05", "desc": "Diretor de produção e operações em empresa agropecuária"},
  {"cod":"1221-10", "desc": "Diretor de produção e operações em empresa aqüícola"},
  {"cod":"1221-15", "desc": "Diretor de produção e operações em empresa florestal"},
  {"cod":"1221-20", "desc": "Diretor de produção e operações em empresa pesqueira"},
  {"cod":"1222-05", "desc": "Diretor de produção e operações da indústria de transformação, extração mineral e utilidades"},
  {"cod":"1223-05", "desc": "Diretor de operações de obras pública e civil"},
  {"cod":"1224-05", "desc": "Diretor de operações comerciais (comércio atacadista e varejista)"},
  {"cod":"1225-05", "desc": "Diretor de produção e operações de alimentação"},
  {"cod":"1225-10", "desc": "Diretor de produção e operações de hotel"},
  {"cod":"1225-15", "desc": "Diretor de produção e operações de turismo"},
  {"cod":"1226-05", "desc": "Diretor de operações de correios"},
  {"cod":"1226-10", "desc": "Diretor de operações de serviços de armazenamento"},
  {"cod":"1226-15", "desc": "Diretor de operações de serviços de telecomunicações"},
  {"cod":"1226-20", "desc": "Diretor de operações de serviços de transporte"},
  {"cod":"1227-05", "desc": "Diretor comercial em operações de intermediação financeira"},
  {"cod":"1227-10", "desc": "Diretor de produtos bancários"},
  {"cod":"1227-15", "desc": "Diretor de crédito rural"},
  {"cod":"1227-20", "desc": "Diretor de câmbio e comércio exterior"},
  {"cod":"1227-25", "desc": "Diretor de compliance"},
  {"cod":"1227-30", "desc": "Diretor de crédito (exceto crédito imobiliário)"},
  {"cod":"1227-35", "desc": "Diretor de crédito imobiliário"},
  {"cod":"1227-40", "desc": "Diretor de leasing"},
  {"cod":"1227-45", "desc": "Diretor de mercado de capitais"},
  {"cod":"1227-50", "desc": "Diretor de recuperação de créditos em operações de intermediação financeira"},
  {"cod":"1227-55", "desc": "Diretor de riscos de mercado"},
  {"cod":"1231-05", "desc": "Diretor administrativo"},
  {"cod":"1231-10", "desc": "Diretor administrativo e financeiro"},
  {"cod":"1231-15", "desc": "Diretor financeiro"},
  {"cod":"1232-05", "desc": "Diretor de recursos humanos"},
  {"cod":"1232-10", "desc": "Diretor de relações de trabalho"},
  {"cod":"1233-05", "desc": "Diretor comercial"},
  {"cod":"1233-10", "desc": "Diretor de marketing"},
  {"cod":"1234-05", "desc": "Diretor de suprimentos"},
  {"cod":"1234-10", "desc": "Diretor de suprimentos no serviço público"},
  {"cod":"1236-05", "desc": "Diretor de serviços de informática"},
  {"cod":"1237-05", "desc": "Diretor de pesquisa e desenvolvimento (p&d)"},
  {"cod":"1238-05", "desc": "Diretor de manutenção"},
  {"cod":"1311-05", "desc": "Diretor de serviços culturais"},
  {"cod":"1311-10", "desc": "Diretor de serviços sociais"},
  {"cod":"1311-15", "desc": "Gerente de serviços culturais"},
  {"cod":"1311-20", "desc": "Gerente de serviços sociais"},
  {"cod":"1312-05", "desc": "Diretor de serviços de saúde"},
  {"cod":"1312-10", "desc": "Gerente de serviços de saúde"},
  {"cod":"1313-05", "desc": "Diretor de instituição educacional da área privada"},
  {"cod":"1313-10", "desc": "Diretor de instituição educacional pública"},
  {"cod":"1313-15", "desc": "Gerente de instituição educacional da área privada"},
  {"cod":"1313-20", "desc": "Gerente de serviços educacionais da área pública"},
  {"cod":"1411-05", "desc": "Gerente de produção e operações aqüícolas"},
  {"cod":"1411-10", "desc": "Gerente de produção e operações florestais"},
  {"cod":"1411-15", "desc": "Gerente de produção e operações agropecuárias"},
  {"cod":"1411-20", "desc": "Gerente de produção e operações pesqueiras"},
  {"cod":"1412-05", "desc": "Gerente de produção e operações"},
  {"cod":"1413-05", "desc": "Gerente de produção e operações da construção civil e obras públicas"},
  {"cod":"1414-05", "desc": "Comerciante atacadista"},
  {"cod":"1414-10", "desc": "Comerciante varejista"},
  {"cod":"1414-15", "desc": "Gerente de loja e supermercado"},
  {"cod":"1414-20", "desc": "Gerente de operações de serviços de assistência técnica"},
  {"cod":"1415-05", "desc": "Gerente de hotel"},
  {"cod":"1415-10", "desc": "Gerente de restaurante"},
  {"cod":"1415-15", "desc": "Gerente de bar"},
  {"cod":"1415-20", "desc": "Gerente de pensão"},
  {"cod":"1416-05", "desc": "Gerente de operações de transportes"},
  {"cod":"1416-10", "desc": "Gerente de operações de correios e telecomunicações"},
  {"cod":"1416-15", "desc": "Gerente de logística (armazenagem e distribuição)"},
  {"cod":"1417-05", "desc": "Gerente de produtos bancários"},
  {"cod":"1417-10", "desc": "Gerente de agência"},
  {"cod":"1417-15", "desc": "Gerente de câmbio e comércio exterior"},
  {"cod":"1417-20", "desc": "Gerente de crédito e cobrança"},
  {"cod":"1417-25", "desc": "Gerente de crédito imobiliário"},
  {"cod":"1417-30", "desc": "Gerente de crédito rural"},
  {"cod":"1417-35", "desc": "Gerente de recuperação de crédito"},
  {"cod":"1421-05", "desc": "Gerente administrativo"},
  {"cod":"1421-10", "desc": "Gerente de riscos"},
  {"cod":"1421-15", "desc": "Gerente financeiro"},
  {"cod":"1422-05", "desc": "Gerente de recursos humanos"},
  {"cod":"1422-10", "desc": "Gerente de departamento pessoal"},
  {"cod":"1423-05", "desc": "Gerente comercial"},
  {"cod":"1423-10", "desc": "Gerente de comunicação"},
  {"cod":"1423-15", "desc": "Gerente de marketing"},
  {"cod":"1423-20", "desc": "Gerente de vendas"},
  {"cod":"1424-05", "desc": "Gerente de compras"},
  {"cod":"1424-10", "desc": "Gerente de suprimentos"},
  {"cod":"1424-15", "desc": "Gerente de almoxarifado"},
  {"cod":"1425-05", "desc": "Gerente de rede"},
  {"cod":"1425-10", "desc": "Gerente de desenvolvimento de sistemas"},
  {"cod":"1425-15", "desc": "Gerente de produção de tecnologia da informação"},
  {"cod":"1425-20", "desc": "Gerente de projetos de tecnologia da informação"},
  {"cod":"1425-25", "desc": "Gerente de segurança de tecnologia da informação"},
  {"cod":"1425-30", "desc": "Gerente de suporte técnico de tecnologia da informação"},
  {"cod":"1426-05", "desc": "Gerente de pesquisa e desenvolvimento (p&d)"},
  {"cod":"1426-10", "desc": "Especialista em desenvolvimento de cigarros"},
  {"cod":"1427-05", "desc": "Gerente de projetos e serviços de manutenção"},
  {"cod":"2011-05", "desc": "Bioengenheiro"},
  {"cod":"2011-10", "desc": "Biotecnologista"},
  {"cod":"2011-15", "desc": "Geneticista"},
  {"cod":"2012-05", "desc": "Pesquisador em metrologia"},
  {"cod":"2012-10", "desc": "Especialista em calibrações metrológicas"},
  {"cod":"2012-15", "desc": "Especialista em ensaios metrológicos"},
  {"cod":"2012-20", "desc": "Especialista em instrumentação metrológica"},
  {"cod":"2012-25", "desc": "Especialista em materiais de referência metrológica"},
  {"cod":"2021-05", "desc": "Engenheiro mecatrônico"},
  {"cod":"2030-05", "desc": "Pesquisador em biologia ambiental"},
  {"cod":"2030-10", "desc": "Pesquisador em biologia animal"},
  {"cod":"2030-15", "desc": "Pesquisador em biologia de microorganismos e parasitas"},
  {"cod":"2030-20", "desc": "Pesquisador em biologia humana"},
  {"cod":"2030-25", "desc": "Pesquisador em biologia vegetal"},
  {"cod":"2031-05", "desc": "Pesquisador em ciências da computação e informática"},
  {"cod":"2031-10", "desc": "Pesquisador em ciências da terra e meio ambiente"},
  {"cod":"2031-15", "desc": "Pesquisador em física"},
  {"cod":"2031-20", "desc": "Pesquisador em matemática"},
  {"cod":"2031-25", "desc": "Pesquisador em química"},
  {"cod":"2032-05", "desc": "Pesquisador de engenharia civil"},
  {"cod":"2032-10", "desc": "Pesquisador de engenharia e tecnologia (outras áreas da engenharia)"},
  {"cod":"2032-15", "desc": "Pesquisador de engenharia elétrica e eletrônica"},
  {"cod":"2032-20", "desc": "Pesquisador de engenharia mecânica"},
  {"cod":"2032-25", "desc": "Pesquisador de engenharia metalúrgica, de minas e de materiais"},
  {"cod":"2032-30", "desc": "Pesquisador de engenharia química"},
  {"cod":"2033-05", "desc": "Pesquisador de clínica médica"},
  {"cod":"2033-10", "desc": "Pesquisador de medicina básica"},
  {"cod":"2033-15", "desc": "Pesquisador em medicina veterinária"},
  {"cod":"2033-20", "desc": "Pesquisador em saúde coletiva"},
  {"cod":"2034-05", "desc": "Pesquisador em ciências agronômicas"},
  {"cod":"2034-10", "desc": "Pesquisador em ciências da pesca e aqüicultura"},
  {"cod":"2034-15", "desc": "Pesquisador em ciências da zootecnia"},
  {"cod":"2034-20", "desc": "Pesquisador em ciências florestais"},
  {"cod":"2035-05", "desc": "Pesquisador em ciências sociais e humanas"},
  {"cod":"2035-10", "desc": "Pesquisador em economia"},
  {"cod":"2035-15", "desc": "Pesquisador em ciências da educação"},
  {"cod":"2035-20", "desc": "Pesquisador em história"},
  {"cod":"2035-25", "desc": "Pesquisador em psicologia"},
  {"cod":"2041-05", "desc": "Perito criminal"},
  {"cod":"2111-05", "desc": "Atuário"},
  {"cod":"2111-10", "desc": "Especialista em pesquisa operacional"},
  {"cod":"2111-15", "desc": "Matemático"},
  {"cod":"2111-20", "desc": "Matemático aplicado"},
  {"cod":"2112-05", "desc": "Estatístico"},
  {"cod":"2112-10", "desc": "Estatístico (estatística aplicada)"},
  {"cod":"2112-15", "desc": "Estatístico teórico"},
  {"cod":"2122-05", "desc": "Engenheiro de aplicativos em computação"},
  {"cod":"2122-10", "desc": "Engenheiro de equipamentos em computação"},
  {"cod":"2122-15", "desc": "Engenheiros de sistemas operacionais em computação"},
  {"cod":"2123-05", "desc": "Administrador de banco de dados"},
  {"cod":"2123-10", "desc": "Administrador de redes"},
  {"cod":"2123-15", "desc": "Administrador de sistemas operacionais"},
  {"cod":"2124-05", "desc": "Analista de desenvolvimento de sistemas"},
  {"cod":"2124-10", "desc": "Analista de redes e de comunicação de dados"},
  {"cod":"2124-15", "desc": "Analista de sistemas de automação"},
  {"cod":"2124-20", "desc": "Analista de suporte computacional"},
  {"cod":"2131-05", "desc": "Físico"},
  {"cod":"2131-10", "desc": "Físico (acústica)"},
  {"cod":"2131-15", "desc": "Físico (atômica e molecular)"},
  {"cod":"2131-20", "desc": "Físico (cosmologia)"},
  {"cod":"2131-25", "desc": "Físico (estatística e matemática)"},
  {"cod":"2131-30", "desc": "Físico (fluidos)"},
  {"cod":"2131-35", "desc": "Físico (instrumentação)"},
  {"cod":"2131-40", "desc": "Físico (matéria condensada)"},
  {"cod":"2131-45", "desc": "Físico (materiais)"},
  {"cod":"2131-50", "desc": "Físico (medicina)"},
  {"cod":"2131-55", "desc": "Físico (nuclear e reatores)"},
  {"cod":"2131-60", "desc": "Físico (óptica)"},
  {"cod":"2131-65", "desc": "Físico (partículas e campos)"},
  {"cod":"2131-70", "desc": "Físico (plasma)"},
  {"cod":"2131-75", "desc": "Físico (térmica)"},
  {"cod":"2132-05", "desc": "Químico"},
  {"cod":"2132-10", "desc": "Químico industrial"},
  {"cod":"2133-05", "desc": "Astrônomo"},
  {"cod":"2133-10", "desc": "Geofísico espacial"},
  {"cod":"2133-15", "desc": "Meteorologista"},
  {"cod":"2134-05", "desc": "Geólogo"},
  {"cod":"2134-10", "desc": "Geólogo de engenharia"},
  {"cod":"2134-15", "desc": "Geofísico"},
  {"cod":"2134-20", "desc": "Geoquímico"},
  {"cod":"2134-25", "desc": "Hidrogeólogo"},
  {"cod":"2134-30", "desc": "Paleontólogo"},
  {"cod":"2134-35", "desc": "Petrógrafo"},
  {"cod":"2134-40", "desc": "Oceanógrafo"},
  {"cod":"2141-05", "desc": "Arquiteto de edificações"},
  {"cod":"2141-10", "desc": "Arquiteto de interiores"},
  {"cod":"2141-15", "desc": "Arquiteto de patrimônio"},
  {"cod":"2141-20", "desc": "Arquiteto paisagista"},
  {"cod":"2141-25", "desc": "Arquiteto urbanista"},
  {"cod":"2141-30", "desc": "Urbanista"},
  {"cod":"2142-05", "desc": "Engenheiro civil"},
  {"cod":"2142-10", "desc": "Engenheiro civil (aeroportos)"},
  {"cod":"2142-15", "desc": "Engenheiro civil (edificações)"},
  {"cod":"2142-20", "desc": "Engenheiro civil (estruturas metálicas)"},
  {"cod":"2142-25", "desc": "Engenheiro civil (ferrovias e metrovias)"},
  {"cod":"2142-30", "desc": "Engenheiro civil (geotécnia)"},
  {"cod":"2142-35", "desc": "Engenheiro civil (hidrologia)"},
  {"cod":"2142-40", "desc": "Engenheiro civil (hidráulica)"},
  {"cod":"2142-45", "desc": "Engenheiro civil (pontes e viadutos)"},
  {"cod":"2142-50", "desc": "Engenheiro civil (portos e vias navegáveis)"},
  {"cod":"2142-55", "desc": "Engenheiro civil (rodovias)"},
  {"cod":"2142-60", "desc": "Engenheiro civil (saneamento)"},
  {"cod":"2142-65", "desc": "Engenheiro civil (túneis)"},
  {"cod":"2142-70", "desc": "Engenheiro civil (transportes e trânsito)"},
  {"cod":"2142-75", "desc": "Engenheiro ambiental"},
  {"cod":"2143-05", "desc": "Engenheiro eletricista"},
  {"cod":"2143-10", "desc": "Engenheiro eletrônico"},
  {"cod":"2143-15", "desc": "Engenheiro eletricista de manutenção"},
  {"cod":"2143-20", "desc": "Engenheiro eletricista de projetos"},
  {"cod":"2143-25", "desc": "Engenheiro eletrônico de manutenção"},
  {"cod":"2143-30", "desc": "Engenheiro eletrônico de projetos"},
  {"cod":"2143-35", "desc": "Engenheiro de manutenção de telecomunicações"},
  {"cod":"2143-40", "desc": "Engenheiro de telecomunicações"},
  {"cod":"2143-45", "desc": "Engenheiro projetista de telecomunicações"},
  {"cod":"2143-50", "desc": "Engenheiro de redes de comunicação"},
  {"cod":"2143-55", "desc": "Engenheiro de controle e automação"},
  {"cod":"2143-60", "desc": "Tecnólogo em eletricidade"},
  {"cod":"2143-65", "desc": "Tecnólogo em eletrônica"},
  {"cod":"2144-05", "desc": "Engenheiro mecânico"},
  {"cod":"2144-10", "desc": "Engenheiro mecânico automotivo"},
  {"cod":"2144-15", "desc": "Engenheiro mecânico (energia nuclear)"},
  {"cod":"2144-20", "desc": "Engenheiro mecânico industrial"},
  {"cod":"2144-25", "desc": "Engenheiro aeronáutico"},
  {"cod":"2144-30", "desc": "Engenheiro naval"},
  {"cod":"2145-05", "desc": "Engenheiro químico"},
  {"cod":"2145-10", "desc": "Engenheiro químico (indústria química)"},
  {"cod":"2145-15", "desc": "Engenheiro químico (mineração, metalurgia, siderurgia, cimenteira e cerâmica)"},
  {"cod":"2145-20", "desc": "Engenheiro químico (papel e celulose)"},
  {"cod":"2145-25", "desc": "Engenheiro químico (petróleo e borracha)"},
  {"cod":"2145-30", "desc": "Engenheiro químico (utilidades e meio ambiente)"},
  {"cod":"2146-05", "desc": "Engenheiro de materiais"},
  {"cod":"2146-10", "desc": "Engenheiro metalurgista"},
  {"cod":"2147-05", "desc": "Engenheiro de minas"},
  {"cod":"2147-10", "desc": "Engenheiro de minas (beneficiamento)"},
  {"cod":"2147-15", "desc": "Engenheiro de minas (lavra a céu aberto)"},
  {"cod":"2147-20", "desc": "Engenheiro de minas (lavra subterrânea)"},
  {"cod":"2147-25", "desc": "Engenheiro de minas (pesquisa mineral)"},
  {"cod":"2147-30", "desc": "Engenheiro de minas (planejamento)"},
  {"cod":"2147-35", "desc": "Engenheiro de minas (processo)"},
  {"cod":"2147-40", "desc": "Engenheiro de minas (projeto)"},
  {"cod":"2148-05", "desc": "Engenheiro agrimensor"},
  {"cod":"2148-10", "desc": "Engenheiro cartógrafo"},
  {"cod":"2149-05", "desc": "Engenheiro de produção"},
  {"cod":"2149-10", "desc": "Engenheiro de controle de qualidade"},
  {"cod":"2149-15", "desc": "Engenheiro de segurança do trabalho"},
  {"cod":"2149-20", "desc": "Engenheiro de riscos"},
  {"cod":"2149-25", "desc": "Engenheiro de tempos e movimentos"},
  {"cod":"2151-05", "desc": "Agente de manobra e docagem"},
  {"cod":"2151-10", "desc": "Capitão de manobra da marinha mercante"},
  {"cod":"2151-15", "desc": "Comandante da marinha mercante"},
  {"cod":"2151-20", "desc": "Coordenador de operações de combate à poluição no meio aquaviário"},
  {"cod":"2151-25", "desc": "Imediato da marinha mercante"},
  {"cod":"2151-30", "desc": "Inspetor de terminal"},
  {"cod":"2151-35", "desc": "Inspetor naval"},
  {"cod":"2151-40", "desc": "Oficial de quarto de navegação da marinha mercante"},
  {"cod":"2151-45", "desc": "Prático de portos da marinha mercante"},
  {"cod":"2151-50", "desc": "Vistoriador naval"},
  {"cod":"2152-05", "desc": "Oficial superior de máquinas da marinha mercante"},
  {"cod":"2152-10", "desc": "Primeiro oficial de máquinas da marinha mercante"},
  {"cod":"2152-15", "desc": "Segundo oficial de máquinas da marinha mercante"},
  {"cod":"2152-20", "desc": "Superintendente técnico no transporte aquaviário"},
  {"cod":"2153-05", "desc": "Piloto de aeronaves"},
  {"cod":"2153-10", "desc": "Piloto de ensaios em vôo"},
  {"cod":"2153-15", "desc": "Instrutor de vôo"},
  {"cod":"2211-05", "desc": "Biólogo"},
  {"cod":"2212-05", "desc": "Biomédico"},
  {"cod":"2221-05", "desc": "Engenheiro agrícola"},
  {"cod":"2221-10", "desc": "Engenheiro agrônomo"},
  {"cod":"2221-15", "desc": "Engenheiro de pesca"},
  {"cod":"2221-20", "desc": "Engenheiro florestal"},
  {"cod":"2231-01", "desc": "Médico acupunturista"},
  {"cod":"2231-02", "desc": "Médico alergista e imunologista"},
  {"cod":"2231-03", "desc": "Médico anatomopatologista"},
  {"cod":"2231-04", "desc": "Médico anestesiologista"},
  {"cod":"2231-05", "desc": "Médico angiologista"},
  {"cod":"2231-06", "desc": "Médico cardiologista"},
  {"cod":"2231-07", "desc": "Médico cirurgião cardiovascular"},
  {"cod":"2231-08", "desc": "Médico cirurgião de cabeça e pescoço"},
  {"cod":"2231-09", "desc": "Médico cirurgião do aparelho digestivo"},
  {"cod":"2231-10", "desc": "Médico cirurgião geral"},
  {"cod":"2231-11", "desc": "Médico cirurgião pediátrico"},
  {"cod":"2231-12", "desc": "Médico cirurgião plástico"},
  {"cod":"2231-13", "desc": "Médico cirurgião torácico"},
  {"cod":"2231-14", "desc": "Médico citopatologista"},
  {"cod":"2231-15", "desc": "Médico clínico"},
  {"cod":"2231-16", "desc": "Médico de saúde da família"},
  {"cod":"2231-17", "desc": "Médico dermatologista"},
  {"cod":"2231-18", "desc": "Médico do trabalho"},
  {"cod":"2231-19", "desc": "Médico em eletroencefalografia"},
  {"cod":"2231-20", "desc": "Médico em endoscopia"},
  {"cod":"2231-21", "desc": "Médico em medicina de tráfego"},
  {"cod":"2231-22", "desc": "Médico em medicina intensiva"},
  {"cod":"2231-23", "desc": "Médico em medicina nuclear"},
  {"cod":"2231-24", "desc": "Médico em radiologia e diagnóstico por imagem"},
  {"cod":"2231-25", "desc": "Médico endocrinologista e metabologista"},
  {"cod":"2231-26", "desc": "Médico fisiatra"},
  {"cod":"2231-27", "desc": "Médico foniatra"},
  {"cod":"2231-28", "desc": "Médico gastroenterologista"},
  {"cod":"2231-29", "desc": "Médico generalista"},
  {"cod":"2231-30", "desc": "Médico geneticista"},
  {"cod":"2231-31", "desc": "Médico geriatra"},
  {"cod":"2231-32", "desc": "Médico ginecologista e obstetra"},
  {"cod":"2231-33", "desc": "Médico hematologista"},
  {"cod":"2231-34", "desc": "Médico hemoterapeuta"},
  {"cod":"2231-35", "desc": "Médico homeopata"},
  {"cod":"2231-36", "desc": "Médico infectologista"},
  {"cod":"2231-37", "desc": "Médico legista"},
  {"cod":"2231-38", "desc": "Médico mastologista"},
  {"cod":"2231-39", "desc": "Médico nefrologista"},
  {"cod":"2231-40", "desc": "Médico neurocirurgião"},
  {"cod":"2231-41", "desc": "Médico neurofisiologista"},
  {"cod":"2231-42", "desc": "Médico neurologista"},
  {"cod":"2231-43", "desc": "Médico nutrologista"},
  {"cod":"2231-44", "desc": "Médico oftalmologista"},
  {"cod":"2231-45", "desc": "Médico oncologista"},
  {"cod":"2231-46", "desc": "Médico ortopedista e traumatologista"},
  {"cod":"2231-47", "desc": "Médico otorrinolaringologista"},
  {"cod":"2231-48", "desc": "Médico patologista clínico"},
  {"cod":"2231-49", "desc": "Médico pediatra"},
  {"cod":"2231-50", "desc": "Médico perito"},
  {"cod":"2231-51", "desc": "Médico pneumologista"},
  {"cod":"2231-52", "desc": "Médico proctologista"},
  {"cod":"2231-53", "desc": "Médico psiquiatra"},
  {"cod":"2231-54", "desc": "Médico radioterapeuta"},
  {"cod":"2231-55", "desc": "Médico reumatologista"},
  {"cod":"2231-56", "desc": "Médico sanitarista"},
  {"cod":"2231-57", "desc": "Médico urologista"},
  {"cod":"2232-04", "desc": "Cirurgião dentista - auditor"},
  {"cod":"2232-08", "desc": "Cirurgião dentista - clínico geral"},
  {"cod":"2232-12", "desc": "Cirurgião dentista - endodontista"},
  {"cod":"2232-16", "desc": "Cirurgião dentista - epidemiologista"},
  {"cod":"2232-20", "desc": "Cirurgião dentista - estomatologista"},
  {"cod":"2232-24", "desc": "Cirurgião dentista - implantodontista"},
  {"cod":"2232-28", "desc": "Cirurgião dentista - odontogeriatra"},
  {"cod":"2232-32", "desc": "Cirurgião dentista - odontologista legal"},
  {"cod":"2232-36", "desc": "Cirurgião dentista - odontopediatra"},
  {"cod":"2232-40", "desc": "Cirurgião dentista - ortopedista e ortodontista"},
  {"cod":"2232-44", "desc": "Cirurgião dentista - patologista bucal"},
  {"cod":"2232-48", "desc": "Cirurgião dentista - periodontista"},
  {"cod":"2232-52", "desc": "Cirurgião dentista - protesiólogo bucomaxilofacial"},
  {"cod":"2232-56", "desc": "Cirurgião dentista - protesista"},
  {"cod":"2232-60", "desc": "Cirurgião dentista - radiologista"},
  {"cod":"2232-64", "desc": "Cirurgião dentista - reabilitador oral"},
  {"cod":"2232-68", "desc": "Cirurgião dentista - traumatologista bucomaxilofacial"},
  {"cod":"2232-72", "desc": "Cirurgião dentista de saúde coletiva"},
  {"cod":"2232-76", "desc": "Cirurgião dentista - odontologia do trabalho"},
  {"cod":"2232-80", "desc": "Cirurgião dentista - dentística"},
  {"cod":"2232-84", "desc": "Cirurgião dentista - disfunção temporomandibular e dor orofacial"},
  {"cod":"2232-88", "desc": "Cirurgião dentista - odontologia para pacientes com necessidades especiais"},
  {"cod":"2233-05", "desc": "Médico veterinário"},
  {"cod":"2233-10", "desc": "Zootecnista"},
  {"cod":"2234-05", "desc": "Farmacêutico"},
  {"cod":"2234-10", "desc": "Farmacêutico bioquímico"},
  {"cod":"2235-05", "desc": "Enfermeiro"},
  {"cod":"2235-10", "desc": "Enfermeiro auditor"},
  {"cod":"2235-15", "desc": "Enfermeiro de bordo"},
  {"cod":"2235-20", "desc": "Enfermeiro de centro cirúrgico"},
  {"cod":"2235-25", "desc": "Enfermeiro de terapia intensiva"},
  {"cod":"2235-30", "desc": "Enfermeiro do trabalho"},
  {"cod":"2235-35", "desc": "Enfermeiro nefrologista"},
  {"cod":"2235-40", "desc": "Enfermeiro neonatologista"},
  {"cod":"2235-45", "desc": "Enfermeiro obstétrico"},
  {"cod":"2235-50", "desc": "Enfermeiro psiquiátrico"},
  {"cod":"2235-55", "desc": "Enfermeiro puericultor e pediátrico"},
  {"cod":"2235-60", "desc": "Enfermeiro sanitarista"},
  {"cod":"2236-05", "desc": "Fisioterapeuta geral"},
  {"cod":"2236-25", "desc": "Fisioterapeuta respiratória"},
  {"cod":"2236-30", "desc": "Fisioterapeuta neurofuncional"},
  {"cod":"2236-35", "desc": "Fisioterapeuta traumato-ortopédica funcional"},
  {"cod":"2236-40", "desc": "Fisioterapeuta osteopata"},
  {"cod":"2236-45", "desc": "Fisioterapeuta quiropraxista"},
  {"cod":"2236-50", "desc": "Fisioterapeuta acupunturista"},
  {"cod":"2236-55", "desc": "Fisioterapeuta esportivo"},
  {"cod":"2236-60", "desc": "Fisioterapeuta do trabalho"},
  {"cod":"2237-05", "desc": "Dietista"},
  {"cod":"2237-10", "desc": "Nutricionista"},
  {"cod":"2238-10", "desc": "Fonoaudiólogo"},
  {"cod":"2239-05", "desc": "Terapeuta ocupacional"},
  {"cod":"2239-10", "desc": "Ortoptista"},
  {"cod":"2241-05", "desc": "Avaliador físico"},
  {"cod":"2241-10", "desc": "Ludomotricista"},
  {"cod":"2241-15", "desc": "Preparador de atleta"},
  {"cod":"2241-20", "desc": "Preparador físico"},
  {"cod":"2241-25", "desc": "Técnico de desporto individual e coletivo (exceto futebol)"},
  {"cod":"2241-30", "desc": "Técnico de laboratório e fiscalização desportiva"},
  {"cod":"2241-35", "desc": "Treinador profissional de futebol"},
  {"cod":"2311-05", "desc": "Professor de nível superior na educação infantil (quatro a seis anos)"},
  {"cod":"2311-10", "desc": "Professor de nível superior na educação infantil (zero a três anos)"},
  {"cod":"2312-05", "desc": "Professor da educação de jovens e adultos do ensino fundamental (primeira a quarta série)"},
  {"cod":"2312-10", "desc": "Professor de nível superior do ensino fundamental (primeira a quarta série)"},
  {"cod":"2313-05", "desc": "Professor de ciências exatas e naturais do ensino fundamental"},
  {"cod":"2313-10", "desc": "Professor de educação artística do ensino fundamental"},
  {"cod":"2313-15", "desc": "Professor de educação física do ensino fundamental"},
  {"cod":"2313-20", "desc": "Professor de geografia do ensino fundamental"},
  {"cod":"2313-25", "desc": "Professor de história do ensino fundamental"},
  {"cod":"2313-30", "desc": "Professor de língua estrangeira moderna do ensino fundamental"},
  {"cod":"2313-35", "desc": "Professor de língua portuguesa do ensino fundamental"},
  {"cod":"2313-40", "desc": "Professor de matemática do ensino fundamental"},
  {"cod":"2321-05", "desc": "Professor de artes no ensino médio"},
  {"cod":"2321-10", "desc": "Professor de biologia no ensino médio"},
  {"cod":"2321-15", "desc": "Professor de disciplinas pedagógicas no ensino médio"},
  {"cod":"2321-20", "desc": "Professor de educação física no ensino médio"},
  {"cod":"2321-25", "desc": "Professor de filosofia no ensino médio"},
  {"cod":"2321-30", "desc": "Professor de física no ensino médio"},
  {"cod":"2321-35", "desc": "Professor de geografia no ensino médio"},
  {"cod":"2321-40", "desc": "Professor de história no ensino médio"},
  {"cod":"2321-45", "desc": "Professor de língua e literatura brasileira no ensino médio"},
  {"cod":"2321-50", "desc": "Professor de língua estrangeira moderna no ensino médio"},
  {"cod":"2321-55", "desc": "Professor de matemática no ensino médio"},
  {"cod":"2321-60", "desc": "Professor de psicologia no ensino médio"},
  {"cod":"2321-65", "desc": "Professor de química no ensino médio"},
  {"cod":"2321-70", "desc": "Professor de sociologia no ensino médio"},
  {"cod":"2331-05", "desc": "Professor da área de meio ambiente"},
  {"cod":"2331-10", "desc": "Professor de desenho técnico"},
  {"cod":"2331-15", "desc": "Professor de técnicas agrícolas"},
  {"cod":"2331-20", "desc": "Professor de técnicas comerciais e secretariais"},
  {"cod":"2331-25", "desc": "Professor de técnicas de enfermagem"},
  {"cod":"2331-30", "desc": "Professor de técnicas industriais"},
  {"cod":"2331-35", "desc": "Professor de tecnologia e cálculo técnico"},
  {"cod":"2332-05", "desc": "Instrutor de aprendizagem e treinamento agropecuário"},
  {"cod":"2332-10", "desc": "Instrutor de aprendizagem e treinamento industrial"},
  {"cod":"2332-15", "desc": "Professor de aprendizagem e treinamento comercial"},
  {"cod":"2332-20", "desc": "Professor instrutor de ensino e aprendizagem agroflorestal"},
  {"cod":"2332-25", "desc": "Professor instrutor de ensino e aprendizagem em serviços"},
  {"cod":"2341-05", "desc": "Professor de matemática aplicada (no ensino superior)"},
  {"cod":"2341-10", "desc": "Professor de matemática pura (no ensino superior)"},
  {"cod":"2341-15", "desc": "Professor de estatística (no ensino superior)"},
  {"cod":"2341-20", "desc": "Professor de computação (no ensino superior)"},
  {"cod":"2341-25", "desc": "Professor de pesquisa operacional (no ensino superior)"},
  {"cod":"2342-05", "desc": "Professor de física (ensino superior)"},
  {"cod":"2342-10", "desc": "Professor de química (ensino superior)"},
  {"cod":"2342-15", "desc": "Professor de astronomia (ensino superior)"},
  {"cod":"2343-05", "desc": "Professor de arquitetura"},
  {"cod":"2343-10", "desc": "Professor de engenharia"},
  {"cod":"2343-15", "desc": "Professor de geofísica"},
  {"cod":"2343-20", "desc": "Professor de geologia"},
  {"cod":"2344-05", "desc": "Professor de ciências biológicas do ensino superior"},
  {"cod":"2344-10", "desc": "Professor de educação física no ensino superior"},
  {"cod":"2344-15", "desc": "Professor de enfermagem do ensino superior"},
  {"cod":"2344-20", "desc": "Professor de farmácia e bioquímica"},
  {"cod":"2344-25", "desc": "Professor de fisioterapia"},
  {"cod":"2344-30", "desc": "Professor de fonoaudiologia"},
  {"cod":"2344-35", "desc": "Professor de medicina"},
  {"cod":"2344-40", "desc": "Professor de medicina veterinária"},
  {"cod":"2344-45", "desc": "Professor de nutrição"},
  {"cod":"2344-50", "desc": "Professor de odontologia"},
  {"cod":"2344-55", "desc": "Professor de terapia ocupacional"},
  {"cod":"2344-60", "desc": "Professor de zootecnia do ensino superior"},
  {"cod":"2345-05", "desc": "Professor de ensino superior na área de didática"},
  {"cod":"2345-10", "desc": "Professor de ensino superior na área de orientação educacional"},
  {"cod":"2345-15", "desc": "Professor de ensino superior na área de pesquisa educacional"},
  {"cod":"2345-20", "desc": "Professor de ensino superior na área de prática de ensino"},
  {"cod":"2346-04", "desc": "Professor de língua alemã"},
  {"cod":"2346-08", "desc": "Professor de língua italiana"},
  {"cod":"2346-12", "desc": "Professor de língua francesa"},
  {"cod":"2346-16", "desc": "Professor de língua inglesa"},
  {"cod":"2346-20", "desc": "Professor de língua espanhola"},
  {"cod":"2346-24", "desc": "Professor de língua portuguesa"},
  {"cod":"2346-28", "desc": "Professor de literatura brasileira"},
  {"cod":"2346-32", "desc": "Professor de literatura portuguesa"},
  {"cod":"2346-36", "desc": "Professor de literatura alemã"},
  {"cod":"2346-40", "desc": "Professor de literatura comparada"},
  {"cod":"2346-44", "desc": "Professor de literatura espanhola"},
  {"cod":"2346-48", "desc": "Professor de literatura francesa"},
  {"cod":"2346-52", "desc": "Professor de literatura inglesa"},
  {"cod":"2346-56", "desc": "Professor de literatura italiana"},
  {"cod":"2346-60", "desc": "Professor de literatura de línguas estrangeiras modernas"},
  {"cod":"2346-64", "desc": "Professor de outras línguas e literaturas"},
  {"cod":"2346-68", "desc": "Professor de línguas estrangeiras modernas"},
  {"cod":"2346-72", "desc": "Professor de lingüística e lingüística aplicada"},
  {"cod":"2346-76", "desc": "Professor de filologia e crítica textual"},
  {"cod":"2346-80", "desc": "Professor de semiótica"},
  {"cod":"2346-84", "desc": "Professor de teoria da literatura"},
  {"cod":"2347-05", "desc": "Professor de antropologia do ensino superior"},
  {"cod":"2347-10", "desc": "Professor de arquivologia do ensino superior"},
  {"cod":"2347-15", "desc": "Professor de biblioteconomia do ensio superior"},
  {"cod":"2347-20", "desc": "Professor de ciência política do ensino superior"},
  {"cod":"2347-25", "desc": "Professor de comunicação social do ensino superior"},
  {"cod":"2347-30", "desc": "Professor de direito do ensino superior"},
  {"cod":"2347-35", "desc": "Professor de filosofia do ensino superior"},
  {"cod":"2347-40", "desc": "Professor de geografia do ensino superior"},
  {"cod":"2347-45", "desc": "Professor de história do ensino superior"},
  {"cod":"2347-50", "desc": "Professor de jornalismo"},
  {"cod":"2347-55", "desc": "Professor de museologia do ensino superior"},
  {"cod":"2347-60", "desc": "Professor de psicologia do ensino superior"},
  {"cod":"2347-65", "desc": "Professor de serviço social do ensino superior"},
  {"cod":"2347-70", "desc": "Professor de sociologia do ensino superior"},
  {"cod":"2348-05", "desc": "Professor de economia"},
  {"cod":"2348-10", "desc": "Professor de administração"},
  {"cod":"2348-15", "desc": "Professor de contabilidade"},
  {"cod":"2349-05", "desc": "Professor de artes do espetáculo no ensino superior"},
  {"cod":"2349-10", "desc": "Professor de artes visuais no ensino superior (artes plásticas e multimídia)"},
  {"cod":"2349-15", "desc": "Professor de música no ensino superior"},
  {"cod":"2392-05", "desc": "Professor de alunos com deficiência auditiva e surdos"},
  {"cod":"2392-10", "desc": "Professor de alunos com deficiência física"},
  {"cod":"2392-15", "desc": "Professor de alunos com deficiência mental"},
  {"cod":"2392-20", "desc": "Professor de alunos com deficiência múltipla"},
  {"cod":"2392-25", "desc": "Professor de alunos com deficiência visual"},
  {"cod":"2394-05", "desc": "Coordenador pedagógico"},
  {"cod":"2394-10", "desc": "Orientador educacional"},
  {"cod":"2394-15", "desc": "Pedagogo"},
  {"cod":"2394-20", "desc": "Professor de técnicas e recursos audiovisuais"},
  {"cod":"2394-25", "desc": "Psicopedagogo"},
  {"cod":"2394-30", "desc": "Supervisor de ensino"},
  {"cod":"2394-35", "desc": "Designer educacional"},
  {"cod":"2410-05", "desc": "Advogado"},
  {"cod":"2410-10", "desc": "Advogado de empresa"},
  {"cod":"2410-15", "desc": "Advogado (direito civil)"},
  {"cod":"2410-20", "desc": "Advogado (direito público)"},
  {"cod":"2410-25", "desc": "Advogado (direito penal)"},
  {"cod":"2410-30", "desc": "Advogado (áreas especiais)"},
  {"cod":"2410-35", "desc": "Advogado (direito do trabalho)"},
  {"cod":"2410-40", "desc": "Consultor jurídico"},
  {"cod":"2412-05", "desc": "Advogado da união"},
  {"cod":"2412-10", "desc": "Procurador autárquico"},
  {"cod":"2412-15", "desc": "Procurador da fazenda nacional"},
  {"cod":"2412-20", "desc": "Procurador do estado"},
  {"cod":"2412-25", "desc": "Procurador do município"},
  {"cod":"2412-30", "desc": "Procurador federal"},
  {"cod":"2412-35", "desc": "Procurador fundacional"},
  {"cod":"2413-05", "desc": "Oficial de registro de contratos marítimos"},
  {"cod":"2413-10", "desc": "Oficial do registro civil de pessoas juridicas"},
  {"cod":"2413-15", "desc": "Oficial do registro civil de pessoas naturais"},
  {"cod":"2413-20", "desc": "Oficial do registro de distribuições"},
  {"cod":"2413-25", "desc": "Oficial do registro de imóveis"},
  {"cod":"2413-30", "desc": "Oficial do registro de títulos e documentos"},
  {"cod":"2413-35", "desc": "Tabelião de notas"},
  {"cod":"2413-40", "desc": "Tabelião de protestos"},
  {"cod":"2422-05", "desc": "Procurador da república"},
  {"cod":"2422-10", "desc": "Procurador de justiça"},
  {"cod":"2422-15", "desc": "Procurador de justiça militar"},
  {"cod":"2422-20", "desc": "Procurador do trabalho"},
  {"cod":"2422-25", "desc": "Procurador regional da república"},
  {"cod":"2422-30", "desc": "Procurador regional do trabalho"},
  {"cod":"2422-35", "desc": "Promotor de justiça"},
  {"cod":"2422-40", "desc": "Subprocurador de justiça militar"},
  {"cod":"2422-45", "desc": "Subprocurador-geral da república"},
  {"cod":"2422-50", "desc": "Subprocurador-geral do trabalho"},
  {"cod":"2423-05", "desc": "Delegado de polícia"},
  {"cod":"2424-05", "desc": "Defensor público"},
  {"cod":"2424-10", "desc": "Procurador da assistência judiciária"},
  {"cod":"2511-05", "desc": "Antropólogo"},
  {"cod":"2511-10", "desc": "Arqueólogo"},
  {"cod":"2511-15", "desc": "Cientista político"},
  {"cod":"2511-20", "desc": "Sociólogo"},
  {"cod":"2512-05", "desc": "Economista"},
  {"cod":"2512-10", "desc": "Economista agroindustrial"},
  {"cod":"2512-15", "desc": "Economista financeiro"},
  {"cod":"2512-20", "desc": "Economista industrial"},
  {"cod":"2512-25", "desc": "Economista do setor público"},
  {"cod":"2512-30", "desc": "Economista ambiental"},
  {"cod":"2512-35", "desc": "Economista regional e urbano"},
  {"cod":"2513-05", "desc": "Geógrafo"},
  {"cod":"2514-05", "desc": "Filósofo"},
  {"cod":"2515-05", "desc": "Psicólogo educacional"},
  {"cod":"2515-10", "desc": "Psicólogo clínico"},
  {"cod":"2515-15", "desc": "Psicólogo do esporte"},
  {"cod":"2515-20", "desc": "Psicólogo hospitalar"},
  {"cod":"2515-25", "desc": "Psicólogo jurídico"},
  {"cod":"2515-30", "desc": "Psicólogo social"},
  {"cod":"2515-35", "desc": "Psicólogo do trânsito"},
  {"cod":"2515-40", "desc": "Psicólogo do trabalho"},
  {"cod":"2515-45", "desc": "Neuropsicólogo"},
  {"cod":"2515-50", "desc": "Psicanalista"},
  {"cod":"2516-05", "desc": "Assistente social"},
  {"cod":"2516-10", "desc": "Economista doméstico"},
  {"cod":"2521-05", "desc": "Administrador"},
  {"cod":"2522-05", "desc": "Auditor (contadores e afins)"},
  {"cod":"2522-10", "desc": "Contador"},
  {"cod":"2522-15", "desc": "Perito contábil"},
  {"cod":"2523-05", "desc": "Secretária executiva"},
  {"cod":"2523-10", "desc": "Secretário bilíngüe"},
  {"cod":"2523-15", "desc": "Secretária trilíngüe"},
  {"cod":"2524-05", "desc": "Analista de recursos humanos"},
  {"cod":"2525-05", "desc": "Administrador de fundos e carteiras de investimento"},
  {"cod":"2525-10", "desc": "Analista de câmbio"},
  {"cod":"2525-15", "desc": "Analista de cobrança (instituições financeiras)"},
  {"cod":"2525-25", "desc": "Analista de crédito (instituições financeiras)"},
  {"cod":"2525-30", "desc": "Analista de crédito rural"},
  {"cod":"2525-35", "desc": "Analista de leasing"},
  {"cod":"2525-40", "desc": "Analista de produtos bancários"},
  {"cod":"2525-45", "desc": "Analista financeiro (instituições financeiras)"},
  {"cod":"2531-05", "desc": "Relações públicas"},
  {"cod":"2531-10", "desc": "Redator de publicidade"},
  {"cod":"2531-15", "desc": "Agente publicitário"},
  {"cod":"2531-20", "desc": "Analista de negócios"},
  {"cod":"2531-25", "desc": "Analista de pesquisa de mercado"},
  {"cod":"2532-05", "desc": "Gerente de captação (fundos e investimentos institucionais)"},
  {"cod":"2532-10", "desc": "Gerente de clientes especiais (private)"},
  {"cod":"2532-15", "desc": "Gerente de contas - pessoa física e jurídica"},
  {"cod":"2532-20", "desc": "Gerente de grandes contas (corporate)"},
  {"cod":"2532-25", "desc": "Operador de negócios"},
  {"cod":"2533-05", "desc": "Corretor de valores, ativos financeiros, mercadorias e derivativos"},
  {"cod":"2541-05", "desc": "Auditor-fiscal da receita federal"},
  {"cod":"2541-10", "desc": "Técnico da receita federal"},
  {"cod":"2542-05", "desc": "Auditor-fiscal da previdência social"},
  {"cod":"2543-05", "desc": "Auditor-fiscal do trabalho"},
  {"cod":"2543-10", "desc": "Agente de higiene e segurança"},
  {"cod":"2544-05", "desc": "Fiscal de tributos estadual"},
  {"cod":"2544-10", "desc": "Fiscal de tributos municipal"},
  {"cod":"2544-15", "desc": "Técnico de tributos estadual"},
  {"cod":"2544-20", "desc": "Técnico de tributos municipal"},
  {"cod":"2611-05", "desc": "Arquivista pesquisador (jornalismo)"},
  {"cod":"2611-10", "desc": "Assessor de imprensa"},
  {"cod":"2611-15", "desc": "Diretor de redação"},
  {"cod":"2611-20", "desc": "Editor"},
  {"cod":"2611-25", "desc": "Jornalista"},
  {"cod":"2611-30", "desc": "Produtor de texto"},
  {"cod":"2611-35", "desc": "Repórter (exclusive rádio e televisão)"},
  {"cod":"2611-40", "desc": "Revisor de texto"},
  {"cod":"2612-05", "desc": "Bibliotecário"},
  {"cod":"2612-10", "desc": "Documentalista"},
  {"cod":"2612-15", "desc": "Analista de informações (pesquisador de informações de rede)"},
  {"cod":"2613-05", "desc": "Arquivista"},
  {"cod":"2613-10", "desc": "Museólogo"},
  {"cod":"2614-05", "desc": "Filólogo"},
  {"cod":"2614-10", "desc": "Intérprete"},
  {"cod":"2614-15", "desc": "Lingüista"},
  {"cod":"2614-20", "desc": "Tradutor"},
  {"cod":"2614-25", "desc": "Intérprete de língua de sinais"},
  {"cod":"2615-05", "desc": "Autor-roteirista"},
  {"cod":"2615-10", "desc": "Crítico"},
  {"cod":"2615-15", "desc": "Escritor de ficção"},
  {"cod":"2615-20", "desc": "Escritor de não ficção"},
  {"cod":"2615-25", "desc": "Poeta"},
  {"cod":"2615-30", "desc": "Redator de textos técnicos"},
  {"cod":"2616-05", "desc": "Editor de jornal"},
  {"cod":"2616-10", "desc": "Editor de livro"},
  {"cod":"2616-15", "desc": "Editor de mídia eletrônica"},
  {"cod":"2616-20", "desc": "Editor de revista"},
  {"cod":"2616-25", "desc": "Editor de revista científica"},
  {"cod":"2617-10", "desc": "Comentarista de rádio e televisão"},
  {"cod":"2617-15", "desc": "Locutor de rádio e televisão"},
  {"cod":"2617-20", "desc": "Locutor publicitário de rádio e televisão"},
  {"cod":"2617-25", "desc": "Narrador em programas de rádio e televisão"},
  {"cod":"2617-30", "desc": "Repórter de rádio e televisão"},
  {"cod":"2618-05", "desc": "Fotógrafo"},
  {"cod":"2618-10", "desc": "Fotógrafo publicitário"},
  {"cod":"2618-15", "desc": "Fotógrafo retratista"},
  {"cod":"2618-20", "desc": "Repóter fotográfico"},
  {"cod":"2621-05", "desc": "Empresário de espetáculo"},
  {"cod":"2621-10", "desc": "Produtor cinematográfico"},
  {"cod":"2621-15", "desc": "Produtor de rádio"},
  {"cod":"2621-20", "desc": "Produtor de teatro"},
  {"cod":"2621-25", "desc": "Produtor de televisão"},
  {"cod":"2622-05", "desc": "Diretor de cinema"},
  {"cod":"2622-10", "desc": "Diretor de programas de rádio"},
  {"cod":"2622-15", "desc": "Diretor de programas de televisão"},
  {"cod":"2622-20", "desc": "Diretor teatral"},
  {"cod":"2623-05", "desc": "Cenógrafo carnavalesco e festas populares"},
  {"cod":"2623-10", "desc": "Cenógrafo de cinema"},
  {"cod":"2623-15", "desc": "Cenógrafo de eventos"},
  {"cod":"2623-20", "desc": "Cenógrafo de teatro"},
  {"cod":"2623-25", "desc": "Cenógrafo de tv"},
  {"cod":"2623-30", "desc": "Diretor de arte"},
  {"cod":"2624-05", "desc": "Artista (artes visuais)"},
  {"cod":"2624-10", "desc": "Desenhista industrial (designer)"},
  {"cod":"2624-15", "desc": "Conservador-restaurador de bens culturais"},
  {"cod":"2625-05", "desc": "Ator"},
  {"cod":"2626-05", "desc": "Compositor"},
  {"cod":"2626-10", "desc": "Músico arranjador"},
  {"cod":"2626-15", "desc": "Músico regente"},
  {"cod":"2626-20", "desc": "Musicólogo"},
  {"cod":"2627-05", "desc": "Músico intérprete cantor"},
  {"cod":"2627-10", "desc": "Músico intérprete instrumentista"},
  {"cod":"2628-05", "desc": "Assistente de coreografia"},
  {"cod":"2628-10", "desc": "Bailarino (exceto danças populares)"},
  {"cod":"2628-15", "desc": "Coreógrafo"},
  {"cod":"2628-20", "desc": "Dramaturgo de dança"},
  {"cod":"2628-25", "desc": "Ensaiador de dança"},
  {"cod":"2628-30", "desc": "Professor de dança"},
  {"cod":"2629-05", "desc": "Decorador de interiores de nível superior"},
  {"cod":"2631-05", "desc": "Ministro de culto religioso"},
  {"cod":"2631-10", "desc": "Missionário"},
  {"cod":"2631-15", "desc": "Teólogo"},
  {"cod":"3001-05", "desc": "Técnico em mecatrônica - automação da manufatura"},
  {"cod":"3001-10", "desc": "Técnico em mecatrônica - robótica"},
  {"cod":"3003-05", "desc": "Técnico em eletromecânica"},
  {"cod":"3011-05", "desc": "Técnico de laboratório industrial"},
  {"cod":"3011-10", "desc": "Técnico de laboratório de análises físico-químicas (materiais de construção)"},
  {"cod":"3011-15", "desc": "Técnico químico de petróleo"},
  {"cod":"3012-05", "desc": "Técnico de apoio à bioengenharia"},
  {"cod":"3111-05", "desc": "Técnico químico"},
  {"cod":"3111-10", "desc": "Técnico de celulose e papel"},
  {"cod":"3111-15", "desc": "Técnico em curtimento"},
  {"cod":"3112-05", "desc": "Técnico em petroquímica"},
  {"cod":"3113-05", "desc": "Técnico em materiais, produtos cerâmicos e vidros"},
  {"cod":"3114-05", "desc": "Técnico em borracha"},
  {"cod":"3114-10", "desc": "Técnico em plástico"},
  {"cod":"3115-05", "desc": "Técnico de controle de meio ambiente"},
  {"cod":"3115-10", "desc": "Técnico de meteorologia"},
  {"cod":"3115-15", "desc": "Técnico de utilidade (produção e distribuição de vapor, gases, óleos, combustíveis, energia)"},
  {"cod":"3115-20", "desc": "Técnico em tratamento de efluentes"},
  {"cod":"3116-05", "desc": "Técnico têxtil"},
  {"cod":"3116-10", "desc": "Técnico têxtil (tratamentos químicos)"},
  {"cod":"3116-15", "desc": "Técnico têxtil de fiação"},
  {"cod":"3116-20", "desc": "Técnico têxtil de malharia"},
  {"cod":"3116-25", "desc": "Técnico têxtil de tecelagem"},
  {"cod":"3117-05", "desc": "Colorista de papel"},
  {"cod":"3117-10", "desc": "Colorista têxtil"},
  {"cod":"3117-15", "desc": "Preparador de tintas"},
  {"cod":"3117-20", "desc": "Preparador de tintas (fábrica de tecidos)"},
  {"cod":"3117-25", "desc": "Tingidor de couros e peles"},
  {"cod":"3121-05", "desc": "Técnico de obras civis"},
  {"cod":"3122-05", "desc": "Técnico de estradas"},
  {"cod":"3122-10", "desc": "Técnico de saneamento"},
  {"cod":"3123-05", "desc": "Técnico em agrimensura"},
  {"cod":"3123-10", "desc": "Técnico em geodésia e cartografia"},
  {"cod":"3123-15", "desc": "Técnico em hidrografia"},
  {"cod":"3123-20", "desc": "Topógrafo"},
  {"cod":"3131-05", "desc": "Eletrotécnico"},
  {"cod":"3131-10", "desc": "Eletrotécnico (produção de energia)"},
  {"cod":"3131-15", "desc": "Eletroténico na fabricação, montagem e instalação de máquinas e equipamentos"},
  {"cod":"3131-20", "desc": "Técnico de manutenção elétrica"},
  {"cod":"3131-25", "desc": "Técnico de manutenção elétrica de máquina"},
  {"cod":"3131-30", "desc": "Técnico eletricista"},
  {"cod":"3132-05", "desc": "Técnico de manutenção eletrônica"},
  {"cod":"3132-10", "desc": "Técnico de manutenção eletrônica (circuitos de máquinas com comando numérico)"},
  {"cod":"3132-15", "desc": "Técnico eletrônico"},
  {"cod":"3132-20", "desc": "Técnico em manutenção de equipamentos de informática"},
  {"cod":"3133-05", "desc": "Técnico de comunicação de dados"},
  {"cod":"3133-10", "desc": "Técnico de rede (telecomunicações)"},
  {"cod":"3133-15", "desc": "Técnico de telecomunicações (telefonia)"},
  {"cod":"3133-20", "desc": "Técnico de transmissão (telecomunicações)"},
  {"cod":"3134-05", "desc": "Técnico em calibração"},
  {"cod":"3134-10", "desc": "Técnico em instrumentação"},
  {"cod":"3134-15", "desc": "Encarregado de manutenção de instrumentos de controle, medição e similares"},
  {"cod":"3135-05", "desc": "Técnico em fotônica"},
  {"cod":"3141-05", "desc": "Técnico em mecânica de precisão"},
  {"cod":"3141-10", "desc": "Técnico mecânico"},
  {"cod":"3141-15", "desc": "Técnico mecânico (calefação, ventilação e refrigeração)"},
  {"cod":"3141-20", "desc": "Técnico mecânico (máquinas)"},
  {"cod":"3141-25", "desc": "Técnico mecânico (motores)"},
  {"cod":"3142-05", "desc": "Técnico mecânico na fabricação de ferramentas"},
  {"cod":"3142-10", "desc": "Técnico mecânico na manutenção de ferramentas"},
  {"cod":"3143-05", "desc": "Técnico em automobilística"},
  {"cod":"3143-10", "desc": "Técnico mecânico (aeronaves)"},
  {"cod":"3143-15", "desc": "Técnico mecânico (embarcações)"},
  {"cod":"3144-05", "desc": "Técnico de manutenção de sistemas e instrumentos"},
  {"cod":"3144-10", "desc": "Técnico em manutenção de máquinas"},
  {"cod":"3146-05", "desc": "Inspetor de soldagem"},
  {"cod":"3146-10", "desc": "Técnico em caldeiraria"},
  {"cod":"3146-15", "desc": "Técnico em estruturas metálicas"},
  {"cod":"3146-20", "desc": "Técnico em soldagem"},
  {"cod":"3147-05", "desc": "Técnico de acabamento em siderurgia"},
  {"cod":"3147-10", "desc": "Técnico de aciaria em siderurgia"},
  {"cod":"3147-15", "desc": "Técnico de fundição em siderurgia"},
  {"cod":"3147-20", "desc": "Técnico de laminação em siderurgia"},
  {"cod":"3147-25", "desc": "Técnico de redução na siderurgia (primeira fusão)"},
  {"cod":"3147-30", "desc": "Técnico de refratário em siderurgia"},
  {"cod":"3161-05", "desc": "Técnico em geofísica"},
  {"cod":"3161-10", "desc": "Técnico em geologia"},
  {"cod":"3161-15", "desc": "Técnico em geoquímica"},
  {"cod":"3161-20", "desc": "Técnico em geotecnia"},
  {"cod":"3163-05", "desc": "Técnico de mineração"},
  {"cod":"3163-10", "desc": "Técnico de mineração (óleo e petróleo)"},
  {"cod":"3163-15", "desc": "Técnico em processamento mineral (exceto petróleo)"},
  {"cod":"3163-20", "desc": "Técnico em pesquisa mineral"},
  {"cod":"3163-25", "desc": "Técnico de produção em refino de petróleo"},
  {"cod":"3163-30", "desc": "Técnico em planejamento de lavra de minas"},
  {"cod":"3163-35", "desc": "Desincrustador (poços de petróleo)"},
  {"cod":"3163-40", "desc": "Cimentador (poços de petróleo)"},
  {"cod":"3171-05", "desc": "Programador de internet"},
  {"cod":"3171-10", "desc": "Programador de sistemas de informação"},
  {"cod":"3171-15", "desc": "Programador de máquinas - ferramenta com comando numérico"},
  {"cod":"3171-20", "desc": "Programador de multimídia"},
  {"cod":"3172-05", "desc": "Operador de computador (inclusive microcomputador)"},
  {"cod":"3172-10", "desc": "Técnico de apoio ao usuário de informática (helpdesk)"},
  {"cod":"3180-05", "desc": "Desenhista técnico"},
  {"cod":"3180-10", "desc": "Desenhista copista"},
  {"cod":"3180-15", "desc": "Desenhista detalhista"},
  {"cod":"3181-05", "desc": "Desenhista técnico (arquitetura)"},
  {"cod":"3181-10", "desc": "Desenhista técnico (cartografia)"},
  {"cod":"3181-15", "desc": "Desenhista técnico (construção civil)"},
  {"cod":"3181-20", "desc": "Desenhista técnico (instalações hidrossanitárias)"},
  {"cod":"3182-05", "desc": "Desenhista técnico mecânico"},
  {"cod":"3182-10", "desc": "Desenhista técnico aeronáutico"},
  {"cod":"3182-15", "desc": "Desenhista técnico naval"},
  {"cod":"3183-05", "desc": "Desenhista técnico (eletricidade e eletrônica)"},
  {"cod":"3183-10", "desc": "Desenhista técnico (calefação, ventilação e refrigeração)"},
  {"cod":"3184-05", "desc": "Desenhista técnico (artes gráficas)"},
  {"cod":"3184-10", "desc": "Desenhista técnico (ilustrações artísticas)"},
  {"cod":"3184-15", "desc": "Desenhista técnico (ilustrações técnicas)"},
  {"cod":"3184-20", "desc": "Desenhista técnico (indústria têxtil)"},
  {"cod":"3184-25", "desc": "Desenhista técnico (mobiliário)"},
  {"cod":"3184-30", "desc": "Desenhista técnico de embalagens, maquetes e leiautes"},
  {"cod":"3185-05", "desc": "Desenhista projetista de arquitetura"},
  {"cod":"3185-10", "desc": "Desenhista projetista de construção civil"},
  {"cod":"3186-05", "desc": "Desenhista projetista de máquinas"},
  {"cod":"3186-10", "desc": "Desenhista projetista mecânico"},
  {"cod":"3187-05", "desc": "Desenhista projetista de eletricidade"},
  {"cod":"3187-10", "desc": "Desenhista projetista eletrônico"},
  {"cod":"3188-05", "desc": "Projetista de móveis"},
  {"cod":"3188-10", "desc": "Modelista de roupas"},
  {"cod":"3188-15", "desc": "Modelista de calçados"},
  {"cod":"3191-05", "desc": "Técnico em calçados e artefatos de couro"},
  {"cod":"3191-10", "desc": "Técnico em confecções do vestuário"},
  {"cod":"3192-05", "desc": "Técnico do mobiliário"},
  {"cod":"3201-05", "desc": "Técnico em bioterismo"},
  {"cod":"3201-10", "desc": "Técnico em histologia"},
  {"cod":"3211-05", "desc": "Técnico agrícola"},
  {"cod":"3211-10", "desc": "Técnico agropecuário"},
  {"cod":"3212-05", "desc": "Técnico em madeira"},
  {"cod":"3212-10", "desc": "Técnico florestal"},
  {"cod":"3213-05", "desc": "Técnico em piscicultura"},
  {"cod":"3213-10", "desc": "Técnico em carcinicultura"},
  {"cod":"3213-15", "desc": "Técnico em mitilicultura"},
  {"cod":"3213-20", "desc": "Técnico em ranicultura"},
  {"cod":"3221-05", "desc": "Técnico em acupuntura"},
  {"cod":"3221-10", "desc": "Podólogo"},
  {"cod":"3221-15", "desc": "Técnico em quiropraxia"},
  {"cod":"3221-20", "desc": "Massoterapeuta"},
  {"cod":"3221-25", "desc": "Terapeuta holístico"},
  {"cod":"3222-05", "desc": "Técnico de enfermagem"},
  {"cod":"3222-10", "desc": "Técnico de enfermagem de terapia intensiva"},
  {"cod":"3222-15", "desc": "Técnico de enfermagem do trabalho"},
  {"cod":"3222-20", "desc": "Técnico de enfermagem psiquiátrica"},
  {"cod":"3222-25", "desc": "Instrumentador cirúrgico"},
  {"cod":"3222-30", "desc": "Auxiliar de enfermagem"},
  {"cod":"3222-35", "desc": "Auxiliar de enfermagem do trabalho"},
  {"cod":"3222-40", "desc": "Auxiliar de saúde (navegação marítima)"},
  {"cod":"3223-05", "desc": "Técnico em óptica e optometria"},
  {"cod":"3224-05", "desc": "Técnico em higiene dental"},
  {"cod":"3224-10", "desc": "Protético dentário"},
  {"cod":"3224-15", "desc": "Atendente de consultório dentário"},
  {"cod":"3224-20", "desc": "Auxiliar de prótese dentária"},
  {"cod":"3225-05", "desc": "Técnico de ortopedia"},
  {"cod":"3226-05", "desc": "Técnico de imobilização ortopédica"},
  {"cod":"3231-05", "desc": "Técnico em pecuária"},
  {"cod":"3241-05", "desc": "Técnico em métodos eletrográficos em encefalografia"},
  {"cod":"3241-10", "desc": "Técnico em métodos gráficos em cardiologia"},
  {"cod":"3241-15", "desc": "Técnico em radiologia e imagenologia"},
  {"cod":"3242-05", "desc": "Técnico em patologia clínica"},
  {"cod":"3242-10", "desc": "Auxiliar técnico em patologia clínica"},
  {"cod":"3250-05", "desc": "Enólogo"},
  {"cod":"3250-10", "desc": "Aromista"},
  {"cod":"3250-15", "desc": "Perfumista"},
  {"cod":"3251-05", "desc": "Auxiliar técnico em laboratório de farmácia"},
  {"cod":"3251-10", "desc": "Técnico em laboratório de farmácia"},
  {"cod":"3251-15", "desc": "Técnico em farmácia"},
  {"cod":"3252-05", "desc": "Técnico de alimentos"},
  {"cod":"3252-10", "desc": "Técnico em nutrição e dietética"},
  {"cod":"3253-05", "desc": "Técnico em biotecnologia"},
  {"cod":"3253-10", "desc": "Técnico em imunobiológicos"},
  {"cod":"3281-05", "desc": "Embalsamador"},
  {"cod":"3281-10", "desc": "Taxidermista"},
  {"cod":"3311-05", "desc": "Professor de nível médio na educação infantil"},
  {"cod":"3311-10", "desc": "Auxiliar de desenvolvimento infantil"},
  {"cod":"3312-05", "desc": "Professor de nível médio no ensino fundamental"},
  {"cod":"3313-05", "desc": "Professor de nível médio no ensino profissionalizante"},
  {"cod":"3321-05", "desc": "Professor leigo no ensino fundamental"},
  {"cod":"3322-05", "desc": "Professor prático no ensino profissionalizante"},
  {"cod":"3331-05", "desc": "Instrutor de auto-escola"},
  {"cod":"3331-10", "desc": "Instrutor de cursos livres"},
  {"cod":"3331-15", "desc": "Professores de cursos livres"},
  {"cod":"3341-05", "desc": "Inspetor de alunos de escola privada"},
  {"cod":"3341-10", "desc": "Inspetor de alunos de escola pública"},
  {"cod":"3411-05", "desc": "Piloto comercial (exceto linhas aéreas)"},
  {"cod":"3411-10", "desc": "Piloto comercial de helicóptero (exceto linhas aéreas)"},
  {"cod":"3411-15", "desc": "Mecânico de vôo"},
  {"cod":"3411-20", "desc": "Piloto agrícola"},
  {"cod":"3412-05", "desc": "Contramestre de cabotagem"},
  {"cod":"3412-10", "desc": "Mestre de cabotagem"},
  {"cod":"3412-15", "desc": "Mestre fluvial"},
  {"cod":"3412-20", "desc": "Patrão de pesca de alto-mar"},
  {"cod":"3412-25", "desc": "Patrão de pesca na navegação interior"},
  {"cod":"3412-30", "desc": "Piloto fluvial"},
  {"cod":"3413-05", "desc": "Condutor maquinista fluvial"},
  {"cod":"3413-10", "desc": "Condutor maquinista marítimo"},
  {"cod":"3413-15", "desc": "Eletricista de bordo"},
  {"cod":"3421-05", "desc": "Analista de transporte em comércio exterior"},
  {"cod":"3421-10", "desc": "Operador de transporte multimodal"},
  {"cod":"3421-15", "desc": "Controlador de serviços de máquinas e veículos"},
  {"cod":"3421-20", "desc": "Afretador"},
  {"cod":"3422-05", "desc": "Ajudante de despachante aduaneiro"},
  {"cod":"3422-10", "desc": "Despachante aduaneiro"},
  {"cod":"3423-05", "desc": "Chefe de serviço de transporte rodoviário (passageiros e cargas)"},
  {"cod":"3423-10", "desc": "Inspetor de serviços de transportes rodoviários (passageiros e cargas)"},
  {"cod":"3423-15", "desc": "Supervisor de carga e descarga"},
  {"cod":"3424-05", "desc": "Agente de estação (ferrovia e metrô)"},
  {"cod":"3424-10", "desc": "Operador de centro de controle (ferrovia e metrô)"},
  {"cod":"3425-05", "desc": "Controlador de tráfego aéreo"},
  {"cod":"3425-10", "desc": "Despachante operacional de vôo"},
  {"cod":"3425-15", "desc": "Fiscal de aviação civil (fac)"},
  {"cod":"3425-20", "desc": "Gerente da administração de aeroportos"},
  {"cod":"3425-25", "desc": "Gerente de empresa aérea em aeroportos"},
  {"cod":"3425-30", "desc": "Inspetor de aviação civil"},
  {"cod":"3425-35", "desc": "Operador de atendimento aeroviário"},
  {"cod":"3425-40", "desc": "Supervisor da administração de aeroportos"},
  {"cod":"3425-45", "desc": "Supervisor de empresa aérea em aeroportos"},
  {"cod":"3426-05", "desc": "Chefe de estação portuária"},
  {"cod":"3426-10", "desc": "Supervisor de operações portuárias"},
  {"cod":"3511-05", "desc": "Técnico de contabilidade"},
  {"cod":"3511-10", "desc": "Chefe de contabilidade (técnico)"},
  {"cod":"3511-15", "desc": "Consultor contábil (técnico)"},
  {"cod":"3513-05", "desc": "Técnico em administração"},
  {"cod":"3513-10", "desc": "Técnico em administração de comércio exterior"},
  {"cod":"3513-15", "desc": "Agente de recrutamento e seleção"},
  {"cod":"3514-05", "desc": "Escrevente"},
  {"cod":"3514-10", "desc": "Escrivão judicial"},
  {"cod":"3514-15", "desc": "Escrivão extra - judicial"},
  {"cod":"3514-20", "desc": "Escrivão de polícia"},
  {"cod":"3514-25", "desc": "Oficial de justiça"},
  {"cod":"3514-30", "desc": "Auxiliar de serviços jurídicos"},
  {"cod":"3515-05", "desc": "Técnico em secretariado"},
  {"cod":"3515-10", "desc": "Taquígrafo"},
  {"cod":"3515-15", "desc": "Estenotipista"},
  {"cod":"3516-05", "desc": "Técnico em segurança no trabalho"},
  {"cod":"3517-05", "desc": "Analista de seguros (técnico)"},
  {"cod":"3517-10", "desc": "Analista de sinistros"},
  {"cod":"3517-15", "desc": "Assistente comercial de seguros"},
  {"cod":"3517-20", "desc": "Assistente técnico de seguros"},
  {"cod":"3517-25", "desc": "Inspetor de risco"},
  {"cod":"3517-30", "desc": "Inspetor de sinistros"},
  {"cod":"3517-35", "desc": "Técnico de resseguros"},
  {"cod":"3517-40", "desc": "Técnico de seguros"},
  {"cod":"3518-05", "desc": "Detetive profissional"},
  {"cod":"3518-10", "desc": "Investigador de polícia"},
  {"cod":"3518-15", "desc": "Papiloscopista policial"},
  {"cod":"3522-05", "desc": "Agente de defesa ambiental"},
  {"cod":"3522-10", "desc": "Agente de saúde pública"},
  {"cod":"3523-05", "desc": "Metrologista"},
  {"cod":"3523-10", "desc": "Agente fiscal de qualidade"},
  {"cod":"3523-15", "desc": "Agente fiscal metrológico"},
  {"cod":"3523-20", "desc": "Agente fiscal têxtil"},
  {"cod":"3524-05", "desc": "Agente de direitos autorais"},
  {"cod":"3524-10", "desc": "Avaliador de produtos do meio de comunicação"},
  {"cod":"3524-15", "desc": "Ouvidor (ombudsman) do meio de comunicação"},
  {"cod":"3524-20", "desc": "Técnico em direitos autorais"},
  {"cod":"3532-05", "desc": "Técnico de operações e serviços bancários - câmbio"},
  {"cod":"3532-10", "desc": "Técnico de operações e serviços bancários - crédito imobiliário"},
  {"cod":"3532-15", "desc": "Técnico de operações e serviços bancários - crédito rural"},
  {"cod":"3532-20", "desc": "Técnico de operações e serviços bancários - leasing"},
  {"cod":"3532-25", "desc": "Técnico de operações e serviços bancários - renda fixa e variável"},
  {"cod":"3532-30", "desc": "Tesoureiro de banco"},
  {"cod":"3532-35", "desc": "Chefe de serviços bancários"},
  {"cod":"3541-10", "desc": "Agenciador de propaganda"},
  {"cod":"3541-20", "desc": "Agente de vendas de serviços"},
  {"cod":"3541-25", "desc": "Assistente de vendas"},
  {"cod":"3541-30", "desc": "Promotor de vendas especializado"},
  {"cod":"3541-35", "desc": "Técnico de vendas"},
  {"cod":"3541-40", "desc": "Técnico em atendimento e vendas"},
  {"cod":"3541-45", "desc": "Vendedor pracista"},
  {"cod":"3542-05", "desc": "Comprador"},
  {"cod":"3542-10", "desc": "Supervisor de compras"},
  {"cod":"3543-05", "desc": "Analista de exportação e importação"},
  {"cod":"3544-05", "desc": "Leiloeiro"},
  {"cod":"3544-10", "desc": "Avaliador de imóveis"},
  {"cod":"3544-15", "desc": "Avaliador de bens móveis"},
  {"cod":"3545-05", "desc": "Corretor de seguros"},
  {"cod":"3546-05", "desc": "Corretor de imóveis"},
  {"cod":"3547-05", "desc": "Representante comercial autônomo"},
  {"cod":"3548-05", "desc": "Técnico em turismo"},
  {"cod":"3548-10", "desc": "Operador de turismo"},
  {"cod":"3548-15", "desc": "Agente de viagem"},
  {"cod":"3548-20", "desc": "Organizador de evento"},
  {"cod":"3711-05", "desc": "Auxiliar de biblioteca"},
  {"cod":"3711-10", "desc": "Técnico em biblioteconomia"},
  {"cod":"3712-05", "desc": "Colecionador de selos e moedas"},
  {"cod":"3712-10", "desc": "Técnico em museologia"},
  {"cod":"3713-05", "desc": "Técnico em programação visual"},
  {"cod":"3713-10", "desc": "Técnico gráfico"},
  {"cod":"3714-05", "desc": "Recreador de acantonamento"},
  {"cod":"3714-10", "desc": "Recreador"},
  {"cod":"3721-05", "desc": "Diretor de fotografia"},
  {"cod":"3721-10", "desc": "Iluminador (televisão)"},
  {"cod":"3721-15", "desc": "Operador de câmera de televisão"},
  {"cod":"3722-05", "desc": "Operador de rede de teleprocessamento"},
  {"cod":"3722-10", "desc": "Radiotelegrafista"},
  {"cod":"3731-05", "desc": "Operador de áudio de continuidade (rádio)"},
  {"cod":"3731-10", "desc": "Operador de central de rádio"},
  {"cod":"3731-15", "desc": "Operador de externa (rádio)"},
  {"cod":"3731-20", "desc": "Operador de gravação de rádio"},
  {"cod":"3731-25", "desc": "Operador de transmissor de rádio"},
  {"cod":"3732-05", "desc": "Técnico em operação de equipamentos de produção para televisão e produtoras de vídeo"},
  {"cod":"3732-10", "desc": "Técnico em operação de equipamento de exibição de televisão"},
  {"cod":"3732-15", "desc": "Técnico em operação de equipamentos de transmissão/recepção de televisão"},
  {"cod":"3732-20", "desc": "Supervisor técnico operacional de sistemas de televisão e produtoras de vídeo"},
  {"cod":"3741-05", "desc": "Técnico em gravação de áudio"},
  {"cod":"3741-10", "desc": "Técnico em instalação de equipamentos de áudio"},
  {"cod":"3741-15", "desc": "Técnico em masterização de áudio"},
  {"cod":"3741-20", "desc": "Projetista de som"},
  {"cod":"3741-25", "desc": "Técnico em sonorização"},
  {"cod":"3741-30", "desc": "Técnico em mixagem de áudio"},
  {"cod":"3741-35", "desc": "Projetista de sistemas de áudio"},
  {"cod":"3741-40", "desc": "Microfonista"},
  {"cod":"3742-05", "desc": "Cenotécnico (cinema, vídeo, televisão, teatro e espetáculos)"},
  {"cod":"3742-10", "desc": "Maquinista de cinema e vídeo"},
  {"cod":"3742-15", "desc": "Maquinista de teatro e espetáculos"},
  {"cod":"3743-05", "desc": "Operador de projetor cinematográfico"},
  {"cod":"3743-10", "desc": "Operador-mantenedor de projetor cinematográfico"},
  {"cod":"3744-05", "desc": "Editor de tv e vídeo"},
  {"cod":"3744-10", "desc": "Finalizador de filmes"},
  {"cod":"3744-15", "desc": "Finalizador de vídeo"},
  {"cod":"3744-20", "desc": "Montador de filmes"},
  {"cod":"3751-05", "desc": "Designer de interiores"},
  {"cod":"3751-10", "desc": "Designer de vitrines"},
  {"cod":"3751-15", "desc": "Visual merchandiser"},
  {"cod":"3761-05", "desc": "Dançarino tradicional"},
  {"cod":"3761-10", "desc": "Dançarino popular"},
  {"cod":"3762-05", "desc": "Acrobata"},
  {"cod":"3762-10", "desc": "Artista aéreo"},
  {"cod":"3762-15", "desc": "Artista de circo (outros)"},
  {"cod":"3762-20", "desc": "Contorcionista"},
  {"cod":"3762-25", "desc": "Domador de animais (circense)"},
  {"cod":"3762-30", "desc": "Equilibrista"},
  {"cod":"3762-35", "desc": "Mágico"},
  {"cod":"3762-40", "desc": "Malabarista"},
  {"cod":"3762-45", "desc": "Palhaço"},
  {"cod":"3762-50", "desc": "Titeriteiro"},
  {"cod":"3762-55", "desc": "Trapezista"},
  {"cod":"3763-05", "desc": "Apresentador de eventos"},
  {"cod":"3763-10", "desc": "Apresentador de festas populares"},
  {"cod":"3763-15", "desc": "Apresentador de programas de rádio"},
  {"cod":"3763-20", "desc": "Apresentador de programas de televisão"},
  {"cod":"3763-25", "desc": "Apresentador de circo"},
  {"cod":"3764-05", "desc": "Modelo artístico"},
  {"cod":"3764-10", "desc": "Modelo de modas"},
  {"cod":"3764-15", "desc": "Modelo publicitário"},
  {"cod":"3771-05", "desc": "Atleta profissional (outras modalidades)"},
  {"cod":"3771-10", "desc": "Atleta profissional de futebol"},
  {"cod":"3771-15", "desc": "Atleta profissional de golfe"},
  {"cod":"3771-20", "desc": "Atleta profissional de luta"},
  {"cod":"3771-25", "desc": "Atleta profissional de tênis"},
  {"cod":"3771-30", "desc": "Jóquei"},
  {"cod":"3771-35", "desc": "Piloto de competição automobilística"},
  {"cod":"3771-40", "desc": "Profissional de atletismo"},
  {"cod":"3771-45", "desc": "Pugilista"},
  {"cod":"3911-05", "desc": "Cronoanalista"},
  {"cod":"3911-10", "desc": "Cronometrista"},
  {"cod":"3911-15", "desc": "Controlador de entrada e saída"},
  {"cod":"3911-20", "desc": "Planejista"},
  {"cod":"3911-25", "desc": "Técnico de planejamento de produção"},
  {"cod":"3911-30", "desc": "Técnico de planejamento e programação da manutenção"},
  {"cod":"3911-35", "desc": "Técnico de matéria-prima e material"},
  {"cod":"3912-05", "desc": "Inspetor de qualidade"},
  {"cod":"3912-10", "desc": "Técnico de garantia da qualidade"},
  {"cod":"3912-15", "desc": "Operador de inspeção de qualidade"},
  {"cod":"3912-20", "desc": "Técnico de painel de controle"},
  {"cod":"3912-25", "desc": "Escolhedor de papel"},
  {"cod":"3912-30", "desc": "Técnico operacional de serviços de correios"},
  {"cod":"3951-05", "desc": "Técnico de apoio em pesquisa e desenvolvimento (exceto agropecuário e florestal)"},
  {"cod":"3951-10", "desc": "Técnico de apoio em pesquisa e desenvolvimento agropecuário florestal"},
  {"cod":"4101-05", "desc": "Supervisor administrativo"},
  {"cod":"4102-05", "desc": "Supervisor de almoxarifado"},
  {"cod":"4102-10", "desc": "Supervisor de câmbio"},
  {"cod":"4102-15", "desc": "Supervisor de contas a pagar"},
  {"cod":"4102-20", "desc": "Supervisor de controle patrimonial"},
  {"cod":"4102-25", "desc": "Supervisor de crédito e cobrança"},
  {"cod":"4102-30", "desc": "Supervisor de orçamento"},
  {"cod":"4102-35", "desc": "Supervisor de tesouraria"},
  {"cod":"4110-05", "desc": "Auxiliar de escritório, em geral"},
  {"cod":"4110-10", "desc": "Assistente administrativo"},
  {"cod":"4110-15", "desc": "Atendente de judiciário"},
  {"cod":"4110-20", "desc": "Auxiliar de judiciário"},
  {"cod":"4110-25", "desc": "Auxiliar de cartório"},
  {"cod":"4110-30", "desc": "Auxiliar de pessoal"},
  {"cod":"4110-35", "desc": "Auxiliar de estatística"},
  {"cod":"4110-40", "desc": "Auxiliar de seguros"},
  {"cod":"4110-45", "desc": "Auxiliar de serviços de importação e exportação"},
  {"cod":"4121-05", "desc": "Datilógrafo"},
  {"cod":"4121-10", "desc": "Digitador"},
  {"cod":"4121-15", "desc": "Operador de mensagens de telecomunicações (correios)"},
  {"cod":"4121-20", "desc": "Supervisor de digitação e operação"},
  {"cod":"4122-05", "desc": "Contínuo"},
  {"cod":"4131-05", "desc": "Analista de folha de pagamento"},
  {"cod":"4131-10", "desc": "Auxiliar de contabilidade"},
  {"cod":"4131-15", "desc": "Auxiliar de faturamento"},
  {"cod":"4132-05", "desc": "Atendente de agência"},
  {"cod":"4132-10", "desc": "Caixa de banco"},
  {"cod":"4132-15", "desc": "Compensador de banco"},
  {"cod":"4132-20", "desc": "Conferente de serviços bancários"},
  {"cod":"4132-25", "desc": "Escriturário de banco"},
  {"cod":"4132-30", "desc": "Operador de cobrança bancária"},
  {"cod":"4141-05", "desc": "Almoxarife"},
  {"cod":"4141-10", "desc": "Armazenista"},
  {"cod":"4141-15", "desc": "Balanceiro"},
  {"cod":"4142-05", "desc": "Apontador de mão-de-obra"},
  {"cod":"4142-10", "desc": "Apontador de produção"},
  {"cod":"4142-15", "desc": "Conferente de carga e descarga"},
  {"cod":"4151-05", "desc": "Arquivista de documentos"},
  {"cod":"4151-15", "desc": "Codificador de dados"},
  {"cod":"4151-20", "desc": "Fitotecário"},
  {"cod":"4151-25", "desc": "Kardexista"},
  {"cod":"4151-30", "desc": "Operador de máquina copiadora (exceto operador de gráfica rápida)"},
  {"cod":"4152-05", "desc": "Carteiro"},
  {"cod":"4152-10", "desc": "Operador de triagem e transbordo"},
  {"cod":"4201-05", "desc": "Supervisor de caixas e bilheteiros (exceto caixa de banco)"},
  {"cod":"4201-10", "desc": "Supervisor de cobrança"},
  {"cod":"4201-15", "desc": "Supervisor de coletadores de apostas e de jogos"},
  {"cod":"4201-20", "desc": "Supervisor de entrevistadores e recenseadores"},
  {"cod":"4201-25", "desc": "Supervisor de recepcionistas"},
  {"cod":"4201-30", "desc": "Supervisor de telefonistas"},
  {"cod":"4201-35", "desc": "Supervisor de telemarketing e atendimento"},
  {"cod":"4211-05", "desc": "Atendente comercial (agência postal)"},
  {"cod":"4211-10", "desc": "Bilheteiro de transportes coletivos"},
  {"cod":"4211-15", "desc": "Bilheteiro no serviço de diversões"},
  {"cod":"4211-20", "desc": "Emissor de passagens"},
  {"cod":"4211-25", "desc": "Operador de caixa"},
  {"cod":"4212-05", "desc": "Recebedor de apostas (loteria)"},
  {"cod":"4212-10", "desc": "Recebedor de apostas (turfe)"},
  {"cod":"4213-05", "desc": "Cobrador externo"},
  {"cod":"4213-10", "desc": "Cobrador interno"},
  {"cod":"4213-15", "desc": "Localizador (cobrador)"},
  {"cod":"4221-05", "desc": "Recepcionista, em geral"},
  {"cod":"4221-10", "desc": "Recepcionista de consultório médico ou dentário"},
  {"cod":"4221-15", "desc": "Recepcionista de seguro saúde"},
  {"cod":"4221-20", "desc": "Recepcionista de hotel"},
  {"cod":"4221-25", "desc": "Recepcionista de banco"},
  {"cod":"4222-05", "desc": "Telefonista"},
  {"cod":"4222-10", "desc": "Teleoperador"},
  {"cod":"4222-15", "desc": "Monitor de teleatendimento"},
  {"cod":"4222-20", "desc": "Operador de rádio-chamada"},
  {"cod":"4223-05", "desc": "Operador de telemarketing ativo"},
  {"cod":"4223-10", "desc": "Operador de telemarketing ativo e receptivo"},
  {"cod":"4223-15", "desc": "Operador de telemarketing receptivo"},
  {"cod":"4223-20", "desc": "Operador de telemarketing técnico"},
  {"cod":"4231-05", "desc": "Despachante documentalista"},
  {"cod":"4241-05", "desc": "Entrevistador censitário e de pesquisas amostrais"},
  {"cod":"4241-10", "desc": "Entrevistador de pesquisa de opinião e mídia"},
  {"cod":"4241-15", "desc": "Entrevistador de pesquisas de mercado"},
  {"cod":"4241-20", "desc": "Entrevistador de preços"},
  {"cod":"4241-25", "desc": "Escriturário em estatística"},
  {"cod":"5101-05", "desc": "Supervisor de transportes"},
  {"cod":"5101-10", "desc": "Administrador de edifícios"},
  {"cod":"5101-15", "desc": "Supervisor de andar"},
  {"cod":"5101-20", "desc": "Chefe de portaria de hotel"},
  {"cod":"5101-25", "desc": "Chefe de cozinha"},
  {"cod":"5101-30", "desc": "Chefe de bar"},
  {"cod":"5101-35", "desc": "Maître"},
  {"cod":"5102-05", "desc": "Supervisor de lavanderia"},
  {"cod":"5103-05", "desc": "Supervisor de bombeiros"},
  {"cod":"5103-10", "desc": "Supervisor de vigilantes"},
  {"cod":"5111-05", "desc": "Comissário de vôo"},
  {"cod":"5111-10", "desc": "Comissário de trem"},
  {"cod":"5111-15", "desc": "Taifeiro (exceto militares)"},
  {"cod":"5112-05", "desc": "Fiscal de transportes coletivos (exceto trem)"},
  {"cod":"5112-10", "desc": "Despachante de transportes coletivos (exceto trem)"},
  {"cod":"5112-15", "desc": "Cobrador de transportes coletivos (exceto trem)"},
  {"cod":"5112-20", "desc": "Bilheteiro (estações de metrô, ferroviárias e assemelhadas)"},
  {"cod":"5114-05", "desc": "Guia de turismo"},
  {"cod":"5121-05", "desc": "Empregado doméstico nos serviços gerais"},
  {"cod":"5121-10", "desc": "Empregado doméstico arrumador"},
  {"cod":"5121-15", "desc": "Empregado doméstico faxineiro"},
  {"cod":"5121-20", "desc": "Empregado doméstico diarista"},
  {"cod":"5131-05", "desc": "Mordomo de residência"},
  {"cod":"5131-10", "desc": "Mordomo de hotelaria"},
  {"cod":"5131-15", "desc": "Governanta de hotelaria"},
  {"cod":"5132-05", "desc": "Cozinheiro geral"},
  {"cod":"5132-10", "desc": "Cozinheiro do serviço doméstico"},
  {"cod":"5132-15", "desc": "Cozinheiro industrial"},
  {"cod":"5132-20", "desc": "Cozinheiro de hospital"},
  {"cod":"5132-25", "desc": "Cozinheiro de embarcações"},
  {"cod":"5133-05", "desc": "Camareira de teatro"},
  {"cod":"5133-10", "desc": "Camareira de televisão"},
  {"cod":"5133-15", "desc": "Camareiro de hotel"},
  {"cod":"5133-20", "desc": "Camareiro de embarcações"},
  {"cod":"5133-25", "desc": "Guarda-roupeira de cinema"},
  {"cod":"5134-05", "desc": "Garçom"},
  {"cod":"5134-10", "desc": "Garçom (serviços de vinhos)"},
  {"cod":"5134-15", "desc": "Cumim"},
  {"cod":"5134-20", "desc": "Barman"},
  {"cod":"5134-25", "desc": "Copeiro"},
  {"cod":"5134-30", "desc": "Copeiro de hospital"},
  {"cod":"5134-35", "desc": "Atendente de lanchonete"},
  {"cod":"5135-05", "desc": "Auxiliar nos serviços de alimentação"},
  {"cod":"5136-05", "desc": "Churrasqueiro"},
  {"cod":"5136-10", "desc": "Pizzaiolo"},
  {"cod":"5136-15", "desc": "Sushiman"},
  {"cod":"5141-05", "desc": "Ascensorista"},
  {"cod":"5141-10", "desc": "Garagista"},
  {"cod":"5141-15", "desc": "Sacristão"},
  {"cod":"5141-20", "desc": "Zelador de edifício"},
  {"cod":"5142-05", "desc": "Coletor de lixo domiciliar"},
  {"cod":"5142-15", "desc": "Varredor de rua"},
  {"cod":"5142-25", "desc": "Trabalhador de serviços de limpeza e conservação de áreas públicas"},
  {"cod":"5142-30", "desc": "Coletor de resíduos sólidos de serviços de saúde"},
  {"cod":"5143-05", "desc": "Limpador de vidros"},
  {"cod":"5143-10", "desc": "Auxiliar de manutenção predial"},
  {"cod":"5143-15", "desc": "Limpador de fachadas"},
  {"cod":"5143-20", "desc": "Faxineiro"},
  {"cod":"5143-25", "desc": "Trabalhador da manutenção de edificações"},
  {"cod":"5143-30", "desc": "Limpador de piscinas"},
  {"cod":"5151-05", "desc": "Agente comunitário de saúde"},
  {"cod":"5151-10", "desc": "Atendente de enfermagem"},
  {"cod":"5151-15", "desc": "Parteira leiga"},
  {"cod":"5151-20", "desc": "Visitador sanitário"},
  {"cod":"5152-05", "desc": "Auxiliar de banco de sangue"},
  {"cod":"5152-10", "desc": "Auxiliar de farmácia de manipulação"},
  {"cod":"5152-15", "desc": "Auxiliar de laboratório de análises clínicas"},
  {"cod":"5152-20", "desc": "Auxiliar de laboratório de imunobiológicos"},
  {"cod":"5152-25", "desc": "Auxiliar de produção farmacêutica"},
  {"cod":"5153-05", "desc": "Educador social"},
  {"cod":"5153-10", "desc": "Agente de ação social"},
  {"cod":"5153-15", "desc": "Monitor de dependente químico"},
  {"cod":"5153-20", "desc": "Conselheiro tutelar"},
  {"cod":"5161-05", "desc": "Barbeiro"},
  {"cod":"5161-10", "desc": "Cabeleireiro"},
  {"cod":"5161-15", "desc": "Esteticista"},
  {"cod":"5161-20", "desc": "Manicure"},
  {"cod":"5161-25", "desc": "Maquiador"},
  {"cod":"5161-30", "desc": "Maquiador de caracterização"},
  {"cod":"5161-40", "desc": "Pedicure"},
  {"cod":"5162-05", "desc": "Babá"},
  {"cod":"5162-10", "desc": "Cuidador de idosos"},
  {"cod":"5162-15", "desc": "Mãe social"},
  {"cod":"5163-05", "desc": "Lavadeiro, em geral"},
  {"cod":"5163-10", "desc": "Lavador de roupas a maquina"},
  {"cod":"5163-15", "desc": "Lavador de artefatos de tapeçaria"},
  {"cod":"5163-20", "desc": "Limpador a seco, à máquina"},
  {"cod":"5163-25", "desc": "Passador de roupas em geral"},
  {"cod":"5163-30", "desc": "Tingidor de roupas"},
  {"cod":"5163-35", "desc": "Conferente-expedidor de roupas (lavanderias)"},
  {"cod":"5163-40", "desc": "Atendente de lavanderia"},
  {"cod":"5163-45", "desc": "Auxiliar de lavanderia"},
  {"cod":"5164-05", "desc": "Lavador de roupas"},
  {"cod":"5164-10", "desc": "Limpador de roupas a seco, à mão"},
  {"cod":"5164-15", "desc": "Passador de roupas, à mão"},
  {"cod":"5165-05", "desc": "Agente funerário"},
  {"cod":"5166-05", "desc": "Operador de forno (serviços funerários)"},
  {"cod":"5166-10", "desc": "Sepultador"},
  {"cod":"5167-05", "desc": "Astrólogo"},
  {"cod":"5167-10", "desc": "Numerólogo"},
  {"cod":"5168-05", "desc": "Esotérico"},
  {"cod":"5168-10", "desc": "Paranormal"},
  {"cod":"5171-05", "desc": "Bombeiro de aeródromo"},
  {"cod":"5171-10", "desc": "Bombeiro de segurança do trabalho"},
  {"cod":"5171-15", "desc": "Salva-vidas"},
  {"cod":"5172-05", "desc": "Agente de polícia federal"},
  {"cod":"5172-10", "desc": "Policial rodoviário federal"},
  {"cod":"5172-15", "desc": "Guarda-civil municipal"},
  {"cod":"5172-20", "desc": "Agente de trânsito"},
  {"cod":"5173-05", "desc": "Agente de proteção de aeroporto"},
  {"cod":"5173-10", "desc": "Agente de segurança"},
  {"cod":"5173-15", "desc": "Agente de segurança penitenciária"},
  {"cod":"5173-20", "desc": "Vigia florestal"},
  {"cod":"5173-25", "desc": "Vigia portuário"},
  {"cod":"5173-30", "desc": "Vigilante"},
  {"cod":"5174-05", "desc": "Porteiro (hotel)"},
  {"cod":"5174-10", "desc": "Porteiro de edifícios"},
  {"cod":"5174-15", "desc": "Porteiro de locais de diversão"},
  {"cod":"5174-20", "desc": "Vigia"},
  {"cod":"5191-05", "desc": "Ciclista mensageiro"},
  {"cod":"5191-10", "desc": "Motociclista no transporte de documentos e pequenos volumes"},
  {"cod":"5192-05", "desc": "Catador de material reciclável"},
  {"cod":"5193-05", "desc": "Auxiliar de veterinário"},
  {"cod":"5193-10", "desc": "Esteticista de animais domésticos"},
  {"cod":"5193-15", "desc": "Banhista de animais domésticos"},
  {"cod":"5193-20", "desc": "Tosador de animais domésticos"},
  {"cod":"5198-05", "desc": "Profissional do sexo"},
  {"cod":"5199-05", "desc": "Cartazeiro"},
  {"cod":"5199-10", "desc": "Controlador de pragas"},
  {"cod":"5199-15", "desc": "Engraxate"},
  {"cod":"5199-20", "desc": "Gandula"},
  {"cod":"5199-25", "desc": "Guardador de veículos"},
  {"cod":"5199-30", "desc": "Lavador de garrafas, vidros e outros utensílios"},
  {"cod":"5199-35", "desc": "Lavador de veículos"},
  {"cod":"5199-40", "desc": "Leiturista"},
  {"cod":"5199-45", "desc": "Recepcionista de casas de espetáculos"},
  {"cod":"5201-05", "desc": "Supervisor de vendas de serviços"},
  {"cod":"5201-10", "desc": "Supervisor de vendas comercial"},
  {"cod":"5211-05", "desc": "Vendedor em comércio atacadista"},
  {"cod":"5211-10", "desc": "Vendedor de comércio varejista"},
  {"cod":"5211-15", "desc": "Promotor de vendas"},
  {"cod":"5211-20", "desc": "Demonstrador de mercadorias"},
  {"cod":"5211-25", "desc": "Repositor de mercadorias"},
  {"cod":"5211-30", "desc": "Atendente de farmácia - balconista"},
  {"cod":"5211-35", "desc": "Frentista"},
  {"cod":"5231-05", "desc": "Instalador de cortinas e persianas, portas sanfonadas e boxe"},
  {"cod":"5231-10", "desc": "Instalador de som e acessórios de veículos"},
  {"cod":"5231-15", "desc": "Chaveiro"},
  {"cod":"5241-05", "desc": "Vendedor em domicílio"},
  {"cod":"5242-05", "desc": "Feirante"},
  {"cod":"5242-10", "desc": "Jornaleiro (em banca de jornal)"},
  {"cod":"5242-15", "desc": "Vendedor permissionário"},
  {"cod":"5243-05", "desc": "Vendedor ambulante"},
  {"cod":"5243-10", "desc": "Pipoqueiro ambulante"},
  {"cod":"6110-05", "desc": "Produtor agropecuário, em geral"},
  {"cod":"6120-05", "desc": "Produtor agrícola polivalente"},
  {"cod":"6121-05", "desc": "Produtor de arroz"},
  {"cod":"6121-10", "desc": "Produtor de cana-de-açúcar"},
  {"cod":"6121-15", "desc": "Produtor de cereais de inverno"},
  {"cod":"6121-20", "desc": "Produtor de gramíneas forrageiras"},
  {"cod":"6121-25", "desc": "Produtor de milho e sorgo"},
  {"cod":"6122-05", "desc": "Produtor de algodão"},
  {"cod":"6122-10", "desc": "Produtor de curauá"},
  {"cod":"6122-15", "desc": "Produtor de juta"},
  {"cod":"6122-20", "desc": "Produtor de rami"},
  {"cod":"6122-25", "desc": "Produtor de sisal"},
  {"cod":"6123-05", "desc": "Produtor na olericultura de legumes"},
  {"cod":"6123-10", "desc": "Produtor na olericultura de raízes, bulbos e tubérculos"},
  {"cod":"6123-15", "desc": "Produtor na olericultura de talos, folhas e flores"},
  {"cod":"6123-20", "desc": "Produtor na olericultura de frutos e sementes"},
  {"cod":"6124-05", "desc": "Produtor de flores de corte"},
  {"cod":"6124-10", "desc": "Produtor de flores em vaso"},
  {"cod":"6124-15", "desc": "Produtor de forrações"},
  {"cod":"6124-20", "desc": "Produtor de plantas ornamentais"},
  {"cod":"6125-05", "desc": "Produtor de árvores frutíferas"},
  {"cod":"6125-10", "desc": "Produtor de espécies frutíferas rasteiras"},
  {"cod":"6125-15", "desc": "Produtor de espécies frutíferas trepadeiras"},
  {"cod":"6126-05", "desc": "Cafeicultor"},
  {"cod":"6126-10", "desc": "Produtor de cacau"},
  {"cod":"6126-15", "desc": "Produtor de erva-mate"},
  {"cod":"6126-20", "desc": "Produtor de fumo"},
  {"cod":"6126-25", "desc": "Produtor de guaraná"},
  {"cod":"6127-05", "desc": "Produtor da cultura de amendoim"},
  {"cod":"6127-10", "desc": "Produtor da cultura de canola"},
  {"cod":"6127-15", "desc": "Produtor da cultura de coco-da-baia"},
  {"cod":"6127-20", "desc": "Produtor da cultura de dendê"},
  {"cod":"6127-25", "desc": "Produtor da cultura de girassol"},
  {"cod":"6127-30", "desc": "Produtor da cultura de linho"},
  {"cod":"6127-35", "desc": "Produtor da cultura de mamona"},
  {"cod":"6127-40", "desc": "Produtor da cultura de soja"},
  {"cod":"6128-05", "desc": "Produtor de especiarias"},
  {"cod":"6128-10", "desc": "Produtor de plantas aromáticas e medicinais"},
  {"cod":"6130-05", "desc": "Criador em pecuária polivalente"},
  {"cod":"6130-10", "desc": "Criador de animais domésticos"},
  {"cod":"6131-05", "desc": "Criador de asininos e muares"},
  {"cod":"6131-10", "desc": "Criador de bovinos (corte)"},
  {"cod":"6131-15", "desc": "Criador de bovinos (leite)"},
  {"cod":"6131-20", "desc": "Criador de bubalinos (corte)"},
  {"cod":"6131-25", "desc": "Criador de bubalinos (leite)"},
  {"cod":"6131-30", "desc": "Criador de eqüínos"},
  {"cod":"6132-05", "desc": "Criador de caprinos"},
  {"cod":"6132-10", "desc": "Criador de ovinos"},
  {"cod":"6132-15", "desc": "Criador de suínos"},
  {"cod":"6133-05", "desc": "Avicultor"},
  {"cod":"6133-10", "desc": "Cunicultor"},
  {"cod":"6134-05", "desc": "Apicultor"},
  {"cod":"6134-10", "desc": "Criador de animais produtores de veneno"},
  {"cod":"6134-15", "desc": "Minhocultor"},
  {"cod":"6134-20", "desc": "Sericultor"},
  {"cod":"6201-05", "desc": "Supervisor de exploração agrícola"},
  {"cod":"6201-10", "desc": "Supervisor de exploração agropecuária"},
  {"cod":"6201-15", "desc": "Supervisor de exploração pecuária"},
  {"cod":"6210-05", "desc": "Trabalhador agropecuário em geral"},
  {"cod":"6220-05", "desc": "Caseiro (agricultura)"},
  {"cod":"6220-10", "desc": "Jardineiro"},
  {"cod":"6220-15", "desc": "Trabalhador na produção de mudas e sementes"},
  {"cod":"6220-20", "desc": "Trabalhador volante da agricultura"},
  {"cod":"6221-05", "desc": "Trabalhador da cultura de arroz"},
  {"cod":"6221-10", "desc": "Trabalhador da cultura de cana-de-açúcar"},
  {"cod":"6221-15", "desc": "Trabalhador da cultura de milho e sorgo"},
  {"cod":"6221-20", "desc": "Trabalhador da cultura de trigo, aveia, cevada e triticale"},
  {"cod":"6222-05", "desc": "Trabalhador da cultura de algodão"},
  {"cod":"6222-10", "desc": "Trabalhador da cultura de sisal"},
  {"cod":"6222-15", "desc": "Trabalhador da cultura do rami"},
  {"cod":"6223-05", "desc": "Trabalhador na olericultura (frutos e sementes)"},
  {"cod":"6223-10", "desc": "Trabalhador na olericultura (legumes)"},
  {"cod":"6223-15", "desc": "Trabalhador na olericultura (raízes, bulbos e tubérculos)"},
  {"cod":"6223-20", "desc": "Trabalhador na olericultura (talos, folhas e flores)"},
  {"cod":"6224-05", "desc": "Trabalhador no cultivo de flores e folhagens de corte"},
  {"cod":"6224-10", "desc": "Trabalhador no cultivo de flores em vaso"},
  {"cod":"6224-15", "desc": "Trabalhador no cultivo de forrações"},
  {"cod":"6224-20", "desc": "Trabalhador no cultivo de mudas"},
  {"cod":"6224-25", "desc": "Trabalhador no cultivo de plantas ornamentais"},
  {"cod":"6225-05", "desc": "Trabalhador no cultivo de árvores frutíferas"},
  {"cod":"6225-10", "desc": "Trabalhador no cultivo de espécies frutíferas rasteiras"},
  {"cod":"6225-15", "desc": "Trabalhador no cultivo de trepadeiras frutíferas"},
  {"cod":"6226-05", "desc": "Trabalhador da cultura de cacau"},
  {"cod":"6226-10", "desc": "Trabalhador da cultura de café"},
  {"cod":"6226-15", "desc": "Trabalhador da cultura de erva-mate"},
  {"cod":"6226-20", "desc": "Trabalhador da cultura de fumo"},
  {"cod":"6226-25", "desc": "Trabalhador da cultura de guaraná"},
  {"cod":"6227-05", "desc": "Trabalhador na cultura de amendoim"},
  {"cod":"6227-10", "desc": "Trabalhador na cultura de canola"},
  {"cod":"6227-15", "desc": "Trabalhador na cultura de coco-da-baía"},
  {"cod":"6227-20", "desc": "Trabalhador na cultura de dendê"},
  {"cod":"6227-25", "desc": "Trabalhador na cultura de mamona"},
  {"cod":"6227-30", "desc": "Trabalhador na cultura de soja"},
  {"cod":"6227-35", "desc": "Trabalhador na cultura do girassol"},
  {"cod":"6227-40", "desc": "Trabalhador na cultura do linho"},
  {"cod":"6228-05", "desc": "Trabalhador da cultura de especiarias"},
  {"cod":"6228-10", "desc": "Trabalhador da cultura de plantas aromáticas e medicinais"},
  {"cod":"6230-05", "desc": "Adestrador de animais"},
  {"cod":"6230-10", "desc": "Inseminador"},
  {"cod":"6230-15", "desc": "Trabalhador de pecuária polivalente"},
  {"cod":"6230-20", "desc": "Tratador de animais"},
  {"cod":"6231-05", "desc": "Trabalhador da pecuária (asininos e muares)"},
  {"cod":"6231-10", "desc": "Trabalhador da pecuária (bovinos corte)"},
  {"cod":"6231-15", "desc": "Trabalhador da pecuária (bovinos leite)"},
  {"cod":"6231-20", "desc": "Trabalhador da pecuária (bubalinos)"},
  {"cod":"6231-25", "desc": "Trabalhador da pecuária (eqüinos)"},
  {"cod":"6232-05", "desc": "Trabalhador da caprinocultura"},
  {"cod":"6232-10", "desc": "Trabalhador da ovinocultura"},
  {"cod":"6232-15", "desc": "Trabalhador da suinocultura"},
  {"cod":"6233-05", "desc": "Trabalhador da avicultura de corte"},
  {"cod":"6233-10", "desc": "Trabalhador da avicultura de postura"},
  {"cod":"6233-15", "desc": "Operador de incubadora"},
  {"cod":"6233-20", "desc": "Trabalhador da cunicultura"},
  {"cod":"6233-25", "desc": "Sexador"},
  {"cod":"6234-05", "desc": "Trabalhador em criatórios de animais produtores de veneno"},
  {"cod":"6234-10", "desc": "Trabalhador na apicultura"},
  {"cod":"6234-15", "desc": "Trabalhador na minhocultura"},
  {"cod":"6234-20", "desc": "Trabalhador na sericicultura"},
  {"cod":"6301-05", "desc": "Supervisor da aqüicultura"},
  {"cod":"6301-10", "desc": "Supervisor da área florestal"},
  {"cod":"6310-05", "desc": "Catador de caranguejos e siris"},
  {"cod":"6310-10", "desc": "Catador de mariscos"},
  {"cod":"6310-15", "desc": "Pescador artesanal de lagostas"},
  {"cod":"6310-20", "desc": "Pescador artesanal de peixes e camarões"},
  {"cod":"6311-05", "desc": "Pescador artesanal de água doce"},
  {"cod":"6312-05", "desc": "Pescador industrial"},
  {"cod":"6312-10", "desc": "Pescador profissional"},
  {"cod":"6313-05", "desc": "Criador de camarões"},
  {"cod":"6313-10", "desc": "Criador de jacarés"},
  {"cod":"6313-15", "desc": "Criador de mexilhões"},
  {"cod":"6313-20", "desc": "Criador de ostras"},
  {"cod":"6313-25", "desc": "Criador de peixes"},
  {"cod":"6313-30", "desc": "Criador de quelônios"},
  {"cod":"6313-35", "desc": "Criador de rãs"},
  {"cod":"6314-05", "desc": "Gelador industrial"},
  {"cod":"6314-10", "desc": "Gelador profissional"},
  {"cod":"6314-15", "desc": "Proeiro"},
  {"cod":"6314-20", "desc": "Redeiro (pesca)"},
  {"cod":"6320-05", "desc": "Guia florestal"},
  {"cod":"6320-10", "desc": "Raizeiro"},
  {"cod":"6320-15", "desc": "Viveirista florestal"},
  {"cod":"6321-05", "desc": "Classificador de toras"},
  {"cod":"6321-10", "desc": "Cubador de madeira"},
  {"cod":"6321-15", "desc": "Identificador florestal"},
  {"cod":"6321-20", "desc": "Operador de motosserra"},
  {"cod":"6321-25", "desc": "Trabalhador de extração florestal, em geral"},
  {"cod":"6322-05", "desc": "Seringueiro"},
  {"cod":"6322-10", "desc": "Trabalhador da exploração de espécies produtoras de gomas não elásticas"},
  {"cod":"6322-15", "desc": "Trabalhador da exploração de resinas"},
  {"cod":"6323-05", "desc": "Trabalhador da exploração de andiroba"},
  {"cod":"6323-10", "desc": "Trabalhador da exploração de babaçu"},
  {"cod":"6323-15", "desc": "Trabalhador da exploração de bacaba"},
  {"cod":"6323-20", "desc": "Trabalhador da exploração de buriti"},
  {"cod":"6323-25", "desc": "Trabalhador da exploração de carnaúba"},
  {"cod":"6323-30", "desc": "Trabalhador da exploração de coco-da-praia"},
  {"cod":"6323-35", "desc": "Trabalhador da exploração de copaíba"},
  {"cod":"6323-40", "desc": "Trabalhador da exploração de malva (pãina)"},
  {"cod":"6323-45", "desc": "Trabalhador da exploração de murumuru"},
  {"cod":"6323-50", "desc": "Trabalhador da exploração de oiticica"},
  {"cod":"6323-55", "desc": "Trabalhador da exploração de ouricuri"},
  {"cod":"6323-60", "desc": "Trabalhador da exploração de pequi"},
  {"cod":"6323-65", "desc": "Trabalhador da exploração de piaçava"},
  {"cod":"6323-70", "desc": "Trabalhador da exploração de tucum"},
  {"cod":"6324-05", "desc": "Trabalhador da exploração de açaí"},
  {"cod":"6324-10", "desc": "Trabalhador da exploração de castanha"},
  {"cod":"6324-15", "desc": "Trabalhador da exploração de pinhão"},
  {"cod":"6324-20", "desc": "Trabalhador da exploração de pupunha"},
  {"cod":"6325-05", "desc": "Trabalhador da exploração de árvores e arbustos produtores de substâncias aromát., Medic. E tóxicas"},
  {"cod":"6325-10", "desc": "Trabalhador da exploração de cipós produtores de substâncias aromáticas, medicinais e tóxicas"},
  {"cod":"6325-15", "desc": "Trabalhador da exploração de madeiras tanantes"},
  {"cod":"6325-20", "desc": "Trabalhador da exploração de raízes produtoras de substâncias aromáticas, medicinais e tóxicas"},
  {"cod":"6325-25", "desc": "Trabalhador da extração de substâncias aromáticas, medicinais e tóxicas, em geral"},
  {"cod":"6326-05", "desc": "Carvoeiro"},
  {"cod":"6326-10", "desc": "Carbonizador"},
  {"cod":"6326-15", "desc": "Ajudante de carvoaria"},
  {"cod":"6410-05", "desc": "Operador de colheitadeira"},
  {"cod":"6410-10", "desc": "Operador de máquinas de beneficiamento de produtos agrícolas"},
  {"cod":"6410-15", "desc": "Tratorista agrícola"},
  {"cod":"6420-05", "desc": "Operador de colhedor florestal"},
  {"cod":"6420-10", "desc": "Operador de máquinas florestais estáticas"},
  {"cod":"6420-15", "desc": "Operador de trator florestal"},
  {"cod":"6430-05", "desc": "Trabalhador na operação de sistema de irrigação localizada (microaspersão e gotejamento)"},
  {"cod":"6430-10", "desc": "Trabalhador na operação de sistema de irrigação por aspersão (pivô central)"},
  {"cod":"6430-15", "desc": "Trabalhador na operação de sistemas convencionais de irrigação por aspersão"},
  {"cod":"6430-20", "desc": "Trabalhador na operação de sistemas de irrigação e aspersão (alto propelido)"},
  {"cod":"6430-25", "desc": "Trabalhador na operação de sistemas de irrigação por superfície e drenagem"},
  {"cod":"7101-05", "desc": "Supervisor de apoio operacional na mineração"},
  {"cod":"7101-10", "desc": "Supervisor de extração de sal"},
  {"cod":"7101-15", "desc": "Supervisor de perfuração e desmonte"},
  {"cod":"7101-20", "desc": "Supervisor de produção na mineração"},
  {"cod":"7101-25", "desc": "Supervisor de transporte na mineração"},
  {"cod":"7102-05", "desc": "Mestre (construção civil)"},
  {"cod":"7102-10", "desc": "Mestre de linhas (ferrovias)"},
  {"cod":"7102-15", "desc": "Inspetor de terraplenagem"},
  {"cod":"7102-20", "desc": "Supervisor de usina de concreto"},
  {"cod":"7102-25", "desc": "Fiscal de pátio de usina de concreto"},
  {"cod":"7111-05", "desc": "Amostrador de minérios"},
  {"cod":"7111-10", "desc": "Canteiro"},
  {"cod":"7111-15", "desc": "Destroçador de pedra"},
  {"cod":"7111-20", "desc": "Detonador"},
  {"cod":"7111-25", "desc": "Escorador de minas"},
  {"cod":"7111-30", "desc": "Mineiro"},
  {"cod":"7112-05", "desc": "Operador de caminhão (minas e pedreiras)"},
  {"cod":"7112-10", "desc": "Operador de carregadeira"},
  {"cod":"7112-15", "desc": "Operador de máquina cortadora (minas e pedreiras)"},
  {"cod":"7112-20", "desc": "Operador de máquina de extração contínua (minas de carvão)"},
  {"cod":"7112-25", "desc": "Operador de máquina perfuradora (minas e pedreiras)"},
  {"cod":"7112-30", "desc": "Operador de máquina perfuratriz"},
  {"cod":"7112-35", "desc": "Operador de motoniveladora (extração de minerais sólidos)"},
  {"cod":"7112-40", "desc": "Operador de schutthecar"},
  {"cod":"7112-45", "desc": "Operador de trator (minas e pedreiras)"},
  {"cod":"7113-05", "desc": "Operador de sonda de percussão"},
  {"cod":"7113-10", "desc": "Operador de sonda rotativa"},
  {"cod":"7113-15", "desc": "Sondador (poços de petróleo e gás)"},
  {"cod":"7113-20", "desc": "Sondador de poços (exceto de petróleo e gás)"},
  {"cod":"7113-25", "desc": "Plataformista (petróleo)"},
  {"cod":"7113-30", "desc": "Torrista (petróleo)"},
  {"cod":"7114-05", "desc": "Garimpeiro"},
  {"cod":"7114-10", "desc": "Operador de salina (sal marinho)"},
  {"cod":"7121-05", "desc": "Moleiro de minérios"},
  {"cod":"7121-10", "desc": "Operador de aparelho de flotação"},
  {"cod":"7121-15", "desc": "Operador de aparelho de precipitação (minas de ouro ou prata)"},
  {"cod":"7121-20", "desc": "Operador de britador de mandíbulas"},
  {"cod":"7121-25", "desc": "Operador de espessador"},
  {"cod":"7121-30", "desc": "Operador de jig (minas)"},
  {"cod":"7121-35", "desc": "Operador de peneiras hidráulicas"},
  {"cod":"7122-05", "desc": "Cortador de pedras"},
  {"cod":"7122-10", "desc": "Gravador de inscrições em pedra"},
  {"cod":"7122-15", "desc": "Gravador de relevos em pedra"},
  {"cod":"7122-20", "desc": "Polidor de pedras"},
  {"cod":"7122-25", "desc": "Torneiro (lavra de pedra)"},
  {"cod":"7122-30", "desc": "Traçador de pedras"},
  {"cod":"7151-05", "desc": "Operador de bate-estacas"},
  {"cod":"7151-10", "desc": "Operador de compactadora de solos"},
  {"cod":"7151-15", "desc": "Operador de escavadeira"},
  {"cod":"7151-20", "desc": "Operador de máquina de abrir valas"},
  {"cod":"7151-25", "desc": "Operador de máquinas de construção civil e mineração"},
  {"cod":"7151-30", "desc": "Operador de motoniveladora"},
  {"cod":"7151-35", "desc": "Operador de pá carregadeira"},
  {"cod":"7151-40", "desc": "Operador de pavimentadora (asfalto, concreto e materiais similares)"},
  {"cod":"7151-45", "desc": "Operador de trator de lâmina"},
  {"cod":"7152-05", "desc": "Calceteiro"},
  {"cod":"7152-10", "desc": "Pedreiro"},
  {"cod":"7152-15", "desc": "Pedreiro (chaminés industriais)"},
  {"cod":"7152-20", "desc": "Pedreiro (material refratário)"},
  {"cod":"7152-25", "desc": "Pedreiro (mineração)"},
  {"cod":"7152-30", "desc": "Pedreiro de edificações"},
  {"cod":"7153-05", "desc": "Armador de estrutura de concreto"},
  {"cod":"7153-10", "desc": "Moldador de corpos de prova em usinas de concreto"},
  {"cod":"7153-15", "desc": "Armador de estrutura de concreto armado"},
  {"cod":"7154-05", "desc": "Operador de betoneira"},
  {"cod":"7154-10", "desc": "Operador de bomba de concreto"},
  {"cod":"7154-15", "desc": "Operador de central de concreto"},
  {"cod":"7155-05", "desc": "Carpinteiro"},
  {"cod":"7155-10", "desc": "Carpinteiro (esquadrias)"},
  {"cod":"7155-15", "desc": "Carpinteiro (cenários)"},
  {"cod":"7155-20", "desc": "Carpinteiro (mineração)"},
  {"cod":"7155-25", "desc": "Carpinteiro de obras"},
  {"cod":"7155-30", "desc": "Carpinteiro (telhados)"},
  {"cod":"7155-35", "desc": "Carpinteiro de fôrmas para concreto"},
  {"cod":"7155-40", "desc": "Carpinteiro de obras civis de arte (pontes, túneis, barragens)"},
  {"cod":"7155-45", "desc": "Montador de andaimes (edificações)"},
  {"cod":"7156-05", "desc": "Eletricista de instalações (cenários)"},
  {"cod":"7156-10", "desc": "Eletricista de instalações (edifícios)"},
  {"cod":"7156-15", "desc": "Eletricista de instalações"},
  {"cod":"7157-05", "desc": "Aplicador de asfalto impermeabilizante (coberturas)"},
  {"cod":"7157-10", "desc": "Instalador de isolantes acústicos"},
  {"cod":"7157-15", "desc": "Instalador de isolantes térmicos (refrigeração e climatização)"},
  {"cod":"7157-20", "desc": "Instalador de isolantes térmicos de caldeira e tubulações"},
  {"cod":"7157-25", "desc": "Instalador de material isolante, a mão (edificações)"},
  {"cod":"7157-30", "desc": "Instalador de material isolante, a máquina (edificações)"},
  {"cod":"7161-05", "desc": "Acabador de superfícies de concreto"},
  {"cod":"7161-10", "desc": "Revestidor de superfícies de concreto"},
  {"cod":"7162-05", "desc": "Telhador (telhas de argila e materias similares)"},
  {"cod":"7162-10", "desc": "Telhador (telhas de cimento-amianto)"},
  {"cod":"7162-15", "desc": "Telhador (telhas metálicas)"},
  {"cod":"7162-20", "desc": "Telhador (telhas pláticas)"},
  {"cod":"7163-05", "desc": "Vidraceiro"},
  {"cod":"7163-10", "desc": "Vidraceiro (edificações)"},
  {"cod":"7163-15", "desc": "Vidraceiro (vitrais)"},
  {"cod":"7164-05", "desc": "Gesseiro"},
  {"cod":"7165-05", "desc": "Assoalhador"},
  {"cod":"7165-10", "desc": "Ladrilheiro"},
  {"cod":"7165-15", "desc": "Pastilheiro"},
  {"cod":"7165-20", "desc": "Lustrador de piso"},
  {"cod":"7165-25", "desc": "Marmorista (construção)"},
  {"cod":"7165-30", "desc": "Mosaísta"},
  {"cod":"7165-35", "desc": "Taqueiro"},
  {"cod":"7166-05", "desc": "Calafetador"},
  {"cod":"7166-10", "desc": "Pintor de obras"},
  {"cod":"7166-15", "desc": "Revestidor de interiores (papel, material plástico e emborrachados)"},
  {"cod":"7170-05", "desc": "Demolidor de edificações"},
  {"cod":"7170-10", "desc": "Operador de martelete"},
  {"cod":"7170-15", "desc": "Poceiro (edificações)"},
  {"cod":"7170-20", "desc": "Servente de obras"},
  {"cod":"7170-25", "desc": "Vibradorista"},
  {"cod":"7201-05", "desc": "Mestre (afiador de ferramentas)"},
  {"cod":"7201-10", "desc": "Mestre de caldeiraria"},
  {"cod":"7201-15", "desc": "Mestre de ferramentaria"},
  {"cod":"7201-20", "desc": "Mestre de forjaria"},
  {"cod":"7201-25", "desc": "Mestre de fundição"},
  {"cod":"7201-30", "desc": "Mestre de galvanoplastia"},
  {"cod":"7201-35", "desc": "Mestre de pintura (tratamento de superfícies)"},
  {"cod":"7201-40", "desc": "Mestre de soldagem"},
  {"cod":"7201-45", "desc": "Mestre de trefilação de metais"},
  {"cod":"7201-50", "desc": "Mestre de usinagem"},
  {"cod":"7201-55", "desc": "Mestre serralheiro"},
  {"cod":"7201-60", "desc": "Supervisor de controle de tratamento térmico"},
  {"cod":"7202-05", "desc": "Mestre (construção naval)"},
  {"cod":"7202-10", "desc": "Mestre (indústria de automotores e material de transportes)"},
  {"cod":"7202-15", "desc": "Mestre (indústria de máquinas e outros equipamentos mecânicos)"},
  {"cod":"7202-20", "desc": "Mestre de construção de fornos"},
  {"cod":"7211-05", "desc": "Ferramenteiro"},
  {"cod":"7211-10", "desc": "Ferramenteiro de mandris, calibradores e outros dispositivos"},
  {"cod":"7211-15", "desc": "Modelador de metais (fundição)"},
  {"cod":"7212-05", "desc": "Operador de máquina de eletroerosão"},
  {"cod":"7212-10", "desc": "Operador de máquinas operatrizes"},
  {"cod":"7212-15", "desc": "Operador de máquinas-ferramenta convencionais"},
  {"cod":"7212-20", "desc": "Operador de usinagem convencional por abrasão"},
  {"cod":"7212-25", "desc": "Preparador de máquinas-ferramenta"},
  {"cod":"7213-05", "desc": "Afiador de cardas"},
  {"cod":"7213-10", "desc": "Afiador de cutelaria"},
  {"cod":"7213-15", "desc": "Afiador de ferramentas"},
  {"cod":"7213-20", "desc": "Afiador de serras"},
  {"cod":"7213-25", "desc": "Polidor de metais"},
  {"cod":"7214-05", "desc": "Operador de centro de usinagem com comando numérico"},
  {"cod":"7214-10", "desc": "Operador de fresadora com comando numérico"},
  {"cod":"7214-15", "desc": "Operador de mandriladora com comando numérico"},
  {"cod":"7214-20", "desc": "Operador de máquina eletroerosão, à fio, com comando numérico"},
  {"cod":"7214-25", "desc": "Operador de retificadora com comando numérico"},
  {"cod":"7214-30", "desc": "Operador de torno com comando numérico"},
  {"cod":"7221-05", "desc": "Forjador"},
  {"cod":"7221-10", "desc": "Forjador a martelo"},
  {"cod":"7221-15", "desc": "Forjador prensista"},
  {"cod":"7222-05", "desc": "Fundidor de metais"},
  {"cod":"7222-10", "desc": "Lingotador"},
  {"cod":"7222-15", "desc": "Operador de acabamento de peças fundidas"},
  {"cod":"7222-20", "desc": "Operador de máquina centrifugadora de fundição"},
  {"cod":"7222-25", "desc": "Operador de máquina de fundir sob pressão"},
  {"cod":"7222-30", "desc": "Operador de vazamento (lingotamento)"},
  {"cod":"7222-35", "desc": "Preparador de panelas (lingotamento)"},
  {"cod":"7223-05", "desc": "Macheiro, a mão"},
  {"cod":"7223-10", "desc": "Macheiro, a máquina"},
  {"cod":"7223-15", "desc": "Moldador, a mão"},
  {"cod":"7223-20", "desc": "Moldador, a máquina"},
  {"cod":"7223-25", "desc": "Operador de equipamentos de preparação de areia"},
  {"cod":"7223-30", "desc": "Operador de máquina de moldar automatizada"},
  {"cod":"7224-05", "desc": "Cableador"},
  {"cod":"7224-10", "desc": "Estirador de tubos de metal sem costura"},
  {"cod":"7224-15", "desc": "Trefilador de metais, à máquina"},
  {"cod":"7231-05", "desc": "Cementador de metais"},
  {"cod":"7231-10", "desc": "Normalizador de metais e de compósitos"},
  {"cod":"7231-15", "desc": "Operador de equipamento para resfriamento"},
  {"cod":"7231-20", "desc": "Operador de forno de tratamento térmico de metais"},
  {"cod":"7231-25", "desc": "Temperador de metais e de compósitos"},
  {"cod":"7232-05", "desc": "Decapador"},
  {"cod":"7232-10", "desc": "Fosfatizador"},
  {"cod":"7232-15", "desc": "Galvanizador"},
  {"cod":"7232-20", "desc": "Metalizador a pistola"},
  {"cod":"7232-25", "desc": "Metalizador (banho quente)"},
  {"cod":"7232-30", "desc": "Operador de máquina recobridora de arame"},
  {"cod":"7232-35", "desc": "Operador de zincagem (processo eletrolítico)"},
  {"cod":"7232-40", "desc": "Oxidador"},
  {"cod":"7233-05", "desc": "Operador de equipamento de secagem de pintura"},
  {"cod":"7233-10", "desc": "Pintor a pincel e rolo (exceto obras e estruturas metálicas)"},
  {"cod":"7233-15", "desc": "Pintor de estruturas metálicas"},
  {"cod":"7233-20", "desc": "Pintor de veículos (fabricação)"},
  {"cod":"7233-25", "desc": "Pintor por imersão"},
  {"cod":"7233-30", "desc": "Pintor, a pistola (exceto obras e estruturas metálicas)"},
  {"cod":"7241-05", "desc": "Assentador de canalização (edificações)"},
  {"cod":"7241-10", "desc": "Encanador"},
  {"cod":"7241-15", "desc": "Instalador de tubulações"},
  {"cod":"7241-20", "desc": "Instalador de tubulações (aeronaves)"},
  {"cod":"7241-25", "desc": "Instalador de tubulações (embarcações)"},
  {"cod":"7241-30", "desc": "Instalador de tubulações de gás combustível (produção e distribuição)"},
  {"cod":"7241-35", "desc": "Instalador de tubulações de vapor (produção e distribuição)"},
  {"cod":"7242-05", "desc": "Montador de estruturas metálicas"},
  {"cod":"7242-10", "desc": "Montador de estruturas metálicas de embarcações"},
  {"cod":"7242-15", "desc": "Rebitador a martelo pneumático"},
  {"cod":"7242-20", "desc": "Preparador de estruturas metálicas"},
  {"cod":"7242-25", "desc": "Riscador de estruturas metálicas"},
  {"cod":"7242-30", "desc": "Rebitador, a mão"},
  {"cod":"7243-05", "desc": "Brasador"},
  {"cod":"7243-10", "desc": "Oxicortador a mão e a máquina"},
  {"cod":"7243-15", "desc": "Soldador"},
  {"cod":"7243-20", "desc": "Soldador a oxigás"},
  {"cod":"7243-25", "desc": "Soldador elétrico"},
  {"cod":"7244-05", "desc": "Caldeireiro (chapas de cobre)"},
  {"cod":"7244-10", "desc": "Caldeireiro (chapas de ferro e aço)"},
  {"cod":"7244-15", "desc": "Chapeador"},
  {"cod":"7244-20", "desc": "Chapeador de carrocerias metálicas (fabricação)"},
  {"cod":"7244-25", "desc": "Chapeador naval"},
  {"cod":"7244-30", "desc": "Chapeador de aeronaves"},
  {"cod":"7244-35", "desc": "Funileiro industrial"},
  {"cod":"7244-40", "desc": "Serralheiro"},
  {"cod":"7245-05", "desc": "Operador de máquina de cilindrar chapas"},
  {"cod":"7245-10", "desc": "Operador de máquina de dobrar chapas"},
  {"cod":"7245-15", "desc": "Prensista (operador de prensa)"},
  {"cod":"7246-05", "desc": "Operador de laços de cabos de aço"},
  {"cod":"7246-10", "desc": "Trançador de cabos de aço"},
  {"cod":"7250-05", "desc": "Ajustador ferramenteiro"},
  {"cod":"7250-10", "desc": "Ajustador mecânico"},
  {"cod":"7250-15", "desc": "Ajustador mecânico (usinagem em bancada e em máquinas-ferramentas)"},
  {"cod":"7250-20", "desc": "Ajustador mecânico em bancada"},
  {"cod":"7250-25", "desc": "Ajustador naval (reparo e construção)"},
  {"cod":"7251-05", "desc": "Montador de máquinas, motores e acessórios (montagem em série)"},
  {"cod":"7252-05", "desc": "Montador de máquinas"},
  {"cod":"7252-10", "desc": "Montador de máquinas gráficas"},
  {"cod":"7252-15", "desc": "Montador de máquinas operatrizes para madeira"},
  {"cod":"7252-20", "desc": "Montador de máquinas têxteis"},
  {"cod":"7252-25", "desc": "Montador de máquinas-ferramentas (usinagem de metais)"},
  {"cod":"7253-05", "desc": "Montador de equipamento de levantamento"},
  {"cod":"7253-10", "desc": "Montador de máquinas agrícolas"},
  {"cod":"7253-15", "desc": "Montador de máquinas de minas e pedreiras"},
  {"cod":"7253-20", "desc": "Montador de máquinas de terraplenagem"},
  {"cod":"7254-05", "desc": "Mecânico montador de motores de aeronaves"},
  {"cod":"7254-10", "desc": "Mecânico montador de motores de embarcações"},
  {"cod":"7254-15", "desc": "Mecânico montador de motores de explosão e diesel"},
  {"cod":"7254-20", "desc": "Mecânico montador de turboalimentadores"},
  {"cod":"7255-05", "desc": "Montador de veículos (linha de montagem)"},
  {"cod":"7255-10", "desc": "Operador de time de montagem"},
  {"cod":"7256-05", "desc": "Montador de estruturas de aeronaves"},
  {"cod":"7256-10", "desc": "Montador de sistemas de combustível de aeronaves"},
  {"cod":"7257-05", "desc": "Mecânico de refrigeração"},
  {"cod":"7301-05", "desc": "Supervisor de montagem e instalação eletroeletrônica"},
  {"cod":"7311-05", "desc": "Montador de equipamentos eletrônicos (aparelhos médicos)"},
  {"cod":"7311-10", "desc": "Montador de equipamentos eletrônicos (computadores e equipamentos auxiliares)"},
  {"cod":"7311-15", "desc": "Montador de equipamentos elétricos (instrumentos de medição)"},
  {"cod":"7311-20", "desc": "Montador de equipamentos elétricos (aparelhos eletrodomésticos)"},
  {"cod":"7311-25", "desc": "Montador de equipamentos elétricos (centrais elétricas)"},
  {"cod":"7311-30", "desc": "Montador de equipamentos elétricos (motores e dínamos)"},
  {"cod":"7311-35", "desc": "Montador de equipamentos elétricos"},
  {"cod":"7311-40", "desc": "Montador de equipamentos eletrônicos (instalações de sinalização)"},
  {"cod":"7311-45", "desc": "Montador de equipamentos eletrônicos (máquinas industriais)"},
  {"cod":"7311-50", "desc": "Montador de equipamentos eletrônicos"},
  {"cod":"7311-55", "desc": "Montador de equipamentos elétricos (elevadores e equipamentos similares)"},
  {"cod":"7311-60", "desc": "Montador de equipamentos elétricos (transformadores)"},
  {"cod":"7311-65", "desc": "Bobinador eletricista, à mão"},
  {"cod":"7311-70", "desc": "Bobinador eletricista, à máquina"},
  {"cod":"7311-75", "desc": "Operador de linha de montagem (aparelhos elétricos)"},
  {"cod":"7311-80", "desc": "Operador de linha de montagem (aparelhos eletrônicos)"},
  {"cod":"7312-05", "desc": "Montador de equipamentos eletrônicos (estação de rádio, tv e equipamentos de radar)"},
  {"cod":"7313-05", "desc": "Instalador-reparador de equipamentos de comutação em telefonia"},
  {"cod":"7313-10", "desc": "Instalador-reparador de equipamentos de energia em telefonia"},
  {"cod":"7313-15", "desc": "Instalador-reparador de equipamentos de transmissão em telefonia"},
  {"cod":"7313-20", "desc": "Instalador-reparador de linhas e aparelhos de telecomunicações"},
  {"cod":"7313-25", "desc": "Instalador-reparador de redes e cabos telefônicos"},
  {"cod":"7313-30", "desc": "Reparador de aparelhos de telecomunicações em laboratório"},
  {"cod":"7321-05", "desc": "Eletricista de manutenção de linhas elétricas, telefônicas e de comunicação de dados"},
  {"cod":"7321-10", "desc": "Emendador de cabos elétricos e telefônicos (aéreos e subterrâneos)"},
  {"cod":"7321-15", "desc": "Examinador de cabos, linhas elétricas e telefônicas"},
  {"cod":"7321-20", "desc": "Instalador de linhas elétricas de alta e baixa - tensão (rede aérea e subterrânea)"},
  {"cod":"7321-25", "desc": "Instalador eletricista (tração de veículos)"},
  {"cod":"7321-30", "desc": "Instalador-reparador de redes telefônicas e de comunicação de dados"},
  {"cod":"7321-35", "desc": "Ligador de linhas telefônicas"},
  {"cod":"7401-05", "desc": "Supervisor da mecânica de precisão"},
  {"cod":"7401-10", "desc": "Supervisor de fabricação de instrumentos musicais"},
  {"cod":"7411-05", "desc": "Ajustador de instrumentos de precisão"},
  {"cod":"7411-10", "desc": "Montador de instrumentos de óptica"},
  {"cod":"7411-15", "desc": "Montador de instrumentos de precisão"},
  {"cod":"7411-20", "desc": "Relojoeiro (fabricação)"},
  {"cod":"7411-25", "desc": "Relojoeiro (reparação)"},
  {"cod":"7421-05", "desc": "Afinador de instrumentos musicais"},
  {"cod":"7421-10", "desc": "Confeccionador de acordeão"},
  {"cod":"7421-15", "desc": "Confeccionador de instrumentos de corda"},
  {"cod":"7421-20", "desc": "Confeccionador de instrumentos de percussão (pele, couro ou plástico)"},
  {"cod":"7421-25", "desc": "Confeccionador de instrumentos de sopro (madeira)"},
  {"cod":"7421-30", "desc": "Confeccionador de instrumentos de sopro (metal)"},
  {"cod":"7421-35", "desc": "Confeccionador de órgão"},
  {"cod":"7421-40", "desc": "Confeccionador de piano"},
  {"cod":"7501-05", "desc": "Supervisor de joalheria"},
  {"cod":"7502-05", "desc": "Supervisor da indústria de minerais não metálicos (exceto os derivados de petróleo e carvão)"},
  {"cod":"7510-05", "desc": "Engastador (jóias)"},
  {"cod":"7510-10", "desc": "Joalheiro"},
  {"cod":"7510-15", "desc": "Joalheiro (reparações)"},
  {"cod":"7510-20", "desc": "Lapidador (jóias)"},
  {"cod":"7511-05", "desc": "Bate-folha a máquina"},
  {"cod":"7511-10", "desc": "Fundidor (joalheria e ourivesaria)"},
  {"cod":"7511-15", "desc": "Gravador (joalheria e ourivesaria)"},
  {"cod":"7511-20", "desc": "Laminador de metais preciosos a mão"},
  {"cod":"7511-25", "desc": "Ourives"},
  {"cod":"7511-30", "desc": "Trefilador (joalheria e ourivesaria)"},
  {"cod":"7521-05", "desc": "Artesão modelador (vidros)"},
  {"cod":"7521-10", "desc": "Moldador (vidros)"},
  {"cod":"7521-15", "desc": "Soprador de vidro"},
  {"cod":"7521-20", "desc": "Transformador de tubos de vidro"},
  {"cod":"7522-05", "desc": "Aplicador serigráfico em vidros"},
  {"cod":"7522-10", "desc": "Cortador de vidro"},
  {"cod":"7522-15", "desc": "Gravador de vidro a água-forte"},
  {"cod":"7522-20", "desc": "Gravador de vidro a esmeril"},
  {"cod":"7522-25", "desc": "Gravador de vidro a jato de areia"},
  {"cod":"7522-30", "desc": "Lapidador de vidros e cristais"},
  {"cod":"7522-35", "desc": "Surfassagista"},
  {"cod":"7523-05", "desc": "Ceramista"},
  {"cod":"7523-10", "desc": "Ceramista (torno de pedal e motor)"},
  {"cod":"7523-15", "desc": "Ceramista (torno semi-automático)"},
  {"cod":"7523-20", "desc": "Ceramista modelador"},
  {"cod":"7523-25", "desc": "Ceramista moldador"},
  {"cod":"7523-30", "desc": "Ceramista prensador"},
  {"cod":"7524-05", "desc": "Decorador de cerâmica"},
  {"cod":"7524-10", "desc": "Decorador de vidro"},
  {"cod":"7524-15", "desc": "Decorador de vidro à pincel"},
  {"cod":"7524-20", "desc": "Operador de esmaltadeira"},
  {"cod":"7524-25", "desc": "Operador de espelhamento"},
  {"cod":"7524-30", "desc": "Pintor de cerâmica, a pincel"},
  {"cod":"7601-05", "desc": "Contramestre de acabamento (indústria têxtil)"},
  {"cod":"7601-10", "desc": "Contramestre de fiação (indústria têxtil)"},
  {"cod":"7601-15", "desc": "Contramestre de malharia (indústria têxtil)"},
  {"cod":"7601-20", "desc": "Contramestre de tecelagem (indústria têxtil)"},
  {"cod":"7601-25", "desc": "Mestre (indústria têxtil e de confecções)"},
  {"cod":"7602-05", "desc": "Supervisor de curtimento"},
  {"cod":"7603-05", "desc": "Encarregado de corte na confecção do vestuário"},
  {"cod":"7603-10", "desc": "Encarregado de costura na confecção do vestuário"},
  {"cod":"7604-05", "desc": "Supervisor (indústria de calçados e artefatos de couro)"},
  {"cod":"7605-05", "desc": "Supervisor da confecção de artefatos de tecidos, couros e afins"},
  {"cod":"7606-05", "desc": "Supervisor das artes gráficas (indústria editorial e gráfica)"},
  {"cod":"7610-05", "desc": "Operador polivalente da indústria têxtil"},
  {"cod":"7611-05", "desc": "Classificador de fibras têxteis"},
  {"cod":"7611-10", "desc": "Lavador de lã"},
  {"cod":"7612-05", "desc": "Operador de abertura (fiação)"},
  {"cod":"7612-10", "desc": "Operador de binadeira"},
  {"cod":"7612-15", "desc": "Operador de bobinadeira"},
  {"cod":"7612-20", "desc": "Operador de cardas"},
  {"cod":"7612-25", "desc": "Operador de conicaleira"},
  {"cod":"7612-30", "desc": "Operador de filatório"},
  {"cod":"7612-35", "desc": "Operador de laminadeira e reunideira"},
  {"cod":"7612-40", "desc": "Operador de maçaroqueira"},
  {"cod":"7612-45", "desc": "Operador de open-end"},
  {"cod":"7612-50", "desc": "Operador de passador (fiação)"},
  {"cod":"7612-55", "desc": "Operador de penteadeira"},
  {"cod":"7612-60", "desc": "Operador de retorcedeira"},
  {"cod":"7613-03", "desc": "Tecelão (redes)"},
  {"cod":"7613-06", "desc": "Tecelão (rendas e bordados)"},
  {"cod":"7613-09", "desc": "Tecelão (tear automático)"},
  {"cod":"7613-12", "desc": "Tecelão (tear jacquard)"},
  {"cod":"7613-15", "desc": "Tecelão (tear mecânico de maquineta)"},
  {"cod":"7613-18", "desc": "Tecelão (tear mecânico de xadrez)"},
  {"cod":"7613-21", "desc": "Tecelão (tear mecânico liso)"},
  {"cod":"7613-24", "desc": "Tecelão (tear mecânico, exceto jacquard)"},
  {"cod":"7613-27", "desc": "Tecelão de malhas, a máquina"},
  {"cod":"7613-30", "desc": "Tecelão de malhas (máquina circular)"},
  {"cod":"7613-33", "desc": "Tecelão de malhas (máquina retilínea)"},
  {"cod":"7613-36", "desc": "Tecelão de meias, a máquina"},
  {"cod":"7613-39", "desc": "Tecelão de meias (máquina circular)"},
  {"cod":"7613-42", "desc": "Tecelão de meias (máquina retilínea)"},
  {"cod":"7613-45", "desc": "Tecelão de tapetes, a máquina"},
  {"cod":"7613-48", "desc": "Operador de engomadeira de urdume"},
  {"cod":"7613-51", "desc": "Operador de espuladeira"},
  {"cod":"7613-54", "desc": "Operador de máquina de cordoalha"},
  {"cod":"7613-57", "desc": "Operador de urdideira"},
  {"cod":"7613-60", "desc": "Passamaneiro a máquina"},
  {"cod":"7613-63", "desc": "Remetedor de fios"},
  {"cod":"7613-66", "desc": "Picotador de cartões jacquard"},
  {"cod":"7614-05", "desc": "Alvejador (tecidos)"},
  {"cod":"7614-10", "desc": "Estampador de tecido"},
  {"cod":"7614-15", "desc": "Operador de calandras (tecidos)"},
  {"cod":"7614-20", "desc": "Operador de chamuscadeira de tecidos"},
  {"cod":"7614-25", "desc": "Operador de impermeabilizador de tecidos"},
  {"cod":"7614-30", "desc": "Operador de máquina de lavar fios e tecidos"},
  {"cod":"7614-35", "desc": "Operador de rameuse"},
  {"cod":"7618-05", "desc": "Inspetor de estamparia (produção têxtil)"},
  {"cod":"7618-10", "desc": "Revisor de fios (produção têxtil)"},
  {"cod":"7618-15", "desc": "Revisor de tecidos acabados"},
  {"cod":"7618-20", "desc": "Revisor de tecidos crus"},
  {"cod":"7620-05", "desc": "Trabalhador polivalente do curtimento de couros e peles"},
  {"cod":"7621-05", "desc": "Classificador de peles"},
  {"cod":"7621-10", "desc": "Descarnador de couros e peles, à maquina"},
  {"cod":"7621-15", "desc": "Estirador de couros e peles (preparação)"},
  {"cod":"7621-20", "desc": "Fuloneiro"},
  {"cod":"7621-25", "desc": "Rachador de couros e peles"},
  {"cod":"7622-05", "desc": "Curtidor (couros e peles)"},
  {"cod":"7622-10", "desc": "Classificador de couros"},
  {"cod":"7622-15", "desc": "Enxugador de couros"},
  {"cod":"7622-20", "desc": "Rebaixador de couros"},
  {"cod":"7623-05", "desc": "Estirador de couros e peles (acabamento)"},
  {"cod":"7623-10", "desc": "Fuloneiro no acabamento de couros e peles"},
  {"cod":"7623-15", "desc": "Lixador de couros e peles"},
  {"cod":"7623-20", "desc": "Matizador de couros e peles"},
  {"cod":"7623-25", "desc": "Operador de máquinas do acabamento de couros e peles"},
  {"cod":"7623-30", "desc": "Prensador de couros e peles"},
  {"cod":"7623-35", "desc": "Palecionador de couros e peles"},
  {"cod":"7623-40", "desc": "Preparador de couros curtidos"},
  {"cod":"7623-45", "desc": "Vaqueador de couros e peles"},
  {"cod":"7630-05", "desc": "Alfaiate"},
  {"cod":"7630-10", "desc": "Costureira de peças sob encomenda"},
  {"cod":"7630-15", "desc": "Costureira de reparação de roupas"},
  {"cod":"7630-20", "desc": "Costureiro de roupa de couro e pele"},
  {"cod":"7631-05", "desc": "Auxiliar de corte (preparação da confecção de roupas)"},
  {"cod":"7631-10", "desc": "Cortador de roupas"},
  {"cod":"7631-15", "desc": "Enfestador de roupas"},
  {"cod":"7631-20", "desc": "Riscador de roupas"},
  {"cod":"7631-25", "desc": "Ajudante de confecção"},
  {"cod":"7632-05", "desc": "Costureiro de roupas de couro e pele, a máquina na confecção em série"},
  {"cod":"7632-10", "desc": "Costureiro na confecção em série"},
  {"cod":"7632-15", "desc": "Costureiro, a máquina na confecção em série"},
  {"cod":"7633-05", "desc": "Arrematadeira"},
  {"cod":"7633-10", "desc": "Bordador, à máquina"},
  {"cod":"7633-15", "desc": "Marcador de peças confeccionadas para bordar"},
  {"cod":"7633-20", "desc": "Operador de máquina de costura de acabamento"},
  {"cod":"7633-25", "desc": "Passadeira de peças confeccionadas"},
  {"cod":"7640-05", "desc": "Trabalhador polivalente da confecção de calçados"},
  {"cod":"7641-05", "desc": "Cortador de calçados, a máquina (exceto solas e palmilhas)"},
  {"cod":"7641-10", "desc": "Cortador de solas e palmilhas, a máquina"},
  {"cod":"7641-15", "desc": "Preparador de calçados"},
  {"cod":"7641-20", "desc": "Preparador de solas e palmilhas"},
  {"cod":"7642-05", "desc": "Costurador de calçados, a máquina"},
  {"cod":"7642-10", "desc": "Montador de calçados"},
  {"cod":"7643-05", "desc": "Acabador de calçados"},
  {"cod":"7650-05", "desc": "Confeccionador de artefatos de couro (exceto sapatos)"},
  {"cod":"7650-10", "desc": "Chapeleiro de senhoras"},
  {"cod":"7650-15", "desc": "Boneleiro"},
  {"cod":"7651-05", "desc": "Cortador de artefatos de couro (exceto roupas e calçados)"},
  {"cod":"7651-10", "desc": "Cortador de tapeçaria"},
  {"cod":"7652-05", "desc": "Colchoeiro (confecção de colchões)"},
  {"cod":"7652-15", "desc": "Confeccionador de brinquedos de pano"},
  {"cod":"7652-25", "desc": "Confeccionador de velas náuticas, barracas e toldos"},
  {"cod":"7652-30", "desc": "Estofador de aviões"},
  {"cod":"7652-35", "desc": "Estofador de móveis"},
  {"cod":"7653-10", "desc": "Costurador de artefatos de couro, a máquina (exceto roupas e calçados)"},
  {"cod":"7653-15", "desc": "Montador de artefatos de couro (exceto roupas e calçados)"},
  {"cod":"7654-05", "desc": "Trabalhador do acabamento de artefatos de tecidos e couros"},
  {"cod":"7661-05", "desc": "Copiador de chapa"},
  {"cod":"7661-15", "desc": "Gravador de matriz para flexografia (clicherista)"},
  {"cod":"7661-20", "desc": "Editor de texto e imagem"},
  {"cod":"7661-25", "desc": "Montador de fotolito (analógico e digital)"},
  {"cod":"7661-30", "desc": "Gravador de matriz para rotogravura (eletromecânico e químico)"},
  {"cod":"7661-35", "desc": "Gravador de matriz calcográfica"},
  {"cod":"7661-40", "desc": "Gravador de matriz serigráfica"},
  {"cod":"7661-45", "desc": "Operador de sistemas de prova (analógico e digital)"},
  {"cod":"7661-50", "desc": "Operador de processo de tratamento de imagem"},
  {"cod":"7661-55", "desc": "Programador visual gráfico"},
  {"cod":"7662-05", "desc": "Impressor (serigrafia)"},
  {"cod":"7662-10", "desc": "Impressor calcográfico"},
  {"cod":"7662-15", "desc": "Impressor de ofsete (plano e rotativo)"},
  {"cod":"7662-20", "desc": "Impressor de rotativa"},
  {"cod":"7662-25", "desc": "Impressor de rotogravura"},
  {"cod":"7662-30", "desc": "Impressor digital"},
  {"cod":"7662-35", "desc": "Impressor flexográfico"},
  {"cod":"7662-40", "desc": "Impressor letterset"},
  {"cod":"7662-45", "desc": "Impressor tampográfico"},
  {"cod":"7662-50", "desc": "Impressor tipográfico"},
  {"cod":"7663-05", "desc": "Acabador de embalagens (flexíveis e cartotécnicas)"},
  {"cod":"7663-10", "desc": "Impressor de corte e vinco"},
  {"cod":"7663-15", "desc": "Operador de acabamento (indústria gráfica)"},
  {"cod":"7663-20", "desc": "Operador de guilhotina (corte de papel)"},
  {"cod":"7663-25", "desc": "Preparador de matrizes de corte e vinco"},
  {"cod":"7664-05", "desc": "Laboratorista fotográfico"},
  {"cod":"7664-10", "desc": "Revelador de filmes fotográficos, em preto e branco"},
  {"cod":"7664-15", "desc": "Revelador de filmes fotográficos, em cores"},
  {"cod":"7664-20", "desc": "Auxiliar de radiologia (revelação fotográfica)"},
  {"cod":"7681-05", "desc": "Tecelão (tear manual)"},
  {"cod":"7681-10", "desc": "Tecelão de tapetes, a mão"},
  {"cod":"7681-15", "desc": "Tricoteiro, à mão"},
  {"cod":"7681-20", "desc": "Redeiro"},
  {"cod":"7681-25", "desc": "Chapeleiro (chapéus de palha)"},
  {"cod":"7681-30", "desc": "Crocheteiro, a mão"},
  {"cod":"7682-05", "desc": "Bordador, a mão"},
  {"cod":"7682-10", "desc": "Cerzidor"},
  {"cod":"7683-05", "desc": "Artífice do couro"},
  {"cod":"7683-10", "desc": "Cortador de calçados, a mão (exceto solas)"},
  {"cod":"7683-15", "desc": "Costurador de artefatos de couro, a mão (exceto roupas e calçados)"},
  {"cod":"7683-20", "desc": "Sapateiro (calçados sob medida)"},
  {"cod":"7683-25", "desc": "Seleiro"},
  {"cod":"7686-05", "desc": "Tipógrafo"},
  {"cod":"7686-10", "desc": "Linotipista"},
  {"cod":"7686-15", "desc": "Monotipista"},
  {"cod":"7686-20", "desc": "Paginador"},
  {"cod":"7686-25", "desc": "Pintor de letreiros"},
  {"cod":"7686-30", "desc": "Confeccionador de carimbos de borracha"},
  {"cod":"7687-05", "desc": "Gravador, à mão (encadernação)"},
  {"cod":"7687-10", "desc": "Restaurador de livros"},
  {"cod":"7701-05", "desc": "Mestre (indústria de madeira e mobiliário)"},
  {"cod":"7701-10", "desc": "Mestre carpinteiro"},
  {"cod":"7711-05", "desc": "Marceneiro"},
  {"cod":"7711-10", "desc": "Modelador de madeira"},
  {"cod":"7711-15", "desc": "Maquetista na marcenaria"},
  {"cod":"7711-20", "desc": "Tanoeiro"},
  {"cod":"7721-05", "desc": "Classificador de madeira"},
  {"cod":"7721-10", "desc": "Impregnador de madeira"},
  {"cod":"7721-15", "desc": "Secador de madeira"},
  {"cod":"7731-05", "desc": "Cortador de laminados de madeira"},
  {"cod":"7731-10", "desc": "Operador de serras no desdobramento de madeira"},
  {"cod":"7731-15", "desc": "Serrador de bordas no desdobramento de madeira"},
  {"cod":"7731-20", "desc": "Serrador de madeira"},
  {"cod":"7731-25", "desc": "Serrador de madeira (serra circular múltipla)"},
  {"cod":"7731-30", "desc": "Serrador de madeira (serra de fita múltipla)"},
  {"cod":"7732-05", "desc": "Operador de máquina intercaladora e placas (compensados)"},
  {"cod":"7732-10", "desc": "Prensista de aglomerados"},
  {"cod":"7732-15", "desc": "Prensista de compensados"},
  {"cod":"7732-20", "desc": "Preparador de aglomerantes"},
  {"cod":"7733-05", "desc": "Operador de desempenadeira na usinagem convencional de madeira"},
  {"cod":"7733-10", "desc": "Operador de entalhadeira (usinagem de madeira)"},
  {"cod":"7733-15", "desc": "Operador de fresadora (usinagem de madeira)"},
  {"cod":"7733-20", "desc": "Operador de lixadeira (usinagem de madeira)"},
  {"cod":"7733-25", "desc": "Operador de máquina de usinagem madeira, em geral"},
  {"cod":"7733-30", "desc": "Operador de molduradora (usinagem de madeira)"},
  {"cod":"7733-35", "desc": "Operador de plaina desengrossadeira"},
  {"cod":"7733-40", "desc": "Operador de serras (usinagem de madeira)"},
  {"cod":"7733-45", "desc": "Operador de torno automático (usinagem de madeira)"},
  {"cod":"7733-50", "desc": "Operador de tupia (usinagem de madeira)"},
  {"cod":"7733-55", "desc": "Torneiro na usinagem convencional de madeira"},
  {"cod":"7734-05", "desc": "Operador de máquina bordatriz"},
  {"cod":"7734-10", "desc": "Operador de máquina de cortina d´água (produção de móveis)"},
  {"cod":"7734-15", "desc": "Operador de máquina de usinagem de madeira (produção em série)"},
  {"cod":"7734-20", "desc": "Operador de prensa de alta freqüência na usinagem de madeira"},
  {"cod":"7735-05", "desc": "Operador de centro de usinagem de madeira (cnc)"},
  {"cod":"7735-10", "desc": "Operador de máquinas de usinar madeira (cnc)"},
  {"cod":"7741-05", "desc": "Montador de móveis e artefatos de madeira"},
  {"cod":"7751-05", "desc": "Entalhador de madeira"},
  {"cod":"7751-10", "desc": "Folheador de móveis de madeira"},
  {"cod":"7751-15", "desc": "Lustrador de peças de madeira"},
  {"cod":"7751-20", "desc": "Marcheteiro"},
  {"cod":"7764-05", "desc": "Cesteiro"},
  {"cod":"7764-10", "desc": "Confeccionador de escovas, pincéis e produtos similares (a mão)"},
  {"cod":"7764-15", "desc": "Confeccionador de escovas, pincéis e produtos similares (a máquina)"},
  {"cod":"7764-20", "desc": "Confeccionador de móveis de vime, junco e bambu"},
  {"cod":"7764-25", "desc": "Esteireiro"},
  {"cod":"7764-30", "desc": "Vassoureiro"},
  {"cod":"7771-05", "desc": "Carpinteiro naval (construção de pequenas embarcações)"},
  {"cod":"7771-10", "desc": "Carpinteiro naval (embarcações)"},
  {"cod":"7771-15", "desc": "Carpinteiro naval (estaleiros)"},
  {"cod":"7772-05", "desc": "Carpinteiro de carretas"},
  {"cod":"7772-10", "desc": "Carpinteiro de carrocerias"},
  {"cod":"7801-05", "desc": "Supervisor de embalagem e etiquetagem"},
  {"cod":"7811-05", "desc": "Condutor de processos robotizados de pintura"},
  {"cod":"7811-10", "desc": "Condutor de processos robotizados de soldagem"},
  {"cod":"7813-05", "desc": "Operador de veículos subaquáticos controlados remotamente"},
  {"cod":"7817-05", "desc": "Mergulhador profissional (raso e profundo)"},
  {"cod":"7821-05", "desc": "Operador de draga"},
  {"cod":"7821-10", "desc": "Operador de guindaste (fixo)"},
  {"cod":"7821-15", "desc": "Operador de guindaste móvel"},
  {"cod":"7821-20", "desc": "Operador de máquina rodoferroviária"},
  {"cod":"7821-25", "desc": "Operador de monta-cargas (construção civil)"},
  {"cod":"7821-30", "desc": "Operador de ponte rolante"},
  {"cod":"7821-35", "desc": "Operador de pórtico rolante"},
  {"cod":"7821-40", "desc": "Operador de talha elétrica"},
  {"cod":"7821-45", "desc": "Sinaleiro (ponte-rolante)"},
  {"cod":"7822-05", "desc": "Guincheiro (construção civil)"},
  {"cod":"7822-10", "desc": "Operador de docagem"},
  {"cod":"7822-20", "desc": "Operador de empilhadeira"},
  {"cod":"7823-05", "desc": "Motorista de carro de passeio"},
  {"cod":"7823-10", "desc": "Motorista de furgão ou veículo similar"},
  {"cod":"7823-15", "desc": "Motorista de táxi"},
  {"cod":"7824-05", "desc": "Motorista de ônibus rodoviário"},
  {"cod":"7824-10", "desc": "Motorista de ônibus urbano"},
  {"cod":"7824-15", "desc": "Motorista de trólebus"},
  {"cod":"7825-05", "desc": "Caminhoneiro autônomo (rotas regionais e internacionais)"},
  {"cod":"7825-10", "desc": "Motorista de caminhão (rotas regionais e internacionais)"},
  {"cod":"7825-15", "desc": "Motorista operacional de guincho"},
  {"cod":"7826-05", "desc": "Operador de trem de metrô"},
  {"cod":"7826-10", "desc": "Maquinista de trem"},
  {"cod":"7826-15", "desc": "Maquinista de trem metropolitano"},
  {"cod":"7826-20", "desc": "Motorneiro"},
  {"cod":"7826-25", "desc": "Auxiliar de maquinista de trem"},
  {"cod":"7826-30", "desc": "Operador de teleférico (passageiros)"},
  {"cod":"7827-05", "desc": "Marinheiro de convés (marítimo e fluviário)"},
  {"cod":"7827-10", "desc": "Marinheiro de máquinas"},
  {"cod":"7827-15", "desc": "Moço de convés (marítimo e fluviário)"},
  {"cod":"7827-20", "desc": "Moço de máquinas (marítimo e fluviário)"},
  {"cod":"7827-25", "desc": "Marinheiro de esporte e recreio"},
  {"cod":"7828-05", "desc": "Condutor de veículos de tração animal (ruas e estradas)"},
  {"cod":"7828-10", "desc": "Tropeiro"},
  {"cod":"7828-15", "desc": "Boiadeiro"},
  {"cod":"7828-20", "desc": "Condutor de veículos a pedais"},
  {"cod":"7831-05", "desc": "Agente de pátio"},
  {"cod":"7831-10", "desc": "Manobrador"},
  {"cod":"7832-05", "desc": "Carregador (aeronaves)"},
  {"cod":"7832-10", "desc": "Carregador (armazém)"},
  {"cod":"7832-15", "desc": "Carregador (veículos de transportes terrestres)"},
  {"cod":"7832-20", "desc": "Estivador"},
  {"cod":"7832-25", "desc": "Ajudante de motorista"},
  {"cod":"7841-05", "desc": "Embalador, a mão"},
  {"cod":"7841-10", "desc": "Embalador, a máquina"},
  {"cod":"7841-15", "desc": "Operador de máquina de etiquetar"},
  {"cod":"7841-20", "desc": "Operador de máquina de envasar líquidos"},
  {"cod":"7841-25", "desc": "Operador de prensa de enfardamento"},
  {"cod":"7842-05", "desc": "Alimentador de linha de produção"},
  {"cod":"8101-05", "desc": "Mestre (indústria petroquímica e carboquímica)"},
  {"cod":"8101-10", "desc": "Mestre de produção química"},
  {"cod":"8102-05", "desc": "Mestre (indústria de borracha e plástico)"},
  {"cod":"8103-05", "desc": "Mestre de produção farmacêutica"},
  {"cod":"8110-05", "desc": "Operador de processos químicos e petroquímicos"},
  {"cod":"8110-10", "desc": "Operador de sala de controle de instalações químicas, petroquímicas e afins"},
  {"cod":"8111-05", "desc": "Moleiro (tratamentos químicos e afins)"},
  {"cod":"8111-10", "desc": "Operador de máquina misturadeira (tratamentos químicos e afins)"},
  {"cod":"8111-15", "desc": "Operador de britadeira (tratamentos químicos e afins)"},
  {"cod":"8111-20", "desc": "Operador de concentração"},
  {"cod":"8111-25", "desc": "Trabalhador da fabricação de resinas e vernizes"},
  {"cod":"8111-30", "desc": "Trabalhador de fabricação de tintas"},
  {"cod":"8112-05", "desc": "Operador de calcinação (tratamento químico e afins)"},
  {"cod":"8112-15", "desc": "Operador de tratamento químico de materiais radioativos"},
  {"cod":"8113-05", "desc": "Operador de centrifugadora (tratamentos químicos e afins)"},
  {"cod":"8113-10", "desc": "Operador de exploração de petróleo"},
  {"cod":"8113-15", "desc": "Operador de filtro de secagem (mineração)"},
  {"cod":"8113-20", "desc": "Operador de filtro de tambor rotativo (tratamentos químicos e afins)"},
  {"cod":"8113-25", "desc": "Operador de filtro-esteira (mineração)"},
  {"cod":"8113-30", "desc": "Operador de filtro-prensa (tratamentos químicos e afins)"},
  {"cod":"8113-35", "desc": "Operador de filtros de parafina (tratamentos químicos e afins)"},
  {"cod":"8114-05", "desc": "Destilador de madeira"},
  {"cod":"8114-10", "desc": "Destilador de produtos químicos (exceto petróleo)"},
  {"cod":"8114-15", "desc": "Operador de alambique de funcionamento contínuo (produtos químicos, exceto petróleo)"},
  {"cod":"8114-20", "desc": "Operador de aparelho de reação e conversão (produtos químicos, exceto petróleo)"},
  {"cod":"8114-25", "desc": "Operador de equipamento de destilação de álcool"},
  {"cod":"8114-30", "desc": "Operador de evaporador na destilação"},
  {"cod":"8115-05", "desc": "Operador de painel de controle (refinação de petróleo)"},
  {"cod":"8115-10", "desc": "Operador de transferência e estocagem - na refinação do petróleo"},
  {"cod":"8116-05", "desc": "Operador de britador de coque"},
  {"cod":"8116-10", "desc": "Operador de carro de apagamento e coque"},
  {"cod":"8116-15", "desc": "Operador de destilação e subprodutos de coque"},
  {"cod":"8116-20", "desc": "Operador de enfornamento e desenfornamento de coque"},
  {"cod":"8116-25", "desc": "Operador de exaustor (coqueria)"},
  {"cod":"8116-30", "desc": "Operador de painel de controle"},
  {"cod":"8116-35", "desc": "Operador de preservação e controle térmico"},
  {"cod":"8116-40", "desc": "Operador de reator de coque de petróleo"},
  {"cod":"8116-45", "desc": "Operador de refrigeração (coqueria)"},
  {"cod":"8116-50", "desc": "Operador de sistema de reversão (coqueria)"},
  {"cod":"8117-05", "desc": "Bamburista"},
  {"cod":"8117-10", "desc": "Calandrista de borracha"},
  {"cod":"8117-15", "desc": "Confeccionador de pneumáticos"},
  {"cod":"8117-25", "desc": "Confeccionador de velas por imersão"},
  {"cod":"8117-35", "desc": "Confeccionador de velas por moldagem"},
  {"cod":"8117-45", "desc": "Laminador de plástico"},
  {"cod":"8117-50", "desc": "Moldador de borracha por compressão"},
  {"cod":"8117-60", "desc": "Moldador de plástico por compressão"},
  {"cod":"8117-70", "desc": "Moldador de plástico por injeção"},
  {"cod":"8117-75", "desc": "Trefilador de borracha"},
  {"cod":"8118-05", "desc": "Operador de máquina de produtos farmacêuticos"},
  {"cod":"8118-10", "desc": "Drageador (medicamentos)"},
  {"cod":"8118-15", "desc": "Operador de máquina de fabricação de cosméticos"},
  {"cod":"8118-20", "desc": "Operador de máquina de fabricação de produtos de higiene e limpeza (sabão, sabonete, detergente,"},
  {"cod":"8121-05", "desc": "Pirotécnico"},
  {"cod":"8121-10", "desc": "Trabalhador da fabricação de munição e explosivos"},
  {"cod":"8131-05", "desc": "Cilindrista (petroquímica e afins)"},
  {"cod":"8131-10", "desc": "Operador de calandra (química, petroquímica e afins)"},
  {"cod":"8131-15", "desc": "Operador de extrusora (química, petroquímica e afins)"},
  {"cod":"8131-20", "desc": "Operador de processo (química, petroquímica e afins)"},
  {"cod":"8131-25", "desc": "Operador de produção (química, petroquímica e afins)"},
  {"cod":"8131-30", "desc": "Técnico de operação (química, petroquímica e afins)"},
  {"cod":"8181-05", "desc": "Assistente de laboratório industrial"},
  {"cod":"8181-10", "desc": "Auxiliar de laboratório de análises físico-químicas"},
  {"cod":"8201-05", "desc": "Mestre de siderurgia"},
  {"cod":"8201-10", "desc": "Mestre de aciaria"},
  {"cod":"8201-15", "desc": "Mestre de alto-forno"},
  {"cod":"8201-20", "desc": "Mestre de forno elétrico"},
  {"cod":"8201-25", "desc": "Mestre de laminação"},
  {"cod":"8202-05", "desc": "Supervisor de fabricação de produtos cerâmicos, porcelanatos e afins"},
  {"cod":"8202-10", "desc": "Supervisor de fabricação de produtos de vidro"},
  {"cod":"8211-05", "desc": "Operador de centro de controle"},
  {"cod":"8211-10", "desc": "Operador de máquina de sinterizar"},
  {"cod":"8212-05", "desc": "Forneiro e operador (alto-forno)"},
  {"cod":"8212-10", "desc": "Forneiro e operador (conversor a oxigênio)"},
  {"cod":"8212-15", "desc": "Forneiro e operador (forno elétrico)"},
  {"cod":"8212-20", "desc": "Forneiro e operador (refino de metais não-ferrosos)"},
  {"cod":"8212-25", "desc": "Forneiro e operador de forno de redução direta"},
  {"cod":"8212-30", "desc": "Operador de aciaria (basculamento de convertedor)"},
  {"cod":"8212-35", "desc": "Operador de aciaria (dessulfuração de gusa)"},
  {"cod":"8212-40", "desc": "Operador de aciaria (recebimento de gusa)"},
  {"cod":"8212-45", "desc": "Operador de área de corrida"},
  {"cod":"8212-50", "desc": "Operador de desgaseificação"},
  {"cod":"8212-55", "desc": "Soprador de convertedor"},
  {"cod":"8213-05", "desc": "Operador de laminador"},
  {"cod":"8213-10", "desc": "Operador de laminador de barras a frio"},
  {"cod":"8213-15", "desc": "Operador de laminador de barras a quente"},
  {"cod":"8213-20", "desc": "Operador de laminador de metais não-ferrosos"},
  {"cod":"8213-25", "desc": "Operador de laminador de tubos"},
  {"cod":"8213-30", "desc": "Operador de montagem de cilindros e mancais"},
  {"cod":"8213-35", "desc": "Recuperador de guias e cilindros"},
  {"cod":"8214-05", "desc": "Encarregado de acabamento de chapas e metais (têmpera)"},
  {"cod":"8214-10", "desc": "Escarfador"},
  {"cod":"8214-15", "desc": "Marcador de produtos (siderúrgico e metalúrgico)"},
  {"cod":"8214-20", "desc": "Operador de bobinadeira de tiras a quente, no acabamento de chapas e metais"},
  {"cod":"8214-25", "desc": "Operador de cabine de laminação (fio-máquina)"},
  {"cod":"8214-30", "desc": "Operador de escória e sucata"},
  {"cod":"8214-35", "desc": "Operador de jato abrasivo"},
  {"cod":"8214-40", "desc": "Operador de tesoura mecânica e máquina de corte, no acabamento de chapas e metais"},
  {"cod":"8214-45", "desc": "Preparador de sucata e aparas"},
  {"cod":"8214-50", "desc": "Rebarbador de metal"},
  {"cod":"8221-05", "desc": "Forneiro de cubilô"},
  {"cod":"8221-10", "desc": "Forneiro de forno-poço"},
  {"cod":"8221-15", "desc": "Forneiro de fundição (forno de redução)"},
  {"cod":"8221-20", "desc": "Forneiro de reaquecimento e tratamento térmico na metalurgia"},
  {"cod":"8221-25", "desc": "Forneiro de revérbero"},
  {"cod":"8231-05", "desc": "Preparador de massa (fabricação de abrasivos)"},
  {"cod":"8231-10", "desc": "Preparador de massa (fabricação de vidro)"},
  {"cod":"8231-15", "desc": "Preparador de massa de argila"},
  {"cod":"8231-20", "desc": "Preparador de barbotina"},
  {"cod":"8231-25", "desc": "Preparador de esmaltes (cerâmica)"},
  {"cod":"8231-30", "desc": "Preparador de aditivos"},
  {"cod":"8231-35", "desc": "Operador de atomizador"},
  {"cod":"8232-10", "desc": "Extrusor de fios ou fibras de vidro"},
  {"cod":"8232-15", "desc": "Forneiro na fundição de vidro"},
  {"cod":"8232-20", "desc": "Forneiro no recozimento de vidro"},
  {"cod":"8232-30", "desc": "Moldador de abrasivos na fabricação de cerâmica, vidro e porcelana"},
  {"cod":"8232-35", "desc": "Operador de banho metálico de vidro por flutuação"},
  {"cod":"8232-40", "desc": "Operador de máquina de soprar vidro"},
  {"cod":"8232-45", "desc": "Operador de máquina extrusora de varetas e tubos de vidro"},
  {"cod":"8232-50", "desc": "Operador de prensa de moldar vidro"},
  {"cod":"8232-55", "desc": "Temperador de vidro"},
  {"cod":"8232-65", "desc": "Trabalhador na fabricação de produtos abrasivos"},
  {"cod":"8233-05", "desc": "Classificador e empilhador de tijolos refratários"},
  {"cod":"8233-15", "desc": "Forneiro (materiais de construção)"},
  {"cod":"8233-20", "desc": "Trabalhador da elaboração de pré-fabricados (cimento amianto)"},
  {"cod":"8233-25", "desc": "Trabalhador da elaboração de pré-fabricados (concreto armado)"},
  {"cod":"8233-30", "desc": "Trabalhador da fabricação de pedras artificiais"},
  {"cod":"8281-05", "desc": "Oleiro (fabricação de telhas)"},
  {"cod":"8281-10", "desc": "Oleiro (fabricação de tijolos)"},
  {"cod":"8301-05", "desc": "Mestre (indústria de celulose, papel e papelão)"},
  {"cod":"8311-05", "desc": "Cilindreiro na preparação de pasta para fabricação de papel"},
  {"cod":"8311-10", "desc": "Operador de branqueador de pasta para fabricação de papel"},
  {"cod":"8311-15", "desc": "Operador de digestor de pasta para fabricação de papel"},
  {"cod":"8311-20", "desc": "Operador de lavagem e depuração de pasta para fabricação de papel"},
  {"cod":"8311-25", "desc": "Operador de máquina de secar celulose"},
  {"cod":"8321-05", "desc": "Calandrista de papel"},
  {"cod":"8321-10", "desc": "Operador de cortadeira de papel"},
  {"cod":"8321-15", "desc": "Operador de máquina de fabricar papel (fase úmida)"},
  {"cod":"8321-20", "desc": "Operador de máquina de fabricar papel (fase seca)"},
  {"cod":"8321-25", "desc": "Operador de máquina de fabricar papel e papelão"},
  {"cod":"8321-35", "desc": "Operador de rebobinadeira na fabricação de papel e papelão"},
  {"cod":"8331-05", "desc": "Cartonageiro, a máquina"},
  {"cod":"8331-10", "desc": "Confeccionador de bolsas, sacos e sacolas e papel, a máquina"},
  {"cod":"8331-15", "desc": "Confeccionador de sacos de celofane, a máquina"},
  {"cod":"8331-20", "desc": "Operador de máquina de cortar e dobrar papelão"},
  {"cod":"8331-25", "desc": "Operador de prensa de embutir papelão"},
  {"cod":"8332-05", "desc": "Cartonageiro, a mão (caixas de papelão)"},
  {"cod":"8401-05", "desc": "Supervisor de produção da indústria alimentícia"},
  {"cod":"8401-10", "desc": "Supervisor da indústria de bebidas"},
  {"cod":"8401-15", "desc": "Supervisor da indústria de fumo"},
  {"cod":"8401-20", "desc": "Chefe de confeitaria"},
  {"cod":"8411-05", "desc": "Moleiro de cereais (exceto arroz)"},
  {"cod":"8411-10", "desc": "Moleiro de especiarias"},
  {"cod":"8411-15", "desc": "Operador de processo de moagem"},
  {"cod":"8412-05", "desc": "Moedor de sal"},
  {"cod":"8412-10", "desc": "Refinador de sal"},
  {"cod":"8413-05", "desc": "Operador de cristalização na refinação de açucar"},
  {"cod":"8413-10", "desc": "Operador de equipamentos de refinação de açúcar (processo contínuo)"},
  {"cod":"8413-15", "desc": "Operador de moenda na fabricação de açúcar"},
  {"cod":"8413-20", "desc": "Operador de tratamento de calda na refinação de açúcar"},
  {"cod":"8414-08", "desc": "Cozinhador (conservação de alimentos)"},
  {"cod":"8414-16", "desc": "Cozinhador de carnes"},
  {"cod":"8414-20", "desc": "Cozinhador de frutas e legumes"},
  {"cod":"8414-28", "desc": "Cozinhador de pescado"},
  {"cod":"8414-32", "desc": "Desidratador de alimentos"},
  {"cod":"8414-40", "desc": "Esterilizador de alimentos"},
  {"cod":"8414-44", "desc": "Hidrogenador de óleos e gorduras"},
  {"cod":"8414-48", "desc": "Lagareiro"},
  {"cod":"8414-56", "desc": "Operador de câmaras frias"},
  {"cod":"8414-60", "desc": "Operador de preparação de grãos vegetais (óleos e gorduras)"},
  {"cod":"8414-64", "desc": "Prensador de frutas (exceto oleaginosas)"},
  {"cod":"8414-68", "desc": "Preparador de rações"},
  {"cod":"8414-72", "desc": "Refinador de óleo e gordura"},
  {"cod":"8414-76", "desc": "Trabalhador de fabricação de margarina"},
  {"cod":"8414-84", "desc": "Trabalhador de preparação de pescados (limpeza)"},
  {"cod":"8415-05", "desc": "Trabalhador de tratamento do leite e fabricação de laticínios e afins"},
  {"cod":"8416-05", "desc": "Misturador de café"},
  {"cod":"8416-10", "desc": "Torrador de café"},
  {"cod":"8416-15", "desc": "Moedor de café"},
  {"cod":"8416-20", "desc": "Operador de extração de café solúvel"},
  {"cod":"8416-25", "desc": "Torrador de cacau"},
  {"cod":"8416-30", "desc": "Misturador de chá ou mate"},
  {"cod":"8417-05", "desc": "Alambiqueiro"},
  {"cod":"8417-10", "desc": "Filtrador de cerveja"},
  {"cod":"8417-15", "desc": "Fermentador"},
  {"cod":"8417-20", "desc": "Trabalhador de fabricação de vinhos"},
  {"cod":"8417-25", "desc": "Malteiro (germinação)"},
  {"cod":"8417-30", "desc": "Cozinhador de malte"},
  {"cod":"8417-35", "desc": "Dessecador de malte"},
  {"cod":"8417-40", "desc": "Vinagreiro"},
  {"cod":"8417-45", "desc": "Xaropeiro"},
  {"cod":"8418-05", "desc": "Operador de forno (fabricação de pães, biscoitos e similares)"},
  {"cod":"8418-10", "desc": "Operador de máquinas de fabricação de doces, salgados e massas alimentícias"},
  {"cod":"8418-15", "desc": "Operador de máquinas de fabricação de chocolates e achocolatados"},
  {"cod":"8421-05", "desc": "Preparador de melado e essência de fumo"},
  {"cod":"8421-10", "desc": "Processador de fumo"},
  {"cod":"8421-15", "desc": "Classificador de fumo"},
  {"cod":"8421-20", "desc": "Auxiliar de processamento de fumo"},
  {"cod":"8421-25", "desc": "Operador de máquina (fabricação de cigarros)"},
  {"cod":"8421-35", "desc": "Operador de máquina de preparação de matéria prima para produção de cigarros"},
  {"cod":"8422-05", "desc": "Preparador de fumo na fabricação de charutos"},
  {"cod":"8422-10", "desc": "Operador de máquina de fabricar charutos e cigarrilhas"},
  {"cod":"8422-15", "desc": "Classificador de charutos"},
  {"cod":"8422-20", "desc": "Cortador de charutos"},
  {"cod":"8422-25", "desc": "Celofanista na fabricação de charutos"},
  {"cod":"8422-30", "desc": "Charuteiro a mão"},
  {"cod":"8422-35", "desc": "Degustador de charutos"},
  {"cod":"8481-05", "desc": "Defumador de carnes e pescados"},
  {"cod":"8481-10", "desc": "Salgador de alimentos"},
  {"cod":"8481-15", "desc": "Salsicheiro (fabricação de lingüiça, salsicha e produtos similares)"},
  {"cod":"8482-05", "desc": "Pasteurizador"},
  {"cod":"8482-10", "desc": "Queijeiro na fabricação de laticínio"},
  {"cod":"8482-15", "desc": "Manteigueiro na fabricação de laticínio"},
  {"cod":"8483-05", "desc": "Padeiro"},
  {"cod":"8483-10", "desc": "Confeiteiro"},
  {"cod":"8483-15", "desc": "Masseiro (massas alimentícias)"},
  {"cod":"8483-25", "desc": "Trabalhador de fabricação de sorvete"},
  {"cod":"8484-05", "desc": "Degustador de café"},
  {"cod":"8484-10", "desc": "Degustador de chá"},
  {"cod":"8484-15", "desc": "Degustador de derivados de cacau"},
  {"cod":"8484-20", "desc": "Degustador de vinhos ou licores"},
  {"cod":"8484-25", "desc": "Classificador de grãos"},
  {"cod":"8485-05", "desc": "Abatedor"},
  {"cod":"8485-10", "desc": "Açougueiro"},
  {"cod":"8485-15", "desc": "Desossador"},
  {"cod":"8485-20", "desc": "Magarefe"},
  {"cod":"8485-25", "desc": "Retalhador de carne"},
  {"cod":"8486-05", "desc": "Trabalhador do beneficiamento de fumo"},
  {"cod":"8601-05", "desc": "Supervisor de manutenção eletromecânica (utilidades)"},
  {"cod":"8601-10", "desc": "Supervisor de operação de fluidos (distribuição, captação, tratamento de água, gases, vapor)"},
  {"cod":"8601-15", "desc": "Supervisor de operação elétrica (geração, transmissão e distribuição de energia elétrica)"},
  {"cod":"8611-05", "desc": "Operador de central hidrelétrica"},
  {"cod":"8611-10", "desc": "Operador de quadro de distribuição de energia elétrica"},
  {"cod":"8611-15", "desc": "Operador de central termoelétrica"},
  {"cod":"8611-20", "desc": "Operador de reator nuclear"},
  {"cod":"8612-05", "desc": "Operador de subestação"},
  {"cod":"8621-05", "desc": "Foguista (locomotivas a vapor)"},
  {"cod":"8621-10", "desc": "Maquinista de embarcações"},
  {"cod":"8621-15", "desc": "Operador de bateria de gás de hulha"},
  {"cod":"8621-20", "desc": "Operador de caldeira"},
  {"cod":"8621-30", "desc": "Operador de compressor de ar"},
  {"cod":"8621-40", "desc": "Operador de estação de bombeamento"},
  {"cod":"8621-50", "desc": "Operador de máquinas fixas, em geral"},
  {"cod":"8621-55", "desc": "Operador de utilidade (produção e distribuição de vapor, gás, óleo, combustível, energia, oxigênio)"},
  {"cod":"8622-05", "desc": "Operador de estação de captação, tratamento e distribuição de água"},
  {"cod":"8623-05", "desc": "Operador de estação de tratamento de água e efluentes"},
  {"cod":"8623-10", "desc": "Operador de forno de incineração no tratamento de água, efluentes e resíduos industriais"},
  {"cod":"8624-05", "desc": "Operador de instalação de extração, processamento, envasamento e distribuição de gases"},
  {"cod":"8625-05", "desc": "Operador de instalação de refrigeração"},
  {"cod":"8625-10", "desc": "Operador de refrigeração com amônia"},
  {"cod":"8625-15", "desc": "Operador de instalação de ar-condicionado"},
  {"cod":"9101-05", "desc": "Encarregado de manutenção mecânica de sistemas operacionais"},
  {"cod":"9101-10", "desc": "Supervisor de manutenção de aparelhos térmicos, de climatização e de refrigeração"},
  {"cod":"9101-15", "desc": "Supervisor de manutenção de bombas, motores, compressores e equipamentos de transmissão"},
  {"cod":"9101-20", "desc": "Supervisor de manutenção de máquinas gráficas"},
  {"cod":"9101-25", "desc": "Supervisor de manutenção de máquinas industriais têxteis"},
  {"cod":"9101-30", "desc": "Supervisor de manutenção de máquinas operatrizes e de usinagem"},
  {"cod":"9102-05", "desc": "Supervisor da manutenção e reparação de veículos leves"},
  {"cod":"9102-10", "desc": "Supervisor da manutenção e reparação de veículos pesados"},
  {"cod":"9109-05", "desc": "Supervisor de reparos linhas férreas"},
  {"cod":"9109-10", "desc": "Supervisor de manutenção de vias férreas"},
  {"cod":"9111-05", "desc": "Mecânico de manutenção de bomba injetora (exceto de veículos automotores)"},
  {"cod":"9111-10", "desc": "Mecânico de manutenção de bombas"},
  {"cod":"9111-15", "desc": "Mecânico de manutenção de compressores de ar"},
  {"cod":"9111-20", "desc": "Mecânico de manutenção de motores diesel (exceto de veículos automotores)"},
  {"cod":"9111-25", "desc": "Mecânico de manutenção de redutores"},
  {"cod":"9111-30", "desc": "Mecânico de manutenção de turbinas (exceto de aeronaves)"},
  {"cod":"9111-35", "desc": "Mecânico de manutenção de turbocompressores"},
  {"cod":"9112-05", "desc": "Mecânico de manutenção e instalação de aparelhos de climatização e refrigeração"},
  {"cod":"9113-05", "desc": "Mecânico de manutenção de máquinas, em geral"},
  {"cod":"9113-10", "desc": "Mecânico de manutenção de máquinas gráficas"},
  {"cod":"9113-15", "desc": "Mecânico de manutenção de máquinas operatrizes (lavra de madeira)"},
  {"cod":"9113-20", "desc": "Mecânico de manutenção de máquinas têxteis"},
  {"cod":"9113-25", "desc": "Mecânico de manutenção de máquinas-ferramentas (usinagem de metais)"},
  {"cod":"9131-05", "desc": "Mecânico de manutenção de aparelhos de levantamento"},
  {"cod":"9131-10", "desc": "Mecânico de manutenção de equipamento de mineração"},
  {"cod":"9131-15", "desc": "Mecânico de manutenção de máquinas agrícolas"},
  {"cod":"9131-20", "desc": "Mecânico de manutenção de máquinas de construção e terraplenagem"},
  {"cod":"9141-05", "desc": "Mecânico de manutenção de aeronaves, em geral"},
  {"cod":"9141-10", "desc": "Mecânico de manutenção de sistema hidráulico de aeronaves (serviços de pista e hangar)"},
  {"cod":"9142-05", "desc": "Mecânico de manutenção de motores e equipamentos navais"},
  {"cod":"9143-05", "desc": "Mecânico de manutenção de veículos ferroviários"},
  {"cod":"9144-05", "desc": "Mecânico de manutenção de automóveis, motocicletas e veículos similares"},
  {"cod":"9144-10", "desc": "Mecânico de manutenção de empilhadeiras e outros veículos de cargas leves"},
  {"cod":"9144-15", "desc": "Mecânico de manutenção de motocicletas"},
  {"cod":"9144-20", "desc": "Mecânico de manutenção de tratores"},
  {"cod":"9144-25", "desc": "Mecânico de veículos automotores a diesel (exceto tratores)"},
  {"cod":"9151-05", "desc": "Técnico em manutenção de instrumentos de medição e precisão"},
  {"cod":"9151-10", "desc": "Técnico em manutenção de hidrômetros"},
  {"cod":"9151-15", "desc": "Técnico em manutenção de balanças"},
  {"cod":"9152-05", "desc": "Restaurador de instrumentos musicais (exceto cordas arcadas)"},
  {"cod":"9152-10", "desc": "Reparador de instrumentos musicais"},
  {"cod":"9152-15", "desc": "Luthier (restauração de cordas arcadas)"},
  {"cod":"9153-05", "desc": "Técnico em manutenção de equipamentos e instrumentos médico-hospitalares"},
  {"cod":"9154-05", "desc": "Reparador de equipamentos fotográficos"},
  {"cod":"9191-05", "desc": "Lubrificador industrial"},
  {"cod":"9191-10", "desc": "Lubrificador de veículos automotores (exceto embarcações)"},
  {"cod":"9191-15", "desc": "Lubrificador de embarcações"},
  {"cod":"9192-05", "desc": "Mecânico de manutenção de máquinas cortadoras de grama, roçadeiras, motosserras e similares"},
  {"cod":"9193-05", "desc": "Mecânico de manutenção de aparelhos esportivos e de ginástica"},
  {"cod":"9193-10", "desc": "Mecânico de manutenção de bicicletas e veículos similares"},
  {"cod":"9193-15", "desc": "Montador de bicicletas"},
  {"cod":"9501-05", "desc": "Supervisor de manutenção elétrica de alta tensão industrial"},
  {"cod":"9501-10", "desc": "Supervisor de manutenção eletromecânica industrial, comercial e predial"},
  {"cod":"9502-05", "desc": "Encarregado de manutenção elétrica de veículos"},
  {"cod":"9503-05", "desc": "Supervisor de manutenção eletromecânica"},
  {"cod":"9511-05", "desc": "Eletricista de manutenção eletroeletrônica"},
  {"cod":"9513-05", "desc": "Instalador de sistemas eletroeletrônicos de segurança"},
  {"cod":"9513-10", "desc": "Mantenedor de sistemas eletroeletrônicos de segurança"},
  {"cod":"9531-05", "desc": "Eletricista de instalações (aeronaves)"},
  {"cod":"9531-10", "desc": "Eletricista de instalações (embarcações)"},
  {"cod":"9531-15", "desc": "Eletricista de instalações (veículos automotores e máquinas operatrizes, exceto aeronaves e embarcações)"},
  {"cod":"9541-05", "desc": "Eletromecânico de manutenção de elevadores"},
  {"cod":"9541-10", "desc": "Eletromecânico de manutenção de escadas rolantes"},
  {"cod":"9541-15", "desc": "Eletromecânico de manutenção de portas automáticas"},
  {"cod":"9541-20", "desc": "Mecânico de manutenção de instalações mecânicas de edifícios"},
  {"cod":"9541-25", "desc": "Operador eletromecânico"},
  {"cod":"9542-05", "desc": "Reparador de aparelhos eletrodomésticos (exceto imagem e som)"},
  {"cod":"9542-10", "desc": "Reparador de rádio, tv e som"},
  {"cod":"9543-05", "desc": "Reparador de equipamentos de escritório"},
  {"cod":"9911-05", "desc": "Conservador de via permanente (trilhos)"},
  {"cod":"9911-10", "desc": "Inspetor de via permanente (trilhos)"},
  {"cod":"9911-15", "desc": "Operador de máquinas especiais em conservação de via permanente (trilhos)"},
  {"cod":"9911-20", "desc": "Soldador aluminotérmico em conservação de trilhos"},
  {"cod":"9912-05", "desc": "Mantenedor de equipamentos de parques de diversões e similares"},
  {"cod":"9913-05", "desc": "Funileiro de veículos (reparação)"},
  {"cod":"9913-10", "desc": "Montador de veículos (reparação)"},
  {"cod":"9913-15", "desc": "Pintor de veículos (reparação)"},
  {"cod":"9921-05", "desc": "Alinhador de pneus"},
  {"cod":"9921-10", "desc": "Balanceador"},
  {"cod":"9921-15", "desc": "Borracheiro"},
  {"cod":"9921-20", "desc": "Lavador de peças"},
  {"cod":"9922-05", "desc": "Encarregado geral de operações de conservação de vias permanentes (exceto trilhos)"},
  {"cod":"9922-10", "desc": "Encarregado de equipe de conservação de vias permanentes (exceto trilhos)"},
  {"cod":"9922-15", "desc": "Operador de ceifadeira na conservação de vias permanentes"},
  {"cod":"9922-20", "desc": "Pedreiro de conservação de vias permanentes (exceto trilhos)"},
  {"cod":"9922-25", "desc": "Auxiliar geral de conservação de vias permanentes (exceto trilhos)"}
]