import {IDadosPessoaisProposta} from "../../../../../models/GDProposta";
import {AbstractValidation, Validate} from "../../../../../utils/AbstractValidation";

export class DadosPessoaisValidate extends AbstractValidation<IDadosPessoaisProposta> {
  getValidate(): Validate<IDadosPessoaisProposta>[] {
    return [
      {
        field: "nome",
        validate(value, model: IDadosPessoaisProposta, addError: (message: string) => void) {
          if (!value) addError("O Nome deve ser informada")
        }
      },
      {
        field: "celular",
        validate(value, model: IDadosPessoaisProposta, addError: (message: string) => void) {
          if (!value) addError("O celular  deve ser informada")
          else if (value.length < 11) addError("O celular deve ser preenchido corretamente")
        }
      },
      {
        field: "cooperativaId",
        validate(value, model: IDadosPessoaisProposta, addError: (message: string) => void) {
          if (!value) addError("A cooperativa deve ser informada")
        }
      },
    ]
  }
}