import http from "../common/http-common";
import {IPaginatedList} from "../../models/PaginatedList";
import {EnumStatusCargo} from "../../enums/EnumStatusCargo";
import {ICargo} from "../../models/Cargo";
import {AxiosResponse} from "axios";
import {StatusDashboard} from "../../models/StatusDashboard";
import {ICreateCargo} from "../../pages/private/Cargo/Modais/ModalCargo";

export const GetCargos: (pageNumber: number, pageSize: number, search?: string, statusCargo?: (EnumStatusCargo | null))
  => Promise<AxiosResponse<IPaginatedList<ICargo>>> = (
  pageNumber: number,
  pageSize: number,
  search?: string,
  statusCargo?: EnumStatusCargo | null,
) => {
  let url = "/Cargo";
  const params = [];

  if (pageSize) params.push(`PageSize=${pageSize}`);
  if (pageNumber) params.push(`PageNumber=${pageNumber}`);
  if (search) params.push(`Search=${search}`);
  if (statusCargo !== null) params.push(`StatusCargo=${statusCargo}`);

  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  return http.get<IPaginatedList<ICargo>>(url);
};

export const GetStatusCargos: () => Promise<AxiosResponse<StatusDashboard<EnumStatusCargo>[]>> = () => {
  return http.get<StatusDashboard<EnumStatusCargo>[]>("/Cargo/ContaDashboard");
};

export const GetCargoById: (id: string) => Promise<AxiosResponse<ICreateCargo>> = (id: string) => {
  return http.get<ICreateCargo>(`/Cargo/${id}`);
};

export const GeraRelatorio: () => Promise<AxiosResponse<File>> = () => {
  return http.get<File>(
    `/Cargo/BuscaRelatorioCargos`,
    {
      responseType: "blob",
    }
  );
};

export const CreateCargo: (createCargoData: ICreateCargo) => Promise<AxiosResponse<ICreateCargo>> = (createCargoData: ICreateCargo) => {
  return http.post<ICreateCargo>(`/Cargo`, createCargoData);
};

export const UpdateCargo: (updateCargoData: ICreateCargo) => Promise<AxiosResponse<ICreateCargo>> = (updateCargoData: ICreateCargo) => {
  return http.put<ICreateCargo>(`/Cargo`, updateCargoData);
};