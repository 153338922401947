import * as C from "./style";
import * as D from "../../../../../../../styles/appComponents";
import { useState } from "react";
import { Button, ButtonGroup, Stack } from "@mui/material";
import StaticLeadPage from "../StaticLeadPage";
import { ICaptadorConfig } from "../../../../../../../models/CaptadorConfig";

export type Proporcoes = "16/9" | "16/10" | "4/3";
export type Dispositivo = "Desktop" | "Mobile";
export type MobileModelos = "Iphone 15" | "Samsung S24";
export type LeadPage = "Gera Lead" | "Anexar Conta";

interface PreviewProps {
  theme: ICaptadorConfig;
}

const Preview: React.FC<PreviewProps> = ({ theme }) => {
  const [proporcao, setProporcao] = useState<Proporcoes>("16/9");
  const [dispositivo, setDispositivo] = useState<Dispositivo>("Desktop");
  const [modelo, setModelo] = useState<MobileModelos>("Iphone 15");
  const [leadPage, setLeadPage] = useState<LeadPage>("Gera Lead");

  return (
    <D.FWStack
      direction={"column"}
      spacing={2}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <D.FWStack direction={"row"} spacing={2} justifyContent={"space-between"}>
        <h3>Preview</h3>

        <Stack direction={"row"} spacing={2}>
          <ButtonGroup>
            <Button
              color="primary"
              variant={leadPage == "Gera Lead" ? "contained" : "outlined"}
              onClick={() => setLeadPage("Gera Lead")}
            >
              Gerar Lead
            </Button>
            <Button
              variant={leadPage == "Anexar Conta" ? "contained" : "outlined"}
              onClick={() => setLeadPage("Anexar Conta")}
            >
              Anexar Conta
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button
              color="primary"
              variant={dispositivo == "Desktop" ? "contained" : "outlined"}
              onClick={() => setDispositivo("Desktop")}
            >
              Desktop
            </Button>
            <Button
              variant={dispositivo == "Mobile" ? "contained" : "outlined"}
              onClick={() => setDispositivo("Mobile")}
            >
              Mobile
            </Button>
          </ButtonGroup>
          <>
            {dispositivo == "Desktop" ? (
              <ButtonGroup>
                <Button
                  color="primary"
                  variant={proporcao == "16/9" ? "contained" : "outlined"}
                  onClick={() => setProporcao("16/9")}
                >
                  16/9
                </Button>
                <Button
                  color="primary"
                  variant={proporcao == "16/10" ? "contained" : "outlined"}
                  onClick={() => setProporcao("16/10")}
                >
                  16/10
                </Button>
                <Button
                  color="primary"
                  variant={proporcao == "4/3" ? "contained" : "outlined"}
                  onClick={() => setProporcao("4/3")}
                >
                  4/3
                </Button>
              </ButtonGroup>
            ) : (
              <ButtonGroup>
                <Button
                  color="primary"
                  variant={modelo == "Iphone 15" ? "contained" : "outlined"}
                  onClick={() => setModelo("Iphone 15")}
                >
                  Iphone 14
                </Button>
                <Button
                  color="primary"
                  variant={modelo == "Samsung S24" ? "contained" : "outlined"}
                  onClick={() => setModelo("Samsung S24")}
                >
                  Samsung S24
                </Button>
              </ButtonGroup>
            )}
          </>
        </Stack>
      </D.FWStack>
      <D.FWStack alignItems={"center"} justifyContent={"center"}>
        <C.PreviewContainer
          $proporcao={proporcao}
          $dispositivo={dispositivo}
          $modelo={modelo}
        >
          <C.Container
            $proporcao={proporcao}
            $dispositivo={dispositivo}
            $modelo={modelo}
          >
            <StaticLeadPage
              leadPage={leadPage}
              theme={theme}
              dispositivo={dispositivo}
            />
          </C.Container>
        </C.PreviewContainer>
      </D.FWStack>
    </D.FWStack>
  );
};

export default Preview;
