import { Modal, TextField } from "@mui/material"
import * as C from "./style";
import * as D from "../../../../styles/appComponents";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../../../hooks/useAuth";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { boolean } from "zod";
import { GeradorResponse, UsinaResponse } from "../../../../models/Gerador/GeradorResponse";
import { GetOneGerador, SendWhatsappSupport } from "../../../../services/api/GeradorService";
import { CELULAR } from "../../../../utils/masInputs";
import { CircularSpinner } from "../../../../components/CircularSpinner";
import editar from "../../../../assets/Icones/editar.svg"
import MaskDefaultInput from "../../../../components/MasDefaultinput/MaskDefaultInput";
import { toastMessage } from "../../../../utils/toastMessage";

export const Support = ({ children }: any) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [gerador, setGerador] = useState<GeradorResponse | null>(null);
    const [disableEdit, setDisableEdit] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false);
    const [telephone, setTelephone] = useState<string>("");
    const [countryCode, setCountryCode] = useState("");
    const [message, setMessage] = useState<string>("")
    const support = searchParams.get("support")
    const { user } = useAuth();

    const geradorAccess: string | null = useMemo(() => user?.idGerador || null, [user]);
    const openModal: boolean = useMemo(() => !!support, [support]);

    useEffect(() => {
        loadGerador();

    }, [geradorAccess, openModal])

    const closeModal = () => {
        searchParams.delete('support');
        setSearchParams(searchParams);

    }

    const sendWhatsappSupport = async () => {
        setLoading(true)

        try {
            if(geradorAccess){
                await SendWhatsappSupport({ geradorId:geradorAccess, whatsapp:`${countryCode}${telephone}` });
                setTimeout(() => {
                    setLoading(false);
                    closeModal();
                }, 5 * 1000)
            }

        } catch (error) {
            setLoading(false);
            console.error("error ao enviar mensagem para o support")
            toastMessage("warning", "Ocorreu um erro ao contatar o suporte")
        }
    }

    const loadGerador = async () => {

        try {
            if (geradorAccess) {
                const { data } = await GetOneGerador(geradorAccess);
                setGerador(data);
            }

        } catch (error) {
            console.error("Error ao listar gerador", error)
        }
    }

    return (
        <>
            <Modal
                open={!!geradorAccess && openModal}
                onClose={closeModal}
                aria-labelledby="modal-details"
                aria-describedby="modal-details"
            >
                <C.ModalContainer>
                    <C.ModalArea height="60%" width="40%">
                        <C.ModalCard>
                            <>
                            <C.ModalHeader>
                                <div></div>
                                <C.CloseButton
                                    aria-label="close"
                                    onClick={closeModal}
                                >
                                    <CloseIcon />
                                </C.CloseButton>
                            </C.ModalHeader>
                            <div style={{ margin: "10px" }}>
                                <h1>Nossa equipe de suporte entrará em contato em breve.</h1>
                                <p>Iremos entrar em contato no seguinte numero:</p>
                                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }} >
                                    <MaskDefaultInput
                                        type="CELULAR"
                                        label="Celular"
                                        readonly={disableEdit}
                                        value={gerador?.usuario?.whatsapp ?? ""}
                                        valueCountryCode={gerador?.usuario?.countryCodeWhatsapp ?? ""}
                                        onChange={(value:any) =>
                                            {
                                                setTelephone(value?.value);
                                                setCountryCode(value?.zipCode);
                                            }
                    
                                        }
                                        sx={{
                                            width: "70%",
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    border: 'none'
                                                }
                                            }
                                        }}
                                    />
                                    {/* <TextField
                                        onBlur={() => setDisableEdit(true)}
                                        value={new CELULAR().execute(gerador?.usuario?.whatsapp ?? "")}
                                        sx={{
                                            margin: 0,
                                            padding: 0,
                                            width: "40%",
                                            '& .MuiOutlinedInput-root': {
                                                padding: 0,
                                                '& fieldset': {
                                                    border: 'none'
                                                }
                                            },
                                            '& .MuiInputBase-input': {
                                                padding: 0
                                            }
                                        }}
                                        inputProps={{ readOnly: disableEdit }}
                                        
                                    /> */}
                                    <div onClick={() => setDisableEdit(false)} style={{ display: "flex", flexDirection: "row", cursor:"pointer", gap: 2 }} >
                                        <img src={editar} sizes="10" height="15px" alt="" />
                                        <span>Editar numero</span>
                                    </div>
                                </div>

                                <div style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: 10, gap: 4 }}>
                                    {
                                        loading?
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                        <CircularSpinner
                                            color="pink"
                                            size="30px"
                                        />
                                    </div>
                                    :
                                    <>
                                        <D.ContainedButton $color="pink" onClick={sendWhatsappSupport} style={{ height: 0 }}>
                                            Enviar
                                        </D.ContainedButton>
                                        <D.OutlineButton $color="pink" onClick={closeModal} style={{ height: 40 }}>
                                            Fechar
                                        </D.OutlineButton>
                                    </>
                                    }

                                </div>
                            </div>
                            </>

                        </C.ModalCard>
                    </C.ModalArea>
                </C.ModalContainer>

            </Modal>
            {children}
        </>
    )
}