import { Tooltip, Typography } from "@mui/material";
import { AbstractValidation } from "./AbstractValidation";
import { HelperText } from "../types/HelperText";

export interface AddValidateReturn {
  onBlur: () => void;
  onFocus: () => void;
  helperText: JSX.Element;
  error: boolean;
}

export function addValidate<T>(
  validate: AbstractValidation<T>,
  campo: keyof T,
  dados: T,
  helperText: HelperText<T>,
  setHelperText: (novoHelperText: HelperText<T>) => void
): AddValidateReturn {
  return {
    onBlur: () => {
      const erros = validate.validarCampo(dados, campo);
      if (erros !== null && erros.length > 0) {
        setHelperText({
          ...helperText,
          [campo]: erros[0].message,
        });
      }
    },
    onFocus: () =>
      setHelperText({
        ...helperText,
        [campo]: null,
      }),
    helperText: (
      <Tooltip title={helperText[campo]}>
        <Typography noWrap>{helperText[campo]}</Typography>
      </Tooltip>
    ),
    error: Boolean(helperText[campo]),
  };
}
