import {useEffect, useState} from "react";
import {StatusBuilder} from "../../models/StatusBuilder"
import {Checkbox, Tooltip} from "@mui/material";
import {ColorType} from "../../types/ColorType";
import {formatValueFloatInString, formatValues} from "../../utils/moneyFormatter";
import StatusCard from "../StatusCard";
import * as C from "./style"
import * as D from "../../styles/appComponents";
import TotalCard from "../TotalCard";
import {TabsComponent} from "../TabsComponent";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {HandleDataStatus} from "../../utils/HandleDataStatus";
import {StatusDashboard} from "../../models/StatusDashboard";
import {KWH, REAL} from "../../utils/masInputs";
import SkeletonDefaultPage from "../SkeletonLoads/DefaultPage";
import { EnumStatusContratoGerador } from "../../enums/Gerador/EnumStatusContratoGerador.enum";

export interface ListViewProps<T>{
    statusContratoBuilder: StatusBuilder[];
    sectionName:string;
    data: StatusDashboard<T>[];
    children?: React.ReactNode;
    getSendClickSign?:React.Dispatch<React.SetStateAction<boolean>>;
    getFilterStatus:React.Dispatch<React.SetStateAction<any>>;
    filterStatusContrato:any;
    hasButtonSendClickSign: boolean;
    hasOtherStatus: boolean;
    isNotSendClickSign?:boolean;
    hasSecondaryValues?:boolean;
    hasThridValues?:boolean;
    statusLoading?:{ sectionName:string, loading:boolean }
}

export const ListView = <T,>(
    {
        statusContratoBuilder,
        data=[],
        children,
        getSendClickSign,
        getFilterStatus,
        filterStatusContrato,
        hasButtonSendClickSign=false,
        isNotSendClickSign,
        sectionName,
        hasOtherStatus,
        hasSecondaryValues=true,
        hasThridValues=false,
        statusLoading
    }: ListViewProps<T>
) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
    const [statusOpen, setStatusOpen] = useState(false);
    const [otherStatusOpen, setOtherStatusOpen] = useState(false);

    const handleStatusOpen = () => {
        setStatusOpen((x) => !x);
    };

    const handleOtherStatusOpen = () => {
        setOtherStatusOpen((x) => !x);
    };

    const handleChangeIsIsign = (event: any) => {
        if(getSendClickSign)
            getSendClickSign(event?.target?.checked);
    };

    return(
        <>
        <C.Title onClick={() => handleStatusOpen()}>
            Status
            {isMobile && (
              <>
                {!statusOpen ? (
                  <KeyboardArrowDownRoundedIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </>
            )}
          </C.Title>

          {(!isMobile || (statusOpen && isMobile)) && (
            <C.StatusArea>
              {
                (data || [])
                ?.filter((f) => HandleDataStatus.getHasPrimaryStatusContrato(f?.enumStatus, statusContratoBuilder))
                ?.map((x) => (
                  <Tooltip 
                  title={`Clica aqui para filtrar por status ${HandleDataStatus.getNameStatus(x?.enumStatus, statusContratoBuilder)}`}
                >
                  <C.StatusWrapper
                    style={{ cursor:"pointer" }} 
                    onClick={() => getFilterStatus(x?.enumStatus)} 
                  >
                    <StatusCard
                      color={HandleDataStatus.getColorStatus(x?.enumStatus, statusContratoBuilder) as ColorType}
                      text={HandleDataStatus.getNameStatus(x?.enumStatus, statusContratoBuilder) || ""}
                      number={x.totalStatus || 0}
                    />

                    {
                      hasSecondaryValues &&
                      <TotalCard
                        color={HandleDataStatus.getColorTitle(x?.enumStatus, statusContratoBuilder) as ColorType | null? HandleDataStatus.getColorTitle(x?.enumStatus, statusContratoBuilder) as ColorType | null: HandleDataStatus.getColorStatus(x?.enumStatus, statusContratoBuilder) as ColorType | null}
                        title={HandleDataStatus.getTitleSecondary(x?.enumStatus, statusContratoBuilder)}
                        text={HandleDataStatus.getTypeFormatter(x?.enumStatus, statusContratoBuilder) === "KWH"? 
                          `${new KWH().execute(x?.energiaTotalInjetada as any)} KWH`: 
                          formatValues(x?.totalValue ?? 0)}
                        evolutionNumber="+8%"
                        evolution={true}
                      />
                    }

                    {
                      hasThridValues &&
                        <TotalCard
                            color={HandleDataStatus.getColorTitle(x?.enumStatus, statusContratoBuilder) as ColorType | null? HandleDataStatus.getColorTitle(x?.enumStatus, statusContratoBuilder) as ColorType | null: HandleDataStatus.getColorStatus(x?.enumStatus, statusContratoBuilder) as ColorType | null}
                            title={HandleDataStatus.getTitleThird(x?.enumStatus, statusContratoBuilder)}
                            text={HandleDataStatus.getTypeFormatterThird(x?.enumStatus, statusContratoBuilder) === "KWH"? 
                              `${new KWH().execute(x?.energiaTotalInjetada as any)} KWH`: 
                              formatValues(x?.totalValue ?? 0)}
                            evolutionNumber="+8%"
                            evolution={true}
                        />
                    }

                  </C.StatusWrapper>
                </Tooltip>
                ))
              }
            </C.StatusArea>
          )}

          {
            hasOtherStatus &&
            <C.Title style={{ cursor:"pointer" }} onClick={() => handleOtherStatusOpen()}>
              Outros Status
              {!otherStatusOpen ? (
                <KeyboardArrowDownRoundedIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </C.Title>
          }

          {
            otherStatusOpen &&
            <C.StatusArea>
              {
                (data || [])
                ?.filter((f) => !HandleDataStatus.getHasPrimaryStatusContrato(f?.enumStatus, statusContratoBuilder))
                ?.map((x) => (
                  <Tooltip 
                  title={`Clique aqui para filtrar por status ${HandleDataStatus.getNameStatus(x?.enumStatus, statusContratoBuilder)}`}
                >
                  <C.StatusWrapper
                    style={{ cursor:"pointer" }} 
                    onClick={() => getFilterStatus(x?.enumStatus)} 
                  >
                    <StatusCard
                      color={HandleDataStatus.getColorStatus(x?.enumStatus, statusContratoBuilder) as ColorType || "yellow"}
                      text={HandleDataStatus.getNameStatus(x?.enumStatus, statusContratoBuilder) || ""}
                      number={x.totalStatus || 0}
                    />
                  </C.StatusWrapper>
                </Tooltip>
                ))
              }
            </C.StatusArea>
          }

          <TabsComponent
              getStatus={getFilterStatus}
              status={filterStatusContrato}
              enumStatusContratoMap={statusContratoBuilder}
              sectionName={sectionName}
            >
            { hasButtonSendClickSign &&
                filterStatusContrato === EnumStatusContratoGerador.aguardandoAssinatura &&
                <D.CheckboxWrapper style={{ background:"white", paddingTop:"30px" }}>
                <Checkbox checked={isNotSendClickSign} onChange={handleChangeIsIsign} />
                <p>* Contrato não enviado para Click Sign</p>
                </D.CheckboxWrapper>
            }

            {
              statusLoading?.sectionName === sectionName && statusLoading.loading?
              <SkeletonDefaultPage />:
              children
            }
          </TabsComponent>
        </>
    )
}