import { IAnexoDTO } from "../../models/Anexo";
import { ICreateAssociado } from "../../models/Associado";
import {
  ICaptador,
  ICaptadorDadosContrato,
  ICaptadorListItem,
  ICaptadorResponsavelUpdateRequest,
  ICaptadorResponse,
} from "../../models/Captador";
import { CaptadorListItemTabelaPaginaSchema } from "../../models/CaptadorListItemTabelaPagina";
import { IUpdateDadosAcessoRequest } from "../../models/Colaborador/cadastros/DadosAcesso";
import { IUpdateDadosDepositoRequest } from "../../models/DadosBancario";
import { Filtro } from "../../models/Filtro";
import { InformacaoAssociadoReq } from "../../models/InformacaoAssociado";
import { IUpdateLogoReq } from "../../models/Logo";
import { TotalStatusContratoParceiroSchema } from "../../models/TotalStatusContratoParceiroSchema";
import http from "../common/http-common";

export const GetCaptadores = () => {
  return http.get<ICaptadorListItem[]>(`/Captador/BuscaTodosCaptadores`);
};

export const GetById = (id: string) => {
  return http.get<ICaptadorResponse>(`/Captador/${id}`);
};

export const CreateAssociado = (createAssociado: ICreateAssociado) => {
  return http.post<ICaptador>(`/Captador/Associado`, createAssociado);
};

export const GetLimiteDesconto = (idCaptador: string | null) => {
  return http.get<string>(`/captador/buscaLimiteDesconto?id=${idCaptador}`);
};

export const UpdateAssociadoDadosContrato = (
  updateDadosContrato: ICaptadorDadosContrato
) => {
  return http.patch<boolean>(
    "/Captador/Associado/DadosContrato",
    updateDadosContrato
  );
};

export const UpdateResponsavelLegal = (
  req: ICaptadorResponsavelUpdateRequest
) => {
  return http.patch<boolean>("/Captador/Associado/ReponsavelLegal", req);
};

export const UpdateCaptadorLogo = async (data: IUpdateLogoReq) => {
  const formData = new FormData();
  formData.append("captadorId", data.captadorId);

  Object.entries(data.dicLogo).forEach(([formato, logoDetalhes]) => {
    if (logoDetalhes) {
      if ("arquivo" in logoDetalhes) {
        formData.append(`DicLogo[${formato}].arquivo`, logoDetalhes.arquivo);
        formData.append(
          `DicLogo[${formato}].descricao`,
          logoDetalhes.descricao
        );
        formData.append(
          `DicLogo[${formato}].usuarioAnexouId`,
          logoDetalhes.usuarioAnexouId
        );
      } else {
        formData.append(`DicLogo[${formato}].id`, logoDetalhes.id);
      }
    } else {
      formData.append(`DicLogo[${formato}]`, "");
    }
  });

  return http.patch<Record<string, IAnexoDTO | null>>(
    "/Captador/Logo",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const GetLogo = (id: string) => {
  return http.get<Record<string, IAnexoDTO | null>>(`/Captador/Logo/${id}`);
};

export const UpdateDadosAcesso = (req: IUpdateDadosAcessoRequest) => {
  return http.patch<unknown>("/Captador/DadosAcesso", req);
};

export const UpdateDadosPagamento = (req: IUpdateDadosDepositoRequest) => {
  return http.patch<unknown>("/Captador/DadosBancario", req);
};

export const Listar = (
  pesquisa: string | null,
  pagina: number,
  tamanhoPagina: number
) => {
  const query = new URLSearchParams();
  query.set("pagina", pagina.toString());
  query.set("tamanhoPagina", tamanhoPagina.toString());

  return http
    .get<unknown>(`/Captador/Lista?${query.toString()}`)
    .then((x) => CaptadorListItemTabelaPaginaSchema.parse(x.data));
};

export const GetStatus = () => {
  return http
    .get<unknown>("/Captador/Status")
    .then((x) => TotalStatusContratoParceiroSchema.parse(x.data));
};

export const CreateCaptador = (req: ICaptadorDadosContrato) => {
  return http.post<string>("/Captador/", req);
};

export const GetCaptadoresFiltro = (isMaster?: boolean) => {
  const query = new URLSearchParams();
  if (isMaster) query.set("isMaster", isMaster.toString());
  return http.get<ICaptadorListItem[]>(
    `/Captador/BuscaCaptadores?${query.toString()}`
  );
};

export const UpdateUpdateInformacaoAssociado = (
  req: InformacaoAssociadoReq
) => {
  return http.patch<unknown>("/Captador/InformacaoAssociado", req);
};

export const CriaContratoParaAssinaturaDigital = (captadorId: string) => {
  const url = `/Captador/CriaContratoParaAssinaturaDigital/${captadorId}`;
  return http.post<string>(url);
};
