export enum ResponsavelUsinaEnum{
    NOMERESPONSAVELUSINA="NOMERESPONSAVELUSINA",
    EMAILRESPONSAVELUSINA="EMAILRESPONSAVELUSINA",
    CELULARRESPONSAVELUSINA="CELULARRESPONSAVELUSINA",
    CPFRESPONSAVELUSINA="CPFRESPONSAVELUSINA",
    CEPRESPONSAVELUSINA="CEPRESPONSAVELUSINA",
    UFRESPONSAVELUSINA="UFRESPONSAVELUSINA",
    CIDADERESPONSAVELUSINA="CIDADERESPONSAVELUSINA",
    BAIRRORESPONSAVELUSINA="BAIRRORESPONSAVELUSINA",
    NUMERORESPONSAVELUSINA="NUMERORESPONSAVELUSINA",
    COMPLEMENTORESPONSAVELUSINA="COMPLEMENTORESPONSAVELUSINA",
    LOGRADOURORESPONSAVELUSINA="LOGRADOURORESPONSAVELUSINA",
    DATANASCIMENTORESPONSAVELUSINA="DATANASCIMENTORESPONSAVELUSINA",
    COUNTRYCODERESPONSAVELUSINA="COUNTRYCODERESPONSAVELUSINA",
}