import { MenuItem, TextField } from "@mui/material";
import RegisterCard from "../../../../../components/RegisterCard";
import * as D from "../../../../../styles/appComponents";
import {
  EnumStatusGDProposta, EnumStatusGDPropostaMapSelect,
  EnumStatusGDPropostaNumericMap,
  StatusPropostaColorMap, StatusPropostaNumberMap,
} from "../../../../../enums/EnumStatusGDProposta.enum";
import { useEffect, useState } from "react";

interface IStatusPropostaGerarPropostaProps {
  initialStatus: number;
  onSendData: (data: number) => void;
}

const StatusPropostaGerarProposta: React.FC<
  IStatusPropostaGerarPropostaProps
> = ({ initialStatus, onSendData }) => {
  const [status, setStatus] = useState<number>(1);

  useEffect(() => {
    setStatus(initialStatus);
  }, [initialStatus]);

  useEffect(() => {
    onSendData(status);
  }, [status]);

  return (
    <RegisterCard title="Status da proposta">
      <D.FWStack direction={"row"} spacing={2} alignItems={"center"}>
        <TextField
          select
          fullWidth
          label="Status da proposta"
          value={status}
          onChange={(e) => setStatus(Number(e.target.value))}
        >
          {Object.keys(EnumStatusGDPropostaMapSelect).map((key, index) => (
            <MenuItem
              key={key}
              value={
                EnumStatusGDPropostaNumericMap[
                  EnumStatusGDProposta[key as keyof typeof EnumStatusGDProposta]
                ]
              }
            >
              <D.FWStack direction={"row"} spacing={2} alignItems={"center"}>
                <D.Circle
                  color={
                    StatusPropostaColorMap[
                      EnumStatusGDPropostaNumericMap[
                        EnumStatusGDProposta[
                          key as keyof typeof EnumStatusGDProposta
                        ]
                      ]
                    ]
                  }
                ></D.Circle>
                <span>
                  {
                    EnumStatusGDPropostaMapSelect[
                      key as keyof typeof EnumStatusGDPropostaMapSelect
                    ]
                  }
                </span>
              </D.FWStack>
            </MenuItem>
          ))}
        </TextField>
        <D.FWStack></D.FWStack>
        <D.FWStack></D.FWStack>
      </D.FWStack>
    </RegisterCard>
  );
};

export default StatusPropostaGerarProposta;
