import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import SkeletonDefaultPage from "../../../components/SkeletonLoads/DefaultPage";
import * as C from "../Gerador/style";
import * as Cards from "../../../components/TotalCard/style";
import * as CC from "../../../components/StatusCard/style";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import * as D from "../../../styles/appComponents";
import Datatable from "../../../components/Datatable";
import ExcelIcon from "../../../assets/Plataforma/excelIcon.svg";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import {AxiosResponse} from "axios";
import {IPaginatedList} from "../../../models/PaginatedList";
import {ColorType} from "../../../types/ColorType";
import {CNPJ, CPF, DECIMAL, KWH} from "../../../utils/masInputs";
import {
  GetTodasUsinas
} from "../../../services/api/GeradorService";
import {
  GetStats,
  GerarRelatorioExtratoDeUsinas,
  GetDashboardExtratoDeUsinas,
  GetExtratoDeUsinas,
} from "../../../services/api/GDContratoClienteService";
import {Autocomplete, TextField} from "@mui/material";
import {IBuscaTodasUsinasPorNome} from "../../../models/TarifaFioB";
import {toastMessage} from "../../../utils/toastMessage";
import {EnumStatusContratoCliente, StatusContratoColorMap} from "../../../enums/EnumStatusContratoCliente.enum";
import {StatusDashboard} from "../../../models/StatusDashboard";
import {ListView} from "../../../components/ListView";
import {EnumStatusExtratoPorUsinaMap} from "../../../enums/EnumExtratoPorUsina";
import {EnumTipoDocumento} from "../../../enums/EnumTipoDocumento.enum";
import {formatValues, formatValueStringInFloat, moneyFormatter} from "../../../utils/moneyFormatter";
import { InputFilterModel } from '../../../models/InputFilterModel';

export interface IExtratoPorUsina {
  idReferencial: string;
  id: string;
  cliente: string;
  cpf: string;
  instalacao: string;
  kwh: string;
  valor: number;
  rendimento: number;
  status: EnumStatusContratoCliente;
  usina: string;
  tipoDocumento: EnumTipoDocumento;
  captador: number;
}

export interface IDashboardExtratoPorUsina {
  valorTotalAssinatura: number;
  totalContratos: number;
  valorTotalComissao: number;
  ticketMedio: number;
}

export interface IExtratoPorUsinaReport {
  usinaId: string;
}

export enum ExtratoPorUsinaReportEnum {
  USINA = "usinaId",
}

function ExtratoPorUsina() {
  //region Variáveis
  const [statusOpen] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [usinas, setUsinas] = useState<IBuscaTodasUsinasPorNome[]>([]);
  const [extratoPorUsina, setExtratoPorUsina] = useState<IExtratoPorUsina[]>([]);
  const [page, setPage]: (number | Dispatch<SetStateAction<number>>)[] = useState(1)
  const [loading, setLoading]: (boolean | Dispatch<SetStateAction<boolean>>)[] = useState<boolean>(true);
  const [isMobile, setIsMobile]: (boolean | Dispatch<SetStateAction<boolean>>)[] = useState(window.innerWidth < 1024);
  const [statusContratoCliente, setStatusContratoCliente] = useState<StatusDashboard<EnumStatusContratoCliente>[]>([]);
  const [filterStatusContratoCliente, setFilterStatusContratoCliente] = useState<EnumStatusContratoCliente | null | undefined>(null);
  const sectionName = "extratoUsina";
  const [statusLoading, setStatusLoading] = useState<{sectionName:string, loading:boolean}>({ sectionName, loading:false })
  const [modelFilter, setModelFilter] = useState<InputFilterModel | null>(null);
  const [extratPorUsinaReport, setExtratPorUsinaReport] = useState<IExtratoPorUsinaReport>({
    usinaId: "",
  });
  const [dashboardSummaryExtratoPorUsina, setDashboarSummaryDeExtratoPorUsina] = useState<IDashboardExtratoPorUsina>({
    ticketMedio: 0,
    totalContratos: 0,
    valorTotalAssinatura: 0,
    valorTotalComissao: 0
  });

  const rowsWithDescription = (extratoPorUsina || []).map((row) => ({
    ...row,
    descriptionCpf: row.tipoDocumento === EnumTipoDocumento.CNPJ ? "CNPJ" : "CPF"
  }));

  const columns: GridColDef[] = [
    {
      field: "idReferencial",
      headerName: "ID",
      description: "id",
      flex: 1,
      maxWidth: 70,
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField>
          {params.value}
        </D.GridField>
      )
    },
    {
      field: "cliente",
      headerName: "Cliente",
      description: "name",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField>
          {params.value ?? ""}
        </D.GridField>
      )
    },
    {
      field: "cpf",
      headerName: "CPF/CNPJ",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField>
          {params.row.tipoDocumento === EnumTipoDocumento.CNPJ ? new CNPJ().executeForInput(params.value ?? "") : new CPF().executeForInput(params.value ?? "")}
        </D.GridField>
      )
    },
    {
      field: "instalacao",
      headerName: "Instalação",
      description: "instalacao",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField>
          {params.value}
        </D.GridField>
      )
    },
    {
      field: "kwh",
      headerName: "kwh",
      description: "kwh",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField>
          {new KWH().execute(params.value)}
        </D.GridField>
      )
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField>
          {moneyFormatter.format(formatValueStringInFloat(params.value))}
        </D.GridField>
      )
    },
    {
      field: "rendimento",
      headerName: "Rendimento",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField>
          {moneyFormatter.format(formatValueStringInFloat(params.value))}
        </D.GridField>
      )
    },
    {
      field: "usina",
      headerName: "Usina",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField>
          {params.value}
        </D.GridField>
      )
    },
    {
      field: "captador",
      headerName: "Captador",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField>
          {params.value}
        </D.GridField>
      )
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        const type: ColorType = params.value == 1 ? "green" : "red";
        return (
          <D.GridField $alignCenter>
            <D.Circle color={StatusContratoColorMap[params.value]}/>
          </D.GridField>
        )
      }
    },
  ];
  //endregion

  //region Services
  const loadData:(model:InputFilterModel | null, type:"clear"|"filter") => Promise<void> = async (model:InputFilterModel | null, type:"clear"|"filter"): Promise<void> => {
    setStatusLoading({ sectionName, loading:true });
    setModelFilter(model);

    try {
      const {data}: AxiosResponse<IPaginatedList<IExtratoPorUsina>, any> = await GetExtratoDeUsinas(
        (model?.id? parseInt(model?.id): null),
        model?.instalacao,
        model?.name,
        page, 
        pageSize, 
        "", 
        filterStatusContratoCliente
      );
      setTotalPages(data?.totalPages);
      setTotalPages(data.totalPages);
      setPageSize(data?.pageSize);
      setExtratoPorUsina(data?.data);
      setStatusLoading({ sectionName, loading:false });
    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar os extratos");
      setStatusLoading({ sectionName, loading:false });
    } finally {
      setLoading(false);
      setStatusLoading({ sectionName, loading:false });
    }
  }

  const getExtratoDeUsinas: (search?: string) => Promise<void> = async (search?: string): Promise<void> => {
    setStatusLoading({ sectionName, loading:true });
    try {
      const {data}: AxiosResponse<IPaginatedList<IExtratoPorUsina>, any> = await GetExtratoDeUsinas(
        (modelFilter?.id? parseInt(modelFilter?.id): null),
        modelFilter?.instalacao,
        modelFilter?.name,
        page, 
        pageSize, 
        search, 
        filterStatusContratoCliente
      );
      setTotalPages(data?.totalPages);
      setTotalPages(data.totalPages);
      setPageSize(data?.pageSize);
      setExtratoPorUsina(data?.data);
    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar os extratos");
      setStatusLoading({ sectionName, loading:false });
    } finally {
      setLoading(false);
      setStatusLoading({ sectionName, loading:false });
    }
  }

  const getSummarydashboardextratoPorUsina: () => Promise<void> = async (): Promise<void> => {
    try {
      const {data}: AxiosResponse<any, IDashboardExtratoPorUsina> = await GetDashboardExtratoDeUsinas();
      setDashboarSummaryDeExtratoPorUsina(data);
    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar o dashboard");
    }
  }

  const getdashboardContratoCliente: () => Promise<void> = async (): Promise<void> => {
    try {
      const {data}: AxiosResponse<any, StatusDashboard<EnumStatusContratoCliente>[]> = await GetStats();
      setStatusContratoCliente(data);
    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar o dashboard");
    }
  }

  const getUsinas: () => Promise<void> = async (): Promise<void> => {
    try {
      const response: AxiosResponse<any, IBuscaTodasUsinasPorNome> = await GetTodasUsinas();
      setUsinas(response.data);
    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar as usinas");
    }
  }

  const geraRelatorio: () => Promise<void> = async () => {
    try {
      const response: AxiosResponse<File, any> = await GerarRelatorioExtratoDeUsinas(extratPorUsinaReport.usinaId);

      const blob: File = response.data;

      const link: HTMLAnchorElement = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `extratoPorUsina_${new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/[-:T]/g, "")}.xlsx`;
      link.click();

      URL.revokeObjectURL(link.href);
    } catch (e) {
      toastMessage("error", "Erro ao tentar criar o relatório.");
    }
  }
  //endregion

  //region UI
  const handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setExtratPorUsinaReport((state: IExtratoPorUsinaReport) => {
      const updatedState = {
        ...state,
        [name]: value,
      };
      return updatedState;
    });
  }

  const handleChangePage: (value: number) => void = (value: number): void => {
    setPage(value);
  };

  const handleChangePageSize: (value: number) => void = (value: number): void => {
    setPageSize(value);
  };

  const handleSearch: (value: string) => Promise<void> = async (value: string): Promise<void> => {
    getExtratoDeUsinas(value);
  };

  useEffect((): () => void => {
    const handleResize: () => void = (): void => {
      setIsMobile(window.innerWidth < 1024);
    };

    getExtratoDeUsinas();
    getSummarydashboardextratoPorUsina();
    getUsinas();
    getdashboardContratoCliente();
    window.addEventListener("resize", handleResize);
    return (): void => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getExtratoDeUsinas();
  }, [filterStatusContratoCliente]);

  useEffect(() => {
    getExtratoDeUsinas();
  }, [page, pageSize]);

  //endregion

  return (
    <>
      {
        loading ? (<SkeletonDefaultPage/>) : (
          <>
            <C.Container>
              <C.Title>
                Status
                {isMobile && (
                  <>
                    {!statusOpen ? (
                      <KeyboardArrowDownRoundedIcon/>
                    ) : (
                      <KeyboardArrowUpIcon/>
                    )}
                  </>
                )}
              </C.Title>

              {(!isMobile || (statusOpen && isMobile)) && (
                <C.StatusArea style={{flexWrap: "nowrap"}}>
                  <C.StatusWrapperOrdemPagamento>
                    <Cards.Card>
                      <Cards.Top>
                        <Cards.TopTextArea>
                          <Cards.Text>Valor total assinatura</Cards.Text>
                        </Cards.TopTextArea>
                      </Cards.Top>
                      <Cards.Bottom>
                        <Cards.Title>{moneyFormatter.format(dashboardSummaryExtratoPorUsina.valorTotalAssinatura)}</Cards.Title>
                      </Cards.Bottom>
                    </Cards.Card>
                  </C.StatusWrapperOrdemPagamento>
                  <C.StatusWrapperOrdemPagamento>
                    <Cards.Card>
                      <Cards.Top>
                        <Cards.TopTextArea>
                          <CC.Text>Total de contratos</CC.Text>
                        </Cards.TopTextArea>
                      </Cards.Top>
                      <Cards.Bottom>
                        <Cards.Title>{dashboardSummaryExtratoPorUsina.totalContratos}</Cards.Title>
                      </Cards.Bottom>
                    </Cards.Card>
                  </C.StatusWrapperOrdemPagamento>
                  <C.StatusWrapperOrdemPagamento>
                    <Cards.Card>
                      <Cards.Top>
                        <Cards.TopTextArea>
                          <CC.Text>Valor total de comissão</CC.Text>
                        </Cards.TopTextArea>
                      </Cards.Top>
                      <Cards.Bottom>
                        <Cards.Title>{moneyFormatter.format(dashboardSummaryExtratoPorUsina.valorTotalComissao)}</Cards.Title>
                      </Cards.Bottom>
                    </Cards.Card>
                  </C.StatusWrapperOrdemPagamento>
                  <C.StatusWrapperOrdemPagamento>
                    <Cards.Card>
                      <Cards.Top>
                        <Cards.TopTextArea>
                          <CC.Text>Ticket médio</CC.Text>
                        </Cards.TopTextArea>
                      </Cards.Top>
                      <Cards.Bottom>
                        <Cards.Title>{dashboardSummaryExtratoPorUsina.ticketMedio}</Cards.Title>
                      </Cards.Bottom>
                    </Cards.Card>
                  </C.StatusWrapperOrdemPagamento>
                </C.StatusArea>
              )}
              <ListView<EnumStatusContratoCliente>
                sectionName={"captadorAccessCaptador"}
                hasButtonSendClickSign={false}
                hasOtherStatus={true}
                hasThridValues={false}
                statusContratoBuilder={EnumStatusExtratoPorUsinaMap}
                data={statusContratoCliente}
                getFilterStatus={setFilterStatusContratoCliente}
                filterStatusContrato={filterStatusContratoCliente}
                hasSecondaryValues={false}
              >
                <D.DataArea $align="right">
                  <Datatable
                    hasButton={false}
                    columns={columns}
                    totalPages={totalPages}
                    rows={extratoPorUsina}
                    pageNumber={page}
                    pageSize={pageSize}
                    onChangePage={(e) => handleChangePage(e)}
                    onChangePageSize={(e) => handleChangePageSize(e)}
                    onSearch={handleSearch}
                    hasFilter={true}
                    loadData={loadData}
                    sectionName={sectionName}
                    statusLoading={statusLoading}
                  />
                  <C.LineBreak/>
                  <C.SelectArea>
                    <C.ReportsLabel>Selecione uma usina</C.ReportsLabel>
                    <Autocomplete
                      aria-label="Selecione uma usina"
                      sx={{
                        maxWidth: '30%',
                      }}
                      options={usinas}
                      fullWidth
                      noOptionsText="Nenhuma usina encontrado"
                      getOptionLabel={(option) => option.nome}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Usina"
                          inputProps={{
                            ...params.inputProps,
                            "aria-label": "Without label",
                          }}
                        />
                      )}
                      defaultValue={null}
                      onChange={(event, newValue) => {
                        handleInputChange({
                          target: {
                            name: ExtratoPorUsinaReportEnum.USINA,
                            value: newValue?.id as string
                          }
                        } as unknown as unknown as React.ChangeEvent<HTMLInputElement>)
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                    />
                  </C.SelectArea>
                  <C.ExportButton
                    onClick={geraRelatorio}
                    variant="contained"
                    startIcon={
                      <img
                        style={{width: "20px", color: "white", fill: "white"}}
                        src={ExcelIcon}
                        alt="excelIcon"
                      />
                    }
                  >
                    Gerar relatório
                  </C.ExportButton>
                </D.DataArea>
              </ListView>
            </C.Container>
          </>
        )
      }
    </>
  );
}

export default ExtratoPorUsina;