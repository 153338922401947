import {
  ICreateLead,
  IFullLead,
  ILead,
  ILeadsRelatoriosFilters,
  IUpdateLead,
  IVerifyUploadedBill,
} from "../../models/Lead";
import {IPaginatedList} from "../../models/PaginatedList";
import http from "../common/http-common";
import {AxiosResponse} from "axios";
import {EnumStatusLead} from "../../enums/EnumStatusLead.enum";
import {StatusDashboard} from "../../models/StatusDashboard";

export const GetLeadsStatus = () => {
  return http.get<StatusDashboard<EnumStatusLead>[]>("/GDLead/StatsDashboard");
};

export const GetLeads = (
  id: number | null,
  name: string | null | undefined,
  date: string | null | undefined,
  pageNumber: number | null,
  pageSize: number | null,
  search?: string,
  statusGDLeads?: EnumStatusLead | null,
) => {
  var searchString: string = "";
  let url = "/GDLead";
  const params = [];
  const formData = new FormData();

  if (id) params.push(`Id=${id}`);
  if (name) params.push(`Name=${name}`);
  if (date) params.push(`Date=${date}`);
  if (pageSize) params.push(`PageSize=${pageSize}`);
  if (pageNumber) params.push(`PageNumber=${pageNumber}`);
  if (search) params.push(`Search=${search}`);
  if (statusGDLeads !== null) params.push(`StatusGDLeads=${statusGDLeads}`);

  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  return http.get<IPaginatedList<ILead>>(url);
};

export const GetLeadById: (id: string) => Promise<AxiosResponse<IFullLead>> = (id: string) => {
  return http.get<IFullLead>(`/GDLead/${id}`);
};

export const CreateLead = async (updateLeadData: ICreateLead) => {
  const formData = new FormData();

  formData.append("Nome", updateLeadData.nome.toString());
  formData.append("Celular", updateLeadData.celular);

  if (updateLeadData.email != null) {
    formData.append("Email", updateLeadData.email);
  }
  if (updateLeadData.idCaptador != null) {
    formData.append("IdCaptador", updateLeadData.idCaptador);
  }
  if (updateLeadData.urlCaptador != null) {
    formData.append("UrlCaptador", updateLeadData.urlCaptador);
  }
  if (updateLeadData.valor != null) {
    formData.append("ValorMedioConta", updateLeadData.valor);
  }
  if (updateLeadData.conta != null) {
    formData.append("Conta", updateLeadData.conta);
  }

  return await http.post(`/GDLead`, formData);
};

export const UpdateLead = (updateLeadData: IUpdateLead) => {
  const formData = new FormData();

  formData.append("IdGDLead", updateLeadData.id.toString());
  if (updateLeadData.nome != null) {
    formData.append("Nome", updateLeadData.nome.toString());
  }
  if (updateLeadData.celular != null) {
    formData.append("Celular", updateLeadData.celular);
  }
  if (updateLeadData.email != null) {
    formData.append("Email", updateLeadData.email);
  }
  if (updateLeadData.valor != null) {
    formData.append("ValorMedioConta", updateLeadData.valor);
  }
  if (updateLeadData.captadorId != null) {
    formData.append("captadorId", updateLeadData.captadorId);
  }
  if (updateLeadData.status != null) {
    formData.append("Status", updateLeadData.status.toString());
  }

  return http.post(`/GDLead/Update`, formData);
};

export const AnexarConta = (idLead: string, conta: File) => {
  const formData = new FormData();

  formData.append("IdGDLead", idLead);
  formData.append("Conta", conta);

  return http.post(`/GDLead/AnexarConta`, formData);
};

export const VerifyUploadedBill = (id: string) => {
  return http.get<IVerifyUploadedBill>(`/GDLead/VerifyUploadedBill/${id}`);
};

export const GeraRelatorio = (filters: ILeadsRelatoriosFilters) => {
  const queryParams: string[] = [];

  if (filters.status !== undefined) {
    queryParams.push(`EnumStatusLead=${filters.status}`);
  }

  if (filters.captadorId) {
    queryParams.push(`CaptadorId=${filters.captadorId}`);
  }

  if (filters.backofficeId) {
    queryParams.push(`BackofficeId=${filters.backofficeId}`);
  }

  if (filters.dataInicio) {
    queryParams.push(`DataInicio=${filters.dataInicio}`);
  }

  if (filters.dataFim) {
    queryParams.push(`DataFim=${filters.dataFim}`);
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

  return http.get<File>(`/GDLead/BuscaRelatorioLeads${queryString}`, {
    responseType: "blob",
  });
};
