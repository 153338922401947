import { useNavigate, useParams } from "react-router-dom";
import * as C from "../style";
import StatusPropostaGerarProposta from "./StatusProposta";
import * as propostaService from "../../../../services/api/PropostaService";
import * as D from "../../../../styles/appComponents";
import { useEffect, useState } from "react";
import {
  IConexaoContaProposta,
  IContaCooperativaProposta,
  IContaDistribuidoraProposta,
  IDadosPessoaisEditarProposta, IFullGDProposta,
  IGetCalculoResponse,
  IResumoFaturaProposta,
  IUpdateGDProposta,
} from "../../../../models/GDProposta";
import {AxiosError, AxiosResponse} from "axios";
import Swal from "sweetalert2";
import { EnumTipoConexaoValueMap } from "../../../../enums/EnumTipoConexao.enum";
import ConexaoContaEditarProposta from "./ConexaoConta";
import ContaCooperativaGerarProposta from "../../Leads/GerarProposta/ContaCooperativa";
import ContaDistribuidoraGerarProposta from "../../Leads/GerarProposta/ContaDistribuidora";
import ResumoFaturaGerarProposta from "../../Leads/GerarProposta/ResumoFatura";
import DadosPessoaisEditarProposta from "./DadosPessoais";
import * as services from "../../../../services/api/PropostaService";
import { SkeletonCreationCards } from "../../../../components/SkeletonLoads/CreationCards";
import {toastMessage} from "../../../../utils/toastMessage";
import {DadosPessoaisValidate} from "../Validation/DadosPessoais/DadosPessoaisValidate";
import {ErrorMessage} from "../../../../utils/AbstractValidation";
import {ConexaoContaValidate} from "../Validation/ConexaoConta/ConexaoContaValidate";
import {undefined} from "zod";

const EditarProposta = () => {
  //region Variáveis
  const { idProposta } = useParams();
  const navigate = useNavigate();
  const [fieldErros, setFieldErros] = useState<ErrorMessage[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<number | null>(null);
  const [initialStatus, setInitialStatus] = useState<number>(1);
  const [dadosPessoaisData, setDadosPessoaisData] = useState<IDadosPessoaisEditarProposta>({
    backofficeNome: "",
    captadorNome: "",
    captadorId: "",
      nome: "",
      cpf: "",
      celular: "",
      email: "",
      cooperativaId: "",
      leadId: ""
    });
  const [initialData, setInitialData] = useState<IDadosPessoaisEditarProposta>({
    backofficeNome: "",
    captadorNome: "",
    nome: "",
    cpf: "",
    celular: "",
    email: "",
    cooperativaId: "",
    captadorId: "",
    leadId: "",
  });
  const [conexaoContaData, setConexaoContaData] = useState<IConexaoContaProposta>({
      classe: "",
      conexao: "",
      consumoMensalkWh: "",
      iluminacaoPublica: "",
      numeroInstalacao: "",
      distribuidoraId: "",
      tarifaDistribuidora: "",
      valorConta: "",
      valorDesconto: "",
      tributosPisCofins: "",
    });
  const [conexaoContaInitialData, setConexaoContaInitialData] = useState<IConexaoContaProposta>({
      classe: "",
      conexao: "",
      consumoMensalkWh: "",
      iluminacaoPublica: "",
      numeroInstalacao: "",
      distribuidoraId: "",
      tarifaDistribuidora: "",
      valorConta: "",
      valorDesconto: "",
      tributosPisCofins: "",
    });
  const [contaCooperativaData, setContaCooperativaData] = useState<IContaCooperativaProposta>({
      energiaInjetadakWh: "",
      energiaInjetadaReais: "",
      valorCooperativa: "",
    });
  const [contaDistribuidoraData, setContaDistribuidoraData] = useState<IContaDistribuidoraProposta>({
      impostoTotalDistribuidora: "",
      taxaDisponibilidadekWh: "",
      taxaDisponibilidadeReais: "",
      totalContaDistribuidora: "",
    });
  const [resumoFaturaData, setResumoFaturaData] = useState<IResumoFaturaProposta>({
      contasEconomizadasAnualmente: "",
      economiaAnual: "",
      economiaMensalBruta: "",
      economiaMensalLiquida: "",
      tarifaCooperativa: "",
      valorTotal: "",
    });
  //endregion

  //region Services
  useEffect(() => {
    services
      .GetFullPropostaById(idProposta!)
      .then(async (response: AxiosResponse<IFullGDProposta>) => {
        setInitialData(response.data);
        setConexaoConta(response.data);
        setContaCooperativa(response.data);
        setContaDistribuidora(response.data);
        setResumoFatura(response.data);
        setInitialStatus(response.data.status!);
        setLoading(false);
      })
      .catch((e: AxiosError) => {
        toastMessage("warning", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.");
      });
  }, []);

  const editaProposta = () => {
    const validatorDadosPessoais: ErrorMessage[] | null | undefined = new DadosPessoaisValidate().errorMessage(dadosPessoaisData);
    const validatorConexaoConta: ErrorMessage[] | null | undefined = new ConexaoContaValidate().errorMessage(conexaoContaData);

    if(validatorDadosPessoais) {
      setFieldErros(validatorDadosPessoais);

      validatorDadosPessoais.forEach((error: ErrorMessage) => {
        toastMessage("warning", error?.message);
      });
      return;
    }

    if(validatorConexaoConta) {
      setFieldErros(validatorConexaoConta);

      validatorConexaoConta.forEach((error: ErrorMessage) => {
        toastMessage("warning", error?.message);
      });
      return;
    }

    propostaService
      .UpdateGDProposta(createUpdatePropostaPropostaAsync())
      .then(async () => {
        navigate("/Parceiro/Propostas");
        toastMessage("success", "Editado com sucesso!");
      })
      .catch((e: AxiosError) => {
        toastMessage("warning", e.response
          ? String(e.response?.data)
          : "Houve um erro ao editar a proposta.");
      })
  };
  //endregion

  //region UI
  const setConexaoConta = (data: IFullGDProposta) => {
    setConexaoContaInitialData({
      classe: data.classe ?? "",
      conexao: data.conexao ?? "",
      consumoMensalkWh: data.consumoMensalkWh ?? "",
      iluminacaoPublica: data.iluminacaoPublica ?? "",
      numeroInstalacao: data.numeroInstalacao ?? "",
      distribuidoraId: data.distribuidoraId ?? "",
      tarifaDistribuidora: data.tarifaDistribuidora ?? "",
      valorConta: data.valorConta ?? "",
      valorDesconto: data.valorDesconto ?? "",
      tributosPisCofins: data.tributosPisCofins ?? "",
    });
  };

  const setContaCooperativa = (data: IFullGDProposta) => {
    setContaCooperativaData({
      energiaInjetadakWh: data.energiaInjetadakWh ?? "",
      energiaInjetadaReais: data.energiaInjetadaReais ?? "",
      valorCooperativa: data.valorCooperativa ?? "",
    });
  };

  const setContaDistribuidora = (data: IFullGDProposta) => {
    setContaDistribuidoraData({
      impostoTotalDistribuidora: data.impostoTotalDistribuidora ?? "",
      taxaDisponibilidadekWh: data.taxaDisponibilidadekWh ?? "",
      taxaDisponibilidadeReais: data.taxaDisponibilidadeReais ?? "",
      totalContaDistribuidora: data.totalContaDistribuidora ?? "",
    });
  };

  const setResumoFatura = (data: IFullGDProposta) => {
    setResumoFaturaData({
      contasEconomizadasAnualmente: data.contasEconomizadasAnualmente ?? "",
      economiaAnual: data.economiaAnual ?? "",
      economiaMensalBruta: data.economiaMensalBruta ?? "",
      economiaMensalLiquida: data.economiaMensalLiquida ?? "",
      tarifaCooperativa: data.tarifaCooperativa ?? "",
      valorTotal: data.valorTotal ?? "",
    });
  };

  const getCalculoResponse = (data: IGetCalculoResponse) => {
    setContaCooperativaData({
      energiaInjetadakWh: data.kwhInjetado.toString(),
      energiaInjetadaReais: data.energiaInjetada.toString(),
      valorCooperativa: data.valorContaCooperativa.toString(),
    });
    setContaDistribuidoraData({
      impostoTotalDistribuidora: data.valorImpostosDistribuidora.toString(),
      taxaDisponibilidadekWh:
        EnumTipoConexaoValueMap[
          Number(
            conexaoContaData.conexao
          ) as keyof typeof EnumTipoConexaoValueMap
          ],
      taxaDisponibilidadeReais: data.valorTaxaDisponibilidade.toString(),
      totalContaDistribuidora: data.valorContaDistribuidora.toString(),
    });
    setResumoFaturaData({
      contasEconomizadasAnualmente: data.contasEconomizadas.toString(),
      economiaAnual: data.economiaAnual.toString(),
      economiaMensalBruta: data.economiaMensalBruta.toString(),
      economiaMensalLiquida: data.economiaMensalLiquida.toString(),
      tarifaCooperativa: data.tarifaUsina.toString(),
      valorTotal: data.valorTotalFatura.toString(),
    });
  };

  const receiveDataDadosPessoais = (data: IDadosPessoaisEditarProposta) => {
    setDadosPessoaisData(data);
  };

  const receiveDataConexaoConta = (data: IConexaoContaProposta) => {
    setConexaoContaData(data);
  };

  const createUpdatePropostaPropostaAsync: () => IUpdateGDProposta = () => {
    return {
      ...conexaoContaData,
      ...contaCooperativaData,
      ...contaDistribuidoraData,
      ...resumoFaturaData,
      ...dadosPessoaisData,
      id: idProposta ?? null,
      status: status,
      captadorId: initialData.captadorId
    } as IUpdateGDProposta;
  };

  const receiveDataStatus = (data: number) => {
    setStatus(data);
  };
  //endregion

  return loading ? (
    <SkeletonCreationCards />
  ) : (
    <C.Container>
      <DadosPessoaisEditarProposta
        initialData={initialData}
        onSendData={receiveDataDadosPessoais}
      />
      <ConexaoContaEditarProposta
        initialData={conexaoContaInitialData}
        data={conexaoContaData}
        onSendData={receiveDataConexaoConta}
        sendCalculoProposta={getCalculoResponse}
      />
      <ContaCooperativaGerarProposta data={contaCooperativaData} />
      <ContaDistribuidoraGerarProposta
        data={contaDistribuidoraData}
        iluPub={conexaoContaData.iluminacaoPublica!.toString()}
      />
      <ResumoFaturaGerarProposta data={resumoFaturaData} />
      <StatusPropostaGerarProposta
        initialStatus={initialStatus}
        onSendData={receiveDataStatus}
      />
      <D.FWStack justifyContent={"flex-end"} direction={"row"} spacing={2}>
        <D.ContainedButton $color="lightpink" onClick={editaProposta}>
          Salvar proposta
        </D.ContainedButton>
      </D.FWStack>
    </C.Container>
  );
};

export default EditarProposta;
