import { AxiosError } from "axios";
import { toastMessage } from "./toastMessage";
import { ZodError, ZodIssue } from "zod";

interface ErrorDetalhes {
  field: string;
  error: string;
}

const isErrorDetalhes = (obj: any): obj is ErrorDetalhes =>
  typeof obj === "object" &&
  obj !== null &&
  typeof obj.field === "string" &&
  typeof obj.error === "string";

const validarVetorDeErros = (vetor: unknown): vetor is ErrorDetalhes[] =>
  Array.isArray(vetor) && vetor.every(isErrorDetalhes);

export const handleError = (
  error: unknown,
  message?: string,
  exibirLog: boolean = true
) => {
  let messageErro: string | null = null;
  let descricao: string | undefined = undefined;

  if (error instanceof AxiosError) {
    messageErro = `Erro de rede: ${error.message}`;
    const erros = error.response?.data?.errors;
    if (validarVetorDeErros(erros)) {
      messageErro = "Erro ao criar o contrato do parceiro";
      descricao = erros
        .filter((_, index) => index < 4)
        .map((e) => e.error)
        .join(" ");
    }
  } else if (error instanceof ZodError) {
    messageErro =
      "Erro na validação dos dados:\n" +
      error.errors
        .map(
          ({ path, message }: ZodIssue) =>
            `Campo: ${path.join(".")}, Erro: ${message}`
        )
        .join("\n");
  } else if (error instanceof Error) {
    messageErro = `Erro: ${error.message}`;
  } else {
    messageErro = "Ocorreu um erro desconhecido.";
  }

  if (exibirLog && messageErro) console.error(messageErro);

  const toastMsg = message || messageErro;
  if (toastMsg) toastMessage("error", toastMsg, descricao);
};
