import {StatusBuilder} from "../models/StatusBuilder";
import {ColorType} from "../types/ColorType";

export enum EnumStatusCargo {
  INATIVO = 0,
  ATIVO = 1,
}

export enum EnumStatusCargoMapSelect {
  ATIVO = "Ativo",
  INATIVO = "Inativo",
}

export const EnumStatusCargoMap: StatusBuilder[] = [
  {
    name: "Ativos",
    value: EnumStatusCargo.ATIVO,
    color: "green",
    primary: true
  },
  {
    name: "Inativos",
    value: EnumStatusCargo.INATIVO,
    color: "red",
    primary: true
  },
];

export const EnumStatusCargoColorMap: Record<number, ColorType> = {
  0: "red",
  1: "green",
};
