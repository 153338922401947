import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Autocomplete, MenuItem, TextField, Tooltip } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { AxiosError } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContractIcon from "../../../assets/Plataforma/contractIcon.svg";
import ExcelIcon from "../../../assets/Plataforma/excelIcon.svg";
import PourposeIcon from "../../../assets/Plataforma/pourposeIcon.svg";
import Datatable from "../../../components/Datatable";
import { ListView } from "../../../components/ListView";
import SkeletonDefaultPage from "../../../components/SkeletonLoads/DefaultPage";
import {
  EnumStatusGDProposta,
  EnumStatusGDPropostaMapSelect,
  EnumStatusGDPropostaNumericMap,
  EnumStatusPropostaMap,
  StatusPropostaColorMap,
  StatusPropostaNumberMap,
} from "../../../enums/EnumStatusGDProposta.enum";
import { useAuth } from "../../../hooks/useAuth";
import { ICaptadorListItem } from "../../../models/Captador";
import { IBackofficeListItem } from "../../../models/Colaborador/Colaborador";
import {
  IGDProposta,
  IGDPropostaPaged,
  IGDPropostaRelatoriosFilters,
} from "../../../models/GDProposta";
import { StatusDashboard } from "../../../models/StatusDashboard";
import * as captadorServices from "../../../services/api/CaptadorService";
import * as colaboradorServices from "../../../services/api/ColaboradorService";
import * as services from "../../../services/api/PropostaService";
import * as D from "../../../styles/appComponents";
import { PessoaType } from "../../../types/PessoaType";
import { toastMessage } from "../../../utils/toastMessage";
import ModalContratar from "./Modais/contratar";
import PropostasModal from "./Modais/PropostasModal";
import * as C from "./style";
import { InputFilterModel } from "../../../models/InputFilterModel";

const Parceiro: React.FC = () => {
  //region Variáveis
  const { user } = useAuth();
  const navigate = useNavigate();
  const [statusGDPropostas, setstatusGDPropostas] = useState<
    StatusDashboard<EnumStatusGDProposta>[]
  >([]);
  const [filterStatusGDPropostas, setFilterStatusGDPropostas] = useState<
    EnumStatusGDProposta | null | undefined
  >(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [statusOpen, setStatusOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [openModalContratar, setOpenModalContratar] = useState<boolean>(false);
  const [uuidModalContratar, setUuidModalContratar] = useState<
    undefined | string
  >(undefined);
  const [openModalSeeMoreId, setOpenModalSeeMoreId] = useState<
    string | undefined
  >(undefined);
  const [selectedBackoffice, setSelectedBackoffice] = useState<
    string | undefined
  >();
  const [selectedCaptador, setSelectedCaptador] = useState<
    string | undefined
  >();
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>();
  const [openProposta, setOpenProposta] = useState<boolean>(false);
  const [dataInicio, setDataInicio] = useState<string | undefined>();
  const [dataFim, setDataFim] = useState<string | undefined>();
  const [backoffices, setBackoffices] = useState<IBackofficeListItem[]>([]);
  const [captadores, setCaptadores] = useState<ICaptadorListItem[]>([]);
  const [rows, setRows] = useState<IGDPropostaPaged[]>([]);
  const [statusLoading, setStatusLoading] = useState<{sectionName:string, loading:boolean}>({ sectionName:"captadorAccessProposta", loading:false })
  const [modelFilter, setModelFilter] = useState<InputFilterModel | null>(null);
  const columns: GridColDef[] = [
    {
      field: "idReferencial",
      headerName: "Id",
      description: "id",
      renderCell: (params: GridCellParams<IGDProposta>) => {
        return (
          <D.GridField
            onClick={() =>
              user?.idCaptador
                ? navigate(`/Parceiro/Editar/Proposta/${params.row.id}`)
                : navigate(`/Colaborador/Editar/Proposta/${params.row.id}`)
            }
          >
            <D.LinkClick
              style={{
                whiteSpace: "normal",
                fontSize: 14,
              }}
            >
              {params.row.idReferencial}
            </D.LinkClick>
          </D.GridField>
        );
      },
    },
    {
      field: "cliente",
      headerName: "Cliente",
      description: "name",
      flex: 1,
      align: "center",
      renderCell: (params: any) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "instalacao",
      headerName: "Instalação",
      description: "instalacao",
      flex: 1,
      renderCell: (params: any) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "parceiro",
      headerName: "Parceiro",
      flex: 1,
      align: "center",
      renderCell: (params: any) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => (
        <D.GridField $alignCenter>
          <Tooltip title={StatusPropostaNumberMap[params.value]}>
            <D.Circle color={StatusPropostaColorMap[params.value]} />
          </Tooltip>
        </D.GridField>
      ),
    },
    {
      field: "proposta",
      headerName: "Proposta",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => {
        return (
          <D.GridField $alignCenter>
            <D.GridImage
              $disable={!params.row.temProposta}
              onClick={(): void =>
                handleOpenViewProposta(params.row.id, !params.row.temProposta)
              }
              src={PourposeIcon}
              alt="pourposeIcon"
            />
          </D.GridField>
        );
      },
    },
    {
      field: "contratar",
      headerName: "Contratar",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridCellParams<IGDPropostaPaged>) => (
        <D.GridField $alignCenter>
          <D.GridImage
            $disable={
              params.row.status !=
              EnumStatusGDPropostaNumericMap[EnumStatusGDProposta.aprovada]
            }
            src={ContractIcon}
            alt="contractIcon"
            onClick={(): void => {
              if (
                params.row.status ==
                EnumStatusGDPropostaNumericMap[EnumStatusGDProposta.aprovada]
              ) {
                OpenModal(params.row.id);
              }
            }}
          />
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: any) => (
        <D.GridField $alignCenter>
          <D.DefaultGridButton
            onClick={() => handleOpenModalSeeMore(params.row.id)}
          >
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];
  //endregion

  //region Services
  const getBackoffices: () => void = () => {
    colaboradorServices
      .GetBackoffices()
      .then((response: any) => {
        setBackoffices(response.data);
      })
      .catch((e: AxiosError) => {
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os backoffices."
        );
      });
  };

  const getCaptadores: () => void = () => {
    captadorServices
      .GetCaptadores()
      .then((response: any) => {
        setCaptadores(response.data);
      })
      .catch((e: AxiosError) => {
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os captadores."
        );
      });
  };

  const geraRelatorio: () => void = () => {
    var filters: IGDPropostaRelatoriosFilters = {
      status: selectedStatus,
      backofficeId: selectedBackoffice,
      captadorId: selectedCaptador,
      dataInicio: dataInicio,
      dataFim: dataFim,
    };
    services
      .GeraRelatorio(filters)
      .then(async (response: any) => {
        const blob = response.data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `propostas_${new Date()
          .toISOString()
          .slice(0, 19)
          .replace(/[-:T]/g, "")}.xlsx`;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((e: AxiosError) => {
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar o relatório."
        );
      });
  };

  const getStats: () => void = () => {
    services
      .GetStats()
      .then((response) => {
        setstatusGDPropostas(response.data);
      })
      .catch((e: AxiosError) => {
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os status."
        );
      });
  };

  const loadData = async (model:InputFilterModel | null, type:"clear"|"filter") => {
    setStatusLoading({ sectionName:"captadorAccessProposta", loading:true });
    await getStats();

    services
      .GetPropostas(
        (model?.id? parseInt(model?.id): null),
        model?.instalacao,
        model?.name,
        page, 
        pageSize, 
        search, 
        filterStatusGDPropostas ?? null)
      .then((response: any) => {
        setStatusLoading({ sectionName:"captadorAccessProposta", loading:false });
        setRows(response.data.data);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((e: AxiosError) => {
        setStatusLoading({ sectionName:"captadorAccessProposta", loading:false });
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar as propostas."
        );
      });
  }

  const getPropostas: () => Promise<void> = async () => {
    setStatusLoading({ sectionName:"captadorAccessProposta", loading:true });
    await getStats();

    services
      .GetPropostas(
        (modelFilter?.id? parseInt(modelFilter?.id): null),
        modelFilter?.instalacao,
        modelFilter?.name,
        page, 
        pageSize, 
        search, 
        filterStatusGDPropostas ?? null)
      .then((response: any) => {
        setStatusLoading({ sectionName:"captadorAccessProposta", loading:false });
        setRows(response.data.data);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch((e: AxiosError) => {
        setStatusLoading({ sectionName:"captadorAccessProposta", loading:false });
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar as propostas."
        );
      });
  };
  //endregion

  //region UI
  const callbackPropostasModal = () => {
    setOpenProposta(false);
  };

  const handleSearch: (value: string) => Promise<void> = async (
    value: string
  ) => {
    await setPage(1);
    await setSearch(value);
  };

  const handleChangePage: (value: number) => void = (value: number) => {
    setPage(value);
  };

  const handleChangePageSize: (value: number) => void = (value: number) => {
    setPageSize(value);
  };

  const handleCloseModalContratar: () => void = () => {
    setOpenModalContratar(false);
  };

  const handleStatusOpen: () => void = () => {
    setStatusOpen(!statusOpen);
  };

  const handleOpenModalSeeMore: (id: string) => Promise<void> = async (
    id: string
  ) => {
    setOpenModalSeeMoreId(id);
    setOpenProposta(true);
  };

  const callbackModalContratar = async (type: PessoaType) => {
    if (!uuidModalContratar) {
      toastMessage("error", "Id não encontrado.");
      return;
    }

    user?.idCaptador
      ? navigate(
          `/Parceiro/Novo/Contrato/Contratar/${type}/${uuidModalContratar}`
        )
      : navigate(
          `/Colaborador/Novo/Contrato/Contratar/${type}/${uuidModalContratar}`
        );
  };

  const handleOpenViewProposta: (id: string, disabled: boolean) => void = (
    id: string,
    disabled: boolean
  ): void => {
    if (disabled) return;

    if (user?.idCaptador)
      window.open("/Parceiro/Documento/Proposta/" + id, "_blank");
    else window.open("/Colaborador/Documento/Proposta/" + id, "_blank");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getBackoffices();
    getCaptadores();
  }, []);

  useEffect(() => {
    getPropostas();
  }, [page, pageSize, search]);

  useEffect(() => {
    getPropostas();
  }, [filterStatusGDPropostas]);

  const captadorAccessProposta = useMemo<string | null>(
    () => user?.idCaptador ?? null,
    [user]
  );

  const sectionNameListViewProposta: string = useMemo(
    () => (!captadorAccessProposta ? "gerador" : "captadorAccess"),
    [openModalSeeMoreId]
  );

  const OpenModal = async (id: string) => {
    setUuidModalContratar(id);
    setOpenModalContratar(true);
  };
  //endregion

  return (
    <>
      <ModalContratar
        uuidModalContratar={uuidModalContratar!}
        handleCloseModalContratar={handleCloseModalContratar}
        openModalContratar={openModalContratar}
        callback={callbackModalContratar}
      />
      <PropostasModal
        id={openModalSeeMoreId}
        openPropostasModal={openProposta}
        callback={callbackPropostasModal}
        onSave={getPropostas}
      />
      {loading ? (
        <SkeletonDefaultPage />
      ) : (
        <C.Container>
          <C.Title onClick={() => handleStatusOpen()}>
            {isMobile && (
              <>
                {!statusOpen ? (
                  <KeyboardArrowDownRoundedIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </>
            )}
          </C.Title>
          <ListView<EnumStatusGDProposta>
            sectionName={"captadorAccessProposta"}
            hasButtonSendClickSign={false}
            hasOtherStatus={true}
            statusContratoBuilder={EnumStatusPropostaMap}
            data={statusGDPropostas}
            getFilterStatus={setFilterStatusGDPropostas}
            hasThridValues={true}
            filterStatusContrato={filterStatusGDPropostas}
            hasSecondaryValues={true}
          >
            <D.DataArea $align="right">
              <Datatable
                sectionName={"captadorAccessProposta"}
                hasButton={false}
                columns={columns}
                rows={rows}
                pageNumber={page}
                pageSize={pageSize}
                onChangePage={(e) => handleChangePage(e)}
                onChangePageSize={(e) => handleChangePageSize(e)}
                totalPages={totalPages}
                onSearch={handleSearch}
                loadData={loadData}
                hasFilter={true}
                statusLoading={statusLoading}
              />
              <C.LineBreak />
              <C.ReportsArea>
                <C.SelectArea>
                  <C.ReportsLabel>Status de propostas</C.ReportsLabel>
                  <C.ReportsSelect
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    defaultValue={""}
                    onChange={(e: any) =>
                      setSelectedStatus(e.target.value as string)
                    }
                  >
                    <MenuItem value="">
                      <D.DefaultColorText>Todos os status</D.DefaultColorText>
                    </MenuItem>
                    {Object.keys(EnumStatusGDPropostaMapSelect).map(
                      (key, index) => (
                        <MenuItem
                          key={key}
                          value={
                            EnumStatusGDPropostaNumericMap[
                              EnumStatusGDProposta[
                                key as keyof typeof EnumStatusGDProposta
                              ]
                            ]
                          }
                        >
                          {
                            EnumStatusGDPropostaMapSelect[
                              key as keyof typeof EnumStatusGDPropostaMapSelect
                            ]
                          }
                        </MenuItem>
                      )
                    )}
                  </C.ReportsSelect>
                </C.SelectArea>
                <C.SelectArea>
                  <C.ReportsLabel>Selecione um captador</C.ReportsLabel>
                  <Autocomplete
                    options={captadores}
                    fullWidth
                    noOptionsText="Nenhum captador encontrado"
                    getOptionLabel={(option: any) => option.nome}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder="Todos os captadores"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Without label",
                        }}
                      />
                    )}
                    defaultValue={null}
                    onChange={(event: any, newValue: any) => {
                      setSelectedCaptador(newValue?.id);
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                  />
                </C.SelectArea>
                <C.SelectArea>
                  <C.ReportsLabel>Selecione o backoffice</C.ReportsLabel>
                  <Autocomplete
                    options={backoffices}
                    fullWidth
                    noOptionsText="Nenhum backoffice encontrado"
                    getOptionLabel={(option: any) => option.nome}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder="Todos os backoffices"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Without label",
                        }}
                      />
                    )}
                    defaultValue={null}
                    onChange={(event: any, newValue: any) => {
                      setSelectedBackoffice(newValue?.id);
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                  />
                </C.SelectArea>
                <C.SelectArea>
                  <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
                  <D.DateTextfield
                    type="date"
                    label="Data início"
                    fullWidth
                    value={dataInicio}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setDataInicio(e.target.value)
                    }
                  />
                </C.SelectArea>
                <C.SelectArea>
                  <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
                  <D.DateTextfield
                    type="date"
                    label="Data fim"
                    fullWidth
                    value={dataFim}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setDataFim(e.target.value)
                    }
                  />
                </C.SelectArea>
              </C.ReportsArea>
              <C.ExportButton
                onClick={geraRelatorio}
                variant="contained"
                startIcon={
                  <img
                    style={{ width: "20px", color: "white", fill: "white" }}
                    src={ExcelIcon}
                    alt="excelIcon"
                  />
                }
              >
                Gerar relatório
              </C.ExportButton>
            </D.DataArea>
          </ListView>
        </C.Container>
      )}
    </>
  );
};

export default Parceiro;
