import React, {useEffect, useState} from 'react';
import {CircularProgress, Modal, Stack} from "@mui/material";
import * as C from "../../style";
import CloseIcon from "@mui/icons-material/Close";
import * as D from "../../../../../styles/appComponents";
import {ICreateLead} from "../../../../../models/Lead";
import * as services from "../../../../../services/api/LeadService";
import {AxiosError} from "axios";
import {useAuth} from "../../../../../hooks/useAuth";
import {toastMessage} from "../../../../../utils/toastMessage";
import MaskDefaultInput from "../../../../../components/MasDefaultinput/MaskDefaultInput";

type NewLeadModalProps = {
  openNewLead: boolean,
  callback?: () => void,
  onClose?: VoidFunction,
}

enum CreateLeadEnum {
  NOME = "nome",
  CELULAR = "celular",
  EMAIL = "email",
}

const NewLeadModal: (props: NewLeadModalProps) => React.JSX.Element = (props: NewLeadModalProps): React.JSX.Element => {
  //region Variáveis
  let {openNewLead, callback, onClose} = props;
  const {user} = useAuth();
  const [loadingCreateLead, setLoadingCreateLead] = useState(false);
  const [dataForm, setDataForm] = useState<ICreateLead>({
    idCaptador: user?.idCaptador!,
    urlCaptador: null,
    nome: "",
    celular: "",
    email: null,
    valor: null,
    conta: null,
  })
  //endregion

  //region Services
  const createLead: (e: React.FormEvent<HTMLFormElement>) => Promise<void> = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoadingCreateLead(true);

    if (!dataForm.nome || !dataForm.celular) {
      toastMessage("error", "Nome e telefone são obrigatórios.");
      setLoadingCreateLead(false);
      return;
    }

    try {
      await services.CreateLead(dataForm)

      toastMessage("success", "Criado com sucesso");
      setLoadingCreateLead(false);

      if (callback) {
        callback();
      }

    } catch(e: unknown) {
      setLoadingCreateLead(false);
      toastMessage("error", "Houve um erro ao criar a lead.");
    }
  };
  //endregion

  //region UI
  const handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setDataForm((state: ICreateLead) => {
      const updatedState = {
        ...state,
        [name]: value,
      };
      return updatedState;
    });
  }

  const handleCloseNewLead = () => {
    setDataForm({
      idCaptador: user?.idCaptador!,
      urlCaptador: null,
      nome: "",
      celular: "",
      email: null,
      valor: null,
      conta: null,
    })
    openNewLead = false;
    if(callback)
      callback()
  }
  //endregion

  return (
    <Modal
      sx={{width: "100%", zIndex: 10}}
      open={openNewLead}
      aria-labelledby="modal-details"
      aria-describedby="modal-details"
    >
      <C.ModalContainer>
        <C.ModalArea style={{height: "auto"}}>
          <C.ModalCard onSubmit={createLead} data-testid="lead-form">
            <C.ModalHeader>
              <h2>Nova lead</h2>
              <C.CloseButton aria-label="close" onClick={handleCloseNewLead}>
                <CloseIcon/>
              </C.CloseButton>
            </C.ModalHeader>
            <C.ModalInputsArea
              style={{flexWrap: "nowrap"}}
            >
              <C.ModalInput
                label="Nome"
                placeholder="Nome"
                inputProps={{ maxLength: 100 }}
                name={CreateLeadEnum.NOME}
                value={dataForm.nome}
                onChange={handleInputChange}
                required
              />
              <MaskDefaultInput
                type={"CELULAR"}
                label={"Telefone"}
                value={dataForm.celular}
                onChange={(rawValue: string | Object) => {

                  const value =
                    typeof rawValue === "string"
                      ? rawValue
                      : (rawValue as { value?: string })?.value ?? "";

                  handleInputChange({
                    target: {
                      name: CreateLeadEnum.CELULAR,
                      value
                    }
                  } as React.ChangeEvent<HTMLInputElement>);
                }}
              />
            </C.ModalInputsArea>
            <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
              <D.ContainedButton
                variant="contained"
                $color={"lightpink"}
                onClick={handleCloseNewLead}
              >
                Cancelar
              </D.ContainedButton>
              <D.ContainedButton variant="contained" type="submit">
                {loadingCreateLead ? (
                  <CircularProgress size={20} color="secondary"/>
                ) : (
                  <>Salvar</>
                )}
              </D.ContainedButton>
            </Stack>
          </C.ModalCard>
        </C.ModalArea>
      </C.ModalContainer>
    </Modal>
  );
};

export default NewLeadModal;