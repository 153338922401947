import { EnumStatusContratoParceiro } from "../../../../enums/EnumStatusContratoParceiro.enum";
import { IAssociadoStats } from "../../../../models/Stats";
import { ColorType } from "../../../../types/ColorType";

type status = {
  id: EnumStatusContratoParceiro;
  cor: ColorType;
  texto: string;
};

export const listaStatus: status[] = [
  {
    id: EnumStatusContratoParceiro.lead,
    texto: "Leads",
    cor: "yellow",
  },
  {
    id: EnumStatusContratoParceiro.aguardandoAssinatura,
    texto: "Aguardando assinatura de contrato",
    cor: "blue",
  },
  {
    id: EnumStatusContratoParceiro.contratoAssinado,
    texto: "Contratao assinado",
    cor: "green",
  },
  {
    id: EnumStatusContratoParceiro.aguardandoAssinaturaDistrato,
    texto: "Aguardando assinatura de distrato",
    cor: "red",
  },
  {
    id: EnumStatusContratoParceiro.cancelado,
    texto: "Cancelado",
    cor: "purple",
  },
];
