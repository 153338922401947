import { IUpdateResponsavelLegal } from "../../models/ResponsavelLegal";
import { AbstractValidation, Validate } from "../../utils/AbstractValidation";
import {
  validarCelular,
  validarCPF,
  validarEmail,
  verificarMaiorDeIdade,
} from "../../utils/Utils";

export class UpdateResponsavelLegalValidate extends AbstractValidation<IUpdateResponsavelLegal> {
  getValidate(): Validate<IUpdateResponsavelLegal>[] {
    return [
      {
        field: "celular",
        validate(
          value: IUpdateResponsavelLegal["celular"],
          model,
          addError: (message: string) => void
        ) {
          if (model.nome.trim().length !== 0 && value.trim().length === 0) {
            addError("O campo é obrigatorio");
          }
          if (value.trim().length !== 0) {
            const validacao = validarCelular(model.countryCode, value);
            if (validacao.sucesso === false) addError(validacao.mensagem ?? "");
          }
        },
      },
      {
        field: "email",
        validate(
          value: IUpdateResponsavelLegal["email"],
          _model,
          addError: (message: string) => void
        ) {
          if (value.trim().length === 0) return;
          const validacao = validarEmail(value);
          if (validacao.sucesso === false) addError(validacao.mensagem ?? "");
        },
      },
      {
        field: "cpf",
        validate(
          value: IUpdateResponsavelLegal["cpf"],
          _model,
          addError: (message: string) => void
        ) {
          if (value.trim().length === 0) return;
          const validacao = validarCPF(value);
          if (validacao.sucesso === false) addError(validacao.mensagem ?? "");
        },
      },
      {
        field: "dataNascimento",
        validate(
          value: IUpdateResponsavelLegal["dataNascimento"],
          _model,
          addError: (message: string) => void
        ) {
          if (value === null) return;
          if (isNaN(value.getTime())) addError("Valor de data incorreto");
          if (!verificarMaiorDeIdade(value))
            addError("O responsável legal deve ser maior de idade.");
        },
      },
    ];
  }
}
