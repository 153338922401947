import React, {useEffect, useState} from 'react';
import {Autocomplete, CircularProgress, Modal, Stack, TextField} from "@mui/material";
import * as C from "../../style";
import CloseIcon from "@mui/icons-material/Close";
import * as D from "../../../../../styles/appComponents";
import * as cargoServices from "../../../../../services/api/CargoService";
import {toastMessage} from "../../../../../utils/toastMessage";
import {ModalCargoValidate} from "../../Validation/ModalCargo/ModalCargoValidate";
import {ErrorMessage} from "../../../../../utils/AbstractValidation";
import {cbos} from "../../../../../utils/cbos";
import CircleCheckbox from "../../../../../components/CircleCheckBox";
import {EnumStatusCargo} from "../../../../../enums/EnumStatusCargo";
import {AxiosError, AxiosResponse} from "axios";

type NewCargoModalProps = {
  id: string | undefined,
  openNewCargo: boolean,
  callback?: () => void,
  onClose?: VoidFunction,
}

enum CreateCargoEnum {
  CBO = "cbo",
  CARGO = "cargo",
  SETOR = "setor",
  STATUS = "status",
}

export interface ICreateCargo {
  id: string | null;
  cbo: string | null;
  cargo: string | null;
  setor: string | null;
  status: EnumStatusCargo | null;
}

const NewCargoModal: (props: NewCargoModalProps) => React.JSX.Element = (props: NewCargoModalProps): React.JSX.Element => {
  //region Variáveis
  let {openNewCargo, callback, id, onClose} = props;
  const [loadingCreateCargo, setLoadingCreateCargo] = useState<boolean>(false);
  const [_, setFieldErros] = useState<ErrorMessage[] | null>(null);
  const EnumStatusCargoNumericMap = {
    [EnumStatusCargo.ATIVO]: 1,
    [EnumStatusCargo.INATIVO]: 0,
  };
  const [dataForm, setDataForm] = useState<ICreateCargo>({
    status: EnumStatusCargo.ATIVO,
    id: null,
    setor: "",
    cargo: "",
    cbo: ""
  })
  //endregion

  //region Services
  const handleCreateOrUpdateCargo: (e: React.FormEvent<HTMLFormElement>) => Promise<void> = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoadingCreateCargo(true);

    const validator: ErrorMessage[] | null | undefined = new ModalCargoValidate().errorMessage(dataForm);

    if (validator) {
      setFieldErros(validator);

      validator.forEach((error: ErrorMessage) => {
        toastMessage("warning", error?.message);
      });
      setLoadingCreateCargo(false);
      return;
    }

    try {
      !dataForm.id ? await cargoServices.CreateCargo(dataForm) : await cargoServices.UpdateCargo(dataForm);

      const type: string = !dataForm.id ? "criado" : "atualizado";
      toastMessage("success", type + " com sucesso");

      if (callback) {
        callback();
      }

    } catch (e: unknown) {
      const type: string = !dataForm.id ? "criado" : "atualizado";
      toastMessage("error", `Houve um erro ao ${type} a Cargo.`);
    } finally {
      setLoadingCreateCargo(false);
    }
  };

  useEffect(() => {
    if(!id) return;
    (async () => {
      try {
        const response: AxiosResponse<ICreateCargo> = await cargoServices.GetCargoById(id);
        setDataForm({...response.data, id: id});

      } catch (e) {
        const error = e as AxiosError;

        toastMessage("error", error.response
          ? String(error.response?.data)
          : "Houve um erro ao pegar os dados.");
      }
    })()
  }, [id]);
  //endregion

  //region UI
  const handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setDataForm((state: ICreateCargo) => {
      const updatedState = {
        ...state,
        [name]: value,
      };
      return updatedState;
    });
  }

  const handleCloseNewCargo = () => {
    setDataForm({
      status: EnumStatusCargo.INATIVO,
      id: "",
      cbo: "",
      cargo: "",
      setor: ""
    })
    openNewCargo = false;
    if (onClose)
      onClose()
  }
  //endregion

  return (
    <Modal
      sx={{width: "100%", zIndex: 10}}
      open={openNewCargo}
      onClose={onClose}
      aria-labelledby="modal-details"
      aria-describedby="modal-details"
    >
      <C.ModalContainer>
        <C.ModalArea style={{height: "auto"}}>
          <C.ModalCard onSubmit={handleCreateOrUpdateCargo} data-testid="Cargo-form">
            <C.ModalHeader>
              <h2>Novo Cargo</h2>
              <C.CloseButton aria-label="close" onClick={handleCloseNewCargo}>
                <CloseIcon/>
              </C.CloseButton>
            </C.ModalHeader>
            <C.ModalInputsArea>
            <CircleCheckbox
              id={EnumStatusCargoNumericMap[EnumStatusCargo.ATIVO]}
              label="Ativo"
              color="green"
              selected={dataForm.status == EnumStatusCargoNumericMap[EnumStatusCargo.ATIVO]}
              onSelect={function (id: number): void {
                handleInputChange({
                  target: {
                    name: CreateCargoEnum.STATUS,
                    value: 1
                  }
                } as unknown as React.ChangeEvent<HTMLInputElement>)
              }}/>
            <CircleCheckbox
              id={EnumStatusCargoNumericMap[EnumStatusCargo.INATIVO]}
              label="Inativo"
              color="red"
              selected={dataForm.status == EnumStatusCargoNumericMap[EnumStatusCargo.INATIVO]}
              onSelect={function (id: number): void {
                handleInputChange({
                  target: {
                    name: CreateCargoEnum.STATUS,
                    value: 0
                  }
                } as unknown as React.ChangeEvent<HTMLInputElement>)
              }}/>
            </C.ModalInputsArea>
            <C.ModalInputsArea>
              <Autocomplete
                value={cbos.find(x => x.cod === dataForm.cbo) ?? null}
                aria-label="Cargo"
                style={{
                  flex: 1,
                  flexBasis: "49%",
                }}
                options={cbos}
                fullWidth
                noOptionsText="Nenhum cargo encontrado"
                getOptionLabel={(option) => `${option.cod} ${option.desc}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Cargo"
                    inputProps={{
                      ...params.inputProps,
                      "aria-label": "Without label",
                    }}
                  />
                )}
                defaultValue={null}
                onChange={(event, newValue) => {
                  handleInputChange({
                    target: {
                      name: CreateCargoEnum.CBO,
                      value: newValue?.cod as string
                    }
                  } as unknown as unknown as React.ChangeEvent<HTMLInputElement>)
                  handleInputChange({
                    target: {
                      name: CreateCargoEnum.CARGO,
                      value: newValue?.desc as string
                    }
                  } as unknown as unknown as React.ChangeEvent<HTMLInputElement>)
                }}
                isOptionEqualToValue={(option, value) =>
                  option.cod === value.cod
                }
              />
              <C.ModalInput
                label="Setor"
                placeholder="setor"
                inputProps={{maxLength: 100}}
                name={CreateCargoEnum.SETOR}
                value={dataForm.setor}
                onChange={handleInputChange}
                required
              />
            </C.ModalInputsArea>
            <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
              <D.ContainedButton variant="contained" type="submit">
                {loadingCreateCargo ? (
                  <CircularProgress size={20} color="secondary"/>
                ) : (
                  <>Salvar</>
                )}
              </D.ContainedButton>
            </Stack>
          </C.ModalCard>
        </C.ModalArea>
      </C.ModalContainer>
    </Modal>
  );
};

export default NewCargoModal;