import { ICaptador } from "../models/Captador";
import { ICreateLog } from "../models/Log";

export const CREATE_LOG_DEFAULT: ICreateLog = {
  assunto: "",
  descricao: "",
  gdContratoClienteId: null,
  anexo: null,
  anexoName: "",
};

const LIMITE_DESCONTO_PADRAO = 25;

export const CREATE_CAPTADOR: ICaptador = {
  id: "",
  razaoSocial: "",
  cnpj: "",
  cep: "",
  uf: "",
  cidade: "",
  bairro: "",
  logradouro: "",
  numero: "",
  complemento: "",
  idReferencial: 0,
  responsaveisLegais: [],
  responsavelComunicacao: null,
  percentualComissao: null,
  comissaoPorExtenso: "",
  vigenciaContrato: null,
  banco: "",
  agencia: "",
  conta: "",
  cnpjFavorecido: "",
  favorecido: "",
  tipoChavePix: null,
  chavePix: "",
  enumConvenio: null,
  backofficeId: null,
  backofficeNome: "",
  statusContrato: null,
  user: "",
  userEmail: "",
  userCelular: "",
  userCountryCode: "",
  linkCaptador: "",
  ultimaAlteracaoStatus: null,
  dataAssinaturaContrato: null,
  uuidContrato: null,
  cupomBoasVindas: null,
  emissorNotaFiscal: null,
  isMaster: null,
  limiteDesconto: LIMITE_DESCONTO_PADRAO,
  parceiroMasterId: null,
  tipoParceiro: null,
};
