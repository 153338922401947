import { GridFilterInputValueProps, GridFilterOperator } from "@mui/x-data-grid";
import { CustomFilterInput } from "./CustomFilterInput";
import { InputFilterModel } from "../../models/InputFilterModel";

export const customFilterOperator: (sendForm: (model:InputFilterModel | null, type:"clear"|"filter") => void) => GridFilterOperator[] = (sendForm) => [
    {
        label: "Igua a",
        value: "contains",
        getApplyFilterFn: (_) => null,
        InputComponent: (props:GridFilterInputValueProps) => CustomFilterInput(props, sendForm),
    },
];

