import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';

dayjs.locale('pt-br');

export const MonthYearCalendar = ( 
  { 
    handleDateChange,
    dateSelected,
    readOnly
  }
  :
  { 
    handleDateChange:(date: Dayjs | null) => void,
    dateSelected: Dayjs | null;
    readOnly?: boolean;
  } 
) => {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          readOnly={readOnly}
          value={dateSelected}
          onChange={handleDateChange}
          views={['month', 'year']}
          sx={{
            width: '80%',
        }} />
    </LocalizationProvider>
  );
}