import { Grid, Typography } from "@mui/material";
import { ColorType } from "../../types/ColorType";
import * as C from "./style";

interface CircleCheckboxProps {
  id: number;
  selected: boolean;
  label: string;
  onSelect: (id: number) => void;
  color: ColorType;
  disabled?: boolean;
}

const CircleCheckbox: React.FC<CircleCheckboxProps> = ({
  id,
  selected,
  label,
  onSelect,
  color,
  disabled = false,
}) => {
  return (
    <C.Wrapper disabled={disabled} onClick={() => onSelect(id)}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <C.Circle color={color} disabled={disabled} selected={selected} />
        </Grid>
        <Grid item>
          <Typography color={disabled ? "text.disabled" : ""}>
            {label}
          </Typography>
        </Grid>
      </Grid>
    </C.Wrapper>
  );
};

export default CircleCheckbox;
