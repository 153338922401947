import React, { ReactNode, useEffect, useRef, useState } from "react";
import { InputAdornment, MenuItem, Stack, TextField } from "@mui/material";
import { InputTypes } from "../../types/InputType";
import { countryCodes } from "../../utils/masInputs";
import { handleTypeMasks } from "./handleTypeMasks";
import { useSessionStorage } from "../../hooks/useSessionStorage";

type MaskDefaultInputProps = {
  type: InputTypes;
  label: string;
  onChange?: (rawValue: string | Object) => void;
  value?: string | number;
  valueCountryCode?: string;
  sx?: any;
  readonly?: boolean;
  disabled?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  helperText?: ReactNode;
  error?: boolean;
  required?: boolean;
};

const MaskDefaultInput: React.FC<MaskDefaultInputProps> = ({
  type,
  label,
  onChange,
  value,
  sx,
  readonly,
  valueCountryCode,
  disabled,
  onBlur,
  onFocus,
  helperText,
  error,
  required,
}) => {
  const [valueInput, setValueInput] = useState<string>("");
  const [rawValue, setRawValue] = useState<string>("");
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>("+55");
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const typeMask = handleTypeMasks.find((x) => x.type === type);

  const handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue: string = e.target.value;
    let numericValue: string = inputValue.replace(/\D/g, "");

    if (type === "PORCENTAGEM") {
      numericValue = inputValue.replace(/[^0-9,]/g, "");
    }

    const maskedValue: string =
      typeMask?.applyMask(numericValue, valueCountryCode) ?? "";
    numericValue = typeMask?.newValue(numericValue);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      typeMask?.applyChanges(
        numericValue,
        onChange,
        selectedCountryCode,
        getViaCep
      );

      setRawValue(numericValue);
      setValueInput(maskedValue);
    }, 50);
  };

  const handleChangeSelectZipCode: (e: any) => void = (e: any) => {
    setSelectedCountryCode(e.target.value as string);

    onChange?.({
      value: rawValue,
      zipCode: e.target.value,
    });
  };

  const getViaCep: (cep: string) => Promise<void> = async (cep: string) => {
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((json) => json.json())
      .then((res) =>
        onChange?.({
          value: cep,
          ...res,
        })
      )
      .catch((x) =>
        onChange?.({
          value: cep,
        })
      );
  };

  useEffect(() => {
    if (value) {
      const valueString: string = typeof value != "string" ? `${value}` : value;

      const maskedValue: string =
        typeMask?.applyMask(valueString, valueCountryCode) ?? "";
      setValueInput(maskedValue);

      setRawValue(valueString.replace(/\D/g, ""));
    } else {
      setValueInput("");
    }
  }, [value]);

  useEffect(() => {
    if (value) {
      const valueString: string = typeof value != "string" ? `${value}` : value;
      const maskedValue: string =
        typeMask?.applyMask(valueString, valueCountryCode) ?? "";
      setValueInput(maskedValue);

      setRawValue(valueString.replace(/\D/g, ""));
    } else {
      setValueInput("");
    }
  }, [valueCountryCode]);

  return (
    <Stack direction={"row"} sx={sx ? sx : { width: "100%" }}>
      {type === "CELULAR" && (
        <TextField
          inputProps={{ readOnly: readonly }}
          value={valueCountryCode ?? "+55"}
          onChange={handleChangeSelectZipCode}
          sx={{
            minWidth: "110px",
            padding: 0,
            whiteSpace: "nowrap",
            "& .fbEKzQ": {
              borderBottomRightRadius: 0,
              borderTopRightRadius: 0,
            },
          }}
          select
          disabled={disabled}
          error={error}
          onBlur={onBlur}
          onFocus={onFocus}
        >
          {countryCodes.map((country) => (
            <MenuItem key={country?.code} value={country?.code}>
              <img src={country.img} width="20" />
              {country.code}
            </MenuItem>
          ))}
        </TextField>
      )}

      <TextField
        label={label}
        aria-label={label}
        value={valueInput}
        onChange={handleChange}
        onBlur={onBlur}
        onFocus={onFocus}
        helperText={helperText}
        error={error}
        required={required}
        fullWidth
        inputProps={{ readOnly: readonly }}
        disabled={disabled}
        InputProps={
          type === "REAL"
            ? {
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }
            : type === "PORCENTAGEM"
            ? {
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }
            : type === "KWH"
            ? {
                endAdornment: (
                  <InputAdornment position="end">kWh</InputAdornment>
                ),
              }
            : {}
        }
        sx={
          type === "CELULAR"
            ? {
                ...sx,
                "& .fbEKzQ": {
                  borderBottomLeftRadius: 0,
                  borderTopLeftRadius: 0,
                },
              }
            : sx
        }
      />
    </Stack>
  );
};

export default MaskDefaultInput;
