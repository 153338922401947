import * as React from 'react';
import { GridFilterInputValueProps } from '@mui/x-data-grid';
import { Box, Button, InputAdornment, TextField } from '@mui/material';
import { ChangeEvent } from 'react';
import { MaskCurrencyInput } from '../MakCurrencyInput';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import { InputFilterModel } from '../../models/InputFilterModel';
import MaskDefaultInput from '../MasDefaultinput/MaskDefaultInput';

export const CustomFilterInput = (props: GridFilterInputValueProps, sendForm: (model:InputFilterModel | null, type:"clear"|"filter") => void) => {
    const { item, applyValue, focusElementRef } = props;
    const description = (item as any)?.description;
    const value = item.value;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        applyValue({ ...item, value });
    };

    const handleChangeKwh = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        applyValue({ ...item, value });
    };

    const handleDateChange: (date: (Dayjs | null)) => void = (date: Dayjs | null): void => {
        applyValue({ ...item, value:date?.toISOString() });
    }

    const send = () => {
        const model = {
            id:"",
            name:"",
            date:"",
            kwh:"",
            percentageValue:""
        }
        sendForm({...model, [description]:value } as any, "filter");
    }

    const inputs = [
        {
            type: "kwh",
            input: () => (
                <MaskCurrencyInput
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            Kwh
                        </InputAdornment>
                    ),
                }}
                sx={{ width: "100%", border: "none" }}
                name="kwh"
                onValueChange={(newValue, name) => {
                    handleChangeKwh({ target: { name, value: newValue } } as unknown as ChangeEvent<HTMLInputElement>);
                }}
                value={item.value ?? ""}
                placeholder="Digite o valor"
                defaultValue={0}
                decimalsLimit={2}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            )
        },
        {
            type: "cpf",
            input: () => (
                <MaskDefaultInput
                    type="CPF"
                    label="CPF"
                    value={item.value ?? ""}
                    onChange={(value) =>
                            handleChange({
                            target: { name: "cpf", value },
                        } as React.ChangeEvent<HTMLInputElement>)
                    }
                />
            )
        },
        {
            type: "cnpj",
            input: () => (
                <MaskDefaultInput
                    type="CNPJ"
                    label="CNPJ"
                    value={item.value ?? ""}
                    onChange={(value) =>
                            handleChange({
                            target: { name: "cnpj", value },
                        } as React.ChangeEvent<HTMLInputElement>)
                    }
                />
            )
        },
        {
            type: "date",
            input: () => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        value={dayjs(item.value ? dayjs(item.value) : null)}
                        onChange={handleDateChange}
                        views={['month', 'year']}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                sx: {
                                    minWidth: "160px",
                                    maxWidth: "200px",
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            border: "none",
                                            borderBottom: "1px solid black"
                                        },
                                        "&:hover fieldset": {
                                            borderBottom: "2px solid black"
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderBottom: "2px solid blue"
                                        }
                                    }
                                }
                            }
                        }}
                    />
                </LocalizationProvider>
            )
        },
        {
            type: "default",
            input: () => (
                <TextField
                    inputRef={focusElementRef}
                    value={item.value || ''}
                    name={description}
                    onChange={handleChange}
                    placeholder="Digite um valor..."
                    variant="outlined"
                    size="small"
                    sx={{
                        margin: "10px", width:"100%",
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none',
                                borderBottom: '1px solid black',
                            },
                            '&:hover fieldset': {
                                borderBottom: '2px solid black'
                            },
                            '&.Mui-focused fieldset': {
                                borderBottom: '2px solid blue'
                            }
                        }
                    }}
                />
            )
        }
    ];

    return (
        <Box sx={{ display: 'flex', width: "100%", minWidth: "250px", alignItems: 'center', justifyContent: "space-between" }}>
            {
                inputs.find((x) => x.type === description)?.input() ?? 
                inputs.find((x) => x.type === "default")?.input()
            }
            <Button type="button" onClick={send} >Aplicar</Button>
        </Box>
    );
}