import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { Box, Checkbox, Stack, TextField } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import MaskDefaultInput from "../../../../../components/MasDefaultinput/MaskDefaultInput";
import RegisterCard from "../../../../../components/RegisterCard";
import { useObjectState } from "../../../../../hooks/useObjectState";
import { ICelularInputReturn } from "../../../../../models/Celular";
import {
  IPreferencias,
  IUpdatePreferencias,
} from "../../../../../models/GDContrato";
import * as service from "../../../../../services/api/GDContratoClienteService";
import * as D from "../../../../../styles/appComponents";
import { convertEmptyStringsToNullAsync } from "../../../../../utils/convertEmptyStringsToNull";
import * as G from "../../../Colaborador/Cadastro/style";
import { CheckboxGroup, ReportsArea } from "../../EditarContrato/style";

type PropsPreferenciaContratar = {
  id: string;
  dataContrato?: IPreferencias;
};

const PreferenciaContratar = (props: PropsPreferenciaContratar) => {
  //region Variáveis
  const { id, dataContrato } = props;
  const [editar, setEditar] = useState<boolean>(false);
  const { state, setObject, updateObject } =
    useObjectState<IUpdatePreferencias>({
      id: id,
      emails: [""],
      countryCode: "+55",
      numeroCelular: "",
      recebeTresDias: false,
      recebeSeteDias: false,
      recebeGeracao: false,
      recebeVencimento: false,
      preferenciaDiaRecebimento: false,
      possuiGeracaoEnergiaPropria: false,
      obedeceFranquia: false,
      preferenciaTitularidade: false,
    });
  //endregion

  //region Services
  const Update: () => Promise<void> = async () => {
    var data: IUpdatePreferencias = await convertEmptyStringsToNullAsync(state);

    await service
      .UpdatePreferencias(data)
      .then(() => {
        setEditar(false);
        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "success",
          title: "Atualizado com sucesso!",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      })
      .catch((e: AxiosError) => {
        var errorMessage: string = e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.";

        Swal.fire({
          position: "top-end",
          toast: true,
          icon: "warning",
          title: errorMessage,
          showConfirmButton: false,
          showCloseButton: true,
          timer: 3000,
        });
      });
  };
  //endregion

  //region UI
  const handleChangeCelular: (e: ICelularInputReturn) => Promise<void> = async (
    e: ICelularInputReturn
  ) => {
    updateObject({ numeroCelular: e.value });
    updateObject({ countryCode: e.zipCode });
  };
  const handleChange: (value: string, index: number) => void = (
    value: string,
    index: number
  ) => {
    const updatedEmails: string[] = [...state.emails];
    updatedEmails[index] = value;
    updateObject({ emails: updatedEmails });
  };

  const handleAddEmails: () => void = () => {
    updateObject({ emails: [...state.emails, ""] });
  };

  const handleRemoveEmails: () => void = () => {
    if (state.emails.length > 1) {
      updateObject({ emails: state.emails.slice(0, -1) });
    }
  };

  useEffect((): void => {
    if (dataContrato) {
      const mappedEmails: string[] | undefined = dataContrato.emails?.map(
        (item) => item.emailAddress
      );

      setObject({
        id: id,
        emails: mappedEmails ?? [""],
        recebeGeracao: dataContrato.recebeGeracao!,
        obedeceFranquia: dataContrato.obdeceFranquia!,
        possuiGeracaoEnergiaPropria: dataContrato.possuiGeracaoEnergiaPropria!,
        preferenciaDiaRecebimento: dataContrato.preferenciaDiaRecebimento!,
        preferenciaTitularidade: dataContrato.preferenciaTitularidade!,
        recebeSeteDias: dataContrato.recebeSeteDias!,
        recebeTresDias: dataContrato.recebeTresDias!,
        recebeVencimento: dataContrato.recebeVencimento!,
        countryCode: "+55",
        numeroCelular: dataContrato.whatsapp,
      });
    }
  }, [dataContrato]);
  //endregion

  return (
    <RegisterCard title="Preferência">
      <D.FWStack direction={"row"} spacing={2}>
        <MaskDefaultInput
          label="Whatsapp"
          value={state.numeroCelular}
          type="CELULAR"
          readonly={!editar}
          onChange={(e) => handleChangeCelular(e as ICelularInputReturn)}
        />

        <CheckboxGroup>
          <Box>
            <G.CheckboxWrapper>
              <Checkbox
                disabled={!editar}
                checked={state.recebeTresDias}
                onChange={() =>
                  updateObject({ recebeTresDias: !state.recebeTresDias })
                }
              />
              <p>3 dias</p>
            </G.CheckboxWrapper>
            <G.CheckboxWrapper>
              <Checkbox
                disabled={!editar}
                checked={state.recebeSeteDias}
                onChange={() =>
                  updateObject({ recebeSeteDias: !state.recebeSeteDias })
                }
              />
              <p>7 dias</p>
            </G.CheckboxWrapper>
          </Box>

          <Box>
            <G.CheckboxWrapper>
              <Checkbox
                disabled={!editar}
                checked={state.recebeGeracao}
                onChange={() =>
                  updateObject({ recebeGeracao: !state.recebeGeracao })
                }
              />
              <p>Geração</p>
            </G.CheckboxWrapper>
            <G.CheckboxWrapper>
              <Checkbox
                disabled={!editar}
                checked={state.recebeVencimento}
                onChange={() =>
                  updateObject({ recebeVencimento: !state.recebeVencimento })
                }
              />
              <p>Vencimento</p>
            </G.CheckboxWrapper>
          </Box>
        </CheckboxGroup>
      </D.FWStack>

      <D.FWStack direction={"row"} spacing={2} alignItems={"flex-end"}>
        <Stack sx={{ width: "50%" }} spacing={2} direction={"column"}>
          <p>Lista de email para recebimento de faturas</p>
          {(state.emails.length > 0 ? state.emails : [""]).map(
            (email, index) => (
              <TextField
                key={index} // Adicione uma chave única para cada item
                value={email}
                onChange={(e) => handleChange(e.target.value, index)} // Atualiza o estado ao digitar
                label={`Email ${index + 1}`}
                required
                fullWidth
                inputProps={{ readOnly: !editar }}
              />
            )
          )}
        </Stack>
        {state.emails.length > 1 && (
          <D.ContainedButton
            $color="blue"
            startIcon={<RemoveCircleOutlineOutlinedIcon />}
            onClick={() => handleRemoveEmails()}
            disabled={!editar}
          >
            Remover email
          </D.ContainedButton>
        )}
        <D.ContainedButton
          $color="lightpink"
          startIcon={<AddCircleOutlineOutlinedIcon />}
          onClick={() => handleAddEmails()}
          disabled={!editar}
        >
          Adicionar mais email
        </D.ContainedButton>
      </D.FWStack>

      <D.FWStack direction={"row"} spacing={2}>
        <ReportsArea>
          <Box>
            <G.CheckboxWrapper>
              <Checkbox
                disabled={!editar}
                checked={state.preferenciaDiaRecebimento}
                onChange={(e) =>
                  updateObject({
                    preferenciaDiaRecebimento: !state.preferenciaDiaRecebimento,
                  })
                }
              />
              <p>Preferência de dia de recebimento fatura?</p>
            </G.CheckboxWrapper>
          </Box>

          <Box>
            <G.CheckboxWrapper>
              <Checkbox
                disabled={!editar}
                checked={state.possuiGeracaoEnergiaPropria}
                onChange={(e) =>
                  updateObject({
                    possuiGeracaoEnergiaPropria:
                      !state.possuiGeracaoEnergiaPropria,
                  })
                }
              />
              <p>Possui geração de energia própria</p>
            </G.CheckboxWrapper>
          </Box>

          <Box>
            <G.CheckboxWrapper>
              <Checkbox
                disabled={!editar}
                checked={state.obedeceFranquia}
                onChange={(e) =>
                  updateObject({ obedeceFranquia: !state.obedeceFranquia })
                }
              />
              <p>Obedece a franquia</p>
            </G.CheckboxWrapper>
          </Box>

          <Box>
            <G.CheckboxWrapper>
              <Checkbox
                disabled={!editar}
                checked={state.preferenciaTitularidade}
                onChange={(e) =>
                  updateObject({
                    preferenciaTitularidade: !state.preferenciaTitularidade,
                  })
                }
              />
              <p>Preferência titularidade fatura</p>
            </G.CheckboxWrapper>
          </Box>
        </ReportsArea>
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
        <D.ContainedButton
          $color="yellow"
          disabled={editar}
          onClick={() => setEditar(true)}
        >
          Editar
        </D.ContainedButton>
        <D.ContainedButton disabled={!editar} onClick={Update}>
          Salvar
        </D.ContainedButton>
      </D.FWStack>
    </RegisterCard>
  );
};

export default PreferenciaContratar;
