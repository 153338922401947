import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CloseIcon from "@mui/icons-material/Close";
import {IconButton} from '@mui/material';
import {StatusBuilder} from '../../models/StatusBuilder';

interface TabPanelProps {
    value: number | null;
    index: number;
}

export interface TypeTap {
    name: string;
    value: number;
}

interface TabsComponentProps {
    getStatus: (value: number | null | undefined) => void;
    status: any;
    children?: React.ReactNode;
    enumStatusContratoMap: StatusBuilder[];
    sectionName: string;
}

const CustomTabPanel: React.FC<TabPanelProps> = ({ value, index, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        />
    );
};

const a11yProps = (index: number | null) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

export const TabsComponent: React.FC<TabsComponentProps> = (
    {
        getStatus,
        status,
        children,
        enumStatusContratoMap,
        sectionName
    }
) => {

    const handleChange = (event: React.SyntheticEvent, newValue: number | null | undefined) => {
        getStatus(newValue === undefined? null: newValue);
    };

    React.useEffect(() => {

        if (!verifyIfExistStatus(status) && status !== null) {
            addTab();
        }

    }, [status])

    const addTab = () => {
        setTabs((x: any) => {
            saveDataInCookie([...x, listStatus(status)] as any);
            return [...x, listStatus(status)]
        })
    };

    const getCookie = (name: string) => {
        const match = document.cookie.split('; ').find(row => row.startsWith(name + '='));
        return match ? decodeURIComponent(match.split('=')[1]) : null;
    };

    const getDataSavedInCookie = () => {
        const savedData = getCookie(`statusData${sectionName}`);

        if (savedData) {
            return JSON.parse(savedData);
            
        }else return [];
    }

    const verifyIfExistStatus = (status: any) => {
        return tabs.some((x: any) => x.value === status)
    }
        

    const listStatus = (value: number) => {
        return {
            name: enumStatusContratoMap?.find(x => x.value === value)?.name as any,
            value: enumStatusContratoMap?.find(x => x.value === value)?.value as any
        }
    }

    const saveDataInCookie = (data:TypeTap) => {
        document.cookie = `statusData${sectionName}=${encodeURIComponent(JSON.stringify(data))}; path=/;`;
    }

    const [tabs, setTabs] = React.useState<TypeTap[]>(getDataSavedInCookie())

    const removeStatus = (status: any, index: number) => {
        setTabs((x: any) => {
            saveDataInCookie(x.filter((e: any, i: number) => index !== i))
            return x.filter((e: any, i: number) => index !== i)
        })

        let lastTab;

        if (index === tabs?.length - 1)
            lastTab = null
        else
            lastTab = (tabs || []).find((_: any, i: number) => i === (index - 1))?.value

        handleChange({} as React.SyntheticEvent, lastTab);
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box>
                <Tabs
                    sx={{
                        background: "white",
                        borderTopRightRadius: "20px",
                        width: "fit-content",
                    }}
                    value={status}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    {(tabs || []).map((item: any, index: number) => (
                        <Tab
                            sx={{
                                background: "white",
                                borderRight: "1px solid black",
                                paddingX: 2,
                            }}
                            key={item?.value}
                            value={item?.value}
                            label={
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    {item?.name}
                                    {index !== tabs.length && (
                                        <IconButton
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                removeStatus(item?.value, index);
                                            }}
                                            aria-label="remove"
                                            size="small"
                                            sx={{ padding: 0 }}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                </Box>
                            }
                            {...a11yProps(item?.value)}
                        />
                    ))}
                        <Tab
                            sx={{
                                background: "white",
                                borderRight: "1px solid black",
                                paddingX: 2,
                            }}
                            key={tabs.length}
                            value={null}
                            label={
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    {"Todos"}
                                </Box>
                            }
                            {...a11yProps(null)}
                        />
                </Tabs>
            </Box>
            {tabs.map((item: any) => (
                <CustomTabPanel key={item.value} value={status} index={item.value} />
            ))}
            {/* <CustomTabPanel key={tabs.length} value={null} index={tabs.length} /> */}
            {children}
        </Box>

    );
};
