import RegisterCard from "../../../../../../components/RegisterCard";
import React, { RefObject, useEffect, useState } from "react";
import * as G from "../../style";
import { TextField } from "@mui/material";
import * as D from "../../../../../../styles/appComponents";
import { iDadosAcesso } from "../../../../../../models/Colaborador/cadastros/DadosAcesso";
import { ErrorMessage } from "../../../../../../utils/AbstractValidation";
import { handleStyleFieldError } from "../../../../../../utils/verifyFieldError";
import { UsinaResponse } from "../../../../../../models/Gerador/GeradorResponse";
import { EnumTipoPessoa } from "../../../../../../enums/Gerador/EnumTipoPessoa.enum";
import { IResponsavelUsina } from "../../../../../../models/Gerador/Cadastro/ResponsavelUsina";
import { ICreateResponsavelLegal } from "../../../../../../models/ResponsavelLegal";
import { DadosAcessoEnum } from "../../../../../../enums/Gerador/EnumInputModel.ts/DadosAcessoEnum";
import MaskDefaultInput from "../../../../../../components/MasDefaultinput/MaskDefaultInput";

export interface PropsDadosAcesso {
  onSave: (data: iDadosAcesso) => void;
  onChange: (data: iDadosAcesso) => void;
  onUpdate: () => void;
  onReset: (usuarioId: string) => void;
  buttonSave: boolean;
  fieldErros: ErrorMessage[] | null;
  geradorId?: string | null | undefined;
  usina?: any;
  usinas?: UsinaResponse[];
  scrollRef: RefObject<HTMLDivElement> | null;
  dadosUsina?: any;
  tipoFormulario?: string | null;
  responsavelUsina?: IResponsavelUsina | undefined;
  responsavelLegal?: ICreateResponsavelLegal[] | undefined;
  areaAccess: boolean;
  buttonResetSenha: boolean;
}

const DadosAcesso: React.FC<PropsDadosAcesso> = ({
  onSave,
  onChange,
  onUpdate,
  buttonSave,
  fieldErros,
  geradorId,
  usina,
  usinas,
  scrollRef,
  onReset,
  dadosUsina,
  tipoFormulario,
  responsavelUsina,
  responsavelLegal,
  areaAccess,
  buttonResetSenha
}) => {
  const formDadosAcessoModel: iDadosAcesso = {
    nomedadosacesso: null,
    email: null,
    telefone: null,
    countrycode: null,
    login: null,
    whatsapp:
      tipoFormulario === "pessoaFisica"
        ? responsavelUsina?.celularresponsavelusina
        : tipoFormulario === "pessoaJuridica"
        ? (responsavelLegal || [{ celular: "" }])[0]?.celular
        : null,
    whatsappcountrycode:
      tipoFormulario === "pessoaFisica"
        ? responsavelUsina?.countrycoderesponsavelusina
        : tipoFormulario === "pessoaJuridica"
        ? (responsavelLegal || [{ countrycode: "" }])[0]?.countrycode
        : null,
  };
  const [dadosAcesso, setDadosAcesso] =
    useState<iDadosAcesso>(formDadosAcessoModel);
  const sendOnSave = (): void => onSave(dadosAcesso);
  const sendOnUpdate = (): void => onUpdate();
  const sendOnChange = (update: iDadosAcesso): void => onChange(update);
  const sendOnReset = (usuarioId: string): void => onReset(usuarioId);

  useEffect(() => {
    if (geradorId) {
      const formDadosAcessoModel: any = {
        nomedadosacesso:
          usina?.tipoPessoa === EnumTipoPessoa.pessoaFisica
            ? usina?.nomeUsina
            : usina?.razaoSocialNome,
        email:
          usina?.tipoPessoa === EnumTipoPessoa.pessoaFisica
            ? usina?.emailResponsavelUsina
            : (usina?.responsavelLegal ?? [{ email: "" }])[0]?.email,
        telefone:
          usina?.tipoPessoa === EnumTipoPessoa.pessoaFisica
            ? usina?.celularResponsavelUsina
            : (usina?.responsavelLegal || [{ celular: "" }])[0]?.celular,
        countrycode:
          usina?.tipoPessoa === EnumTipoPessoa.pessoaFisica
            ? usina?.countryCodeResponsavelUsina
            : (usina?.responsavelLegal || [{ countryCode: "" }])[0]
                ?.countryCode,
        whatsapp:
          (usina?.whatsappDadosAcesso === "" || !usina?.whatsappDadosAcesso) &&
          usina?.tipoPessoa === EnumTipoPessoa.pessoaFisica
            ? usina?.celularResponsavelUsina
            : (usina?.whatsappDadosAcesso === "" ||
                !usina?.whatsappDadosAcesso) &&
              usina?.tipoPessoa === EnumTipoPessoa.pessoaJuridica
            ? (usina?.responsavelLegal || [{ celular: "" }])[0]?.celular
            : usina?.whatsappDadosAcesso,
        whatsappcountrycode:
          (usina?.whatsappCountryCode === "" || !usina?.whatsappCountryCode) &&
          usina?.tipoPessoa === EnumTipoPessoa.pessoaFisica
            ? usina?.countryCodeDadosAcesso
            : (usina?.whatsappCountryCode === "" ||
                !usina?.whatsappCountryCode) &&
              usina?.tipoPessoa === EnumTipoPessoa.pessoaJuridica
            ? (usina?.responsavelLegal || [{ countryCode: "" }])[0]?.countryCode
            : usina?.whatsappCountryCode,
        login:
          usina?.tipoPessoa === EnumTipoPessoa.pessoaFisica
            ? usina?.cpf
            : usina?.cnpjcpf,
      };
      setDadosAcesso(formDadosAcessoModel);
    }
  }, [geradorId, usina]);

  useEffect(() => {
    if (!geradorId) {
      handleInputChange({
        target: {
          name: DadosAcessoEnum.NOMEDADOSACESSO,
          value:
            tipoFormulario === "pessoaFisica"
              ? dadosUsina?.nomeusina
              : tipoFormulario === "pessoaJuridica"
              ? dadosUsina?.razaosocialnome
              : null,
        },
      } as React.ChangeEvent<HTMLInputElement>);

      handleInputChange({
        target: {
          name: DadosAcessoEnum.TELEFONE,
          value:
            tipoFormulario === "pessoaFisica"
              ? responsavelUsina?.celularresponsavelusina
              : tipoFormulario === "pessoaJuridica"
              ? (responsavelLegal || [{ celular: "" }])[0]?.celular
              : null,
        },
      } as React.ChangeEvent<HTMLInputElement>);

      handleInputChange({
        target: {
          name: DadosAcessoEnum.TELEFONE,
          value:
            tipoFormulario === "pessoaFisica"
              ? responsavelUsina?.celularresponsavelusina
              : tipoFormulario === "pessoaJuridica"
              ? (responsavelLegal || [{ celular: "" }])[0]?.celular
              : null,
        },
      } as React.ChangeEvent<HTMLInputElement>);

      handleInputChange({
        target: {
          name: DadosAcessoEnum.WHATSAPP,
          value:
            tipoFormulario === "pessoaFisica"
              ? responsavelUsina?.celularresponsavelusina
              : tipoFormulario === "pessoaJuridica"
              ? (responsavelLegal || [{ celular: "" }])[0]?.celular
              : null,
        },
      } as React.ChangeEvent<HTMLInputElement>);

      handleInputChange({
        target: {
          name: DadosAcessoEnum.EMAIL,
          value:
            tipoFormulario === "pessoaFisica"
              ? responsavelUsina?.emailresponsavelusina
              : tipoFormulario === "pessoaJuridica"
              ? (responsavelLegal || [{ email: "" }])[0]?.email
              : null,
        },
      } as React.ChangeEvent<HTMLInputElement>);

      handleInputChange({
        target: {
          name: DadosAcessoEnum.LOGIN,
          value:
            tipoFormulario === "pessoaFisica"
              ? dadosUsina?.cpf
              : tipoFormulario === "pessoaJuridica"
              ? dadosUsina?.cpfcnpj
              : null,
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [responsavelUsina, responsavelLegal]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name = event.target.name.toLocaleLowerCase();
    const value = event.target.value;

    setDadosAcesso((state: iDadosAcesso) => {
      sendOnChange({ ...state, [name]: value });
      return { ...state, [name]: value };
    });
  };

  return (
    <RegisterCard title="Dados de acesso">
      <G.FWStack direction={"row"} spacing={2}>
        <TextField
          inputProps={{ readOnly: true }}
          sx={handleStyleFieldError(
            DadosAcessoEnum.NOMEDADOSACESSO,
            fieldErros
          )}
          name={DadosAcessoEnum.NOMEDADOSACESSO}
          onChange={handleInputChange}
          value={dadosAcesso?.nomedadosacesso ?? ""}
          label="Nome"
          fullWidth
        />
        <MaskDefaultInput
          type="CELULAR"
          label="Celular"
          readonly={true}
          value={dadosAcesso?.telefone ?? ""}
          valueCountryCode={dadosAcesso?.countrycode ?? ""}
          sx={{ width: "100%" }}
        />
        <TextField
          inputProps={{ readOnly: true }}
          value={dadosAcesso?.login ?? ""}
          label="Login"
          fullWidth
        />
      </G.FWStack>
      <G.FWStack direction={"row"} spacing={2}>
        <MaskDefaultInput
          type="CELULAR"
          label="whatsapp"
          readonly={!areaAccess}
          value={dadosAcesso?.whatsapp ?? ""}
          valueCountryCode={dadosAcesso?.whatsappcountrycode ?? ""}
          onChange={(value: any) => {
            handleInputChange({
              target: { name: DadosAcessoEnum.WHATSAPP, value: value?.value },
            } as React.ChangeEvent<HTMLInputElement>);

            handleInputChange({
              target: {
                name: DadosAcessoEnum.WHATSAPPCOUNTRYCODE,
                value: value?.zipCode,
              },
            } as React.ChangeEvent<HTMLInputElement>);
          }}
          sx={{ width: "48%" }}
        />
      </G.FWStack>
      {
        areaAccess &&
        <G.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton
            disabled={buttonSave || buttonResetSenha}
            $color="lightpink"
            onClick={() => sendOnReset(usina?.idUsuario)}
          >
            Reset Senha
          </D.ContainedButton>
          <D.ContainedButton
            disabled={!buttonSave}
            onClick={sendOnUpdate}
            $color="yellow"
          >
            Editar
          </D.ContainedButton>
          <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>
            Salvar
          </D.ContainedButton>
        </G.FWStack>
      }
    </RegisterCard>
  );
};

export default DadosAcesso;
