import { CircularProgress } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SkeletonCreationCards } from "../../../../components/SkeletonLoads/CreationCards";
import {
  IContratoData,
  ICreateGDContratoCliente,
  IUpdateEndereco,
  IUpdateInformacoesContrato,
  IUpdateOutrasInformacoes,
  IUpdateResponsavelLegal,
} from "../../../../models/GDContrato";
import * as service from "../../../../services/api/GDContratoClienteService";
import * as D from "../../../../styles/appComponents";
import { PessoaType } from "../../../../types/PessoaType";
import { toastMessage } from "../../../../utils/toastMessage";
import InformacoesContratoPJContratar from "../components/cnpj/InformacoesContratoPJ";
import InformacoesResponsavelLegal from "../components/cnpj/informacoesResponsavelLegal";
import EnderecoContratar from "../components/cpf/Endereco";
import InformacoesContratoPFContratar from "../components/cpf/InformacoesContratoPF";
import InformacoesTitularContratar, {
  DataInformacoesTitular,
  DataInformacoesTitularUpdate,
} from "../components/InformacoesTitular";
import OutrasInformacoesContratar from "../components/OutrasInformacoes";
import * as C from "../style";
import { useObjectState } from "../../../../hooks/useObjectState";
import { convertEmptyStringsToNullAsync } from "../../../../utils/convertEmptyStringsToNull";
import { useAuth } from "../../../../hooks/useAuth";

const Contratar = () => {
  //#region Variáveis
  const { user } = useAuth();
  const { tipo, idProposta } = useParams();
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<PessoaType | undefined>(undefined);

  const { state, setObject, updateObject } =
    useObjectState<ICreateGDContratoCliente>({
      isPF: true,
      propostaId: idProposta!,
      enderecoPF: undefined,
      informacoesContrato: undefined,
      informacoesTitularPF: undefined,
      informacoesTitularPJ: undefined,
      outrasInformacoes: undefined,
      responsavelLegalPJ: undefined,
    });

  const [propostaData, setPropostaData] = useState<IContratoData | undefined>();
  //#endregion

  //#region Services
  const getDataInformacoesTitular = async (
    data: DataInformacoesTitularUpdate
  ) => {
    type === "pessoaFisica"
      ? updateObject({
          informacoesTitularPF: data.dataPF,
        })
      : updateObject({
          informacoesTitularPJ: data.dataPJ,
        });
  };

  const getEndereco = async (data: IUpdateEndereco) => {
    updateObject({ enderecoPF: data });
  };

  const getInformacoesContrato = async (data: IUpdateInformacoesContrato) => {
    updateObject({ informacoesContrato: data });
  };

  const getResponsavelLegal = async (data: IUpdateResponsavelLegal) => {
    updateObject({ responsavelLegalPJ: data });
  };

  const getOutrasInformações = async (data: IUpdateOutrasInformacoes) => {
    updateObject({ outrasInformacoes: data });
  };

  const createContrato = async () => {
    setCreateLoading(true);
    var data: ICreateGDContratoCliente = await convertEmptyStringsToNullAsync(
      state
    );

    type === "pessoaFisica"
      ? (data.informacoesTitularPJ = undefined)
      : (data.informacoesTitularPF = undefined);

    await service
      .CreateGDContrato(data)
      .then(async () => {
        setCreateLoading(false);
        if (user?.idCaptador) navigate("/Parceiro/Contratos");
        else navigate("/Colaborador/Comercial/ContratoCliente");
      })
      .catch((e: AxiosError) => {
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao criar o contrato."
        );
        setCreateLoading(false);
      });
  };
  const getContratoDataByPropostaId = async () => {
    if (!idProposta) return;

    await service
      .GetContratoDataByPropostaId(
        idProposta,
        (tipo as PessoaType) == "pessoaFisica" ? 0 : 1
      )
      .then(async (response) => {
        setPropostaData(response.data);
      })
      .catch((e: AxiosError) => {
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao buscar os dados da proposta."
        );
        setCreateLoading(false);
      });
  };
  //#endregion

  //#region UI
  useEffect((): void => {
    setType(tipo as PessoaType);
    getContratoDataByPropostaId();
  }, []);

  useEffect(() => {
    updateObject({ isPF: type === "pessoaFisica" });
  }, [type]);

  useEffect(() => {

  }, [state]);
  //#endregion

  return (
    <>
      {loading ? (
        <SkeletonCreationCards />
      ) : (
        <C.Container>
          <InformacoesTitularContratar
            dataContrato={propostaData}
            type={type!}
            edit={false}
            callback={getDataInformacoesTitular}
          />
          {(tipo as PessoaType) === "pessoaFisica" ? (
            <>
              <EnderecoContratar edit={false} callback={getEndereco} />
              <InformacoesContratoPFContratar
                dataContrato={propostaData?.informacoesContrato}
                edit={false}
                callback={getInformacoesContrato}
              />
            </>
          ) : (
            <>
              <InformacoesContratoPJContratar
                dataContrato={propostaData?.informacoesContrato}
                edit={false}
                callback={getInformacoesContrato}
              />
              <InformacoesResponsavelLegal
                dataContrato={propostaData?.responsavelLegal}
                edit={false}
                callback={getResponsavelLegal}
              />
            </>
          )}
          <OutrasInformacoesContratar
            dataContrato={propostaData?.outrasInformacoes}
            edit={false}
            callback={getOutrasInformações}
            idProposta={idProposta!}
          />
          <D.FWStack justifyContent={"flex-end"} direction={"row"} spacing={2}>
            <D.ContainedButton $color="lightpink" onClick={createContrato}>
              {createLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>Criar contrato</>
              )}
            </D.ContainedButton>
          </D.FWStack>
        </C.Container>
      )}
    </>
  );
};

export default Contratar;
