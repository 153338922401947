import { ReactNode, useMemo } from "react";
import { EnumType } from "../../types/EnumType";
import SelectInput from "./SelectInput";

interface ISelectEnumProps<E extends EnumType> {
  readonly values: E;
  readonly value: E[keyof E] | null;
  readonly onChange: (newValue: E[keyof E] | null) => void;
  readonly label: string;
  readonly idSelect?: string;
  readonly disabled?: boolean;
  readonly filter?: (value: E[keyof E]) => boolean;
  readonly required?: boolean;
  readonly onBlur?: () => void;
  readonly onFocus?: () => void;
  readonly helperText?: ReactNode;
  readonly error?: boolean;
}

function SelectEnum<E extends EnumType>({
  values,
  value,
  onChange,
  label,
  idSelect,
  disabled = false,
  filter,
  required,
  onBlur,
  onFocus,
  helperText,
  error,
}: ISelectEnumProps<E>): JSX.Element {
  const selectValues = useMemo(
    () => Object.values(values),
    [values]
  ) as E[keyof E][];

  const selectFilter = filter ? selectValues.filter(filter) : selectValues;

  return (
    <SelectInput
      idSelect={idSelect}
      label={label}
      value={value}
      values={selectFilter}
      onChange={onChange}
      getValue={(v) => v}
      disabled={disabled}
      required={required}
      onBlur={onBlur}
      onFocus={onFocus}
      helperText={helperText}
      error={error}
    />
  );
}

export default SelectEnum;
