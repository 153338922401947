import { INovaOrdem, IOrdemPagamento } from "../../../../../models/OrdemPagamento";
import { AbstractValidation, Validate } from "../../../../../utils/AbstractValidation";

export class OrdemPagamentoValidate extends AbstractValidation<INovaOrdem>{
    getValidate(): Validate<INovaOrdem>[] {
        return [
            {
                field: "dataReferencia",
                validate(value, model, addError) {
                    if(!value) addError("Primeiro deve selecionar o Mês e Ano")
                },
            },
            {
                field: "usinaId",
                validate(value, model, addError) {
                    if(!value) addError("Primeiro deve selecionar a usina")
                },
            }
        ];
    }

}