import { FC, useState } from "react";
import { Grid, Stack, TextField } from "@mui/material";
import QRCode from "react-qr-code";
import SelectSimNao from "../Select/SelectSimNao";
import { ICaptador } from "../../models/Captador";
import { IUpdateDadosAcesso } from "../../models/Colaborador/cadastros/DadosAcesso";
import RegisterCardButton from "../RegisterCardButton/RegisterCardButton";
import { StartTextTextfield } from "../../styles/appComponents";
import MaskDefaultInput from "../MasDefaultinput/MaskDefaultInput";
import { ICelularInputReturn } from "../../models/Celular";
import useValidate from "../../hooks/useValidate";
import { DadosAcessoInputValidate } from "./DadosAcessoInputValidate";
import { CodigoTelefonico } from "../../enums/EnumCodigoTelefonico.enum";
import SelectEnum from "../Select/SelectEnum";
import { EnumCupomBoasVindas } from "../../enums/EnumCupomBoasVindas.enum";

interface IDadosAcessoProps {
  captador: ICaptador;
  updateDadosAcesso: (
    captadorId: string,
    dadosAcesso: IUpdateDadosAcesso
  ) => Promise<boolean>;
  disabled?: boolean;
}

export const DadosAcessoInput: FC<IDadosAcessoProps> = ({
  captador,
  updateDadosAcesso,
  disabled = false,
}) => {
  const [editar, setEditar] = useState<boolean>(false);
  const [salvandoDados, setSalvandoDados] = useState<boolean>(false);

  const [dadosAcesso, setDadosAcesso] = useState<IUpdateDadosAcesso>(() => {
    return {
      login: captador.user ?? "",
      senha: "",
      email: captador.userEmail ?? "",
      celular: captador.userCelular ?? "",
      countryCode: captador.userCountryCode ?? CodigoTelefonico.BRASIL,
      cupomBoasVindas: captador.cupomBoasVindas,
      emissorNotaFiscal: captador.emissorNotaFiscal ?? false,
      linkCaptador: captador.linkCaptador ?? "",
    };
  });

  const dadosAcessoInputValidate = useValidate(DadosAcessoInputValidate);

  const handleEditeDadosAcesso = async () => {
    setSalvandoDados(true);
    await updateDadosAcesso(captador.id, dadosAcesso);
    setEditar(false);
    setSalvandoDados(false);
  };

  return (
    <RegisterCardButton
      title="Dados de acesso"
      onClickEditar={() => setEditar(true)}
      onClickSalvar={handleEditeDadosAcesso}
      desativarBotaoSalvar={
        !editar || salvandoDados || disabled || dadosAcessoInputValidate.error
      }
      desativarBotaoEditar={editar || salvandoDados || disabled}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Login"
            value={dadosAcesso.login}
            disabled={true}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Senha"
            value={dadosAcesso.senha}
            disabled={true}
          />
        </Grid>
        <Grid item xs={3}>
          <MaskDefaultInput
            label="Celular"
            type="CELULAR"
            onChange={(e) => {
              const celular = e as ICelularInputReturn;
              setDadosAcesso({
                ...dadosAcesso,
                celular: celular.value,
                countryCode: celular.zipCode,
              });
            }}
            value={dadosAcesso.celular}
            valueCountryCode={dadosAcesso.countryCode}
            disabled={disabled || !editar}
            {...dadosAcessoInputValidate.addValidateCampo(
              "celular",
              dadosAcesso
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label="E-mail"
            value={dadosAcesso.email}
            onChange={(v) =>
              setDadosAcesso({ ...dadosAcesso, email: v.target.value })
            }
            disabled={disabled || !editar}
            {...dadosAcessoInputValidate.addValidateCampo("email", dadosAcesso)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Link do parceiro"
            InputProps={{
              startAdornment: (
                <StartTextTextfield>{`${process.env.REACT_APP_APP_URL?.replace(
                  "https://",
                  ""
                ).replace("http://", "")}Convida/`}</StartTextTextfield>
              ),
            }}
            value={dadosAcesso.linkCaptador}
            onChange={(v) =>
              setDadosAcesso({
                ...dadosAcesso,
                linkCaptador: v.target.value.trim(),
              })
            }
            disabled={disabled || !editar}
            {...dadosAcessoInputValidate.addValidateCampo(
              "linkCaptador",
              dadosAcesso
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectEnum
            idSelect="cupom-boas-vindas"
            label="Cupom de boas vindas destinado para?"
            values={EnumCupomBoasVindas}
            value={dadosAcesso.cupomBoasVindas}
            onChange={(v) =>
              setDadosAcesso({ ...dadosAcesso, cupomBoasVindas: v })
            }
            disabled={disabled || !editar}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectSimNao
            label="Emissor de nota fiscal?"
            value={dadosAcesso.emissorNotaFiscal}
            onChange={(v) =>
              setDadosAcesso({ ...dadosAcesso, emissorNotaFiscal: v ?? false })
            }
            disabled={disabled || !editar}
            limparSelecao={false}
          />
        </Grid>
        <Grid item xs={3}>
          <Stack
            justifyContent={"flex-end"}
            alignItems={"flex-end"}
            maxWidth="150px"
            width="100%"
          >
            <QRCode
              value={`${process.env.REACT_APP_APP_URL}Convida/${dadosAcesso.linkCaptador}`}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              size={256}
              viewBox={`0 0 256 256`}
            />
          </Stack>
        </Grid>
      </Grid>
    </RegisterCardButton>
  );
};
