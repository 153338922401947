import { FC, useEffect, useMemo } from "react";
import { IAssociadoListItem } from "../../models/Associado";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { DataArea, GridField } from "../../styles/appComponents";
import { Typography } from "@mui/material";
import Datatable from "../Datatable";
import { mapMask } from "../MasDefaultinput/MapMasks";
import { ObterEnum } from "../../utils/Utils";
import { EnumStatusContratoParceiro } from "../../enums/EnumStatusContratoParceiro.enum";

interface ITabelaAssociadoProps {
  getAssociados: (
    pageNumber: number,
    pageSize: number,
    search: string
  ) => Promise<void>;
  associados: IAssociadoListItem[];
  tamanhoPagina: number;
  paginaNumero: number;
  totalPagina: number;
  pesquisa: string;
}

const TabelaAssociado: FC<ITabelaAssociadoProps> = ({
  getAssociados,
  associados,
  tamanhoPagina,
  paginaNumero,
  totalPagina,
  pesquisa,
}) => {
  const columns: GridColDef<IAssociadoListItem>[] = useMemo(
    () => [
      {
        field: "idReferencial",
        headerName: "ID",
        renderCell: (
          params: GridRenderCellParams<
            IAssociadoListItem,
            IAssociadoListItem["idReferencial"]
          >
        ) => (
          <GridField $alignCenter>
            <Typography textAlign="center" whiteSpace="normal" fontSize="14px">
              {params.value}
            </Typography>
          </GridField>
        ),
      },
      {
        field: "razaoSocial",
        headerName: "Nome",
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<
            IAssociadoListItem,
            IAssociadoListItem["razaoSocial"]
          >
        ) => (
          <GridField $alignCenter>
            <Typography textAlign="center" whiteSpace="normal" fontSize="14px">
              {params.value}
            </Typography>
          </GridField>
        ),
      },
      {
        field: "percentualComissao",
        headerName: "% da comisão",
        renderCell: (
          params: GridRenderCellParams<
            IAssociadoListItem,
            IAssociadoListItem["percentualComissao"]
          >
        ) => (
          <GridField $alignCenter>
            <Typography textAlign="center" whiteSpace="normal" fontSize="14px">
              {params.value}
            </Typography>
          </GridField>
        ),
      },
      {
        field: "email",
        headerName: "E-mail",
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<
            IAssociadoListItem,
            IAssociadoListItem["email"]
          >
        ) => (
          <GridField $alignCenter>
            <Typography textAlign="center" whiteSpace="normal" fontSize="14px">
              {params.value}
            </Typography>
          </GridField>
        ),
      },
      {
        field: "celular",
        headerName: "Telefone",
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<
            IAssociadoListItem,
            IAssociadoListItem["celular"]
          >
        ) => (
          <GridField $alignCenter>
            <Typography textAlign="center" whiteSpace="normal" fontSize="14px">
              {params.value ? mapMask["CELULAR"].execute(params.value) : ""}
            </Typography>
          </GridField>
        ),
      },
      {
        field: "status",
        headerName: "Status do contrato",
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<
            IAssociadoListItem,
            IAssociadoListItem["status"]
          >
        ) => (
          <GridField $alignCenter>
            <Typography textAlign="center" whiteSpace="normal" fontSize="14px">
              {ObterEnum(EnumStatusContratoParceiro, Number(params.value))}
            </Typography>
          </GridField>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    getAssociados(1, 5, "");
  }, [getAssociados]);

  const onSearch = (novaPesquisa: string) => {
    getAssociados(1, tamanhoPagina, novaPesquisa);
  };

  const onChangePage = (novaPagina: number) => {
    getAssociados(novaPagina, tamanhoPagina, pesquisa);
  };

  const onChangePageSize = (novaPaginaTamanho: number) => {
    getAssociados(1, novaPaginaTamanho, pesquisa);
  };

  return (
    <DataArea $align="right">
      <Datatable
        titulo="Associados"
        columns={columns}
        rows={associados}
        pageNumber={paginaNumero}
        pageSize={tamanhoPagina}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onSearch={onSearch}
        totalPages={totalPagina}
        getRowId={(x) => x.idReferencial}
        ocultarSelectPageSize
      />
    </DataArea>
  );
};

export default TabelaAssociado;
