import { Box } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import DadosContratoInput from "../../../../components/DadosContratatoInput/DadosContratoInput";
import {
  ICaptador,
  ICaptadorDadosContrato,
  ICaptadorListItem,
} from "../../../../models/Captador";
import parceiroManager from "../Manager/ParceiroManager";
import { useNavigate, useParams } from "react-router-dom";
import { SkeletonCreationCards } from "../../../../components/SkeletonLoads/CreationCards";
import ReponsavelLegalInput from "../../../../components/ResponsavelLegalInput/ReponsavelLegalInput";
import { InformacaoAssociadoInput } from "../../../../components/InformacaoAssociadoInput/InformacaoAssociadoInput";
import { IBackofficeListItem } from "../../../../models/Colaborador/Colaborador";
import LogoCardInput from "../../../../components/LogoCardInput/LogoCardInput";
import { DicLogo } from "../../../../models/Logo";
import ComissaoDadosPagamento from "../../../../components/ComissaoDadosPagamento/ComissaoDadosPagamento";
import { DadosAcessoInput } from "../../../../components/DadosAcessoInput/DadosAcessoInput";
import StatusContratoInput from "../../../../components/StatusContratoInput/StatusContratoInput";
import { CREATE_CAPTADOR } from "../../../../utils/InicialValues";
import { InformacaoAssociado } from "../../../../models/InformacaoAssociado";
import { FormatDate } from "../../../../utils/dateFormatter";
import { EnumStatusContratoParceiro } from "../../../../enums/EnumStatusContratoParceiro.enum";

const obterDicLogoInicial = (): DicLogo => {
  return {
    svg: null,
    png: null,
    pdf: null,
  };
};

const EditarParceiro: FC = () => {
  const { captadorId } = useParams();
  const [captador, setCaptador] = useState<ICaptador>(() => ({
    ...CREATE_CAPTADOR,
    id: captadorId ?? "",
  }));
  const [backOffices, setBackOffices] = useState<IBackofficeListItem[]>([]);
  const [captadores, setCaptadores] = useState<ICaptadorListItem[]>([]);
  const [dicLogo, setDicLogo] = useState<DicLogo>(obterDicLogoInicial());
  const [buscandoDados, setBuscandoDados] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getCapacitador = async (id: string) => {
      const captador = await parceiroManager.getCaptadorById(id);
      if (captador) {
        setCaptador(captador);
      } else {
        navigate(-1);
      }
    };

    const getLogo = async (id: string) => {
      const novoDicLogo = await parceiroManager.getLogo(id);
      if (novoDicLogo) setDicLogo(novoDicLogo);
    };

    const getBackoffices = async () => {
      const novoBackoffices = await parceiroManager.getBackoffices();
      setBackOffices(novoBackoffices);
    };

    const getCaptadores = async () => {
      const novoCaptadores = await parceiroManager.getCaptadoresFiltro(true);
      setCaptadores(novoCaptadores);
    };

    const buscarDados = async () => {
      setBuscandoDados(true);
      if (captador.id) {
        await Promise.all([
          getCapacitador(captador.id),
          getLogo(captador.id),
          getBackoffices(),
          getCaptadores(),
        ]);
      }
      setBuscandoDados(false);
    };

    buscarDados();
  }, [captador.id, navigate]);

  const obterKeyDicLogo: () => string = () =>
    Object.entries(dicLogo).reduce((acc, [chave, valor]) => {
      if (valor === null) return acc;
      return acc + `${chave}:${"id" in valor ? valor.id : ""}`;
    }, "");

  const updateCaptadorLogo: (updateDicLogo: DicLogo) => Promise<void> = async (
    updateDicLogo: DicLogo
  ) => {
    if (captador) {
      const novoDicLogo = await parceiroManager.updateCaptadorLogo(
        captador.id,
        updateDicLogo
      );
      if (novoDicLogo) setDicLogo(novoDicLogo);
    }
  };

  const getAssociados = useCallback(
    async (pageNumber: number, pageSize: number, search: string) => {
      if (captador?.id) {
        return parceiroManager.getAssociadosByCaptador(
          captador.id,
          pageNumber,
          pageSize,
          search
        );
      }
      return null;
    },
    [captador.id]
  );

  const handlerUpdateAssociadoDadosContrato = useCallback(
    async (dadosContrato: ICaptadorDadosContrato) => {
      if (dadosContrato.captadorId === "") {
        const novoCaptadorId = await parceiroManager.createCaptador(
          dadosContrato
        );
        if (novoCaptadorId) {
          setCaptador((c) => ({ ...c, id: novoCaptadorId }));
          return true;
        }
        return false;
      } else {
        return await parceiroManager.updateAssociadoDadosContrato(
          dadosContrato
        );
      }
    },
    []
  );

  const handlerUpdateInformacaoAssociado = useCallback(
    async (informacaoAssociado: InformacaoAssociado) => {
      const res = await parceiroManager.updateInformacaoAssociado(
        informacaoAssociado
      );
      if (res) {
        setCaptador((c) => ({ ...c, informacaoAssociado }));
      }
      return res;
    },
    []
  );

  const handlerCriaContratoParaAssinaturaDigital = useCallback(
    async (captadorId: string) => {
      const documentoId =
        await parceiroManager.criaContratoParaAssinaturaDigital(captadorId);
      if (documentoId) {
        setCaptador((c) => ({
          ...c,
          uuidContrato: documentoId,
          ultimaAlteracaoStatus: FormatDate(new Date(), "YYYY-MM-DD"),
          statusContrato: EnumStatusContratoParceiro.aguardandoAssinatura,
        }));
      }
      return documentoId;
    },
    []
  );

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      {buscandoDados === false ? (
        <>
          <DadosContratoInput
            captador={captador}
            updateAssociadoDadosContrato={handlerUpdateAssociadoDadosContrato}
          />
          <ReponsavelLegalInput
            captador={captador}
            updateResponsavelLegal={parceiroManager.updateResponsavelLegal}
            disabled={captador.id === ""}
          />
          <InformacaoAssociadoInput
            captador={captador}
            backOffices={backOffices}
            captadores={captadores}
            tipoParceiro={captador.tipoParceiro}
            getAssociados={getAssociados}
            disabled={captador.id === ""}
            updateInformacaoAssociado={handlerUpdateInformacaoAssociado}
          />
          <ComissaoDadosPagamento
            captador={captador}
            updateDadosPagamento={parceiroManager.updateDadosPagamento}
            disabled={captador.id === ""}
          />
          <StatusContratoInput
            captador={captador}
            disabled={captador.id === ""}
            key={captador.statusContrato}
            criaContratoParaAssinaturaDigital={
              handlerCriaContratoParaAssinaturaDigital
            }
          />
          <LogoCardInput
            title="Anexo logo do parceiro"
            value={dicLogo}
            updateCaptadorLogo={updateCaptadorLogo}
            dowloadAnexo={parceiroManager.dowloadAnexo}
            key={obterKeyDicLogo()}
            disabled={captador.id === ""}
          />
          <DadosAcessoInput
            captador={captador}
            updateDadosAcesso={parceiroManager.updateDadosAcesso}
            disabled={captador.id === ""}
          />
        </>
      ) : (
        <SkeletonCreationCards />
      )}
    </Box>
  );
};

export default EditarParceiro;
