import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {ITarifaFioB, ITarifaFioBDashboard} from "../../../models/TarifaFioB";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import * as D from "../../../styles/appComponents";
import {AxiosResponse} from "axios";
import {IPaginatedList} from "../../../models/PaginatedList";
import {
  GetDashboardTarifaFioB,
  GetPaginatedTarifaFioB,
  GetTarifaFioBReport
} from "../../../services/api/TarifaFioBService";
import {toastMessage} from "../../../utils/toastMessage";
import SkeletonDefaultPage from "../../../components/SkeletonLoads/DefaultPage";
import * as C from "../Gerador/style";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import StatusCard from "../../../components/StatusCard";
import Datatable from "../../../components/Datatable";
import ExcelIcon from "../../../assets/Plataforma/excelIcon.svg";
import {EnumTipoTarifas} from "../../../enums/Gerador/EnumTipoTarifas.enum";
import {ColorType} from "../../../types/ColorType";
import {formatValueFloatInString} from "../../../utils/moneyFormatter";

const TarifaFioB: () => React.JSX.Element = (): React.JSX.Element => {
  //region Variáveis
  const [loading, setLoading]: (boolean | Dispatch<SetStateAction<boolean>>)[] = useState<boolean>(true);
  const [tarifaFioB, setTarifaFioB] = useState<ITarifaFioB[]>([]);
  const [isMobile, setIsMobile]: (boolean | Dispatch<SetStateAction<boolean>>)[] = useState(window.innerWidth < 1024);
  const [page, setPage]: (number | Dispatch<SetStateAction<number>>)[] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [statusOpen] = useState(false);
  const [dashboardTarifaFioB, setdashboardTarifaFioB] = useState<ITarifaFioBDashboard>({
    ativos: 0,
    inativos: 0,
  });
  const columns: GridColDef[] = [
    {
      field: "idReferencial",
      headerName: "ID",
      flex: 1,
      align: "left",
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField>
          {params.value}
        </D.GridField>
      )
    },
    {
      field: "classe",
      headerName: "Classe",
      flex: 1,
      minWidth: 100,
      align: "left",
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField>
          {EnumTipoTarifas[params.value as keyof typeof EnumTipoTarifas]}
        </D.GridField>
      )
    },
    {
      field: "ano",
      headerName: "Ano",
      flex: 1,
      minWidth: 100,
      align: "left",
    },
    {
      field: "aliquota",
      headerName: "Alíquota",
      flex: 1,
      minWidth: 100,
      align: "left",
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField>
          {params.value !== null && params.value !== undefined
            ? Number(params.value).toFixed(6)
            : ''}
        </D.GridField>
      )
    },
    {
      field: "ativaEm",
      headerName: "Ativa em",
      flex: 1,
      align: "left",
      minWidth: 110,
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField>
          {new Date(String(params.value)).toLocaleDateString() === "Invalid Date" ? "" : new Date(String(params.value)).toLocaleDateString()}
        </D.GridField>
      )
    },
    {
      field: "inativaEm",
      headerName: "Inativa em",
      flex: 1,
      align: "left",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField $alignCenter={true}>
          {new Date(String(params.value)).toLocaleDateString() === "Invalid Date" ? "" : new Date(String(params.value)).toLocaleDateString()}
        </D.GridField>
      )
    },
    {
      field: "reajuste",
      headerName: "Reajuste",
      flex: 1,
      align: "left",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<any, any, any>) => (
        <D.GridField $alignCenter={true}>
          {formatValueFloatInString(params.value)}
        </D.GridField>
      )
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        const type: ColorType = params.value == 1 ? "green" : "red";
        return (
          <D.GridField $alignCenter={true}>
            <D.Circle color={type}/>
          </D.GridField>
        )
      }
    }
  ];
  //endregion

  //region Services
  const getTarifaFioBs: (queryString?: string) => Promise<void> = async (queryString?: string): Promise<void> => {
    try {
      const {data}: AxiosResponse<IPaginatedList<ITarifaFioB>, any> = await GetPaginatedTarifaFioB(page, pageSize, queryString);
      setTotalPages(data?.totalPages);
      setTarifaFioB(data?.data);
    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar as distribuidoras");
    } finally {
      setLoading(false);
    }
  }

  const getdashboardTarifaFioB: () => Promise<void> = async (): Promise<void> => {
    try {
      const {data}: AxiosResponse<any, ITarifaFioBDashboard> = await GetDashboardTarifaFioB();
      setdashboardTarifaFioB({...data});
    } catch (e: unknown) {
      toastMessage("error", "Erro ao listar o dashboard");
    }
  }

  const geraRelatorio: () => Promise<void> = async () => {
    try {
      const response: AxiosResponse<File, any> = await GetTarifaFioBReport();

      const blob: File = response.data;

      const link: HTMLAnchorElement = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `tarifaFioB_${new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/[-:T]/g, "")}.xlsx`;
      link.click();

      URL.revokeObjectURL(link.href);
    } catch (e) {
      toastMessage("error", "Erro ao tentar criar o relatório.");
    }
  }
  //endregion

  //region UI
  const handleChangePage: (value: number) => void = (value: number): void => {
    setPage(value);
  };

  const handleChangePageSize: (value: number) => void = (value: number): void => {
    setPageSize(value);
  };

  const handleSearch: (value: string) => Promise<void> = async (value: string): Promise<void> => {
    getTarifaFioBs(value);
  };

  useEffect((): () => void => {
    const handleResize: () => void = (): void => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);
    Promise.all([getTarifaFioBs(), getdashboardTarifaFioB()] as Promise<void>[]);
    return (): void => window.removeEventListener("resize", handleResize);
  }, []);
  //endregion

  return (
    <>
      {
        loading ? (<SkeletonDefaultPage/>) : (
          <>
            <C.Container>
              <C.Title>
                Status
                {isMobile && (
                  <>
                    {!statusOpen ? (
                      <KeyboardArrowDownRoundedIcon/>
                    ) : (
                      <KeyboardArrowUpIcon/>
                    )}
                  </>
                )}
              </C.Title>

              {(!isMobile || (statusOpen && isMobile)) && (
                <C.StatusArea>
                  <C.StatusWrapperOrdemPagamento>
                    <StatusCard
                      color="green"
                      text="Ativos"
                      number={dashboardTarifaFioB.ativos ?? "0"}
                    />
                  </C.StatusWrapperOrdemPagamento>
                  <C.StatusWrapperOrdemPagamento>
                    <StatusCard color="red" text="Inativa"
                                number={dashboardTarifaFioB.inativos ?? "0"}/>
                  </C.StatusWrapperOrdemPagamento>
                </C.StatusArea>
              )}

              <D.DataArea $align="right">
                <Datatable
                  hasButton={false}
                  titleButton="Inserir tarifaFioB"
                  columns={columns}
                  rows={tarifaFioB}
                  pageNumber={page}
                  pageSize={pageSize}
                  onChangePage={handleChangePage}
                  onChangePageSize={handleChangePageSize}
                  totalPages={totalPages}
                  onSearch={handleSearch}
                />
                <C.LineBreak/>
                <C.ExportButton
                  onClick={geraRelatorio}
                  variant="contained"
                  startIcon={
                    <img
                      style={{width: "20px", color: "white", fill: "white"}}
                      src={ExcelIcon}
                      alt="excelIcon"
                    />
                  }
                >
                  Gerar relatório
                </C.ExportButton>
              </D.DataArea>
            </C.Container>
          </>
        )
      }
    </>
  );
};

export default TarifaFioB;