import React, { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  CircularProgress,
  Modal,
  TextField,
} from "@mui/material";
import * as C from "../../style";
import CloseIcon from "@mui/icons-material/Close";
import CircleCheckbox from "../../../../../components/CircleCheckBox";
import {
  EnumStatusLead,
  EnumStatusLeadNumericMap,
} from "../../../../../enums/EnumStatusLead.enum";
import HistoricoCard from "../../../../../components/HistoricoCard";
import { ICreateLog, ILog } from "../../../../../models/Log";
import * as logServices from "../../../../../services/api/LogService";
import { AxiosError, AxiosResponse } from "axios";
import { IFullLead, IUpdateLead } from "../../../../../models/Lead";
import * as services from "../../../../../services/api/LeadService";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "../../../../../utils/toastMessage";
import MaskDefaultInput from "../../../../../components/MasDefaultinput/MaskDefaultInput";
import { GetCaptadores } from "../../../../../services/api/CaptadorService";
import { ICaptadorListItem } from "../../../../../models/Captador";
import { handleStyleFieldError } from "../../../../../utils/verifyFieldError";
import { ErrorMessage } from "../../../../../utils/AbstractValidation";
import { LeadModalValidate } from "../../Validation/NewLead/LeadModalValidate";

type LeadModalProps = {
  id: string | undefined;
  openNewLead: boolean;
  callback?: () => void;
};

enum LeadModalEnum {
  ID = "id",
  IDREFERENCIAL = "idReferencial",
  CELULAR = "celular",
  EMAIL = "email",
  NOME = "nome",
  NOMEBACKOFFICE = "nomeBackoffice",
  CAPTADORID = "captadorId",
  STATUS = "status",
  VALORMEDIOCONTA = "valorMedioConta",
  CONTA = "conta",
}

const LeadModal: (props: LeadModalProps) => React.JSX.Element = (
  props: LeadModalProps
): React.JSX.Element => {
  //region Variáveis
  let { openNewLead, callback, id } = props;
  const navigate = useNavigate();
  const [loadingSaveEdit, setLoadingSaveEdit] = useState(false);
  const [editar, setEditar] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [historicoLeads, setHistoricoLeads] = useState<ILog[]>([]);
  const [captadores, setCaptadores] = useState<ICaptadorListItem[]>([]);
  const [historicoAssunto, setHistoricoAssunto] = useState<string>("");
  const [historicoDescricao, setHistoricoDescricao] = useState<string>("");
  const [historicoAnexo, setHistoricoAnexo] = useState<File | null>(null);
  const [fieldErros, setFieldErros] = useState<ErrorMessage[] | null>(null);
  const [leadModal, setLeadModal] = useState<IFullLead>({
    id: "",
    idReferencial: "",
    nome: "",
    celular: "",
    email: "",
    captadorId: "",
    nomeCaptador: "",
    backofficeId: "",
    nomeBackoffice: "",
    valorMedioConta: "",
    status: EnumStatusLead.leads,
    conta: null,
  });
  //endregion

  //region Services
  useEffect(() => {
    (async () => {
      try {
        const response = await GetCaptadores();
        setCaptadores(response.data);
      } catch (e: unknown) {
        const error = e as AxiosError;
        toastMessage(
          "error",
          error.response
            ? String(error.response?.data)
            : "Houve um erro ao pegar os dados."
        );
      }
    })();
  }, []);

  useEffect(() => {
    if (!id) return;

    (async () => {
      try {
        const response: AxiosResponse<IFullLead> = await services.GetLeadById(
          id
        );

        setLeadModal(response.data);
        setSelectedId(response.data.status as unknown as number);

        await getLogsByLeadId(id);
      } catch (e: unknown) {
        const error = e as AxiosError;

        toastMessage(
          "error",
          error.response
            ? String(error.response?.data)
            : "Houve um erro ao pegar os dados."
        );
      }
    })();
  }, [id]);

  const getLogsByLeadId: (id: string) => Promise<void> = async (id: string) => {
    await logServices
      .GetLogsByItemId(id)
      .then((response) => {
        setHistoricoLeads(response.data);
      })
      .catch((e: AxiosError) => {
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os dados."
        );
      });
  };

  const createNewLog: () => Promise<void> = async () => {
    if (historicoAssunto == "") {
      toastMessage("warning", "Assunto não pode ser vazio.");
      return;
    }

    const historicoData: ICreateLog = {
      gdLeadId: leadModal.id,
      gdPropostaId: null,
      gdContratoClienteId: null,
      assunto: historicoAssunto,
      descricao: historicoDescricao,
      anexo: historicoAnexo,
    };

    await logServices
      .CreateLog(historicoData)
      .then(() => {
        getLogsByLeadId(leadModal.id);
        toastMessage("success", "Criado com sucesso");
      })
      .catch((e: AxiosError) => {
        toastMessage(
          "error",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os dados."
        );
      });
  };

  const handleUpdateLead: () => Promise<void> = async () => {
    await setLoadingSaveEdit(true);
    var uploadLeadData: IUpdateLead = {
      id: leadModal.id,
      celular: leadModal.celular,
      captadorId: leadModal.captadorId,
      email: leadModal.email,
      nome: leadModal.nome,
      status: selectedId,
      valor: leadModal.valorMedioConta,
    };

    const resultValidation: LeadModalValidate = new LeadModalValidate();
    const errorMessages: ErrorMessage[] | null | undefined =
      resultValidation.errorMessage(uploadLeadData);

    if (errorMessages) {
      setFieldErros(errorMessages);

      errorMessages.forEach((error: ErrorMessage) => {
        toastMessage("warning", error?.message);
      });
      setLoadingSaveEdit(false);
      return;
    }

    try {
      await services.UpdateLead(uploadLeadData);

      setEditar(false);

      setLoadingSaveEdit(false);
      toastMessage("success", "Editado com sucesso");
      setFieldErros([]);
    } catch (e) {
      const error = e as AxiosError;

      setLoadingSaveEdit(false);
      toastMessage(
        "error",
        error.response
          ? String(error.response?.data)
          : "Houve um erro ao pegar os dados."
      );
    }
  };
  //endregion

  //region UI
  const handleNewHistoricoChange = (
    assunto: string,
    descricao: string,
    anexo: File | null
  ) => {
    setHistoricoAssunto(assunto);
    setHistoricoDescricao(descricao);
    setHistoricoAnexo(anexo);
  };

  const handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setLeadModal((state: IFullLead) => {
      const updatedState = {
        ...state,
        [name]: value ?? null,
      };
      return updatedState;
    });
  };

  const handleSelect = (id: number) => {
    if (editar) {
      setSelectedId(id);
    }
  };

  const handleCloseLeadModal = () => {
    setLeadModal({
      id: "",
      idReferencial: "",
      nome: "",
      celular: "",
      email: "",
      captadorId: "",
      nomeCaptador: "",
      backofficeId: "",
      nomeBackoffice: "",
      valorMedioConta: "",
      status: EnumStatusLead.leads,
      conta: null,
    });
    setEditar(false);
    setHistoricoLeads([]);
    if (callback) callback();
  };
  //endregion

  return (
    <Modal
      sx={{ width: "100%", zIndex: 10 }}
      open={openNewLead}
      onClose={handleCloseLeadModal}
      aria-labelledby="modal-details"
      aria-describedby="modal-details"
    >
      <C.ModalContainer>
        <C.ModalArea>
          <C.ModalCard>
            <C.ModalHeader>
              <h2>
                {leadModal.nome} | {leadModal.idReferencial}
              </h2>
              <C.CloseButton aria-label="close" onClick={handleCloseLeadModal}>
                <CloseIcon />
              </C.CloseButton>
            </C.ModalHeader>
            <h4>Status atual:</h4>
            <C.ModalStatusArea>
              <CircleCheckbox
                id={EnumStatusLeadNumericMap[EnumStatusLead.leads]}
                label="Leads"
                color="blue"
                selected={
                  selectedId === EnumStatusLeadNumericMap[EnumStatusLead.leads]
                }
                onSelect={handleSelect}
              />
              <CircleCheckbox
                id={
                  EnumStatusLeadNumericMap[EnumStatusLead.aguardandoEnvioConta]
                }
                label="Aguardando envio de conta"
                color="yellow"
                selected={
                  selectedId ===
                  EnumStatusLeadNumericMap[EnumStatusLead.aguardandoEnvioConta]
                }
                onSelect={handleSelect}
              />
              <CircleCheckbox
                id={EnumStatusLeadNumericMap[EnumStatusLead.contaAnexada]}
                label="Conta anexada"
                color="green"
                selected={
                  selectedId ===
                  EnumStatusLeadNumericMap[EnumStatusLead.contaAnexada]
                }
                onSelect={handleSelect}
              />
              <CircleCheckbox
                id={EnumStatusLeadNumericMap[EnumStatusLead.propostaGerada]}
                label="Proposta Gerada"
                color="purple"
                selected={
                  selectedId ===
                  EnumStatusLeadNumericMap[EnumStatusLead.propostaGerada]
                }
                onSelect={handleSelect}
              />
              <CircleCheckbox
                id={EnumStatusLeadNumericMap[EnumStatusLead.cancelada]}
                label="Cancelada"
                color="red"
                selected={
                  selectedId ===
                  EnumStatusLeadNumericMap[EnumStatusLead.cancelada]
                }
                onSelect={handleSelect}
              />
            </C.ModalStatusArea>
            <C.ModalInputsArea>
              <C.ModalInput
                aria-label="nome"
                label="Nome"
                sx={handleStyleFieldError(LeadModalEnum.NOME, fieldErros, {
                  width: "100%",
                })}
                value={leadModal.nome}
                name={LeadModalEnum.NOME}
                onChange={handleInputChange}
                inputProps={{ readOnly: !editar }}
              />
              <MaskDefaultInput
                label="Telefone"
                type={"CELULAR"}
                value={leadModal.celular}
                sx={{
                  width: "inherit",
                  flex: 1,
                  flexBasis: "49%",
                  ...handleStyleFieldError(LeadModalEnum.CELULAR, fieldErros, {
                    width: "100%",
                  }),
                }}
                onChange={(rawValue) => {
                  const value =
                    typeof rawValue === "string"
                      ? rawValue
                      : (rawValue as { value?: string })?.value ?? "";

                  handleInputChange({
                    target: {
                      name: LeadModalEnum.CELULAR,
                      value: value,
                    },
                  } as React.ChangeEvent<HTMLInputElement>);
                }}
                readonly={!editar}
              />
              <C.ModalInput
                aria-label="email"
                label="Email"
                name={LeadModalEnum.EMAIL}
                value={leadModal.email ? leadModal.email : ""}
                onChange={handleInputChange}
                inputProps={{ readOnly: !editar }}
              />
              <Autocomplete
                data-testid="autocomplete"
                options={captadores}
                noOptionsText="Nenhum parceiro encontrado"
                getOptionLabel={(option) => option.nome}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Parceiro"
                    inputProps={{
                      ...params.inputProps,
                      "aria-label": "parceiro",
                    }}
                  />
                )}
                sx={{
                  width: "100%",
                  flex: 1,
                  flexBasis: "49%",
                  ...handleStyleFieldError(
                    LeadModalEnum.CAPTADORID,
                    fieldErros,
                    { width: "100%" }
                  ),
                }}
                multiple={false}
                value={
                  captadores.find(
                    (captador: ICaptadorListItem) =>
                      captador.id == leadModal.captadorId
                  ) ?? null
                }
                readOnly={!editar}
                onChange={(_, newValue) => {
                  handleInputChange({
                    target: {
                      name: LeadModalEnum.CAPTADORID,
                      value: newValue?.id as string,
                    },
                  } as unknown as unknown as React.ChangeEvent<HTMLInputElement>);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
              <C.ModalInput
                aria-label="backoffice"
                label="Backoffice"
                value={leadModal.nomeBackoffice}
                inputProps={{ readOnly: true }}
              />
              <MaskDefaultInput
                type={"REAL"}
                label="Valor"
                sx={{
                  width: "inherit",
                  flex: 1,
                  flexBasis: "49%",
                  ...handleStyleFieldError(
                    LeadModalEnum.VALORMEDIOCONTA,
                    fieldErros,
                    { width: "100%" }
                  ),
                }}
                value={leadModal.valorMedioConta}
                onChange={(value) => {
                  handleInputChange({
                    target: {
                      name: LeadModalEnum.VALORMEDIOCONTA,
                      value: value,
                    },
                  } as React.ChangeEvent<HTMLInputElement>);
                }}
                readonly={!editar}
              />
            </C.ModalInputsArea>
            <C.ModalButtonsArea>
              <C.ModalProposalButton
                variant="contained"
                onClick={() =>
                  navigate(`/Parceiro/Novo/Proposta/${leadModal.id}`)
                }
              >
                Gerar proposta
              </C.ModalProposalButton>
              <C.ModalSaveButton
                aria-label="editar"
                variant="contained"
                disabled={editar}
                onClick={() => setEditar(true)}
              >
                Editar
              </C.ModalSaveButton>
              <C.ModalSaveButton
                aria-label="salvar"
                variant="contained"
                disabled={!editar}
                onClick={() => handleUpdateLead()}
              >
                {loadingSaveEdit ? (
                  <CircularProgress size={20} color="secondary" />
                ) : (
                  <>Salvar</>
                )}
              </C.ModalSaveButton>
            </C.ModalButtonsArea>
          </C.ModalCard>
          <HistoricoCard
            tableData={historicoLeads}
            getNewHistoricData={handleNewHistoricoChange}
            saveFunction={createNewLog}
            reloadTable={() => getLogsByLeadId(leadModal.id)}
          />
        </C.ModalArea>
      </C.ModalContainer>
    </Modal>
  );
};

export default LeadModal;
