import {MenuItem, TextField, Typography,} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ExcelIcon from "../../../assets/Plataforma/excelIcon.svg";
import * as D from "../../../styles/appComponents";
import * as C from "./style";
import Datatable from "../../../components/Datatable";
import {toastMessage} from "../../../utils/toastMessage";
import {GetAll, GetColaboradorById} from "../../../services/api/ColaboradorService";
import {ColaboradorByIdResponse, ColaboradorResponse} from "../../../models/Colaborador/Colaborador";
import {enumSituacaoColaboradorMapping} from "../../../enums/EnumSituacaoColaborador.enum";
import {enumFuncaoColaboradorMap} from "../../../enums/Colaborador/EnumFuncaoColaborador.enum";
import {ModalInfoColaborador} from "./Components/ModalInfoColaborador";
import {IAnexoDTO} from "../../../models/Anexo";
import {GetAllAnexos} from "../../../services/api/AnexoService";
import {CPFTransform, TelefoneFormatter} from "../../../utils/ValueTranform";

const Colaborador: React.FC = () => {
  const navigate = useNavigate();

  const [colaboradores, setColaboradores] = useState<ColaboradorResponse[]>([]);
  const [colaborador, setColaborador] = useState<ColaboradorByIdResponse | undefined>(undefined);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>("")
  const [openModalSeeMore, setOpenModalSeeMore] = useState<boolean>(false);
  const [anexos, setAnexos] = useState<IAnexoDTO[]>([]);

  useEffect(() => {
    loadColaboradores();

  }, [page, pageSize]);

  const getColaboradorById = async (colaboradorId: string | null) => {
      try {
        if(colaboradorId){
          const { data } = await GetColaboradorById(colaboradorId);
          setColaborador(data);
        };
  
      } catch (error) {
        console.error(error);
        toastMessage("error", "Erro ao buscar colaborador");
      }
  };

  const loadAnexos = async (id:string | null | undefined) => {
    try {
      if(id){
        const { data } = await GetAllAnexos(id);
        setAnexos(data);

      }else setAnexos([]);

    } catch (error) {
      toastMessage("error","Erro ao listar Arquivos");
    }
  };

  const handleOpenModalSeeMore = async (colaboradorId: string | null) => {
    setOpenModalSeeMore(true);

    if(colaboradorId){
      await getColaboradorById(colaboradorId);
      await loadAnexos(colaboradorId);
    }

  };

  const handleCloseModalSeeMore = () => {
    setColaborador(undefined);
    setOpenModalSeeMore(false);
  };

  const handleChangePage = (value: number) => {
    setPage(value);
  };

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
  };

  const handleSearch = async (value: string): Promise<void> => {
    setSearch(value)
    await loadColaboradores(value);
  };

  const loadColaboradores = async (queryString?: string | null) => {
    try {
      const { data } = await GetAll(pageSize, page, queryString);
      setColaboradores(data?.data);
      setTotalPages(data?.totalPages);
      
    } catch (error) {
      toastMessage("error","rro ao listar Colaboradores!")
    }
  };

  const redirectPage = () => {
    navigate("/Cadastro/Colaborador")
  };

  const columns: GridColDef[] = [
    { 
      field: "idReferencial", 
      headerName: "ID",
      renderCell: (params: any) => (
        <D.GridField
          onClick={() =>
            navigate(`/Editar/Colaborador/${params?.row?.id}`)
          }
        >
          {params.value}
        </D.GridField>
      ), 
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "cpf",
      headerName: "CPF",
      description: "cpf",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {CPFTransform(params.value)}
          </Typography>
        </D.GridField>
      ),
    },
    { 
      field: "telefone", 
      headerName: "Telefone",
      flex: 1,
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {params.value ? TelefoneFormatter(params.value) : ""}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "funcao",
      headerName: "Função",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {enumFuncaoColaboradorMap.find(x => x.value === params.value)?.name}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "situacao",
      headerName: "Situação",
      flex: 1,
      align: "center",
      renderCell: (params) => (
        <D.GridField>
          <Typography
            style={{
              whiteSpace: "normal",
              fontSize: 14,
            }}
          >
            {enumSituacaoColaboradorMapping.find(x => x.value === params.value)?.name}
          </Typography>
        </D.GridField>
      ),
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <D.GridField>
          <D.DefaultGridButton onClick={() => handleOpenModalSeeMore(params?.row?.id)}>
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];

  const rows = colaboradores


  return (
    <>
      <C.Container>
        <D.DataArea $align="right">
          <Datatable
              handleButton={redirectPage}
              hasButton={true}
              titleButton="Novo Colaborador"
              columns={columns}
              rows={rows}
              pageNumber={page}
              pageSize={pageSize}
              onChangePage={(e: number) => handleChangePage(e)}
              onChangePageSize={(e: number) => handleChangePageSize(e)}
              totalPages={totalPages}
              onSearch={handleSearch}
            />

          <C.LineBreak />
          <C.ReportsArea>
            <C.SelectArea>
              <C.ReportsLabel>Status de leads</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Em análise</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Selecione um parceiro</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Todos os parceiros</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Selecione o backoffice</C.ReportsLabel>
              <C.ReportsSelect
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={""}
              >
                <MenuItem value="">
                  <em>Todos os backoffice</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </C.ReportsSelect>
            </C.SelectArea>
            <C.SelectArea>
              <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
              <TextField
                fullWidth
                type="date"
                inputProps={{ "aria-label": "Without label" }}
              ></TextField>
            </C.SelectArea>
          </C.ReportsArea>
          <C.ExportButton
            variant="contained"
            startIcon={
              <img
                style={{ width: "20px", color: "white", fill: "white" }}
                src={ExcelIcon}
                alt="excelIcon"
              />
            }
          >
            Gerar relatório
          </C.ExportButton>
        </D.DataArea>
      </C.Container>
      <ModalInfoColaborador 
        handleCloseModalSeeMore={handleCloseModalSeeMore}
        colaborador={colaborador}
        openModalSeeMore={openModalSeeMore}
        anexos={anexos}
      />
    </>
  );
};

export default Colaborador;
