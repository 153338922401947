import {Autocomplete, TextField} from "@mui/material";
import RegisterCard from "../../../../../components/RegisterCard";
import * as D from "../../../../../styles/appComponents";
import React, {useEffect, useState} from "react";
import * as leadService from "../../../../../services/api/LeadService";
import * as cooperativaService from "../../../../../services/api/CooperativaService";
import {IFullLead} from "../../../../../models/Lead";
import {AxiosError, AxiosResponse} from "axios";
import Swal from "sweetalert2";
import {IDadosPessoaisProposta} from "../../../../../models/GDProposta";
import {ICooperativaListItem} from "../../../../../models/Cooperativa";
import MaskDefaultInput from "../../../../../components/MasDefaultinput/MaskDefaultInput";
import {toastMessage} from "../../../../../utils/toastMessage";
import {EnumStatusLead} from "../../../../../enums/EnumStatusLead.enum";
import {ErrorMessage} from "../../../../../utils/AbstractValidation";
import {handleStyleFieldError} from "../../../../../utils/verifyFieldError";

interface IDadosPessoaisGerarPropostaProps {
  leadId: string;
  onSendData: (data: IDadosPessoaisProposta) => void;
  erros?: ErrorMessage[] | null
}

enum DadosPessoaisGerarPropostaEnum {
  NOME = "nome",
  CPF = "cpf",
  CELULAR = "celular",
  EMAIL = "email",
  COOPERATIVA = "cooperativaId",
}

const DadosPessoaisGerarProposta: React.FC<
  IDadosPessoaisGerarPropostaProps
> = ({leadId, onSendData, erros}) => {
  //region Variáveis
  const [leadData, setLeadData] = useState<IFullLead>();
  const [cooperativaList, setCooperativaList] = useState<ICooperativaListItem[]>([]);
  const [fieldErros, setFieldErros] = useState<ErrorMessage[] | null | undefined>(null);
  const [formData, setFormData] = useState<IDadosPessoaisProposta>({
    celular: "",
    cooperativaId: "",
    cpf: "",
    email: "",
    nome: ""
  });
  //endregion

  //region Services
  useEffect(() => {
    (async () => {
      try {
        const response: AxiosResponse<IFullLead, any> = await leadService.GetLeadById(leadId);

        setLeadData(response.data);
        setFormData((s: IDadosPessoaisProposta) => {
          const updatedState = {
            nome: response.data.nome,
            celular: response.data.celular,
            email: response.data.email ?? ""
          } as IDadosPessoaisProposta;
          onSendData(updatedState);

          return updatedState;
        });
      } catch (error) {
        const e = error as AxiosError;

        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.");
      }
    })()
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response: AxiosResponse<ICooperativaListItem[], any> = await cooperativaService
          .GetCooperativas();
        setCooperativaList(response.data);
      } catch (error) {
        const e = error as AxiosError;
        toastMessage("error", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.");
      }
    })()
  }, []);
  //endregion

  //region UI
  const handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setFormData((state: IDadosPessoaisProposta) => {
      const updatedState = {
        ...state,
        [name]: value ?? null,
      };

      onSendData(updatedState);
      return updatedState;
    });
  }

  useEffect(() => {
    setFieldErros(erros);
  }, [erros]);
  //endregion

  return (
    <RegisterCard title="Dados Pessoais">
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          aria-label="nome"
          label="Nome"
          required
          fullWidth
          sx={handleStyleFieldError(
            DadosPessoaisGerarPropostaEnum.NOME,
            fieldErros ?? null, { width:"100%" }
          )}
          name={DadosPessoaisGerarPropostaEnum.NOME}
          onChange={handleInputChange}
          value={formData.nome}
        />
        <MaskDefaultInput
          value={formData.cpf ?? undefined}
          type={"CPF"}
          label={"CPF"}
          onChange={(rawValue) => {
            const value =
              typeof rawValue === "string"
                ? rawValue
                : (rawValue as { value?: string })?.value ?? "";

            handleInputChange({
              target: {
                name: DadosPessoaisGerarPropostaEnum.CPF,
                value: value
              }
            } as React.ChangeEvent<HTMLInputElement>)
          }}
        />
        <MaskDefaultInput
          sx={handleStyleFieldError(
            DadosPessoaisGerarPropostaEnum.CELULAR,
            fieldErros ?? null, { width:"100%" }
          )}
          value={formData.celular ?? undefined}
          type={"CELULAR"}
          label={"Celular"}
          onChange={(rawValue) => {
            const value =
              typeof rawValue === "string"
                ? rawValue
                : (rawValue as { value?: string })?.value ?? "";

            handleInputChange({
              target: {
                name: DadosPessoaisGerarPropostaEnum.CELULAR,
                value: value
              }
            } as React.ChangeEvent<HTMLInputElement>)
          }}
        />
        <TextField
          label="Email"
          aria-label="email"
          required
          fullWidth
          value={formData.email}
          name={DadosPessoaisGerarPropostaEnum.EMAIL}
          onChange={handleInputChange}
        />
      </D.FWStack>
      <D.FWStack direction={"row"} spacing={2}>
        <TextField
          aria-label="captador"
          value={leadData ? leadData.nomeCaptador : ""}
          label="Parceiro"
          required
          fullWidth
        />
        <Autocomplete
          options={cooperativaList}
          fullWidth
          data-testid="cooperativa"
          value={cooperativaList.find(x => formData.cooperativaId === x.id) ?? null}
          noOptionsText="Nenhuma cooperativa encontrada"
          getOptionLabel={(option) => option.nome}
          sx={{
            ...handleStyleFieldError(
              DadosPessoaisGerarPropostaEnum.COOPERATIVA,
              fieldErros ?? null, {width:"100%"}
            )}}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.nome}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              data-testid="cooperativa-txt"
              {...params}
              inputProps={{
                ...params.inputProps,
                "aria-label": "cooperativa-txt",
              }}
              label="Cooperativa"
              required
            />
          )}
          defaultValue={null}
          onChange={(_, newValue) => {
            handleInputChange({
              target: {
                name: DadosPessoaisGerarPropostaEnum.COOPERATIVA,
                value: newValue?.id as string
              }
            } as unknown as unknown as React.ChangeEvent<HTMLInputElement>)
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        <TextField
          value={leadData ? leadData.nomeBackoffice : ""}
          label="Backoffice"
          aria-label="backoffice"
          required
          fullWidth
        />
      </D.FWStack>
    </RegisterCard>
  );
};

export default DadosPessoaisGerarProposta;
