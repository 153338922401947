import React, {useEffect, useState} from 'react';
import SkeletonDefaultPage from "../../../components/SkeletonLoads/DefaultPage";
import * as C from "./style";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {ListView} from "../../../components/ListView";
import * as D from "../../../styles/appComponents";
import Datatable from "../../../components/Datatable";
import ExcelIcon from "../../../assets/Plataforma/excelIcon.svg";
import {StatusDashboard} from "../../../models/StatusDashboard";
import {GridColDef} from "@mui/x-data-grid";
import {AxiosError, AxiosResponse} from "axios";
import {toastMessage} from "../../../utils/toastMessage";
import {IPaginatedList} from "../../../models/PaginatedList";
import * as BuscaFaturaServices from "../../../services/api/BuscaFaturaService";
import {EnumStatusBuscaFaturaColorMap, IBuscaFatura} from "../../../models/BuscaFatura";
import {EnumStatusBuscaFatura, EnumStatusBuscaFaturaMap} from "../../../enums/EnumStatusBuscaFatura";
import {Autocomplete, Box, MenuItem, Stack, TextField} from "@mui/material";
import * as geradorServices from '../../../services/api/GeradorService';
import {
  EnumStatusOrdemPagamento,
  EnumStatusOrdemPagamentoMapSelect,
  EnumStatusOrdemPagamentoNumericMap
} from "../../../enums/EnumStatusOrdemPagamento";
import {IUsinaListItem} from "../../../models/Gerador/Usina";
import {IBackofficeListItem} from "../../../models/Colaborador/Colaborador";
import * as colaboradorServices from "../../../services/api/ColaboradorService";
import {useNavigate} from "react-router-dom";
import {undefined} from "zod";
import {REAL} from "../../../utils/masInputs";

export interface ITIpoDeBuscaFatura {
  type: TipoDeBuscaEnum | null;
  search: string | null;
}

export interface ITipoDeBuscaFaturaReport {
  ordemPagamento: string;
  gerador: string;
  backOffice: string;
  dataInicio: Date | null;
  dataFim: Date | null;
}

enum TipoDeBuscaEnum {
  idConta = "Id conta",
  cpfcnpj = "CPF/CNPJ",
  nomeTItular = "Nome do titular",
  numeroInstalacao = "Número de instalação"
}

const typeOptions = [
  {
    name: TipoDeBuscaEnum.idConta,
    value: TipoDeBuscaEnum.idConta,
  },
  {
    name: TipoDeBuscaEnum.cpfcnpj,
    value: TipoDeBuscaEnum.cpfcnpj,
  },
  {
    name: TipoDeBuscaEnum.nomeTItular,
    value: TipoDeBuscaEnum.nomeTItular,
  },
  {
    name: TipoDeBuscaEnum.numeroInstalacao,
    value: TipoDeBuscaEnum.numeroInstalacao,
  },
];

const BuscaFatura: () => React.JSX.Element = (): React.JSX.Element => {
  //region Variaveis
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [showTableContext, setShowTableContext] = useState<boolean>(false);
  const [geradores, setGeradores] = useState<IUsinaListItem[]>([]);
  const [backoffices, setBackoffices] = useState<IBackofficeListItem[]>([])
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [statusOpen, setStatusOpen] = useState<boolean>(false);
  const [isMobile, _] = useState(window.innerWidth < 1024);
  const [buscaFaturaReport, setBuscaFaturaReport] = useState<ITipoDeBuscaFaturaReport>({
    backOffice: "",
    dataFim: null,
    dataInicio: null,
    gerador: "",
    ordemPagamento: ""
  });
  const [BuscaFaturasRows, setBuscaFaturasRows] = useState<IBuscaFatura[]>([
      {
        id: 1,
        titular: "João Silva",
        gd: "Financeiro",
        instalacao: "3014702819",
        valor: "Pendente",
        referente: "Janeiro/2024",
        vencimento: "2024-02-10",
        recebimento: "-",
        valorQuitado: 0.00,
        jurosMultas: 5.00,
        status: EnumStatusBuscaFatura.quitado,
      },
      {
        id: 2,
        titular: "Maria Oliveira",
        gd: "Comercial",
        instalacao: "3014702819",
        valor: "Pago",
        referente: "Fevereiro/2024",
        vencimento: "2024-03-10",
        recebimento: "2024-03-12",
        valorQuitado: 150.00,
        jurosMultas: 0.00,
        status: EnumStatusBuscaFatura.cancelado,
      },
      {
        id: 3,
        titular: "Carlos Souza",
        gd: "TI",
        instalacao: "3014702819",
        valor: "Atrasado",
        referente: "Março/2024",
        vencimento: "2024-04-10",
        recebimento: "-",
        valorQuitado: 0.00,
        jurosMultas: 12.00,
        status: EnumStatusBuscaFatura.vencido,
      }
    ]
  );
  const [tipoDeBuscaSearch, setTipoDeBuscaSearch] = useState<ITIpoDeBuscaFatura>({
    type: null,
    search: null
  })
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      renderCell: (params) => <D.GridField onClick={() => handleOpen(params.row.id)}>{params.value}</D.GridField>,
    },
    {
      field: "titular",
      headerName: "TItular",
      flex: 1,
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "gd",
      headerName: "GD",
      flex: 1,
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "instalacao",
      headerName: "Instalação",
      flex: 1,
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 1,
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "referente",
      headerName: "Referente",
      flex: 1,
      renderCell: (params) => <D.GridField>{params.value}</D.GridField>,
    },
    {
      field: "vencimento",
      headerName: "Vencimento",
      flex: 1,
      renderCell: (params) => <D.GridField>{new Date(params.value).toLocaleDateString()}</D.GridField>,
    },
    {
      field: "recebimento",
      headerName: "Recebimento",
      flex: 1,
      renderCell: (params) =>
        <D.GridField>{new Date(params.value).toLocaleDateString() === "Invalid Date" ? "" : new Date(params.value).toLocaleDateString()}</D.GridField>,
    },
    {
      field: "valorQuitado",
      headerName: "Valor quitado",
      flex: 1,
      renderCell: (params) => <D.GridField>R$ {new REAL().executeForInput(params.value.toString())}</D.GridField>,
    },
    {
      field: "jurosMultas",
      headerName: "Juros e multas",
      flex: 1,
      renderCell: (params) => <D.GridField>R$ {new REAL().executeForInput(params.value.toString())}</D.GridField>,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => <D.GridField $alignCenter={true}>
        <D.Circle color={EnumStatusBuscaFaturaColorMap[Number(params.value)]}/>
      </D.GridField>,
    },
    {
      field: "outras",
      headerName: "Outras",
      flex: 1,
      renderCell: (params) => (
        <D.GridField $alignCenter>
          <D.DefaultGridButton>
            Ver mais
          </D.DefaultGridButton>
        </D.GridField>
      ),
    },
  ];
  const [statusBuscaFatura, setStatusBuscaFatura] = useState<StatusDashboard<EnumStatusBuscaFatura>[]>([
    {
      enumStatus: 0,
      totalStatus: 14,
      totalValue: 2235.14,
      energiaTotalInjetada: 2849
    },
    {
      enumStatus: 1,
      totalStatus: 14,
      totalValue: 2235.14,
      energiaTotalInjetada: 2849
    },
    {
      enumStatus: 2,
      totalStatus: 14,
      totalValue: 2235.14,
      energiaTotalInjetada: 2849
    },
  ]);
  const [filterStatusBuscaFatura, setFilterStatusBuscaFatura] = useState<EnumStatusBuscaFatura | null | undefined>(null);
  //endregion

  //region Services
  const handleSubmit: (e: any) => Promise<void> = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    try {
      const {data}: AxiosResponse<IPaginatedList<IBuscaFatura>> = await BuscaFaturaServices.GetBuscaFaturas(page, pageSize, search, filterStatusBuscaFatura ?? null);

      setTotalPages(data?.totalPages);
      setBuscaFaturasRows(data?.data);
    } catch (e) {
      const error = e as AxiosError;

      toastMessage("error", error.response
        ? String(error.response?.data)
        : "Houve um erro ao pegar os dados.");
    } finally {
      setLoading(false);
    }
    setShowTableContext(true);
  }

  useEffect(() => {
    (async () => {
      try {
        const {data}: AxiosResponse<IUsinaListItem[]> = await geradorServices.GetUsinasListItem();

        setGeradores(data)
      } catch (e) {
        const error = e as AxiosError;

        toastMessage("error", error.response
          ? String(error.response?.data)
          : "Houve um erro ao pegar os dados.");
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response: AxiosResponse<IBackofficeListItem[]> =
          await colaboradorServices.GetBackoffices();
        setBackoffices(response.data);
      } catch (e) {
        const error = e as AxiosError;

        toastMessage("error", error.response
          ? String(error.response?.data)
          : "Houve um erro ao pegar os dados.");
      }
    })()
  }, []);

  const geraRelatorio: () => Promise<void> = async () => {
    try {
      const response: AxiosResponse<File, any> = await BuscaFaturaServices.GeraRelatorio();
      const blob: File = response.data;

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `BuscaFatura_${new Date()
        .toISOString()
        .slice(0, 19)
        .replace(/[-:T]/g, "")}.xlsx`;
      link.click();

      URL.revokeObjectURL(link.href);
    } catch (e) {
      const error = e as AxiosError;

      toastMessage("error", error.response
        ? String(error.response?.data)
        : "Houve um erro ao tentar gerar o relatório.");
    }
  }
  //endregion

  //region UI
  const handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setTipoDeBuscaSearch((state: ITIpoDeBuscaFatura) => {
      return {
        ...state,
        [name]: value,
      };
    });
  };

  const handleStatusOpen: () => void = () => {
    setStatusOpen(!statusOpen);
  };

  const handleChangePage: (value: number) => void = (value: number) => {
    setPage(value);
  };

  const handleChangePageSize: (value: number) => void = (value: number) => {
    setPageSize(value);
  };

  const handleOpen: (id: string) => Promise<void> = async (id: string) => {
    navigate(`/Editar/BuscarFatura/${id}`);
  };

  const handleSearch: (value: string) => Promise<void> = async (value: string) => {
    setPage(1);
    setSearch(value);
  };
  //endregion

  return (
    <>
      <C.Container>
        <C.DataAreaForm onSubmit={handleSubmit} $align="right">
          <Box sx={{width: '100%'}}>
            <Box sx={{width: '100%', marginBottom: 2}}>
              <C.Title>
                Tipo de Busca
              </C.Title>
            </Box>
            <C.ReportsLabel>Selecione</C.ReportsLabel>
            <Autocomplete
              aria-label="autocomplete"
              test-id="autocomplete"
              options={typeOptions}
              noOptionsText="Nenhum tipo encontrado"
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Selecione"
                />
              )}
              defaultValue={null}
              onChange={(_, newValue) => {
                handleInputChange({
                  target: {
                    name: tipoDeBuscaSearch.type,
                    value: newValue?.value as string,
                  },
                } as unknown as unknown as React.ChangeEvent<HTMLInputElement>);
              }}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
            />
          </Box>

          <TextField
            sx={{width: '100%'}}
            label="Digite aqui"
            aria-label="Digite aqui"
            type="text"
            required
            value={tipoDeBuscaSearch.search}
            onChange={handleInputChange}
          />
          <Stack
            sx={{width: '50%'}}
          >
            <D.ContainedButton
              test-id="submit"
              type="submit"
            >
              Buscar
            </D.ContainedButton>
          </Stack>
        </C.DataAreaForm>
      </C.Container>

      {
        showTableContext ? (
          loading ? (
            <SkeletonDefaultPage/>
          ) : (
            <C.Container>
              <C.Title onClick={() => handleStatusOpen()}>
                {isMobile && (
                  <>
                    {!statusOpen ? (
                      <KeyboardArrowDownRoundedIcon/>
                    ) : (
                      <KeyboardArrowUpIcon/>
                    )}
                  </>
                )}
              </C.Title>
              <ListView<EnumStatusBuscaFatura>
                sectionName={"colaboradorAcessBuscaFatura"}
                hasButtonSendClickSign={false}
                hasOtherStatus={false}
                statusContratoBuilder={EnumStatusBuscaFaturaMap}
                data={statusBuscaFatura}
                getFilterStatus={setFilterStatusBuscaFatura}
                filterStatusContrato={filterStatusBuscaFatura}
                hasSecondaryValues={true}
                hasThridValues={false}
              >
                <D.DataArea $align="right">
                  <Datatable
                    hasButton={false}
                    titleButton={"Novo BuscaFatura"}
                    columns={columns}
                    rows={BuscaFaturasRows}
                    pageNumber={page}
                    onSearch={() => handleSearch}
                    pageSize={pageSize}
                    onChangePage={(e) => handleChangePage(e)}
                    onChangePageSize={(e) => handleChangePageSize(e)}
                    totalPages={totalPages}
                  />
                  <C.LineBreak/>
                  <C.ReportsArea>
                    <C.SelectArea>
                      <C.ReportsLabel>Status da ordem de pagamento</C.ReportsLabel>
                      <C.ReportsSelect
                        displayEmpty
                        inputProps={{"aria-label": "Without label"}}
                        defaultValue={""}
                      >
                        <MenuItem value="">
                          <D.DefaultColorText>Todos os status</D.DefaultColorText>
                        </MenuItem>
                        {Object.keys(EnumStatusOrdemPagamentoMapSelect).map(
                          (key) => (
                            <MenuItem
                              key={key}
                              value={EnumStatusOrdemPagamentoNumericMap[EnumStatusOrdemPagamento[key as keyof typeof EnumStatusOrdemPagamento]]
                              }
                            >
                              {EnumStatusOrdemPagamentoMapSelect[key as keyof typeof EnumStatusOrdemPagamento]}
                            </MenuItem>
                          )
                        )}
                      </C.ReportsSelect>
                    </C.SelectArea>
                    <C.SelectArea>
                      <C.ReportsLabel>Selecione um gerador</C.ReportsLabel>
                      <Autocomplete
                        options={geradores}
                        fullWidth
                        noOptionsText="Nenhum gerador encontrado"
                        getOptionLabel={(option: any) => option.nome}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            placeholder="Todos os geradores"
                            inputProps={{
                              ...params.inputProps,
                              "aria-label": "Without label",
                            }}
                          />
                        )}
                        defaultValue={null}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                      />
                    </C.SelectArea>
                    <C.SelectArea>
                      <C.ReportsLabel>Selecione o backoffice</C.ReportsLabel>
                      <Autocomplete
                        options={backoffices}
                        fullWidth
                        noOptionsText="Nenhum backoffice encontrado"
                        getOptionLabel={(option: any) => option.nome}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            placeholder="Todos os backoffices"
                            inputProps={{
                              ...params.inputProps,
                              "aria-label": "Without label",
                            }}
                          />
                        )}
                        defaultValue={null}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                      />
                    </C.SelectArea>
                    <C.SelectArea>
                      <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
                      <D.DateTextfield
                        type="date"
                        label="Data início"
                        fullWidth
                      />
                    </C.SelectArea>
                    <C.SelectArea>
                      <C.ReportsLabel>Data da ultima movimentação</C.ReportsLabel>
                      <D.DateTextfield
                        type="date"
                        label="Data fim"
                        fullWidth
                      />
                    </C.SelectArea>
                  </C.ReportsArea>
                  <C.ExportButton
                    onClick={geraRelatorio}
                    variant="contained"
                    startIcon={
                      <img
                        style={{width: "20px", color: "white", fill: "white"}}
                        src={ExcelIcon}
                        alt="excelIcon"
                      />
                    }
                  >
                    Gerar relatório
                  </C.ExportButton>
                </D.DataArea>
              </ListView>
            </C.Container>
          )
        ) : null
      }
    </>
  )
    ;
};

export default BuscaFatura;