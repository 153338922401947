import { Autocomplete, Chip, TextField } from "@mui/material";
import RegisterCardButton from "../RegisterCardButton/RegisterCardButton";
import { EnumTipoChavePix } from "../../enums/EnumTipoChavePix.enum";
import { FWStack, MWDiv, Subtitle } from "./style";
import { ICaptador } from "../../models/Captador";
import { listaBancos } from "../../utils/Bancos";
import { useMemo, useState } from "react";
import {
  IUpdateDadosContrato,
  IUpdateDadosDeposito,
  IUpdateDadosPix,
} from "../../models/DadosBancario";
import MaskDefaultInput from "../MasDefaultinput/MaskDefaultInput";
import { numberForExtensive } from "../../utils/moneyFormatter";
import DataInput from "../DataInput/DataInput";
import {
  UpdateDadosContratoValidate,
  UpdateDadosDepositoValidate,
  UpdateDadosPixValidate,
} from "./ComissaoDadosPagamentoValidate";
import {
  aplicarMascaraChavePix,
  obterTamanhoMaximoCampoChavePix,
} from "../../utils/Utils";
import useValidate from "../../hooks/useValidate";

interface IComissaoDadosPagamentoProps {
  captador: ICaptador;
  updateDadosPagamento: (
    captadorId: string,
    dadosDeposito: IUpdateDadosDeposito,
    dadosPix: IUpdateDadosPix,
    dadosContrato: IUpdateDadosContrato
  ) => Promise<boolean>;
  disabled?: boolean;
}

const ComissaoDadosPagamento: React.FC<IComissaoDadosPagamentoProps> = ({
  captador,
  updateDadosPagamento,
  disabled = false,
}) => {
  const [dadosBancario, setDadosBancario] = useState<IUpdateDadosDeposito>({
    banco: captador.banco ?? "",
    agencia: captador.agencia ?? "",
    conta: captador.conta ?? "",
    cnpjFavorecido: captador.cnpjFavorecido ?? "",
    favorecido: captador.favorecido ?? "",
  });

  const [dadosContrato, setDadosContrato] = useState<IUpdateDadosContrato>({
    percentualComissao: captador.percentualComissao ?? 0,
    vigenciaContrato:
      captador.vigenciaContrato == null
        ? null
        : new Date(captador.vigenciaContrato),
  });

  const [dadosPix, setDadosPix] = useState<IUpdateDadosPix>({
    tipoChavePix: captador.tipoChavePix,
    chavePix: captador.chavePix ?? "",
  });

  const [editar, setEditar] = useState<boolean>(false);
  const [salvando, setSalvando] = useState<boolean>(false);

  const updateDadosContratoValidate = useValidate(UpdateDadosContratoValidate);
  const updateDadosDepositoValidate = useValidate(UpdateDadosDepositoValidate);
  const updateDadosPixValidate = useValidate(UpdateDadosPixValidate);

  const bancos = useMemo(() => listaBancos.map((x) => x.Nome), []);

  const verificarErro = () => {
    const errosDadosContratoValidate =
      updateDadosContratoValidate.executeErrorMessage(dadosContrato);
    const errosDadosDepositoValidate =
      updateDadosDepositoValidate.executeErrorMessage(dadosBancario);
    const errosDadosPixValidate =
      updateDadosPixValidate.executeErrorMessage(dadosPix);

    return (
      errosDadosContratoValidate ||
      errosDadosDepositoValidate ||
      errosDadosPixValidate
    );
  };

  const contemErrosCampo =
    updateDadosContratoValidate.error ||
    updateDadosDepositoValidate.error ||
    updateDadosPixValidate.error;

  const handleSalvar = async () => {
    if (!verificarErro()) {
      setSalvando(true);
      if (captador.id) {
        const res = await updateDadosPagamento(
          captador.id,
          dadosBancario,
          dadosPix,
          dadosContrato
        );
        if (res) setEditar(false);
      }
      setSalvando(false);
    }
  };

  return (
    <RegisterCardButton
      title="Comissão e dados para pagamentos"
      onClickEditar={() => setEditar(true)}
      onClickSalvar={handleSalvar}
      desativarBotaoEditar={editar || salvando || disabled}
      desativarBotaoSalvar={!editar || salvando || disabled || contemErrosCampo}
    >
      <FWStack direction={"row"} spacing={2}>
        <TextField
          required
          value={dadosContrato.percentualComissao || ""}
          label="Porcentual de comissão"
          disabled={!editar || disabled}
          fullWidth
          onChange={(e) => {
            setDadosContrato({
              ...dadosContrato,
              percentualComissao: Number(e.target.value),
            });
          }}
          placeholder="0"
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          {...updateDadosContratoValidate.addValidateCampo(
            "percentualComissao",
            dadosContrato
          )}
        />
        <TextField
          value={`${numberForExtensive(
            dadosContrato.percentualComissao
          )} por cento`}
          label="Comissão por extenso"
          disabled
          fullWidth
        />
        <DataInput
          required
          value={dadosContrato.vigenciaContrato}
          label="Vigência do contrato"
          disabled={!editar || disabled}
          onChange={(x) =>
            setDadosContrato({ ...dadosContrato, vigenciaContrato: x })
          }
          {...updateDadosContratoValidate.addValidateCampo(
            "vigenciaContrato",
            dadosContrato
          )}
        />
      </FWStack>
      <FWStack direction={"row"} spacing={2}>
        <Subtitle variant="h4">Dados para pagamento de comissão*</Subtitle>
      </FWStack>
      <FWStack direction={"row"} spacing={16}>
        <MWDiv>
          <Subtitle>Dados bancários*</Subtitle>
          <FWStack direction={"row"} spacing={2}>
            <Autocomplete
              freeSolo
              fullWidth
              options={bancos}
              value={dadosBancario.banco}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Banco"
                  {...updateDadosDepositoValidate.addValidateCampo(
                    "banco",
                    dadosBancario
                  )}
                />
              )}
              onChange={(_, x) =>
                setDadosBancario({ ...dadosBancario, banco: x ?? "" })
              }
              disabled={!editar || disabled}
            />
            <TextField
              required
              value={dadosBancario.agencia}
              label="Agência"
              disabled={!editar || disabled}
              fullWidth
              onChange={(x) =>
                setDadosBancario({ ...dadosBancario, agencia: x.target.value })
              }
              {...updateDadosDepositoValidate.addValidateCampo(
                "agencia",
                dadosBancario
              )}
            />
          </FWStack>
          <FWStack direction={"row"} spacing={2}>
            <TextField
              required
              value={dadosBancario.conta}
              label="Conta"
              disabled={!editar || disabled}
              fullWidth
              onChange={(x) =>
                setDadosBancario({ ...dadosBancario, conta: x.target.value })
              }
              {...updateDadosDepositoValidate.addValidateCampo(
                "conta",
                dadosBancario
              )}
            />
            <MaskDefaultInput
              value={dadosBancario.cnpjFavorecido}
              label="CNPJ do favorecido"
              type="CNPJ"
              disabled={!editar || disabled}
              onChange={(x) =>
                setDadosBancario({
                  ...dadosBancario,
                  cnpjFavorecido: x as string,
                })
              }
              {...updateDadosDepositoValidate.addValidateCampo(
                "cnpjFavorecido",
                dadosBancario
              )}
            />
          </FWStack>
          <FWStack direction={"row"} spacing={2}>
            <TextField
              required
              value={dadosBancario.favorecido}
              label="Favorecido"
              disabled={!editar || disabled}
              fullWidth
              onChange={(x) =>
                setDadosBancario({
                  ...dadosBancario,
                  favorecido: x.target.value,
                })
              }
              {...updateDadosDepositoValidate.addValidateCampo(
                "favorecido",
                dadosBancario
              )}
            />
          </FWStack>
        </MWDiv>
        <MWDiv>
          <Subtitle>Pix</Subtitle>
          <FWStack direction={"row"} spacing={2}>
            {Object.values(EnumTipoChavePix).map((tipoChave) => (
              <Chip
                label={tipoChave}
                key={tipoChave}
                color={
                  dadosPix.tipoChavePix === tipoChave ? "primary" : "default"
                }
                onClick={() => {
                  if (!editar || disabled) return;
                  setDadosPix({
                    ...dadosPix,
                    chavePix: "",
                    tipoChavePix:
                      dadosPix.tipoChavePix === tipoChave ? null : tipoChave,
                  });
                  updateDadosDepositoValidate.setHelperText({});
                }}
                disabled={!editar || disabled}
              />
            ))}
          </FWStack>
          <FWStack direction={"row"} spacing={2}>
            <TextField
              required={Boolean(dadosPix.tipoChavePix)}
              value={aplicarMascaraChavePix(
                dadosPix.chavePix,
                dadosPix.tipoChavePix
              )}
              label="Chave PIX"
              disabled={!editar || disabled || !dadosPix.tipoChavePix}
              fullWidth
              inputProps={{
                maxLength: obterTamanhoMaximoCampoChavePix(
                  dadosPix.tipoChavePix
                ),
              }}
              onChange={(x) =>
                setDadosPix({
                  ...dadosPix,
                  chavePix:
                    dadosPix.tipoChavePix === EnumTipoChavePix.aleatoria
                      ? x.target.value.toLowerCase()
                      : x.target.value,
                })
              }
              {...updateDadosPixValidate.addValidateCampo("chavePix", dadosPix)}
            />
          </FWStack>
        </MWDiv>
      </FWStack>
    </RegisterCardButton>
  );
};

export default ComissaoDadosPagamento;
