import { CircularProgress } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useAuth } from "../../../hooks/useAuth";
import * as services from "../../../services/api/CaptadorService";
import DadosContrato from "./components/DadosContrato";
import * as C from "./style";
import ResponsaveisLegais from "./components/ResponsaveisLegais";
import { ICaptador } from "../../../models/Captador";
import ComissaoDadosPagamento from "./components/ComissaoDadosPagamento";
import StatusContrato from "./components/StatusContrato";
import AnexoLogoParceiro from "./components/AnexoLogoParceiro";
import DadosAcessoParceiro from "./components/DadosAcessoParceiro";
import InformacoesParceiro from "./components/InformacoesParceiro";
import ConfigCaptadorParceiro from "./components/ConfigCaptador";
import { ObterEnum } from "../../../utils/Utils";
import { EnumEstadoCivil } from "../../../enums/EnumEstadoCivil.enum";
import { EnumCupomBoasVindas } from "../../../enums/EnumCupomBoasVindas.enum";
import { EnumTipoChavePix } from "../../../enums/EnumTipoChavePix.enum";
import { EnumConvenio } from "../../../enums/EnumConvenio.enum";
import { EnumTipoParceiro } from "../../../enums/EnumTipoParceiro.enum";
import { EnumStatusContratoParceiro } from "../../../enums/EnumStatusContratoParceiro.enum";

const MeuCadastroParceiro = () => {
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const [captador, setCaptador] = useState<ICaptador>();

  useEffect(() => {
    if (user) {
      services
        .GetById(user.idCaptador)
        .then(async (response) => {
          const novoCaptador: ICaptador = {
            ...response.data,
            responsaveisLegais: response.data.responsaveisLegais.map((x) => ({
              ...x,
              dataNascimento: x.dataNascimento
                ? new Date(x.dataNascimento)
                : null,
              enumEstadoCivil: ObterEnum(EnumEstadoCivil, x.enumEstadoCivil),
            })),
            cupomBoasVindas: ObterEnum(
              EnumCupomBoasVindas,
              response.data.cupomBoasVindas
            ),
            tipoChavePix: ObterEnum(
              EnumTipoChavePix,
              response.data.cupomBoasVindas
            ),
            enumConvenio: ObterEnum(EnumConvenio, response.data.enumConvenio),
            tipoParceiro:
              ObterEnum(EnumTipoParceiro, response.data.tipoParceiro) ??
              EnumTipoParceiro.associado,
            statusContrato: ObterEnum(
              EnumStatusContratoParceiro,
              response.data.statusContrato
            ),
          };

          await setCaptador(novoCaptador);
          setLoading(false);
        })
        .catch((e: AxiosError) => {
          var errorMessage: string = e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os dados.";

          Swal.fire({
            position: "top-end",
            toast: true,
            icon: "warning",
            title: errorMessage,
            showConfirmButton: false,
            showCloseButton: true,
            timer: 3000,
          });
        });
    }
  }, [user]);

  return loading ? (
    <C.LoadContainer>
      <CircularProgress />
    </C.LoadContainer>
  ) : (
    <C.Container>
      {captador && (
        <>
          <DadosContrato captador={captador} />
          <ResponsaveisLegais captador={captador} />
          <ComissaoDadosPagamento captador={captador} />
          <InformacoesParceiro captador={captador} />
          <StatusContrato captador={captador} />
          <AnexoLogoParceiro captador={captador} />
          <DadosAcessoParceiro captador={captador} />
          <ConfigCaptadorParceiro captador={captador} />
        </>
      )}
    </C.Container>
  );
};

export default MeuCadastroParceiro;
