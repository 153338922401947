import { Card, styled } from "@mui/material";

interface ArchiveAreaProps {
  disabled?: boolean;
}

export const ArchiveArea = styled(Card)<ArchiveAreaProps>(({ disabled }) => ({
  borderRadius: "5px",
  background: "#f4f4f4",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  aspectRatio: "1/1",
  cursor: disabled ? "default" : "pointer",
  transition: "box-shadow 0.3s ease-in-out, background 0.3s ease-in-out",
  "&:hover": {
    background: disabled ? "#f4f4f4" : "#e0e0e0",
  },
  "& img": { width: "50%" },
  "& p": { margin: 0, color: "#262626", fontSize: "14px", fontWeight: 500 },
}));
