import { INovoPagamento } from "../../../../../models/OrdemPagamento";
import { AbstractValidation, Validate } from "../../../../../utils/AbstractValidation";

export class ContaValidate extends AbstractValidation<INovoPagamento>{
    getValidate(): Validate<INovoPagamento>[] {
        return [
            {
                field:"data",
                validate(value, model, addError) {
                    if(!value) addError("A Data precisa ser selecionada")
                },
            },
            {
                field: "tipoPagamento",
                validate(value, model, addError) {
                    if(value === null) addError("O Tipo de Pagamento precisa ser selecionada")
                },
            },
            {
                field: "valor",
                validate(value, model, addError) {
                    if(!value) addError("O Valor precisa ser preenchido")
                },
            }
        ];
    }

}