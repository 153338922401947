import RegisterCard from "../../../../../components/RegisterCard";
import * as C from "../../../Cliente/Cadastro/style";
import * as D from "../../../../../styles/appComponents";
import {Stack, TextField} from "@mui/material";
import React, {useState} from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

export interface IFormConselhoFiscal {
  nome: string;
  cpf: string;
}

type PropsConselhoFiscal = {
  onSave: (data: IFormConselhoFiscal[]) => void;
  onUpdate: (data: IFormConselhoFiscal[]) => void;
  onChange: (data: IFormConselhoFiscal[]) => void;
  data?: IFormConselhoFiscal[] | null;
};

enum FormConselhoFiscalActions {
  UPDATE_CONSELHO = "UPDATE_CONSELHO",
  ADD_CONSELHO = "ADD_CONSELHO",
  REMOVE_CONSELHO = "REMOVE_CONSELHO",
}

const initialConselho = { nome: "", cpf: "" };

export const ConselhoFiscal = (props: PropsConselhoFiscal) => {
  const sendOnSave = (): void => props.onSave(conselhos);
  const sendOnUpdate = (): void => props.onUpdate(conselhos);

  const [conselhos, setConselhos] = useState<IFormConselhoFiscal[]>(
    props.data ? props.data : [initialConselho]
  );

  const handleInputChange =
    (index: number, field: keyof IFormConselhoFiscal) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedConselhos = [...conselhos];
      updatedConselhos[index] = {
        ...updatedConselhos[index],
        [field]: event.target.value,
      };
      setConselhos(updatedConselhos);
      props.onChange(updatedConselhos);
    };

  const addConselho = () => {
    setConselhos([...conselhos, { nome: "", cpf: "" }]);
  };

  const removeConselho = (index: number) => {
    const updatedConselhos = conselhos.filter((_, i) => i !== index);
    setConselhos(updatedConselhos);
    props.onChange(updatedConselhos);
  };

  return (
    <RegisterCard title="Conselho Fiscal">
      {conselhos.map((conselho, index) => (
        <Stack key={index} spacing={2}>
          <Stack direction={"row"} spacing={2}>
            <TextField
              label={`Nome conselho fiscal ${index + 1}`}
              required
              fullWidth
              value={conselho.nome}
              onChange={handleInputChange(index, "nome")}
            />
            <TextField
              label={`CPF conselho fiscal ${index + 1}`}
              required
              fullWidth
              value={conselho.cpf}
              onChange={handleInputChange(index, "cpf")}
            />
          </Stack>

          {conselhos.length > 1 && (
            <Stack direction={"row"} justifyContent="flex-end">
              <D.ContainedButton
                startIcon={<RemoveCircleIcon />}
                onClick={() => removeConselho(index)}
                $color="blue"
              >
                Remover conselho fiscal
              </D.ContainedButton>
            </Stack>
          )}
        </Stack>
      ))}

      <C.FWStack
        direction={"row"}
        spacing={2}
        justifyContent="flex-end"
        marginTop={2}
      >
        <D.ContainedButton
          startIcon={<AddCircleIcon />}
          onClick={addConselho}
          $color="lightpink"
        >
          Adicionar outro conselho fiscal
        </D.ContainedButton>
      </C.FWStack>
    </RegisterCard>
  );
};
