import { useCallback, useMemo, useState } from "react";
import { ClasseConstrutor } from "../types/ClasseConstrutor";
import { AbstractValidation, ErrorMessage } from "../utils/AbstractValidation";
import { addValidate, AddValidateReturn } from "../utils/AddValidate";
import ReadonlyArray from "../types/ReadonlyArray";
import { HelperText } from "../types/HelperText";

interface IUseValidateArrayHook<T> {
  validate: AbstractValidation<T>;
  setHelperTextArray: React.Dispatch<React.SetStateAction<HelperText<T>[]>>;
  addValidateCampo: (
    index: number,
    campo: keyof T,
    dados: ReadonlyArray<T>
  ) => AddValidateReturn | {};
  helperTextArray: HelperText<T>[];
  error: boolean;
  executeErrorMessage: (dados: ReadonlyArray<T>) => boolean;
  addHelperText: (inicialValor?: HelperText<T>) => void;
  removerHelperText: (index: number) => void;
  limparHelperText: (index: number) => void;
}

const useValidateArray = <T extends Object>(
  c: ClasseConstrutor<AbstractValidation<T>>,
  inicialValor: Partial<Record<keyof T, string | null>>[] = [{}]
): IUseValidateArrayHook<T> => {
  const [helperTextArray, setHelperTextArray] =
    useState<Partial<Record<keyof T, string | null>>[]>(inicialValor);

  const validate = useMemo(() => new c(), [c]);

  const error = useMemo(
    () =>
      helperTextArray.some((helperText) =>
        Object.values(helperText).some((x) => x !== null)
      ),
    [helperTextArray]
  );

  const addValidateCampo = useCallback(
    (index: number, campo: keyof T, dados: ReadonlyArray<T>) => {
      if (index >= helperTextArray.length) return {};

      const setHelperTextIndex = (novoHelperText: HelperText<T>) => {
        const novohelperTextArray = [...helperTextArray];
        novohelperTextArray[index] = novoHelperText;
        setHelperTextArray(novohelperTextArray);
      };

      return addValidate(
        validate,
        campo,
        dados[index],
        helperTextArray[index],
        setHelperTextIndex
      );
    },
    [validate, helperTextArray]
  );

  const executeErrorMessage = useCallback(
    (dados: ReadonlyArray<T>) => {
      const buscarErros = (erros: ErrorMessage<T>[], key: string) =>
        erros.find((x) => x.ref === key)?.message ?? null;

      let temErros = false;

      const newHelperTextArray = dados.map((d) => {
        const erros = validate.errorMessage(d);
        if (erros === null) return {};

        temErros = true;

        const helperText = Object.fromEntries(
          Object.keys(d).map((key) => [key, buscarErros(erros, key)])
        ) as Partial<Record<keyof T, string | null>>;

        return helperText;
      });

      setHelperTextArray(newHelperTextArray);
      return temErros;
    },
    [validate]
  );

  const addHelperText = useCallback(
    (inicialValor?: Partial<Record<keyof T, string | null>>) => {
      inicialValor ??= {};
      setHelperTextArray([...helperTextArray, inicialValor]);
    },
    [helperTextArray]
  );

  const removerHelperText = useCallback(
    (index: number) => {
      const novohelperTextArray = helperTextArray.filter((_, i) => i !== index);
      setHelperTextArray(novohelperTextArray);
    },
    [helperTextArray]
  );

  const limparHelperText = useCallback(
    (index: number) => {
      const novohelperTextArray = [...helperTextArray];
      novohelperTextArray[index] = {};
      setHelperTextArray(novohelperTextArray);
    },
    [helperTextArray]
  );

  return {
    validate,
    setHelperTextArray,
    addValidateCampo,
    helperTextArray,
    error,
    executeErrorMessage,
    addHelperText,
    removerHelperText,
    limparHelperText,
  };
};

export default useValidateArray;
