import { Modal } from "@mui/material";
import * as C from "../../style";
import CloseIcon from "@mui/icons-material/Close";
import { FC } from "react";
import InserirNovoPagamento from "../../../Components/InserirNovoPagamento";
import { ErrorMessage } from "../../../../../../utils/AbstractValidation";
import { IContaOrdemPagamento } from "../../../../../../models/Conta";
import { INovoPagamento, IStatusOrdemPagamento } from "../../../../../../models/OrdemPagamento";
import TotalCard from "../../../../../../components/TotalCard";
import { moneyFormatter } from "../../../../../../utils/moneyFormatter";
import { KWH } from "../../../../../../utils/masInputs";

interface NewPaymentModalProps {
    openModal: boolean;
    onCloseModal: () => void;
    onSave: () => void;
    onChange: (novoPagamento: INovoPagamento) => void;
    fieldErros: ErrorMessage[] | null;
    id: string | null;
    ordemPagamento: IContaOrdemPagamento;
    statusOrdemPagamento: IStatusOrdemPagamento;
}

export const NewPaymentModal: FC<NewPaymentModalProps> = (
    {
        openModal,
        onCloseModal,
        onSave,
        onChange,
        fieldErros,
        id,
        ordemPagamento,
        statusOrdemPagamento
    }
) => {
    return (
        <>
            <Modal
                sx={{ width: "100%", zIndex: 10 }}
                open={openModal}
                onClose={onCloseModal}
                aria-labelledby="modal-details"
                aria-describedby="modal-details"
            >
                <C.ModalContainer>
                    <C.ModalArea height="95%" width="90%">
                        <C.ModalCard>
                            <C.ModalHeader>
                                <C.CloseButton
                                    aria-label="close"
                                    onClick={onCloseModal}
                                >
                                    <CloseIcon />
                                </C.CloseButton>
                            </C.ModalHeader>
                            <C.StatusArea>
                                <C.StatusWrapper>
                                    <TotalCard
                                        color="blue"
                                        title="Valor total"
                                        text={moneyFormatter.format(statusOrdemPagamento.valorTotal)}
                                        evolutionNumber="-8%"
                                        evolution={false}
                                    />
                                    <TotalCard
                                        color="blue"
                                        title="Valor total de kwh"
                                        text={`${new KWH().execute(statusOrdemPagamento?.valorTotalKwh as any)} KWH`}
                                        evolutionNumber="-8%"
                                        evolution={false}
                                    />
                                </C.StatusWrapper>
                                <C.StatusWrapper>
                                    <TotalCard
                                        color="green"
                                        title="Valor quitado"
                                        text={moneyFormatter.format(
                                            statusOrdemPagamento.valorTotalQuitado
                                        )}
                                        evolutionNumber="-8%"
                                        evolution={false}
                                    />
                                    <TotalCard
                                        color="green"
                                        title="Energia quitada"
                                        text={`${new KWH().execute(statusOrdemPagamento?.valorTotalKwhQuitado as any)} KWH`}
                                        evolutionNumber="-8%"
                                        evolution={false}
                                    />
                                </C.StatusWrapper>
                                <C.StatusWrapper>
                                    <TotalCard
                                        color="yellow"
                                        title="A pagar"
                                        text={moneyFormatter.format(
                                            statusOrdemPagamento.valorTotalAPagar
                                        )}
                                        evolutionNumber="-8%"
                                        evolution={false}
                                    />
                                    <TotalCard
                                        color="yellow"
                                        title="Energia a pagar"
                                        text={`${new KWH().execute(statusOrdemPagamento?.valorTotalKwhAPagar as any)} KWH`}
                                        evolutionNumber="-8%"
                                        evolution={false}
                                    />
                                </C.StatusWrapper>
                            </C.StatusArea>
                            <InserirNovoPagamento
                                callbackExit={() => { }}
                                onSave={onSave}
                                onChange={onChange}
                                fieldErros={fieldErros}
                                id={id}
                                ordemPagamento={ordemPagamento}
                            />
                        </C.ModalCard>
                    </C.ModalArea>
                </C.ModalContainer>
            </Modal>
        </>
    );
}