import { StatusBuilder } from "../models/StatusBuilder";

export enum EnumStatusOrdemPagamento {
    AguardandoPagamento = 0,
    Quitada = 1,
    fechado = 2
}

export enum EnumStatusOrdemPagamentoMapSelect {
    AguardandoPagamento = "Aguardando pagamento",
    Quitada = "Quitada",
    fechado = "Fechado"
}

export const EnumStatusOrdemPagamentoNumericMap = {
    [EnumStatusOrdemPagamento.AguardandoPagamento]: 0,
    [EnumStatusOrdemPagamento.Quitada]: 1,
    [EnumStatusOrdemPagamento.fechado]: 2
}

export const enumStatusOrdemPagamentoMap:StatusBuilder[] = [
    {
        name:"Aguardando pagamento",
        value: EnumStatusOrdemPagamento.AguardandoPagamento,
        color: "yellow",
        colorText: "green",
        titleSecondary: "Valor total",
        typeNumberFormatter: "$",
        titleThird: "Energia total injetada",
        typeNumberFormatterThird: "KWH",
        primary: true
    },
    {
        name:"Quitada",
        value: EnumStatusOrdemPagamento.Quitada,
        color: "green",
        colorText: "blue",
        titleSecondary: "Valor total",
        typeNumberFormatter: "$",
        titleThird: "Valor total de kwh",
        typeNumberFormatterThird: "KWH",
        primary: true
    },
    {
        name:"Fechado",
        value: EnumStatusOrdemPagamento.fechado,
        color: "blue",
        colorText: "blue",
        titleSecondary: "Valor total",
        typeNumberFormatter: "$",
        titleThird: "Valor total de kwh",
        typeNumberFormatterThird: "KWH",
        primary: true
    }
]