import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import DadosPagador, {IDadosPagador} from "./components/DadosPagador";
import DetalhesFatura, {IDetalhesFatura} from "./components/DetalhesFatura";
import DetalhesGd, {IDetalhesGd} from "./components/DetalhesGd";
import TipoFaturamento, {ITipoFaturamento} from "./components/TipoFaturamento";
import {AxiosError, AxiosResponse} from "axios";
import * as buscaFaturaService from "../../../../services/api/BuscaFaturaService";
import {toastMessage} from "../../../../utils/toastMessage";
import DetalhesPagamento, {IDetalhesPagamento} from "./components/DetalhesPagamento";
import DetalhesQuitacao, {IDetalhessQuitacao} from "./components/DetalhesQuitacao";
import EconomiaImpostos, {IEconomiaImpostos} from "./components/EconomiaImpostos";
import {EnumTipoDocumento} from "../../../../enums/EnumTipoDocumento.enum";
import {SkeletonCreationCards} from "../../../../components/SkeletonLoads/CreationCards";

export interface IEditarFatura {
  celular: string | null;
  email: string | null;
  cpfcnpj: string | null;
  instalacao: string | null;
  contratoId: string | null;
  cep: string | null;
  uf: string | null;
  cidade: string | null;
  bairro: string | null;
  numero: string | null;
  complemento: string | null;
  logradouro: string | null;
  quantidadeCompensada: string | null;
  quantidadeRecebimento: string | null;
  quantidadeSaldoAtual: string | null;
  energiaInjetada: string | null;
  valorFaturaCooperativa: string | null;
  valorGd: string | null;
  cupomAplicado: string | null;
  valorFaturaLiquidada: string | null;
  status: string | null;
  split: string | null;
  vencimento: string | null;
  juros: string | null;
  multa: string | null;
  valorPago: string | null;
  vencimentoDetalhesPagamento: string | null;
  valorGdDetalhesGd: string | null;
  recebedor: string | null;
  splitFalhou: string | null;
  statusDetalhesGd: string | null;
  tipo: string | null;
}

const EditarFatura: () => React.JSX.Element = (): React.JSX.Element => {
  //region Variaveis
  const {faturaId} = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [dadosPagador, setDadosPagador] = useState<IDadosPagador>({
    bairro: null,
    celular: null,
    cep: null,
    cidade: null,
    complemento: null,
    contratoId: null,
    cpfcnpj: null,
    email: null,
    instalacao: null,
    logradouro: null,
    numero: null,
    uf: null
  });
  const [economiaImmpostos, setEconomiaImmpostos] = useState<IEconomiaImpostos>({
    economia: null,
    economiaLiquida: null,
    tributo: null
  });
  const [detalhesFatura, setDetalhesFatura] = useState<IDetalhesFatura>({
    cupomAplicado: null,
    energiaInjetada: null,
    quantidadeCompensada: null,
    quantidadeRecebimento: null,
    quantidadeSaldoAtual: null,
    split: null,
    status: null,
    valorFaturaCooperativa: null,
    valorFaturaLiquidada: null,
    valorGd: null,
    vencimento: null
  });
  const [detalhesDoPagamento, setDetalhesDoPagamento] = useState<IDetalhesPagamento>({
    vencimentoDetalhesPagamento: null,
    juros: null,
    multa: null,
    valorPago: null,
  });
  const [detalhesGd, setDetalhesGd] = useState<IDetalhesGd>({
    recebedor: null,
    splitFalhou: null,
    statusDetalhesGd: null,
    valorGdDetalhesGd: null
  });
  const [dadosQuitacao, setDadosQuitacao] = useState<IDetalhessQuitacao>({
    dataRecebimento: null,
    finalizacao: null,
    valor: null
  })
  const [tipoFaturamento, setTipoFaturamento] = useState<ITipoFaturamento>({
    tipo: null
  })
  const [editarFatura, setEditarFatura] = useState<IEditarFatura>({
    bairro: null,
    celular: null,
    cep: null,
    cidade: null,
    complemento: null,
    contratoId: null,
    cpfcnpj: null,
    cupomAplicado: null,
    email: null,
    energiaInjetada: null,
    instalacao: null,
    juros: null,
    logradouro: null,
    multa: null,
    numero: null,
    quantidadeCompensada: null,
    quantidadeRecebimento: null,
    quantidadeSaldoAtual: null,
    recebedor: null,
    split: null,
    splitFalhou: null,
    status: null,
    statusDetalhesGd: null,
    tipo: null,
    uf: null,
    valorFaturaCooperativa: null,
    valorFaturaLiquidada: null,
    valorGd: null,
    valorGdDetalhesGd: null,
    valorPago: null,
    vencimento: null,
    vencimentoDetalhesPagamento: null
  })
  //endregion

  //region Services
  useEffect(() => {
    if (!faturaId) return;

    (async () => {
      try {
        const response: AxiosResponse<IEditarFatura> =
          await buscaFaturaService.GetBuscaFaturaById(faturaId);
        setEditarFatura(response.data);
      } catch (e) {
        const error = e as AxiosError;

        toastMessage("error", error.response
          ? String(error.response?.data)
          : "Houve um erro ao pegar os dados.");
      }
    })()
  }, [faturaId]);
  //endregion


  //region UI
  const handleCallbackDadosPagador: (data: IDadosPagador) => void = (data: IDadosPagador) => {
    setDadosPagador(data);
  }

  const handleCallbackEconomiaImpostos: (data: IEconomiaImpostos) => void = (data: IEconomiaImpostos) => {
    setEconomiaImmpostos(data);
  }

  const handleCallbackDetalhesFatura: (data: IDetalhesFatura) => void = (data: IDetalhesFatura) => {
    setDetalhesFatura(data)
  }

  const handleCallbackDetalhesPagamento: (data: IDetalhesPagamento) => void = (data: IDetalhesPagamento) => {
    setDetalhesDoPagamento(data);
  }

  const handleCallbackDadosQuitacao: (data: IDetalhessQuitacao) => void = (data: IDetalhessQuitacao) => {
    setDadosQuitacao(data)
  }

  const handleCallbackDetalhesGd: (data: IDetalhesGd) => void = (data: IDetalhesGd) => {
    setDetalhesGd(data)
  }

  const handleCallbackTipoFaturamento: (data: ITipoFaturamento) => void = (data: ITipoFaturamento) => {
    setTipoFaturamento(data);
  }

  useEffect(() => {
    setEditarFatura({
      ...editarFatura,
      ...dadosPagador,
      ...detalhesFatura,
      ...detalhesGd,
      ...dadosQuitacao,
      ...tipoFaturamento,
    })
  }, [dadosPagador,
    economiaImmpostos,
    detalhesFatura,
    detalhesDoPagamento,
    detalhesGd,
    dadosQuitacao,
    tipoFaturamento]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);
  //endregion

  return loading ? <SkeletonCreationCards/> : (
    <>
      <DadosPagador type={EnumTipoDocumento.CNPJ} callback={handleCallbackDadosPagador}/>
      <DetalhesFatura callback={handleCallbackDetalhesFatura}/>
      <EconomiaImpostos callback={handleCallbackEconomiaImpostos}/>
      <DetalhesPagamento callback={handleCallbackDetalhesPagamento}/>
      <DetalhesQuitacao callback={handleCallbackDadosQuitacao}/>
      <DetalhesGd callback={handleCallbackDetalhesGd}/>
      <TipoFaturamento callback={handleCallbackTipoFaturamento}/>
    </>
  )
};

export default EditarFatura;