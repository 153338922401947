import * as G from "../../style";
import {TextField} from "@mui/material";
import * as D from "../../../../../../styles/appComponents";

import {useEffect, useState} from "react";
import {iDadosAcessoColaborador} from "../../../../../../models/Colaborador/cadastros/DadosAcesso";
import {ColaboradorByIdResponse} from "../../../../../../models/Colaborador/Colaborador";
import {ErrorMessage} from "../../../../../../utils/AbstractValidation";
import RegisterCard from "../../../../../../components/RegisterCard";
import {handleStyleFieldError} from "../../../../../../utils/verifyFieldError";
import {EnumTipoDocumento} from "../../../../../../enums/EnumTipoDocumento.enum";
import {DadosAcessoColaboradorEnum} from "../../../../../../enums/Gerador/EnumInputModel.ts/DadosAcessoEnum";
import MaskDefaultInput from "../../../../../../components/MasDefaultinput/MaskDefaultInput";

export interface PropsDadosAcesso {
    onSave: (data: iDadosAcessoColaborador) => void;
    onChange: (data: iDadosAcessoColaborador) => void;
    onUpdate: () => void;
    onReset: (usuarioId: string | null | undefined) => void;
    buttonSave: boolean;
    fieldErros: ErrorMessage[] | null;
    colaboradorId: string | undefined;
    colaborador: ColaboradorByIdResponse | undefined;
    load: { status: boolean, section: string };
}

export const DadosAcessoColaborador: React.FC<PropsDadosAcesso> = (
    {
        onSave,
        onUpdate,
        onChange,
        onReset,
        fieldErros,
        colaboradorId,
        colaborador,
        load,
        buttonSave,
    }
) => {
    const formDadosAcessoModel: iDadosAcessoColaborador = {
        nomedadosacesso: colaborador?.nome || null,
        email: colaborador?.emailParaContato || null,
        telefone: colaborador?.celularParaContato || null,
        countrycode: colaborador?.countryCode,
        login: colaborador?.documentos
        ?.find(x => x.tipoDocumento === EnumTipoDocumento.CPF)?.numero || null,
        whatsappdadosacesso: !colaborador?.whatsappDadosAcesso? colaborador?.celularParaContato: colaborador?.whatsappDadosAcesso,
        whatsappcountrycode: !colaborador?.countryCodeWhatsapp? colaborador?.countryCode: colaborador?.countryCodeWhatsapp
    };
    const [dadosAcesso, setDadosAcesso] = useState<iDadosAcessoColaborador>(formDadosAcessoModel);
    const sendOnSave = (): void => onSave(dadosAcesso);
    const sendOnUpdate = (): void => onUpdate();
    const sendOnChange = (update: iDadosAcessoColaborador): void => onChange(update);
    const sendOnReset = (usuarioId: string | null | undefined): void => onReset(usuarioId);

    useEffect(() => {
        if(colaboradorId){
            const formDadosAcessoModel: iDadosAcessoColaborador = {
                nomedadosacesso: colaborador?.nome || null,
                email: colaborador?.emailParaContato || null,
                telefone: colaborador?.celularParaContato || null,
                countrycode: colaborador?.countryCode,
                login: colaborador?.documentos
                ?.find(x => x.tipoDocumento === EnumTipoDocumento.CPF)?.numero || null,
                whatsappdadosacesso: !colaborador?.whatsappDadosAcesso? colaborador?.celularParaContato: colaborador?.whatsappDadosAcesso,
                whatsappcountrycode: !colaborador?.countryCodeWhatsapp? colaborador?.countryCode: colaborador?.countryCodeWhatsapp
            };

            setDadosAcesso(formDadosAcessoModel);
        }

    }, [colaboradorId, colaborador])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const name = event?.target?.name?.toLocaleLowerCase();
        const value = event?.target?.value;

        setDadosAcesso((state: iDadosAcessoColaborador) => {
            sendOnChange({ ...state, [name]: value });
            return { ...state, [name]: value };
        });
    };

    return (
    <RegisterCard title="Dados de acesso">
        <G.FWStack direction={"row"} spacing={2}>
          <TextField
            inputProps={{ readOnly: true }}
            sx={handleStyleFieldError(DadosAcessoColaboradorEnum.NOMEDADOSACESSO, fieldErros, { width:"100%" })}
            name={DadosAcessoColaboradorEnum.NOMEDADOSACESSO}
            onChange={handleInputChange}
            value={dadosAcesso?.nomedadosacesso ?? ""}
            label="Nome"
          />
          <MaskDefaultInput
            type="CELULAR"
            label="Celular"
            readonly={true}
            value={dadosAcesso?.telefone || ""}
            valueCountryCode={dadosAcesso?.countrycode ?? ""}
            sx={{ width:"100%" }}
        />
          <MaskDefaultInput
            type="CPF"
            label="Login"
            readonly={true}
            value={dadosAcesso?.login ?? ""}
            sx={{ width:"100%" }}

          />
        </G.FWStack>
        <G.FWStack direction={"row"} spacing={2}>
          <MaskDefaultInput
            type="CELULAR"
            label="whatsapp"
            readonly={buttonSave}
            value={dadosAcesso?.whatsappdadosacesso ?? ""}
            valueCountryCode={dadosAcesso?.whatsappcountrycode ?? ""}
            onChange={(value:any) =>
                {
                    handleInputChange(
                        { 
                            target:{ name:DadosAcessoColaboradorEnum.WHATSAPPDADOSACESSO, value:value?.value } 
                        } as React.ChangeEvent<HTMLInputElement>);

                    handleInputChange(
                        { 
                            target:{ name:DadosAcessoColaboradorEnum.WHATSAPPCOUNTRYCODE, value:value?.zipCode } 
                        } as React.ChangeEvent<HTMLInputElement>);
                }

            }
            sx={{ width:"48%" }}
        />
        </G.FWStack>
        <G.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton
            disabled={buttonSave}
            $color="lightpink"
            onClick={() => sendOnReset(colaborador?.usuarioId)}
          >
            Reset Senha
          </D.ContainedButton>
          <D.ContainedButton
            disabled={!buttonSave}
            onClick={sendOnUpdate}
            $color="yellow"
          >
            Editar
          </D.ContainedButton>
          <D.ContainedButton disabled={buttonSave} onClick={sendOnSave}>
            Salvar
          </D.ContainedButton>
        </G.FWStack>
      </RegisterCard>
    )
}