import { StatusBuilder } from "../../models/StatusBuilder";

export enum EnumStatusContratoGerador{
    aguardandoAssinatura = 0,
    contratoAssinado = 1,
    emProcessoTrocaTitularidade = 2,
    mudancaTitularidadeConcluida = 3,
    conectado = 4,
    desconexaoSolicitada = 5,
    processoJudicial = 6,
    aguardandoAssinaturaDistrato = 7,
    cancelado = 8,
    desconectado = 9
}

export const enumStatusContratoGeradorMap:StatusBuilder[] = [
    {
        name:"Aguardando assinatura",
        value: EnumStatusContratoGerador.aguardandoAssinatura,
        color: "yellow",
        titleSecondary: "Valor total dos contratos",
        typeNumberFormatter: "$",
        titleThird: "Valor total de kwh",
        typeNumberFormatterThird: "KWH",
        primary: true
    },
    {
        name:"Contrato assinado",
        value: EnumStatusContratoGerador.contratoAssinado,
        color: "blue",
        titleSecondary: "Valor total dos contratos",
        typeNumberFormatter: "$",
        titleThird: "Valor total de kwh",
        typeNumberFormatterThird: "KWH",
        primary: true
    },
    {
        name:"Em troca de Titularidade",
        value: EnumStatusContratoGerador.emProcessoTrocaTitularidade,
        color: "purple",
        titleSecondary: "Valor total dos contratos",
        typeNumberFormatter: "$",
        titleThird: "Valor total de kwh",
        typeNumberFormatterThird: "KWH",
        primary: true
    },
    {
        name:"Mudança de Titularidade concluída",
        value: EnumStatusContratoGerador.mudancaTitularidadeConcluida,
        color: "wine",
        titleSecondary: "Valor total dos contratos",
        typeNumberFormatter: "$",
        titleThird: "Valor total de kwh",
        typeNumberFormatterThird: "KWH",
        primary: false
    },
    {
        name:"Conectado",
        value: EnumStatusContratoGerador.conectado,
        color: "green",
        titleSecondary: "Valor total dos contratos",
        typeNumberFormatter: "$",
        titleThird: "Valor total de kwh",
        typeNumberFormatterThird: "KWH",
        primary: true
    },
    {
        name:"Aguardando assinatura de distrato",
        value: EnumStatusContratoGerador.aguardandoAssinaturaDistrato,
        color: "red",
        titleSecondary: "Valor total dos contratos",
        typeNumberFormatter: "$",
        titleThird: "Valor total de kwh",
        typeNumberFormatterThird: "KWH",
        primary: true
    },
    {
        name:"Desconexão Solicitada",
        value: EnumStatusContratoGerador.desconexaoSolicitada,
        color: "deepPink",
        titleSecondary: "Valor total dos contratos",
        typeNumberFormatter: "$",
        titleThird: "Valor total de kwh",
        typeNumberFormatterThird: "KWH",
        primary: false
    },
    {
        name:"Processo judicial",
        value: EnumStatusContratoGerador.processoJudicial,
        color: "midnight",
        titleSecondary: "Valor total dos contratos",
        typeNumberFormatter: "$",
        titleThird: "Valor total de kwh",
        typeNumberFormatterThird: "KWH",
        primary: false
    },
    {
        name:"Cancelado",
        value: EnumStatusContratoGerador.cancelado,
        color: "orange",
        titleSecondary: "Valor total dos contratos",
        typeNumberFormatter: "$",
        titleThird: "Valor total de kwh",
        typeNumberFormatterThird: "KWH",
        primary: false
    },
    {
        name:"Desconectado",
        value: EnumStatusContratoGerador.desconectado,
        color: "dimGray",
        titleSecondary: "Valor total dos contratos",
        typeNumberFormatter: "$",
        titleThird: "Valor total de kwh",
        typeNumberFormatterThird: "KWH",
        primary: false
    }
];