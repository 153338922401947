import { z } from "zod";
import { ListaTipoParceiro } from "./TipoParceiro";
import { ObterEnum } from "../utils/Utils";
import { EnumStatusContratoParceiro } from "../enums/EnumStatusContratoParceiro.enum";

export const CaptadorListItemTabelaSchema = z.object({
  captadorId: z.string(),
  idReferencial: z.number(),
  razaoSocial: z.string(),
  responsavelLegal: z.string(),
  celular: z.string(),
  prazoContratual: z
    .string()
    .nullable()
    .refine((x) => x === null || !isNaN(Date.parse(x)), {
      message: "Data inválida",
    })
    .transform((x) => (x === null ? null : new Date(x))),
  backOfficeNome: z.string(),
  tipoParceiro: z.enum(ListaTipoParceiro),
  statusContrato: z
    .number()
    .nullable()
    .refine(
      (x) => x === null || ObterEnum(EnumStatusContratoParceiro, x) !== null,
      {
        message: "Status do contrato invalido",
      }
    )
    .transform((x) => ObterEnum(EnumStatusContratoParceiro, x)),
});

export type CaptadorListItemTabela = z.output<
  typeof CaptadorListItemTabelaSchema
>;
