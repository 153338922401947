export enum NovoPagamentoEnum {
  DATA = "data",
  TIPOPAGAMENTO = "tipoPagamento",
  VALOR = "valor",
  ENERGIATOTALGD = "energiaTotalGd",
  ASSUNTO = "assunto",
  DESCRICAO = "descricao",
  COMPROVANTE = "comprovante",
  ID = "id",
  IDORDEMPAGAMENTO = "ordemPagamentoId",
}

export enum NovaOrdemEnum {
  MES = "mes",
  ANO = "ano",
  USINA = "usinaId",
  ENERGIAINJETADA = "energiaInjetada",
  ENERGIACONSUMIDA = "energiaConsumida",
  DIFERENCA = "diferenca",
  SALDO = "saldo",
  SALDOTOTAL = "saldoTotal",
  TARIFABASE = "tarifaBase",
  DATALIMITEQUITACAO = "dataLimiteQuitacao",
  TARIFADISTRIBUIDORA = "tarifaDistribuidora",
  DESCONTOTARIFA = "descontoTarifa",
  VALORBASE = "valorBase",
  VALORCREDITO = "valorCredito",
  TIPOCONEXAO = "tipoConexao",
  TARIFAFIOB = "tarifaFioB",
  COMPROVANTE = "comprovante",
  ACTIVE = "active"
}

export enum ReportOrdemPagamento {
  STATUSCONTRATO = "statusContrato",
  GERADOR = "gerador",
  DATAINICIAL = "dataInicial",
  DATAFINAL = "dataFinal",
}
