import { MenuItem, TextField } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import MaskDefaultInput from "../../../../../components/MasDefaultinput/MaskDefaultInput";
import RegisterCard from "../../../../../components/RegisterCard";
import {
  EnumEstadoCivil,
  EnumEstadoCivilNumericMap,
} from "../../../../../enums/EnumEstadoCivil.enum";
import { useObjectState } from "../../../../../hooks/useObjectState";
import {
  IFullGDContratoCliente,
  IInformacoesTitularPF,
  IInformacoesTitularPJ,
  IUpdateInformacoesTitularPF,
  IUpdateInformacoesTitularPJ,
} from "../../../../../models/GDContrato";
import { ICepReturn } from "../../../../../models/IbgeService";
import * as service from "../../../../../services/api/GDContratoClienteService";
import * as D from "../../../../../styles/appComponents";
import { convertEmptyStringsToNullAsync } from "../../../../../utils/convertEmptyStringsToNull";
import { FormatDate } from "../../../../../utils/dateFormatter";
import { toastMessage } from "../../../../../utils/toastMessage";
import { PessoaType } from "../../../../../types/PessoaType";
import { ICelularInputReturn } from "../../../../../models/Celular";

type PropsInformacoesTitularContratar = {
  id?: string;
  type: PessoaType;
  dataContrato?: DataInformacoesTitular;
  edit: boolean;
  callback?: (data: DataInformacoesTitularUpdate) => void;
};

export type DataInformacoesTitular = {
  informacoesTitularPF?: IInformacoesTitularPF;
  informacoesTitularPJ?: IInformacoesTitularPJ;
};

export type DataInformacoesTitularUpdate = {
  dataPF?: IUpdateInformacoesTitularPF;
  dataPJ?: IUpdateInformacoesTitularPJ;
};

const InformacoesTitularContratar = (
  props: PropsInformacoesTitularContratar
) => {
  //region Variáveis
  const { id, type, dataContrato } = props;
  const [editar, setEditar] = useState<boolean>(false);
  const {
    state: statePF,
    setObject: setObjectPF,
    updateObject: updateObjectPF,
  } = useObjectState<IUpdateInformacoesTitularPF>({
    id: id!,
    nome: "",
    cpf: "",
    enumEstadoCivil: undefined,
    nacionalidade: "",
    profissao: "",
    dataNascimento: "",
    countryCode: "+55",
  });
  const {
    state: statePJ,
    setObject: setObjectPJ,
    updateObject: updateObjectPJ,
  } = useObjectState<IUpdateInformacoesTitularPJ>({
    id: id!,
    bairro: "",
    cep: "",
    cidade: "",
    complemento: "",
    cnpj: "",
    logradouro: "",
    numero: "",
    uf: "",
    numInstalacao: "",
    razaoSocial: "",
  });
  //endregion

  //region Services
  const UpdatePF: () => Promise<void> = async () => {
    var data: IUpdateInformacoesTitularPF =
      await convertEmptyStringsToNullAsync(statePF);

    data.enumEstadoCivil = Number(data.enumEstadoCivil);

    if (data.cpf != null)
      await service
        .UpdateInformacoesTitularPF(data)
        .then(() => {
          setEditar(false);
          toastMessage("success", "Atualizado com sucesso!");
        })
        .catch((e: AxiosError) => {
          toastMessage(
            "success",
            e.response
              ? String(e.response?.data)
              : "Houve um erro ao pegar os dados."
          );
        });
  };

  const UpdatePJ: () => Promise<void> = async () => {
    var data: IUpdateInformacoesTitularPJ =
      await convertEmptyStringsToNullAsync(statePJ);

    await service
      .UpdateInformacoesTitularPJ(data)
      .then(() => {
        setEditar(false);
        toastMessage("success", "Atualizado com sucesso!");
      })
      .catch((e: AxiosError) => {
        toastMessage(
          "success",
          e.response
            ? String(e.response?.data)
            : "Houve um erro ao pegar os dados."
        );
      });
  };
  //endregion

  //region UI
  const Update: () => Promise<void> = async () => {
    if (type == "pessoaFisica") {
      await UpdatePF();
    } else {
      await UpdatePJ();
    }
  };

  const BuscaCep: (cepReturn: ICepReturn) => Promise<void> = async (
    cepReturn: ICepReturn
  ) => {
    updateObjectPJ({
      cep: cepReturn.value ?? "",
      bairro: cepReturn.bairro ?? "",
      cidade: cepReturn.localidade ?? "",
      logradouro: cepReturn.logradouro ?? "",
      uf: cepReturn.uf ?? "",
    });
  };

  useEffect((): void => {
    if (dataContrato && dataContrato.informacoesTitularPF) {
      setObjectPF({
        id: id!,
        cpf: dataContrato.informacoesTitularPF.cpf ?? "",
        enumEstadoCivil: dataContrato.informacoesTitularPF.enumEstadoCivil,
        nacionalidade:
          dataContrato.informacoesTitularPF.nacionalidade ?? "Brasileiro(a)",
        nome: dataContrato.informacoesTitularPF.nome ?? "",
        profissao: dataContrato.informacoesTitularPF.profissao ?? "",
        email: dataContrato.informacoesTitularPF.email ?? "",
        celular: dataContrato.informacoesTitularPF.celular ?? "",
        countryCode: dataContrato.informacoesTitularPF.countryCode ?? "+55",
        dataNascimento:
          dataContrato.informacoesTitularPF.dataNascimento != null
            ? FormatDate(
                new Date(dataContrato.informacoesTitularPF.dataNascimento)
              )
            : "",
      });
    }

    if (dataContrato && dataContrato.informacoesTitularPJ) {
      setObjectPJ({
        id: id!,
        bairro: dataContrato.informacoesTitularPJ.endereco?.bairro ?? "",
        cep: dataContrato.informacoesTitularPJ.endereco?.cep ?? "",
        cidade: dataContrato.informacoesTitularPJ.endereco?.cidade ?? "",
        cnpj: dataContrato.informacoesTitularPJ.cnpj ?? "",
        complemento:
          dataContrato.informacoesTitularPJ.endereco?.complemento ?? "",
        logradouro:
          dataContrato.informacoesTitularPJ.endereco?.logradouro ?? "",
        numero: dataContrato.informacoesTitularPJ.endereco?.numero ?? "",
        numInstalacao: dataContrato.informacoesTitularPJ.numeroInstalacao ?? "",
        razaoSocial: dataContrato.informacoesTitularPJ.razaoSocial ?? "",
        uf: dataContrato.informacoesTitularPJ.endereco?.uf ?? "",
      });
    }
  }, [dataContrato]);

  useEffect(() => {
    if (!props.edit) {
      props.callback!({ dataPF: statePF, dataPJ: statePJ });
    }
  }, [statePF, statePJ]);

  useEffect(() => {
    if (!props.edit) {
      setEditar(true);
    }
  }, [props.edit]);

  const handleChangeCelular = async (e: ICelularInputReturn) => {
    updateObjectPF({ celular: e.value });
    updateObjectPF({ countryCode: e.zipCode });
  };
  //endregion

  return (
    <RegisterCard title="Informações do titular">
      {type === "pessoaFisica" ? (
        <>
          <D.FWStack direction={"row"} spacing={2}>
            <TextField
              label="Nome"
              required
              fullWidth
              value={statePF.nome}
              onChange={(e) => updateObjectPF({ nome: e.target.value })}
              inputProps={{ readOnly: !editar }}
            />
            <MaskDefaultInput
              label="CPF"
              type="CPF"
              value={statePF.cpf}
              onChange={(e) => {
                updateObjectPF({ cpf: e as string });
              }}
              readonly={!editar}
            />
            <D.DateTextfield
              label="Data de nascimento"
              type="date"
              fullWidth
              value={statePF.dataNascimento}
              onChange={(e) =>
                updateObjectPF({ dataNascimento: e.target.value })
              }
              inputProps={{ readOnly: !editar }}
            />
            <TextField
              label="Estado Civil"
              required
              fullWidth
              select
              value={statePF.enumEstadoCivil?.toString() ?? ""}
              onChange={(e) =>
                updateObjectPF({ enumEstadoCivil: Number(e.target.value) })
              }
              inputProps={{ readOnly: !editar }}
            >
              {Object.values(EnumEstadoCivil).map((value) => (
                <MenuItem key={value} value={EnumEstadoCivilNumericMap[value]}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Nacionalidade"
              required
              fullWidth
              value={statePF.nacionalidade}
              onChange={(e) =>
                updateObjectPF({ nacionalidade: e.target.value })
              }
              inputProps={{ readOnly: !editar }}
            />
          </D.FWStack>
          <D.FWStack direction={"row"} spacing={2}>
            <TextField
              label="Profissão"
              required
              fullWidth
              value={statePF.profissao}
              onChange={(e) => updateObjectPF({ profissao: e.target.value })}
              inputProps={{ readOnly: !editar }}
            />
            <TextField
              label="Email"
              required
              fullWidth
              value={statePF.email}
              onChange={(e) => updateObjectPF({ email: e.target.value })}
              inputProps={{ readOnly: !editar }}
            />
            <MaskDefaultInput
              label="Celular"
              value={statePF.celular}
              valueCountryCode={statePF.countryCode}
              readonly={!editar}
              type="CELULAR"
              onChange={(e) => handleChangeCelular(e as ICelularInputReturn)}
            />
            <D.FWStack></D.FWStack>
          </D.FWStack>
        </>
      ) : (
        <>
          <D.FWStack direction={"row"} spacing={2}>
            <TextField
              label="Razão social"
              required
              fullWidth
              value={statePJ.razaoSocial}
              onChange={(e) => updateObjectPJ({ razaoSocial: e.target.value })}
              inputProps={{ readOnly: !editar }}
            />
            <MaskDefaultInput
              label="CNPJ"
              type="CNPJ"
              value={statePJ.cnpj}
              onChange={(e) => {
                updateObjectPJ({ cnpj: e as string });
              }}
              readonly={!editar}
            />
            <TextField
              label="Número instalação"
              required
              fullWidth
              value={statePJ.numInstalacao}
              onChange={(e) =>
                updateObjectPJ({ numInstalacao: e.target.value })
              }
              inputProps={{ readOnly: !editar }}
            />
          </D.FWStack>
          <D.FWStack direction={"row"} spacing={2}>
            <MaskDefaultInput
              label="CEP"
              type="CEP"
              value={statePJ.cep}
              onChange={(e) => BuscaCep(e as ICepReturn)}
              readonly={!editar}
            />
            <TextField
              label="UF"
              required
              value={statePJ.uf}
              sx={{ width: "50%" }}
              onChange={(e) => updateObjectPJ({ uf: e.target.value })}
              inputProps={{ readOnly: !editar }}
            />
            <TextField
              label="Cidade"
              required
              fullWidth
              value={statePJ.cidade}
              onChange={(e) => updateObjectPJ({ cidade: e.target.value })}
              inputProps={{ readOnly: !editar }}
            />
            <TextField
              label="Bairro"
              required
              fullWidth
              value={statePJ.bairro}
              onChange={(e) => updateObjectPJ({ bairro: e.target.value })}
              inputProps={{ readOnly: !editar }}
            />
            <TextField
              label="Logradouro"
              required
              fullWidth
              value={statePJ.logradouro}
              onChange={(e) => updateObjectPJ({ logradouro: e.target.value })}
              inputProps={{ readOnly: !editar }}
            />
          </D.FWStack>
          <D.FWStack direction={"row"} spacing={2}>
            <TextField
              label="Número"
              required
              sx={{ width: "50%" }}
              value={statePJ.numero}
              onChange={(e) => updateObjectPJ({ numero: e.target.value })}
              inputProps={{ readOnly: !editar }}
            />
            <TextField
              label="Complemento"
              fullWidth
              value={statePJ.complemento}
              onChange={(e) => updateObjectPJ({ complemento: e.target.value })}
              inputProps={{ readOnly: !editar }}
            />
            <D.FWStack></D.FWStack>
            <D.FWStack></D.FWStack>
            <D.FWStack></D.FWStack>
          </D.FWStack>
        </>
      )}
      {props.edit && (
        <D.FWStack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <D.ContainedButton
            $color="yellow"
            disabled={editar}
            onClick={() => setEditar(true)}
          >
            Editar
          </D.ContainedButton>
          <D.ContainedButton disabled={!editar} onClick={Update}>
            Salvar
          </D.ContainedButton>
        </D.FWStack>
      )}
    </RegisterCard>
  );
};

export default InformacoesTitularContratar;
