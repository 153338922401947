import React, {useEffect, useState} from 'react';
import {Modal} from "@mui/material";
import * as C from "../../style";
import * as D from "../../../../../styles/appComponents";
import {
  StatusContratoColorMap,
  StatusGDContratoNumber,
  StatusGDContratoNumberMap
} from "../../../../../enums/EnumStatusContratoCliente.enum";
import CloseIcon from "@mui/icons-material/Close";
import HistoricoCard from "../../../../../components/HistoricoCard";
import {toastMessage} from "../../../../../utils/toastMessage";
import {ICreateLog, ILog} from "../../../../../models/Log";
import * as logServices from "../../../../../services/api/LogService";
import {AxiosError} from "axios";
import * as services from "../../../../../services/api/GDContratoClienteService";
import {IGDContrato} from "../../../../../models/GDContrato";
import {CELULAR, CPF} from "../../../../../utils/masInputs";

type ContratoModalProps = {
  id: string | null;
  openContratoModal: boolean;
  callback?: (id: null) => void;
}

const ContratoModal: (props: ContratoModalProps) => React.JSX.Element = (props: ContratoModalProps): React.JSX.Element => {
  //region Variáveis
  const {id, openContratoModal, callback} = props;
  const [openModalSeeMore, setOpenModalSeeMore] = useState(false);
  const [historico, setHistorico] = useState<ILog[]>([]);
  const [historicoAssunto, setHistoricoAssunto] = useState<string>("");
  const [historicoDescricao, setHistoricoDescricao] = useState<string>("");
  const [historicoAnexo, setHistoricoAnexo] = useState<File | null>(null);
  const [modalContrato, setModalContrato] = useState<IGDContrato>({
    backoffice: "",
    celular: "",
    celularParceiro: "",
    cpf: "",
    cpfParceiro: "",
    createdAt: new Date(),
    dataAssinatura: new Date(),
    email: "",
    emailParceiro: "",
    id: "",
    idReferencial: 0,
    instalacao: "",
    nomeParceiro: "",
    nomeUsina: "",
    status: 0,
    titular: "",
    totalkWh: 0,
    updatedAt: new Date(),
    valor: 0,
  });
  //endregion

  //region Services
  const getLogsByContratoId = async (id: string) => {
    await logServices
      .GetLogsByItemId(id)
      .then((response) => {
        setHistorico(response.data);
      })
      .catch((e: AxiosError) => {
        toastMessage("warning", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.");
      });
  };

  const createNewLog = async () => {
    if (historicoAssunto === "") {
      toastMessage("warning", "Assunto não pode ser vazio.");
      return;
    }

    const historicoData: ICreateLog = {
      gdLeadId: null,
      gdPropostaId: null,
      gdContratoClienteId: modalContrato.id,
      assunto: historicoAssunto,
      descricao: historicoDescricao,
      anexo: historicoAnexo,
    };

    await logServices
      .CreateLog(historicoData)
      .then(() => {
        handleOpenModal(modalContrato.id);
        toastMessage("success", "Criado com sucesso!");
      })
      .catch((e: AxiosError) => {
        toastMessage("warning", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.");
      });
  };

  const handleOpenModal = async (contratoId: string) => {
    await services
      .GetContratoById(contratoId)
      .then(async (response) => {
        await setModalContrato(response.data);
        await getLogsByContratoId(contratoId);
        setOpenModalSeeMore(true);
      })
      .catch((e: AxiosError) => {
        toastMessage("warning", e.response
          ? String(e.response?.data)
          : "Houve um erro ao pegar os dados.");
      });
  };
  //endregion

  //region UI
  const handleCloseModalSeeMore = () => {
    setModalContrato({
      backoffice: "",
      celular: "",
      celularParceiro: "",
      cpf: "",
      cpfParceiro: "",
      createdAt: new Date(),
      dataAssinatura: new Date(),
      email: "",
      emailParceiro: "",
      id: "",
      idReferencial: 0,
      instalacao: "",
      nomeParceiro: "",
      nomeUsina: "",
      status: 0,
      titular: "",
      totalkWh: 0,
      updatedAt: new Date(),
      valor: 0,
    });
    setOpenModalSeeMore(false);
    callback && callback(null);
  };

  const handleNewHistoricoChange = (
    assunto: string,
    descricao: string,
    anexo: File | null
  ) => {
    setHistoricoAssunto(assunto);
    setHistoricoDescricao(descricao);
    setHistoricoAnexo(anexo);
  };

  useEffect(() => {
    if(id)
      handleOpenModal(id)
  }, [id, openContratoModal]);
  //endregion

  return (
      <Modal
        sx={{ width: "100%" }}
        open={openModalSeeMore}
        onClose={handleCloseModalSeeMore}
        aria-labelledby="modal-details"
        aria-describedby="modal-details"
      >
        <C.ModalContainer>
          <C.ModalArea>
            <C.ModalCard>
              <C.ModalHeader>
                <h2>
                  {modalContrato.titular} - {modalContrato.instalacao}
                  {modalContrato.status && (
                    <C.ModalTextIcon>
                      <D.Circle
                        color={StatusContratoColorMap[modalContrato.status]}
                      />
                      <p>
                        {StatusGDContratoNumber[modalContrato.status]}
                      </p>
                    </C.ModalTextIcon>
                  )}
                </h2>
                <C.CloseButton
                  aria-label="close"
                  onClick={handleCloseModalSeeMore}
                >
                  <CloseIcon />
                </C.CloseButton>
              </C.ModalHeader>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Total de kwh:</label>
                  <p>{modalContrato.totalkWh} kwh</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Valor:</label>
                  <p>R$ {modalContrato.valor}</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Data de assinatura:</label>
                  <p>
                    {modalContrato.dataAssinatura
                      ? new Date(
                        modalContrato.dataAssinatura?.toString()
                      ).toLocaleDateString()
                      : "Não informado."}
                  </p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Backoffice:</label>
                  <p>{modalContrato.backoffice}</p>
                </C.ModalItem>
              </C.ModalLine>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Titular:</label>
                  <p>{modalContrato.titular}</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>CPF:</label>
                  <p>{new CPF().executeForInput(modalContrato.cpf ?? "")}</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Telefone:</label>
                  <p>{new CELULAR().executeForInput(modalContrato.celular ?? "")}</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Email:</label>
                  <p>{modalContrato.email}</p>
                </C.ModalItem>
              </C.ModalLine>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Parceiro:</label>
                  <p>{modalContrato.nomeParceiro}</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>CPF:</label>
                  <p>{new CPF().executeForInput(modalContrato.cpfParceiro ?? "")}</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Telefone:</label>
                  <p>{new CELULAR().executeForInput(modalContrato.celularParceiro ?? "")}</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Email:</label>
                  <p>{modalContrato.emailParceiro}</p>
                </C.ModalItem>
              </C.ModalLine>
              <C.ModalLine>
                <C.ModalItem>
                  <label>Nome da usina:</label>
                  <p>{modalContrato.nomeUsina}</p>
                </C.ModalItem>
                <C.ModalItem>
                  <label>Instalação:</label>
                  <p>{modalContrato.instalacao}</p>
                </C.ModalItem>
                <C.ModalItem></C.ModalItem>
                <C.ModalItem></C.ModalItem>
              </C.ModalLine>
            </C.ModalCard>
            <HistoricoCard
              tableData={historico}
              getNewHistoricData={handleNewHistoricoChange}
              saveFunction={createNewLog}
              reloadTable={() => getLogsByContratoId(modalContrato.id)}
            />
          </C.ModalArea>
        </C.ModalContainer>
      </Modal>
    );
};

export default ContratoModal;