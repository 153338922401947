import {StatusBuilder} from "../models/StatusBuilder";
import {EnumStatusContratoCliente} from "./EnumStatusContratoCliente.enum";

export const EnumStatusExtratoPorUsinaMap: StatusBuilder[] = [
  {
    name: "Aguardando assinatura",
    value: EnumStatusContratoCliente.aguardandoAssinatura,
    color: "yellow",
    primary: true
  },
  {
    name: "Em processo de conexão",
    value: EnumStatusContratoCliente.processoConexao,
    color: "blue",
    primary: true
  },
  {
    name: "Conectado a distribuidora",
    value: EnumStatusContratoCliente.conectadoADistribuidora,
    color: "green",
    primary: true
  },
  {
    name: "Inadimplente",
    value: EnumStatusContratoCliente.inadimplente,
    color: "purple",
    primary: true
  },
  {
    name: "Desconexão solicitada",
    value: EnumStatusContratoCliente.desconexaoSolicitada,
    color: "red",
    primary: true
  },
  {
    name: "Contrato assinado",
    value: EnumStatusContratoCliente.contratoAssinado,
    color: "cyan",
    primary: false
  },
  {
    name: "Em processo de conexão",
    value: EnumStatusContratoCliente.processoConexao,
    color: "darkBlue",
    primary: false
  },
  {
    name: "Medidor zerado",
    value: EnumStatusContratoCliente.medidorZerado,
    color: "orange",
    primary: false
  },
  {
    name: "Saldo de geração em exesso",
    value: EnumStatusContratoCliente.saldoGeracaoEmExcesso,
    color: "gold",
    primary: false
  },
  {
    name: "Aguardando assinatura de aditivo",
    value: EnumStatusContratoCliente.aguardandoAssinaturaAditivo,
    color: "darkPink",
    primary: false
  },
  {
    name: "Aguardando assinatura de distrato",
    value: EnumStatusContratoCliente.aguardandoAssinaturaDistrato,
    color: "brown",
    primary: false
  },
  {
    name: "Processo judicial",
    value: EnumStatusContratoCliente.processoJudicial,
    color: "darkGreen",
    primary: false
  },
  {
    name: "Conectado a distribuidora",
    value: EnumStatusContratoCliente.conectadoADistribuidora,
    color: "whiteGreen",
    primary: false
  },
  {
    name: "Desconectado",
    value: EnumStatusContratoCliente.desconectado,
    color: "grey",
    primary: false
  },
  {
    name: "Em análise",
    value: EnumStatusContratoCliente.emAnalise,
    color: "whitePink",
    primary: false
  },
  {
    name: "Cancelado",
    value: EnumStatusContratoCliente.cancelado,
    color: "whiteOrange",
    primary: false
  },
]