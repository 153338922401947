import http from "../common/http-common";
import {IPaginatedList} from "../../models/PaginatedList";
import {AxiosResponse} from "axios";
import {
  IDashboardOrdemPagamento,
  INovaOrdem,
  INovoPagamento,
  IOrdemPagamento,
  IReportOrdemPagamento
} from "../../models/OrdemPagamento";
import {IContaOrdemPagamento} from "../../models/Conta";
import { EnumStatusOrdemPagamento } from "../../enums/EnumStatusOrdemPagamento";


export const GetOrdensPagamento: (
  id: number | null,
  instalacao: string | null | undefined,
  name: string | null | undefined,
  date: string | null | undefined,
  pageNumber: number | null, 
  pageSize: number | null, 
  statusOrdemPagamento?: EnumStatusOrdemPagamento | null | undefined,
  usinaId?: string | null | undefined

) => Promise<AxiosResponse<IPaginatedList<IOrdemPagamento>, any>> = (
  id: number | null = null,
  instalacao: string | null | undefined = null,
  name: string | null | undefined = null,
  date: string | null | undefined = null,
  pageNumber: number | null,
  pageSize: number | null,
  statusOrdemPagamento?: EnumStatusOrdemPagamento | null | undefined,
  usinaId?: string | null | undefined

): Promise<AxiosResponse<IPaginatedList<IOrdemPagamento>, any>> => {

  let url = "/OrdemPagamento";

  const params = [];
  const formData = new FormData();

  if (id) params.push(`Id=${id}`);
  if (instalacao) params.push(`Instalacao=${instalacao}`);
  if (name) params.push(`Name=${name}`);
  if (date) params.push(`Date=${date}`);
  if (pageSize) params.push(`PageSize=${pageSize}`);
  if (pageNumber) params.push(`PageNumber=${pageNumber}`);
  if (statusOrdemPagamento !== null || statusOrdemPagamento) params.push(`statusOrdemPagamento=${statusOrdemPagamento}`);
  if (usinaId) params.push(`usinaId=${usinaId}`);

  if (params.length > 0) {
    url += `?${params.join("&")}`;
  }

  return http.get<IPaginatedList<IOrdemPagamento>>(url);

};

export const GetConta: (id: string) => Promise<AxiosResponse<INovoPagamento, any>> = (
  id: string,
): Promise<AxiosResponse<INovoPagamento, any>> => {
  return http.get(
    `/conta/${id}`
  );
};

export const GetOrdemPagamento: (id: string) => Promise<AxiosResponse<IContaOrdemPagamento, any>> = (
  id: string,
): Promise<AxiosResponse<IContaOrdemPagamento, any>> => {
  return http.get(
    `/ordemPagamento/getById?id=${id}`
  );
};

export const GetLastDataReferenciaOrdemPagamentoByUsinaId: (usinaId: string, dataReferencia:string) => Promise<AxiosResponse<IContaOrdemPagamento, any>> = (
  usinaId: string,
  dataReferencia:string
): Promise<AxiosResponse<IContaOrdemPagamento, any>> => {
  return http.get(
    `/ordemPagamento/BuscaUltimaDataReferenciaPorUsina?UsinaId=${usinaId}&&DataReferencia=${dataReferencia}`
  );
};

export const GetOrdemPagamentoReport = (
  filters: IReportOrdemPagamento,
) => {
  const queryParams: string[] = [];

  if(filters.statusContrato !== null && filters.statusContrato !== undefined && filters.statusContrato !== "todos") {
    queryParams.push(`statusContrato=${filters.statusContrato}`);
  }
  if(filters.gerador) {
    queryParams.push(`gerador=${filters.gerador}`);
  }
  if(filters.dataInicial) {
    queryParams.push(`dataInicial=${filters.dataInicial}`);
  }
  if(filters.dataFinal) {
    queryParams.push(`dataFinal=${filters.dataFinal}`);
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

  return http.get<File>(`/OrdemPagamento/BuscaRelatorioOrdemPagamento${queryString}`, {
    responseType: "blob",
  });
};


export const GetDashboardOrdemPagamento: (usinaId?:string | null) => Promise<AxiosResponse<any, IDashboardOrdemPagamento[]>> = (usinaId?:string | null): Promise<AxiosResponse<any, IDashboardOrdemPagamento[]>> => {
  let url = ""
  if(!!usinaId)
    url = `/OrdemPagamento/Dashboard?UsinaId=${usinaId}`;
  else
    url = `/OrdemPagamento/Dashboard`

  return http.get(url);
};

export const SetOrdemPagamento: (data: INovaOrdem) => Promise<AxiosResponse<INovaOrdem, any>> = (data: INovaOrdem): Promise<AxiosResponse<INovaOrdem, any>> => {
  const formData = new FormData();

  if (data.dataReferencia) {
    formData.append("dataReferencia", data.dataReferencia as unknown as string)
  }
  if (data.instalacao) {
    formData.append("instalacao", data.instalacao)
  }
  if (data.sigla) {
    formData.append("sigla", data.sigla)
  }
  if (data.descontoTarifa) {
    formData.append("descontoTarifa", data.descontoTarifa?.toString().replace(".", ",") ?? "");
  }
  if (data.energiaInjetada) {
    formData.append("energiaInjetada", data.energiaInjetada?.toString().replace(".", ",") ?? "");
  }
  if (data.energiaConsumida) {
    formData.append("energiaConsumida", data.energiaConsumida?.toString().replace(".", ",") ?? "");
  }
  if (data.tarifaDistribuidora) {
    formData.append("tarifaDistribuidora", data.tarifaDistribuidora?.toString().replace(".", ",") ?? "");
  }
  if (data.saldo) {
    formData.append("saldo", data.saldo?.toString().replace(".", ",") ?? "");
  }
  if (data.tarifaFioB) {
    formData.append("tarifaFioB", data.tarifaFioB?.toString().replace(".", ",") ?? "");
  }
  if (typeof(data.tipoConexao) !== "undefined") {
    formData.append("tipoConexao", data.tipoConexao as unknown as string)
  }
  if (data.dataLimiteQuitacao) {
    formData.append("dataLimiteQuitacao", data.dataLimiteQuitacao as unknown as string)
  }
  if (data.status) {
    formData.append("status", data.status as unknown as string)
  }
  if (data.comprovante) {
    formData.append("comprovante", data.comprovante)
  }
  if (data.usinaId) {
    formData.append("usinaId", data.usinaId)
  }

  return http.post<INovaOrdem>(`/OrdemPagamento`, formData);
};
export const updateOrdemPagamento: (data: INovaOrdem) => Promise<AxiosResponse<INovaOrdem, any>> = (data: INovaOrdem): Promise<AxiosResponse<INovaOrdem, any>> => {
  const formData = new FormData();

  if (data.id) {
    formData.append("id", data.id as unknown as string)
  }
  if (data.dataReferencia) {
    formData.append("dataReferencia", data.dataReferencia as unknown as string)
  }
  if (data.instalacao) {
    formData.append("instalacao", data.instalacao)
  }
  if (data.sigla) {
    formData.append("sigla", data.sigla)
  }
  if (data.descontoTarifa) {
    formData.append("descontoTarifa", data.descontoTarifa?.toString().replace(".", ",") ?? "");
  }
  if (data.energiaInjetada) {
    formData.append("energiaInjetada", data.energiaInjetada?.toString().replace(".", ",") ?? "");
  }
  if (data.energiaConsumida) {
    formData.append("energiaConsumida", data.energiaConsumida?.toString().replace(".", ",") ?? "");
  }
  if (data.saldo) {
    formData.append("saldo", data.saldo?.toString().replace(".", ",") ?? "");
  }
  if (data.saldoDeQuitacao) {
    formData.append("saldoDeQuitacao", data.saldoDeQuitacao?.toString().replace(".", ",") ?? "");
  }
  if (data.tarifaDistribuidora) {
    formData.append("tarifaDistribuidora", data.tarifaDistribuidora?.toString().replace(".", ",") ?? "");
  }
  if (data.tarifaFioB) {
    formData.append("tarifaFioB", data.tarifaFioB?.toString().replace(".", ",") ?? "");
  }
  if (typeof(data.tipoConexao) !== "undefined") {
    formData.append("tipoConexao", data.tipoConexao as unknown as string)
  }
  if (data.dataLimiteQuitacao) {
    formData.append("dataLimiteQuitacao", data.dataLimiteQuitacao as unknown as string)
  }
  if (data.status) {
    formData.append("status", data.status as unknown as string)
  }
  if (data.comprovante) {
    formData.append("comprovante", data.comprovante)
  }
  if (data.usinaId) {
    formData.append("usinaId", data.usinaId)
  }
  if (data.active != undefined) {
    formData.append("active", data.active as unknown as string)
  }
  if (data.visualizacao != undefined) {
    formData.append("visualizacao", data.visualizacao.toString())
  }
  return http.put<INovaOrdem>(`/OrdemPagamento`, formData);
};