import React, {useState} from 'react';
import RegisterCard from "../../../../../components/RegisterCard";
import * as D from "../../../../../styles/appComponents";
import {TextField} from "@mui/material";

export interface IDetalhessQuitacao {
  dataRecebimento: string | null;
  valor: string | null;
  finalizacao: string | null;
}

export enum DetalhesQuitacaoEnum {
  DATA_RECEBIMENTO = "dataRecebimento",
  VALOR = "valor",
  FINALIZACAO = "finalizacao",
}

type DetalhesQuitacaoProps = {
  callback: (dadosPagador: IDetalhessQuitacao) => void;
}

const DetalhesQuitacao: (props: DetalhesQuitacaoProps) => React.JSX.Element = (props: DetalhesQuitacaoProps): React.JSX.Element => {
  //region Variáveis
  const {callback} = props;
  const [dadosQuitacao, setDadosQuitacao] = useState<IDetalhessQuitacao>({
    dataRecebimento: null,
    finalizacao: null,
    valor: null,
  });
  //endregion

  //region UI
  const handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name: string = event.target.name;
    const value: string = event.target.value;

    setDadosQuitacao((state: IDetalhessQuitacao) => {
      const newData = {
        ...state,
        [name]: value,
      };
      callback(newData);
      return newData;
    });
  };
  //endregion

  return (
    <>
      <RegisterCard title="Detalhes quitação">
        <D.FWStack direction={"row"} spacing={2}>
          <TextField
            label="Data de recebimento"
            required
            fullWidth
            name={DetalhesQuitacaoEnum.DATA_RECEBIMENTO}
            sx={{width:'50%'}}
            value={dadosQuitacao.dataRecebimento}
            onChange={handleInputChange}
          />
          <TextField
            label="Valor"
            required
            fullWidth
            name={DetalhesQuitacaoEnum.VALOR}
            sx={{width:'50%'}}
            value={dadosQuitacao.valor}
            onChange={handleInputChange}
          />
          <TextField
            label="Finalização"
            required
            fullWidth
            sx={{width:'50%'}}
            name={DetalhesQuitacaoEnum.FINALIZACAO}
            value={dadosQuitacao.finalizacao}
            onChange={handleInputChange}
          />
        </D.FWStack>
      </RegisterCard>
    </>
  );
};

export default DetalhesQuitacao;