import { AbstractValidation, Validate } from "../../../../../../utils/AbstractValidation";
import { IFormBeneficiosComissao } from "../BeneficiosComissao";

export class BeneficioComissaoValidate extends AbstractValidation<IFormBeneficiosComissao>{
    getValidate(): Validate<IFormBeneficiosComissao>[] {
        return [
            {
                field: "valordiavr",
                validate: (value, data, addError:(message:string) => void) => {
                    if(data?.boolrecebevr && !value) addError("O valor do dia de vale refeição deve ser informada")
                }
            },
            {
                field: "valordiava",
                validate: (value, data, addError:(message:string) => void) => {
                    if(data?.boolrecebeva && !value) addError("O valor do dia de vale alimentação deve ser informada")
                }
            },
            {
                field: "valordiacombustivel",
                validate: (value, data, addError:(message:string) => void) => {
                    if(data?.boolrecebecombustivel && !value) addError("O valor do dia de vale combustível deve ser informada")
                }
            }
        ]
    }
}