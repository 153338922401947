import { IBanco } from "../models/Banco";
import ReadonlyArray from "../types/ReadonlyArray";

export const listaBancos: ReadonlyArray<IBanco> = [
  {
    Codigo: "001",
    Nome: "Banco do Brasil S.A.",
    NomeCurto: "BCO DO BRASIL S.A.",
  },
  {
    Codigo: "003",
    Nome: "Banco da Amazônia S.A.",
    NomeCurto: "BCO DA AMAZONIA S.A.",
  },
  {
    Codigo: "004",
    Nome: "Banco do Nordeste do Brasil S.A.",
    NomeCurto: "BCO DO NORDESTE DO BRASIL S.A.",
  },
  {
    Codigo: "007",
    Nome: "BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL",
    NomeCurto: "BNDES",
  },
  {
    Codigo: "010",
    Nome: "CREDICOAMO CREDITO RURAL COOPERATIVA",
    NomeCurto: "CREDICOAMO",
  },
  {
    Codigo: "011",
    Nome: "CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A",
    NomeCurto: "C.SUISSE HEDGING-GRIFFO CV S/A",
  },
  {
    Codigo: "012",
    Nome: "Banco Inbursa S.A.",
    NomeCurto: "BANCO INBURSA",
  },
  {
    Codigo: "014",
    Nome: "STATE STREET BRASIL S.A. - BANCO COMERCIAL",
    NomeCurto: "STATE STREET BR S.A. BCO COMERCIAL",
  },
  {
    Codigo: "015",
    Nome: "UBS BB CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    NomeCurto: "UBS BB CCTVM S.A.",
  },
  {
    Codigo: "016",
    Nome: "COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RIO GRANDE DO SUL - SICOOB CREDITRAN",
    NomeCurto: "CCM DESP TRÂNS SC E RS",
  },
  {
    Codigo: "017",
    Nome: "BNY Mellon Banco S.A.",
    NomeCurto: "BNY MELLON BCO S.A.",
  },
  {
    Codigo: "018",
    Nome: "Banco Tricury S.A.",
    NomeCurto: "BCO TRICURY S.A.",
  },
  {
    Codigo: "021",
    Nome: "BANESTES S.A. Banco do Estado do Espírito Santo",
    NomeCurto: "BCO BANESTES S.A.",
  },
  {
    Codigo: "024",
    Nome: "Banco Bandepe S.A.",
    NomeCurto: "BCO BANDEPE S.A.",
  },
  {
    Codigo: "025",
    Nome: "Banco Alfa S.A.",
    NomeCurto: "BCO ALFA S.A.",
  },
  {
    Codigo: "029",
    Nome: "Banco Itaú Consignado S.A.",
    NomeCurto: "BANCO ITAÚ CONSIGNADO S.A.",
  },
  {
    Codigo: "033",
    Nome: "BANCO SANTANDER (BRASIL) S.A.",
    NomeCurto: "BCO SANTANDER (BRASIL) S.A.",
  },
  {
    Codigo: "036",
    Nome: "Banco Bradesco BBI S.A.",
    NomeCurto: "BCO BBI S.A.",
  },
  {
    Codigo: "037",
    Nome: "Banco do Estado do Pará S.A.",
    NomeCurto: "BCO DO EST. DO PA S.A.",
  },
  {
    Codigo: "040",
    Nome: "Banco Cargill S.A.",
    NomeCurto: "BCO CARGILL S.A.",
  },
  {
    Codigo: "041",
    Nome: "Banco do Estado do Rio Grande do Sul S.A.",
    NomeCurto: "BCO DO ESTADO DO RS S.A.",
  },
  {
    Codigo: "047",
    Nome: "Banco do Estado de Sergipe S.A.",
    NomeCurto: "BCO DO EST. DE SE S.A.",
  },
  {
    Codigo: "060",
    Nome: "Confidence Corretora de Câmbio S.A.",
    NomeCurto: "CONFIDENCE CC S.A.",
  },
  {
    Codigo: "062",
    Nome: "Hipercard Banco Múltiplo S.A.",
    NomeCurto: "HIPERCARD BM S.A.",
  },
  {
    Codigo: "063",
    Nome: "Banco Bradescard S.A.",
    NomeCurto: "BANCO BRADESCARD",
  },
  {
    Codigo: "064",
    Nome: "Goldman Sachs do Brasil Banco Múltiplo S.A.",
    NomeCurto: "GOLDMAN SACHS DO BRASIL BM S.A",
  },
  {
    Codigo: "065",
    Nome: "Banco AndBank (Brasil) S.A.",
    NomeCurto: "BCO ANDBANK S.A.",
  },
  {
    Codigo: "066",
    Nome: "BANCO MORGAN STANLEY S.A.",
    NomeCurto: "BCO MORGAN STANLEY S.A.",
  },
  {
    Codigo: "069",
    Nome: "Banco Crefisa S.A.",
    NomeCurto: "BCO CREFISA S.A.",
  },
  {
    Codigo: "070",
    Nome: "BRB - Banco de Brasília S.A.",
    NomeCurto: "BRB - BCO DE BRASILIA S.A.",
  },
  {
    Codigo: "074",
    Nome: "Banco J. Safra S.A.",
    NomeCurto: "BCO. J.SAFRA S.A.",
  },
  {
    Codigo: "075",
    Nome: "BANCO ABN AMRO CLEARING S.A.",
    NomeCurto: "BANCO ABN AMRO CLEARING S.A.",
  },
  {
    Codigo: "076",
    Nome: "Banco KDB do Brasil S.A.",
    NomeCurto: "BCO KDB BRASIL S.A.",
  },
  {
    Codigo: "077",
    Nome: "Banco Inter S.A.",
    NomeCurto: "BANCO INTER",
  },
  {
    Codigo: "078",
    Nome: "Haitong Banco de Investimento do Brasil S.A.",
    NomeCurto: "HAITONG BI DO BRASIL S.A.",
  },
  {
    Codigo: "079",
    Nome: "PICPAY BANK - BANCO MÚLTIPLO S.A",
    NomeCurto: "PICPAY BANK - BANCO MÚLTIPLO S.A",
  },
  {
    Codigo: "080",
    Nome: "BT CORRETORA DE CÂMBIO LTDA.",
    NomeCurto: "BT CC LTDA.",
  },
  {
    Codigo: "081",
    Nome: "BancoSeguro S.A.",
    NomeCurto: "BANCOSEGURO S.A.",
  },
  {
    Codigo: "082",
    Nome: "BANCO TOPÁZIO S.A.",
    NomeCurto: "BANCO TOPÁZIO S.A.",
  },
  {
    Codigo: "083",
    Nome: "Banco da China Brasil S.A.",
    NomeCurto: "BCO DA CHINA BRASIL S.A.",
  },
  {
    Codigo: "084",
    Nome: "SISPRIME DO BRASIL - COOPERATIVA DE CRÉDITO",
    NomeCurto: "SISPRIME DO BRASIL - COOP",
  },
  {
    Codigo: "085",
    Nome: "Cooperativa Central de Crédito - Ailos",
    NomeCurto: "COOPCENTRAL AILOS",
  },
  {
    Codigo: "088",
    Nome: "BANCO RANDON S.A.",
    NomeCurto: "BANCO RANDON S.A.",
  },
  {
    Codigo: "089",
    Nome: "CREDISAN COOPERATIVA DE CRÉDITO",
    NomeCurto: "CREDISAN CC",
  },
  {
    Codigo: "091",
    Nome: "CENTRAL DE COOPERATIVAS DE ECONOMIA E CRÉDITO MÚTUO DO ESTADO DO RIO GRANDE DO S",
    NomeCurto: "CCCM UNICRED CENTRAL RS",
  },
  {
    Codigo: "092",
    Nome: "BRK S.A. Crédito, Financiamento e Investimento",
    NomeCurto: "BRK S.A. CFI",
  },
  {
    Codigo: "093",
    Nome: "PÓLOCRED   SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.",
    NomeCurto: "POLOCRED SCMEPP LTDA.",
  },
  {
    Codigo: "094",
    Nome: "Banco Finaxis S.A.",
    NomeCurto: "BANCO FINAXIS",
  },
  {
    Codigo: "095",
    Nome: "BANCO TRAVELEX S.A.",
    NomeCurto: "BANCO TRAVELEX S.A.",
  },
  {
    Codigo: "096",
    Nome: "Banco B3 S.A.",
    NomeCurto: "BCO B3 S.A.",
  },
  {
    Codigo: "097",
    Nome: "Credisis - Central de Cooperativas de Crédito Ltda.",
    NomeCurto: "CREDISIS - CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.",
  },
  {
    Codigo: "098",
    Nome: "Credialiança Cooperativa de Crédito Rural",
    NomeCurto: "CREDIALIANÇA CCR",
  },
  {
    Codigo: "099",
    Nome: "UNIPRIME CENTRAL NACIONAL - CENTRAL NACIONAL DE COOPERATIVA DE CREDITO",
    NomeCurto: "UNIPRIME COOPCENTRAL LTDA.",
  },
  {
    Codigo: "100",
    Nome: "Planner Corretora de Valores S.A.",
    NomeCurto: "PLANNER CV S.A.",
  },
  {
    Codigo: "101",
    Nome: "RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
    NomeCurto: "RENASCENCA DTVM LTDA",
  },
  {
    Codigo: "102",
    Nome: "XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A",
    NomeCurto: "XP INVESTIMENTOS CCTVM S/A",
  },
  {
    Codigo: "104",
    Nome: "Caixa Econômica Federal",
    NomeCurto: "CAIXA ECONOMICA FEDERAL",
  },
  {
    Codigo: "105",
    Nome: "Lecca Crédito, Financiamento e Investimento S/A",
    NomeCurto: "LECCA CFI S.A.",
  },
  {
    Codigo: "107",
    Nome: "Banco Bocom BBM S.A.",
    NomeCurto: "BCO BOCOM BBM S.A.",
  },
  {
    Codigo: "108",
    Nome: "PORTOCRED S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO",
    NomeCurto: "PORTOCRED S.A. - CFI",
  },
  {
    Codigo: "111",
    Nome: "OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.",
    NomeCurto: "OLIVEIRA TRUST DTVM S.A.",
  },
  {
    Codigo: "113",
    Nome: "NEON CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    NomeCurto: "NEON CTVM S.A.",
  },
  {
    Codigo: "114",
    Nome: "Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP",
    NomeCurto: "CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO",
  },
  {
    Codigo: "117",
    Nome: "ADVANCED CORRETORA DE CÂMBIO LTDA",
    NomeCurto: "ADVANCED CC LTDA",
  },
  {
    Codigo: "119",
    Nome: "Banco Western Union do Brasil S.A.",
    NomeCurto: "BCO WESTERN UNION",
  },
  {
    Codigo: "120",
    Nome: "BANCO RODOBENS S.A.",
    NomeCurto: "BCO RODOBENS S.A.",
  },
  {
    Codigo: "121",
    Nome: "Banco Agibank S.A.",
    NomeCurto: "BCO AGIBANK S.A.",
  },
  {
    Codigo: "122",
    Nome: "Banco Bradesco BERJ S.A.",
    NomeCurto: "BCO BRADESCO BERJ S.A.",
  },
  {
    Codigo: "124",
    Nome: "Banco Woori Bank do Brasil S.A.",
    NomeCurto: "BCO WOORI BANK DO BRASIL S.A.",
  },
  {
    Codigo: "125",
    Nome: "BANCO GENIAL S.A.",
    NomeCurto: "BANCO GENIAL",
  },
  {
    Codigo: "126",
    Nome: "BR Partners Banco de Investimento S.A.",
    NomeCurto: "BR PARTNERS BI",
  },
  {
    Codigo: "127",
    Nome: "Codepe Corretora de Valores e Câmbio S.A.",
    NomeCurto: "CODEPE CVC S.A.",
  },
  {
    Codigo: "128",
    Nome: "BRAZA BANK S.A. BANCO DE CÂMBIO",
    NomeCurto: "BRAZA BANK S.A. BCO DE CÂMBIO",
  },
  {
    Codigo: "129",
    Nome: "UBS BB BANCO DE INVESTIMENTO S.A.",
    NomeCurto: "UBS BB BI S.A.",
  },
  {
    Codigo: "130",
    Nome: "CARUANA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    NomeCurto: "CARUANA SCFI",
  },
  {
    Codigo: "131",
    Nome: "TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA",
    NomeCurto: "TULLETT PREBON BRASIL CVC LTDA",
  },
  {
    Codigo: "132",
    Nome: "ICBC do Brasil Banco Múltiplo S.A.",
    NomeCurto: "ICBC DO BRASIL BM S.A.",
  },
  {
    Codigo: "133",
    Nome: "CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E SOLIDÁRIA - CRESOL CONFEDERAÇÃO",
    NomeCurto: "CRESOL CONFEDERAÇÃO",
  },
  {
    Codigo: "134",
    Nome: "BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
    NomeCurto: "BGC LIQUIDEZ DTVM LTDA",
  },
  {
    Codigo: "136",
    Nome: "COOPERATIVA CENTRAL DE CRÉDITO UNICRED DO BRASIL - UNICRED DO BRASIL",
    NomeCurto: "UNICRED DO BRASIL",
  },
  {
    Codigo: "138",
    Nome: "Get Money Corretora de Câmbio S.A.",
    NomeCurto: "GET MONEY CC LTDA",
  },
  {
    Codigo: "139",
    Nome: "Intesa Sanpaolo Brasil S.A. - Banco Múltiplo",
    NomeCurto: "INTESA SANPAOLO BRASIL S.A. BM",
  },
  {
    Codigo: "140",
    Nome: "NU INVEST CORRETORA DE VALORES S.A.",
    NomeCurto: "NU INVEST CORRETORA DE VALORES S.A.",
  },
  {
    Codigo: "141",
    Nome: "BANCO MASTER DE INVESTIMENTO S.A.",
    NomeCurto: "MASTER BI S.A.",
  },
  {
    Codigo: "142",
    Nome: "Broker Brasil Corretora de Câmbio Ltda.",
    NomeCurto: "BROKER BRASIL CC LTDA.",
  },
  {
    Codigo: "143",
    Nome: "INTEX BANK BANCO DE CÂMBIO S.A.",
    NomeCurto: "INTEX BANK BCO DE CÂMBIO S.A.",
  },
  {
    Codigo: "144",
    Nome: "EBURY BANCO DE CÂMBIO S.A.",
    NomeCurto: "EBURY BCO DE CÂMBIO S.A.",
  },
  {
    Codigo: "145",
    Nome: "LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.",
    NomeCurto: "LEVYCAM CCV LTDA",
  },
  {
    Codigo: "146",
    Nome: "GUITTA CORRETORA DE CAMBIO LTDA.",
    NomeCurto: "GUITTA CC LTDA",
  },
  {
    Codigo: "149",
    Nome: "Facta Financeira S.A. - Crédito Financiamento e Investimento",
    NomeCurto: "FACTA S.A. CFI",
  },
  {
    Codigo: "157",
    Nome: "ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.",
    NomeCurto: "ICAP DO BRASIL CTVM LTDA.",
  },
  {
    Codigo: "159",
    Nome: "Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor",
    NomeCurto: "CASA CREDITO S.A. SCM",
  },
  {
    Codigo: "163",
    Nome: "Commerzbank Brasil S.A. - Banco Múltiplo",
    NomeCurto: "COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO",
  },
  {
    Codigo: "173",
    Nome: "BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.",
    NomeCurto: "BRL TRUST DTVM SA",
  },
  {
    Codigo: "174",
    Nome: "PEFISA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    NomeCurto: "PEFISA S.A. - C.F.I.",
  },
  {
    Codigo: "177",
    Nome: "Guide Investimentos S.A. Corretora de Valores",
    NomeCurto: "GUIDE",
  },
  {
    Codigo: "180",
    Nome: "CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA",
    NomeCurto: "CM CAPITAL MARKETS CCTVM LTDA",
  },
  {
    Codigo: "183",
    Nome: "SOCRED S.A. - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE",
    NomeCurto: "SOCRED SA - SCMEPP",
  },
  {
    Codigo: "184",
    Nome: "Banco Itaú BBA S.A.",
    NomeCurto: "BCO ITAÚ BBA S.A.",
  },
  {
    Codigo: "188",
    Nome: "ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES",
    NomeCurto: "ATIVA S.A. INVESTIMENTOS CCTVM",
  },
  {
    Codigo: "189",
    Nome: "HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS",
    NomeCurto: "HS FINANCEIRA",
  },
  {
    Codigo: "190",
    Nome: "SERVICOOP - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS E MUNICIPAIS DO RIO GRANDE DO SUL",
    NomeCurto: "SERVICOOP",
  },
  {
    Codigo: "191",
    Nome: "Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.",
    NomeCurto: "NOVA FUTURA CTVM LTDA.",
  },
  {
    Codigo: "194",
    Nome: "UNIDA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
    NomeCurto: "UNIDA DTVM LTDA",
  },
  {
    Codigo: "195",
    Nome: "VALOR S/A SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    NomeCurto: "VALOR S/A SCFI",
  },
  {
    Codigo: "196",
    Nome: "FAIR CORRETORA DE CAMBIO S.A.",
    NomeCurto: "FAIR CC S.A.",
  },
  {
    Codigo: "197",
    Nome: "STONE INSTITUIÇÃO DE PAGAMENTO S.A.",
    NomeCurto: "STONE IP S.A.",
  },
  {
    Codigo: "208",
    Nome: "Banco BTG Pactual S.A.",
    NomeCurto: "BANCO BTG PACTUAL S.A.",
  },
  {
    Codigo: "212",
    Nome: "Banco Original S.A.",
    NomeCurto: "BANCO ORIGINAL",
  },
  {
    Codigo: "213",
    Nome: "Banco Arbi S.A.",
    NomeCurto: "BCO ARBI S.A.",
  },
  {
    Codigo: "217",
    Nome: "Banco John Deere S.A.",
    NomeCurto: "BANCO JOHN DEERE S.A.",
  },
  {
    Codigo: "218",
    Nome: "Banco BS2 S.A.",
    NomeCurto: "BCO BS2 S.A.",
  },
  {
    Codigo: "222",
    Nome: "BANCO CRÉDIT AGRICOLE BRASIL S.A.",
    NomeCurto: "BCO CRÉDIT AGRICOLE BR S.A.",
  },
  {
    Codigo: "224",
    Nome: "Banco Fibra S.A.",
    NomeCurto: "BCO FIBRA S.A.",
  },
  {
    Codigo: "233",
    Nome: "Banco Cifra S.A.",
    NomeCurto: "BANCO CIFRA",
  },
  {
    Codigo: "237",
    Nome: "Banco Bradesco S.A.",
    NomeCurto: "BCO BRADESCO S.A.",
  },
  {
    Codigo: "241",
    Nome: "BANCO CLASSICO S.A.",
    NomeCurto: "BCO CLASSICO S.A.",
  },
  {
    Codigo: "243",
    Nome: "BANCO MASTER S/A",
    NomeCurto: "BANCO MASTER",
  },
  {
    Codigo: "246",
    Nome: "Banco ABC Brasil S.A.",
    NomeCurto: "BCO ABC BRASIL S.A.",
  },
  {
    Codigo: "249",
    Nome: "Banco Investcred Unibanco S.A.",
    NomeCurto: "BANCO INVESTCRED UNIBANCO S.A.",
  },
  {
    Codigo: "250",
    Nome: "BANCO BMG CONSIGNADO S.A.",
    NomeCurto: "BANCO BMG CONSIGNADO S.A.",
  },
  {
    Codigo: "253",
    Nome: "Bexs Corretora de Câmbio S/A",
    NomeCurto: "BEXS CC S.A.",
  },
  {
    Codigo: "254",
    Nome: "PARANÁ BANCO S.A.",
    NomeCurto: "PARANA BCO S.A.",
  },
  {
    Codigo: "259",
    Nome: "MONEYCORP BANCO DE CÂMBIO S.A.",
    NomeCurto: "MONEYCORP BCO DE CÂMBIO S.A.",
  },
  {
    Codigo: "260",
    Nome: "NU PAGAMENTOS S.A. - INSTITUIÇÃO DE PAGAMENTO",
    NomeCurto: "NU PAGAMENTOS - IP",
  },
  {
    Codigo: "265",
    Nome: "Banco Fator S.A.",
    NomeCurto: "BCO FATOR S.A.",
  },
  {
    Codigo: "266",
    Nome: "BANCO CEDULA S.A.",
    NomeCurto: "BCO CEDULA S.A.",
  },
  {
    Codigo: "268",
    Nome: "BARI COMPANHIA HIPOTECÁRIA",
    NomeCurto: "BARI CIA HIPOTECÁRIA",
  },
  {
    Codigo: "269",
    Nome: "BANCO HSBC S.A.",
    NomeCurto: "BCO HSBC S.A.",
  },
  {
    Codigo: "270",
    Nome: "SAGITUR CORRETORA DE CÂMBIO S.A.",
    NomeCurto: "SAGITUR CC - EM LIQUIDAÇÃO EXTRAJUDICIAL",
  },
  {
    Codigo: "271",
    Nome: "BPY CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    NomeCurto: "BPY CCTVM S.A.",
  },
  {
    Codigo: "272",
    Nome: "AGK CORRETORA DE CAMBIO S.A.",
    NomeCurto: "AGK CC S.A.",
  },
  {
    Codigo: "273",
    Nome: "COOPERATIVA DE CREDITO SULCREDI AMPLEA",
    NomeCurto: "COOP SULCREDI AMPLEA",
  },
  {
    Codigo: "274",
    Nome: "BMP SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LTDA.",
    NomeCurto: "BMP SCMEPP LTDA",
  },
  {
    Codigo: "276",
    Nome: "BANCO SENFF S.A.",
    NomeCurto: "BCO SENFF S.A.",
  },
  {
    Codigo: "278",
    Nome: "Genial Investimentos Corretora de Valores Mobiliários S.A.",
    NomeCurto: "GENIAL INVESTIMENTOS CVM S.A.",
  },
  {
    Codigo: "279",
    Nome: "PRIMACREDI CREDISIS - COOPERATIVA DE CRÉDITO DE PRIMAVERA DO LESTE",
    NomeCurto: "COOP DE PRIMAVERA DO LESTE",
  },
  {
    Codigo: "280",
    Nome: "WILL FINANCEIRA S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    NomeCurto: "WILL FINANCEIRA S.A.CFI",
  },
  {
    Codigo: "281",
    Nome: "Cooperativa de Crédito Rural Coopavel",
    NomeCurto: "CCR COOPAVEL",
  },
  {
    Codigo: "283",
    Nome: "RB INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LIMITADA",
    NomeCurto: "RB INVESTIMENTOS DTVM LTDA.",
  },
  {
    Codigo: "285",
    Nome: "FRENTE CORRETORA DE CÂMBIO S.A.",
    NomeCurto: "FRENTE CC S.A.",
  },
  {
    Codigo: "286",
    Nome: "UNIPRIME OURO - COOPERATIVA DE CRÉDITO DE OURO",
    NomeCurto: "UNIPRIME OURO - COOP DE OURO",
  },
  {
    Codigo: "288",
    Nome: "CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
    NomeCurto: "CAROL DTVM LTDA.",
  },
  {
    Codigo: "289",
    Nome: "EFX CORRETORA DE CÂMBIO LTDA.",
    NomeCurto: "EFX CC LTDA.",
  },
  {
    Codigo: "290",
    Nome: "PAGSEGURO INTERNET INSTITUIÇÃO DE PAGAMENTO S.A.",
    NomeCurto: "PAGSEGURO INTERNET IP S.A.",
  },
  {
    Codigo: "292",
    Nome: "GALAPAGOS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    NomeCurto: "GALAPAGOS DTVM S.A.",
  },
  {
    Codigo: "293",
    Nome: "Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.",
    NomeCurto: "LASTRO RDV DTVM LTDA",
  },
  {
    Codigo: "296",
    Nome: "OZ CORRETORA DE CÂMBIO S.A.",
    NomeCurto: "OZ CORRETORA DE CÂMBIO S.A.",
  },
  {
    Codigo: "298",
    Nome: "Vip's Corretora de Câmbio Ltda.",
    NomeCurto: "VIPS CC LTDA.",
  },
  {
    Codigo: "299",
    Nome: "BANCO AFINZ S.A. - BANCO MÚLTIPLO",
    NomeCurto: "BCO AFINZ S.A. - BM",
  },
  {
    Codigo: "300",
    Nome: "Banco de la Nacion Argentina",
    NomeCurto: "BCO LA NACION ARGENTINA",
  },
  {
    Codigo: "301",
    Nome: "DOCK INSTITUIÇÃO DE PAGAMENTO S.A.",
    NomeCurto: "DOCK IP S.A.",
  },
  {
    Codigo: "305",
    Nome: "FOURTRADE CORRETORA DE CÂMBIO LTDA.",
    NomeCurto: "FOURTRADE COR. DE CAMBIO LTDA",
  },
  {
    Codigo: "306",
    Nome: "PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
    NomeCurto: "PORTOPAR DTVM LTDA",
  },
  {
    Codigo: "307",
    Nome: "Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.",
    NomeCurto: "TERRA INVESTIMENTOS DTVM",
  },
  {
    Codigo: "309",
    Nome: "CAMBIONET CORRETORA DE CÂMBIO LTDA.",
    NomeCurto: "CAMBIONET CC LTDA",
  },
  {
    Codigo: "310",
    Nome: "VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.",
    NomeCurto: "VORTX DTVM LTDA.",
  },
  {
    Codigo: "311",
    Nome: "DOURADA CORRETORA DE CÂMBIO LTDA.",
    NomeCurto: "DOURADA CORRETORA",
  },
  {
    Codigo: "312",
    Nome: "HSCM - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.",
    NomeCurto: "HSCM SCMEPP LTDA.",
  },
  {
    Codigo: "313",
    Nome: "AMAZÔNIA CORRETORA DE CÂMBIO LTDA.",
    NomeCurto: "AMAZÔNIA CC LTDA.",
  },
  {
    Codigo: "315",
    Nome: "PI Distribuidora de Títulos e Valores Mobiliários S.A.",
    NomeCurto: "PI DTVM S.A.",
  },
  {
    Codigo: "318",
    Nome: "Banco BMG S.A.",
    NomeCurto: "BCO BMG S.A.",
  },
  {
    Codigo: "319",
    Nome: "OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
    NomeCurto: "OM DTVM LTDA",
  },
  {
    Codigo: "320",
    Nome: "BANK OF CHINA (BRASIL) BANCO MÚLTIPLO S/A",
    NomeCurto: "BOC BRASIL",
  },
  {
    Codigo: "321",
    Nome: "CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE S.A.",
    NomeCurto: "CREFAZ SCMEPP SA",
  },
  {
    Codigo: "322",
    Nome: "Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz",
    NomeCurto: "CCR DE ABELARDO LUZ",
  },
  {
    Codigo: "323",
    Nome: "MERCADO PAGO INSTITUIÇÃO DE PAGAMENTO LTDA.",
    NomeCurto: "MERCADO PAGO IP LTDA.",
  },
  {
    Codigo: "324",
    Nome: "CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "CARTOS SCD S.A.",
  },
  {
    Codigo: "325",
    Nome: "Órama Distribuidora de Títulos e Valores Mobiliários S.A.",
    NomeCurto: "ÓRAMA DTVM S.A.",
  },
  {
    Codigo: "326",
    Nome: "PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
    NomeCurto: "PARATI - CFI S.A.",
  },
  {
    Codigo: "328",
    Nome: "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FABRICANTES DE CALÇADOS DE SAPIRANGA LTDA.",
    NomeCurto: "CECM FABRIC CALÇADOS SAPIRANGA",
  },
  {
    Codigo: "329",
    Nome: "QI Sociedade de Crédito Direto S.A.",
    NomeCurto: "QI SCD S.A.",
  },
  {
    Codigo: "330",
    Nome: "BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.",
    NomeCurto: "BANCO BARI S.A.",
  },
  {
    Codigo: "331",
    Nome: "OSLO CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A",
    NomeCurto: "OSLO CAPITAL DTVM SA",
  },
  {
    Codigo: "332",
    Nome: "ACESSO SOLUÇÕES DE PAGAMENTO S.A. - INSTITUIÇÃO DE PAGAMENTO",
    NomeCurto: "ACESSO SOLUÇÕES DE PAGAMENTO S.A. - INSTITUIÇÃO DE PAGAMENTO",
  },
  {
    Codigo: "334",
    Nome: "BANCO BESA S.A.",
    NomeCurto: "BANCO BESA S.A.",
  },
  {
    Codigo: "335",
    Nome: "Banco Digio S.A.",
    NomeCurto: "BANCO DIGIO",
  },
  {
    Codigo: "336",
    Nome: "Banco C6 S.A.",
    NomeCurto: "BCO C6 S.A.",
  },
  {
    Codigo: "340",
    Nome: "SUPERDIGITAL INSTITUIÇÃO DE PAGAMENTO S.A.",
    NomeCurto: "SUPERDIGITAL I.P. S.A.",
  },
  {
    Codigo: "341",
    Nome: "ITAÚ UNIBANCO S.A.",
    NomeCurto: "ITAÚ UNIBANCO S.A.",
  },
  {
    Codigo: "342",
    Nome: "Creditas Sociedade de Crédito Direto S.A.",
    NomeCurto: "CREDITAS SCD",
  },
  {
    Codigo: "343",
    Nome: "FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.",
    NomeCurto: "FFA SCMEPP LTDA.",
  },
  {
    Codigo: "348",
    Nome: "Banco XP S.A.",
    NomeCurto: "BCO XP S.A.",
  },
  {
    Codigo: "349",
    Nome: "AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    NomeCurto: "AL5 S.A. CFI",
  },
  {
    Codigo: "350",
    Nome: "COOPERATIVA DE CRÉDITO, POUPANÇA E SERVIÇOS FINANCEIROS DE AGRICULTORES E AEROPORTUÁRIOS DO BRASIL - CREHNOR",
    NomeCurto: "COOP DE AGRICULTORES E AEROPORTUÁRIOS DO BRASIL",
  },
  {
    Codigo: "352",
    Nome: "TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    NomeCurto: "TORO CTVM S.A.",
  },
  {
    Codigo: "354",
    Nome: "NECTON INVESTIMENTOS  S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES",
    NomeCurto: "NECTON INVESTIMENTOS S.A CVM",
  },
  {
    Codigo: "355",
    Nome: "ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "ÓTIMO SCD S.A.",
  },
  {
    Codigo: "358",
    Nome: "MIDWAY S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    NomeCurto: "MIDWAY S.A. - SCFI",
  },
  {
    Codigo: "359",
    Nome: "ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A",
    NomeCurto: "ZEMA CFI S/A",
  },
  {
    Codigo: "360",
    Nome: "TRINUS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    NomeCurto: "TRINUS CAPITAL DTVM",
  },
  {
    Codigo: "362",
    Nome: "CIELO S.A. - INSTITUIÇÃO DE PAGAMENTO",
    NomeCurto: "CIELO IP S.A.",
  },
  {
    Codigo: "363",
    Nome: "SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    NomeCurto: "SINGULARE CTVM S.A.",
  },
  {
    Codigo: "364",
    Nome: "EFÍ S.A. - INSTITUIÇÃO DE PAGAMENTO",
    NomeCurto: "EFÍ S.A. - IP",
  },
  {
    Codigo: "365",
    Nome: "SIMPAUL CORRETORA DE CAMBIO E VALORES MOBILIARIOS  S.A.",
    NomeCurto: "SIMPAUL",
  },
  {
    Codigo: "366",
    Nome: "BANCO SOCIETE GENERALE BRASIL S.A.",
    NomeCurto: "BCO SOCIETE GENERALE BRASIL",
  },
  {
    Codigo: "367",
    Nome: "VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    NomeCurto: "VITREO DTVM S.A.",
  },
  {
    Codigo: "368",
    Nome: "Banco CSF S.A.",
    NomeCurto: "BCO CSF S.A.",
  },
  {
    Codigo: "370",
    Nome: "Banco Mizuho do Brasil S.A.",
    NomeCurto: "BCO MIZUHO S.A.",
  },
  {
    Codigo: "371",
    Nome: "WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.",
    NomeCurto: "WARREN CVMC LTDA",
  },
  {
    Codigo: "373",
    Nome: "UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.",
    NomeCurto: "UP.P SEP S.A.",
  },
  {
    Codigo: "374",
    Nome: "REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
    NomeCurto: "REALIZE CFI S.A.",
  },
  {
    Codigo: "376",
    Nome: "BANCO J.P. MORGAN S.A.",
    NomeCurto: "BCO J.P. MORGAN S.A.",
  },
  {
    Codigo: "377",
    Nome: "BMS SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "BMS SCD S.A.",
  },
  {
    Codigo: "378",
    Nome: "BANCO BRASILEIRO DE CRÉDITO SOCIEDADE ANÔNIMA",
    NomeCurto: "BCO BRASILEIRO DE CRÉDITO S.A.",
  },
  {
    Codigo: "379",
    Nome: "COOPERFORTE - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE FUNCIONÁRIOS DE INSTITUIÇÕES FINANCEIRAS PÚBLICAS FEDERAIS LTDA.",
    NomeCurto: "CECM COOPERFORTE",
  },
  {
    Codigo: "380",
    Nome: "PICPAY INSTITUIçãO DE PAGAMENTO S.A.",
    NomeCurto: "PICPAY",
  },
  {
    Codigo: "381",
    Nome: "BANCO MERCEDES-BENZ DO BRASIL S.A.",
    NomeCurto: "BCO MERCEDES-BENZ S.A.",
  },
  {
    Codigo: "382",
    Nome: "FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LIMITADA.",
    NomeCurto: "FIDUCIA SCMEPP LTDA",
  },
  {
    Codigo: "383",
    Nome: "EBANX INSTITUICAO DE PAGAMENTOS LTDA.",
    NomeCurto: "EBANX IP LTDA.",
  },
  {
    Codigo: "384",
    Nome: "GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.",
    NomeCurto: "GLOBAL SCM LTDA",
  },
  {
    Codigo: "385",
    Nome: "COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS TRABALHADORES PORTUARIOS DA GRANDE VITORIA - CREDESTIVA.",
    NomeCurto: "CECM DOS TRAB.PORT. DA G.VITOR",
  },
  {
    Codigo: "386",
    Nome: "NU FINANCEIRA S.A. - Sociedade de Crédito, Financiamento e Investimento",
    NomeCurto: "NU FINANCEIRA S.A. CFI",
  },
  {
    Codigo: "387",
    Nome: "Banco Toyota do Brasil S.A.",
    NomeCurto: "BCO TOYOTA DO BRASIL S.A.",
  },
  {
    Codigo: "389",
    Nome: "Banco Mercantil do Brasil S.A.",
    NomeCurto: "BCO MERCANTIL DO BRASIL S.A.",
  },
  {
    Codigo: "390",
    Nome: "BANCO GM S.A.",
    NomeCurto: "BCO GM S.A.",
  },
  {
    Codigo: "391",
    Nome: "COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM",
    NomeCurto: "CCR DE IBIAM",
  },
  {
    Codigo: "393",
    Nome: "Banco Volkswagen S.A.",
    NomeCurto: "BCO VOLKSWAGEN S.A",
  },
  {
    Codigo: "394",
    Nome: "Banco Bradesco Financiamentos S.A.",
    NomeCurto: "BCO BRADESCO FINANC. S.A.",
  },
  {
    Codigo: "395",
    Nome: "F.D'GOLD - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
    NomeCurto: "F D GOLD DTVM LTDA",
  },
  {
    Codigo: "396",
    Nome: "MAGALUPAY INSTITUIÇÃO DE PAGAMENTO S.A.",
    NomeCurto: "MAGALUPAY",
  },
  {
    Codigo: "397",
    Nome: "LISTO SOCIEDADE DE CREDITO DIRETO S.A.",
    NomeCurto: "LISTO SCD S.A.",
  },
  {
    Codigo: "398",
    Nome: "IDEAL CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    NomeCurto: "IDEAL CTVM S.A.",
  },
  {
    Codigo: "399",
    Nome: "Kirton Bank S.A. - Banco Múltiplo",
    NomeCurto: "KIRTON BANK",
  },
  {
    Codigo: "400",
    Nome: "COOPERATIVA DE CRÉDITO, POUPANÇA E SERVIÇOS FINANCEIROS",
    NomeCurto: "COOP CREDITAG",
  },
  {
    Codigo: "401",
    Nome: "IUGU INSTITUIÇÃO DE PAGAMENTO S.A.",
    NomeCurto: "IUGU IP S.A.",
  },
  {
    Codigo: "402",
    Nome: "COBUCCIO S/A - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTOS",
    NomeCurto: "COBUCCIO S.A. SCFI",
  },
  {
    Codigo: "403",
    Nome: "CORA SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
    NomeCurto: "CORA SCFI",
  },
  {
    Codigo: "404",
    Nome: "SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "SUMUP SCD S.A.",
  },
  {
    Codigo: "406",
    Nome: "ACCREDITO - SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "ACCREDITO SCD S.A.",
  },
  {
    Codigo: "407",
    Nome: "SEFER INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
    NomeCurto: "SEFER INVESTIMENTOS DTVM LTDA",
  },
  {
    Codigo: "408",
    Nome: "BONUSPAGO SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "BONUSPAGO SCD S.A.",
  },
  {
    Codigo: "410",
    Nome: "PLANNER SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "PLANNER SOCIEDADE DE CRÉDITO DIRETO",
  },
  {
    Codigo: "411",
    Nome: "Via Certa Financiadora S.A. - Crédito, Financiamento e Investimentos",
    NomeCurto: "VIA CERTA FINANCIADORA S.A. - CFI",
  },
  {
    Codigo: "412",
    Nome: "SOCIAL BANK BANCO MÚLTIPLO S/A",
    NomeCurto: "SOCIAL BANK S/A",
  },
  {
    Codigo: "413",
    Nome: "BANCO BV S.A.",
    NomeCurto: "BCO BV S.A.",
  },
  {
    Codigo: "414",
    Nome: "LEND SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "LEND SCD S.A.",
  },
  {
    Codigo: "415",
    Nome: "BANCO NACIONAL S.A.",
    NomeCurto: "BCO NACIONAL",
  },
  {
    Codigo: "416",
    Nome: "LAMARA SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "LAMARA SCD S.A.",
  },
  {
    Codigo: "418",
    Nome: "ZIPDIN SOLUÇÕES DIGITAIS SOCIEDADE DE CRÉDITO DIRETO S/A",
    NomeCurto: "ZIPDIN SCD S.A.",
  },
  {
    Codigo: "419",
    Nome: "NUMBRS SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "NUMBRS SCD S.A.",
  },
  {
    Codigo: "421",
    Nome: "LAR COOPERATIVA DE CRÉDITO - LAR CREDI",
    NomeCurto: "CC LAR CREDI",
  },
  {
    Codigo: "422",
    Nome: "Banco Safra S.A.",
    NomeCurto: "BCO SAFRA S.A.",
  },
  {
    Codigo: "423",
    Nome: "COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIÁRIOS",
    NomeCurto: "COLUNA S.A. DTVM",
  },
  {
    Codigo: "425",
    Nome: "SOCINAL S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    NomeCurto: "SOCINAL S.A. CFI",
  },
  {
    Codigo: "426",
    Nome: "NEON FINANCEIRA - CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
    NomeCurto: "NEON FINANCEIRA - CFI S.A.",
  },
  {
    Codigo: "427",
    Nome: "COOPERATIVA DE CRÉDITO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DO ESPIRITO SANTO",
    NomeCurto: "CRED.UFES",
  },
  {
    Codigo: "428",
    Nome: "CREDSYSTEM SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "CREDSYSTEM SCD S.A.",
  },
  {
    Codigo: "429",
    Nome: "Crediare S.A. - Crédito, financiamento e investimento",
    NomeCurto: "CREDIARE CFI S.A.",
  },
  {
    Codigo: "430",
    Nome: "COOPERATIVA DE CREDITO RURAL SEARA - CREDISEARA",
    NomeCurto: "CCR SEARA",
  },
  {
    Codigo: "433",
    Nome: "BR-CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    NomeCurto: "BR-CAPITAL DTVM S.A.",
  },
  {
    Codigo: "435",
    Nome: "DELCRED SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "DELCRED SCD S.A.",
  },
  {
    Codigo: "438",
    Nome: "TRUSTEE DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
    NomeCurto: "TRUSTEE DTVM LTDA.",
  },
  {
    Codigo: "439",
    Nome: "ID CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    NomeCurto: "ID CTVM",
  },
  {
    Codigo: "440",
    Nome: "CREDIBRF - COOPERATIVA DE CRÉDITO",
    NomeCurto: "CREDIBRF COOP",
  },
  {
    Codigo: "442",
    Nome: "MAGNETIS - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
    NomeCurto: "MAGNETIS - DTVM",
  },
  {
    Codigo: "443",
    Nome: "CREDIHOME SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "CREDIHOME SCD",
  },
  {
    Codigo: "444",
    Nome: "TRINUS SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "TRINUS SCD S.A.",
  },
  {
    Codigo: "445",
    Nome: "PLANTAE S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    NomeCurto: "PLANTAE CFI",
  },
  {
    Codigo: "447",
    Nome: "MIRAE ASSET (BRASIL) CORRETORA DE CÂMBIO, TÍTULOS  E VALORES MOBILIÁRIOS LTDA.",
    NomeCurto: "MIRAE ASSET (BRASIL) CCTVM LTDA.",
  },
  {
    Codigo: "448",
    Nome: "HEMERA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
    NomeCurto: "HEMERA DTVM LTDA.",
  },
  {
    Codigo: "449",
    Nome: "DM SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "DM",
  },
  {
    Codigo: "450",
    Nome: "FITBANK INSTITUIÇÃO DE PAGAMENTO S.A.",
    NomeCurto: "FITBANK IP",
  },
  {
    Codigo: "451",
    Nome: "J17 - SOCIEDADE DE CRÉDITO DIRETO S/A",
    NomeCurto: "J17 - SCD S/A",
  },
  {
    Codigo: "452",
    Nome: "CREDIFIT SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "CREDIFIT SCD S.A.",
  },
  {
    Codigo: "454",
    Nome: "MÉRITO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
    NomeCurto: "MÉRITO DTVM LTDA.",
  },
  {
    Codigo: "455",
    Nome: "FÊNIX DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
    NomeCurto: "FÊNIX DTVM LTDA.",
  },
  {
    Codigo: "456",
    Nome: "Banco MUFG Brasil S.A.",
    NomeCurto: "BCO MUFG BRASIL S.A.",
  },
  {
    Codigo: "457",
    Nome: "UY3 SOCIEDADE DE CRÉDITO DIRETO S/A",
    NomeCurto: "UY3 SCD S/A",
  },
  {
    Codigo: "458",
    Nome: "HEDGE INVESTMENTS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
    NomeCurto: "HEDGE INVESTMENTS DTVM LTDA.",
  },
  {
    Codigo: "459",
    Nome: "COOPERATIVA DE CRÉDITO MÚTUO DE SERVIDORES PÚBLICOS DO ESTADO DE SÃO PAULO - CREDIFISCO",
    NomeCurto: "CCM SERV. PÚBLICOS SP",
  },
  {
    Codigo: "460",
    Nome: "UNAVANTI SOCIEDADE DE CRÉDITO DIRETO S/A",
    NomeCurto: "UNAVANTI SCD S/A",
  },
  {
    Codigo: "461",
    Nome: "ASAAS GESTÃO FINANCEIRA INSTITUIÇÃO DE PAGAMENTO S.A.",
    NomeCurto: "ASAAS IP S.A.",
  },
  {
    Codigo: "462",
    Nome: "STARK SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "STARK SCD S.A.",
  },
  {
    Codigo: "463",
    Nome: "AZUMI DISTRIBUIDORA DE TíTULOS E VALORES MOBILIáRIOS LTDA.",
    NomeCurto: "AZUMI DTVM",
  },
  {
    Codigo: "464",
    Nome: "Banco Sumitomo Mitsui Brasileiro S.A.",
    NomeCurto: "BCO SUMITOMO MITSUI BRASIL S.A.",
  },
  {
    Codigo: "465",
    Nome: "CAPITAL CONSIG SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "CAPITAL CONSIG SCD S.A.",
  },
  {
    Codigo: "467",
    Nome: "MASTER S/A CORRETORA DE CâMBIO, TíTULOS E VALORES MOBILIáRIOS",
    NomeCurto: "MASTER S/A CCTVM",
  },
  {
    Codigo: "468",
    Nome: "PORTOSEG S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO",
    NomeCurto: "PORTOSEG S.A. CFI",
  },
  {
    Codigo: "469",
    Nome: "PICPAY INVEST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
    NomeCurto: "PICPAY INVEST",
  },
  {
    Codigo: "470",
    Nome: "CDC SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "CDC SCD S.A.",
  },
  {
    Codigo: "471",
    Nome: "COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS SERVIDORES PUBLICOS DE PINHÃO - CRESERV-PINHÃO",
    NomeCurto: "CECM SERV PUBL PINHÃO",
  },
  {
    Codigo: "473",
    Nome: "Banco Caixa Geral - Brasil S.A.",
    NomeCurto: "BCO CAIXA GERAL BRASIL S.A.",
  },
  {
    Codigo: "475",
    Nome: "Banco Yamaha Motor do Brasil S.A.",
    NomeCurto: "BCO YAMAHA MOTOR S.A.",
  },
  {
    Codigo: "477",
    Nome: "Citibank N.A.",
    NomeCurto: "CITIBANK N.A.",
  },
  {
    Codigo: "478",
    Nome: "GAZINCRED S.A. SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    NomeCurto: "GAZINCRED S.A. SCFI",
  },
  {
    Codigo: "479",
    Nome: "Banco ItauBank S.A.",
    NomeCurto: "BCO ITAUBANK S.A.",
  },
  {
    Codigo: "481",
    Nome: "SUPERLÓGICA SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "SUPERLÓGICA SCD S.A.",
  },
  {
    Codigo: "482",
    Nome: "SBCASH SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "SBCASH SCD",
  },
  {
    Codigo: "484",
    Nome: "MAF DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    NomeCurto: "MAF DTVM SA",
  },
  {
    Codigo: "487",
    Nome: "DEUTSCHE BANK S.A. - BANCO ALEMAO",
    NomeCurto: "DEUTSCHE BANK S.A.BCO ALEMAO",
  },
  {
    Codigo: "488",
    Nome: "JPMorgan Chase Bank, National Association",
    NomeCurto: "JPMORGAN CHASE BANK",
  },
  {
    Codigo: "492",
    Nome: "ING Bank N.V.",
    NomeCurto: "ING BANK N.V.",
  },
  {
    Codigo: "495",
    Nome: "Banco de La Provincia de Buenos Aires",
    NomeCurto: "BCO LA PROVINCIA B AIRES BCE",
  },
  {
    Codigo: "505",
    Nome: "Banco Credit Suisse (Brasil) S.A.",
    NomeCurto: "BCO CREDIT SUISSE S.A.",
  },
  {
    Codigo: "506",
    Nome: "RJI CORRETORA DE TITULOS E VALORES MOBILIARIOS LTDA",
    NomeCurto: "RJI",
  },
  {
    Codigo: "507",
    Nome: "SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO EFÍ S.A.",
    NomeCurto: "SCFI EFÍ S.A.",
  },
  {
    Codigo: "508",
    Nome: "AVENUE SECURITIES DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
    NomeCurto: "AVENUE SECURITIES DTVM LTDA.",
  },
  {
    Codigo: "509",
    Nome: "CELCOIN INSTITUICAO DE PAGAMENTO S.A.",
    NomeCurto: "CELCOIN IP S.A.",
  },
  {
    Codigo: "510",
    Nome: "FFCRED SOCIEDADE DE CRÉDITO DIRETO S.A..",
    NomeCurto: "FFCRED SCD S.A.",
  },
  {
    Codigo: "511",
    Nome: "MAGNUM SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "MAGNUM SCD",
  },
  {
    Codigo: "512",
    Nome: "FINVEST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
    NomeCurto: "FINVEST DTVM",
  },
  {
    Codigo: "513",
    Nome: "ATF SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "ATF SCD S.A.",
  },
  {
    Codigo: "514",
    Nome: "EXIM CORRETORA DE CAMBIO LTDA",
    NomeCurto: "EXIM CC LTDA.",
  },
  {
    Codigo: "516",
    Nome: "QISTA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    NomeCurto: "QISTA S.A. CFI",
  },
  {
    Codigo: "518",
    Nome: "MERCADO CRÉDITO SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.",
    NomeCurto: "MERCADO CRÉDITO SCFI S.A.",
  },
  {
    Codigo: "519",
    Nome: "LIONS TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
    NomeCurto: "LIONS TRUST DTVM",
  },
  {
    Codigo: "520",
    Nome: "SOMAPAY SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "SOMAPAY SCD S.A.",
  },
  {
    Codigo: "521",
    Nome: "PEAK SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.",
    NomeCurto: "PEAK SEP S.A.",
  },
  {
    Codigo: "522",
    Nome: "RED SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "RED SCD S.A.",
  },
  {
    Codigo: "523",
    Nome: "HR DIGITAL - SOCIEDADE DE CRÉDITO DIRETO S/A",
    NomeCurto: "HR DIGITAL SCD",
  },
  {
    Codigo: "524",
    Nome: "WNT CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    NomeCurto: "WNT CAPITAL DTVM",
  },
  {
    Codigo: "525",
    Nome: "INTERCAM CORRETORA DE CÂMBIO LTDA.",
    NomeCurto: "INTERCAM CC LTDA",
  },
  {
    Codigo: "526",
    Nome: "MONETARIE SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "MONETARIE SCD",
  },
  {
    Codigo: "527",
    Nome: "ATICCA - SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "ATICCA SCD S.A.",
  },
  {
    Codigo: "528",
    Nome: "REAG TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    NomeCurto: "REAG TRUST DTVM",
  },
  {
    Codigo: "529",
    Nome: "PINBANK BRASIL INSTITUIÇÃO DE PAGAMENTO S.A.",
    NomeCurto: "PINBANK IP",
  },
  {
    Codigo: "530",
    Nome: "SER FINANCE SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "SER FINANCE SCD S.A.",
  },
  {
    Codigo: "531",
    Nome: "BMP SOCIEDADE DE CRÉDITO DIRETO S.A",
    NomeCurto: "BMP SCD S.A.",
  },
  {
    Codigo: "532",
    Nome: "EAGLE SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "EAGLE SCD S.A.",
  },
  {
    Codigo: "533",
    Nome: "SRM BANK INSTITUIÇÃO DE PAGAMENTO S/A",
    NomeCurto: "SRM BANK",
  },
  {
    Codigo: "534",
    Nome: "EWALLY INSTITUIÇÃO DE PAGAMENTO S.A.",
    NomeCurto: "EWALLY IP S.A.",
  },
  {
    Codigo: "535",
    Nome: "OPEA SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "OPEA SCD",
  },
  {
    Codigo: "536",
    Nome: "NEON PAGAMENTOS S.A. - INSTITUIÇÃO DE PAGAMENTO",
    NomeCurto: "NEON PAGAMENTOS S.A. IP",
  },
  {
    Codigo: "537",
    Nome: "MICROCASH SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.",
    NomeCurto: "MICROCASH SCMEPP LTDA.",
  },
  {
    Codigo: "538",
    Nome: "SUDACRED SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "SUDACRED SCD S.A.",
  },
  {
    Codigo: "539",
    Nome: "SANTINVEST S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTOS",
    NomeCurto: "SANTINVEST S.A. - CFI",
  },
  {
    Codigo: "540",
    Nome: "HBI SOCIEDADE DE CRÉDITO DIRETO S/A.",
    NomeCurto: "HBI SCD",
  },
  {
    Codigo: "541",
    Nome: "FUNDO GARANTIDOR DE CREDITOS - FGC",
    NomeCurto: "FDO GARANTIDOR CRÉDITOS",
  },
  {
    Codigo: "542",
    Nome: "CLOUDWALK INSTITUIÇÃO DE PAGAMENTO E SERVICOS LTDA",
    NomeCurto: "CLOUDWALK IP LTDA",
  },
  {
    Codigo: "543",
    Nome: "COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS ELETRICITÁRIOS E DOS TRABALHADORES DAS EMPRESAS DO SETOR DE ENERGIA - COOPCRECE",
    NomeCurto: "COOPCRECE",
  },
  {
    Codigo: "544",
    Nome: "MULTICRED SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "MULTICRED SCD S.A.",
  },
  {
    Codigo: "545",
    Nome: "SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A",
    NomeCurto: "SENSO CCVM S.A.",
  },
  {
    Codigo: "546",
    Nome: "U4C INSTITUIÇÃO DE PAGAMENTO S.A.",
    NomeCurto: "U4C INSTITUIÇÃO DE PAGAMENTO S.A.",
  },
  {
    Codigo: "547",
    Nome: "BNK DIGITAL SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "BNK DIGITAL SCD S.A.",
  },
  {
    Codigo: "548",
    Nome: "RPW S/A SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    NomeCurto: "RPW S.A. SCFI",
  },
  {
    Codigo: "549",
    Nome: "INTRA INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
    NomeCurto: "INTRA DTVM",
  },
  {
    Codigo: "550",
    Nome: "BEETELLER INSTITUIÇÃO DE PAGAMENTO LTDA.",
    NomeCurto: "BEETELLER",
  },
  {
    Codigo: "551",
    Nome: "VERT DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
    NomeCurto: "VERT DTVM LTDA.",
  },
  {
    Codigo: "552",
    Nome: "UZZIPAY INSTITUIÇÃO DE PAGAMENTO S.A.",
    NomeCurto: "UZZIPAY IP S.A.",
  },
  {
    Codigo: "553",
    Nome: "PERCAPITAL SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "PERCAPITAL SCD S.A.",
  },
  {
    Codigo: "554",
    Nome: "STONEX BANCO DE CÂMBIO S.A.",
    NomeCurto: "STONEX BANCO DE CÂMBIO S.A.",
  },
  {
    Codigo: "555",
    Nome: "PAN FINANCEIRA S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTOS",
    NomeCurto: "PAN CFI",
  },
  {
    Codigo: "556",
    Nome: "SAYGO CORRETORA DE CÂMBIO S.A.",
    NomeCurto: "SAYGO CÂMBIO",
  },
  {
    Codigo: "557",
    Nome: "PAGPRIME INSTITUICAO DE PAGAMENTO LTDA",
    NomeCurto: "PAGPRIME IP",
  },
  {
    Codigo: "558",
    Nome: "QI DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
    NomeCurto: "QI DTVM LTDA.",
  },
  {
    Codigo: "559",
    Nome: "KANASTRA FINANCEIRA S.A, CREDITO, FINANCIAMENTO E INVESTIMENTO",
    NomeCurto: "KANASTRA CFI",
  },
  {
    Codigo: "560",
    Nome: "MAG INSTITUICAO DE PAGAMENTO LTDA",
    NomeCurto: "MAG IP LTDA.",
  },
  {
    Codigo: "561",
    Nome: "PAY4FUN INSTITUICAO DE PAGAMENTO S.A.",
    NomeCurto: "PAY4FUN IP S.A.",
  },
  {
    Codigo: "562",
    Nome: "AZIMUT BRASIL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
    NomeCurto: "AZIMUT BRASIL DTVM LTDA",
  },
  {
    Codigo: "563",
    Nome: "PROTEGE PAY CASH INSTITUICAO DE PAGAMENTO S/A",
    NomeCurto: "PROTEGE PAY CASH IP S.A.",
  },
  {
    Codigo: "565",
    Nome: "ÁGORA CORRETORA DE TITULOS E VALORES MOBILIARIOS S.A.",
    NomeCurto: "ÁGORA CTVM S.A.",
  },
  {
    Codigo: "566",
    Nome: "FLAGSHIP INSTITUICAO DE PAGAMENTOS LTDA",
    NomeCurto: "FLAGSHIP IP LTDA",
  },
  {
    Codigo: "567",
    Nome: "MERCANTIL FINANCEIRA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    NomeCurto: "MERCANTIL FINANCEIRA",
  },
  {
    Codigo: "568",
    Nome: "BRCONDOS SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "BRCONDOS SCD S.A.",
  },
  {
    Codigo: "569",
    Nome: "CONTA PRONTA INSTITUICAO DE PAGAMENTO LTDA",
    NomeCurto: "CONTA PRONTA IP",
  },
  {
    Codigo: "572",
    Nome: "ALL IN CRED SOCIEDADE DE CREDITO DIRETO S.A.",
    NomeCurto: "ALL IN CRED SCD S.A.",
  },
  {
    Codigo: "575",
    Nome: "DGBK CREDIT S.A. - SOCIEDADE DE CRÉDITO DIRETO.",
    NomeCurto: "DGBK CREDIT S.A. - SOCIEDADE DE CRÉDITO DIRETO.",
  },
  {
    Codigo: "576",
    Nome: "MERCADO BITCOIN INSTITUICAO DE PAGAMENTO LTDA",
    NomeCurto: "MERCADO BITCOIN IP LTDA",
  },
  {
    Codigo: "577",
    Nome: "DESENVOLVE SP - AGÊNCIA DE FOMENTO DO ESTADO DE SÃO PAULO S.A.",
    NomeCurto: "AF DESENVOLVE SP S.A.",
  },
  {
    Codigo: "578",
    Nome: "COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS MUNICIPAIS DA GRANDE VITÓRIA/ES",
    NomeCurto: "SICRES",
  },
  {
    Codigo: "579",
    Nome: "QUADRA SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "QUADRA SCD",
  },
  {
    Codigo: "580",
    Nome: "COOPERATIVA CENTRAL DE CRÉDITO, POUPANÇA E INVESTIMENTO DO SUL E SUDESTE - CENTRAL SICREDI SUL/SUDESTE",
    NomeCurto: "CCCPOUPINV SUL E SUDESTE - CENTRAL SUL/SUDESTE",
  },
  {
    Codigo: "581",
    Nome: "COOPERATIVA CENTRAL DE CRÉDITO, POUPANÇA E INVESTIMENTO DO NORDESTE - CENTRAL SICREDI NORDESTE",
    NomeCurto: "CENTRAL NORDESTE",
  },
  {
    Codigo: "582",
    Nome: "COOPERATIVA CENTRAL DE CRÉDITO, POUPANÇA E INVESTIMENTO DE MATO GROSSO DO SUL, GOIÁS, DISTRITO FEDERAL E TOCANTINS - CENTRAL SICREDI BRASIL CENTRAL",
    NomeCurto: '"CCC POUP INV DE MS, GO, DF E TO"',
  },
  {
    Codigo: "583",
    Nome: "COOPERATIVA CENTRAL DE CRÉDITO, POUPANÇA E INVESTIMENTO DO CENTRO NORTE DO BRASIL - CENTRAL SICREDI CENTRO NORTE",
    NomeCurto: "CCC POUP INV DO CENTRO NORTE DO BRASIL",
  },
  {
    Codigo: "584",
    Nome: "COOPERATIVA CENTRAL DE CRÉDITO, POUPANÇA E INVESTIMENTO DOS ESTADOS DO PARANÁ, SÃO PAULO E RIO DE JANEIRO - CENTRAL SICREDI PR/SP/RJ",
    NomeCurto: '"CCC POUP E INV DOS ESTADOS DO PR, SP E RJ"',
  },
  {
    Codigo: "585",
    Nome: "SETHI SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "SETHI SCD SA",
  },
  {
    Codigo: "586",
    Nome: "Z1 INSTITUIÇÃO DE PAGAMENTO LTDA.",
    NomeCurto: "Z1 IP LTDA.",
  },
  {
    Codigo: "587",
    Nome: "FIDD DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
    NomeCurto: "FIDD DTVM LTDA.",
  },
  {
    Codigo: "588",
    Nome: "PROVER PROMOCAO DE VENDAS INSTITUICAO DE PAGAMENTO LTDA",
    NomeCurto: "PROVER PROMOCAO DE VENDAS IP LTDA.",
  },
  {
    Codigo: "589",
    Nome: "G5 SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "G5 SCD SA",
  },
  {
    Codigo: "590",
    Nome: "REPASSES FINANCEIROS E SOLUCOES TECNOLOGICAS INSTITUICAO DE PAGAMENTO S.A.",
    NomeCurto: "REPASSES FINANCEIROS E SOLUCOES TECNOLOGICAS IP S.A.",
  },
  {
    Codigo: "591",
    Nome: "BANVOX DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA",
    NomeCurto: "BANVOX DTVM",
  },
  {
    Codigo: "592",
    Nome: "INSTITUIÇÃO DE PAGAMENTOS MAPS LTDA.",
    NomeCurto: "MAPS IP LTDA.",
  },
  {
    Codigo: "593",
    Nome: "TRANSFEERA INSTITUIÇÃO DE PAGAMENTO S.A",
    NomeCurto: "TRANSFEERA IP S.A.",
  },
  {
    Codigo: "594",
    Nome: "ASA SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "ASA SOCIEDADE DE CRÉDITO DIRETO S.A.",
  },
  {
    Codigo: "595",
    Nome: "ZOOP TECNOLOGIA & INSTITUICAO DE PAGAMENTO S.A.",
    NomeCurto: "ZOOP MEIOS DE PAGAMENTO",
  },
  {
    Codigo: "597",
    Nome: "ISSUER INSTITUICAO DE PAGAMENTO LTDA.",
    NomeCurto: "ISSUER IP LTDA.",
  },
  {
    Codigo: "599",
    Nome: "AGORACRED S/A SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    NomeCurto: "AGORACRED S/A SCFI",
  },
  {
    Codigo: "600",
    Nome: "Banco Luso Brasileiro S.A.",
    NomeCurto: "BCO LUSO BRASILEIRO S.A.",
  },
  {
    Codigo: "604",
    Nome: "Banco Industrial do Brasil S.A.",
    NomeCurto: "BCO INDUSTRIAL DO BRASIL S.A.",
  },
  {
    Codigo: "610",
    Nome: "Banco VR S.A.",
    NomeCurto: "BCO VR S.A.",
  },
  {
    Codigo: "611",
    Nome: "Banco Paulista S.A.",
    NomeCurto: "BCO PAULISTA S.A.",
  },
  {
    Codigo: "612",
    Nome: "Banco Guanabara S.A.",
    NomeCurto: "BCO GUANABARA S.A.",
  },
  {
    Codigo: "613",
    Nome: "Omni Banco S.A.",
    NomeCurto: "OMNI BANCO S.A.",
  },
  {
    Codigo: "614",
    Nome: "NITRO SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "NITRO SCD S.A.",
  },
  {
    Codigo: "615",
    Nome: "SMART SOLUTIONS GROUP INSTITUICAO DE PAGAMENTO LTDA",
    NomeCurto: "SMART SOLUTIONS GROUP IP LTDA",
  },
  {
    Codigo: "619",
    Nome: "TRIO INSTITUICAO DE PAGAMENTO LTDA.",
    NomeCurto: "TRIO IP LTDA.",
  },
  {
    Codigo: "620",
    Nome: "REVOLUT SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "REVOLUT SCD S.A.",
  },
  {
    Codigo: "623",
    Nome: "Banco Pan S.A.",
    NomeCurto: "BANCO PAN",
  },
  {
    Codigo: "626",
    Nome: "BANCO C6 CONSIGNADO S.A.",
    NomeCurto: "BCO C6 CONSIG",
  },
  {
    Codigo: "630",
    Nome: "BANCO LETSBANK S.A.",
    NomeCurto: "BCO LETSBANK S.A.",
  },
  {
    Codigo: "632",
    Nome: "Z-ON SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "Z-ON SCD S.A.",
  },
  {
    Codigo: "633",
    Nome: "Banco Rendimento S.A.",
    NomeCurto: "BCO RENDIMENTO S.A.",
  },
  {
    Codigo: "634",
    Nome: "BANCO TRIANGULO S.A.",
    NomeCurto: "BCO TRIANGULO S.A.",
  },
  {
    Codigo: "636",
    Nome: "GIRO - SOCIEDADE DE CRÉDITO DIRETO S/A",
    NomeCurto: "GIRO - SCD S/A",
  },
  {
    Codigo: "637",
    Nome: "BANCO SOFISA S.A.",
    NomeCurto: "BCO SOFISA S.A.",
  },
  {
    Codigo: "643",
    Nome: "Banco Pine S.A.",
    NomeCurto: "BCO PINE S.A.",
  },
  {
    Codigo: "644",
    Nome: "321 SOCIEDADE DE CRÉDITO DIRETO S.A.",
    NomeCurto: "321 SCD S.A.",
  },
  {
    Codigo: "646",
    Nome: "DM FINANCEIRA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    NomeCurto: "DM SA CFI",
  },
  {
    Codigo: "651",
    Nome: "PAGARE INSTITUICAO DE PAGAMENTO S.A.",
    NomeCurto: "PAGARE IP S.A.",
  },
  {
    Codigo: "652",
    Nome: "Itaú Unibanco Holding S.A.",
    NomeCurto: "ITAÚ UNIBANCO HOLDING S.A.",
  },
  {
    Codigo: "653",
    Nome: "BANCO VOITER S.A.",
    NomeCurto: "BANCO VOITER",
  },
  {
    Codigo: "654",
    Nome: "BANCO DIGIMAIS S.A.",
    NomeCurto: "BCO DIGIMAIS S.A.",
  },
  {
    Codigo: "655",
    Nome: "Banco Votorantim S.A.",
    NomeCurto: "BCO VOTORANTIM S.A.",
  },
  {
    Codigo: "659",
    Nome: "ONEKEY PAYMENTS INSTITUICAO DE PAGAMENTO SA",
    NomeCurto: "ONEKEY PAYMENTS IP S.A.",
  },
  {
    Codigo: "660",
    Nome: "PAGME INSTITUIÇÃO DE PAGAMENTO LTDA.",
    NomeCurto: "PAGME IP LTDA",
  },
  {
    Codigo: "661",
    Nome: "FREEX CORRETORA DE CAMBIO S.A.",
    NomeCurto: "FREEX CC S.A.",
  },
  {
    Codigo: "662",
    Nome: "WE PAY OUT INSTITUICAO DE PAGAMENTO LTDA.",
    NomeCurto: "WE PAY OUT IP LTDA.",
  },
  {
    Codigo: "663",
    Nome: "ACTUAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.",
    NomeCurto: "ACTUAL DTVM S.A.",
  },
  {
    Codigo: "664",
    Nome: "EAGLE INSTITUICAO DE PAGAMENTO LTDA.",
    NomeCurto: "EAGLE IP LTDA.",
  },
  {
    Codigo: "665",
    Nome: "STARK BANK S.A. - INSTITUICAO DE PAGAMENTO",
    NomeCurto: "STARK BANK S.A. - IP",
  },
  {
    Codigo: "666",
    Nome: "URBANO S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
    NomeCurto: "URBANO S.A. SCFI",
  },
  {
    Codigo: "671",
    Nome: "ZERO INSTITUIÇÃO DE PAGAMENTO S.A.",
    NomeCurto: "ZERO",
  },
  {
    Codigo: "674",
    Nome: "HINOVA PAY INSTITUICAO DE PAGAMENTO S.A.",
    NomeCurto: "HINOVA PAY IP S.A.",
  },
  {
    Codigo: "676",
    Nome: "DUFRIO FINANCEIRA, CRÉDITO, FINANCIAMENTO E INVESTIMENTOS S.A.",
    NomeCurto: "DUFRIO CFI S.A.",
  },
  {
    Codigo: "707",
    Nome: "Banco Daycoval S.A.",
    NomeCurto: "BCO DAYCOVAL S.A",
  },
  {
    Codigo: "712",
    Nome: "OURIBANK S.A. BANCO MÚLTIPLO",
    NomeCurto: "OURIBANK S.A.",
  },
  {
    Codigo: "719",
    Nome: "BANCO MASTER MÚLTIPLO S.A.",
    NomeCurto: "BANCO MASTER MÚLTIPLO",
  },
  {
    Codigo: "720",
    Nome: "BANCO RNX S.A.",
    NomeCurto: "BCO RNX S.A.",
  },
  {
    Codigo: "739",
    Nome: "Banco Cetelem S.A.",
    NomeCurto: "BCO CETELEM S.A.",
  },
  {
    Codigo: "741",
    Nome: "BANCO RIBEIRAO PRETO S.A.",
    NomeCurto: "BCO RIBEIRAO PRETO S.A.",
  },
  {
    Codigo: "743",
    Nome: "Banco Semear S.A.",
    NomeCurto: "BANCO SEMEAR",
  },
  {
    Codigo: "745",
    Nome: "Banco Citibank S.A.",
    NomeCurto: "BCO CITIBANK S.A.",
  },
  {
    Codigo: "746",
    Nome: "Banco Modal S.A.",
    NomeCurto: "BCO MODAL S.A.",
  },
  {
    Codigo: "747",
    Nome: "Banco Rabobank International Brasil S.A.",
    NomeCurto: "BCO RABOBANK INTL BRASIL S.A.",
  },
  {
    Codigo: "748",
    Nome: "BANCO COOPERATIVO SICREDI S.A.",
    NomeCurto: "BCO COOPERATIVO SICREDI S.A.",
  },
  {
    Codigo: "751",
    Nome: "Scotiabank Brasil S.A. Banco Múltiplo",
    NomeCurto: "SCOTIABANK BRASIL",
  },
  {
    Codigo: "752",
    Nome: "Banco BNP Paribas Brasil S.A.",
    NomeCurto: "BCO BNP PARIBAS BRASIL S A",
  },
  {
    Codigo: "753",
    Nome: "Novo Banco Continental S.A. - Banco Múltiplo",
    NomeCurto: "NOVO BCO CONTINENTAL S.A. - BM",
  },
  {
    Codigo: "754",
    Nome: "Banco Sistema S.A.",
    NomeCurto: "BANCO SISTEMA",
  },
  {
    Codigo: "755",
    Nome: "Bank of America Merrill Lynch Banco Múltiplo S.A.",
    NomeCurto: "BOFA MERRILL LYNCH BM S.A.",
  },
  {
    Codigo: "756",
    Nome: "BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB",
    NomeCurto: "BANCO SICOOB S.A.",
  },
  {
    Codigo: "757",
    Nome: "BANCO KEB HANA DO BRASIL S.A.",
    NomeCurto: "BCO KEB HANA DO BRASIL S.A.",
  },
];
