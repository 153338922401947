import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { ReactNode, useMemo } from "react";

interface SelectInputProps<T, A extends string | number> {
  idSelect?: string;
  label: string;
  values: ReadonlyArray<T>;
  getValue: (item: T) => A;
  getRender?: (item: T) => JSX.Element | string;
  value: A | null;
  onChange: (newValue: A | null) => void;
  disabled?: boolean;
  limparSelecao?: boolean;
  required?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  helperText?: ReactNode;
  error?: boolean;
}

const getRenderDefault = <T, A extends string | number>(
  item: T,
  getValue: (item: T) => A
): JSX.Element => <>{getValue(item)}</>;

const SelectInput = <T, A extends string | number>({
  idSelect = "id-select",
  label,
  value,
  values,
  getValue,
  getRender,
  onChange,
  disabled = false,
  limparSelecao = true,
  required = false,
  onBlur,
  onFocus,
  helperText,
  error = false,
}: SelectInputProps<T, A>): JSX.Element => {
  const idLabel = `${idSelect}-label`;

  const menuItems = useMemo(
    () =>
      values.map((item) => {
        const valueMenu = getValue(item);
        return (
          <MenuItem key={valueMenu} value={valueMenu}>
            {getRender ? getRender(item) : getRenderDefault(item, getValue)}
          </MenuItem>
        );
      }),
    [values, getValue, getRender]
  );

  return (
    <FormControl fullWidth error={error} disabled={disabled}>
      <InputLabel
        id={idLabel}
        sx={{
          backgroundColor: "white",
          px: "4px",
          transform: "translate(16px, -9px) scale(0.75)",
          pointerEvents: "none",
          color: disabled ? "text.disabled" : "primary",
        }}
      >{`${label} ${required ? "*" : ""}`}</InputLabel>
      <Select
        labelId={idLabel}
        id={idSelect}
        value={value ?? ""}
        onChange={(e) =>
          onChange(e.target.value === "" ? null : (e.target.value as A))
        }
        onBlur={onBlur}
        onFocus={onFocus}
        aria-labelledby={idLabel}
      >
        {limparSelecao && (
          <MenuItem value="">
            <em>Limpar Seleção</em>
          </MenuItem>
        )}
        {menuItems}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectInput;
