import {useState} from "react";

const CadastroCliente = () => {
  const [extraContacts, setExtraContacts] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const [showLogin, setShowLogin] = useState(false);

  const handleClickShowLogin = () => setShowLogin((show) => !show);

  const handleMouseDownLogin = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  return (
    <>

    </>
  );
};

export default CadastroCliente;
